// @flow
import type { Error, Listener, QueryParams } from '@core/types';
import type { SielBleuUser, SielBleuUserDetailed } from '@user/types';
import EventEmitter from '_common/services/EventEmitter';
import ApiService from '@api/service';

import { API_PATHS } from '@app/constants/paths';
import { hydrateSielBleuUserFromPayload, hydrateSielBleuUserDetailedFromPayload } from '@user/helpers/SielBleuUsersUtils';

export type SielBleuUserServiceData = {
  sielBleuUser: SielBleuUserDetailed | null,
  sielBleuUsers: Array<SielBleuUser>,
  loading: boolean,
  errors: Error[],
};

type UpdateValues = (newSielBleuUser: SielBleuUserDetailed | null, newSielBleuUsers: SielBleuUser[], newIsLoading: boolean, newErrors: Error[]) => void;
type FetchAll = (params: QueryParams) => void;
type FetchOne = (id: number) => Promise<SielBleuUserDetailed>;
type Reset = () => void;
type OnChange = (listener: Listener) => Function;

const { SIEL_BLEU_USERS } = API_PATHS;

let sourceAll = ApiService.createToken();

class SielBleuUserService {
  constructor() {
    this.eventEmitter = new EventEmitter();

    this.sielBleuUser = null;
    this.sielBleuUsers = [];
    this.loading = false;
    this.errors = [];
  }

  eventEmitter: EventEmitter;

  sielBleuUser: SielBleuUserDetailed | null;
  sielBleuUsers: SielBleuUser[];
  loading: boolean;
  errors: Error[];

  // TODO: Implements getters/setters

  get getSielBleuUser(): SielBleuUserDetailed | null {
    return this.sielBleuUser;
  }

  get getSielBleuUsers(): SielBleuUser[] {
    return this.sielBleuUsers;
  }

  get isLoadingValue(): boolean {
    return this.loading;
  }

  get errorsValues(): Error[] {
    return this.errors;
  }

  updateValues: UpdateValues = (newSielBleuUser: SielBleuUserDetailed | null, newSielBleuUsers: SielBleuUser[], newLoading: boolean, newErrors: Error[] = []): void => {
    this.sielBleuUser = newSielBleuUser;
    this.sielBleuUsers = newSielBleuUsers;
    this.loading = newLoading;
    this.errors = newErrors;
    this.#trigger();
  };

  fetchAll: FetchAll = (params: QueryParams): void => {
    const { page = 1 } = params;

    sourceAll.cancel();
    sourceAll = ApiService.createToken();

    this.updateValues(this.sielBleuUser, [], true, this.errors);

    ApiService.request({
      method: 'get',
      url: `${ SIEL_BLEU_USERS }`,
      cancelToken: sourceAll.token,
      params: {
        page,
      },
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;
          const sielBleuUsersArray = data['hydra:member'].map(
            (sielBleuUserPayload) => hydrateSielBleuUserFromPayload(sielBleuUserPayload),
          );
          this.updateValues(this.sielBleuUser, sielBleuUsersArray, false, this.errors);
        } else {
          this.updateValues(this.sielBleuUser, [], false, this.errors);
        }
      })
      .catch(() => {
        this.updateValues(this.sielBleuUser, [], false, this.errors);
      });
  };

  fetchOne: FetchOne = (id: number): Promise<SielBleuUserDetailed> => {
    this.updateValues(null, this.sielBleuUsers, true, this.errors);

    return ApiService.request({
      method: 'get',
      url: `${ SIEL_BLEU_USERS }/${ id }`,
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;
          const sielBleuUser = hydrateSielBleuUserDetailedFromPayload(data);

          this.updateValues(sielBleuUser, this.sielBleuUsers, false, this.errors);
          return sielBleuUser;
        }

        this.updateValues(null, this.sielBleuUsers, false, this.errors);
        return Promise.reject();
      })
      .catch(() => {
        this.updateValues(null, this.sielBleuUsers, false, this.errors);
        return Promise.reject();
      });
  };

  reset: Reset = (): void => {
    this.updateValues(null, [], false, []);
  };

  onChange: OnChange = (listener: Listener) => {
    const listenerFunction = this.eventEmitter.addListener(listener);
    this.#trigger();
    return listenerFunction;
  };

  /**
   * @private
   */
  #trigger = () => {
    this.eventEmitter.trigger({
      sielBleuUser: this.sielBleuUser,
      sielBleuUsers: this.sielBleuUsers,
      loading: this.loading,
      errors: this.errors,
    });
  };

}

const instance: SielBleuUserService = new SielBleuUserService();
export default instance;
