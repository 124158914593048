// @flow
import axios from 'axios';
import type { Listener } from '@core/types';
import EventEmitter from '_common/services/EventEmitter';

import ApiService from '@api/service';

import { type MediaObject } from '@groupSend/types';
import { hydrateMediaObjectFromPayload } from '@groupSend/helpers/GroupSendUtils';

import { API_PATHS } from '@app/constants/paths';
const { MEDIA_OBJECTS } = API_PATHS;

type CreateMediaObject = (file: File) => Promise<MediaObject>;
type OnChange = (listener: Listener) => Function;

class MediaObjectService {
  constructor() {
    this.eventEmitter = new EventEmitter();
  }

  eventEmitter: EventEmitter;

  createMediaObject: CreateMediaObject = (file: File): Promise<MediaObject> => (
    ApiService
      .request({
        method: 'post',
        url: MEDIA_OBJECTS,
        data: {
          originalName: file.name,
          contentType: file.type,
          contentSize: file.size,
        },
      })
      .then((payload) => {
        if (payload.data) {
          const mediaObject = hydrateMediaObjectFromPayload(payload.data);
          if (mediaObject.upload) {
            return axios.put(mediaObject.upload, file)
              .then(() => mediaObject);
          }
          return Promise.reject();
        }
        return Promise.reject();
      })
  );

  onChange: OnChange = (listener: Listener): Function => {
    const listenerFunction = this.eventEmitter.addListener(listener);
    this.#trigger();
    return listenerFunction;
  };

  #trigger = () => {
    this.eventEmitter.trigger({});
  };
}

const instance: MediaObjectService = new MediaObjectService();
export default instance;
