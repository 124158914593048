// @flow
import { useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { format as dateFmt } from 'date-fns';

import withGroupClass from '@hoc/withGroupClass';

import AdminLayout from '@layout/components/AdminLayout';

import DatePickerField from '_common/components/DateTimePicker/components/DatePickerField';
import InputField from '_common/components/input-field/InputField';
import Button, { buttonConstants } from '@shared/Button';
import Toast from '_common/services/Toast/Toast';
import ButtonGoBack from '@shared/Navigation/ButtonGoBack';

import { emptyBankDeposit } from '@payment/helpers/BankDepositUtils';
import BankDepositService, { type BankDepositServiceData } from '@payment/services/BankDepositService';
import TranslationHelpers, { ERROR_IS_BLANK, IS_NOT_UNIQUE } from '@helpers/TranslationHelpers';

import type { Error, ErrorProps } from '@core/types';
import type { BankDepositFormData, BankDeposit } from '@payment/types';
import type { GroupClassDetailed } from '@groupClass/types';

import { LOADER_TYPE_PAGE } from '_common/components/loader/constants';
import { errorColor } from '@app/constants/styles';
import { FORMAT_DATE_API } from '@app/constants/dates';
import { ADMIN_HEADER_PAYMENTS } from '@layout/constants';
import { WEB_PATHS } from '@app/constants/paths';
import { RE_1_TO_30_TEXT_DIGITS, RE_ONLY_TEXT_NUMBERS } from '@helpers/Regex';
const { PAYMENTS_BANK_DEPOSIT_ADD_CHEQUES } = WEB_PATHS;

type Props = {
  groupClass: GroupClassDetailed | null,
};

export const AddBankDeposit = (props: Props): React$Node => {
  const { groupClass } = props;
  const { t, i18n: { language } } = useTranslation();

  const navigate = useNavigate();

  const [ bankDeposit, setBankDeposit ] = useState<BankDepositFormData>(emptyBankDeposit);
  const [ listBankDeposit, setListBankDeposit ] = useState<Array<BankDeposit> | null>(null);
  const [ errors, setErrors ] = useState<Error[]>(BankDepositService.errors);
  const [ isLoading, setIsLoading ] = useState<boolean>(BankDepositService.isLoading);

  const handleUpdateDeposit = (data: BankDepositServiceData): void => {
    setIsLoading(data.isLoading);
    setErrors(data.errors);
    if (data.deposits) {
      setListBankDeposit(data.deposits);
    }
  };

  useEffect(() => BankDepositService.onChange(handleUpdateDeposit), []);

  useEffect(() => BankDepositService.fetchAll({ pagination: false }), []);

  const handleChange = useCallback((e: SyntheticEvent<HTMLInputElement>): void => {
    const { name, value } = e.currentTarget;
    if (value.match(RE_1_TO_30_TEXT_DIGITS) || value === '') {
      setBankDeposit({ ...bankDeposit, [name]: value });
      setErrors(errors.filter((error) => error.propertyPath !== name));
    }
  }, [bankDeposit, errors, setErrors]);

  const handleDateChange = useCallback((depositDate: moment$Moment): void => {
    const dateFormatted = new Date(String(depositDate));
    setBankDeposit({ ...bankDeposit, depositDate: dateFmt(dateFormatted, FORMAT_DATE_API) });
    setErrors(errors.filter((error) => error.propertyPath !== 'depositDate'));
  }, [bankDeposit, errors, setErrors]);

  const showSuccessMessage = useCallback((message: string): void => {
    Toast.success(t(message));
  }, [t]);

  const checkExistingReference = useCallback((reference): boolean => {
    let exists = false;
    for (let bankDeposit of listBankDeposit) {
      if (bankDeposit.reference === reference) {
        exists = true;
      }
    }
    return exists;
  }, [listBankDeposit]);

  const onValidation = useCallback((): void => {
    const { reference, depositDate } = bankDeposit;
    const formErrors = [...errors];
    if (!reference) {
      formErrors.push({ propertyPath: 'reference', code: ERROR_IS_BLANK, message: '' });
    }
    if (!depositDate) {
      formErrors.push({ propertyPath: 'depositDate', code: ERROR_IS_BLANK, message: '' });
    }
    if (checkExistingReference(reference)) {
      formErrors.push({ propertyPath: 'reference', code: IS_NOT_UNIQUE, message: '' });
    }

    if (formErrors.length === 0) {
      navigate(`${ PAYMENTS_BANK_DEPOSIT_ADD_CHEQUES
        .replace(':classId', String(groupClass?.id))
        .replace(':reference', String(reference))
        .replace(':depositDate', String(depositDate))
      }`);
    }
    setErrors(formErrors);
  }, [bankDeposit, errors, setErrors, showSuccessMessage, history]);

  const errorsProps = useMemo((): ErrorProps => {
    let errorsPropsTmp = {};
    errors.forEach((error) => {
      Object.assign(errorsPropsTmp, {
        [`${ error.propertyPath }`]: {
          color: 'is-danger',
          borderColor: errorColor,
          labelProps: { className: 'has-text-danger' },
          labelTextAreaProps: {
            className: 'label has-text-danger',
          },
          selectFieldHelperText: error.propertyPath === 'reference'
            ? { text: t(TranslationHelpers.getCommonErrorKeyByCode(error.code, 'referenceBankDeposit')), textColor: 'is-danger' }
            : { text: t(TranslationHelpers.getCommonErrorKeyByCode(error.code, error.propertyPath)), textColor: 'is-danger' },
          error: {
            text: t(TranslationHelpers.getCommonErrorKeyByCode(error.code, error.propertyPath)),
            textColor: 'is-danger',
          },
        },
      });
    });
    return errorsPropsTmp;
  }, [errors, language]);

  return (
    <AdminLayout groupClass={ groupClass } activeTab={ ADMIN_HEADER_PAYMENTS } preventPortraitMode isLoading={ isLoading } loaderType={ LOADER_TYPE_PAGE }>
      <div className="bank-deposit">
        <div className="bank-deposit-container container">
          { groupClass && (
            <div className="btn-go-back">
              <ButtonGoBack />
            </div>
          ) }

          <div className="bank-deposit-header">
            <div className="bank-deposit-title">
              <h1>{ t('payment.addBankDepositTitle') }</h1>
            </div>
            <div className="header-fields">
              <InputField
                id="reference"
                name="reference"
                label={ t('payment.bankDepositNumber') }
                onChange={ handleChange }
                value={ bankDeposit.reference }
                color={ errorsProps.reference?.color }
                labelProps={ errorsProps.reference?.labelProps }
                borderColor={ errorsProps.reference?.borderColor }
                helpProps={ errorsProps.reference?.selectFieldHelperText }
                className="fields"
                type="text"
                unauthorizedKeys={ RE_ONLY_TEXT_NUMBERS }
                required
              />
              <DatePickerField
                id="depositDate"
                name="depositDate"
                value={ (bankDeposit && bankDeposit.depositDate) ? new Date(bankDeposit.depositDate) : null }
                onChange={ handleDateChange }
                label={ t('payment.depositDate') }
                color={ errorsProps.depositDate?.color }
                labelProps={ errorsProps.depositDate?.labelProps }
                helpProps={ errorsProps.depositDate?.selectFieldHelperText }
                className="fields"
                required
              />
            </div>
          </div>

          <div className="validation-button">
            <Button
              type={ buttonConstants.PRIMARY }
              onClick={ onValidation }
            >
              { t('payment.bankDepositNext') }
            </Button>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default withGroupClass(AddBankDeposit);
