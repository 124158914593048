// @flow
import { useMemo, useCallback, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import type { Colors, LabelProps, HelpProps, Sizes } from '@core/types';
import type { IconsProps } from '_common/components/control/Control';
import IconEyePassword from '@icons/components/IconEyePassword';
import IconEyePasswordSlash from '@icons/components/IconEyePasswordSlash';
import Field from '_common/components/field/Field';
import Control from '_common/components/control/Control';
import Input, { type InputProps } from '_common/components/input/Input';
import Label from '_common/components/label/Label';
import Help from '_common/components/help/Help';

type Props = {
  className?: string,
  color?: Colors,
  controlIsExpanded?: boolean,
  helpProps?: HelpProps,
  icons: IconsProps | null,
  id?: string,
  inputProps?: InputProps,
  isDisabled?: boolean,
  label?: React$Node,
  labelProps?: LabelProps,
  limitedChar?: number,
  name: string,
  placeholder?: string,
  required?: boolean,
  size?: Sizes,
  step?: number,
  type?: string,
  value?: string | number,
  onChange: (event: SyntheticEvent<HTMLInputElement>) => void,
  unauthorizedKeys?: RegExp,
  readOnly?: boolean,
  isPasswordVisibilityToggleable?: boolean,
};

const InputField = (props: Props): React$Node => {
  const {
    className,
    color,
    controlIsExpanded,
    helpProps,
    icons = {},
    id,
    inputProps,
    isDisabled,
    label,
    labelProps = {},
    limitedChar,
    name,
    onChange,
    placeholder,
    required,
    size,
    step,
    type,
    value,
    unauthorizedKeys,
    readOnly,
    isPasswordVisibilityToggleable,
  } = props;

  const { className: labelClassName, ...othersLabelProps } = labelProps;

  const { t } = useTranslation();

  const [isPasswordVisible, setPasswordVisibility] = useState(false);

  const charLeft = limitedChar && value ? limitedChar - String(value).length : limitedChar;

  const helperClassName = useMemo((): string => clsx({
    'helper': true,
    'help-char-left': limitedChar && !helpProps,
  }), [limitedChar, helpProps]);

  const inputFieldLabelClassName = useMemo((): string => clsx({
    'g-label': true,
    'is-flex': true,
    [String(labelClassName)]: !!labelClassName,
  }), [labelClassName]);

  const inputType = useMemo((): string => {
    if (type === 'password' && isPasswordVisible) {
      return 'text';
    }
    else {
      return type || 'text';
    }
  }, [isPasswordVisible, type]);

  const togglePasswordVisibility = useCallback((event?: SyntheticEvent<HTMLInputElement>): void => {
    event?.preventDefault();
    event?.stopPropagation();
    setPasswordVisibility(!isPasswordVisible);
  }, [setPasswordVisibility, isPasswordVisible]);

  return (
    <Field className={ className }>
      { label && (
        <Label
          { ...othersLabelProps }
          size={ size }
          htmlFor={ name }
          id={ id }
          className={ inputFieldLabelClassName }
        >
          { label } { !readOnly && required && '*' }
        </Label>
      ) }
      <Control isExpanded={ controlIsExpanded } icons={ icons }>
        <Input
          { ...inputProps }
          value={ value }
          inputSize={ size }
          name={ name }
          step={ step }
          borderColor={ color }
          type={ inputType }
          required={ required }
          className="g-form-field"
          maxLength={ limitedChar }
          onChange={ onChange }
          disabled={ isDisabled }
          placeholder={ placeholder }
          unauthorizedKeys={ unauthorizedKeys }
          readOnly= { readOnly }
        />
        { isPasswordVisibilityToggleable && ( isPasswordVisible ? <IconEyePasswordSlash onClick={ togglePasswordVisibility } /> : <IconEyePassword onClick={ togglePasswordVisibility } /> ) }
      </Control>
      <div className={ helperClassName }>
        { helpProps && <Help { ...helpProps } textColor={ color } /> }
        { limitedChar && <Help text={ t('form.charactersLeft', { charLeft }) } /> }
      </div>
    </Field>
  );
};

InputField.defaultProps = {
  icons: null,
  readOnly: false,
  onChange: () => {},
};

export default InputField;
