// @flow
import { useCallback, useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format as dateFmt } from 'date-fns';

import GroupClassPeriodService, { type GroupClassPeriodServiceData } from '@groupClass/services/GroupClassPeriodService';
import GroupClassService from '@groupClass/services/GroupClassService';
import ModalYesNo from '@shared/Modal/components/ModalYesNo/ModalYesNo';
import IconCircleXMark from '@icons/components/IconCircleXMark';

import { FORMAT_DATE_API } from '@app/constants/dates';

import type { GroupClassDetailed } from '@groupClass/types';
import type { Error } from '@core/types';

import { PREVIOUS_PERIOD_NOT_VALIDED } from '@helpers/TranslationHelpers';

type Props = {
  isActive: boolean,
  toggleModal: () => void,
  showSuccessMessage: (message: string) => void,
  periodId: number,
  groupClass: GroupClassDetailed,
};

const ModalValidatePeriod = (props: Props): React$Node => {
  const {
    isActive,
    toggleModal,
    showSuccessMessage,
    periodId,
    groupClass,
  } = props;

  const { t, i18n: { language } } = useTranslation();
  const [ errors, setErrors ] = useState<Error[]>(GroupClassPeriodService.errors);

  const onClickYes = useCallback((): void => {
    if (periodId && groupClass) {
      const today = dateFmt(new Date(), FORMAT_DATE_API);
      GroupClassPeriodService.updatePeriod(periodId, today)
        .then(() => {
          showSuccessMessage('payment.periodValidatedNotification');
          //Fetch period to update button validate period
          GroupClassPeriodService.fetchOne(periodId);
          GroupClassService.fetchGroupClassById(String(groupClass.id), language);
          toggleModal();
        });
    }
  }, [periodId, groupClass, language, toggleModal, showSuccessMessage]);

  const handleGroupClassPeriodServiceChange = useCallback((data: GroupClassPeriodServiceData): void => {
    setErrors(data.errors);
  }, [setErrors]);

  useEffect(() => GroupClassPeriodService.onChange(handleGroupClassPeriodServiceChange), []);

  const isTheLastPeriod = useMemo((): boolean => (
    groupClass?.periods.length > 0
      ? groupClass.periods[groupClass.periods.length - 1].id === periodId
      : false
  ), [periodId, groupClass]);

  const isPreviousPeriodValidationError = useMemo((): boolean => (
    errors?.[0]?.code === PREVIOUS_PERIOD_NOT_VALIDED
  ), [errors]);

  return (
    <ModalYesNo
      isActive={ isActive }
      toggleModal={ toggleModal }
      onClickYes={ onClickYes }
      title={ t('payment.validatePeriod') }
    >
      <p className="text-modal-grey">
        { t('payment.validatePeriodSentenceOne') }
      </p>
      <p className="text-modal-grey">
        { t('payment.validatePeriodSentenceTwo') }
      </p>
      { isTheLastPeriod && (
        <p className="text-modal-grey">
          { t('payment.validatePeriodSentenceThree') }
        </p>
      ) }
      { isPreviousPeriodValidationError && (
        <div className="custom-error-container">
          <div className="custom-error-message">
            <IconCircleXMark />
            { t('payment.previousPeriodValidationError') }
          </div>
        </div>
      ) }
    </ModalYesNo>
  );
};

export default ModalValidatePeriod;
