// @flow
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import PublicRoute from '@shared/PublicRoute/components/PublicRoute';
import Button, { buttonConstants } from '@shared/Button';
import PasswordHint from './PasswordHint';
import Toast from '_common/services/Toast/Toast';

import PasswordUpdateService from '@login/services/PasswordUpdateService';

import { MIN_PASSWORD_LENGTH } from '@login/constants';
import { LOADER_TYPE_PAGE } from '_common/components/loader/constants';
import { WEB_PATHS } from '@app/constants/paths';
import InputField from '_common/components/input-field/InputField';
import SpecialCharInfoBubble from '@login/components/SpecialCharInfoBubble';

const { LOGIN } = WEB_PATHS;

const PasswordUpdate = (): React$Node => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { search } = useLocation();
  const resetKey = new URLSearchParams(search.split('?')[1]).get('req') ?? '';

  const [ password, setPassword ] = useState<string>('');
  const [ passwordBis, setPasswordBis ] = useState<string>('');
  const [ error, setError ] = useState<boolean>(false);
  const [ isBubbleVisible, setIsBubbleVisible ] = useState<boolean>(false);

  const toggleBubble = useCallback((value?: boolean): void => {
    setIsBubbleVisible(value ?? !isBubbleVisible);
  }, [isBubbleVisible]);

  const hasMajChar = useMemo((): boolean => (
    /[A-Z]/.test(password)
  ), [password]);

  const hasMinChar = useMemo((): boolean => (
    /[a-z]/.test(password)
  ), [password]);

  const hasNumber = useMemo((): boolean => (
    /[0-9]/.test(password)
  ), [password]);

  const hasSpecialChar = useMemo((): boolean => (
    /[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/.test(password)
  ), [password]);

  const hasTwelveChar = useMemo((): boolean => (
    password.length >= MIN_PASSWORD_LENGTH
  ), [password]);

  const samePasswords = useMemo((): boolean => (
    password === passwordBis && password !== ''
  ), [password, passwordBis]);

  const handleSubmit = useCallback((event: SyntheticEvent<EventTarget>): void => {
    event.preventDefault();
    setError(false);

    if (password !== '' && passwordBis !== '' && hasMajChar && hasMinChar && hasNumber && hasSpecialChar && hasTwelveChar && samePasswords) {
      PasswordUpdateService.passwordUpdate(resetKey, password, passwordBis)
        .then((res) => {
          if (res.updated) {
            Toast.success(t('new.password.sent'));
            navigate(LOGIN);
          }
          else {
            Toast.warning(t('new.password.already_used'));
          }
        })
        .catch(() => {
          setError(true);
        });
    } else {
      setError(true);
    }
  }, [
    resetKey,
    password,
    passwordBis,
    hasMajChar,
    hasMinChar,
    hasNumber,
    hasSpecialChar,
    hasTwelveChar,
    samePasswords,
    setError,
  ]);

  const handlePasswordChange = (event: SyntheticEvent<HTMLInputElement>): void => {
    setPassword(event.currentTarget.value);
    setError(false);
  };

  const handlePasswordBisChange = (event: SyntheticEvent<HTMLInputElement>): void => {
    setPasswordBis(event.currentTarget.value);
    setError(false);
  };

  const passwordHintContainerError = useMemo((): string => clsx({
    'password-hint-container': true,
    'password-hint-container-error': error,
  }), [error]);

  const inputFieldError = useMemo((): string => clsx({
    'password-validated': samePasswords && !error,
    'error-password-input': error,
  }), [samePasswords, error]);

  return (
    <PublicRoute loaderType={ LOADER_TYPE_PAGE }>
      <div className="login-container container">
        <div className="login-content">
          <h1>{ t('create.password') }</h1>
          <form>
            <InputField
              label={ `${ t('login.password') } *` }
              name="password"
              onChange={ handlePasswordChange }
              value={ password }
              type="password"
              className={ inputFieldError }
              isPasswordVisibilityToggleable={ true }
            />
            <InputField
              label={ `${ t('login.passwordConfirmation') } *` }
              name="password-bis"
              onChange={ handlePasswordBisChange }
              value={ passwordBis }
              type="password"
              className={ inputFieldError }
              isPasswordVisibilityToggleable={ true }
            />
            <ul className={ passwordHintContainerError }>
              <li className="password-hint">
                <PasswordHint translationKey={ 'login.password.hint1' } isValid={ hasMajChar } />
              </li>
              <li className="password-hint">
                <PasswordHint translationKey={ 'login.password.hint2' } isValid={ hasMinChar } />
              </li>
              <li className="password-hint">
                <PasswordHint translationKey={ 'login.password.hint3' } isValid={ hasNumber } />
              </li>
              <li className="password-hint">
                <PasswordHint translationKey={ 'login.password.hint4' } isValid={ hasSpecialChar } />
                <SpecialCharInfoBubble
                  isVisible={ isBubbleVisible }
                  toggleVisible={ toggleBubble }
                />
              </li>
              <li className="password-hint">
                <PasswordHint translationKey={ 'login.password.hint5' } isValid={ hasTwelveChar } />
              </li>
              <li className="password-hint">
                <PasswordHint translationKey={ t('login.password.hint6') } isValid={ samePasswords } />
              </li>
            </ul>
            <Button
              type={ buttonConstants.PRIMARY }
              onClick={ handleSubmit }
            >
              { t('create.password') }
            </Button>
          </form>
        </div>
      </div>
    </PublicRoute>
  );
};

export default PasswordUpdate;
