// @flow
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';

import BeneficiaryLayout from '@layout/components/BeneficiaryLayout';
import InputField from '_common/components/input-field/InputField';
import Label from '_common/components/label/Label';
import Help from '_common/components/help/Help';
import Button, { buttonConstants } from '@shared/Button';
import ButtonGoBack from '@shared/Navigation/ButtonGoBack';

import { formatAmount } from '@payment/helpers/AmountUtils';
import { formatAddressFromGoogleAutoComplete, formatSimpleAddressToString } from '@shared/Locations/services/AddressUtils';
import TranslationHelpers, { ERROR_IS_BLANK } from '@helpers/TranslationHelpers';
import { errorColor, inputBorderColor } from '@app/constants/styles';

import AuthService, { type AuthServiceData } from '@user/services/AuthService';
import BeneficiaryBalancesService, { type BeneficiaryBalancesServiceData } from '@beneficiary/services/BeneficiaryBalancesService';
import PaymentProviderService from '@payment/services/PaymentProviderService';

import type { BillingAddressDataPayload } from '@payment/types';
import type { Error, ErrorProps, Option } from '@core/types';
import type { SimpleAddress } from '@shared/Locations/types';
import type { User } from '@user/types';

import { LOADER_TYPE_PAGE } from '_common/components/loader/constants';
import { GOOGLE_MAPS_API_KEY } from '@app/constants/constants';
import { WEB_PATHS } from '@app/constants/paths';
import { getLanguageFormat } from '_common/services/LanguageUtils';
const { BENEFICIARY_PAYMENT } = WEB_PATHS;

type NameDetails = {
  firstName: string,
  lastName: string,
};

const InvoiceDetails = (): React$Node => {
  const { t, i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const paramClassId = params.get('group_class') ? parseInt(params.get('group_class'), 10) : -1;
  const paramScheduleId = params.get('schedule') ? parseInt(params.get('schedule'), 10) : -1;

  const [ loading, setLoading ] = useState<boolean>(true);
  const [ amountToPay, setAmountToPay ] = useState<number>(0);
  const [ paymentScheduleId, setPaymentScheduleId ] = useState<number>(0);
  const [ nameDetails, setNameDetails ] = useState<NameDetails>({ firstName: '', lastName: '' });
  const [ addressDetails, setAddressDetails ] = useState<SimpleAddress>({ number: '', streetName: '', postalCode: '', city: '', country: '' });
  const [ addressOption, setAddressOption ] = useState<Option | null>(null);
  const [ errors, setErrors ] = useState<Error[]>([]);
  const [ currentUser, setCurrentUser ] = useState<User | null>(AuthService.user);
  const locale = currentUser ? getLanguageFormat(currentUser.language) : getLanguageFormat(language);
  const locales = [];
  locales['fr-FR'] = 'fr';
  locales['es-ES'] = 'es';
  const handleUpdateAuthService = (data: AuthServiceData) => {
    setCurrentUser(data.user);
  };

  useEffect(() => AuthService.onChange(handleUpdateAuthService), []);

  useEffect((): void => {
    if (paramClassId === -1 && paramScheduleId === -1) {
      navigate(BENEFICIARY_PAYMENT);
    }
  }, []);

  useEffect((): void => {
    if (currentUser) {
      PaymentProviderService.fetchCCInformation({
        itemsPerPage: 1,
        order: 'DESC',
      })
        .then((paymentCCInformation) => {
          const { billingFirstName, billingLastName, billingAddress } = paymentCCInformation;
          setNameDetails({
            firstName: billingFirstName,
            lastName: billingLastName,
          });
          setAddressDetails(billingAddress);
          setAddressOption({
            label: formatSimpleAddressToString(billingAddress),
            value: '',
          });
        })
        .catch(() => {
          const { firstName, lastName } = currentUser;
          setNameDetails({ firstName, lastName });
        });
    }
  }, [currentUser]);

  const handleUpdateState = (data: BeneficiaryBalancesServiceData) => {
    let scheduleId = -1;

    const totalAmount = data.beneficiaryBalances.reduce((amount, balance) => {
      let returnAmount = amount;
      if (balance.groupClass.id === paramClassId) {
        returnAmount += balance.toPay - balance.paid;

        if (scheduleId === -1) {
          scheduleId = balance.paymentScheduleId;
        }
      } else if (balance.paymentScheduleId === paramScheduleId) {
        returnAmount = balance.toPay - balance.paid;
        scheduleId = paramScheduleId;
      }
      return returnAmount;
    }, 0);

    setAmountToPay(totalAmount);
    setPaymentScheduleId(scheduleId);
    setLoading(data.loading);
  };

  useEffect(() => BeneficiaryBalancesService.onChange(handleUpdateState), []);

  useEffect((): void => {
    if (currentUser) {
      BeneficiaryBalancesService.fetchAll(currentUser.id, language);
    }
  }, [currentUser, language]);

  const handleNameChange = useCallback((e: SyntheticEvent<HTMLInputElement>): void => {
    const { name, value } = e.currentTarget;
    const newDetails = { ...nameDetails, [name]: value };
    setNameDetails(newDetails);
  }, [nameDetails, setNameDetails]);

  const handleAddressChange = useCallback((value): void => {
    if (value !== null) {
      geocodeByPlaceId(value.value.place_id)
        .then((results) => {
          if (results?.length > 0) {
            const address = formatAddressFromGoogleAutoComplete(results[0]);
            setAddressDetails(address);
            setAddressOption({
              label: formatSimpleAddressToString(address),
              value: '',
            });
            setErrors([]);
          }
        });
    } else {
      setAddressDetails({ number: '', streetName: '', postalCode: '', city: '', country: '' });
      setAddressOption(null);
    }
  }, [setAddressDetails, setAddressOption, setErrors]);

  const amountFormatted = useMemo((): string => (
    formatAmount(amountToPay, language)
  ), [amountToPay, language]);

  const validateFormData = useCallback((): boolean => {
    const errorsTmp = [];

    if (nameDetails.firstName === '') {
      errorsTmp.push({ propertyPath: 'firstName', code: ERROR_IS_BLANK, message: '' });
    }
    if (nameDetails.lastName === '') {
      errorsTmp.push({ propertyPath: 'lastName', code: ERROR_IS_BLANK, message: '' });
    }

    if (addressOption === null) {
      errorsTmp.push({ propertyPath: 'addressInvoice', code: ERROR_IS_BLANK, message: '' });
    }

    setErrors(errorsTmp);

    return errorsTmp.length === 0;
  }, [nameDetails, addressOption, setErrors]);

  /**
   * launchPayment
   * Action used to start the purchase of the selected balance
   */
  const launchPayment = useCallback((e: SyntheticEvent<EventTarget>): void => {
    e.preventDefault();

    if (validateFormData()) {
      const billingAddressData: BillingAddressDataPayload = {
        billingFirstName: nameDetails.firstName,
        billingLastName: nameDetails.lastName,
        billingAddress: {
          ...addressDetails,
          number: addressDetails.number ? parseInt(addressDetails.number, 10) : null,
        },
      };

      PaymentProviderService.fetchPaymentProviderInformation(paymentScheduleId, amountToPay, billingAddressData)
        .catch(setErrors);
    }
  }, [validateFormData, nameDetails, addressDetails, paymentScheduleId, amountToPay]);

  const errorsProps = useMemo((): ErrorProps => {
    let errorsPropsTmp = {};
    errors.forEach((error) => {
      let propertyPath = error.propertyPath;
      if (propertyPath === 'billingAddress.postalCode') propertyPath = 'addressInvoice';
      Object.assign(errorsPropsTmp, {
        [`${ propertyPath }`]: {
          color: 'is-danger',
          borderColor: errorColor,
          labelProps: { className: 'has-text-danger' },
          labelTextAreaProps: {
            className: 'has-text-danger label',
          },
          selectFieldHelperText: { text: t(TranslationHelpers.getCommonErrorKeyByCode(error.code, error.propertyPath)), textColor: 'is-danger' },
          error: {
            text: t(TranslationHelpers.getCommonErrorKeyByCode(error.code, error.propertyPath)),
            textColor: 'is-danger',
          },
        },
      });
    });

    return errorsPropsTmp;
  }, [errors]);

  const invoiceDetailsLabelClassName = useMemo((): string => clsx({
    'g-label': true,
    [String(errorsProps?.['addressInvoice']?.labelProps?.className)]: errorsProps?.['addressInvoice']?.labelProps?.className,
  }), [errorsProps?.['addressInvoice']?.labelProps?.className]);

  return (
    <BeneficiaryLayout isLoading={ loading } loaderType={ LOADER_TYPE_PAGE }>
      <div className="invoice-details">
        <div className="invoice-details-container container">
          <ButtonGoBack />
          <div className="invoice-details-header">
            <h1 className="invoice-details-title">
              { t('payment.invoiceDetails.title') }
            </h1>
          </div>
          <form className="invoice-details-edit">
            <div className="invoice-details-field-container">
              <InputField
                name="firstName"
                label={ t('groupClasses.groupClass.peoples.form.peopleFirstName') }
                required
                className="invoice-details-field"
                value={ nameDetails.firstName }
                onChange={ handleNameChange }
                color={ errorsProps.firstName?.color }
                labelProps={ errorsProps.firstName?.labelProps }
                helpProps={ errorsProps.firstName?.error }
              />
              <InputField
                name="lastName"
                label={ t('groupClasses.groupClass.peoples.form.peopleLastName') }
                required
                className="invoice-details-field"
                value={ nameDetails.lastName }
                onChange={ handleNameChange }
                color={ errorsProps.lastName?.color }
                labelProps={ errorsProps.lastName?.labelProps }
                helpProps={ errorsProps.lastName?.error }
              />
            </div>
            <div >
              <Label
                id="address.postalCode"
                size="is-normal"
                htmlFor="google-address-autocomplete"
                className={ invoiceDetailsLabelClassName }
              >
                { t('groupClasses.groupClass.detailed.address.address') } *
              </Label>
              <GooglePlacesAutocomplete
                apiKey={ GOOGLE_MAPS_API_KEY }
                debounce={ 250 }
                id="google-address-autocomplete"
                name="addressInvoice"
                minLengthAutocomplete={ 3 }
                apiOptions={ {
                  language: language,
                  libraries: ['places'],
                } }
                selectProps={ {
                  className: 'google-address-autocomplete',
                  placeholder: t('payment.invoiceDetails.addressHint'),
                  isClearable: true,
                  onChange: handleAddressChange,
                  value: addressOption,
                  loadingMessage: () => t('common.loading'),
                  noOptionsMessage: () => t('common.noOptions'),
                  styles: {
                    control: (provided) => ({
                      ...provided,
                      border: `1px solid ${ errorsProps?.['addressInvoice']?.color === 'is-danger' ? errorColor : inputBorderColor }`,
                      ':hover': {
                        borderColor: errorsProps?.['addressInvoice']?.color === 'is-danger' ? errorColor : inputBorderColor,
                      },
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                    }),
                  },
                } }
                autocompletionRequest={ {
                  componentRestrictions: {
                    country: [locales[locale]],
                  },
                } }
              />
              { (errorsProps?.['addressInvoice']?.error) && (
                <Help
                  textColor={ errorsProps?.['addressInvoice']?.error?.textColor }
                  text={ errorsProps?.['addressInvoice']?.error?.text }
                />
              ) }
            </div>
            <Button
              onClick={ launchPayment }
              className="invoice-details-button"
              type={ buttonConstants.PRIMARY }
            >
              { amountToPay === 0
                ? t('common.toPay')
                : t('payment.summary.pay', { total: amountFormatted } )
              }
            </Button>
          </form>
        </div>
      </div>
    </BeneficiaryLayout>
  );
};

export default InvoiceDetails;
