// @flow

import clsx from 'clsx';

type Props = {
  isBeneficiary: boolean,
  extraCss: string,
};

const ParticipantIndicator = (props: Props): React$Node => {
  const { isBeneficiary, extraCss } = props;

  const className = clsx({
    'participant-indicator': true,
    'beneficiary': isBeneficiary,
    [String(extraCss)]: !!extraCss,
  });

  return (
    <div className={ className }></div>
  );
};

ParticipantIndicator.defaultProps = {
  isBeneficiary: false,
  extraCss: '',
};

export default ParticipantIndicator;
