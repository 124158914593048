// @flow

export const formatAmount = (number: number, language: string): string => (
  number.toLocaleString(language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
);

export const formatAmountWithEuros = (number: number, language: string): string => {
  const formattedNumber = number.toLocaleString(language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return `${ formattedNumber } €`;
};
