// @flow
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import withGroupClass from '@hoc/withGroupClass';

import AdminLayout from '@layout/components/AdminLayout';

import AdvancedSelectField from '@shared/AdvancedSelectField';
import Button, { buttonConstants } from '@shared/Button';
import MessageService from '@groupSend/services/MessageService';
import RegisteredsService, { type RegisteredsServiceData } from '@groupClass/services/RegisteredsService';
import TextareaField from '_common/components/textarea-field/TextareaField';
import Toast from '_common/services/Toast/Toast';
import ButtonGoBack from '@shared/Button/components/ButtonGoBack';

import { uppercaseFirst } from '_common/services/CommonUtils';

import type { ErrorProps, ErrorPropsBase, Option } from '@core/types';
import type { GroupClassDetailed, ShortRegistered } from '@groupClass/types';

import * as apiConstants from '@api/constants';
import * as coreConstants from '@app/constants/paths';
import * as groupClassesConstants from '@app/constants/constants';
import { ADMIN_HEADER_SENDING_GROUP } from '@layout/constants';

const { PARAMS_NO_UNSUBSCRIBE_NO_PAGINATED_SORTED_LASTNAME } = apiConstants;
const { API_PATHS, WEB_PATHS } = coreConstants;
const { SMS } = API_PATHS;
const { GROUP_SEND } = WEB_PATHS;
const { SMS_LENGTH_LIMIT } = groupClassesConstants;

type Props = {
  groupClass: GroupClassDetailed | null,
};

const GroupClassSendSMS = (props: Props): React$Node => {
  const { groupClass } = props;
  const { t, i18n: { language } } = useTranslation();
  const navigate = useNavigate();

  const [ registered, setRegistered ] = useState<ShortRegistered[]>(RegisteredsService.groupClassRegistereds);
  const [ valueRegistereds, setValueRegistereds ] = useState<ShortRegistered[]>([]);
  const [ registeredLoading, setRegisteredLoading ] = useState<boolean>(RegisteredsService.isLoadingRegistereds);

  const [ valueTextArea, setValueTextArea ] = useState<string>('');
  const [ errors, setErrors ] = useState<ErrorProps>({});

  const handleUpdateRegistereds = (data: RegisteredsServiceData): void => {
    setRegistered(data.groupClassRegistereds);
    setRegisteredLoading(data.isLoadingRegistereds);
  };

  useEffect(() => RegisteredsService.onChange(handleUpdateRegistereds), []);

  useEffect(() => {
    if (groupClass) {
      RegisteredsService.fetchGroupClassRegistereds(groupClass.id, language, PARAMS_NO_UNSUBSCRIBE_NO_PAGINATED_SORTED_LASTNAME);
    }
  }, [groupClass]);

  const recipientsOptions = useMemo((): Option[] => (
    registered && registered
      .filter((register) => (register.person?.mobilePhone))
      .map((register) => ({
        label: `${ uppercaseFirst(register.person.firstName) } ${ register.person.lastName.toUpperCase() }`,
        value: String(register.person.id),
      }))
  ), [registered]);

  const handleChangeRegistereds = useCallback((value): void => {
    setValueRegistereds(value);
    if (errors?.beneficiary) {
      const newErrors = { ...errors };
      delete newErrors.beneficiary;
      setErrors(newErrors);
    }
  }, [errors, setValueRegistereds, setErrors]);

  const handleTextAreaChange = useCallback((e: SyntheticEvent<HTMLInputElement>): void => {
    setValueTextArea(e?.currentTarget?.value);
    if (errors?.message) {
      const newErrors = { ...errors };
      delete newErrors.message;
      setErrors(newErrors);
    }
  }, [errors]);

  const errorBuilder = (type: string): ErrorPropsBase => ({
    error: {
      text: t(`groupClasses.groupClass.sendGroup.global.${ type }Mandatory`),
      textColor: 'is-danger',
    },
    color: 'is-danger',
    labelProps: {
      className: 'has-text-danger',
    },
  });

  const handleSend = useCallback((e: SyntheticEvent<EventTarget>): void => {
    e.preventDefault();

    let selectedList: Array<string> = [];
    let newErrors = { ...errors };

    if (valueRegistereds.length > 0) {
      selectedList = valueRegistereds.map((selectedPerson) => selectedPerson.value);
    } else {
      newErrors = { ...newErrors, beneficiary: { ...errorBuilder('Recipient') } };
    }

    if (valueTextArea.length === 0) {
      newErrors = { ...newErrors, message: { ...errorBuilder('Message') } };
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    let dataForm = {
      recipients: selectedList,
      message: valueTextArea,
      object: undefined,
    };

    MessageService
      .sendMessage(SMS, dataForm)
      .then(() => Toast.success(t('groupClasses.groupClass.sendGroup.sms.sended')))
      .then(navigate(GROUP_SEND.replace(':classId', String(groupClass?.id))));
  }, [
    errors,
    setErrors,
    valueRegistereds,
    valueTextArea,
  ]);

  const handleReturn = useCallback((): void => {
    setErrors({});
    navigate(GROUP_SEND.replace(':classId', String(groupClass?.id)));
  }, [groupClass]);

  const hasRecipients = useMemo((): boolean => (
    !!recipientsOptions.length
  ), [recipientsOptions]);

  return (
    <AdminLayout groupClass={ groupClass } activeTab={ ADMIN_HEADER_SENDING_GROUP } preventPortraitMode>
      <div className="group-class-send-message">
        <div className="container">
          <ButtonGoBack onClick={ handleReturn } />

          <div className="group-class-send-container">
            <div className="container-flex">
              <h1>{ t('groupClasses.groupClass.sendGroup.sms.title') }</h1>
            </div>

            <div className="container-white">
              <form>
                <AdvancedSelectField
                  name="first-field"
                  label={ t('groupClasses.groupClass.sendGroup.global.firstField') }
                  options={ recipientsOptions }
                  isLoading={ registeredLoading }
                  isClearable={ false }
                  className="send-input"
                  helpProps={ errors?.beneficiary?.error }
                  color={ errors?.beneficiary?.color }
                  labelProps={ errors?.beneficiary?.labelProps }
                  onChange={ handleChangeRegistereds }
                  placeholder={
                    hasRecipients
                      ? t('groupClasses.groupClass.sendGroup.global.firstFieldPlaceholder')
                      : t('groupClasses.groupClass.sendGroup.global.noRegistered') }
                  value={ hasRecipients && valueRegistereds }
                  isMulti={ hasRecipients && t('groupClasses.groupClass.sendGroup.global.allParticipants') }
                  isDisabled={ !hasRecipients }
                  isSearchable={ false }
                  required
                />

                <TextareaField
                  limitedChar={ SMS_LENGTH_LIMIT }
                  name="message"
                  label={ `${ t('groupClasses.groupClass.sendGroup.global.thirdField') } ${ t('common.required') }` }
                  value={ valueTextArea }
                  placeholder={ t('groupClasses.groupClass.sendGroup.global.MessageMandatory') }
                  onChange={ handleTextAreaChange }
                  helpProps={ errors?.message?.error }
                  color={ errors?.message?.color }
                  labelProps={ errors?.message?.labelProps }
                />

                <div className="buttons-container">
                  <Button
                    className="cancel-button"
                    onClick={ handleReturn }
                    type={ buttonConstants.TERTIARY }
                  >
                    { t('common.cancel') }
                  </Button>
                  <Button
                    className="send-button"
                    type={ buttonConstants.PRIMARY }
                    onClick={ handleSend }
                  >
                    { t('groupClasses.groupClass.sendGroup.global.send') }
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default withGroupClass(GroupClassSendSMS);
