// @flow
import { useState, useEffect } from 'react';

import type { User } from '@user/types';
import AuthService from '@user/services/AuthService';

type AuthState = {
  user: User | null,
  isConnected: boolean,
};

type UseAuth = AuthState & {
  disconnect: (redirect?: boolean) => void,
};

const useAuth = (): UseAuth => {
  const [ auth, setAuth ] = useState<AuthState>({
    user: AuthService.user,
    isConnected: AuthService.isConnected,
  });

  useEffect(() => AuthService.onChange(setAuth), []);

  return {
    user: auth.user,
    isConnected: auth.isConnected,
    disconnect: AuthService.disconnect,
  };
};

export default useAuth;
