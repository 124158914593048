// @flow

class ObjectHelpers {
  /**
  * isObject
  * Check if the given argument is an object or not
  * @param  {any}  obj
  * @return {boolean}
  */
  static isObject(obj: any): boolean {
    return !!obj && typeof obj === 'object';
  }

  /**
  * isEmpty
  * Check if the argument received is an object and if he has at least one property.
  * If the argument is null, undefined, or another type than object, the return value
  * will be true.
  * Caution: Will returns false if an array is provide with datas
  * @param  {any}  obj
  * @return {boolean}
  */
  static isEmpty(obj: any): boolean {
    if (obj === null || obj === undefined || typeof obj !== 'object') return true;
    return Object.keys(obj).length === 0;
  }

  /**
  * deepClone
  * Enable to make a deep clone of an object. If the provided data is not an object, this
  * helper will return the provided value
  * @param {any} obj
  * @return {any}
  */
  static deepClone(obj: any): any {
    if (obj instanceof Date) return new Date(obj);
    if (!ObjectHelpers.isObject(obj)) return obj;
    if (Array.isArray(obj) && obj.length === 0) return [];
    if (ObjectHelpers.isEmpty(obj)) return {};

    let returnObject: any;

    if (Array.isArray(obj)) {
      returnObject = [];
      obj.forEach((objKey) => {
        returnObject.push(ObjectHelpers.deepClone(objKey));
      });
    } else if (obj instanceof Object) {
      returnObject = {};
      Object.keys(obj).forEach((key) => {
        returnObject[key] = ObjectHelpers.deepClone(obj[key]);
      });
    } else {
      returnObject = obj;
    }

    return returnObject;
  }

  /**
   * parseCSV
   * Helper used to parse data formatted like a CSV into an object
   * @param {?string} data
   * @returns {Object}
   */
  static parseCSV(data?: string): Object {
    const returnObject = {};
    if (data) {
      const objects = data.split('; ');
      objects.forEach((obj) => {
        const [key, value] = obj.split('=');
        returnObject[key] = value;
      });
    }
    return returnObject;
  }
}

export default ObjectHelpers;
