// @flow
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import withGroupClass from '@hoc/withGroupClass';

import AdminLayout from '@layout/components/AdminLayout';

import SendIconCard from '@groupSend/components/SendIconCard';

import type { GroupClassDetailed } from '@groupClass/types';

import { WEB_PATHS } from '@app/constants/paths';
import { ADMIN_HEADER_SENDING_GROUP } from '@layout/constants';
const {
  GROUP_SEND_EMAIL,
  GROUP_SEND_SMS,
  GROUP_SEND_SHARED_SESSION,
} = WEB_PATHS;

type Props = {
  groupClass: GroupClassDetailed | null,
};

export const GroupClassSend = (props: Props): React$Node | null => {
  const { groupClass } = props;
  const { t } = useTranslation();

  if (!groupClass) {
    return null;
  }

  return (
    <AdminLayout groupClass={ groupClass } activeTab={ ADMIN_HEADER_SENDING_GROUP } preventPortraitMode>
      <div className="group-class-send">
        <div className="group-class-send-container container">
          <div className="group-class-send-content">
            <Link to={ GROUP_SEND_EMAIL.replace(':classId', String(groupClass.id)) }>
              <SendIconCard icon="icone-mail.svg" title={ t('groupClasses.groupClass.sendGroup.email.button') } />
            </Link>
            <Link to={ GROUP_SEND_SMS.replace(':classId', String(groupClass.id)) }>
              <SendIconCard icon="icone_SMS.svg" title={ t('groupClasses.groupClass.sendGroup.sms.button') } />
            </Link>
            <Link to={ GROUP_SEND_SHARED_SESSION.replace(':classId', String(groupClass.id)) }>
              <SendIconCard icon="send-session.svg" title={ t('groupClasses.groupClass.sendGroup.session.button') } />
            </Link>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default withGroupClass(GroupClassSend);
