// @flow
import { useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';

import type { User } from '@user/types';
import type { GroupClassRegistered } from '@groupClass/types';
import CardItem from '@groupClass/components/CardItem';
import Loader from '_common/components/loader/Loader';

import AuthService, { type AuthServiceData } from '@user/services/AuthService';
import BeneficiaryGroupClassesRegisteredsService, { type BeneficiaryGroupClassesRegisteredsServiceData } from '@beneficiary/services/BeneficiaryGroupClassesRegisteredsService';
import GroupClassesService from '@groupClass/services/GroupClassesService';

import * as apiConstants from '@api/constants';
import { CLASS_PAYMENT_STATUS_TO_PAY, CLASS_PAYMENT_MYGROUPCLASSES_STATUS } from '@payment/constants';
import { WEB_PATHS } from '@app/constants/paths';
import { LOADER_TYPE_PAGE } from '_common/components/loader/constants';

const { GROUP_CLASS, BENEFICIARY_PAYMENT } = WEB_PATHS;
const { PARAMS_NO_PAGINATED_SORTED_ACTIVITY } = apiConstants;

const GroupClassesRegistered = (): React$Node | null => {
  const navigate = useNavigate();
  const { t, i18n: { language } } = useTranslation();
  const [ currentUser, setCurrentUser ] = useState<User | null>(AuthService.user);
  const [ groupClassesRegistereds, setGroupClassesRegistereds ] = useState<GroupClassRegistered[]>(BeneficiaryGroupClassesRegisteredsService.registeredsValues);
  const [ isGroupClassLoading, setIsGroupClassLoading ] = useState<boolean>(GroupClassesService.isGroupClassLoading);

  const handleUpdateAuthService = (data: AuthServiceData) => {
    setCurrentUser(data.user);
  };

  const handleUpdateState = (data: BeneficiaryGroupClassesRegisteredsServiceData): void => {
    setGroupClassesRegistereds(data.registereds);
    setIsGroupClassLoading(data.isLoading);
  };

  useEffect(() => AuthService.onChange(handleUpdateAuthService), []);
  useEffect(() => BeneficiaryGroupClassesRegisteredsService.onChange(handleUpdateState), []);

  useEffect(() => {
    if (currentUser) {
      BeneficiaryGroupClassesRegisteredsService.fetchAll(currentUser.id, language, PARAMS_NO_PAGINATED_SORTED_ACTIVITY);
    }
  }, [currentUser, language]);

  const hasEntries = useMemo((): boolean => (
    groupClassesRegistereds
      .filter((groupClassesRegistered) => CLASS_PAYMENT_MYGROUPCLASSES_STATUS.includes(groupClassesRegistered.paymentStatus))
      .some((element) => element.groupClass.seasonClosedAt === null
        || (element.groupClass.seasonClosedAt !== null && element.paymentStatus === CLASS_PAYMENT_STATUS_TO_PAY))
  ), [groupClassesRegistereds]);

  const handleClick = useCallback((registered): void => {
    if (registered.groupClass.seasonClosedAt) {
      navigate(BENEFICIARY_PAYMENT);
    } else {
      navigate(GROUP_CLASS.replace(':classId', String(registered.groupClass.id)));
    }
  }, []);

  const sortedGroupClassesRegistereds = useMemo((): GroupClassRegistered[] => (
    groupClassesRegistereds
      .filter((groupClassesRegistered) => groupClassesRegistered.unsubscribedAt === null)
      .sort((a, b) => a.sortKey.localeCompare(b.sortKey))
  ), [groupClassesRegistereds]);

  if (!hasEntries) {
    return null;
  }

  return (
    <div className="column is-12">
      <div className="group-classes-registered">
        <div className="group-classes-title-heretopay">
          <h1 className="group-classes-title">{ t('navigation.myGroupClasses') }</h1>
          <div className="group-classes-registered-button">
            <span className="group-classes-registered-pay">{ t('payment.summary.hereToPay') }</span>
            <Link to={ BENEFICIARY_PAYMENT } className="button button-primary">
              { t('payment.summary.payGroupClasses') }
            </Link>
          </div>
        </div>
        <div className="group-classes-registered-block">
          <div className="columns is-flex-wrap-wrap is-mobile">
            { isGroupClassLoading && (
              <Loader loaderType={ LOADER_TYPE_PAGE } />
            ) }
            { !isGroupClassLoading && sortedGroupClassesRegistereds?.map((registered: GroupClassRegistered) => {
              const isOpen = !registered.groupClass.seasonClosedAt;
              const { paymentStatus } = registered;

              return (
                (isOpen || (paymentStatus === 'toPay')) && (
                  <div
                    className="column is-3-widescreen is-4-desktop is-6-tablet is-12-mobile"
                    key={ registered.registered }
                  >
                    <CardItem
                      groupClass={ registered.groupClass }
                      paymentStatus={ registered.paymentStatus }
                      onClick={ () => handleClick(registered) }
                    />
                  </div>
                )
              );
            }) }
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupClassesRegistered;
