// @flow
import moment from 'moment';

import type { Option } from '@core/types';

import localeEn from 'moment/dist/locale/en-gb';
import localeEs from 'moment/dist/locale/es';
import localeFr from 'moment/dist/locale/fr';

const ALLOWED_LOCALES = ['en', 'es', 'fr'];

const resources = {
  en: {
    locale: localeEn,
  },
  es: {
    locale: localeEs,
  },
  fr: {
    locale: localeFr,
  },
};

class DateHelpers {
  /**
   * parseDate
   * Enable to create a moment object from a string date
   * @param {string | Moment} date
   * @param {?string} format
   * @param {?string} locale
   * @returns {moment$Moment}
   */
  static parseDate(date: string | moment$Moment, format?: string, locale?: string): moment$Moment {
    if (locale) moment.locale(locale);
    return format ? moment(date, format) : moment(date);
  }

  /**
   * formatDate
   * Helper used to format the given date
   * @param {moment$Moment | string | null} date
   * @param {string} format
   * @param {?string} language
   * @returns {string}
   */
  static formatDate(date: moment$Moment | string | null, format: string, language: ?string): string {
    if (language) moment.locale(language);
    return moment(date || undefined, format).format(format);
  }

  /**
   * Normalize the lang attribute
   * @param {string} lang
   * @returns string
   */
  static #parsedLang(lang: string): string {
    const paramLang = lang.substr(0, 2).toLowerCase();
    return ALLOWED_LOCALES.includes(paramLang) ? paramLang : 'fr';
  }

  /**
   * Returns an array of localised weekday names
   *
   * @param {string} lang
   * @returns {string[]}
   */
  static getDaysOfWeek(lang: string): string[] {
    const { locale } = resources[DateHelpers.#parsedLang(lang)];
    const weekdays = locale.weekdays();
    const dow = locale.firstDayOfWeek();

    return [].concat(weekdays.slice(dow), weekdays.slice(0, dow));
  }

  /**
   * Returns an array of weekday Option objects, with localised label
   *
   * @param {string} lang
   * @returns {Option[]}
   */
  static getDaysOfWeekOptions(lang: string): Option[] {
    const translatedDays = DateHelpers.getDaysOfWeek(lang);
    const propertyDays = DateHelpers.getDaysOfWeek('en');

    return translatedDays.map((trDay, i) => ({
      label: trDay.charAt(0).toUpperCase() + trDay.slice(1),
      value: propertyDays[i].toLowerCase(),
    }));
  }

  /**
   * Returns localised english weekday to requested locale's weekday
   * "monday" -> "Monday/lunes/lundi"
   *
   * @param {string} lang
   * @returns {string}
   */
  static getDayOfWeek(day: string, lang: string): string {
    const localeEn = resources['en'].locale;
    const { locale } = resources[DateHelpers.#parsedLang(lang)];

    const translatedDays = locale.weekdays();
    return translatedDays[localeEn.weekdaysParse(day.toLowerCase())];
  }

}

export default DateHelpers;
