//@flow
import { useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DatePickerField from '_common/components/DateTimePicker/components/DatePickerField';
import InputField from '_common/components/input-field/InputField';

import ObjectHelpers from '@helpers/ObjectHelpers';

import type { GroupClassDetailed } from '@groupClass/types';
import type { ErrorProps } from '@core/types';

import * as groupClassesConstants from '@app/constants/constants';
import { errorColor } from '@app/constants/styles';

const {
  EXCEPT_DATES_LENGTH_LIMIT,
} = groupClassesConstants;

type Props = {
  errorsProps: ErrorProps,
  groupClass: GroupClassDetailed,
  setGroupClass: (newGroupClass: GroupClassDetailed) => void,
  onChange: (event: SyntheticEvent<HTMLInputElement>) => void,
};

const SeasonDates = (props: Props): React$Node => {
  const { t } = useTranslation();
  const { groupClass, errorsProps, setGroupClass, onChange } = props;

  const [ errorSeasonDates, setErrorSeasonDates ] = useState<ErrorProps>(errorsProps);

  const startDate = useMemo((): Date => new Date(groupClass.startDate), [groupClass]);
  const endDate = useMemo((): Date => new Date(groupClass.endDate), [groupClass]);

  const handleChangeDate = useCallback((e: Date, date: 'startDate' | 'endDate'): void => {
    const newGroupClass = ObjectHelpers.deepClone(groupClass);
    newGroupClass[date] = new Date(String(e));
    if (e === null) {
      errorSeasonDates[date] = {
        color: 'is-danger',
        borderColor: errorColor,
        labelProps: { className: 'has-text-danger' },
        labelTextAreaProps: {
          className: 'has-text-danger edit-label',
        },
        selectFieldHelperText: { text: t('commonError.thisFieldIsMandatory'), textColor: 'is-danger' },
        error : {
          text: t('groupClasses.groupClass.detailed.errorPeriodDateNull'),
          textColor: 'is-danger',
        },
      };
      setErrorSeasonDates(errorSeasonDates);
    }

    setGroupClass(newGroupClass);
  }, [groupClass]);

  return (
    <>
      <div className="season-dates">
        <DatePickerField
          id="startDate"
          name="startDate"
          value={ startDate }
          selected={ startDate }
          className="input-edit-form"
          label={ t('groupClasses.groupClass.detailed.startSeason') }
          isDisabled={ groupClass.periods.length > 0 }
          onChange={ (e) => handleChangeDate(e, 'startDate') }
          color={ errorsProps.startDate?.color }
          labelProps={ errorsProps.startDate?.labelProps }
          helpProps={ errorsProps.startDate?.error }
          required
        />
        <DatePickerField
          id="endDate"
          name="endDate"
          value={ endDate }
          selected={ endDate }
          className="input-edit-form"
          label={ t('groupClasses.groupClass.detailed.endSeason') }
          isDisabled={ groupClass.periods.length > 0 }
          onChange={ (e) => handleChangeDate(e, 'endDate') }
          color={ errorsProps.endDate?.color }
          labelProps={ errorsProps.endDate?.labelProps }
          helpProps={ errorsProps.endDate?.error }
          required
        />
      </div>

      <InputField
        id="exceptDates"
        name="exceptDates"
        className="input-edit-form"
        label={ t('groupClasses.groupClass.detailed.exceptDate') }
        limitedChar={ EXCEPT_DATES_LENGTH_LIMIT }
        value={ groupClass.exceptDates || '' }
        onChange={ onChange }
        placeholder={ t('groupClasses.groupClass.detailed.exceptDatesPlaceHolder') }
        color={ errorsProps.exceptDates?.color }
        labelProps={ errorsProps.exceptDates?.labelProps }
        helpProps={ errorsProps.exceptDates?.error }
      />
    </>
  );
};

export default SeasonDates;
