// @flow
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import type { UserRegistered, GroupClassDetailed } from '@groupClass/types';
import type { Beneficiary } from '@beneficiary/types';
import type { Error } from '@core/types';

import ObjectHelpers from '@helpers/ObjectHelpers';

import ParticipantIndicator from '@shared/ParticipantIndicator';
import Button, { buttonConstants } from '@shared/Button';
import FormContainer from '@shared/FormContainer';

import PaymentForm from '@shared/Payment/components/PaymentForm';

import DateService from '_common/services/DateService';
import Toast from '_common/services/Toast/Toast';

import BeneficiaryService, { type BeneficiaryServiceData } from '@beneficiary/services/BeneficiaryService';
import RegisteredsService, { type RegisteredsServiceData } from '@groupClass/services/RegisteredsService';

import { ERROR_IS_BLANK } from '@helpers/TranslationHelpers';
import {
  CONDITION_TYPE_SUBSIDY,
  CONDITION_TYPE_OTHER,
} from '@paymentConditionType/constants';
import { WEB_PATHS } from '@app/constants/paths';
const { PARTICIPANTS_REGISTERED_ID } = WEB_PATHS;

type Props = {
  registered: UserRegistered,
  groupClass: GroupClassDetailed,
};

type UseParamProps = {
  classId: string,
};

const BeneficiaryEditPayment = (props: Props): React$Node => {
  const { registered, groupClass } = props;
  const { t, i18n: { language } } = useTranslation();
  const { classId } = useParams<UseParamProps>();
  const navigate = useNavigate();

  const [ isLoading, setLoading ] = useState<boolean>(BeneficiaryService.isLoadingValue);
  const [ beneficiary, setBeneficiary ] = useState<Beneficiary | null>(BeneficiaryService.beneficiary);

  const [ isRegisteredLoading, setIsRegisteredLoading ] = useState<boolean>(RegisteredsService.isLoadingRegistered);
  const [ groupClassRegistered, setGroupClassRegistered ] = useState<UserRegistered | null>(RegisteredsService.groupClassRegistered);
  const [ paymentErrors, setPaymentErrors ] = useState<Error[]>(RegisteredsService.errors);

  const {
    firstName = '',
    lastName = '',
  } = beneficiary || {};

  const lastNameUpperCased = useMemo((): string => (
    lastName.toUpperCase()
  ), [lastName]);

  const handleUpdateState = (data: BeneficiaryServiceData): void => {
    const { isLoading, errors, beneficiary } = data;
    setLoading(isLoading);
    if (!isLoading && errors.length === 0) setBeneficiary(beneficiary);
  };

  useEffect(() => BeneficiaryService.onChange(handleUpdateState), []);

  useEffect(() => {
    BeneficiaryService.fetchOne(registered.person.id, language);
  }, [registered, language]);

  const handleUpdateRegisteredState = (data: RegisteredsServiceData): void => {
    setIsRegisteredLoading(data.isLoadingRegistered);
    setGroupClassRegistered(data.groupClassRegistered);
    setPaymentErrors(data.errors);
  };

  useEffect(() => RegisteredsService.onChange(handleUpdateRegisteredState), []);

  const handleChange = useCallback((e: SyntheticEvent<HTMLInputElement>): void => {
    const newGroupClassRegistered = ObjectHelpers.deepClone(groupClassRegistered);
    newGroupClassRegistered[e.currentTarget.name] = e.currentTarget.value;
    setGroupClassRegistered(newGroupClassRegistered);
  }, [groupClassRegistered]);

  const updatePersonRegistered = useCallback((newGroupClassRegistered: UserRegistered): void => {
    const customError: Error[] = [];

    const commentRequired = newGroupClassRegistered.paymentConditions?.some((paymentCondition) => (
      paymentCondition.code === CONDITION_TYPE_SUBSIDY || paymentCondition.code === CONDITION_TYPE_OTHER
    )) || false;

    if (commentRequired && !newGroupClassRegistered.paymentComment) {
      const error = { propertyPath: 'paymentComment', code: ERROR_IS_BLANK, message: '' };
      customError.push(error);
    }

    if (newGroupClassRegistered.paymentSchedules && newGroupClassRegistered.paymentSchedules.length > 0) {
      newGroupClassRegistered.paymentSchedules.forEach((paymentSchedule, index) => {
        if (paymentSchedule.costAdjust === undefined) {
          const error = { propertyPath: `paymentSchedules[${ index }].costAdjust`, code: ERROR_IS_BLANK, message: '' };
          customError.push(error);
        }
      });
    }

    if (customError.length === 0) {
      RegisteredsService.updateRegistered(newGroupClassRegistered, language)
        .then(() => Toast.success(t('groupClasses.groupClass.peoples.form.wellUpdated')))
        .catch(() => {})
        .then(() => navigate(PARTICIPANTS_REGISTERED_ID.replace(':classId', classId).replace(':registeredId', String(registered.id))))
      ;
    }
    setPaymentErrors(customError);
  }, [t, language, classId, registered, setPaymentErrors]);

  const handleClickFooter = useCallback((): void => {
    if (groupClassRegistered) {
      updatePersonRegistered(groupClassRegistered);
    }
  }, [updatePersonRegistered, groupClassRegistered]);

  const footerButtons: React$Node = useMemo((): React$Node => (
    <div className="button-edit-mode">
      <Link
        to={ PARTICIPANTS_REGISTERED_ID.replace(':classId', classId).replace(':registeredId', String(registered.id)) }
        className="button button-tertiary button-cancel button-position"
      >
        { t('common.cancel') }
      </Link>
      <Button
        className="button-position"
        onClick={ handleClickFooter }
        type={ buttonConstants.PRIMARY }
      >
        { t('groupClasses.groupClass.peoples.form.confirmButton') }
      </Button>
    </div>
  ), [classId, registered, language, t, handleClickFooter]);

  const groupClassActivityName = groupClassRegistered?.groupClass?.activity?.name || '';
  const groupClassCity = groupClassRegistered?.groupClass?.address.city || '';
  const groupClassPostCode = groupClassRegistered?.groupClass?.address.postalCode || '';

  const groupClassDayTime = useMemo((): string => {
    if (groupClassRegistered && groupClassRegistered.groupClass) {
      const { day, startTime, endTime } = groupClassRegistered.groupClass;
      return DateService.localDateAndTimeDisplay(day, startTime, endTime, language);
    }
    return '';
  }, [groupClassRegistered, language]);

  return (
    <>
      <FormContainer
        title={
          <>
            <ParticipantIndicator isBeneficiary extraCss="participant-tab" />
            { !isLoading && (firstName + ' ' + lastNameUpperCased) }
          </>
        }
        className="beneficiary-request-card"
        isLoading={ (isLoading || isRegisteredLoading) }
        footerButtonActions={ footerButtons }
      >
        <div className="block-infos">
          <div className="block-title">
            <div className="flex wrap">
              <h2 className="title-two">
                { `${ t('groupClasses.groupClass.peoples.form.paiementBlocTitle') }
                ${ groupClassActivityName } - ` }
              </h2>
              <h2 className="uppercase-first title-two">{ `${ groupClassPostCode } ${ groupClassCity }` }</h2>
              <h2 className="title-two">{ groupClassDayTime }</h2>
            </div>
          </div>
          <div className="separator"/>
          <div className="container">
            <div className="columns">
              <div className="column is-12">
                <div className="content is-open">
                  <PaymentForm
                    data={ groupClassRegistered }
                    onChange={ handleChange }
                    onChangeData={ setGroupClassRegistered }
                    groupClass={ groupClass }
                    errors={ paymentErrors }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </FormContainer>
    </>
  );
};

export default BeneficiaryEditPayment;
