// @flow
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import type { NavigationTab } from '@shared/Navigation/NavigationPills/types';

type Props = {
  navigationTabs: NavigationTab[],
  isSelectedKey: string,
};

const NavigationPills = (props: Props): React$Node => {
  const { navigationTabs, isSelectedKey } = props;
  const { t } = useTranslation();
  return (
    <div className="navigation-pills">
      { navigationTabs.map((navigationTab, index) => {
        const isFirstTab = index === 0;
        const isLastTab = index === navigationTabs.length -1;
        const isDisabled = navigationTab.isDisabled;
        const navLinkClassName = clsx({
          'pills-link': true,
          'has-text-centered': true,
          'is-disabled': isDisabled,
          'is-selected': isSelectedKey === navigationTab.isSelectedKey,
          'left-pills': isFirstTab,
          'middle-pills': !isFirstTab && !isLastTab,
          'right-pills': isLastTab,
        });
        return (
          <div className="is-flex" key={ index }>
            { isDisabled
              ? <div className={ navLinkClassName } >{ t(navigationTab.translationKey) }</div>
              : (
                <NavLink to={ navigationTab.url } className={ navLinkClassName }>
                  { t(navigationTab.translationKey) }
                </NavLink>
              )
            }
          </div>
        );
      }) }
    </div>
  );
};

export default NavigationPills;
