// @flow

class UrlHelpers {
  /**
   * getFullUri
   * @param {string} path
   * @returns {string}
   */
  static getFullUri(path: string): string {
    return window && window?.location
      ? `${ window.location.origin }${ path }`
      : path;
  }

  /**
   * getQueryStringParametersFromObject
   * @param {Object} params
   * @returns {string}
   */
  static getQueryStringParametersFromObject(params: Object): string {
    return Object.keys(params)
      .map((key) => `${ encodeURIComponent(key) }=${ encodeURIComponent(params[key]) }`)
      .join('&');
  }
}

export default UrlHelpers;
