// @flow
import Header from '@header/components/Header';
import MainFooter from '@footer/components/MainFooter';
import PaymentFormProvider from '@payment/components/PaymentFormProvider';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { WEB_PATHS } from '@app/constants/paths';
import useAuth from '@user/hooks/useAuth';
const { LOGIN,
  SIEL_BLEU_USER_PASSWORD_UPDATE,
  LOGIN_RETURN_SINGLE_SIGN_ON,
  LOGOUT_RETURN_SINGLE_SIGN_ON,
  RESET_KEY_REQUEST } = WEB_PATHS;

type Props = {
  children: React$Node,
};

const Page = (props: Props): React$Node => {
  const { children } = props;
  const location = useLocation();
  const [displayHeader, setDisplayHeader] = useState<boolean>(false);
  const [displayFooter, setDisplayFooter] = useState<boolean>(false);
  const { user, isConnected } = useAuth();

  const pathsWithoutLayout = [
    LOGIN,
    SIEL_BLEU_USER_PASSWORD_UPDATE,
    LOGIN_RETURN_SINGLE_SIGN_ON,
    LOGOUT_RETURN_SINGLE_SIGN_ON,
    RESET_KEY_REQUEST,
  ];

  const handleDisplayLayout = useCallback((): void => {
    if (pathsWithoutLayout.includes(location.pathname)) {
      setDisplayHeader(false);
      setDisplayFooter(false);
    } else {
      setDisplayHeader(true);
      if (user && isConnected) {
        setDisplayFooter(false);
      } else {
        setDisplayFooter(true);
      }
    }
  }, [location, user, isConnected]);

  useEffect(() => {
    handleDisplayLayout();
  }, [handleDisplayLayout]);

  return (
    <>
      {
        displayHeader
        && (
          <Header />
        )
      }
      <main className="getphy-site">
        <div className="page">{ children }</div>
      </main>
      {
        displayFooter
        && (
          <MainFooter />
        )
      }
      <PaymentFormProvider />
    </>
  );
};

export default Page;
