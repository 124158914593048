// @flow
import { useTranslation } from 'react-i18next';
import HubspotEmbeddedForm from '@root/src/Hubspot/components/HubspotEmbeddedForm';
import hubspotForm from '@root/src/Hubspot/hubspotForm';

const Links = (): React$Node => {
  const { t, i18n: { language } } = useTranslation();

  const footerLinks = [
    {
      href: t('footer.siel-bleu.link'),
      render: t('footer.siel-bleu'),
      target: '_blank',
      rel: 'noreferrer',
    },
    {
      href: t('footer.cgu.link'),
      render: t('footer.cgu'),
      target: '_blank',
      rel: 'noreferrer',
    },
    {
      href: t('footer.privacy.link'),
      render: t('footer.privacy'),
      target: '_blank',
      rel: 'noreferrer',
    },
  ];

  return (
    <div className="links">
      {
        footerLinks.map((l) => <a key={ l.render } target={ l.target || null } rel={ l.rel || null } href={ l.href }>{ l.render }</a>)
      }
      <HubspotEmbeddedForm
        formId={ hubspotForm[language]?.formId || hubspotForm.fr.formId }
        fallbackUrl={ hubspotForm[language]?.url || hubspotForm.fr.formId }>
        { t('common.contact') }
      </HubspotEmbeddedForm>
    </div>
  );
};

export default Links;
