// @flow
import type { Listener } from '@core/types';

type RemoveListener = (listener: Listener) => void;
type AddListener = (listener: Listener) => RemoveListener;
type Trigger = (message: any) => void;

/**
 * Basic event emitter implementation
 *
 * Warning : !!! removeListener function must be called to avoid memory leak !!!
 *
 * Advice : use a Custom Hook with a useEffect inside to add and remove listener
 *          then use this hook in the components to listen on events without the need to add and remove listener inside the component
 *
 */
class EventEmitter {
  constructor() {
    this.listeners = [];
  }

  listeners: Listener[];

  addListener: AddListener = (listener: Listener): RemoveListener => {
    this.listeners.push(listener);
    return () => this.removeListener(listener);
  };

  removeListener: RemoveListener = (listener: Listener): void => {
    this.listeners.splice(this.listeners.indexOf(listener), 1);
  };

  trigger: Trigger = (message: any): void => {
    for (let i = 0; i < this.listeners.length; i++) {
      this.listeners[i](message);
    }
  };
}

export default EventEmitter;
