// @flow
import type { PaginationData } from '@api/types';
import type { Listener } from '@core/types';
import type { PersonType, People, PersonPayload } from '@person/type';

import EventEmitter from '_common/services/EventEmitter';
import ApiService from '@api/service';
import { hydratePersonFromPayload } from '@person/helpers/PersonUtils';
import { getPageNumberFromUrl } from '_common/services/RequestUtils';

import { DEFAULT_PAGINATION_DATA } from '@api/constants';
import { API_PATHS } from '@app/constants/paths';

const { PEOPLE } = API_PATHS;

export type PersonRequestsServiceData = {
  requests: People,
  paginationData: PaginationData | null,
  isLoading: boolean,
};

type Params = {
  currentPage: number,
  fullName: string | null,
  personTypes: Array<PersonType>,
  locale: string,
  groupClass?: number | null,
};

type UpdateValues = (newRequests: People, newIsLoading: boolean, newPaginationData: PaginationData | null) => void;
type FetchAll = (language: string, params: Params) => void;
type OnChange = (listener: Listener) => Function;
type OnClean = () => void;
type Trigger = () => void;

let sourceAll = ApiService.createToken();

//TODO: refacto search/page management + move search.length > 3 into fetchAll
class PersonRequestsService {
  constructor() {
    this.eventEmitter = new EventEmitter();

    this.paginationData = null;
    this.search = '';
    this.page = 1;
    this.isLoading = false;
    this.requests = [];
  }
  paginationData: PaginationData | null;
  requests: People;
  isLoading: boolean;
  search: string;
  page: number;
  eventEmitter: EventEmitter;

  // TODO Refacto getter/setters
  get paginationDataValue(): PaginationData | null {
    return this.paginationData;
  }

  get searchValue(): string {
    return this.search;
  }

  get pageValue(): number {
    return this.page;
  }

  get requestsValues(): People {
    return this.requests;
  }

  get isLoadingValue(): boolean {
    return this.isLoading;
  }

  updateValues: UpdateValues = (newRequests: People, newIsLoading: boolean, newPaginationData: PaginationData | null): void => {
    this.requests = newRequests;
    this.isLoading = newIsLoading;
    this.paginationData = newPaginationData;
    this.#trigger();
  };

  fetchAll: FetchAll = (language: string, params: Params): void => {
    const { currentPage, fullName, personTypes, locale, groupClass } = params;

    sourceAll.cancel();
    sourceAll = ApiService.createToken();

    this.updateValues([], true, null);

    if (fullName && fullName !== this.search) {
      this.search = fullName;
    }
    if (currentPage !== this.page) {
      this.page = currentPage;
    }

    ApiService.request({
      method: 'get',
      url: PEOPLE,
      cancelToken: sourceAll.token,
      headers: {
        'Accept-Language': language,
      },
      params: {
        fullname: fullName ?? undefined,
        page: currentPage,
        person_type: personTypes ?? undefined,
        language: locale,
        groupClass,
      },
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;

          const peopleData: People = data['hydra:member']
            .map((request: PersonPayload) => hydratePersonFromPayload(request));

          const payloadCurrentPage = (data['hydra:view'] && getPageNumberFromUrl(data['hydra:view']['@id'])) || 1;
          const lastPage = (data['hydra:view'] && getPageNumberFromUrl(data['hydra:view']['hydra:last'])) || 1;
          const paginationData: PaginationData = {
            lastPage,
            currentPage: payloadCurrentPage,
            totalItems: data['hydra:totalItems'],
          };

          this.updateValues(peopleData, false, paginationData);
        }
      })
      .catch(() => {
        const additionalData = DEFAULT_PAGINATION_DATA;
        this.updateValues(this.requests, false, additionalData);
      });
  };

  onChange: OnChange = (listener: Listener) => {
    const listenerFunction = this.eventEmitter.addListener(listener);
    this.#trigger();
    return listenerFunction;
  };

  onClean: OnClean = (): void => {
    this.updateValues([], false, null);
  };

  /**
   * @private
   */
  #trigger: Trigger = (): void => {
    this.eventEmitter.trigger({
      requests: this.requests,
      paginationData: this.paginationData,
      isLoading: this.isLoading,
    });
  };
}

const instance: PersonRequestsService = new PersonRequestsService();
export default instance;
