// @flow
import { useMemo, useCallback } from 'react';
import clsx from 'clsx';

import type { Colors, Sizes } from '@core/types';

export type InputProps = {
  borderColor?: Colors | null,
  className: string,
  inputSize?: Sizes,
  isFocused?: boolean,
  isHovered?: boolean,
  isLoading?: boolean,
  isRounded?: boolean,
  isStatic?: boolean,
  type?: string,
  unauthorizedKeys?: RegExp,
  readOnly?: boolean,
};

const Input = (props: InputProps): React$Node => {
  const {
    borderColor,
    className,
    inputSize,
    isFocused,
    isHovered,
    isLoading,
    isRounded,
    isStatic,
    type,
    unauthorizedKeys,
    readOnly,
    ...others
  } = props;

  const inputClassName = useMemo((): string => clsx({
    input: true,
    'is-focused': isFocused,
    'is-hovered': isHovered,
    'is-loading': isLoading,
    'is-rounded': isRounded,
    'is-static': isStatic,
    [className]: !!className,
    [String(borderColor)]: !!borderColor,
    [String(inputSize)]: !!inputSize,
  }), [borderColor, className, inputSize, isFocused, isHovered, isLoading, isRounded, isStatic]);

  const handleKeyDown = useCallback((event: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (unauthorizedKeys?.test(event.key)) {
      event.preventDefault();
      event.stopPropagation();
    }
  }, [unauthorizedKeys]);

  return (
    <input
      { ...others }
      className={ inputClassName }
      type={ type }
      onKeyDown={ handleKeyDown }
      readOnly={ readOnly }
    />
  );
};

Input.defaultProps = {
  className: 'input',
  readOnly: false,
  isFocused: false,
  isHovered: false,
  isLoading: false,
  isRounded: false,
  isStatic: false,
  type: 'text',
};

export default Input;
