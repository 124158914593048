// @flow
import clsx from 'clsx';
import { useState, useMemo } from 'react';

import ImageLoader from '@shared/Image/ImageLoader';
import { isValidUrl } from '_common/services/CommonUtils';

type Props = {
  src: string,
  alt?: string,
  className: string,
  onClick: () => void,
};

const Image = (props: Props): React$Node => {
  const {
    src,
    alt,
    className,
    onClick,
  } = props;

  const [ loaded, setLoaded ] = useState<boolean>(true);
  const [ error, setError ] = useState<boolean>(false);

  const baseAssets = '__ASSET_PATH__/assets';
  const noPictureImg = `${ baseAssets }/no-picture.svg`;

  const isUrl = useMemo((): boolean => isValidUrl(src), [src]);

  const getSrc = useMemo((): string => {
    if (src){
      return isUrl ? src : `${ baseAssets }/${ src }`;
    }
    return noPictureImg;
  }, [src]);

  const onImageLoaded = (): void => {
    setLoaded(false);
    setError(false);
  };

  const onImageError = (): void => {
    setTimeout(() => {
      setLoaded(false);
      setError(true);
    }, 500);
  };

  const indexClassName = useMemo((): string => clsx({
    'image-component': true,
    [String(className)]: !!className,
  }), [className]);

  const imageClass = useMemo((): string =>clsx({
    'image': true,
    'hide-image': !!loaded || !!error,
  }), [loaded, error]);

  if (!isUrl){
    if (src){
      return (
        <img
          className={ className }
          src={ getSrc }
          alt={ alt }
          onClick={ onClick }
        />
      );
    }
    return (
      <div className={ indexClassName }>
        <div className="image-loader-parent">
          <ImageLoader inError={ true } />
        </div>
      </div>
    );
  }

  return (
    <div className={ indexClassName }>
      <img
        className={ imageClass }
        src={ getSrc }
        alt={ alt }
        onClick={ onClick }
        onLoad={ onImageLoaded }
        onError={ onImageError }
      />
      { (loaded || error) && (
        <div className="image-loader-parent">
          <ImageLoader inError={ error } />
        </div>
      ) }
    </div>
  );
};

Image.defaultProps = {
  className: 'image-component',
  onClick: () => {},
};

export default Image;
