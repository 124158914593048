// @flow
import { useEffect, useState } from 'react';

import type { Activity, ActivityPayload } from '@activity/types';
import ApiService from '@api/service';
import { parseActivity } from '@activity/services/ActivitiesUtils';

import { API_PATHS } from '@app/constants/paths';

const { ACTIVITIES } = API_PATHS;

type UseActivitiesResponse = [
  Activity[], // activities field
  boolean, // isLoading field
];

let source = ApiService.createToken();

const useActivities = (language: string): UseActivitiesResponse => {
  const [ activities, setActivities ] = useState<Activity[]>([]);
  const [ isLoading, setLoading ] = useState<boolean>(false);

  useEffect(() => {
    source.cancel();
    source = ApiService.createToken();

    setLoading(true);

    ApiService.request({
      method: 'get',
      url: ACTIVITIES,
      cancelToken: source.token,
      headers: {
        'Accept-Language': language,
      },
      params: {
        pagination: 'false',
      },
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;

          const activitiesArray: Activity[] = data['hydra:member'].map((activity: ActivityPayload) => (
            parseActivity(activity)
          ));

          setActivities(activitiesArray);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    return () => {
      source.cancel();
    };
  }, [language]);

  return [activities, isLoading];
};

export default useActivities;
