// @flow
import type { Listener } from '@core/types';
import EventEmitter from '_common/services/EventEmitter';
import ApiService from '@api/service';

export type MessageServiceData = {
  isLoading: boolean,
};

type DataFormRecipients = {
  message: string,
  recipients: string[],
  object?: string,
};

type DataFormBeneficiaries = {
  beneficiaries: string[],
  sessions: string[],
  object?: string,
};

type DataForm = DataFormRecipients | DataFormBeneficiaries;

type UpdateValues = (newIsLoading: boolean) => void;
type OnChange = (listener: Listener) => Function;
type SendMessage = (message: string, dataForm: DataForm) => Promise<void>;

class MessageService {
  constructor() {
    this.eventEmitter = new EventEmitter();
    this.isLoading = false;
  }

  eventEmitter: EventEmitter;
  isLoading: boolean;

  get isLoadingValue(): boolean {
    return this.isLoading;
  }

  updateValues: UpdateValues = (newIsLoading: boolean): void => {
    this.isLoading = newIsLoading;
    this.#trigger();
  };

  sendMessage: SendMessage = (message: string, dataForm: DataForm): Promise<void> => {
    this.updateValues(true);
    return ApiService
      .request({ method: 'post', url: message, data: dataForm })
      .finally(() => this.updateValues(false));
  };

  onChange: OnChange = (listener: Listener): Function => {
    const listenerFunction = this.eventEmitter.addListener(listener);
    this.#trigger();
    return listenerFunction;
  };

  /**
   * @private
   */
  #trigger = async () => {
    await this.eventEmitter.trigger({
      isLoading: this.isLoading,
    });
  };
}

const instance: MessageService = new MessageService();
export default instance;
