// @flow
/* eslint-disable max-len */
import clsx from 'clsx';

type Props = {
  className: string,
  onClick: () => void,
};

const IconMinus = (props: Props): React$Node => {
  const { className, onClick } = props;
  const newProps = {
    className: clsx({ icon: true, 'icon-minus': true, [className]: !!className }),
    preserveAspectRatio: 'xMidYMid',
    role: 'img',
    viewBox: '0 0 448 512',
    xmlns: 'http://www.w3.org/2000/svg',
    onClick,
  };

  return (
    <svg { ...newProps }>
      <path d="M400 288h-352c-17.69 0-32-14.32-32-32.01s14.31-31.99 32-31.99h352c17.69 0 32 14.3 32 31.99S417.7 288 400 288z" />
    </svg>
  );
};

IconMinus.defaultProps = {
  className: 'icon-minus',
  onClick: () => {},
};

export default IconMinus;