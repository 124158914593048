// @flow
import type { Error, Listener, QueryParams } from '@core/types';
import type { PaymentCheque } from '@payment/types';
import EventEmitter from '_common/services/EventEmitter';
import ApiService from '@api/service';
import { hydrateChequeFromPayload } from '@payment/helpers/PaymentChequeUtils';

import { API_PATHS } from '@app/constants/paths';

export type PaymentChequeServiceData = {
  cheque: PaymentCheque | null,
  cheques: Array<PaymentCheque>,
  isLoading: boolean,
  errors: Error[],
};

type UpdateValues = (cheque: PaymentCheque | null, cheques: Array<PaymentCheque>, newIsLoading: boolean, newErrors: Error[]) => void;
type Reset = () => void;
type CleanErrors = () => void;
type FetchAll = (params: QueryParams) => void;
type FetchOne = (id: number) => void;
type Create = ( reference: string, groupClassRegisteredPaymentSchedule: number, amount: number, bankDeposit: ?number ) => Promise<PaymentCheque | boolean>;
type Update = ( id: number, reference: string, groupClassRegisteredPaymentSchedule: number, amount: number, bankDeposit: number | null ) => Promise<PaymentCheque>;
type Remove = (id: number) => Promise<boolean>;
type OnChange = (listener: Listener) => Function;
type Trigger = () => void;

const { PAYMENT_CHEQUES } = API_PATHS;

let source = ApiService.createToken();

class PaymentChequeService {
  constructor() {
    this.eventEmitter = new EventEmitter();

    this.cheque = null;
    this.cheques = [];
    this.isLoading = false;
    this.errors = [];
  }

  eventEmitter: EventEmitter;

  cheque: PaymentCheque | null;
  cheques: Array<PaymentCheque>;
  isLoading: boolean;
  errors: Error[];

  get getCheque(): PaymentCheque | null {
    return this.cheque;
  }

  get getCheques(): Array<PaymentCheque> {
    return this.cheques;
  }

  get isLoadingValue(): boolean {
    return this.isLoading;
  }

  get errorValues(): Error[] {
    return this.errors;
  }

  updateValues: UpdateValues = (newCheque: PaymentCheque | null, newCheques: Array<PaymentCheque>, newIsLoading: boolean, newErrors: Error[] = []): void => {
    this.cheque = newCheque;
    this.cheques = newCheques;
    this.isLoading = newIsLoading;
    this.errors = newErrors;
    this.#trigger();
  };

  reset: Reset = () => {
    this.updateValues(null, [], false, []);
  };

  cleanErrors: CleanErrors = () => {
    this.updateValues(this.cheque, this.cheques, false, []);
  };

  fetchAll: FetchAll = (params: QueryParams): void => {
    source.cancel();
    source = ApiService.createToken();
    let chequesArray = [];

    const { reference, page = 1 } = params;

    this.updateValues(this.cheque, [], true, []);

    ApiService.request({
      method: 'get',
      url: `${ PAYMENT_CHEQUES }`,
      cancelToken: source.token,
      params: {
        page,
        reference,
      },
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;
          chequesArray = data['hydra:member'].map(
            (chequePayload) => hydrateChequeFromPayload(chequePayload),
          );
          this.updateValues(this.cheque, chequesArray, false, this.errors);
        } else {
          this.updateValues(this.cheque, [], false, this.errors);
        }
      })
      .catch(() => {
        this.updateValues(this.cheque, this.cheques, false, this.errors);
      });
  };

  fetchOne: FetchOne = (id: number): void => {
    this.updateValues(null, this.cheques, true, []);

    ApiService.request({
      method: 'get',
      url: `${ PAYMENT_CHEQUES }/${ id }`,
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;
          const chequeFromPayload = hydrateChequeFromPayload(data);

          this.updateValues(chequeFromPayload, this.cheques, false, this.errors);
        }
        else {
          this.updateValues(this.cheque, this.cheques, false, this.errors);
        }
      })
      .catch(() => {
        this.updateValues(this.cheque, this.cheques, false, this.errors);
      });
  };

  create: Create = (
    reference: string,
    groupClassRegisteredPaymentSchedule: number,
    amount: number,
    bankDeposit: ?number,
  ): Promise<PaymentCheque | boolean> => {
    this.updateValues(this.cheque, this.cheques, true, []);

    return ApiService.request({
      method: 'post',
      url: `${ PAYMENT_CHEQUES }`,
      data: {
        reference,
        groupClassRegisteredPaymentSchedule,
        amount,
        bankDeposit,
      },
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;
          const newCheque = hydrateChequeFromPayload(data);

          this.updateValues(newCheque, this.cheques, false, this.errors);
          return newCheque;
        }

        this.updateValues(this.cheque, this.cheques, false, this.errors);
        return Promise.reject(false);
      })
      .catch((error) => {
        const errorViolations = error.response?.data?.violations || this.errors;
        this.updateValues(this.cheque, this.cheques, false, errorViolations);
        return Promise.reject(false);
      });
  };

  update: Update = (
    id: number,
    reference: string,
    groupClassRegisteredPaymentSchedule: number,
    amount: number,
    bankDeposit: number | null,
  ): Promise<PaymentCheque> => {
    this.updateValues(this.cheque, this.cheques, true, []);

    return ApiService.request({
      method: 'put',
      url: `${ PAYMENT_CHEQUES }/${ id }`,
      data: {
        reference,
        groupClassRegisteredPaymentSchedule,
        amount,
        bankDeposit,
      },
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;
          const updatedCheque = hydrateChequeFromPayload(data);

          this.updateValues(updatedCheque, this.cheques, false, this.errors);
          return updatedCheque;
        }

        this.updateValues(this.cheque, this.cheques, false, this.errors);
        return Promise.reject();
      })
      .catch((error) => {
        const errorViolations = error.response?.data?.violations || this.errors;
        this.updateValues(this.cheque, this.cheques, false, errorViolations);
        return Promise.reject();
      });
  };

  remove: Remove = (id: number): Promise<boolean> => {
    this.updateValues(this.cheque, this.cheques, true, this.errors);

    return ApiService.request({
      method: 'delete',
      url: `${ PAYMENT_CHEQUES }/${ id }`,
    })
      .then((payload) => {
        const { status } = payload;
        if (status === 204) {
          this.updateValues(this.cheque, this.cheques, false, this.errors);
          return true;
        }

        return false;
      })
      .catch(() => {
        this.updateValues(this.cheque, this.cheques, false, this.errors);
        return false;
      });
  };

  onChange: OnChange = (listener: Listener): Function => {
    const listenerFunction = this.eventEmitter.addListener(listener);
    this.#trigger();
    return listenerFunction;
  };

  /**
   * @private
   */
  #trigger: Trigger = (): void => {
    this.eventEmitter.trigger({
      cheque: this.cheque,
      cheques: this.cheques,
      isLoading: this.isLoading,
      errors: this.errors,
    });
  };
}

const instance: PaymentChequeService = new PaymentChequeService();
export default instance;
