// @flow
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '@shared/Modal/Modal';
import Button, { buttonConstants } from '@shared/Button';

type Props = {
  isActive: boolean,
  toggleModal: () => void,
  onClickYes: () => void,
  children: React$Node,
  title: string,
};

const ModalYesNo = (props: Props): React$Node => {
  const {
    isActive,
    toggleModal,
    onClickYes,
    children,
    title,
  } = props;

  const { t } = useTranslation();

  const footerModalYesNo: React$Node = useMemo((): React$Node => (
    <footer className="modal-footer-yes-no">
      <Button
        type={ buttonConstants.TERTIARY }
        className="button-modal"
        onClick={ toggleModal }
      >
        { t('common.no') }
      </Button>
      <Button
        type={ buttonConstants.SECONDARY }
        className="button-modal"
        onClick={ onClickYes }
      >
        { t('common.yes') }
      </Button>

    </footer>
  ), [toggleModal, onClickYes, t]);

  return (
    <Modal
      isActive={ isActive }
      isClipped
      onClose={ toggleModal }
      title={ title }
      footer={ footerModalYesNo }
    >
      <div>
        { children }
      </div>
    </Modal>
  );
};

export default ModalYesNo;
