// @flow
import type {
  UserRegistered,
  UserRegisteredPayload,
  PaymentSchedule,
  PaymentCondition,
  ShortRegisteredPayload,
  ShortRegistered,
} from '@groupClass/types';
import { hydratePersonFromPayload } from '@person/helpers/PersonUtils';
import { formatShortGroupClassFromPayload } from '@groupClass/helpers/GroupClassesUtils';
import { hydratePaymentScheduleFromPayload } from '@payment/helpers/PeriodPaymentUtils';

export const hydrateRegisteredFromPayload = (userRegistered: UserRegisteredPayload): UserRegistered => {
  const paymentScheduleFormatted: PaymentSchedule[] = userRegistered.paymentSchedules.map(
    (paymentSchedule) => hydratePaymentScheduleFromPayload(paymentSchedule),
  );

  const formatGroupClassRegistered = userRegistered?.groupClassesRegisteredTo?.map((groupClass) => (
    formatShortGroupClassFromPayload(groupClass)
  )) || [];

  const paymentsConditionFormatted: PaymentCondition[] = userRegistered?.paymentConditions?.map((paymentCondition) => ({
    id: paymentCondition.id,
    name: paymentCondition.name,
    amount: paymentCondition.amount,
    code: paymentCondition.code,
    frequency: paymentCondition.frequency,
  })) || [];

  return {
    id: userRegistered.id,
    annualFeesRequested: userRegistered.annualFeesRequested,
    groupClass: formatShortGroupClassFromPayload(userRegistered.groupClass),
    groupClassesRegisteredTo: formatGroupClassRegistered,
    paymentComment: userRegistered.paymentComment,
    paymentConditions: paymentsConditionFormatted,
    paymentFrequency: userRegistered.paymentFrequency,
    paymentInfos: userRegistered.paymentInfos,
    paymentSchedules: paymentScheduleFormatted,
    person: hydratePersonFromPayload(userRegistered.person),
    subscriptionPaidAt: userRegistered.subscriptionPaidAt ? new Date(userRegistered.subscriptionPaidAt) : null,
    unsubscribedAt: userRegistered.unsubscribedAt ? new Date(userRegistered.unsubscribedAt) : null,
  };
};

export const hydrateShortRegisteredFromPayload = (user: ShortRegisteredPayload): ShortRegistered => ({
  id: user.id,
  person: hydratePersonFromPayload(user.person),
  unsubscribedAt: user.unsubscribedAt ? new Date(user.unsubscribedAt) : null,
});
