// @flow
import axios, { type Axios, type AxiosXHRConfig, type CancelTokenSource, type AxiosXHR } from 'axios';

import i18n from '@app/i18n/i18n';
import AuthService from '@user/services/AuthService';
import TokenService from '@user/services/TokenService';
import { getLanguageFormat } from '_common/services/LanguageUtils';
import ObjectHelpers from '@helpers/ObjectHelpers';

const request: Axios = axios.create({
  baseURL: process.env.REACT_APP_ENTRYPOINT,
});

request.interceptors.request.use(
  (config) => {
    const user = AuthService.user;
    const configUpdated = {
      ...ObjectHelpers.deepClone(config),
      cancelToken: config.cancelToken,
    };
    configUpdated.headers = config.headers || {};
    const token = TokenService.getToken();
    const tokenIsValid = token && TokenService.getTokenValidity(token);
    if (token && !tokenIsValid) {
      AuthService.disconnect();
    }
    if (token) {
      configUpdated.headers.Authorization = `Bearer ${ token }`;
    }
    const { language } = i18n;
    if (user) {
      configUpdated.headers['Accept-Language'] = getLanguageFormat(user.language);
    } else if (language) {
      configUpdated.headers['Accept-Language'] = getLanguageFormat(language);
    } else {
      configUpdated.headers['Accept-Language'] = getLanguageFormat('fr_FR');
    }

    return configUpdated;
  },
  (error) => Promise.reject(error),
);

request.interceptors.response.use(
  (response) => response,
  (error) => {
    const tokenIsValid = TokenService.getTokenValidity();

    if (!tokenIsValid || error?.response?.status === 401) {
      AuthService.disconnect();
    }

    return Promise.reject(error);
  },
);

/**
 * @type Class
 * Static class used to manage request and interactions with axios.
 */
class ApiService {
  /**
   * request
   * @type Static
   * @param {AxiosXHRConfig<any>} config
   * @return {Promise<AxiosXHR<any>>}
   */
  static request(config: AxiosXHRConfig<any>): Promise<AxiosXHR<any>> {
    return request(config);
    // TODO manage cancelToken and cancelState here
  }

  /**
   * createToken
   * @type Static
   * @returns {CancelTokenSource}
   */
  static createToken(): CancelTokenSource {
    // TODO: the CancelToken is deprecated since the axios's version 0.22. Needs to replace by the
    // AbortController. It will impact all requests. Refer to the official documentation
    return axios.CancelToken.source();
  }

  /**
   * isCancel
   * @type Static
   * @param {Object} error
   * @returns {boolean}
   */
  static isCancel(error: Object): boolean {
    return axios.isCancel(error);
  }
}

export default ApiService;
