// @flow
import type { Listener, RequestStatusesType } from '@core/types';
import EventEmitter from '_common/services/EventEmitter';
import FileSaver from 'file-saver';

import ApiService from '@api/service';

import { type CreateSharedSessionData, SharedSession } from '@groupSend/types';
import { parseSharedSessionFromPayload } from '@groupSend/helpers/GroupSendUtils';

import { RequestStatuses } from '@core/constants';
import { API_PATHS } from '@app/constants/paths';
const { SHARED_SESSION, SHARED_SESSIONS_FOR_BENEFICIARY, SHARED_SESSIONS_DOWNLOAD } = API_PATHS;

type ResetCountNewSharedSessionForBeneficiary = () => void;
type UpdateValues = (
  newListSharedSession: SharedSession[],
  newIsSharedSessionLoading: boolean,
  newCountNewSharedSessionForBeneficiary: number,
  requestStatusSharedSessions: RequestStatusesType,
  ) => void;
type GetSharedSessionForBeneficiary = (beneficiaryId: number, language: string) => Promise<number>;
type GetSharedSessionDownload = (sharedSessionId: number, fileId: number, language: string) => Promise<boolean>;
type CreateSharedSession = (data: CreateSharedSessionData) => Promise<boolean>;
type OnChange = (listener: Listener) => Function;

let sourceAll = ApiService.createToken();
class SharedSessionService {
  constructor() {
    this.eventEmitter = new EventEmitter();
    this.sharedSessions = [];
    this.isSharedSessionLoading = false;
    this.countNewSharedSessionForBeneficiary = 0;
    this.requestStatusSharedSessions = RequestStatuses.IDLE;
  }

  eventEmitter: EventEmitter;
  sharedSessions: SharedSession[];
  isSharedSessionLoading: boolean;
  countNewSharedSessionForBeneficiary: number;
  requestStatusSharedSessions: RequestStatusesType;

  get sharedSessionsValues(): SharedSession[] {
    return this.sharedSessions;
  }

  get countNewSharedSessionForBeneficiaryValue(): number {
    return this.countNewSharedSessionForBeneficiary;
  }

  get requestStatusSharedSessionsValue(): RequestStatusesType {
    return this.requestStatusSharedSessions;
  }

  resetCountNewSharedSessionForBeneficiary: ResetCountNewSharedSessionForBeneficiary = (): void => {
    this.countNewSharedSessionForBeneficiary = 0;
  };

  updateValues: UpdateValues = (
    newListSharedSession: SharedSession[],
    newIsSharedSessionLoading: boolean,
    newCountNewSharedSessionForBeneficiary: number,
    requestStatusSharedSessions: RequestStatusesType,
  ): void => {
    this.sharedSessions = newListSharedSession;
    this.isSharedSessionLoading = newIsSharedSessionLoading;
    this.countNewSharedSessionForBeneficiary = newCountNewSharedSessionForBeneficiary;
    this.requestStatusSharedSessions = requestStatusSharedSessions;
    this.#trigger();
  };

  getSharedSessionForBeneficiary: GetSharedSessionForBeneficiary = (beneficiaryId: number, language: string): Promise<number> => {
    sourceAll.cancel();
    sourceAll = ApiService.createToken();
    this.updateValues([], true, 0, RequestStatuses.PENDING);

    return ApiService.request({
      method: 'get',
      url: SHARED_SESSIONS_FOR_BENEFICIARY.replace(':id', String(beneficiaryId)),
      cancelToken: sourceAll.token,
      headers: {
        'Accept-Language': language,
      },
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;
          let count = 0;
          const sharedSessionArray = data['hydra:member'].map((sharedSession: SharedSession) => {
            if (!sharedSession.isDownloaded) {
              count++;
            }

            return parseSharedSessionFromPayload(sharedSession);
          });
          this.updateValues(sharedSessionArray, false, count, RequestStatuses.SUCCEEDED);

          return data['hydra:totalItems'];
        }

        return Promise.reject();
      })
      .catch(() => {
        this.updateValues(this.sharedSessions, false, 0, RequestStatuses.FAILED);

        return Promise.reject();
      });
  };

  getSharedSessionDownload: GetSharedSessionDownload = (sharedSessionId: number, language: string): Promise<any> => (
    ApiService.request({
      method: 'get',
      url: SHARED_SESSIONS_DOWNLOAD.replace(':id', String(sharedSessionId)),
      headers: {
        'Accept-Language': language,
      },
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;
          FileSaver.saveAs(data.download, data.fileName);
          return Promise.resolve();
        }

        return Promise.reject();
      })
  );

  createSharedSession: CreateSharedSession = (data: CreateSharedSessionData): Promise<any> => (
    ApiService.request({
      method: 'post',
      url: SHARED_SESSION,
      data,
    })
  );

  onChange: OnChange = (listener: Listener): Function => {
    const listenerFunction = this.eventEmitter.addListener(listener);
    this.#trigger();
    return listenerFunction;
  };

  #trigger = () => {
    this.eventEmitter.trigger({
      sharedSessions: this.sharedSessions,
      isSharedSessionLoading: this.isSharedSessionLoading,
      countNewSharedSessionForBeneficiary: this.countNewSharedSessionForBeneficiary,
    });
  };
}

const instance: SharedSessionService = new SharedSessionService();
export default instance;
