// @flow

export const RE_7_DIGITS: RegExp = new RegExp('^[0-9]{7}$');
export const RE_2_DIGITS_COMMA_DOT: RegExp = new RegExp(/^(\d+)(,\d{1,2}|\.\d{1,2})?$/);
export const RE_1_TO_7_DIGITS: RegExp = new RegExp(/^[0-9]{1,7}$/);
export const RE_1_TO_30_TEXT_DIGITS: RegExp = new RegExp(/^[a-zA-Z0-9]{1,30}$/);
export const RE_MATCH_PERIOD_AMOUNT_PATTERN: RegExp = new RegExp(/^periods\[\d+]\.amount$/);
export const RE_MATCH_PAYMENT_SCHEDULE_AMOUNT_PATTERN: RegExp = new RegExp(/^paymentSchedules\[\d+]\.amount$/);
export const RE_MATCH_PERIOD_ENDDATE_PATTERN: RegExp = new RegExp(/^periods\[\d+]\.endDate$/);
export const RE_MATCH_PERIOD_STARTDATE_PATTERN: RegExp = new RegExp(/^periods\[\d+]\.startDate$/);
export const RE_MATCH_COACHES_PATTERN: RegExp = new RegExp(/^coaches\[\d+]\.name$/);
export const RE_ONLY_NUMBERS: RegExp = new RegExp(/^(e)$/i);
export const RE_ONLY_TEXT_NUMBERS: RegExp = new RegExp(/^[^a-zA-Z0-9]$/);
