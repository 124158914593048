// @flow
import type { AxiosXHR } from 'axios';

import type { AdditionalData, Listener, QueryParams, Option } from '@core/types';
import type { GroupClass, GroupClassPayload } from '@groupClass/types';
import type { LocationGroupClass, CoordinatesGroupClass } from '@shared/Locations/types';
import EventEmitter from '_common/services/EventEmitter';
import ApiService from '@api/service';
import AuthService from '@user/services/AuthService';
import { getLanguageFormat } from '_common/services/LanguageUtils';

import { getPageNumberFromUrl } from '_common/services/RequestUtils';
import { parseGroupClassFromPayload } from '@groupClass/helpers/GroupClassesUtils';
import { geocodingRadius } from '@app/constants/locations';
import { hydrateCoordinate } from '@shared/Locations/services/CoordinatesUtils';

import { API_PATHS } from '@app/constants/paths';
import {
  DEFAULT_PAGINATION_DATA,
  DEFAULT_ITEMS_PER_PAGE,
} from '@api/constants';

const { GROUP_CLASSES, GROUP_CLASSES_COORDINATES } = API_PATHS;

export type GroupClassesServiceData = {
  eventEmitter: EventEmitter,
  groupClasses: GroupClass[],
  filters: Object,
  coordinatesAPI: Array<LocationGroupClass>,
  additionalData: AdditionalData,
  isGroupClassLoading: boolean,
  isCoordinatesLoading: boolean,
};

type GetFilters = () => Object;
type GetListDay = (day: Array<Option> | null) => string[];
type GetListActivity = (activity: Array<Option> | null) => string[];
type RequestGroupClasses = (language: string, params: QueryParams) => Promise<AxiosXHR<any>>;
type FetchGroupClasses = (language: string, params: QueryParams) => Promise<void>;
type RequestCoordinates = (language: string, params: QueryParams) => Promise<AxiosXHR<any>>;
type FetchCoordinates = (language: string, params: QueryParams) => void;
type FetchGroupClassesAndCoordinates = (language: string, params: QueryParams) => void;
type SetFilters = (filter: Object) => void;
type ResetService = () => void;
type OnChange = (listener: Listener) => Function;
type Trigger = () => void;
type UpdateValues = (
  newGroupClasses: GroupClass[],
  newFilters: Object,
  newAdditionalData: AdditionalData,
  newCoordinatesAPIValue: Array<LocationGroupClass>,
  newIsGroupClassLoading: boolean,
  newIsCoordinatesLoading: boolean,
) => void;

let sourceGroupClasses = ApiService.createToken();
let sourceCoordinate = ApiService.createToken();

class GroupClassesService {
  constructor() {
    this.eventEmitter = new EventEmitter();
    this.groupClasses = [];
    this.filters = {};
    this.coordinatesAPI = [];
    this.additionalData = DEFAULT_PAGINATION_DATA;
    this.isGroupClassLoading = false;
    this.isCoordinatesLoading = false;
  }
  eventEmitter: EventEmitter;
  groupClasses: GroupClass[];
  filters: Object;
  coordinatesAPI: Array<LocationGroupClass>;
  additionalData: AdditionalData;
  isGroupClassLoading: boolean;
  isCoordinatesLoading: boolean;

  updateValues: UpdateValues = (
    newGroupClasses: GroupClass[],
    newFilters: Object,
    newAdditionalData: AdditionalData,
    newCoordinatesAPIValue: Array<LocationGroupClass>,
    newIsGroupClassLoading: boolean,
    newIsCoordinatesLoading: boolean,
  ): void => {
    this.groupClasses = newGroupClasses;
    this.filters = newFilters;
    this.additionalData = newAdditionalData;
    this.coordinatesAPI = newCoordinatesAPIValue;
    this.isGroupClassLoading = newIsGroupClassLoading;
    this.isCoordinatesLoading = newIsCoordinatesLoading;
    this.#trigger();
  };

  getFilters: GetFilters = (): Object => this.filters;

  getListDay: GetListDay = (day: Array<Option> | null): string[] => {
    let listDay: Array<string> = [];
    if (day && day.length > 0) {
      listDay = day.map((selectedDay: Option) => selectedDay.value);
    } else {
      return [String(day.value)];
    }
    return listDay;
  };

  getListActivity: GetListActivity = (activity: Array<Option> | null): string[] => {
    let listActivity: Array<string> = [];
    if (activity && activity.length > 0) {
      listActivity = activity.map((selectedActivity: Option) => selectedActivity.value);
    } else {
      return activity.value;
    }
    return listActivity;
  };

  requestGroupClasses: RequestGroupClasses = (language: string, params: QueryParams): Promise<AxiosXHR<any>> => {
    sourceGroupClasses.cancel();
    sourceGroupClasses = ApiService.createToken();
    const {
      currentPage,
      isSeasonClosed,
      myClassesOnly,
      mySectorGroupClasses,
      coordinates,
      day,
      activity,
      orderActivityName,
      itemsPerPage,
      isValidated,
      caseNumber,
      groupClassKey,
    } = params;

    const { latitude, longitude } = coordinates;

    return ApiService.request({
      method: 'get',
      url: GROUP_CLASSES,
      cancelToken: sourceGroupClasses.token,
      headers: {
        'Accept-Language': language,
      },
      params: {
        page: currentPage,
        isSeasonClosed,
        isValidated,
        myGroupClasses: myClassesOnly ? true : null,
        day: day ? this.getListDay(day) : null,
        caseNumber,
        groupClassKey,
        ['activity.id']: activity ? this.getListActivity(activity) : null,
        mySectorGroupClasses: mySectorGroupClasses ? true : null,
        latitude: latitude ?? null,
        longitude: longitude ?? null,
        radius: latitude !== 0 && longitude !== 0 ? geocodingRadius : null,
        'order[activity.name]': orderActivityName ?? null,
        itemsPerPage: itemsPerPage ?? DEFAULT_ITEMS_PER_PAGE,
      },
    });
  };

  fetchGroupClasses: FetchGroupClasses = async (language: string, params: QueryParams): Promise<void> => {
    this.updateValues(
      this.groupClasses,
      this.filters,
      this.additionalData,
      this.coordinatesAPI,
      true,
      this.isCoordinatesLoading,
    );
    let groupClassesArray = [];
    let additionalData = DEFAULT_PAGINATION_DATA;

    this.requestGroupClasses(language, params)
      .then((response) => {
        if (response.data) {
          const { data } = response;
          groupClassesArray = data['hydra:member'].map(
            (groupClass: GroupClassPayload) => parseGroupClassFromPayload(groupClass),
          );
          const payloadCurrentPage = (data['hydra:view'] && getPageNumberFromUrl(data['hydra:view']['@id'])) || 1;
          const lastPage = (data['hydra:view'] && getPageNumberFromUrl(data['hydra:view']['hydra:last'])) || 1;

          additionalData = { lastPage, currentPage: payloadCurrentPage, totalItems: data['hydra:totalItems'] };

          this.updateValues(
            groupClassesArray,
            this.filters,
            additionalData,
            this.coordinatesAPI,
            false,
            this.isCoordinatesLoading,
          );
        }
      })
      .catch(() => {
        this.updateValues(
          this.groupClasses,
          this.filters,
          this.additionalData,
          this.coordinatesAPI,
          false,
          this.isCoordinatesLoading,
        );
      });
  };

  requestCoordinates: RequestCoordinates = (language: string, params: QueryParams): Promise<AxiosXHR<any>> => {
    sourceCoordinate.cancel();
    sourceCoordinate = ApiService.createToken();
    const user = AuthService.user;

    const {
      pagination,
      isSeasonClosed,
      myClassesOnly,
      mySectorGroupClasses,
      coordinates,
      day,
      activity,
      isValidated,
    } = params;

    const { latitude, longitude } = coordinates;

    return ApiService.request({
      method: 'get',
      url: GROUP_CLASSES_COORDINATES,
      cancelToken: sourceCoordinate.token,
      headers: {
        'Accept-Language': language,
      },
      params: {
        pagination: pagination ? true : false,
        isSeasonClosed,
        isValidated,
        myGroupClasses: myClassesOnly ? true : null,
        day: day ? this.getListDay(day) : null,
        ['activity.id']: activity ? this.getListActivity(activity) : null,
        mySectorGroupClasses: mySectorGroupClasses ? true : null,
        latitude: latitude ?? null,
        longitude: longitude ?? null,
        radius: latitude !== 0 && longitude !== 0 ? geocodingRadius : null,
        locale: user ? getLanguageFormat(user.language) : getLanguageFormat(language),
      },
    });
  };

  fetchCoordinates: FetchCoordinates = (language: string, params: QueryParams): void => {
    this.updateValues(
      this.groupClasses,
      this.filters,
      this.additionalData,
      this.coordinatesAPI,
      this.isGroupClassLoading,
      true,
    );

    this.requestCoordinates(language, params)
      .then((response) => {
        if (response.data) {
          const { data } = response;

          const coordinatesArray: LocationGroupClass[] = data['hydra:member'].map(
            (coordinate: CoordinatesGroupClass) => hydrateCoordinate(coordinate),
          );

          this.updateValues(
            this.groupClasses,
            this.filters,
            this.additionalData,
            coordinatesArray,
            this.isGroupClassLoading,
            false,
          );
        }
      })
      .catch(() => {
        this.updateValues(
          this.groupClasses,
          this.filters,
          this.additionalData,
          this.coordinatesAPI,
          this.isGroupClassLoading,
          false,
        );
      });
  };

  fetchGroupClassesAndCoordinates: FetchGroupClassesAndCoordinates = (
    language: string,
    params: QueryParams,
  ): void => {
    this.updateValues(
      this.groupClasses,
      this.filters,
      this.additionalData,
      this.coordinatesAPI,
      true,
      true,
    );
    Promise.allSettled([
      this.requestGroupClasses(language, params),
      this.requestCoordinates(language, params),
    ])
      .then((results) => {
        const resultGroupClasses = results[0];
        const resultCoordinates = results[1];

        if (resultGroupClasses.status === 'fulfilled' && resultCoordinates.status === 'fulfilled') {
          const { data: groupClassesData } = resultGroupClasses.value;
          const { data: coordinatesData } = resultCoordinates.value;
          let groupClassesArray = [];
          let additionalData = DEFAULT_PAGINATION_DATA;

          const coordinatesArray: LocationGroupClass[] = coordinatesData['hydra:member'].map(
            (coordinate: CoordinatesGroupClass) => hydrateCoordinate(coordinate),
          );

          groupClassesArray = groupClassesData['hydra:member'].map(
            (groupClass: GroupClassPayload) => parseGroupClassFromPayload(groupClass),
          );
          const payloadCurrentPage = (groupClassesData['hydra:view'] && getPageNumberFromUrl(groupClassesData['hydra:view']['@id'])) || 1;
          const lastPage = (groupClassesData['hydra:view'] && getPageNumberFromUrl(groupClassesData['hydra:view']['hydra:last'])) || 1;
          if (lastPage < params.currentPage) {
            this.fetchGroupClassesAndCoordinates(
              language,
              {
                ...params,
                currentPage: 1,
              },
            );
          } else {
            additionalData = { lastPage, currentPage: payloadCurrentPage, totalItems: groupClassesData['hydra:totalItems'] };

            this.updateValues(
              groupClassesArray,
              this.filters,
              additionalData,
              coordinatesArray,
              false,
              false,
            );
          }
        }
      });
  };

  setFilters: SetFilters = (filter: Object): void => {
    const newFilters = (this.filters && { ...this.filters, ...filter }) || filter;
    this.updateValues(
      this.groupClasses,
      newFilters,
      this.additionalData,
      this.coordinatesAPI,
      this.isGroupClassLoading,
      this.isCoordinatesLoading,
    );
  };

  resetService: ResetService = (): void => {
    this.updateValues([], {}, DEFAULT_PAGINATION_DATA, [], false, false);
  };

  onChange: OnChange = (listener: Listener): Function => {
    const listenerFunction = this.eventEmitter.addListener(listener);
    this.#trigger();
    return listenerFunction;
  };

  /**
   * @private
   */
  #trigger: Trigger = (): void => {
    this.eventEmitter.trigger({
      groupClasses: this.groupClasses,
      filters: this.filters,
      additionalData: this.additionalData,
      coordinatesAPI: this.coordinatesAPI,
      isGroupClassLoading: this.isGroupClassLoading,
      isCoordinatesLoading: this.isCoordinatesLoading,
    });
  };
}

const instance: GroupClassesService = new GroupClassesService();
export default instance;
