export const DEFAULT_PAGINATION_DATA = {
  lastPage: 1,
  currentPage: 1,
  totalItems: 0,
};

export const DEFAULT_PAGE = 1;

export const PARAMS_NO_UNSUBSCRIBE_NO_PAGINATED_SORTED_ACTIVITY = {
  'exists[unsubscribedAt]': false,
  'order[groupClass.activity.name]': 'asc',
  pagination: false,
};

export const PARAMS_NO_PAGINATED_SORTED_ACTIVITY = {
  'order[groupClass.activity.name]': 'asc',
  pagination: false,
};

export const PARAMS_NO_UNSUBSCRIBE_NO_PAGINATED_SORTED_LASTNAME = {
  'exists[unsubscribedAt]': false,
  'order[person.lastName]': 'asc',
  pagination: false,
};

export const DEFAULT_ITEMS_PER_PAGE = 24;
