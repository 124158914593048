// @flow
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import ModalYesNo from '@shared/Modal/components/ModalYesNo/ModalYesNo';

import GroupClassesPeriodArchiveService from '@groupClass/services/GroupClassesPeriodArchiveService';

type Props = {
  isActive: boolean,
  toggleModal: () => void,
  showSuccessMessage: (message: string) => void,
};

const ModalArchivePeriods = (props: Props): React$Node => {
  const { isActive, toggleModal, showSuccessMessage } = props;

  const { t } = useTranslation();

  const onClickYes = useCallback((): void => {
    GroupClassesPeriodArchiveService.create()
      .then(() => {
        showSuccessMessage('groupClasses.archivePeriodsNotification');
        GroupClassesPeriodArchiveService.fetchAll();
        toggleModal();
      });
  }, [toggleModal, showSuccessMessage]);

  return (
    <ModalYesNo
      isActive={ isActive }
      toggleModal={ toggleModal }
      onClickYes={ onClickYes }
      title={ t('groupClasses.archive') }
    >
      <p>
        { t('groupClasses.archivePeriodsSentenceOne') }
      </p>
      <p>
        { t('groupClasses.archivePeriodsSentenceTwo') }
      </p>
      <p>
        <b>{ t('groupClasses.archivePeriodsSentenceThree') }</b>
      </p>
    </ModalYesNo>
  );
};

export default ModalArchivePeriods;
