// @flow
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import moment from 'moment/dist/moment';

import InputField from '_common/components/input-field/InputField';
import PaymentInfoBubble from '@shared/Payment/components/PaymentInfoBubble';

import { formatAmount } from '@payment/helpers/AmountUtils';
import { calculatePaymentScheduleAmount, separatePaymentConditionTypes } from '@shared/Payment/helpers/PaymentUtils';

import type { ErrorProps } from '@core/types';
import type { PaymentConditionType } from '@paymentConditionType/types';
import type { PaymentSchedule, Period } from '@groupClass/types';

import {
  CONDITION_TYPE_SUBSIDY,
  CONDITION_TYPE_OTHER,
} from '@paymentConditionType/constants';

type Props = {
  paymentConditions: PaymentConditionType[],
  paymentSchedule: PaymentSchedule,
  period: Period,
  annualFeesPeriodId: number,
  errorsProps: ErrorProps,
  handleCostAdjust: (periodId: number, adjustment: string) => void,
  handleExemption: (paymentSchedule: PaymentSchedule, costAdjust: number) => void,
};

const PaymentFormRow = (props: Props): React$Node => {
  const {
    paymentConditions,
    paymentSchedule,
    period,
    annualFeesPeriodId,
    errorsProps,
    handleCostAdjust,
    handleExemption,
  } = props;
  const { t, i18n: { language } } = useTranslation();

  const [ isBubbleVisible, setIsBubbleVisible ] = useState<boolean>(false);
  const [ inputValue, setInputValue ] = useState<string>(String(paymentSchedule.costAdjust));

  const startDate = moment(period.startDate).locale(language).format('L');
  const endDate = moment(period.endDate).locale(language).format('L');

  const { periodDiscount, annualFeeAmount } = separatePaymentConditionTypes(paymentConditions);

  const toggleBubble = useCallback((value?: boolean): void => {
    setIsBubbleVisible(value ?? !isBubbleVisible);
  }, [isBubbleVisible]);

  const getMinAdjustAmount = useCallback((): number => (
    0 - paymentSchedule.period.amount - (paymentSchedule.discount ? paymentSchedule.discount : 0)
  ), [paymentSchedule]);

  const subsidyOrOther = useMemo((): boolean => (
    paymentConditions.some((condition) => condition.code === CONDITION_TYPE_SUBSIDY || condition.code === CONDITION_TYPE_OTHER)
  ), [paymentConditions]);

  const updatedPaymentSchedule = useMemo((): PaymentSchedule => (
    calculatePaymentScheduleAmount(paymentSchedule, periodDiscount, annualFeeAmount, annualFeesPeriodId, subsidyOrOther)
  ), [paymentSchedule, periodDiscount, annualFeeAmount, annualFeesPeriodId, subsidyOrOther]);

  useEffect(() => {
    if (!subsidyOrOther) {
      setInputValue('');
    }
  }, [subsidyOrOther]);

  const amountDue = useMemo((): number => {
    let newAmount = 0;

    if (updatedPaymentSchedule) {
      const { amount, discount, costAdjust } = updatedPaymentSchedule;
      newAmount = amount + discount + parseFloat(costAdjust);

      if (newAmount < 0) {
        newAmount = 0;
      }
    }
    return newAmount;
  }, [updatedPaymentSchedule]);

  const statusPeriod = useMemo((): string => {
    if (period.validatedAt) {
      return t('groupClasses.validated');
    }
    if (period.archivedAt) {
      return t('groupClasses.archived');
    }
    return '';
  }, [period]);

  const isPeriodValidated = useMemo((): string => clsx({
    'is-period-validated': !!statusPeriod,
  }), [statusPeriod]);

  const isCostAdjustDisabled = useMemo((): boolean => {
    let isFinished = true;
    if (period.validatedAt === null) isFinished = false;
    return isFinished || !subsidyOrOther;
  }, [period, subsidyOrOther]);

  const handleCostAdjustChange = useCallback((e: SyntheticEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value);
    if (/^([+-]?)([0-9]+)([.,]?)([0-9]{0,2})$/.test(e.currentTarget.value)) {
      const adjust = e.currentTarget.value.replace(',', '.');
      handleCostAdjust(period.id, String(adjust));
    } else if (e.currentTarget.value === '' || e.currentTarget.value === '-') {
      handleCostAdjust(period.id, String(0));
    }
  }, [period, setInputValue, handleCostAdjust]);

  return (
    <tr className={ isPeriodValidated }>
      <td>{ `${ startDate } - ${ endDate }` }</td>
      <td><span className="amountEuro">{ formatAmount(period.amount, language) }</span> &euro;</td>
      <td>
        <span className="amountEuro">{ formatAmount(updatedPaymentSchedule?.discount || 0, language) }</span> &euro;
        <PaymentInfoBubble
          paymentConditions={ paymentConditions }
          isVisible={ isBubbleVisible }
          toggleVisible={ toggleBubble }
        />
      </td>
      <td>
        <InputField
          name={ `paymentSchedules[${ period.id }].costAdjust` }
          color={ errorsProps[`paymentSchedules[${ period.id }].costAdjust`]?.color }
          labelProps={ errorsProps[`paymentSchedules[${ period.id }].costAdjust`]?.labelProps }
          helpProps={ errorsProps[`paymentSchedules[${ period.id }].costAdjust`]?.error }
          value={ inputValue === '0' ? '' : inputValue }
          isDisabled={ isCostAdjustDisabled }
          onChange={ handleCostAdjustChange }
        />
      </td>
      <td><span className="amountEuro">{ formatAmount(amountDue, language) }</span> &euro;</td>
      <td>
        { statusPeriod && (
          statusPeriod
        ) }
        { !statusPeriod && (
          <button
            onClick={ () => handleExemption(paymentSchedule, getMinAdjustAmount()) }
            className="button button-tertiary"
          >
            { t('groupClasses.groupClass.payment.exemption') }
          </button>
        ) }
      </td>
    </tr>
  );
};

PaymentFormRow.defaultProps = {
  handleCostAdjust: () => {},
  handleExemption: () => {},
};

export default PaymentFormRow;
