// @flow
import { useCallback } from 'react';
import moment from 'moment';

import type { PickerRules } from '@shared/DateTimePicker/types';
import * as dateTimePickerConstants from '@shared/DateTimePicker/constants';
import IconArrow from '@icons/components/IconArrow';

type Props = {
  date: moment$Moment,
  rules: PickerRules,
  onChange: (date: moment$Moment, viewMode: string) => void,
};

const TimePicker = (props: Props): React$Node => {
  const { date, rules, onChange } = props;

  const increaseHours: Function = useCallback((): void => {
    const newDate = moment(date);
    const increment = rules.hours
      ? rules.hours.step
      : 1;
    newDate.add(increment, 'h');
    onChange(newDate, dateTimePickerConstants.VIEW_MODE_TIME);
  }, [date, rules, onChange]);

  const decreaseHours: Function = useCallback((): void => {
    const newDate = moment(date);
    const decrement = rules.hours
      ? rules.hours.step
      : 1;
    newDate.subtract(decrement, 'h');
    onChange(newDate, dateTimePickerConstants.VIEW_MODE_TIME);
  }, [date, rules, onChange]);

  const increaseMinutes: Function = useCallback((): void => {
    const newDate = moment(date);
    const increment = rules.minutes
      ? rules.minutes.step
      : 1;
    newDate.add(increment, 'm');
    onChange(newDate, dateTimePickerConstants.VIEW_MODE_TIME);
  }, [date, rules, onChange]);

  const decreaseMinutes: Function = useCallback((): void => {
    const newDate = moment(date);
    const decrement = rules.minutes
      ? rules.minutes.step
      : 1;
    newDate.subtract(decrement, 'm');
    onChange(newDate, dateTimePickerConstants.VIEW_MODE_TIME);
  }, [date, rules, onChange]);

  const hours = moment(date).format('HH');
  const minutes = moment(date).format('mm');

  return (
    <div className="time-picker">
      <div className="time-picker-hours">
        <IconArrow className="chevron-up" onClick={ increaseHours } />
        <span className="time-picker-value">{ hours }</span>
        <IconArrow className="chevron-down" onClick={ decreaseHours } />
      </div>

      <div className="time-picker-minutes">
        <IconArrow className="chevron-up" onClick={ increaseMinutes } />
        <span className="time-picker-value">{ minutes }</span>
        <IconArrow className="chevron-down" onClick={ decreaseMinutes } />
      </div>
    </div>
  );
};

TimePicker.defaultProps = {
  date: '',
  rules: dateTimePickerConstants.DEFAULT_STEP_RULES,
  onChange: (): void => undefined,
};

export default TimePicker;
