// @flow
/* eslint-disable max-len */
import clsx from 'clsx';

type Props = {
  className: string,
  onClick: () => void,
};

const IconArrow = (props: Props): React$Node => {
  const { className, onClick } = props;
  const newProps = {
    className: clsx({ icon: true, 'icon-arrow': true, [className]: !!className }),
    role: 'img',
    viewBox: '0 0 10 17.97',
    xmlns: 'http://www.w3.org/2000/svg',
    onClick,
  };

  return (
    <svg { ...newProps }>
      <path d="M7.612 8.974l-7.334 7.31a.981.981 0 000 1.392.99.99 0 001.397 0L9.708 9.67a.981.981 0 000-1.392L1.675.271a.99.99 0 00-1.396 0 .98.98 0 000 1.393l7.333 7.31z" />
    </svg>
  );
};

IconArrow.defaultProps = {
  className: 'icon-arrow',
  onClick: () => {},
};

export default IconArrow;
