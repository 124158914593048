// @flow
import { useMemo } from 'react';
import clsx from 'clsx';

type Props = {
  className: string,
  onClick: () => void,
};

const IconYoutube = (props: Props): React$Node => {
  const {
    className,
    onClick,
  } = props;

  const iconSocialClassName = useMemo((): string => clsx({
    'icon-social': true,
    [String(className)]: !!className,
  }), [className]);

  const newProps = {
    className: iconSocialClassName,
    onClick,
  };

  return (
    <svg { ...newProps } viewBox="0 0 52.34 52.67" width="52.34" height="52.67">
      <g>
        <path className="bg"
          d="M0,3.77C0,1.69,1.73,0,3.87,0h44.6c2.14,0,3.87,1.69,3.87,3.77v45.12c0,2.08-1.73,3.77-3.87,3.77H3.87c-2.14,0-3.87-1.69-3.87-3.77V3.77Z"/>
        <path className="icon"
          d="M44.8,16.99c-.45-1.65-1.78-2.99-3.43-3.43-3.06-.83-15.2-.83-15.2-.83,0,0-12.14,0-15.19.83-1.66.44-2.99,1.78-3.44,3.43-.82,3.05-.82,9.35-.82,9.35,0,0,0,6.29.82,9.34.45,1.66,1.78,2.99,3.44,3.44,3.05.82,15.19.82,15.19.82,0,0,12.14,0,15.2-.82,1.65-.45,2.98-1.78,3.43-3.44.82-3.05.82-9.34.82-9.34,0,0,0-6.36-.82-9.35ZM22.42,32.19v-11.7l10.11,5.85-10.11,5.85Z"/>
      </g>
    </svg>
  );
};

IconYoutube.defaultProps = {
  className: 'icon-youtube',
  onClick: () => {
  },
};

export default IconYoutube;
