// @flow
import { useMemo } from 'react';

import GroupClassVisitor from '@groupClass/components/Visitor/GroupClassVisitor';
import GroupClassBeneficiary from '@groupClass/components/Beneficiary/GroupClassBeneficiary';
import GroupClassDetailed from '@groupClass/components/Admin/GroupClassDetailed';

import useAuth from '@user/hooks/useAuth';
import RolesHelpers from '@user/helpers/RolesHelpers';

const GroupClass = (): React$Node => {
  const { user, isConnected } = useAuth();

  const isBeneficiary = useMemo((): boolean => (
    RolesHelpers.isBeneficiary(user)
  ), [user]);

  const isSielBleuUser = useMemo((): boolean => (
    RolesHelpers.isSielBleuUser(user)
  ), [user]);

  return (
    <>
      { !isConnected && (
        <GroupClassVisitor />
      ) }

      { isBeneficiary && !isSielBleuUser && (
        <GroupClassBeneficiary />
      ) }

      { isSielBleuUser && (
        <GroupClassDetailed />
      ) }
    </>
  );
};

export default GroupClass;
