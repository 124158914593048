// @flow

import { useParams, Link } from 'react-router-dom';
import clsx from 'clsx';

import ParticipantIndicator from '@shared/ParticipantIndicator';

import type { ShortRegistered } from '@groupClass/types';
import { WEB_PATHS } from '@app/constants/paths';
const { PARTICIPANTS_REGISTERED_ID } = WEB_PATHS;

type Props = {
  registered: ShortRegistered,
};

type UseParamProps = {
  classId: string,
  registeredId: string,
};

const ListRegisteredRow = (props: Props): React$Node => {
  const { registered } = props;
  const { classId: id, registeredId } = useParams<UseParamProps>();
  const { firstName, lastName, type } = registered.person;

  const lastNameFormatted = lastName.toUpperCase();
  const label = `${ firstName } ${ lastNameFormatted }`;
  const isBeneficiary = type === 'beneficiary';

  const listRegisteredsGroupClassesRegisteredsClassName = clsx({
    'people': true,
    'list-registered-request': true,
    'is-selected': String(registered.id) === registeredId,
  });

  return (
    <li className={ listRegisteredsGroupClassesRegisteredsClassName } title={ label }>
      <Link to={ PARTICIPANTS_REGISTERED_ID.replace(':classId', id).replace(':registeredId', String(registered.id)) } >
        <div className="list-registereds-line-people">
          <ParticipantIndicator isBeneficiary={ isBeneficiary } />
          <span className="list-registereds-name">{ label }</span>
        </div>
      </Link>
    </li>
  );
};

export default ListRegisteredRow;
