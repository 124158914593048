// @flow
import type {
  GroupClassRequest,
  GroupClassRequestPayload,
  ShortGroupClassRequest,
  ShortGroupClassRequestPayload,
  ShortGroupClassRequestBeneficiaryPayload,
  ShortGroupClassRequestBeneficiary,
} from '@participant/types';
import type { UserRegistered } from '@groupClass/types';
import { hydratePersonFromPayload } from '@person/helpers/PersonUtils';
import { formatShortGroupClassFromPayload } from '@groupClass/helpers/GroupClassesUtils';

import { PAYMENT_FREQUENCY_PERIOD } from '@payment/constants';

export const hydrateBeneficiaryShortRequest = (request: ShortGroupClassRequestBeneficiaryPayload): ShortGroupClassRequestBeneficiary => ({
  id: request.id,
  groupClass: formatShortGroupClassFromPayload(request.groupClass),
  status: request.status,
  createdAt: new Date(request.createdAt),
});

export const hydrateShortRequest = (request: ShortGroupClassRequestPayload): ShortGroupClassRequest => ({
  id: request.id,
  person: hydratePersonFromPayload(request.person),
  status: request.status,
});

export const hydrateRequest = (request: GroupClassRequestPayload): GroupClassRequest => {
  const formatGroupClasses = request.groupClassesRegisteredTo.map((groupClass) => (
    formatShortGroupClassFromPayload(groupClass)
  )) || [];

  return {
    id: request.id,
    person: hydratePersonFromPayload(request.person),
    status: request.status,
    groupClass: formatShortGroupClassFromPayload(request.groupClass),
    groupClassesRegisteredTo: formatGroupClasses,
  };
};

export const createUserRegisteredFromRequest = (request: GroupClassRequest): UserRegistered => ({
  id: null,
  person: request.person,
  groupClass: request.groupClass,
  groupClassesRegisteredTo: request.groupClassesRegisteredTo,
  paymentFrequency: PAYMENT_FREQUENCY_PERIOD,
  paymentSchedules: [],
  paymentConditions: [],
  subscriptionPaidAt: null,
  unsubscribedAt: null,
});
