// @flow
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import ModalDeleteCheque from '@payment/components/ModalDeleteCheque';
import ChequeLine from '@payment/components/ChequeLine';
import ModalTransferPayment from '@payment/components/ModalTransferPayment';
import GroupClassPaymentService, { type GroupClassPaymentServiceData } from '@payment/services/GroupClassPaymentService';
import Loader from '_common/components/loader/Loader';
import Toast from '_common/services/Toast/Toast';

import { sortChequeBalance } from '@payment/helpers/SortPaymentUtils';

import type { PeriodItemOutput, GroupClassDetailed } from '@groupClass/types';
import type { ChequeBalance } from '@payment/types';

import { LOADER_TYPE_BACKGROUND } from '_common/components/loader/constants';

type Props = {
  groupClass: GroupClassDetailed,
  period: PeriodItemOutput,
};

const ChequeDetailed = (props: Props): React$Node => {
  const { groupClass, period } = props;
  const { t } = useTranslation();

  const [ balanceSelected, setBalanceSelected ] = useState<ChequeBalance | null>(null);
  const [ chequeBalances, setChequeBalances] = useState<ChequeBalance[][]>(sortChequeBalance(GroupClassPaymentService.chequeBalances));
  const [ isLoading, setIsLoading ] = useState<boolean>(GroupClassPaymentService.isLoading);

  const [ isActiveModalTransfer, setIsActiveModalTransfer ] = useState<boolean>(false);
  const [ isActiveModalDeleteCheque, setIsActiveModalDeleteCheque ] = useState<boolean>(false);

  const toggleModalDeleteCheque = useCallback((): void => {
    setIsActiveModalDeleteCheque(!isActiveModalDeleteCheque);
  }, [isActiveModalDeleteCheque]);

  const toggleModalTransfer = useCallback((): void => {
    setIsActiveModalTransfer(!isActiveModalTransfer);
  }, [isActiveModalTransfer]);

  const handleTransferClick = useCallback((balance: ChequeBalance): void => {
    setBalanceSelected(balance);
    toggleModalTransfer();
  }, [toggleModalTransfer]);

  const handleDeleteClick = useCallback((balance: ChequeBalance): void => {
    setBalanceSelected(balance);
    toggleModalDeleteCheque();
  }, [toggleModalDeleteCheque]);

  const handleUpdateChequeCardBalances = (data: GroupClassPaymentServiceData): void => {
    const balances = sortChequeBalance(data.chequeBalances);
    setChequeBalances(balances);
    setIsLoading(data.isLoading);
  };

  useEffect((): Function => {
    const unsubscribe = GroupClassPaymentService.onChange(handleUpdateChequeCardBalances);
    return () => {
      unsubscribe();
      GroupClassPaymentService.reset();
    };
  }, []);

  useEffect((): void => {
    if (period) {
      const params = {
        id: groupClass.id,
        periodId: period.id,
      };
      GroupClassPaymentService.fetchChequeBalances(params);
    }
  }, [groupClass, period]);

  const handleTransferSuccess = useCallback((): void => {
    Toast.success(t('payment.transfer.success'));
    const params = {
      id: groupClass.id,
      periodId: period.id,
    };
    GroupClassPaymentService.fetchChequeBalances(params);
  }, [t, groupClass, period]);

  const showSuccessMessage = useCallback((message: string): void => {
    Toast.success(t(message));
  }, [t]);

  return (
    <div className="table-payment-cb-cheque-container">
      <table className="table">
        <colgroup>
          <col />
          <col className="table-cell-width-cheque"/>
          <col className="table-cell-width-cheque"/>
          <col className="table-cell-width-cheque"/>
          <col className="table-cell-width-cheque"/>
          <col className="table-cell-width-cheque"/>
          <col className="table-cell-width-cheque"/>
        </colgroup>
        <thead className="table-head">
          <tr>
            <th className="table-head-title table-cell-title-name">
              { t('payment.name') }
            </th>
            <th className="table-head-title cell-text-centered">
              { t('payment.tableDue') }
            </th>
            <th className="table-head-title cell-text-centered">
              { t('payment.tablePaid') }
            </th>
            <th className="table-head-title cell-text-centered">
              { t('payment.paymentDate') }
            </th>
            <th className="table-head-title cell-text-centered">
              { t('payment.chequeNumberShort') }
            </th>
            <th className="table-head-title cell-text-centered">
              { t('payment.bankDepositNumberShort') }
            </th>
            <th className="table-head-title cell-text-centered">
              { t('payment.actions') }
            </th>
          </tr>
        </thead>
        <tbody className="table-body">
          { isLoading && (
            <tr>
              <td colSpan="7" className="cell-text-centered">
                <Loader loaderType={ LOADER_TYPE_BACKGROUND } />
              </td>
            </tr>
          ) }
          { !isLoading && chequeBalances.length === 0 && (
            <tr>
              <td colSpan="7" className="cell-text-centered">
                { t('payment.noresultForThisPeriod') }
              </td>
            </tr>
          ) }
          { chequeBalances.map((personCheque, index) => (
            personCheque.map((cheque, chequeIndex) => (
              <ChequeLine
                period={ period }
                cheque={ cheque }
                groupClass={ groupClass }
                index={ index }
                handleTransferClick={ handleTransferClick }
                handleDeleteClick={ handleDeleteClick }
                key={ cheque.id }
                isFirstLine={ chequeIndex === 0 }
              />
            ))
          )) }
        </tbody>

      </table>
      { balanceSelected && (
        <>
          <ModalTransferPayment
            isActive={ isActiveModalTransfer }
            toggleModal={ toggleModalTransfer }
            balance={ balanceSelected }
            onTransferSuccess={ handleTransferSuccess }
          />
          <ModalDeleteCheque
            deleteChequeId={ balanceSelected.id }
            isActive={ isActiveModalDeleteCheque }
            toggleModal={ toggleModalDeleteCheque }
            showSuccessMessage={ showSuccessMessage }
            groupClass={ groupClass }
            periodId={ period.id }
          />
        </>
      ) }
    </div>
  );
};

export default ChequeDetailed;
