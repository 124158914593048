// @flow
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AdvancedSelectField from '@shared/AdvancedSelectField';
import TextareaField from '_common/components/textarea-field/TextareaField';
import Button from '@shared/Button';

import { TEXTAREA_LENGTH_LIMIT } from '@app/constants/constants';

import type { Option } from '@core/types';

const PageContact = (): React$Node => {
  const { t, i18n: { language } } = useTranslation();

  const [ subject, setSubject ] = useState<Option | null>(null);
  const [ valueTextArea, setValueTextArea ] = useState<string>('');

  const handleTextAreaChange = useCallback((e: SyntheticEvent<HTMLInputElement>): void => {
    setValueTextArea(e?.currentTarget?.value);
  }, [setValueTextArea]);

  const isButtonDisabled = useMemo((): boolean => (
    !(subject && valueTextArea)
  ), [subject, valueTextArea]);

  const handleSubmit = useCallback((event: SyntheticEvent<EventTarget>): void => {
    event.preventDefault();
    window.open(`mailto:mescours@sielbleu.org?subject=${ subject?.value || '' }&body=${ valueTextArea }`, '_blank');
  }, [subject, valueTextArea]);

  const getOptions = useMemo((): Option[] => (
    ['pageContact.subject.one', 'pageContact.subject.two', 'pageContact.subject.three'].map((el) => ({ 'label': t(el), 'value': t(el) }))
  ), [language]);

  const handleChangeSubject = useCallback((newValue): void => {
    if (newValue !== null) {
      const selected: Option = Array.isArray(newValue) ? newValue[0] : newValue;
      setSubject({
        label: selected.label,
        value: selected.value,
      });
    } else {
      setSubject(null);
    }
  }, [setSubject]);

  return (
    <div className="page-contact">
      <h1>{ t('pageContact.title') }</h1>
      <AdvancedSelectField
        className="subject-field"
        name="subject-contact"
        options={ getOptions }
        id="subject-contact"
        isClearable
        placeholder={ t('pageContact.placeholder.select') }
        onChange={ handleChangeSubject }
        value={ subject }
        required
        isSearchable={ false }
      />
      <TextareaField
        name="Taper votre message"
        label="" onChange={ handleTextAreaChange }
        value={ valueTextArea }
        placeholder={ t('pageContact.placeholder.textarea') }
        limitedChar={ TEXTAREA_LENGTH_LIMIT }
      />
      <Button
        onClick={ handleSubmit }
        isDisabled={ isButtonDisabled }
      >
        { t('groupClasses.groupClass.sendGroup.global.send') }
      </Button>
    </div>
  );
};

export default PageContact;
