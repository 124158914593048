//@flow
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { GroupClassDetailed } from '@groupClass/types';

import InputField from '_common/components/input-field/InputField';

type Props = {
  groupClass: GroupClassDetailed,
};

const Time = (props: Props): React$Node => {
  const { t, i18n: { language } } = useTranslation();
  const { groupClass } = props;

  const startTime = useMemo((): string => (
    groupClass.startTime.toLocaleTimeString(language , { hour: '2-digit', minute: '2-digit' })
  ), [language]);
  const duration = (groupClass.endTime - groupClass.startTime) / 1000;
  const minutes = Math.floor(duration / 60);
  const hours = Math.floor(minutes / 60);
  const minutesToDisplay = minutes - (hours * 60);
  const durationToDisplay = `${ hours }h${ minutesToDisplay === 0 ? '' : minutesToDisplay }`;

  return (
    <div className="field edit-field edit-two-sides timers">
      <div id="startTime">
        <InputField
          id="startTime"
          name="startTime"
          label={ t('groupClasses.groupClass.detailed.startTime') }
          value={ startTime }
          isDisabled
          readOnly
        />
      </div>
      <div className="input-edit-form">
        <InputField
          id="endTime"
          name="endTime"
          label={ t('groupClasses.groupClass.detailed.duration') }
          value={ durationToDisplay }
          isDisabled
          readOnly
        />
      </div>
    </div>
  );
};

export default Time;
