// @flow
import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import RegisteredsService from '@groupClass/services/RegisteredsService';

import ListRegisteredRow from '@participant/components/ListRegisteredRow';

import { RequestStatuses } from '@core/constants';

import type { ShortRegistered } from '@groupClass/types';

type UseParamProps = {
  classId: string,
  registeredId: string,
};

const ListRegistereds = (): React$Node => {
  const { t, i18n: { language } } = useTranslation();
  const { classId: id } = useParams<UseParamProps>();

  const [ groupClassesRegistereds, setGroupClassesRegistereds ] = useState<ShortRegistered[]>(RegisteredsService.groupClassRegistereds);

  useEffect(() => {
    RegisteredsService.fetchGroupClassRegistereds(parseInt(id, 10), language, { pagination: false, 'order[person.lastName]': 'asc' })
      .then(setGroupClassesRegistereds)
      .catch(() => {});
    return () => RegisteredsService.reset();
  }, [id, language]);

  const registeredSubscribed = useMemo((): ShortRegistered[] => (
    groupClassesRegistereds.filter((userRegistered) => userRegistered.unsubscribedAt === null)
  ), [groupClassesRegistereds]);

  const emptyRegisteredSubscribed = useMemo((): boolean => (
    registeredSubscribed.length === 0
  ), [registeredSubscribed]);

  const hasNoRegistereds = useMemo((): boolean => (
    RegisteredsService.registeredsRequestStatus === RequestStatuses.SUCCEEDED && emptyRegisteredSubscribed
  ), [emptyRegisteredSubscribed, RegisteredsService.registeredsRequestStatus]);

  return (
    <>
      <>
        <div className="list-registereds-line-people">
          <h2 className="title-two registered">
            { t('groupClasses.groupClass.peoples.list.registeredTitle') }
          </h2>
        </div>
        { hasNoRegistereds && (
          <div className="empty-registered">{ t('participantLayout.no.registered') }</div>
        ) }
        <ul className="list-registereds-users-registereds">
          {
            registeredSubscribed.map((userRegistered) => (
              <ListRegisteredRow registered={ userRegistered } key={ userRegistered.id } />
            ))
          }
        </ul>
      </>
    </>
  );
};

export default ListRegistereds;
