// @flow
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { buttonConstants } from '@shared/Button';
import { formatAmount } from '@payment/helpers/AmountUtils';

import { WEB_PATHS } from '@app/constants/paths';
const { INVOICE_DETAILS } = WEB_PATHS;

type Props = {
  totalAmount: number,
  groupClassId: number,
};

const GroupClassTotalRow = (props: Props): React$Node => {
  const { totalAmount, groupClassId } = props;

  const { t, i18n: { language } } = useTranslation();

  const amountFormatted = formatAmount(totalAmount, language);

  return (
    <tr className="group-class-total-row change-mobile">
      <td className="cell-padding-left cell-txt-middle">
        { t('payment.summary.total') }
      </td>
      <td className="cell-padding cell-txt-middle cell-txt-middle-amount" align="center">
        { `${ amountFormatted } €` }
      </td>
      <td className="cell-padding" align="center">
        <Link
          to={ {
            pathname: INVOICE_DETAILS,
            search: `?group_class=${ groupClassId }`,
          } }
          className="button button-primary button-period"
          type={ buttonConstants.PRIMARY }
        >
          { t('payment.summary.payTotal', { total: amountFormatted } ) }
        </Link>
      </td>
    </tr>
  );
};

export default GroupClassTotalRow;
