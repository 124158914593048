// @flow
import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Toast from '_common/services/Toast/Toast';
import TrialBanner from '@banner/components/TrialBanner';
import Loader from '_common/components/loader/Loader';

import AuthService, { type AuthServiceData } from '@user/services/AuthService';
import BeneficiaryGroupClassesRequestsService from '@beneficiary/services/BeneficiaryGroupClassesRequestsService';
import GroupClassesService from '@groupClass/services/GroupClassesService';

import type { QueryParams } from '@core/types';
import type { ShortGroupClassBeneficiaryRequests } from '@participant/types';
import type { User } from '@user/types';

import { LOADER_TYPE_PAGE } from '_common/components/loader/constants';
import { GROUP_CLASS_REQUEST_INITIATED } from '@app/constants/constants';

type Props = {
  paramsGroupClasses: QueryParams,
};

const TrialBanners = (props: Props): React$Node | null => {
  const { paramsGroupClasses } = props;
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const [ currentUser, setCurrentUser ] = useState<User | null>(AuthService.user);
  const [ groupClassesRequests, setGroupClassesRequests ] = useState<ShortGroupClassBeneficiaryRequests>(BeneficiaryGroupClassesRequestsService.requestsValues);
  const [ isGroupClassLoading, setIsGroupClassLoading ] = useState<boolean>(GroupClassesService.isGroupClassLoading);

  const handleUpdateAuthService = (data: AuthServiceData) => {
    setCurrentUser(data.user);
  };

  const handleUpdateState = (data) => {
    setGroupClassesRequests(data.requests);
    setIsGroupClassLoading(data.isLoading);
  };

  useEffect(() => AuthService.onChange(handleUpdateAuthService), []);
  useEffect(() => BeneficiaryGroupClassesRequestsService.onChange(handleUpdateState), []);

  useEffect(() => {
    if (currentUser) {
      BeneficiaryGroupClassesRequestsService.fetchAll(currentUser.id, language, { status: GROUP_CLASS_REQUEST_INITIATED });
    }
  }, [currentUser, language]);

  const onDeleteRequest = useCallback((id: number): void => {
    if (currentUser) {
      BeneficiaryGroupClassesRequestsService.deleteOne(
        id,
        language,
        currentUser.id,
        { status: GROUP_CLASS_REQUEST_INITIATED },
      )
        .then(() => Toast.success(t('groupClasses.groupClass.peoples.form.wellUnsubscribe')))
        .then(() => {
          /* Reload Home Page */
          GroupClassesService.fetchGroupClasses(language, paramsGroupClasses);
        });
    }
  }, [currentUser, language, paramsGroupClasses, t]);

  if (groupClassesRequests.length === 0) {
    return null;
  }

  const sortedTrialBanners = groupClassesRequests.sort((a,b) => (
    b.createdAt - a.createdAt
  ));

  return (
    <div className="column is-12">
      <div className="trial-banners">
        <h2>{ t('groupClasses.trialBanner.title') }</h2>
        { isGroupClassLoading && (
          <Loader loaderType={ LOADER_TYPE_PAGE } />
        ) }
        { !isGroupClassLoading && sortedTrialBanners.map((groupClassesRequest) => (
          <TrialBanner
            key={ `trial-banner-${ groupClassesRequest.id }` }
            groupClass={ groupClassesRequest.groupClass }
            onDeleteRequest={ () => onDeleteRequest(groupClassesRequest.id) }
          />
        )) }
      </div>
    </div>
  );
};

export default TrialBanners;
