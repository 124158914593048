// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

import i18n from '@app/i18n/i18n';
import App from '@app/App';

import '@app/styles/main.scss';
import { Loader } from '@googlemaps/js-api-loader';
import { GOOGLE_MAPS_API_KEY } from '@app/constants/constants';

const client = new ApolloClient({
  uri: process.env.REACT_APP_FIZZUP_API_URL,
  cache: new InMemoryCache(),
});

const rootElement = document.getElementById('root');

const loader = new Loader({
  apiKey: GOOGLE_MAPS_API_KEY,
  version: 'weekly',
  libraries: ['places'],
});

if (rootElement !== null) {
  loader
    .load()
    .then(() => {
      ReactDOM.render(
        <React.StrictMode>
          <ApolloProvider client={ client }>
            <I18nextProvider i18n={ i18n }>
              <Router>
                <App/>
              </Router>
            </I18nextProvider>
          </ApolloProvider>
        </React.StrictMode>,
        rootElement,
      );
    });
}
