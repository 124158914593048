// @flow
import { useState, useCallback, useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import type { Colors, AnotherColors } from '@core/types';
import IconBurgerMenu from '@icons/components/IconBurgerMenu';

import SharedSessionService from '@groupSend/services/SharedSessionService';
import { type SharedSessionsData } from '@groupSend/types';

import { WEB_PATHS } from '@app/constants/paths';
import useAuth from '@user/hooks/useAuth';
import { useTranslation } from 'react-i18next';
import NavDropDown from '@header/components/NavDropDown';
import NavLinks from '@header/components/NavLinks';
import type { NavLink } from '@header/types';
import { SHARED_SESSIONS_TAB_NAME } from '@app/constants/tabs';
import SielBleuLogo from '@layout/components/siel-bleu-logo/SielBleuLogo';

export type Props = {
  brandUrl: string,
  color?: Colors | AnotherColors,
  dataTarget: string,
  hasShadow: boolean,
  isFixed: 'top' | 'bottom' | null,
  isSpaced: boolean,
  isTransparent: boolean,
  label: string,
  navLinks: NavLink[],
};

const { HOME } = WEB_PATHS;

const NavBar = (props: Props): React$Node => {
  const {
    label,
    brandUrl,
    dataTarget,
    color,
    isTransparent,
    isFixed,
    isSpaced,
    hasShadow,
    navLinks,
  } = props;

  const { user, isConnected } = useAuth();
  const { t, i18n: { language } } = useTranslation();

  const [ isOpen, setIsOpen ] = useState<boolean>(false);
  const [ countNewSharedSession, setCountNewSharedSession ] = useState<number>(SharedSessionService.countNewSharedSessionForBeneficiaryValue);

  const handleUpdateSharedSessions = (data: SharedSessionsData) => {
    setCountNewSharedSession(data.countNewSharedSessionForBeneficiary);
  };

  useEffect(() => SharedSessionService.onChange(handleUpdateSharedSessions), []);

  useEffect(() => {
    if (user) {
      SharedSessionService.getSharedSessionForBeneficiary(user.id, language);
      return () => SharedSessionService.resetCountNewSharedSessionForBeneficiary();
    }
  }, [user, language]);

  const toggleMenu = useCallback((): void => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const navBarClassName = useMemo((): string => clsx({
    'navbar': true,
    'has-shadow': !!hasShadow,
    'is-spaced': !!isSpaced,
    'is-transparent': !!isTransparent,
    [`is-fixed-${ isFixed || '' }`]: !!isFixed,
    [String(color)]: !!color,
  }), [hasShadow, isSpaced, isTransparent, isFixed, color]);

  const navBarMenuClassName = useMemo((): string => clsx({
    'navbar-menu': true,
    'is-active': isOpen,
  }), [isOpen]);

  const navBarIconBurgerMenuClassName = useMemo((): string => clsx({
    'navbar-burger': true,
    'is-active': !!isOpen,
  }), [isOpen]);

  const getLinks = useMemo((): NavLink[] => {
    navLinks.map((tab) => {
      if (tab.name === SHARED_SESSIONS_TAB_NAME) {
        tab.badge = countNewSharedSession;
      }
    });

    return navLinks;
  }, [navLinks, countNewSharedSession]);

  return (
    <nav
      className={ navBarClassName }
      role="navigation"
      aria-label={ label }
      onMouseLeave={ () => setIsOpen(false) }
    >
      <div className="navbar-brand-logo">
        <Link
          to={ brandUrl }>
          <SielBleuLogo className="siel-bleu-logo" />
        </Link>
      </div>

      <div className={ navBarIconBurgerMenuClassName }
        onClick={ toggleMenu }
        onKeyPress={ toggleMenu }>
        <IconBurgerMenu
          role="button"
          tabIndex={ 0 }
          aria-label="menu"
          aria-expanded="false"
          data-target={ dataTarget }
        />
        <h3>{ t('header.menu') }</h3>
      </div>

      <div className="navbar-end">
        <div id={ dataTarget } className={ navBarMenuClassName } >
          <NavLinks links={ getLinks } />
          { user && isConnected && (
            <NavDropDown />
          ) }
        </div>
      </div>
    </nav>
  );
};

NavBar.defaultProps = {
  brandUrl: HOME,
  color: 'is-primary',
  dataTarget: 'mainNavbar',
  hasShadow: false,
  isFixed: null,
  isSpaced: false,
  isTransparent: false,
  label: 'mainNavbar',
};

export default NavBar;
