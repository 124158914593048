// @flow
import jwt, { type Jwt, type JwtPayload } from 'jsonwebtoken';

import * as userConstants from '@user/constants';

const TokenService = {
  setToken: (tokenObj: string): void => {
    const informations = TokenService.parseToken(tokenObj);
    if (Object.keys(informations).length > 0) {
      localStorage.setItem(userConstants.tokenName, tokenObj);
      localStorage.setItem(userConstants.tokenIATStorage, String(informations?.iat || ''));
      localStorage.setItem(userConstants.tokenEXPStorage, String(informations?.exp || ''));
    }
  },

  getToken: (): string | null => (
    localStorage.getItem(userConstants.tokenName) ?? null
  ),

  // Function used on the v3 Frontend application. Used to extract informations from the token : iat
  // and exp. Some information already used on the v3.
  parseToken: (token: string): JwtPayload => {
    if (!token) return {};
    const decodedToken: Jwt | null = jwt.decode(token, { complete: true });
    return decodedToken?.payload || {};
  },

  getTokenValidity: (tokenObj?: string): boolean | null => {
    let token;

    if (tokenObj) {
      token = tokenObj;
    } else {
      token = TokenService.getToken();
    }

    if (token) {
      const tokenData = TokenService.parseToken(token);
      const dateNow = new Date();
      const timeStamp = dateNow.getTime() / 1000;

      if (tokenData?.exp && tokenData?.exp < timeStamp) {
        return false;
      }
      return true;
    }
    return null;
  },

  clearToken: (): void => {
    localStorage.removeItem(userConstants.tokenName);
  },
};

export default TokenService;
