// @flow
import type {
  Period,
  PeriodPayload,
  GroupClassDetailed,
  PaymentSchedule,
  PeriodItemOutput,
  PeriodItemPayloadOutput,
} from '@groupClass/types';

import { formatShortGroupClassFromPayload } from '@groupClass/helpers/GroupClassesUtils';

export const hydratePeriodFromPayload = (payload: PeriodPayload): Period => ({
  id: payload.id,
  startDate: payload.startDate,
  endDate: payload.endDate,
  amount: payload.amount,
  validatedAt: payload.validatedAt === null ? null : payload.validatedAt,
  hasPayment: payload.hasPayment,
  archivedAt: payload.archivedAt === null ? null : payload.archivedAt,
});

export const hydratePeriodItemOutputFromPayload = (payload: PeriodItemPayloadOutput): PeriodItemOutput => ({
  id: payload.id,
  groupClass: formatShortGroupClassFromPayload(payload.groupClass),
  startDate: payload.startDate,
  endDate: payload.endDate,
  amount: payload.amount,
  validatedAt: payload.validatedAt === null ? null : payload.validatedAt,
  archivedAt: payload.archivedAt === null ? null : payload.archivedAt,
  hasPayment: payload.hasPayment,
});

export const templatePeriodFromGroupClass = (period: Period, groupClass: GroupClassDetailed): string => {
  const { periods } = groupClass;
  const periodIndex = periods.findIndex((p) => p.id === period.id);

  return `${ periodIndex + 1 }/${ periods.length }`;
};

export const templatePaymentScheduleFromGroupClass = (paymentSchedule: PaymentSchedule, groupClass: GroupClassDetailed): string => {
  const { periods } = groupClass;
  const { period } = paymentSchedule;
  const periodIndex = periods.findIndex((p) => p.id === period.id);

  return `${ periodIndex + 1 }/${ periods.length }`;
};
