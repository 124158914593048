// @flow
import type { ActionMeta } from 'react-select';
import { useCallback, useMemo } from 'react';
import CreatableSelect from 'react-select/creatable';
import clsx from 'clsx';

import type { Colors, Sizes, Option, LabelProps, HelpProps } from '@core/types';
import Field from '_common/components/field/Field';
import Control from '_common/components/control/Control';
import Label from '_common/components/label/Label';
import Help from '_common/components/help/Help';
import AdvancedSelectFieldHelpers from '@shared/AdvancedSelectField/helpers/AdvancedSelectFieldHelpers';

import { errorColor, inputBorderColor, bgColorDisabled, colorDisabled, colorWhite } from '@app/constants/styles';

type OnInputChangeFunction = (newValue: string) => void;

type OnChangeFunction = (
  newValue: Option | Option[] | null,
  actionMeta: ActionMeta<Option>,
) => void;

export type MenuPlacementOption = 'top' | 'auto' | 'bottom';

type Props = {
  borderColor?: string,
  className?: string,
  color?: Colors,
  controlIsExpanded?: boolean,
  helpProps?: HelpProps,
  isClearable?: boolean,
  isDisabled?: boolean,
  isLoading?: boolean,
  isMulti?: string | boolean,
  label?: React$Node,
  labelProps?: LabelProps,
  menuPlacement?: MenuPlacementOption,
  menuPortalTarget?: HTMLElement | null,
  name: string,
  onInputChange?: OnInputChangeFunction,
  onChange: OnChangeFunction,
  options: Option[],
  placeholder?: React$Node,
  required?: boolean,
  selectAll?: string,
  size?: Sizes,
  value?: Option | null,
  asyncOptions?: Promise<Array<Option>>,
  // extra options for CreatableSelect:
  allowCreateWhileLoading?: boolean,
  createOptionPosition?: 'first' | 'last',
  formatCreateLabel?: (inputValue: string) => string,
  onCreateOption?: (inputValue: string) => any,
};

const CreatableSelectField = (props: Props): React$Node => {
  const {
    allowCreateWhileLoading,
    borderColor = inputBorderColor,
    className,
    color,
    controlIsExpanded,
    createOptionPosition,
    formatCreateLabel,
    helpProps,
    isClearable,
    isDisabled,
    isLoading,
    isMulti,
    label,
    labelProps = {},
    menuPlacement,
    menuPortalTarget,
    name,
    onChange,
    onCreateOption,
    onInputChange,
    options,
    placeholder,
    required,
    selectAll,
    size,
    value,
    ...others
  } = props;

  const { className: labelClassName, ...othersLabelProps } = labelProps;

  const customStyles = {
    control: (base) => ({
      ...base,
      border: `1px solid ${ color === 'is-danger' ? errorColor : isDisabled ? bgColorDisabled : borderColor }`,
      backgroundColor: isDisabled ? bgColorDisabled : colorWhite,
      ':hover': {
        borderColor: borderColor,
      },
    }),
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
    singleValue: (provided) => ({
      ...provided,
      color: isDisabled ? colorDisabled : provided.color,
    }),
  };

  const selectAllOption: Option = {
    value: '<SELECT_ALL>',
    label: (isMulti && String(isMulti)) || (selectAll && String(selectAll)) || '',
  };

  const getOptions = useMemo((): Option[] => {
    options.sort((a, b) => a.label.localeCompare(b.label));
    const optionsArray: Option[] = options ? [...options] : [];
    const isMultiString = isMulti && typeof isMulti === 'string';

    if (isMultiString || selectAll) {
      optionsArray.unshift(selectAllOption);
    }

    return optionsArray;
  }, [isMulti, options]);

  const values = useMemo((): Option[] => {
    const defaultValue: Option | Option[] = value ?? [];
    const returnValue: Option[] = Array.isArray(defaultValue) ? defaultValue : [defaultValue];
    return options && isMulti && returnValue.length === options.length
      ? [selectAllOption]
      : returnValue;
  }, [options, isMulti, value]);

  const isOptionSelected = useCallback((option): boolean => (
    !!isMulti
    && (values?.some((element) => element.value === option.value) || (values.includes(selectAllOption)))
  ), [isMulti, values, options]);

  const handleChange = useCallback((optionSelected: Option | Option[], actionMeta: ActionMeta<Option>): void => {
    const advancedSelectFieldHelpers = new AdvancedSelectFieldHelpers(optionSelected, actionMeta, values, options, isMulti);
    const newValue = advancedSelectFieldHelpers.handleUpdateValue();
    onChange(newValue, actionMeta);
  }, [values, options, isMulti, onChange]);

  const createSelecFieldLabelClassName = useMemo((): string => clsx({
    'g-label': true,
    'is-flex': true,
    [String(labelClassName)]: !!labelClassName,
  }), [labelClassName]);

  return (
    <Field className={ className }>
      { label && (
        <Label
          { ...othersLabelProps }
          className={ createSelecFieldLabelClassName }
          htmlFor={ name }
          size={ size }
        >
          { label } { required && '*' }
        </Label>
      ) }
      <Control isExpanded={ controlIsExpanded }>
        <CreatableSelect
          { ...others }
          allowCreateWhileLoading={ allowCreateWhileLoading }
          className="g-form-field"
          createOptionPosition={ createOptionPosition }
          formatCreateLabel={ formatCreateLabel }
          hideSelectedOptions={ false }
          isClearable={ isClearable }
          isDisabled={ isDisabled }
          isLoading={ isLoading }
          isMulti={ isMulti }
          isOptionSelected={ isOptionSelected }
          menuPlacement={ menuPlacement }
          menuPortalTarget={ menuPortalTarget }
          name={ name }
          onChange={ handleChange }
          onCreateOption={ onCreateOption }
          onInputChange={ onInputChange }
          options={ getOptions }
          placeholder={ placeholder }
          //required={required}
          styles={ customStyles }
          value={ values }
        />
      </Control>
      { helpProps && <Help { ...helpProps } textColor={ color } /> }
    </Field>
  );
};

CreatableSelectField.defaultProps = {
  options: ([]: Option[]),
  onChange: () => {},
};

export default CreatableSelectField;
