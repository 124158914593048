// @flow
import type { PaymentConditionType } from '@paymentConditionType/types';

export const parsePaymentConditionTypesFromPayload = (paymentConditionType: PaymentConditionType): PaymentConditionType => ({
  id: paymentConditionType.id,
  amount: paymentConditionType.amount,
  code: paymentConditionType.code,
  frequency: paymentConditionType.frequency,
  name: paymentConditionType.name,
});
