// @flow
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CheckboxField from '_common/components/checkbox-field/CheckboxField';
import PaymentInformationRow from '@shared/Payment/components/PaymentInformationRow';
import IconInfos from '@icons/components/IconInfos';

import DescriptionContainer from '_common/components/description-container/DescriptionContainer';
import PaymentConditionTypeService, { type PaymentConditionTypeServiceData } from '@paymentConditionType/services/PaymentConditionTypeService';
import { formatAmount } from '@payment/helpers/AmountUtils';
import DateService from '_common/services/DateService';

import type { GroupClassDetailed, UserRegistered } from '@groupClass/types';
import type { PaymentConditionType } from '@paymentConditionType/types';

import { PAYMENT_FREQUENCY_ANNUAL } from '@paymentConditionType/constants';

type Props = {
  data: UserRegistered | null,
  groupClass: GroupClassDetailed | null,
};

const PaymentInformation = (props: Props): React$Node => {
  const { data, groupClass } = props;
  const { t, i18n: { language } } = useTranslation();

  const [ conditionTypes, setConditionTypes ] = useState<PaymentConditionType[]>([]);

  const handleUpdateState = (data: PaymentConditionTypeServiceData): void => {
    setConditionTypes(data.conditionTypes);
  };

  useEffect(() => PaymentConditionTypeService.onChange(handleUpdateState), []);

  useEffect(() => {
    PaymentConditionTypeService.fetchAll(language);
  }, [language]);

  const reductionPaymentConditions = useMemo((): PaymentConditionType[] => (
    conditionTypes.filter((condition) => condition.frequency !== PAYMENT_FREQUENCY_ANNUAL)
  ), [conditionTypes]);

  const subscriptionPaymentCondition = useMemo((): PaymentConditionType | null => (
    conditionTypes.find((condition) => condition.frequency === PAYMENT_FREQUENCY_ANNUAL) || null
  ), [conditionTypes]);

  const feesPaymentInfo = useMemo((): string => {
    if (data?.subscriptionPaidAt && subscriptionPaymentCondition) {
      return t('groupClasses.groupClass.payment.feesPaid', {
        feeCost: subscriptionPaymentCondition.amount,
        datePaid: DateService.localDateDisplay(data.subscriptionPaidAt, language),
        interpolation: { escapeValue: false },
      });
    }
    return t('groupClasses.groupClass.payment.feesToPay');
  }, [data, subscriptionPaymentCondition, language]);

  const total = useMemo((): number => (
    (data?.paymentSchedules || [])
      .reduce((amount, schedule) => {
        const scheduleTotal = schedule.period.amount + schedule.discount + parseFloat(schedule.costAdjust);
        return amount + (scheduleTotal > 0 ? scheduleTotal : 0);
      }, 0)
  ), [data]);

  const alertPayments = useMemo((): boolean => (
    (data?.paymentSchedules || []).length !== (groupClass?.periods || []).length
  ), [data, groupClass]);

  return (
    <>
      <div className="beneficiary-payment-info">
        <h2 className="label-title">{ t('groupClasses.groupClass.peoples.form.paiementParticipantCondition') }</h2>
        <div className="payment-condition container">
          <div className="columns is-flex-wrap-wrap mb-3">
            <div className="column is-2-widescreen is-12-desktop is-12-tablet">
              <p>{ t('groupClasses.groupClass.payment.discounts') }</p>
            </div>
            { reductionPaymentConditions.map((paymentCondition, index) => {
              const found = data?.paymentConditions?.some(
                (dataPaymentCondition) => dataPaymentCondition.id === paymentCondition.id,
              );
              return (
                <div className="column is-2-widescreen is-4-desktop is-4-tablet"
                  key={ `payment-condition-${ paymentCondition.id }-${ index }` }
                >
                  <CheckboxField
                    className="condition-checkbox"
                    checked={ found }
                    isDisabled
                  >
                    { paymentCondition.name }
                  </CheckboxField>
                </div>
              );
            }) }
          </div>
        </div>
        <div className="payment-condition container">
          { subscriptionPaymentCondition && (
            <div className="columns is-flex-wrap-wrap">
              <div className="column is-4-desktop">
                <p>{ feesPaymentInfo }</p>
              </div>
              <div className="column is-8-desktop">
                <CheckboxField
                  className="condition-checkbox"
                  key={ `payment-condition-${ subscriptionPaymentCondition.id }` }
                  checked={ data?.paymentConditions?.some(
                    (dataPaymentCondition) => dataPaymentCondition.id === subscriptionPaymentCondition.id,
                  ) }
                  isDisabled
                >
                  { subscriptionPaymentCondition.name }
                </CheckboxField>
              </div>
            </div>
          ) }
        </div>
        <div className="flex-container">
          <div className="period-container">
            <div className="commentary-container">
              <DescriptionContainer
                value={ data?.paymentComment || '' }
                trad={ t('groupClasses.groupClass.peoples.form.paiementCommentTitle') }
              />
            </div>

            <h2 className="label-title">{ t('groupClasses.groupClass.payment.paymentSummary') }</h2>

            <table>
              <thead>
                <tr className="table-entitled">
                  <td>{ t('payment.period') }</td>
                  <td>{ t('groupClasses.groupClass.detailed.tarif') }</td>
                  <td>{ t('groupClasses.groupClass.payment.paymentConditions') }</td>
                  <td>{ t('groupClasses.groupClass.payment.costAdjust') }</td>
                  <td>{ t('groupClasses.groupClass.payment.amountDue') }</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                { (data?.paymentSchedules || []).map((paymentSchedule, index) => (
                  <PaymentInformationRow
                    key={ `ps-${ paymentSchedule.id ?? index }` }
                    paymentConditions={ conditionTypes }
                    paymentSchedule={ paymentSchedule }
                  />
                )) }
              </tbody>
            </table>
            { alertPayments && (
              <div className="custom-error-container">
                <div className="errorPeriods custom-error-message">
                  <IconInfos />
                  { t('display.error.validatedPeriods.archivedPeriods') }
                </div>
              </div>
            ) }

            <p className="total-amount">
              { t('groupClasses.groupClass.payment.totalAmountDue') }
              &nbsp;=&nbsp;
              { formatAmount(total, language) } &euro;
            </p>

          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentInformation;
