// @flow

import IconAvatar from '@icons/components/IconAvatar';
import IconChevron from '@icons/components/IconChevron';
import { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import useAuth from '@user/hooks/useAuth';
import NavLinks from '@header/components/NavLinks';
import RolesHelpers from '@user/helpers/RolesHelpers';
import GroupClassesService from '@groupClass/services/GroupClassesService';
import AuthService from '@user/services/AuthService';
import { useNavigate } from 'react-router-dom';

import { WEB_PATHS } from '@app/constants/paths';
import LoginService from '@login/services/LoginService';
import type { NavLink } from '@header/types';
import { isMobile, isTablet } from 'react-device-detect';
const { LOGIN, PAGE_CONTACT } = WEB_PATHS;

const NavDropDown = (): React$Node => {
  const { t, i18n: { resolvedLanguage } } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();

  const isSielBleuUser = useMemo((): boolean => RolesHelpers.isSielBleuUser(user), [user]);
  const isBeneficiary = useMemo((): boolean => RolesHelpers.isBeneficiary(user), [user]);
  const [ logoutUrl, setLogoutUrl ] = useState<string>(LoginService.logoutUrl || '');
  const [ isOpen, setIsOpen ] = useState<boolean>(false);

  const toggleMenu = useCallback((): void => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  useEffect(() => {
    LoginService.getLogoutUrl({})
      .then(setLogoutUrl)
      .catch(() => {});
  }, []);

  const menuDropdownLinks = useMemo((): NavLink[] => [
    {
      isVisible: isBeneficiary && !isSielBleuUser,
      to: `${ process.env.REACT_APP_SIELBLEU_APP_URL || '' }/${ resolvedLanguage || 'fr' }/settings`,
      children: t('header.settings'),
      external: true,
      target: '_blank',
      rel: 'noreferrer',
    },
    {
      isVisible: isSielBleuUser && !isBeneficiary,
      to: PAGE_CONTACT,
      children: t('common.contact'),
      external: false,
    },
    {
      isVisible: isBeneficiary && !isSielBleuUser,
      to: logoutUrl,
      children: t('header.logout'),
      external: true,
    },
  ], [isBeneficiary, isSielBleuUser, resolvedLanguage, logoutUrl]);

  const navBarHasDropdownClassName = useMemo((): string => clsx({
    'navbar-item': true ,
    'has-dropdown': true,
    'is-active': isOpen,
  }), [isOpen]);

  const navBarDropdownClassName = useMemo((): string => clsx({
    'navbar-dropdown': true,
    'is-right': true,
  }), []);

  const navBarLinkClassName = useMemo((): string => clsx({
    'navbar-link': true ,
    'is-arrowless': true,
  }), []);

  const logout = useCallback((): void => {
    GroupClassesService.resetService();
    AuthService.disconnect();
    navigate(LOGIN);
  }, []);

  return (
    <div
      onMouseLeave={ () => setIsOpen(false) }
      className={ navBarHasDropdownClassName }>
      <div
        onMouseOver={ () => !isMobile || !isTablet ? setIsOpen(true) : {} }
        onClick={ toggleMenu }
        className={ navBarLinkClassName } >
        <IconAvatar />
        <h3>{ user?.firstName }</h3>
        <IconChevron />
      </div>
      <div className={ navBarDropdownClassName }>
        <hr/>
        <NavLinks links={ menuDropdownLinks } />
        { isSielBleuUser && !isBeneficiary && (
          <div onClick={ logout } className="navbar-item" >{ t('header.logout') }</div>
        ) }
      </div>
    </div>
  );

};

export default NavDropDown;
