// @flow
import type { Tab } from '@shared/Navigation/TabElement/types';
import type { NavigationTab } from '@shared/Navigation/NavigationPills/types';

import { WEB_PATHS } from '@app/constants/paths';

const {
  PAYMENTS_DETAILED_CREDITCARD_OR_CHEQUE,
  PAYMENTS,
  PAYMENTS_PAYMENTS_DETAILED,
  PAYMENTS_BANK_DEPOSITS,
} = WEB_PATHS;

export const PAYMENT_REFERENCE_QUERY_PARAM = 'paymentId';

export const PAYMENT_SCHEDULE_AMOUNT_ORIGIN = 'SCHEDULE';

export const PAYMENT_FREQUENCY_PERIOD = 'PERIOD';

export const PAYMENT_TYPE_CB_CHQ = 'CB_CHQ';
export const PAYMENT_TYPE_CB_CHQ_FORMATTED = 'CB/CHQ';

export const PAYMENT_CARD_TYPE_AMEX = 'CB_AMEX';
export const PAYMENT_CARD_TYPE_MASTERCARD = 'CB_MASTERCARD';
export const PAYMENT_CARD_TYPE_VISA = 'CB_VISA';
export const PAYMENT_CARD_TYPE_CB = 'CB';

export const PAYMENT_STATUS_NOT_APPLICABLE = 'notApplicable';
export const PAYMENT_STATUS_PENDING = 'pending';
export const PAYMENT_STATUS_TOFINALIZE = 'toFinalize';
export const PAYMENT_STATUS_ACCEPTED = 'accepted';
export const PAYMENT_STATUS_OVERPAID = 'overpaid';
export const PAYMENT_STATUS_REFUSED = 'refused';
export const PAYMENT_STATUS_ABANDONED = 'abandoned';

export const CLASS_PAYMENT_STATUS_NOT_REGISTERED = 'notRegistered';
export const CLASS_PAYMENT_STATUS_TO_PAY = 'toPay';
export const CLASS_PAYMENT_STATUS_REQUESTED = 'requested';
export const CLASS_PAYMENT_STATUS_PAID = 'paid';
export const CLASS_PAYMENT_STATUS_FREE = 'free';

export const GENERAL_SELECTED = 'tab_general_selected';
export const DETAILED_SELECTED = 'tab_detailed_selected';
export const DEPOSIT_SELECTED = 'tab_deposit_selected';

export const CLASS_PAYMENT_MYGROUPCLASSES_STATUS = [
  CLASS_PAYMENT_STATUS_FREE,
  CLASS_PAYMENT_STATUS_PAID,
  CLASS_PAYMENT_STATUS_TO_PAY,
];

export const PAYMENT_TABS = ['payments', 'payments-detailed', 'payments-deposit'];

export const TAB_CREDIT_CARD = 'credit-cards';
export const TAB_CHEQUES = 'cheques';

const chequeDetailedTab = (id: number): Tab => ({
  name: TAB_CHEQUES,
  translationKey: 'payment.cheques',
  link: `${ PAYMENTS_DETAILED_CREDITCARD_OR_CHEQUE.replace(':classId', String(id)).replace(':paymentType', TAB_CHEQUES) }`,
  isActive: false,
});

const creditCardDetailedTab = (id: number): Tab => ({
  name: TAB_CREDIT_CARD,
  translationKey: 'payment.creditCard',
  link: `${ PAYMENTS_DETAILED_CREDITCARD_OR_CHEQUE.replace(':classId', String(id)).replace(':paymentType', TAB_CREDIT_CARD) }`,
  isActive: false,
});

export const getPaymentDetailedTab = (id: number): Tab[] => ([creditCardDetailedTab(id), chequeDetailedTab(id)]);

export const getPaymentNavigationTabs = (groupClassId: number): NavigationTab[] => [
  {
    translationKey: 'payment.navigationGeneral',
    url: PAYMENTS.replace(':classId', String(groupClassId)),
    isSelectedKey: GENERAL_SELECTED,
  },
  {
    translationKey: 'payment.navigationDetailed',
    url: PAYMENTS_PAYMENTS_DETAILED.replace(':classId', String(groupClassId)),
    isSelectedKey: DETAILED_SELECTED,
  },
  {
    translationKey: 'payment.navigationDeposit',
    url: PAYMENTS_BANK_DEPOSITS.replace(':classId', String(groupClassId)),
    isSelectedKey: DEPOSIT_SELECTED,
  },
];

export const WIDESCREEN_WIDTH = 1216;
