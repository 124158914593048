// @flow
import { useEffect, useState, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import IconCircleCheck from '@icons/components/IconCircleCheck';

import type { PaymentInformation } from '@payment/types';
import type { Coach } from '@coach/types';

import * as coreConstants from '@app/constants/paths';
import BeneficiaryLayout from '@layout/components/BeneficiaryLayout';
import PaymentProviderService, { type PaymentProviderServiceData } from '@payment/services/PaymentProviderService';
import DateService from '_common/services/DateService';
import { formatAmountWithEuros } from '@payment/helpers/AmountUtils';

import { LOADER_TYPE_PAGE } from '_common/components/loader/constants';
const { WEB_PATHS } = coreConstants;
const { GROUP_CLASSES_BENEFICIARY } = WEB_PATHS;

const MoneticoSuccess = (): React$Node => {
  const { i18n, t } = useTranslation();
  const { language } = i18n;
  const { search } = useLocation();
  const paymentId = new URLSearchParams(search.split('?')[1]).get('paymentId') ?? null;

  const [ paymentInformations, setPaymentInformations ] = useState<PaymentInformation | null>(PaymentProviderService.paymentInformations);

  /**
   * handleUpdateOfPaymentInformation
   */
  const handleUpdateOfPaymentInformation = (data: PaymentProviderServiceData): void => {
    setPaymentInformations(data.paymentInformations);
  };

  /**
   * useEffect used to add listener on the PaymentProviderService
   */
  useEffect(() => PaymentProviderService.onChange(handleUpdateOfPaymentInformation), []);

  /**
   * useEffect used to get informations about the payment id received in query parameters
   */
  useEffect((): void => {
    if (paymentId) {
      PaymentProviderService.fetchPaymentInformation(paymentId);
    }
  }, [paymentId]);

  const { groupClass, amount, reference, date, cardType } = paymentInformations || {};
  const { address } = groupClass || {};
  const { streetName, postalCode, city } = address || {};
  const { day, startTime } = groupClass || {};

  const dateTime = useMemo((): string => (
    (day && startTime) ? DateService.localStartDateAndTimeDisplay(day, startTime, language) : ''
  ), [day, startTime, language]);

  const formattedDate = useMemo((): string => (
    date ? DateService.localDateDisplay(new Date(date), language) : ''
  ), [date, language]);

  const formattedTime = useMemo((): string => (
    date ? DateService.localTimeDisplay(new Date(date), language) : ''
  ), [date, language]);

  const paymentDate = useMemo((): string => (
    (formattedDate && formattedTime)
      ? t('dates.dateTimeAt', { date: formattedDate, time: formattedTime, interpolation: { escapeValue: false } })
      : ''
  ), [formattedDate, formattedTime]);

  const mainCoach = useMemo((): Coach | null => (
    groupClass?.coaches.find((coach) => coach.isMainCoach) ?? null
  ), [groupClass]);

  const mainCoachName = useMemo((): string => (
    mainCoach?.sielBleuUser.firstName || ''
  ), [mainCoach]);

  const loading = useMemo((): boolean => (
    paymentInformations === null
  ), [paymentInformations]);

  const amountFormatted = useMemo((): string => (
    amount ? formatAmountWithEuros(amount, language) : ''
  ), [amount, language]);

  return (
    <BeneficiaryLayout isLoading={ loading } loaderType={ LOADER_TYPE_PAGE }>
      <div className="monetico-container container">
        <div className="monetico">
          <IconCircleCheck className="monetico-icon color-success" />
          <h1 className="monetico-title">{ t('payment.successPayment') }</h1>
          <p className="monetico-text">{ t('payment.textSuccess') }</p>
          <div>
            <h2 className="summary">{ t('payment.summary') }</h2>
            <table className="monetico-table">
              <tbody>
                <tr>
                  <td>{ t('payment.groupClassMonetico') }</td>
                  <td>
                    { groupClass?.activity?.name || '' }
                    <br />
                    { streetName } { postalCode } { city }
                    <br />
                    { dateTime } { t('common.with') } { mainCoachName }
                  </td>
                </tr>
                <tr>
                  <td>{ t('payment.paymentReference') }</td>
                  <td>{ reference }</td>
                </tr>
                <tr>
                  <td>{ t('payment.paymentDate') }</td>
                  <td>{ paymentDate }</td>
                </tr>
                <tr>
                  <td>{ t('payment.paymentMode') }</td>
                  <td>{ t(`payment.cardTypes.${ cardType }`) }</td>
                </tr>
                <tr>
                  <td>{ t('payment.amount') }</td>
                  <td>{ amountFormatted }</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="monetico-button">
            <Link
              to={ GROUP_CLASSES_BENEFICIARY }
              className="button button-tertiary"
            >
              { t('payment.btnPayment') }
            </Link>
          </div>
        </div>
      </div>
    </BeneficiaryLayout>
  );
};

export default MoneticoSuccess;
