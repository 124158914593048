// @flow
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PublicRoute from '@shared/PublicRoute/components/PublicRoute';
import Button, { buttonConstants } from '@shared/Button';

import PasswordUpdateService, { type PasswordUpdateServiceData } from '@login/services/PasswordUpdateService';
import type { Error, ErrorProps } from '@core/types';

import InputField from '_common/components/input-field/InputField';
import IconInfos from '@shared/Icons/components/IconInfos';

import { ERROR_IS_BLANK, INVALID_CHARACTERS } from '@helpers/TranslationHelpers';
import TranslationHelpers from '@helpers/TranslationHelpers';
import { errorColor } from '@app/constants/styles';

import { LOADER_TYPE_PAGE } from '_common/components/loader/constants';
import { WEB_PATHS } from '@app/constants/paths';
const { LOGIN } = WEB_PATHS;

const ResetKeyRequest = (): React$Node => {
  const { t, i18n: { language } } = useTranslation();

  const [ email, setEmail ] = useState<string>('');
  const [ isLoading, setLoading ] = useState<boolean>(PasswordUpdateService.isLoading);
  const [ emailInfo, setEmailInfo ] = useState<boolean>(false);
  const [ submitted, setSubmitted ] = useState<boolean>(false);
  const [ resetKeyRequestErrors, setResetKeyRequestErrors ] = useState<Error[]>([]);

  const handleUpdateState = (data: PasswordUpdateServiceData): void => {
    setLoading(data.isLoading);
  };

  useEffect(() => PasswordUpdateService.onChange(handleUpdateState), []);

  const handleSubmit = useCallback((event: SyntheticEvent<EventTarget>) => {
    event.preventDefault();
    const customError: Error[] = [];

    setEmailInfo(false);
    setSubmitted(false);

    if (email === '') {
      const error = { propertyPath: 'email', code: ERROR_IS_BLANK, message: '' };
      customError.push(error);
    }

    if (email !== '' && !email.includes('@')) {
      const error = { propertyPath: 'email', code: INVALID_CHARACTERS, message: '' };
      customError.push(error);
    }

    if (customError.length === 0) {
      PasswordUpdateService.resetKeyRequest(email);
      setEmailInfo(true);
      setSubmitted(true);
    }

    setResetKeyRequestErrors(customError);
  }, [email, setEmailInfo, setSubmitted, setResetKeyRequestErrors]);

  const handleEmailChange = (event: SyntheticEvent<HTMLInputElement>): void => {
    setEmail(event.currentTarget.value);
  };

  const errorsProps = useMemo((): ErrorProps => {
    let errorsPropsTmp = {};
    resetKeyRequestErrors.forEach((error) => {
      Object.assign(errorsPropsTmp, {
        [`${ error.propertyPath }`]: {
          color: 'is-danger',
          borderColor: errorColor,
          labelProps: { className: 'has-text-danger' },
          labelTextAreaProps: {
            className: 'has-text-danger label',
          },
          selectFieldHelperText: { text: t('commonError.thisFieldIsMandatory'), textColor: 'is-danger' },
          error: {
            text: t(TranslationHelpers.getCommonErrorKeyByCode(error.code, error.propertyPath)),
            textColor: 'is-danger',
          },
        },
      });
    });
    return errorsPropsTmp;
  }, [resetKeyRequestErrors, language]);

  return (
    <PublicRoute isLoading={ isLoading } loaderType={ LOADER_TYPE_PAGE }>
      <div className="login-container container">
        <div className="login-content">
          <h1>{ t('login.forgot.password') }</h1>
          <form>
            <InputField
              name="email"
              type="email"
              value={ email }
              onChange={ handleEmailChange }
              label={ t('login.email') }
              color={ errorsProps.email?.color }
              labelProps={ errorsProps.email?.labelProps }
              helpProps={ errorsProps.email?.error }
            />
            { emailInfo && (
              <div className="custom-error-container">
                <div className="custom-error-message custom-error-message-valid-email">
                  <IconInfos />
                  { t('common.valid.email', { email }) }
                </div>
              </div>
            ) }
            { !submitted && (
              <Button
                type={ buttonConstants.PRIMARY }
                onClick={ handleSubmit }
              >
                { t('common.reset.password') }
              </Button>
            ) }
            { submitted && (
              <div className="link-go-back-container">
                <Link to={ LOGIN } className="button button-primary link-go-back">
                  { t('common.back.groupClasses') }
                </Link>
              </div>
            ) }
          </form>
        </div>
      </div>
    </PublicRoute>
  );
};

export default ResetKeyRequest;
