// @flow
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import RequestsService, { type RequestsServiceData } from '@beneficiary/services/RequestsService';

import ListRequestRow from '@participant/components/ListRequestRow.jsx';
import NotificationBadge from '_common/components/notification-badge/NotificationBadge';

import type { ShortGroupClassRequests } from '@participant/types';

type UseParamProps = {
  classId: string,
};

const ListRequests = (): React$Node => {
  const { t, i18n: { language } } = useTranslation();
  const { classId: id } = useParams<UseParamProps>();

  const [ groupClassesRequests, setGroupClassesRequests ] = useState<ShortGroupClassRequests>(RequestsService.groupClassRequests);

  useEffect(() => {
    RequestsService.fetchGroupClassRequests(parseInt(id, 10), language);
    return () => RequestsService.reset();
  }, [id, language]);

  const handleRequestsUpdateState = (data: RequestsServiceData): void => {
    setGroupClassesRequests(data.groupClassRequests);
  };

  useEffect(() => RequestsService.onChange(handleRequestsUpdateState), []);

  return (
    <>
      { groupClassesRequests.length > 0 && (
        <>
          <div className="list-requests-header">
            <h2 className="title-two">
              <NotificationBadge>{ groupClassesRequests.length }</NotificationBadge>
              { t('groupClasses.groupClass.peoples.list.askingTitle') }
            </h2>
          </div>
          <ul>
            { groupClassesRequests.map((userRequest) => (
              <ListRequestRow request={ userRequest } key={ userRequest.id } />
            )) }
          </ul>
        </>
      ) }
    </>
  );
};

export default ListRequests;
