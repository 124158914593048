// @flow
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment/dist/moment';

import Loader from '_common/components/loader/Loader';
import IconTrash from '@icons/components/IconTrash';
import IconSquarePlus from '@icons/components/IconSquarePlus';
import IconEllipsisVertical from '@icons/components/IconEllipsisVertical';

import ModalAddChequeToBankDeposit from '@payment/components/ModalAddChequeToBankDeposit';

import Toast from '_common/services/Toast/Toast';
import PaymentChequeService from '@payment/services/PaymentChequeService';
import { templatePaymentScheduleFromGroupClass } from '@groupClass/helpers/PeriodUtils';

import type { BankDepositDetailed, ShortPaymentCheque } from '@payment/types';
import type { GroupClassDetailed } from '@groupClass/types';
import type { PaymentSchedule } from '@groupClass/types';

type Props = {
  isLoading: boolean,
  isActiveModalAddCheque: boolean,
  toggleModalAddCheque: () => void,
  paymentCheques: ShortPaymentCheque[],
  removeCheque: (cheque: ShortPaymentCheque) => void,
  addCheque: (cheque: ShortPaymentCheque) => void,
  groupClass: GroupClassDetailed | null,
  bankDeposit: BankDepositDetailed | null,
};

type OrganisedCheque = {
  id: number,
  reference: string,
  firstNameFormatted: string,
  lastNameUppercase: string,
  amountFormatted: string,
  periodFormatted: string,
  cheque: ShortPaymentCheque,
};

const ListCheques = (props: Props): React$Node => {
  const { isLoading, isActiveModalAddCheque, toggleModalAddCheque, paymentCheques, removeCheque, addCheque } = props;
  const { groupClass, bankDeposit } = props;

  const { t, i18n: { language } } = useTranslation();

  const handleDeleteCheque = (cheque: ShortPaymentCheque): void => {
    const { id, reference, groupClassRegisteredPaymentSchedule, amount } = cheque;

    PaymentChequeService.update(
      id,
      reference,
      groupClassRegisteredPaymentSchedule.id || 0,
      amount,
      null,
    )
      .then(removeCheque);
  };

  const showSuccessMessage = useCallback((message: string): void => {
    Toast.success(t(message));
  }, [t]);

  const periodLabel = (paymentSchedule: PaymentSchedule, groupClass: GroupClassDetailed, language: string): string => {
    if (paymentSchedule && groupClass) {
      const templateIndex = templatePaymentScheduleFromGroupClass(paymentSchedule, groupClass);
      const startDate = moment(paymentSchedule.period.startDate).locale(language).format('L');
      const endDate = moment(paymentSchedule.period.endDate).locale(language).format('L');

      return `${ t('payment.period') } (${ templateIndex })${ t('common.colons') } ${ startDate } - ${ endDate }`;
    }
    return '';
  };

  const organisedCheques = useMemo((): OrganisedCheque[] => {
    if (paymentCheques.length > 0 && groupClass !== null) {
      return paymentCheques
        .filter((paymentCheque: ShortPaymentCheque) => {
          const { groupClass: paymentChequeGroupClass } = paymentCheque;
          return paymentChequeGroupClass.id === groupClass.id;
        })
        .map((paymentCheque: ShortPaymentCheque) => {
          const { id, reference, person, groupClassRegisteredPaymentSchedule, amount } = paymentCheque;
          const { firstName, lastName } = person;
          const firstNameFormatted = firstName.charAt(0).toUpperCase() + firstName.slice(1);
          const lastNameUppercase = lastName.toUpperCase();

          const periodFormatted = periodLabel(groupClassRegisteredPaymentSchedule, groupClass, language);
          const amountFormatted = amount.toLocaleString(language, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });

          return {
            id, reference, firstNameFormatted, lastNameUppercase, amountFormatted, periodFormatted, cheque: paymentCheque,
          };
        });
    }

    return [];
  }, [paymentCheques, groupClass, periodLabel, language, paymentCheques.length]);

  return (
    <>
      <div className="payment-container payment-deposit">
        <div className="table-payment-container border-table-deposit">
          <table className="table border-table-deposit">
            <thead className="table-head border-table-deposit">
              <tr>
                <th className="table-head-title table-head-title-name table-head-border-left">
                  { t('payment.name') }
                </th>
                <th className="table-head-title table-head-title-deposit cell-text-centered">
                  { t('payment.period') }
                </th>
                <th className="table-head-title table-head-title-deposit cell-text-centered">
                  { t('payment.chequeNumberShort') }
                </th>
                <th className="table-head-title table-head-title-deposit cell-text-centered">
                  { t('payment.tablePaid') }
                </th>
                <th className="table-head-title table-head-title-deposit cell-text-centered table-head-border-right">
                  { t('payment.actions') }
                </th>
              </tr>
            </thead>
            <tbody className="table-body">
              { isLoading && (
                <tr>
                  <td colSpan="5" className="cell-text-centered">
                    <Loader className="cheques-loading" />
                  </td>
                </tr>
              ) }
              { !isLoading && organisedCheques.map((row, index) => {
                const { id, firstNameFormatted, lastNameUppercase, periodFormatted, reference, amountFormatted } = row;
                const { cheque } = row;

                return (
                  <tr className={ index % 2 === 0 ? '' : 'table-body-colored' } key={ id }>
                    <td className="table-body-cell cell-text-name">
                      { `${ firstNameFormatted } ${ lastNameUppercase }` }
                    </td>
                    <td className="table-body-cell cell-text-centered">
                      { periodFormatted }
                    </td>
                    <td className="table-body-cell cell-text-centered">
                      { reference }
                    </td>
                    <td className="table-body-cell cell-text-centered">
                      { amountFormatted }
                    </td>
                    <td className="table-body-cell cell-text-centered">
                      <div className="table-cell-actions-desktop">
                        <button className="button-action-remove" onClick={ () => handleDeleteCheque(cheque) }>
                          <IconTrash />
                        </button>
                      </div>
                      <div className="table-cell-actions-tablet navbar-item has-dropdown is-hoverable">
                        <IconEllipsisVertical className="icon-action navbar-link" />
                        <div className="navbar-dropdown">
                          <button className="button-action-remove" onClick={ () => handleDeleteCheque(cheque) }>
                            { t('payment.actionDelete') }
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              }) }
              { !isLoading && (
                <tr>
                  <td colSpan="4" className="table-cell-cheques-empty">
                    { t('payment.addChequeToBankDeposit') }
                  </td>
                  <td className="cell-text-centered">
                    <div className="cell-icon-action">
                      <IconSquarePlus className="icon-action-bank-deposit" onClick={ toggleModalAddCheque } />
                    </div>
                  </td>
                </tr>
              ) }
            </tbody>
          </table>
        </div>
      </div>
      <ModalAddChequeToBankDeposit
        isActiveModalAddCheque={ isActiveModalAddCheque }
        toggleModalAddCheque={ toggleModalAddCheque }
        showSuccessMessage={ showSuccessMessage }
        addChequeSuccess={ addCheque }
        initialBankDeposit={ bankDeposit }
        groupClass={ groupClass }
      />
    </>
  );
};

ListCheques.defaultProps = {
  addCheque: () => {},
};

export default ListCheques;
