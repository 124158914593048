// @flow
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from '_common/components/loader/Loader';
import ModalTransferPayment from '@payment/components/ModalTransferPayment';
import Toast from '_common/services/Toast/Toast';
import CreditCardLine from '@payment/components/CreditCardLine';

import GroupClassPaymentService, { type GroupClassPaymentServiceData } from '@payment/services/GroupClassPaymentService';

import { sortCreditCardBalance } from '@payment/helpers/SortPaymentUtils';

import type { PeriodItemOutput, GroupClassDetailed } from '@groupClass/types';
import type { CreditCardBalance } from '@payment/types';

import { LOADER_TYPE_BACKGROUND } from '_common/components/loader/constants';

type Props = {
  groupClass: GroupClassDetailed,
  period: PeriodItemOutput,
};

const CreditCardDetailed = (props: Props): React$Node => {
  const { groupClass, period } = props;
  const { t } = useTranslation();

  const [ balanceSelected, setBalanceSelected ] = useState<CreditCardBalance | null>(null);
  const [ creditCardBalances, setCreditCardBalances] = useState<CreditCardBalance[][]>(sortCreditCardBalance(GroupClassPaymentService.creditCardBalances));
  const [ isLoading, setIsLoading ] = useState<boolean>(GroupClassPaymentService.isLoading);
  const [ isActiveModalTransfer, setIsActiveModalTransfer ] = useState<boolean>(false);

  const handleUpdateCreditCardBalances = (data: GroupClassPaymentServiceData): void => {
    const balances = sortCreditCardBalance(data.creditCardBalances);
    setCreditCardBalances(balances);
    setIsLoading(data.isLoading);
  };

  useEffect((): Function => {
    const unsubscribe = GroupClassPaymentService.onChange(handleUpdateCreditCardBalances);
    return () => {
      unsubscribe();
      GroupClassPaymentService.reset();
    };
  }, []);

  useEffect((): void => {
    if (period) {
      const params = {
        id: groupClass.id,
        periodId: period.id,
      };
      GroupClassPaymentService.fetchCreditCardBalances(params);
    }
  }, [groupClass, period]);

  const toggleModalTransfer = useCallback((): void => {
    setIsActiveModalTransfer(!isActiveModalTransfer);
  }, [setIsActiveModalTransfer, isActiveModalTransfer]);

  const handleTransferClick = useCallback((balance: CreditCardBalance): void => {
    setBalanceSelected(balance);
    toggleModalTransfer();
  }, [toggleModalTransfer]);

  const handleTransferSuccess = useCallback((): void => {
    Toast.success(t('payment.transfer.success'));
    const params = {
      id: groupClass.id,
      periodId: period.id,
    };
    GroupClassPaymentService.fetchCreditCardBalances(params);
  }, [t, groupClass, period]);

  return (
    <div className="table-payment-container table-payment-container-inner">
      <table className="table">
        <colgroup>
          <col />
          <col className="table-cell-width-card"/>
          <col className="table-cell-width-card"/>
          <col className="table-cell-width-card"/>
          <col className="table-cell-width-card"/>
          <col className="table-cell-width-card"/>
        </colgroup>
        <thead className="table-head">
          <tr>
            <th className="table-head-title table-cell-title-name">
              { t('payment.name') }
            </th>
            <th className="table-head-title cell-text-centered">
              { t('payment.tableDue') }
            </th>
            <th className="table-head-title cell-text-centered">
              { t('payment.tablePaid') }
            </th>
            <th className="table-head-title cell-text-centered">
              { t('payment.paymentDate') }
            </th>
            <th className="table-head-title cell-text-centered">
              { t('payment.references') }
            </th>
            <th className="table-head-title cell-text-centered">
              { t('payment.actions') }
            </th>
          </tr>
        </thead>
        <tbody className="table-body">
          { isLoading && (
            <tr>
              <td colSpan="6" className="cell-text-centered">
                <Loader loaderType={ LOADER_TYPE_BACKGROUND } />
              </td>
            </tr>
          ) }
          { !isLoading && creditCardBalances.length === 0 && (
            <tr>
              <td colSpan="6" className="cell-text-centered">
                { t('payment.noresultForThisPeriod') }
              </td>
            </tr>
          ) }
          { creditCardBalances.map((personCreditCard, index) => (

            personCreditCard.map((creditCard, creditCardIndex) => (
              <CreditCardLine
                period={ period }
                creditCard={ creditCard }
                index={ index }
                handleTransferClick={ handleTransferClick }
                key={ creditCard.id }
                isFirstLine={ creditCardIndex === 0 }
              />
            ))
          )) }
        </tbody>
      </table>
      { balanceSelected && (
        <ModalTransferPayment
          isActive={ isActiveModalTransfer }
          toggleModal={ toggleModalTransfer }
          balance={ balanceSelected }
          onTransferSuccess={ handleTransferSuccess }
        />
      ) }
    </div>
  );
};

export default CreditCardDetailed;
