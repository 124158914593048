// @flow
import type { Listener } from '@core/types';
import type { ShortRegistered, UserRegistered } from '@groupClass/types';

import EventEmitter from '_common/services/EventEmitter';
import RegisteredsService from '@groupClass/services/RegisteredsService';
import BeneficiaryService from '@beneficiary/services/BeneficiaryService';
import ParticipantService from '@participant/services/ParticipantService';

import { PERSON_TYPE_PARTICIPANT } from '@person/constants';

const DEFAULT_PARAMS = {
  pagination: false,
  'order[person.lastName]': 'asc',
};

export type PeopleRequestsRegisteredsServiceData = {
  registeredId: number | null,
};

type Trigger = () => void;
type UpdateValues = (newRegisteredId: number | null) => void;
type DeleteRegistered = (registeredId: number, groupClassId: number, language: string) => Promise<boolean>;
type UpdatePersonRegistered = (registered: UserRegistered, groupClassId: number, language: string) => Promise<boolean>;
type OnChange = (listener: Listener) => Function;

class PeopleRequestsRegisteredsService {
  constructor() {
    this.eventEmitter = new EventEmitter();

    this.registeredId = null;
  }
  registeredId: number | null;
  eventEmitter: EventEmitter;

  get registeredIdValue(): number | null {
    return this.registeredId;
  }

  // TODO: Implements getters/setters

  updateValues: UpdateValues = (newRegisteredId: number | null): void => {
    this.registeredId = newRegisteredId;
    this.#trigger();
  };

  deleteRegistered: DeleteRegistered = (registeredId: number, groupClassId: number, language: string): Promise<ShortRegistered[]> => (
    RegisteredsService.deleteRegistered(registeredId, language)
      .then((isOKayDelete) => isOKayDelete ? Promise.resolve() : Promise.reject())
      .then(() => RegisteredsService.fetchGroupClassRegistereds(groupClassId, language, DEFAULT_PARAMS))
  );

  updatePersonRegistered: UpdatePersonRegistered = (registered: UserRegistered, groupClassId: number, language: string): Promise<ShortRegistered[]> => (
    new Promise((resolve) => (
      resolve(registered.person.type === PERSON_TYPE_PARTICIPANT
        ? ParticipantService.update(registered.person.id, { ...registered.person, membershipFee: false })
        : BeneficiaryService.update(registered.person.id, registered.person))
    ))
      .then(() => RegisteredsService.updateRegistered(registered, language))
      .then((registeredId) => { this.updateValues(registeredId); })
      .then(() => RegisteredsService.fetchGroupClassRegistereds(groupClassId, language, DEFAULT_PARAMS))
  );

  onChange: OnChange = (listener: Listener): Function => {
    const listenerFunction = this.eventEmitter.addListener(listener);
    this.#trigger();
    return listenerFunction;
  };

  /**
   * @private
   */
  #trigger: Trigger = (): void => {
    this.eventEmitter.trigger({
      registeredId: this.registeredId,
    });
  };
}

const instance: PeopleRequestsRegisteredsService = new PeopleRequestsRegisteredsService();
export default instance;
