// @flow
import { isIE, isEdge } from 'react-device-detect';

import type { Size } from '@core/types';

class DocumentHelpers {
  /**
   * getElementSize
   * Helper used to get the size (width and height) of a specific HTML element
   * @param {HTMLElement} node
   * @return {Size}
   */
  static getElementSize(node: HTMLElement): Size {
    const cs = getComputedStyle(node);
    return {
      width: parseFloat(cs.width.replace('px', '')),
      height: parseFloat(cs.height.replace('px', '')),
    };
  }

  /**
   * scrollTo
   * Helper used to scroll the page to a specific position (from the top)
   * @param {number} y
   * @return {void}
   */
  static scrollTo(y: number): void {
    if (isIE || isEdge) {
      window.scrollTo(0, y);
    } else {
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }

  /**
   * scrollToElement
   * Helper used to scroll the page to a specific HTML element, with or without a top offset
   * @param {HTMLElement} element
   * @param {number} offset
   * @return {void}
   */
  static scrollToElement(element: HTMLElement, offset: number = 0): void {
    const posElement = element.getBoundingClientRect().top + window.scrollY;
    const scrollPos = posElement - offset;
    DocumentHelpers.scrollTo(scrollPos);
  }

  /**
   * scrollToSelector
   * Helper used to scroll the page to a specific HTML element, acquired with his selector, with or
   * without a top offset
   * @param {string} selector
   * @param {number} offset
   * @return {void}
   */
  static scrollToSelector(selector: string, offset: number): void {
    const element = document.querySelector(selector);
    if (element) {
      DocumentHelpers.scrollToElement(element, offset);
    }
  }

  /**
   * addClass
   * Helper used to add a CSS class to specific HTML element
   * @param {HTMLElement} element
   * @param {string} className
   * @return {void}
   */
  static addClass(element: HTMLElement, className: string): void {
    element.classList.add(className);
  }

  /**
   * removeClass
   * Helper used to remove a CSS class to specific HTML element
   * @param {HTMLElement} element
   * @param {string} className
   * @return {void}
   */
  static removeClass(element: HTMLElement, className: string): void {
    element.classList.remove(className);
  }

  /**
   * fixedToBody
   * Enable to add the class name 'fixed' to the body node element. Used when an element is print on
   * the top layer of the web site. This class will prevent to scroll the page
   * @param {void}
   * @return {void}
   */
  static fixedToBody(): void {
    const bodyElement = document.querySelector('body');
    if (bodyElement) {
      this.addClass(bodyElement, 'fixed');
    }
  }

  /**
   * fixedToBodyEdge
   * Enable to add the class name 'fixed-edge' to the body node element. Used when an element is print on
   * the top layer of the web site. This class will prevent to scroll the page
   * @param {void}
   * @return {void}
   */
  static fixedToBodyEdge(): void {
    const bodyElement = document.querySelector('body');
    if (bodyElement) {
      this.addClass(bodyElement, 'fixed-edge');
    }
  }

  /**
   * unfixedToBody
   * Enable to remove the class name 'fixed' to the body node element
   * @param {void}
   * @return {void}
   */
  static unfixedToBody(): void {
    const bodyElement = document.querySelector('body');
    if (bodyElement) {
      this.removeClass(bodyElement, 'fixed');
    }
  }

  /**
   * stopPropagation
   * Helper used when native event needs to be block. Use to prevent propagation of the event to all
   * parents of the HTML Element (node)
   * @param {SyntheticEvent<EventTarget>} event
   * @return {void}
   */
  //static stopPropagation(event: SyntheticEvent<EventTarget>): void {
  static stopPropagation(event: SyntheticEvent<EventTarget>): void {
    event.stopPropagation();
  }

  /**
   * storeItem
   * Helper used to store some data inside the browser context storage. Used when we need to keep some
   * data between navigation and load of the website.
   * @param {string} storageName
   * @param {any} value
   * @return {void}
   */
  static storeItem(storageName: string, value: any): void {
    window.localStorage.setItem(storageName, JSON.stringify(value));
  }

  /**
   * getStoredItem
   * Helper used to get a stored item. The data will be retrieve from the browser context storage. Used
   * when we need to retrieve data from the previous navigation
   * @param {string} storageName
   * @return {any}
   */
  static getStoredItem(storageName: string): any {
    const item = window.localStorage.getItem(storageName);
    return item ? JSON.parse(item) : null;
  }

  /**
   * openLinkNewWindow
   * Helpers used to manage link in JS (without button or a).
   * @param {string} path
   * @param { '_blank' | '_self' | '_parent' | '_top' } scope
   * @returns {void}
   */
  static openLinkNewWindow(path: string, scope: string = '_blank'): void {
    window.open(path, scope);
  }
}

export default DocumentHelpers;
