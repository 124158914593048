// @flow
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { Option } from '@core/types';
import type { SearchCity } from '@shared/Locations/types';

import Button, { buttonConstants } from '@shared/Button';

import AdvancedSelectField from '@shared/AdvancedSelectField';
import SearchField from '@search/components/SearchField';
import DateHelpers from '@helpers/DateHelpers';

import useActivities from '@activity/hooks/useActivities';

type Props = {
  activity: Option | null,
  day: Option | null,
  locale: string,
  value: SearchCity | null,
  onChangeAddress: (event: SyntheticEvent<HTMLInputElement>) => void,
  onChangeDay: () => void,
  onChangeActivity: () => void,
  onSubmit: (event: SyntheticEvent<EventTarget>) => void,
};

const SearchVisitor = (props: Props): React$Node => {
  const { t, i18n: { language } } = useTranslation();
  const [ activities, isLoading ] = useActivities(language);
  const {
    activity,
    day,
    locale,
    value,
    onChangeActivity,
    onChangeAddress,
    onChangeDay,
    onSubmit,
  } = props;

  const activityOptions = useMemo((): Option[] => (
    activities && activities.map((activity) => ({
      label: activity.name,
      value: activity.id.toString(),
    }))
  ), [activities]);

  const daysOptions = DateHelpers.getDaysOfWeekOptions(language);

  return (
    <>
      <div className="find-group-classes">
        <h1 className="find-group-classes-title">{ t('groupClasses.titleVisitor') }</h1>
        <div className="faq-toggle-btn">
          <a key={ t('user.faq') } target="_blank" rel="noreferrer" href={ t('user.faq.link') }>{ t('user.faq') }</a>
        </div>
      </div>
      <div className="search-container">
        <div className="block-search-city">
          <SearchField value={ value } locale={ locale } isAdmin={ false } onChange={ onChangeAddress } />
          <AdvancedSelectField
            name="activity"
            placeholder={ t('groupClasses.activities') }
            options={ activityOptions }
            isClearable
            className="search-field-input"
            value={ activity }
            onChange={ onChangeActivity }
            label=""
            selectAll={ t('common.allFem') }
            isDisabled={ isLoading }
            isSearchable={ false }
          />
          <AdvancedSelectField
            name="day"
            options={ daysOptions }
            placeholder={ t('groupClasses.groupClass.detailed.day') }
            isClearable
            value={ day }
            className="search-field-input"
            onChange={ onChangeDay }
            label=""
            selectAll={ t('common.allMasc') }
            isSearchable={ false }
          />
        </div>
        <Button type={ buttonConstants.PRIMARY } onClick={ onSubmit }>
          { t('common.search') }
        </Button>
      </div>
    </>
  );
};

export default SearchVisitor;
