// @flow
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import SharedSessionService from '@groupSend/services/SharedSessionService';
import { type SharedSession } from '@groupSend/types';

import Image from '@shared/Image';
import Button, { buttonConstants } from '@shared/Button';

type Props = {
  sharedSession: SharedSession,
};

const CardItemSharedSession = (props: Props): React$Node => {
  const { sharedSession } = props;
  const { beneficiary, mediaObject, isDownloaded } = sharedSession;
  const { t, i18n: { language } } = useTranslation();

  const handleClickDownload = useCallback((sharedSessionId: number): void => {
    SharedSessionService.getSharedSessionDownload(sharedSessionId).then(() => {
      SharedSessionService.getSharedSessionForBeneficiary(beneficiary.id, language);
    });
  });

  return (
    <div
      className="column is-3-widescreen is-4-desktop is-6-tablet is-12-mobile"
      key={ sharedSession.id }
    >
      <div className="shared-sessions-card-item">
        <div className="has-text-centered">
          <Image className="thumbnail shared-sessions-card-image" src="icone_PDF.png" alt={ mediaObject.originalName } />
        </div>
        <div className="text-column">
          <div className="shared-sessions-card-item-name">{ mediaObject.originalName }</div>
          <div className="shared-sessions-card-item-button">
            <Button
              type={ isDownloaded ? buttonConstants.TERTIARY : buttonConstants.PRIMARY }
              isOutlined={ isDownloaded }
              className="see-more"
              onClick={ () => { handleClickDownload(sharedSession.id); } }
            >
              { t('common.download') }
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardItemSharedSession;
