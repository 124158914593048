// @flow
import type { Error, Listener, QueryParams } from '@core/types';
import type { Balance, CreditCardBalance, ChequeBalance } from '@payment/types';
import * as coreConstants from '@app/constants/paths';
import EventEmitter from '_common/services/EventEmitter';
import ApiService from '@api/service';
import {
  hydrateBalancesFromPayload,
  hydrateCreditCardBalancesFromPayload,
  hydrateChequeBalancesFromPayload,
} from '@payment/helpers/GroupClassPaymentUtils';

const { API_PATHS } = coreConstants;
const { GROUP_CLASSES } = API_PATHS;

export type GroupClassPaymentServiceData = {
  balances: Balance[],
  creditCardBalances: CreditCardBalance[],
  chequeBalances: ChequeBalance[],
  isLoading: boolean,
  errors: Error[],
};

type CleanErrors = () => void;
type FetchBalances = (params: QueryParams) => void;
type FetchCreditCardBalances = (params: QueryParams) => void;
type FetchChequeBalances = (params: QueryParams) => void;
type Reset = () => void;
type OnChange = (listener: Listener) => Function;
type UpdateValues = (
  newBalances: Balance[],
  newCreditCardBalances: CreditCardBalance[],
  newChequeBalances: ChequeBalance[],
  newIsLoading: boolean,
  newErrors: Error[],
) => void;

class GroupClassPaymentService {
  constructor() {
    this.eventEmitter = new EventEmitter();

    this.balances = [];
    this.creditCardBalances = [];
    this.chequeBalances = [];
    this.isLoading = false;
    this.errors = [];
  }

  eventEmitter: EventEmitter;

  balances: Balance[];
  creditCardBalances: CreditCardBalance[];
  chequeBalances: ChequeBalance[];
  isLoading: boolean;
  errors: Error[];

  get getBalances(): Balance[] {
    return this.balances;
  }

  get getCreditCardBalances(): CreditCardBalance[] {
    return this.creditCardBalances;
  }

  get getChequeBalances(): ChequeBalance[] {
    return this.chequeBalances;
  }

  get isLoadingValue(): boolean {
    return this.isLoading;
  }

  get errorsValues(): Error[] {
    return this.errors;
  }

  updateValues: UpdateValues = (
    newBalances: Balance[],
    newCreditCardBalances: CreditCardBalance[],
    newChequeBalances: ChequeBalance[],
    newIsLoading: boolean,
    newErrors: Error[] = [],
  ): void => {
    this.balances = newBalances;
    this.creditCardBalances = newCreditCardBalances;
    this.chequeBalances = newChequeBalances;
    this.isLoading = newIsLoading;
    this.errors = newErrors;
    this.#trigger();
  };

  cleanErrors: CleanErrors = (): void => {
    this.updateValues(this.balances, this.creditCardBalances, this.chequeBalances, false, []);
  };

  fetchBalances: FetchBalances = (params: QueryParams): void => {
    const { id, periodId } = params;

    this.updateValues(this.balances, this.creditCardBalances, this.chequeBalances, true, this.errors);

    ApiService.request({
      method: 'get',
      url: `${ GROUP_CLASSES }/${ id }/periods/${ periodId }/balances`,
      params: {
        id,
        periodId,
      },
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;

          const newBalances = hydrateBalancesFromPayload(data);
          this.updateValues(newBalances, this.creditCardBalances, this.chequeBalances, false, this.errors);
        }
        else {
          this.updateValues(this.balances, this.creditCardBalances, this.chequeBalances, false, this.errors);
        }
      })
      .catch(() => {
        this.updateValues(this.balances, this.creditCardBalances, this.chequeBalances, false, this.errors);
      });
  };

  fetchCreditCardBalances: FetchCreditCardBalances = (params: QueryParams): void => {
    const { id, periodId } = params;

    this.updateValues(this.balances, this.creditCardBalances, this.chequeBalances, true, this.errors);

    ApiService.request({
      method: 'get',
      url: `${ GROUP_CLASSES }/${ id }/periods/${ periodId }/credit_card/balances`,
      params: {
        id,
        periodId,
      },
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;

          const newCreditCardBalances = hydrateCreditCardBalancesFromPayload(data);

          this.updateValues(this.balances, newCreditCardBalances, this.chequeBalances, false, this.errors);
        }
        else {
          this.updateValues(this.balances, this.creditCardBalances, this.chequeBalances, false, this.errors);
        }
      })
      .catch(() => {
        this.updateValues(this.balances, this.creditCardBalances, this.chequeBalances, false, this.errors);
      });
  };

  fetchChequeBalances: FetchChequeBalances = (params: QueryParams): void => {
    const { id, periodId } = params;

    this.updateValues(this.balances, this.creditCardBalances, this.chequeBalances, true, this.errors);
    ApiService.request({
      method: 'get',
      url: `${ GROUP_CLASSES }/${ id }/periods/${ periodId }/cheque/balances`,
      params: { id, periodId },
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;
          const newChequeBalances = hydrateChequeBalancesFromPayload(data);
          this.updateValues(this.balances, this.creditCardBalances, newChequeBalances, false, this.errors);
        }
        else {
          this.updateValues(this.balances, this.creditCardBalances, this.chequeBalances, false, this.errors);
        }
      })
      .catch(() => {
        this.updateValues(this.balances, this.creditCardBalances, this.chequeBalances, false, this.errors);
      });
  };

  reset: Reset = (): void => {
    this.updateValues([], [], [], false, []);
  };

  onChange: OnChange = (listener: Listener): Function => {
    const listenerFunction = this.eventEmitter.addListener(listener);
    this.#trigger();
    return listenerFunction;
  };

  #trigger = () => {
    this.eventEmitter.trigger({
      balances: this.balances,
      creditCardBalances: this.creditCardBalances,
      chequeBalances: this.chequeBalances,
      isLoading: this.isLoading,
      errors: this.errors,
    });
  };

}

const instance: GroupClassPaymentService = new GroupClassPaymentService();
export default instance;
