// @flow
import { useTranslation } from 'react-i18next';
import IconFacebook from '@icons/components/IconFacebook';
import IconX from '@icons/components/IconX';
import IconYoutube from '@icons/components/IconYoutube';
import IconLinkedin from '@icons/components/IconLinkedin';
import IconInstagram from '@icons/components/IconInstagram';

const Social = (): React$Node => {
  const { t } = useTranslation();

  const handleSocialLink = (link: string) => {
    window.open(link, '_blank');
  };

  return (
    <div className="social">
      <div className="follow">
        <h3>{ t('social.follow') }</h3>
      </div>
      <div className="icons">
        <IconFacebook onClick={ () => handleSocialLink(t('social.facebook.link')) } />
        <IconX onClick={ () => handleSocialLink(t('social.x.link')) } />
        <IconLinkedin onClick={ () => handleSocialLink(t('social.linkedin.link')) } />
        <IconYoutube onClick={ () => handleSocialLink(t('social.yt.link')) } />
        <IconInstagram onClick={ () => handleSocialLink(t('social.instagram.link')) } />
      </div>
    </div>
  );
};

export default Social;
