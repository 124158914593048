// @flow
import type { Listener, QueryParams, RequestStatusesType } from '@core/types';
import type { ShortGroupClassRequestBeneficiaryPayload, ShortGroupClassBeneficiaryRequests } from '@participant/types';
import EventEmitter from '_common/services/EventEmitter';
import ApiService from '@api/service';
import { hydrateBeneficiaryShortRequest } from '@groupClass/helpers/RequestsUtils';
import { API_PATHS } from '@app/constants/paths';

import { RequestStatuses } from '@core/constants';

export type BeneficiaryGroupClassesRequestsServiceData = {
  requests: ShortGroupClassBeneficiaryRequests,
  isLoading: boolean,
  registeredsRequestStatus: RequestStatusesType,
};

type DeleteOne = (requestId: number, language: string, beneficiaryId?: number | string, params?: QueryParams) => Promise<any>;
type FetchAll = (beneficiaryId?: number | string, language: string, params?: QueryParams) => Promise<number>;
type OnChange = (listener: Listener) => Function;
type UpdateValues = (newRequests: ShortGroupClassBeneficiaryRequests, newIsLoading: boolean, requestStatusRegistereds: RequestStatusesType) => void;
type Trigger = () => void;

const { GROUP_CLASS_REQUESTS_BENEFICIARY, GROUP_CLASS_REQUEST } = API_PATHS;

let sourceAll = ApiService.createToken();

class BeneficiaryGroupClassesRequestsService {
  constructor() {
    this.eventEmitter = new EventEmitter();

    this.requests = [];
    this.isLoading = false;
    this.registeredsRequestStatus = RequestStatuses.IDLE;
  }
  requests: ShortGroupClassBeneficiaryRequests;
  isLoading: boolean;
  registeredsRequestStatus: RequestStatusesType;
  eventEmitter: EventEmitter;

  get requestsValues(): ShortGroupClassBeneficiaryRequests {
    return this.requests;
  }

  get isLoadingValue(): boolean {
    return this.isLoading;
  }

  get registeredsRequestStatusValue(): RequestStatusesType {
    return this.registeredsRequestStatus;
  }

  // TODO: Implements getters/setters

  updateValues: UpdateValues = (newRequests: ShortGroupClassBeneficiaryRequests, newIsLoading: boolean, requestStatusRegistereds: RequestStatusesType): void => {
    this.requests = newRequests;
    this.isLoading = newIsLoading;
    this.registeredsRequestStatus = requestStatusRegistereds;
    this.#trigger();
  };

  fetchAll: FetchAll = (beneficiaryId?: number | string, language: string, params?: QueryParams = {}): Promise<number> => {
    sourceAll.cancel();
    sourceAll = ApiService.createToken();

    this.updateValues(this.requests, true, RequestStatuses.PENDING);

    return ApiService.request({
      method: 'get',
      url: GROUP_CLASS_REQUESTS_BENEFICIARY.replace(':id', String(beneficiaryId)),
      cancelToken: sourceAll.token,
      headers: {
        'Accept-Language': language,
      },
      params,
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;

          const groupClassesRequestsData: ShortGroupClassBeneficiaryRequests = data['hydra:member']
            .map((request: ShortGroupClassRequestBeneficiaryPayload) => (
              hydrateBeneficiaryShortRequest(request)
            ));
          this.updateValues(groupClassesRequestsData, false, RequestStatuses.SUCCEEDED);
          return data['hydra:totalItems'];
        } else {
          return Promise.reject();
        }
      })
      .catch(() => {
        this.updateValues(this.requests, false, RequestStatuses.FAILED);
        return Promise.reject();
      });
  };

  deleteOne: DeleteOne = (requestId: number, language: string, beneficiaryId?: number | string, params?: QueryParams = {}): Promise<any> => {
    this.updateValues(this.requests, true, RequestStatuses.PENDING);

    return ApiService.request({
      method: 'delete',
      url: `${ GROUP_CLASS_REQUEST }/${ requestId }`,
      headers: {
        'Accept-Language': language,
      },
    })
      .then(() => {
        this.fetchAll(beneficiaryId, language, params);
        return Promise.resolve();
      })
      .catch(() => {
        this.updateValues(this.requests, false, RequestStatuses.FAILED);
        return Promise.reject();
      });
  };

  onChange: OnChange = (listener: Listener): Function => {
    const listenerFunction = this.eventEmitter.addListener(listener);
    this.#trigger();
    return listenerFunction;
  };

  /**
   * @private
   */
  #trigger: Trigger = (): void => {
    this.eventEmitter.trigger({
      requests: this.requests,
      isLoading: this.isLoading,
    });
  };
}

const instance: BeneficiaryGroupClassesRequestsService = new BeneficiaryGroupClassesRequestsService();
export default instance;
