// @flow
import { useEffect, useMemo, useState, useCallback } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import type { UserRegistered } from '@groupClass/types';
import type { Beneficiary } from '@beneficiary/types';
import type { Error } from '@core/types';

import ParticipantIndicator from '@shared/ParticipantIndicator';
import Button, { buttonConstants } from '@shared/Button';
import FormContainer from '@shared/FormContainer';
import Toast from '_common/services/Toast/Toast';

import PersonalForm from '@shared/Personal/components/PersonalForm';

import BeneficiaryService, { type BeneficiaryServiceData } from '@beneficiary/services/BeneficiaryService';

import { WEB_PATHS } from '@app/constants/paths';
const { PARTICIPANTS_REGISTERED_ID } = WEB_PATHS;

type Props = {
  registered: UserRegistered,
};

type UseParamProps = {
  classId: string,
};

const ParticipantEditPerson = (props: Props): React$Node => {
  const { registered } = props;
  const { t, i18n: { language } } = useTranslation();
  const { classId } = useParams<UseParamProps>();
  const navigate = useNavigate();

  const [ isLoading, setLoading ] = useState<boolean>(BeneficiaryService.isLoadingValue);
  const [ beneficiary, setBeneficiary ] = useState<Beneficiary | null>(BeneficiaryService.beneficiaryValue);
  const [ errors, setErrors ] = useState<Error[]>(BeneficiaryService.errorsValues);
  const [ authorizeData, setAuthorizeData ] = useState<boolean>(true);

  const toggleAuthorizeData = useCallback((): void => {
    setAuthorizeData(!authorizeData);
  }, [authorizeData]);

  const {
    firstName = '',
    lastName = '',
  } = beneficiary || {};

  const lastNameUpperCased = useMemo((): string => (
    lastName.toUpperCase()
  ), [lastName]);

  const handleUpdateState = (data: BeneficiaryServiceData): void => {
    setLoading(data.isLoading);
    if (!data.isLoading && data.errors.length === 0) setBeneficiary(data.beneficiary);
    setErrors(data.errors);
  };

  useEffect(() => BeneficiaryService.onChange(handleUpdateState), []);

  useEffect(() => {
    BeneficiaryService.fetchOne(registered.person.id, language);
  }, [registered, language]);

  const updateParticipant = useCallback((): void => {
    if (beneficiary) {
      BeneficiaryService.update(beneficiary.id, beneficiary)
        .then(() => Toast.success(t('groupClasses.groupClass.peoples.form.wellUpdated')))
        .then(() => navigate(PARTICIPANTS_REGISTERED_ID.replace(':classId', classId).replace(':registeredId', String(registered.id))));
    }
  }, [beneficiary, classId]);

  const footerButtons: React$Node = useMemo((): React$Node => (
    <div className="button-edit-mode">
      <Link
        to={ PARTICIPANTS_REGISTERED_ID.replace(':classId', classId).replace(':registeredId', String(registered.id)) }
        className="button button-tertiary button-cancel button-position"
      >
        { t('common.cancel') }
      </Link>
      <Button
        className="button-position"
        onClick={ updateParticipant }
        type={ buttonConstants.PRIMARY }
      >
        { t('groupClasses.groupClass.peoples.form.confirmButton') }
      </Button>
    </div>
  ), [classId, registered, language, t, updateParticipant]);

  return (
    <FormContainer
      title={
        <>
          <ParticipantIndicator isBeneficiary extraCss="participant-tab" />
          { !isLoading && (firstName + ' ' + lastNameUpperCased) }
        </>
      }
      className="beneficiary-request-card"
      isLoading={ isLoading }
      footerButtonActions={ footerButtons }
    >
      <div className="block-infos">
        <div className="block-title">
          <h2 className="title-two">{ t('groupClasses.groupClass.peoples.form.personalInfosTitle') }</h2>
        </div>
        <div className="separator" />
        <div className="container containerPersonnalInfos">
          <div className="columns">
            <div className="column is-12">
              <div className="content is-open">
                <PersonalForm
                  user={ beneficiary }
                  setUser={ setBeneficiary }
                  errors={ errors }
                  authorizeData={ authorizeData }
                  toggleAuthorizeData={ toggleAuthorizeData }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormContainer>
  );
};

export default ParticipantEditPerson;
