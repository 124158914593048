// @flow
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '@shared/Modal/Modal';
import Button, { buttonConstants } from '@shared/Button';

import GroupClassExportService from '@groupClass/services/GroupClassExportService';

type Props = {
  isActiveModalExport: boolean,
  toggleModalExport: () => void,
};

const ModalClassExport = (props: Props): React$Node => {
  const { isActiveModalExport, toggleModalExport } = props;
  const { t, i18n: { language } } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback((): void => {
    setIsLoading(true);
    GroupClassExportService.FetchExport()
      .then(toggleModalExport)
      .finally(() => setIsLoading(false));
  }, []);

  const footerModalExport: React$Node = useMemo((): React$Node => (
    <footer className="modal-export-footer">
      <Button
        type={ buttonConstants.PRIMARY }
        className="button-modal"
        onClick={ handleSubmit }
        disabled={ isLoading }
      >
        { isLoading ? t('common.loading') : t('common.validate') }
      </Button>
    </footer>
  ), [handleSubmit, language, isLoading]);

  return (
    <Modal
      isActive={ isActiveModalExport }
      isClipped
      onClose={ toggleModalExport }
      title={ t('groupClasses.groupClassExport') }
      footer={ footerModalExport }
      modalContentClassName="modal-export-card"
    >
      <div className="modal-export-card">
        <p className="modal-export-text">{ t('groupClasses.modalClassExportText') }</p>
        <div id="date-popup"/>
      </div>
    </Modal>
  );
};

export default ModalClassExport;
