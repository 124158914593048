// @flow
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import NavBar from '@header/components/NavBar';
import Button, { buttonConstants } from '@shared/Button';

import useAuth from '@user/hooks/useAuth';
import LoginService from '@login/services/LoginService';
import { getGroupClassesTabs } from '@app/helpers/tabs';

import type { NavLink } from '@header/types';

import * as appTabsConstants from '@app/constants/tabs';
import RolesHelpers from '@user/helpers/RolesHelpers';
import { PROMPT_TYPE_LOGIN, PROMPT_TYPE_REGISTER } from '@login/constants';
import { WEB_PATHS } from '@app/constants/paths';
import HeaderBanner from '@banner/components/HeaderBanner';
import { getLanguageFormat } from '_common/services/LanguageUtils';

const { HOME, GROUP_CLASSES_BENEFICIARY } = WEB_PATHS;

const locales = [];
locales['fr-FR'] = 'fr';
locales['es-ES'] = 'es';

const Header = (): React$Node => {
  const { user, isConnected } = useAuth();
  const { t, i18n: { language } } = useTranslation();
  const isBeneficiary = useMemo((): boolean => RolesHelpers.isBeneficiary(user), [user]);

  const [activeTab, setActiveTab] = useState<string>(appTabsConstants.GROUP_CLASSES_TAB_NAME);
  const [loginUrl, setLoginUrl] = useState<string>('');
  const [registerUrl, setRegisterUrl] = useState<string>('');
  const [hideBannerEmail, setHideBannerEmail] = useState<boolean>(true);

  const alreadyRequestValidationMail = useMemo((): boolean => document.cookie.split(';').some((item) => item.trim().startsWith('requestSendingMailValidation=true')), []);

  useEffect(() => {
    setHideBannerEmail(!isBeneficiary || alreadyRequestValidationMail || user?.confirmed);
  }, [alreadyRequestValidationMail, user, isBeneficiary] );

  useEffect(() => {
    LoginService.getLoginUrl({ prompt: PROMPT_TYPE_LOGIN, locale: locales[getLanguageFormat(language)] })
      .then(setLoginUrl)
      .catch(() => {});
  }, []);

  useEffect(() => {
    LoginService.getLoginUrl({ prompt: PROMPT_TYPE_REGISTER, locale: locales[getLanguageFormat(language)] })
      .then(setRegisterUrl)
      .catch(() => {});
  }, []);

  const setDefaultActiveTab = useCallback((): void => {
    const urlTab = getGroupClassesTabs(user?.role || []).find((tab) => tab.link === location.pathname);
    if (urlTab) {
      if (activeTab !== urlTab.name) setActiveTab(urlTab.name);
    } else {
      setActiveTab(appTabsConstants.GROUP_CLASSES_TAB_NAME);
    }
  }, [user, location, activeTab, setActiveTab]);

  useEffect(() => {
    setDefaultActiveTab();
  }, [location, setDefaultActiveTab]);

  const connectedLinks = useMemo((): NavLink[] => getGroupClassesTabs(user?.role || []).map((tab) => ( {
    isVisible: true,
    to: tab.link,
    children: t(tab.translationKey),
    external: false,
    name: tab.name,
  })), [user, language]);

  const publicLinks = useMemo((): NavLink[] => [
    {
      isVisible: true,
      to: registerUrl,
      children: <Button type={ buttonConstants.QUINARY }>{ t('common.register') }</Button>,
      external: true,
      asButton: true,
    },
    {
      isVisible: true,
      to: loginUrl,
      children: <Button type={ buttonConstants.QUARTERNARY }>{ t('common.login') }</Button>,
      external: true,
      asButton: true,
    },
  ], [loginUrl, registerUrl, language]);

  const requestSendEmail = () => {
    document.cookie = 'requestSendingMailValidation=true;path=/';
    window.location.href = `${ process.env.REACT_APP_SIELBLEU_APP_URL || '' }/email-verify-request`;
  };

  const emailCta = (
    <span className="cta" onClick={ requestSendEmail }>
      { t('header.banner.emailCta') }
    </span>
  );

  return (
    <>
      <NavBar color="is-white" brandUrl={ isBeneficiary ? GROUP_CLASSES_BENEFICIARY : HOME } navLinks={ user && isConnected ? connectedLinks : publicLinks } />
      { !hideBannerEmail && <HeaderBanner text={ t('header.banner.emailValidation') } cta={ emailCta } /> }
    </>
  );
};

export default Header;
