// @flow
import { useState, useCallback, useEffect, useMemo } from 'react';
import { format as dateFmt } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment/dist/moment';

import withGroupClass from '@hoc/withGroupClass';

import AdminLayout from '@layout/components/AdminLayout';

import Toast from '_common/services/Toast/Toast';
import Button, { buttonConstants } from '@shared/Button';
import AdvancedSelectField from '@shared/AdvancedSelectField/components/AdvancedSelectField';
import InputField from '_common/components/input-field/InputField';
import BankDepositInput from '@payment/components/BankDepositInput';
import ButtonGoBack from '@shared/Button/components/ButtonGoBack';
import IconWarning from '@icons/components/IconWarning';
import Loader from '_common/components/loader/Loader';

import RegisteredsService, { type RegisteredsServiceData } from '@groupClass/services/RegisteredsService';
import PaymentChequeService, { type PaymentChequeServiceData } from '@payment/services/PaymentChequeService';
import BankDepositService from '@payment/services/BankDepositService';
import TranslationHelpers, { ERROR_IS_BLANK, ERROR_NOT_EQUAL_LENGTH, ERROR_INVALID_FORMAT, ERROR_MISSING_OR_INVALID } from '@helpers/TranslationHelpers';
import { findTheNearestPaymentSchedule, getPaymentScheduleAmountDue, getSelectedPaymentSchedule } from '@payment/helpers/PeriodPaymentUtils';
import { templatePaymentScheduleFromGroupClass } from '@groupClass/helpers/PeriodUtils';

import type { GroupClassDetailed, PaymentSchedule, ShortRegistered, UserRegistered } from '@groupClass/types';
import type { Error, Option, ErrorProps } from '@core/types';
import type { BankDepositFormData, PaymentChequeFormData, BankDepositDetailed } from '@payment/types';

import { RE_2_DIGITS_COMMA_DOT, RE_1_TO_30_TEXT_DIGITS, RE_ONLY_NUMBERS, RE_ONLY_TEXT_NUMBERS } from '@helpers/Regex';
import { emptyPaymentCheque, amountFormatter } from '@payment/helpers/PaymentChequeUtils';
import { errorColor } from '@app/constants/styles';
import { LOADER_TYPE_PAGE } from '_common/components/loader/constants';
import { FORMAT_DATE_API } from '@app/constants/dates';
import { ADMIN_HEADER_PAYMENTS } from '@layout/constants';
import { TAB_CHEQUES } from '@payment/constants';
import { emptyBankDeposit } from '@payment/helpers/BankDepositUtils';
import { uppercaseFirst } from '_common/services/CommonUtils';
import { WEB_PATHS } from '@app/constants/paths';
const { PAYMENTS_DETAILED_CREDITCARD_OR_CHEQUE } = WEB_PATHS;

type Props = {
  groupClass: GroupClassDetailed | null,
};

const CreateCheque = (props: Props): React$Node => {
  const { groupClass } = props;
  const { t, i18n: { language } } = useTranslation();
  const navigate = useNavigate();

  const [ isLoading, setLoading ] = useState<boolean>(RegisteredsService.isLoadingRegistereds);
  const [ groupClassRegistered, setGroupClassRegistered ] = useState<UserRegistered | null>(RegisteredsService.groupClassRegistered);
  const [ groupClassesRegistereds, setGroupClassesRegistereds ] = useState<ShortRegistered[]>(RegisteredsService.groupClassRegistereds);
  const [ registeredSelected, setRegisteredSelected ] = useState<Option | null>(null);
  const [ isRegisteredLoading, setRegisteredLoading ] = useState<boolean>(RegisteredsService.isLoadingRegistered);
  const [ paymentSchedulesOptions, setPaymentSchedulesOptions ] = useState<Option[]>([]);
  const [ paymentSchedule, setPaymentSchedule ] = useState<Option | null>(null);
  const [ paymentScheduleSelected, setPaymentScheduleSelected ] = useState<PaymentSchedule | null>(null);
  const [ newPaymentCheque, setNewPaymentCheque ] = useState<PaymentChequeFormData>(emptyPaymentCheque);
  const [ bankDeposit, setBankDeposit ] = useState<BankDepositFormData>(emptyBankDeposit);
  const [ idx, setIdx ] = useState<number>(1);
  const [ errors, setErrors ] = useState<Error[]>([]);

  useEffect(() => {
    setGroupClassRegistered(null);
    setRegisteredSelected(null);
    setPaymentSchedulesOptions([]);
    setPaymentSchedule(null);
    setNewPaymentCheque(emptyPaymentCheque);
    setBankDeposit(emptyBankDeposit);
    setErrors([]);
    setPaymentScheduleSelected(null);
    return (): void => {
      RegisteredsService.reset();
    };
  }, []);

  const handleRegisteredUpdateState = (data: RegisteredsServiceData): void => {
    setLoading(data.isLoadingRegistereds);
    setGroupClassRegistered(data.groupClassRegistered);
    setGroupClassesRegistereds(data.groupClassRegistereds);
    setRegisteredLoading(data.isLoadingRegistered);
    setBankDeposit(emptyBankDeposit);
    setErrors(data.errors);
  };

  const handleChequeUpdateState = (data: PaymentChequeServiceData): void => {
    setErrors(data.errors);
  };

  useEffect(() => RegisteredsService.onChange(handleRegisteredUpdateState), []);

  useEffect(() => PaymentChequeService.onChange(handleChequeUpdateState), []);

  useEffect(() => {
    if (groupClass) {
      RegisteredsService.fetchGroupClassRegistereds(groupClass.id, language, { pagination: false, 'order[person.lastName]': 'asc' });
    }
  }, [groupClass, language]);

  useEffect(() => {
    if (registeredSelected) {
      RegisteredsService.fetchOne(parseInt(registeredSelected.value, 10), language);
      setNewPaymentCheque(emptyPaymentCheque);
    }
  }, [registeredSelected, language]);

  useEffect(() => {
    if (groupClassRegistered) {
      const paymentSchedulesFiltered = (groupClassRegistered.paymentSchedules || [])
        .filter((paymentSchedule) => paymentSchedule.period.validatedAt === null)
        .map((paymentSchedule) => ({
          label: periodLabel(paymentSchedule, groupClass, language),
          value: String(paymentSchedule.id),
        }));
      setPaymentSchedulesOptions(paymentSchedulesFiltered);
    }
  }, [groupClassRegistered, groupClass, language]);

  useEffect(() => {
    const paymentSchedules = groupClassRegistered?.paymentSchedules || [];
    if (paymentSchedules.length > 0) {
      const paymentSchedulesFiltered = paymentSchedules.filter((paymentSchedule) => paymentSchedule.period.validatedAt === null);
      const nearestPaymentSchedule = findTheNearestPaymentSchedule(paymentSchedulesFiltered);
      if (nearestPaymentSchedule) {
        setPaymentScheduleSelected(nearestPaymentSchedule);
        setPaymentSchedule({
          label: periodLabel(nearestPaymentSchedule, groupClass, language),
          value: String(nearestPaymentSchedule.id),
        });
        setNewPaymentCheque({ ...emptyPaymentCheque, groupClassRegisteredPaymentSchedule: nearestPaymentSchedule.id });
      }
    } else {
      setPaymentSchedule(null);
    }
  }, [groupClassRegistered, groupClass, language]);

  const periodLabel = useCallback((paymentSchedule: PaymentSchedule | null, groupClass: GroupClassDetailed | null, language: string): string => {
    if (paymentSchedule && groupClass) {
      const templateIndex = templatePaymentScheduleFromGroupClass(paymentSchedule, groupClass);
      const startDate = moment(paymentSchedule.period.startDate).locale(language).format('L');
      const endDate = moment(paymentSchedule.period.endDate).locale(language).format('L');
      const total = (paymentSchedule.period.amount + paymentSchedule.discount + paymentSchedule.costAdjust).toFixed(2);
      return `${ t('payment.period') } (${ templateIndex })${ t('common.colons') } ${ startDate } - ${ endDate } (${ total }€)`;
    }
    return '';
  }, [t]);

  const handleChange = useCallback((e: SyntheticEvent<HTMLInputElement>): void => {
    const { name, value } = e.currentTarget;
    if (name === 'amount' && (value.match(RE_2_DIGITS_COMMA_DOT) || value === '')) {
      setNewPaymentCheque({ ...newPaymentCheque, amount: value });
      const newErrors = errors.filter((error) => error.propertyPath !== 'amount');
      setErrors(newErrors);
    }

    if (name === 'reference' && (value.match(RE_1_TO_30_TEXT_DIGITS) || value === '')) {
      setNewPaymentCheque({ ...newPaymentCheque, reference: value });
      const newErrors = errors.filter((error) => error.propertyPath !== 'reference');
      setErrors(newErrors);
    }
  }, [
    setNewPaymentCheque,
    newPaymentCheque,
    errors,
    setErrors,
  ]);

  const handleBankDepositData = (bankDepositData: BankDepositFormData | null): void => {
    let newErrors = [...errors];
    if (bankDepositData?.depositDate) {
      newErrors = newErrors.filter((error) => error.propertyPath !== 'depositDate');
    }
    if (bankDepositData?.reference) {
      newErrors = newErrors.filter((error) => error.propertyPath !== 'depositNumber');
    }
    if (bankDepositData) {
      setBankDeposit(bankDepositData);
    } else {
      setBankDeposit(emptyBankDeposit);
    }
    setErrors(newErrors);
  };

  const showSuccessMessage = useCallback((message: string): void => {
    Toast.success(t(message));
  }, [t]);

  const validateAndSubmit = useCallback((newCheque: boolean): void => {
    const formErrors = [];
    if (!registeredSelected) {
      formErrors.push({ propertyPath: 'name', code: ERROR_IS_BLANK, message: '' });
    }

    if (!newPaymentCheque.groupClassRegisteredPaymentSchedule) {
      formErrors.push({ propertyPath: 'groupClassRegisteredPaymentSchedule', code: ERROR_IS_BLANK, message: '' });
    }

    if (!newPaymentCheque.reference) {
      formErrors.push({ propertyPath: 'reference', code: ERROR_IS_BLANK, message: '' });
    }

    if (newPaymentCheque.reference && !RE_1_TO_30_TEXT_DIGITS.test(newPaymentCheque.reference)) {
      formErrors.push({ propertyPath: 'reference', code: ERROR_NOT_EQUAL_LENGTH, message: '' });
    }

    if (!newPaymentCheque.amount) {
      formErrors.push({ propertyPath: 'amount', code: ERROR_IS_BLANK, message: '' });
    }

    const chequeAmount = amountFormatter(newPaymentCheque.amount);

    if (chequeAmount <= 0) {
      formErrors.push({ propertyPath: 'amount', code: ERROR_IS_BLANK, message: '' });
    }

    if (bankDeposit.reference && !RE_1_TO_30_TEXT_DIGITS.test(bankDeposit.reference)) {
      formErrors.push({ propertyPath: 'depositNumber', code: ERROR_INVALID_FORMAT, message: '' });
    }

    if (bankDeposit.reference && !bankDeposit.depositDate) {
      formErrors.push({ propertyPath: 'depositDate', code: ERROR_MISSING_OR_INVALID, message: '' });
    }

    setErrors(formErrors);

    if (formErrors.length === 0 && groupClass) {
      let created = false;
      new Promise((resolve) => {
        // create bank deposit
        if (!bankDeposit.id && bankDeposit.reference) {
          created = true;
          resolve(BankDepositService.create(
            bankDeposit.reference,
            dateFmt(new Date(bankDeposit.depositDate), FORMAT_DATE_API),
          ));
        }
        // use existing bank deposit
        if (bankDeposit.id) {
          resolve(BankDepositService.fetchOne(bankDeposit.id));
        }
        // no bank deposit
        resolve(null);
      })
        .then((maybeBankDeposit: BankDepositDetailed | null) => {
          const hasId = maybeBankDeposit?.id || null;
          if (hasId && created) {
            showSuccessMessage('payment.depositCreated');
          }
          return hasId;
        })
        .then((bankDepositId) => (
          PaymentChequeService.create(
            newPaymentCheque.reference,
            newPaymentCheque.groupClassRegisteredPaymentSchedule || 0,
            chequeAmount,
            bankDepositId,
          )
        ))
        .then(() => {
          showSuccessMessage('payment.paymentAdded');
          setNewPaymentCheque(emptyPaymentCheque);
          setGroupClassRegistered(null);
          setRegisteredSelected(null);
          setPaymentSchedulesOptions([]);
          setPaymentSchedule(null);
          setBankDeposit(emptyBankDeposit);
          setIdx((idx) => idx + 1);
          setErrors([]);
          if (!newCheque) {
            navigate(PAYMENTS_DETAILED_CREDITCARD_OR_CHEQUE.replace(':classId', groupClass.id.toString()).replace(':paymentType', TAB_CHEQUES));
          }
        })
        .catch(() => {
          setErrors(PaymentChequeService.errorValues);
        });
    }
  }, [
    registeredSelected,
    newPaymentCheque,
    bankDeposit,
    showSuccessMessage,
    idx,
    groupClass,
  ]);

  const registeredOptions = useMemo((): Option[] => (
    groupClassesRegistereds
      .filter((registered) => registered.unsubscribedAt === null)
      .map((registered) => ({
        label: `${ registered.person.lastName.toUpperCase() } ${ uppercaseFirst(registered.person.firstName) }`,
        value: registered.id.toString(),
      }))
  ), [groupClassesRegistereds]);

  const handleChangeSelectName = useCallback((newValue): void => {
    if (newValue !== null) {
      const selected: Option = Array.isArray(newValue) ? newValue[0] : newValue;
      setRegisteredSelected({
        label: selected.label,
        value: selected.value,
      });
      setBankDeposit(emptyBankDeposit);
      setIdx((idx) => idx + 1);
    }
  }, [
    setRegisteredSelected,
    setBankDeposit,
    setIdx,
    idx,
  ]);

  const handleChangeSelectPaymentSchedule = useCallback((newValue): void => {
    if (newValue !== null) {
      const selected: Option = Array.isArray(newValue) ? newValue[0] : newValue;
      setPaymentSchedule({
        label: selected.label,
        value: selected.value,
      });
      setPaymentScheduleSelected(getSelectedPaymentSchedule((groupClassRegistered?.paymentSchedules || []), selected.value));
      setNewPaymentCheque({ ...newPaymentCheque, groupClassRegisteredPaymentSchedule: parseInt(selected.value, 10) });
      setBankDeposit(emptyBankDeposit);
      setIdx((idx) => idx + 1);
    }
  }, [
    setPaymentSchedule,
    setPaymentScheduleSelected,
    groupClassRegistered,
    setNewPaymentCheque,
    newPaymentCheque,
    setBankDeposit,
    setIdx,
    idx,
  ]);

  const showOverPaymentMessage = useMemo((): boolean => {
    if (paymentScheduleSelected && newPaymentCheque) {
      const paying = parseFloat(newPaymentCheque.amount) || 0;
      const due = getPaymentScheduleAmountDue(paymentScheduleSelected);
      return paying > due;
    }
    return false;
  }, [paymentScheduleSelected, newPaymentCheque]);

  const errorsProps = useMemo((): ErrorProps=> {
    let errorsPropsTmp = {};
    errors.forEach((error: Error): void => {
      Object.assign(errorsPropsTmp, {
        [`${ error.propertyPath }`]: {
          color: 'is-danger',
          borderColor: errorColor,
          labelProps: { className: 'has-text-danger' },
          labelTextAreaProps: {
            className: 'label has-text-danger',
          },
          selectFieldHelperText: { text: t(TranslationHelpers.getCommonErrorKeyByCode(error.code, error.propertyPath)), textColor: 'is-danger' },
          error: {
            text: t(TranslationHelpers.getCommonErrorKeyByCode(error.code, error.propertyPath)),
            textColor: 'is-danger',
          },
        },
      });
    });
    return errorsPropsTmp;
  }, [errors]);

  return (
    <AdminLayout activeTab={ ADMIN_HEADER_PAYMENTS } groupClass={ groupClass } preventPortraitMode isLoading={ isLoading } loaderType={ LOADER_TYPE_PAGE }>
      <div className="edit-cheque">
        <div className="edit-cheque-container container">
          <div className="btn-go-back">
            <ButtonGoBack />
          </div>
          <div className="edit-cheque-header">
            <div className="edit-cheque-title">
              <h1>{ t('payment.addCheque') }</h1>
            </div>
          </div>
          <div className="fields">
            <div className="field-container">
              <AdvancedSelectField
                id="name"
                name="name"
                label={ t('payment.name') }
                options={ registeredOptions }
                placeholder={ t('payment.namePlaceholder') }
                onChange={ handleChangeSelectName }
                isClearable={ false }
                value={ registeredSelected }
                color={ errorsProps.name?.color }
                labelProps={ errorsProps.name?.labelProps }
                borderColor={ errorsProps.name?.borderColor }
                helpProps={ errorsProps.name?.selectFieldHelperText }
                className="field-cheque"
                isSearchable={ false }
                required
              />
              { isRegisteredLoading && (
                <div className="create-cheque-loader">
                  <Loader />
                </div>
              ) }
              { !isRegisteredLoading && (
                <AdvancedSelectField
                  id="groupClassRegisteredPaymentSchedule"
                  name="groupClassRegisteredPaymentSchedule"
                  label={ t('payment.period') }
                  options={ paymentSchedulesOptions }
                  onChange={ handleChangeSelectPaymentSchedule }
                  placeholder={ t('payment.namePlaceholder') }
                  isClearable={ false }
                  value={ paymentSchedule }
                  isDisabled={ !registeredSelected }
                  color={ errorsProps.groupClassRegisteredPaymentSchedule?.color }
                  labelProps={ errorsProps.groupClassRegisteredPaymentSchedule?.labelProps }
                  borderColor={ errorsProps.groupClassRegisteredPaymentSchedule?.borderColor }
                  helpProps={ errorsProps.groupClassRegisteredPaymentSchedule?.selectFieldHelperText }
                  className="field-cheque"
                  isSearchable={ false }
                  required
                />
              ) }
            </div>
            <div className="field-container">
              <InputField
                id="amount"
                name="amount"
                label={ t('payment.chequeAmount') }
                placeholder={ t('payment.chequeAmountPlaceholder') }
                onChange={ handleChange }
                value={ newPaymentCheque.amount ? newPaymentCheque.amount : '' }
                color={ errorsProps.amount?.color }
                labelProps={ errorsProps.amount?.labelProps }
                borderColor={ errorsProps.amount?.borderColor }
                helpProps={ errorsProps.amount?.error }
                isDisabled={ !registeredSelected }
                type="number"
                step={ 1 }
                className="field-cheque"
                unauthorizedKeys={ RE_ONLY_NUMBERS }
                required
              />
              <InputField
                id="reference"
                name="reference"
                label={ t('payment.chequeNumber') }
                onChange={ handleChange }
                value={ newPaymentCheque.reference }
                color={ errorsProps.reference?.color }
                labelProps={ errorsProps.reference?.labelProps }
                isDisabled={ !registeredSelected }
                borderColor={ errorsProps.reference?.borderColor }
                helpProps={ errorsProps.reference?.error }
                className="field-cheque"
                type="text"
                unauthorizedKeys={ RE_ONLY_TEXT_NUMBERS }
                required
              />
            </div>
            <BankDepositInput
              idx={ idx }
              bankDeposit={ bankDeposit }
              handleBankDepositData={ handleBankDepositData }
              errorsProps={ errorsProps }
              className="field-container"
              selectClassName="field-cheque"
              datePickerClassName="field-cheque"
              isDisabled={ !registeredSelected }
              type="number"
            />
            { showOverPaymentMessage && (
              <div className="overpayment-cheque">
                <IconWarning />
                { t('payment.chequeAmountOverpaid') }
              </div>
            ) }
          </div>
          <div className="validation-vertical-button">
            <Button
              type={ buttonConstants.PRIMARY }
              className="button"
              onClick={ () => validateAndSubmit(false) }
            >
              { t('payment.validAndExit') }
            </Button>
            <Button
              type={ buttonConstants.TERTIARY }
              className="button"
              isOutlined
              onClick={ () => validateAndSubmit(true) }
            >
              { t('payment.validAndNextCheque') }
            </Button>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default withGroupClass(CreateCheque);
