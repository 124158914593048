// @flow
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import withGroupClass from '@hoc/withGroupClass';

import ParticipantsLayout from '@layout/components/ParticipantsLayout';

import BeneficiaryRequestEditPerson from '@beneficiary/components/BeneficiaryRequestEditPerson';

import RequestsService from '@beneficiary/services/RequestsService';

import type { GroupClassRequest } from '@participant/types';
import type { GroupClassDetailed } from '@groupClass/types';

type UseParamProps = {
  requestId: string,
};

type Props = {
  groupClass: GroupClassDetailed | null,
};

export const RequestPersonEdit = (props: Props): React$Node => {
  const { groupClass } = props;
  const { requestId } = useParams<UseParamProps>();
  const { i18n: { language } } = useTranslation();

  const [ request, setRequest ] = useState<GroupClassRequest | null>(RequestsService.groupClassRequest);

  useEffect(() => {
    RequestsService.fetchOne(parseInt(requestId, 10), language)
      .then(setRequest);
  }, [requestId, language]);

  return (
    <ParticipantsLayout groupClass={ groupClass }>
      { request && (
        <BeneficiaryRequestEditPerson request={ request } />
      ) }
    </ParticipantsLayout>
  );
};

export default withGroupClass(RequestPersonEdit);
