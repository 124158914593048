// @flow
import { useMemo } from 'react';
import clsx from 'clsx';

import IconClose from '@icons/components/IconClose';

export type ModalProps = {
  children: React$Node,
  isActive?: boolean,
  isClipped?: boolean,
  title: string,
  footer: React$Node,
  modalContentClassName?: string,
  onClose?: (event: SyntheticEvent<HTMLButtonElement>) => void,
};

const Modal = (props: ModalProps): React$Node => {
  const {
    children,
    isActive,
    isClipped,
    onClose,
    title,
    footer,
    modalContentClassName,
  } = props;

  const modalClassName = useMemo((): string => clsx({
    'modal': true,
    'is-active': !!isActive,
    'is-clipped': !!isClipped,
    'modal-container': true,
  }), [isClipped, isActive]);

  const modalContentPageClassName = useMemo((): string => clsx({
    'modal-card': true,
    'card': true,
    [String(modalContentClassName)]: !!modalContentClassName,
  }), [modalContentClassName]);

  return (
    <div className={ modalClassName }>
      <div className="modal-background" />
      <div className={ modalContentPageClassName }>
        <header className="modal-header">
          <p className="title">{ title }</p>
          <IconClose onClick={ onClose } />
        </header>
        <section className="body">
          { children }
        </section>
        { footer }
      </div>
    </div>
  );
};

Modal.defaultProps = {
  title: '',
};

export default Modal;
