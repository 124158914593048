// @flow
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from '_common/components/loader/Loader';

import AuthService, { type AuthServiceData } from '@user/services/AuthService';
import SharedSessionService from '@groupSend/services/SharedSessionService';

import type { User } from '@user/types';
import { type SharedSessionsData, SharedSession } from '@groupSend/types';
import CardItemSharedSession from '@session/components/CardItemSharedSession';
import { LOADER_TYPE_PAGE } from '_common/components/loader/constants';

const SharedSessionsRegistered = (): React$Node => {
  const { t, i18n: { language } } = useTranslation();

  const [ currentUser, setCurrentUser ] = useState<User | null>(AuthService.user);
  const [ sharedSessions, setSharedSessions ] = useState<SharedSession[]>(SharedSessionService.sharedSessionsValues);
  const [ isSharedSessionLoading, SetIsSharedSessionLoading ] = useState<boolean>(SharedSessionService.isSharedSessionLoading);

  const handleUpdateAuthService = (data: AuthServiceData) => {
    setCurrentUser(data.user);
  };

  const handleUpdateSharedSessions = (data: SharedSessionsData) => {
    setSharedSessions(data.sharedSessions);
    SetIsSharedSessionLoading(data.isSharedSessionLoading);
  };

  useEffect(() => AuthService.onChange(handleUpdateAuthService), []);

  useEffect(() => SharedSessionService.onChange(handleUpdateSharedSessions), []);

  useEffect(() => {
    if (currentUser?.id) {
      SharedSessionService.getSharedSessionForBeneficiary(currentUser?.id, language);
    }
  }, [currentUser, language]);

  const hasEntries = useMemo((): boolean => (
    sharedSessions.length > 0
  ), [sharedSessions]);

  if (!hasEntries) {
    return null;
  }

  return (
    <div className="column is-12">
      <div className="shared-sessions-registered">
        <div className="shared-sessions-registered-title">
          <h1>{ t('sharedSessions.title') }</h1>
        </div>
        <div className="shared-sessions-registered-block">
          <div className="columns is-flex-wrap-wrap is-mobile">
            { isSharedSessionLoading && (
              <Loader loaderType={ LOADER_TYPE_PAGE } />
            ) }
            { !isSharedSessionLoading && sharedSessions?.map((sharedSession: SharedSession, index: number) => (
              <CardItemSharedSession
                key={ index }
                sharedSession={ sharedSession }
              />
            )) }
          </div>
        </div>
      </div>
    </div>
  );
};

export default SharedSessionsRegistered;
