// @flow
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BeneficiaryLayout from '@layout/components/BeneficiaryLayout';
import GroupClassRow from '@payment/components/GroupClassRow';
import PeriodBalanceRow from '@payment/components/BeneficiaryPayment/PeriodBalanceRow';
import GroupClassTotalRow from '@payment/components/BeneficiaryPayment/GroupClassTotalRow';
import ButtonGoBack from '@shared/Navigation/ButtonGoBack';

import AuthService, { type AuthServiceData } from '@user/services/AuthService';
import BeneficiaryBalancesService, { type BeneficiaryBalancesServiceData } from '@beneficiary/services/BeneficiaryBalancesService';

import type { BeneficiaryBalance } from '@beneficiary/types';
import type { ShortGroupClass } from '@groupClass/types';
import type { User } from '@user/types';

import { LOADER_TYPE_PAGE } from '_common/components/loader/constants';

type ClassRow = {
  groupClass: ShortGroupClass,
  balances: BeneficiaryBalance[],
  totalAmount: number,
};

const BeneficiaryPayment = (): React$Node => {
  const { t, i18n: { language } } = useTranslation();

  const [ balances, setBalances ] = useState<BeneficiaryBalance[]>([]);
  const [ loading, setLoading ] = useState<boolean>(true);

  const [ currentUser, setCurrentUser ] = useState<User | null>(AuthService.user);

  const handleUpdateAuthState = (data: AuthServiceData) => {
    setCurrentUser(data.user);
  };

  const handleUpdateState = (data: BeneficiaryBalancesServiceData) => {
    setBalances(data.beneficiaryBalances);
    setLoading(data.loading);
  };

  useEffect(() => BeneficiaryBalancesService.onChange(handleUpdateState), []);
  useEffect(() => AuthService.onChange(handleUpdateAuthState), []);

  useEffect(() => {
    if (currentUser) {
      BeneficiaryBalancesService.fetchAll(currentUser.id, language);
    }
  }, [currentUser, language]);

  const classRows = useMemo((): ClassRow[] => {
    const rows: ClassRow[] = [];

    balances.forEach((balance) => {
      const { groupClass } = balance;
      let i = rows.findIndex((el) => el.groupClass.id === groupClass.id);
      if (i === -1) {
        rows.push({ groupClass, balances: [], totalAmount: 0 });
        i = rows.length - 1;
      }
      rows[i].balances.push(balance);
      rows[i].totalAmount += balance.toPay - balance.paid;
    });

    rows.sort((a, b) => a.groupClass.activity.name.localeCompare(b.groupClass.activity.name));

    return rows;
  }, [balances]);

  return (
    <BeneficiaryLayout isLoading={ loading } loaderType={ LOADER_TYPE_PAGE }>
      <div className="bank-deposit">
        <div className="bank-deposit-container container">
          <ButtonGoBack />

          <div className="bank-deposit-header">
            <div className="bank-deposit-title">
              <h1>{ t('payment.summary.title') }</h1>
            </div>
          </div>

          <div>
            {
              !loading && balances.length > 0
                ? (
                  <table className="payment-table light-blue-border drop-shadow rounded-corners txt-grey">
                    <thead className="border-table-deposit">
                      <tr className="bg-light-blue payment-table-label">
                        <th className="cell-padding-left txt-main">
                          { t('payment.period') }
                        </th>
                        <th className="cell-padding txt-main cell-txt-middle" align="center">
                          { t('payment.tableDue') }
                        </th>
                        <th className="cell-padding txt-main">
                          &nbsp;
                        </th>
                      </tr>
                    </thead>
                    <tbody className="border-table-deposit-content">

                      { classRows.map((el) => (
                        <GroupClassRow
                          key={ `group-class-${ el.groupClass.id }` }
                          numCols={ 3 }
                          groupClass={ el.groupClass }
                          showCoach={ true }
                        >

                          { el.balances.map((balance, index) => (
                            <PeriodBalanceRow
                              key={ `period-balance-${ el.groupClass.id }-${ index }` }
                              balance={ balance }
                              index={ index }
                              numPeriods={ el.balances.length }
                              paymentScheduleId={ balance.paymentScheduleId }
                            />
                          )) }

                          { el.balances.length > 1 && (
                            <GroupClassTotalRow
                              totalAmount={ el.totalAmount }
                              groupClassId={ el.groupClass.id }
                            />
                          ) }

                        </GroupClassRow>
                      )) }

                    </tbody>
                  </table>
                )
                : (
                  <span className="no-result">{ t('payment.summary.noBalances') }</span>
                )
            }
          </div>
        </div>

      </div>
    </BeneficiaryLayout>
  );
};

export default BeneficiaryPayment;
