// @flow
import { useCallback, useMemo, useRef } from 'react';
import clsx from 'clsx';

import type { ToolTipPosition } from '@shared/ToolTip/types';
import * as toolTipConstants from '@shared/ToolTip/constants';
import useOnClickOutside from '@hooks/useOnClickOutside';

export type Props = {
  children: React$Node,
  className: string,
  place: ToolTipPosition,
  visible: boolean,
  withArrow: boolean,
  onClick: (isVisible: boolean) => void,
};

export const ToolTip = (props: Props): React$Node => {
  const {
    children,
    className,
    onClick,
    place,
    visible,
    withArrow,
  } = props;

  const toolTipRef: React$Reference<?HTMLElement> = useRef();

  /**
   * Listen click of the user to automatically close this component if the click was made outside of
   * this component
   */
  useOnClickOutside(toolTipRef, onClick);

  /**
   * handleClick
   * Handle the click on the ToolTip and dispatch the selected value to the parent
   * @returns {void}
   */
  const handleClick = useCallback((): void => {
    onClick(!visible);
  }, [onClick, visible]);

  const arrowDirection = toolTipConstants.ARROW_DIRECTION_FROM_POSITION[place]
    || toolTipConstants.ARROW_DIRECTION_DOWN;

  const toolTipClassName = useMemo((): string => clsx({
    'tooltip': true,
    [`tooltip-popin-${ place }`]: true,
    hidden: !visible,
    [className]: !!className,
  }), [className, place, visible]);

  const toolTipArrowClassName = useMemo((): string => clsx({
    'tooltip-arrow': withArrow,
    [`tooltip-arrow-${ arrowDirection }`]: withArrow,
  }), [arrowDirection, withArrow]);

  return (
    <div className={ toolTipClassName } onClick={ handleClick } ref={ toolTipRef }>
      <div className="tooltip-text">
        { children }
      </div>

      { withArrow && (
        <span className={ toolTipArrowClassName } />
      ) }
    </div>
  );
};

ToolTip.defaultProps = {
  children: null,
  className: 'tooltip',
  place: toolTipConstants.TOOLTIP_POSITION_TOP,
  text: '',
  visible: false,
  withArrow: false,
  onClick: () => {},
};

export default ToolTip;
