// @flow
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import IconTransfer from '@icons/components/IconTransfer';
import IconEllipsisVertical from '@icons/components/IconEllipsisVertical';

import DateService from '_common/services/DateService';
import { uppercaseFirst } from '_common/services/CommonUtils';
import { formatAmountWithEuros } from '@payment/helpers/AmountUtils';

import type { PeriodItemOutput } from '@groupClass/types';
import type { CreditCardBalance } from '@payment/types';

import {
  PAYMENT_STATUS_NOT_APPLICABLE,
  PAYMENT_STATUS_PENDING,
  PAYMENT_STATUS_TOFINALIZE,
  PAYMENT_STATUS_ACCEPTED,
  PAYMENT_STATUS_OVERPAID,
  PAYMENT_STATUS_REFUSED,
  PAYMENT_STATUS_ABANDONED,
} from '@payment/constants';

type Props = {
  creditCard: CreditCardBalance,
  handleTransferClick: (creditCard: CreditCardBalance) => void,
  index: number,
  isFirstLine: boolean,
  period: PeriodItemOutput,
};

const CreditCardLine = (props: Props): React$Node => {
  const {
    creditCard,
    handleTransferClick,
    index,
    isFirstLine,
    period,
  } = props;

  const {
    id,
    paid,
    paidAt,
    person,
    reference,
    status,
    toPay,
    unsubscribedAt,
  } = creditCard;

  const { firstName, lastName } = person;

  const { t, i18n: { language } } = useTranslation();

  const toPayFormatted = toPay.toLocaleString(language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const paidFormatted = useMemo((): string => {
    if (status === PAYMENT_STATUS_REFUSED || status === PAYMENT_STATUS_ABANDONED) {
      return formatAmountWithEuros(0, language);
    }
    return formatAmountWithEuros(paid, language);
  }, [paid, language]);

  const paidAtFormatted: string = paidAt ? DateService.localDateDisplay(paidAt, language) : '';

  const firstNameFormatted: string = uppercaseFirst(firstName);
  const lastNameFormatted: string = lastName.toUpperCase();

  const isPeriodOpen = useMemo((): boolean => (
    period.validatedAt === null
  ), [period]);

  const handleTransfer = useCallback((creditCard: CreditCardBalance): void => {
    if (isPeriodOpen && !creditCard.isPaymentOnValidatedPeriod) {
      handleTransferClick(creditCard);
    }
  }, [isPeriodOpen]);

  const cellNameClassName = useMemo((): string => clsx({
    'table-body-cell': true,
    'cell-text-name': true,
    'status-refused': status === PAYMENT_STATUS_REFUSED,
    'status-pending': status === PAYMENT_STATUS_PENDING,
    'status-not-applicable': status === PAYMENT_STATUS_NOT_APPLICABLE,
    'status-to-finalize': status === PAYMENT_STATUS_TOFINALIZE,
    'status-accepted': status === PAYMENT_STATUS_ACCEPTED,
    'status-overpaid': status === PAYMENT_STATUS_OVERPAID,
    'status-abandoned': status === PAYMENT_STATUS_ABANDONED,
    'status-is-unsubscribed': !!unsubscribedAt,
  }), [status, unsubscribedAt]);

  const lineClassName = useMemo((): string => clsx({
    'table-body-colored': index % 2 === 0 && !unsubscribedAt,
    'table-body-unsubscribed': !!unsubscribedAt,
  }), [unsubscribedAt, index]);

  const creditCardLineIconClassName = useMemo((): string => clsx({
    'icon-action': true,
    'icon-disabled': !isPeriodOpen || creditCard.isPaymentOnValidatedPeriod,
  }), [isPeriodOpen]);

  return (
    <tr className={ lineClassName } key={ id }>
      <td className={ cellNameClassName }>
        { isFirstLine && (`${ firstNameFormatted } ${ lastNameFormatted }`) }
      </td>
      <td className="table-body-cell cell-text-centered">
        { isFirstLine && toPayFormatted }
      </td>
      <td className="table-body-cell cell-text-centered">
        { paidFormatted }
      </td>
      <td className="table-body-cell cell-text-centered">
        { paidAtFormatted }
      </td>
      <td className="table-body-cell cell-text-centered">
        { reference }
      </td>
      <td className="table-body-cell cell-text-centered">
        <div className="table-cell-actions-desktop">
          <IconTransfer
            className={ creditCardLineIconClassName }
            onClick={ () => handleTransfer(creditCard) }
          />
        </div>
        <div className="table-cell-actions-tablet navbar-item has-dropdown is-hoverable">
          <IconEllipsisVertical className="icon-action navbar-link" />
          <div className="navbar-dropdown">
            <div className="navbar-button">
              <button
                className={ creditCardLineIconClassName }
                onClick={ () => handleTransfer(creditCard) }
              >
                { t('payment.actionTransfer') }
              </button>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default CreditCardLine;
