// @flow
import Loader from '_common/components/loader/Loader';
import RotateScreen from '@shared/RotateScreen/components/RotateScreen';

type Props = {
  children: React$Node,
  isLoading?: boolean,
  loaderType?: string,
  preventPortraitMode: boolean,
};

const PublicRoute = (props: Props): React$Node => {
  const {
    children,
    isLoading,
    loaderType,
    preventPortraitMode,
  } = props;

  return (
    <>
      { isLoading
        ? ( <Loader loaderType={ loaderType } /> )
        : ( children )
      }

      <RotateScreen isVisible={ preventPortraitMode } />
    </>
  );
};

PublicRoute.defaultProps = {
  preventPortraitMode: false,
};

export default PublicRoute;
