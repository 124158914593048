// @flow
export const accentuatedColor = '#ffb401';
export const errorColor = '#f44336';
export const errorTransparentColor = '#f4433659';
export const greenElementColor = '#399448';
export const greyTextColor = '#6e6e6e';
export const inactiveElementColor = '#ddd';
export const inputBorderColor = '#dbdbdb';
export const labelTextColor = '#4d4d8b';
export const lightBackgroundColor = '#ebf3ff';
export const mainTextColor = '#000060';
export const primaryColor = '#0380cf';
export const secondaryColor = '#006cb0';
export const secondaryTransparentColor = '#006cb032';
export const bgColorDisabled = '#F5F5F5';
export const colorDisabled = '#7a7a7a';
export const colorWhite = '#fff';
