// @flow
import clsx from 'clsx';

import { useMemo } from 'react';

import type { LabelProps } from '@core/types';

type Props = LabelProps & {
  children?: React$Node,
  htmlFor?: string,
};

const Label = (props: Props): React$Node => {
  const {
    children,
    size,
    className,
    htmlFor,
    ...others
  } = props;

  const localClassName = useMemo((): string => clsx({
    'label': true,
    [String(size)]: !!size,
    [String(className)]: !!className,
  }), [className, size]);

  return (
    <label
      { ...others }
      className={ localClassName }
      htmlFor={ htmlFor }
    >
      { children }
    </label>
  );
};

export default Label;
