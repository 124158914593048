// @flow
import clsx from 'clsx';

import { useMemo } from 'react';

export type FooterProps = {
  children: React$Node,
  className?: string,
};

const Footer = (props: FooterProps): React$Node => {
  const {
    children,
    className,
  } = props;

  const footerClassName = useMemo((): string => clsx({
    'footer': true,
    [String(className)]: !!className,
  }), [className]);

  return (
    <footer className={ footerClassName }>
      { children }
    </footer>
  );
};

export default Footer;
