// @flow
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format as dateFmt } from 'date-fns';
import moment from 'moment/dist/moment';

import withGroupClass from '@hoc/withGroupClass';

import AdminLayout from '@layout/components/AdminLayout';

import Toast from '_common/services/Toast/Toast';
import Button, { buttonConstants } from '@shared/Button';
import AdvancedSelectField from '@shared/AdvancedSelectField/components/AdvancedSelectField';
import InputField from '_common/components/input-field/InputField';
import BankDepositInput from '@payment/components/BankDepositInput';
import ButtonGoBack from '@shared/Navigation/ButtonGoBack';
import IconWarning from '@icons/components/IconWarning';

import BankDepositService from '@payment/services/BankDepositService';
import PaymentChequeService, { type PaymentChequeServiceData } from '@payment/services/PaymentChequeService';
import { templatePaymentScheduleFromGroupClass } from '@groupClass/helpers/PeriodUtils';
import { shortBankDepositToFormData, emptyBankDeposit } from '@payment/helpers/BankDepositUtils';
import TranslationHelpers, {
  ERROR_IS_BLANK,
  ERROR_NOT_EQUAL_LENGTH,
  ERROR_INVALID_FORMAT,
  ERROR_MISSING_OR_INVALID,
} from '@helpers/TranslationHelpers';
import { RE_ONLY_NUMBERS } from '@helpers/Regex';

import type { GroupClassDetailed } from '@groupClass/types';
import type { PaymentCheque, BankDepositFormData } from '@payment/types';
import type { Error, ErrorProps, Option } from '@core/types';

import {
  RE_2_DIGITS_COMMA_DOT,
  RE_1_TO_30_TEXT_DIGITS,
  RE_ONLY_TEXT_NUMBERS,
} from '@helpers/Regex';
import { LOADER_TYPE_PAGE } from '_common/components/loader/constants';
import { WEB_PATHS } from '@app/constants/paths';
import { errorColor } from '@app/constants/styles';
import { FORMAT_DATE_API } from '@app/constants/dates';
import { TAB_CHEQUES } from '@payment/constants';
import { ADMIN_HEADER_PAYMENTS } from '@layout/constants';
const { PAYMENTS_DETAILED_CREDITCARD_OR_CHEQUE } = WEB_PATHS;

type useParamsType = {
  classId: string,
  chequeId: string,
};

type Props = {
  groupClass: GroupClassDetailed | null,
};

const EditCheque = (props: Props): React$Node => {
  const { groupClass } = props;
  const { t, i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const { classId, chequeId } = useParams<useParamsType>();

  const [ paymentCheque, setPaymentCheque ] = useState<PaymentCheque | null>(null);
  const [ bankDeposit, setBankDeposit ] = useState<BankDepositFormData>(emptyBankDeposit);
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ errors, setErrors ] = useState<Error[]>([]);
  const idx: number = 1;

  const paymentScheduleOptionValue = useMemo((): Option | null => {
    if (paymentCheque?.groupClassRegisteredPaymentSchedule && groupClass) {
      const paymentSchedule = paymentCheque.groupClassRegisteredPaymentSchedule;
      const templateIndex = templatePaymentScheduleFromGroupClass(paymentSchedule, groupClass);
      const startDate = moment(paymentSchedule.period.startDate).locale(language).format('L');
      const endDate = moment(paymentSchedule.period.endDate).locale(language).format('L');
      const total = paymentSchedule.period.amount + paymentSchedule.discount + paymentSchedule.costAdjust;
      return {
        value: String(paymentCheque.groupClassRegisteredPaymentSchedule.id ?? ''),
        label: `${ t('payment.period') } (${ templateIndex })${ t('common.colons') } ${ startDate } - ${ endDate } (${ total }€)`,
      };
    }
    return null;
  }, [groupClass, language, paymentCheque]);

  const nameOptionValue = useMemo((): Option => {
    if (paymentCheque?.person) {
      return {
        value: String(paymentCheque.person.id),
        label: `${ paymentCheque.person.firstName } ${ paymentCheque.person.lastName.toUpperCase() }`,
      };
    }
    return {
      value: '',
      label: '',
    };
  }, [paymentCheque]);

  useEffect((): void => {
    if (chequeId) {
      PaymentChequeService.fetchOne(parseInt(chequeId, 10));
    }
    setErrors([]);
  }, [chequeId]);

  const handlePaymentChequeChange = (data: PaymentChequeServiceData): void => {
    setPaymentCheque(data.cheque);
    setBankDeposit(data?.cheque?.bankDeposit ? shortBankDepositToFormData(data?.cheque?.bankDeposit) : emptyBankDeposit);
    setIsLoading(data.isLoading);
    setErrors(data.errors);
  };

  useEffect(() => PaymentChequeService.onChange(handlePaymentChequeChange), []);

  const handleChange = useCallback((e: SyntheticEvent<HTMLInputElement>): void => {
    const { name, value } = e.currentTarget;
    if (name === 'amount' && (value.match(RE_2_DIGITS_COMMA_DOT) || value === '')) {
      setPaymentCheque({ ...paymentCheque, [name]: value });
      const newErrors = errors.filter((error) => error.propertyPath !== 'amount');
      setErrors(newErrors);
    } else if (name === 'reference' && (value.match(RE_1_TO_30_TEXT_DIGITS) || value === '')) {
      setPaymentCheque({ ...paymentCheque, [name]: value });
      const newErrors = errors.filter((error) => error.propertyPath !== 'reference');
      setErrors(newErrors);
    }
  }, [paymentCheque, setPaymentCheque, setErrors, errors]);

  const showOverPaymentMessage = useMemo((): boolean => {
    if (paymentCheque) {
      const due = paymentCheque.groupClassRegisteredPaymentSchedule.amount;
      const paying = paymentCheque.amount;

      return paying > due;
    }
    return false;
  }, [paymentCheque]);

  const handleBankDepositData = useCallback((bankDepositData: BankDepositFormData | null): void => {
    let newErrors = [...errors];
    if (bankDepositData?.depositDate) {
      newErrors = newErrors.filter((error) => error.propertyPath !== 'depositDate');
    }
    if (bankDepositData?.reference) {
      newErrors = newErrors.filter((error) => error.propertyPath !== 'depositNumber');
    }
    if (bankDepositData) {
      setBankDeposit(bankDepositData);
    } else {
      setBankDeposit(emptyBankDeposit);
    }
    setErrors(newErrors);
  }, [errors, setBankDeposit, setErrors]);

  const errorsProps = useMemo((): ErrorProps => {
    let errorsPropsTmp = {};
    errors.forEach((error) => {
      Object.assign(errorsPropsTmp, {
        [`${ error.propertyPath }`]: {
          color: 'is-danger',
          borderColor: errorColor,
          labelProps: { className: 'has-text-danger' },
          labelTextAreaProps: {
            className: 'label has-text-danger',
          },
          selectFieldHelperText: { text: t(TranslationHelpers.getCommonErrorKeyByCode(error.code, error.propertyPath)), textColor: 'is-danger' },
          error: {
            text: t(TranslationHelpers.getCommonErrorKeyByCode(error.code, error.propertyPath)),
            textColor: 'is-danger',
          },
        },
      });
    });
    return errorsPropsTmp;
  }, [errors]);

  const currentBankDepositNotChanged = useMemo((): boolean => {
    if (paymentCheque?.bankDeposit && errors.length === 0) {
      return paymentCheque.bankDeposit.id === bankDeposit.id;
    }
    return false;
  }, [paymentCheque, bankDeposit, errors]);

  const hasNoBankDepositAssociated = useMemo((): boolean => {
    if (paymentCheque && errors.length === 0) {
      return paymentCheque.bankDeposit === null && bankDeposit.id === null && bankDeposit.reference === '';
    }
    return false;
  }, [paymentCheque, bankDeposit, errors]);

  const dissociatePaymentChequeFromBankDeposit = useMemo((): boolean => {
    if (paymentCheque && errors.length === 0) {
      return !!paymentCheque.bankDeposit && bankDeposit.id === null && bankDeposit.reference === '';
    }
    return false;
  }, [paymentCheque, bankDeposit, errors]);

  const attributeAnExistingBankDeposit = useMemo((): boolean => {
    if (paymentCheque && errors.length === 0) {
      return paymentCheque.bankDeposit !== bankDeposit.id && bankDeposit.id !== null;
    }
    return false;
  }, [paymentCheque, bankDeposit, errors]);

  const updatePaymentChequeAndCreateBankDeposit = useMemo((): boolean => {
    if (paymentCheque && bankDeposit && errors.length === 0) {
      return bankDeposit.id === null && RE_1_TO_30_TEXT_DIGITS.test(bankDeposit.reference) && !!bankDeposit.depositDate;
    } else {
      return false;
    }
  }, [paymentCheque, bankDeposit, errors]);

  const validatePaymentCheque = useCallback((): boolean => {
    if (paymentCheque) {
      let errorsTmp = [];
      if (paymentCheque.amount === '') {
        errorsTmp.push({ propertyPath: 'amount', code: ERROR_IS_BLANK, message: '' });
      }
      if (paymentCheque.reference === '') {
        errorsTmp.push({ propertyPath: 'reference', code: ERROR_IS_BLANK, message: '' });
      }
      if (paymentCheque.reference && !RE_1_TO_30_TEXT_DIGITS.test(paymentCheque.reference)) {
        errorsTmp.push({ propertyPath: 'reference', code: ERROR_NOT_EQUAL_LENGTH, message: '' });
      }
      if (bankDeposit.reference && !RE_1_TO_30_TEXT_DIGITS.test(bankDeposit.reference)) {
        errorsTmp.push({ propertyPath: 'depositNumber', code: ERROR_INVALID_FORMAT, message: '' });
      }
      if (bankDeposit.reference && !bankDeposit.depositDate) {
        errorsTmp.push({ propertyPath: 'depositDate', code: ERROR_MISSING_OR_INVALID, message: '' });
      }
      if (errorsTmp.length > 0) {
        setErrors(errorsTmp);
        return false;
      }
      return true;
    }
    return false;
  }, [paymentCheque, bankDeposit, setErrors]);

  const onValidation = useCallback((): void => {
    const paymentChequeValid = validatePaymentCheque();

    if (paymentCheque && paymentChequeValid) {

      if (currentBankDepositNotChanged || hasNoBankDepositAssociated || attributeAnExistingBankDeposit || dissociatePaymentChequeFromBankDeposit) {
        PaymentChequeService.update(
          paymentCheque.id,
          paymentCheque.reference,
          paymentCheque.groupClassRegisteredPaymentSchedule?.id || 0,
          parseFloat(paymentCheque.amount),
          hasNoBankDepositAssociated || dissociatePaymentChequeFromBankDeposit ? null : bankDeposit?.id,
        )
          .then(() => {
            Toast.success(t('payment.paymentUpdated'));
            navigate(`${ PAYMENTS_DETAILED_CREDITCARD_OR_CHEQUE.replace(':classId', classId).replace(':paymentType', TAB_CHEQUES) }`);
          });
      }

      if (updatePaymentChequeAndCreateBankDeposit) {
        BankDepositService.create(bankDeposit.reference, dateFmt(new Date(bankDeposit.depositDate), FORMAT_DATE_API))
          .then((result) => {
            Toast.success(t('payment.depositCreated'));
            return result;
          })
          .then((result) => (
            PaymentChequeService.update(
              paymentCheque.id,
              paymentCheque.reference,
              paymentCheque.groupClassRegisteredPaymentSchedule?.id || 0,
              parseFloat(paymentCheque.amount),
              result.id || 0,
            )
          ))
          .then(() => {
            Toast.success(t('payment.paymentUpdated'));
            navigate(`${ PAYMENTS_DETAILED_CREDITCARD_OR_CHEQUE.replace(':classId', classId).replace(':paymentType', TAB_CHEQUES) }`);
          });
      }
    }
  }, [
    paymentCheque,
    bankDeposit,
    currentBankDepositNotChanged,
    hasNoBankDepositAssociated,
    attributeAnExistingBankDeposit,
    dissociatePaymentChequeFromBankDeposit,
    updatePaymentChequeAndCreateBankDeposit,
    t,
    history,
  ]);

  return (
    <AdminLayout groupClass={ groupClass } activeTab={ ADMIN_HEADER_PAYMENTS } preventPortraitMode isLoading={ isLoading } loaderType={ LOADER_TYPE_PAGE }>
      <div className="edit-cheque">
        <div className="edit-cheque-container container">
          <div className="btn-go-back">
            <ButtonGoBack />
          </div>

          <div className="edit-cheque-header">
            <div className="edit-cheque-title">
              <h1>{ t('payment.editCheque') }</h1>
            </div>
            <div className="fields">
              { paymentCheque && (
                <>
                  <div className="field-container">
                    <AdvancedSelectField
                      id="name"
                      name="name"
                      label={ t('payment.name') }
                      options={ [] }
                      placeholder={ t('payment.namePlaceholder') }
                      value={ nameOptionValue }
                      className="field-cheque"
                      isDisabled
                      required
                    />
                    <AdvancedSelectField
                      id="groupClassRegisteredPaymentSchedule"
                      name="groupClassRegisteredPaymentSchedule"
                      label={ t('payment.period') }
                      options={ [] }
                      placeholder={ t('payment.namePlaceholder') }
                      isClearable={ false }
                      value={ paymentScheduleOptionValue }
                      className="field-cheque"
                      isDisabled
                      required
                    />
                  </div>
                  <div className="field-container">
                    <InputField
                      id="amount"
                      name="amount"
                      label={ t('payment.chequeAmount') }
                      placeholder={ t('payment.chequeAmountPlaceholder') }
                      onChange={ handleChange }
                      value={ paymentCheque.amount }
                      color={ errorsProps.amount?.color }
                      labelProps={ errorsProps.amount?.labelProps }
                      helpProps={ errorsProps.amount?.error }
                      type="number"
                      step={ 1 }
                      className="field-cheque"
                      unauthorizedKeys={ RE_ONLY_NUMBERS }
                      required
                    />

                    <InputField
                      id="reference"
                      name="reference"
                      label={ t('payment.chequeNumber') }
                      onChange={ handleChange }
                      value={ paymentCheque.reference }
                      color={ errorsProps.reference?.color }
                      labelProps={ errorsProps.reference?.labelProps }
                      helpProps={ errorsProps.reference?.error }
                      className="field-cheque"
                      type="text"
                      unauthorizedKeys={ RE_ONLY_TEXT_NUMBERS }
                      required
                    />
                  </div>

                  <BankDepositInput
                    idx={ idx }
                    bankDeposit={ bankDeposit }
                    handleBankDepositData={ handleBankDepositData }
                    errorsProps={ errorsProps }
                    className="field-container"
                    selectClassName="field-cheque"
                    datePickerClassName="field-cheque"
                  />
                  { showOverPaymentMessage && (
                    <div className="overpayment-cheque">
                      <IconWarning />
                      { t('payment.chequeAmountOverpaid') }
                    </div>
                  ) }
                </>
              ) }
            </div>
          </div>

          <div className="validation-button">
            <Button
              type={ buttonConstants.PRIMARY }
              onClick={ onValidation }
            >
              { t('payment.validateChanges') }
            </Button>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default withGroupClass(EditCheque);
