// @flow
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import withGroupClass from '@hoc/withGroupClass';

import AdminLayout from '@layout/components/AdminLayout';

import GroupClassRow from '@payment/components/GroupClassRow';
import PaymentChequeRow from '@payment/components/DetailBankDeposit/PaymentChequeRow';
import Button, { buttonConstants } from '@shared/Button';
import Loader from '_common/components/loader/Loader';
import ButtonGoBack from '@shared/Navigation/ButtonGoBack';
import IconCircleXMark from '@icons/components/IconCircleXMark';

import DateService from '_common/services/DateService';
import BankDepositService from '@payment/services/BankDepositService';

import type { GroupClassDetailed, ShortGroupClass } from '@groupClass/types';
import type { BankDepositDetailed, ShortPaymentCheque } from '@payment/types';

import { LOADER_TYPE_BACKGROUND } from '_common/components/loader/constants';
import { WEB_PATHS } from '@app/constants/paths';
import { ADMIN_HEADER_PAYMENTS } from '@layout/constants';
const { PAYMENTS_DEPOSIT_EDIT, PAGE_NOT_FOUND } = WEB_PATHS;

type ClassRow = {
  groupClass: ShortGroupClass,
  paymentCheques: ShortPaymentCheque[],
};

type Props = {
  groupClass: GroupClassDetailed | null,
};

const DetailBankDeposit = (props: Props): React$Node => {
  const { groupClass } = props;
  const { t, i18n: { language } } = useTranslation();

  const params = useParams();
  const navigate = useNavigate();

  const bankDepositId = parseInt(params.depositId, 10);

  const [ bankDeposit, setBankDeposit ] = useState<BankDepositDetailed | null>(null);
  const [ isLoading, setIsLoading ] = useState<boolean>(true);
  const [ showErrorBankDepositEdition, setShowErrorBankDepositEdition ] = useState<boolean>(false);

  useEffect(() => {
    if (groupClass) {
      BankDepositService.fetchOne(bankDepositId)
        .then(setBankDeposit)
        .then(() => setIsLoading(false))
        .catch(() => navigate(PAGE_NOT_FOUND) );
    }

    return () => BankDepositService.reset();
  }, [bankDepositId, setBankDeposit, setIsLoading, history, groupClass]);

  const classRows = useMemo((): ClassRow[] => {
    const rows: ClassRow[] = [];
    if (bankDeposit && groupClass) {
      bankDeposit.paymentCheques
        .forEach((paymentCheque) => {
          const { groupClass } = paymentCheque;
          let i = rows.findIndex((el) => el.groupClass.id === groupClass.id);
          if (i === -1) {
            rows.push({ groupClass, paymentCheques: [] });
            i = rows.length - 1;
          }
          rows[i].paymentCheques.push(paymentCheque);
        });

      rows
        .sort((a, b) => a.groupClass.activity.name.localeCompare(b.groupClass.activity.name))
        .forEach((row) => {
          row.paymentCheques.sort((a, b) => a.person.lastName.localeCompare(b.person.lastName));
        });
    }
    return rows;
  }, [bankDeposit, groupClass]);

  const formattedDate = useMemo((): string => (
    bankDeposit
      ? DateService.localDateDisplay(new Date(bankDeposit.depositDate), language)
      : ''
  ), [bankDeposit, language]);

  const editUrl = useMemo((): string => (
    bankDeposit
      ? `${ PAYMENTS_DEPOSIT_EDIT.replace(':classId', String(groupClass.id)).replace(':depositId', String(bankDeposit.id)) }`
      : '#'
  ), [bankDeposit, groupClass]);

  const handleEditClick = useCallback((): void => {
    if (bankDeposit?.isDeletable) {
      navigate(editUrl);
    }
    if (bankDeposit && !bankDeposit.isDeletable) {
      setShowErrorBankDepositEdition(!showErrorBankDepositEdition);
    }
  }, [history, editUrl, bankDeposit, setShowErrorBankDepositEdition, showErrorBankDepositEdition]);

  return (
    <AdminLayout groupClass={ groupClass } activeTab={ ADMIN_HEADER_PAYMENTS } preventPortraitMode>
      <div className="bank-deposit">
        <div className="bank-deposit-container container">
          { groupClass && (
            <ButtonGoBack />
          ) }

          { isLoading
            ? (
              <Loader loaderType={ LOADER_TYPE_BACKGROUND } />
            ) : (
              <>
                <div className="bank-deposit-header">
                  <div className="bank-deposit-title">
                    <h1>{ t('payment.detailBankDepositTitle', { depositRef: bankDeposit?.reference || '' }) }</h1>
                    <p className="txt-main">
                      { t('payment.detailBankDepositSubtitle',
                        { depositDate: formattedDate, interpolation: { escapeValue: false } })
                      }
                    </p>
                  </div>
                </div>

                <div className="table-payment-container">
                  <table className="payment-table light-blue-border rounded-corners txt-grey">
                    <thead>
                      <tr className="bg-light-blue">
                        <th className="cell-padding-left txt-main">
                          { t('payment.name') }
                        </th>
                        <th className="cell-padding txt-main" align="center">
                          { t('payment.period') }
                        </th>
                        <th className="cell-padding txt-main" align="center">
                          { t('payment.chequeNumberShort') }
                        </th>
                        <th className="cell-padding txt-main" align="center">
                          { t('payment.tablePaid') }
                        </th>
                      </tr>
                    </thead>
                    <tbody>

                      { isLoading && (
                        <tr>
                          <td colSpan="4" className="cell-text-centered">
                            <Loader className="rows-loading" />
                          </td>
                        </tr>
                      ) }

                      { !isLoading && classRows.map((el) => (
                        <GroupClassRow
                          key={ `group-class-${ el.groupClass.id }` }
                          numCols={ 4 }
                          groupClass={ el.groupClass }
                        >
                          { el.paymentCheques.map((paymentCheque, index) => (
                            <PaymentChequeRow
                              key={ `payment-cheque-${ paymentCheque.id }` }
                              index={ index }
                              paymentCheque={ paymentCheque } />
                          )) }
                        </GroupClassRow>
                      )) }

                    </tbody>
                  </table>
                </div>

                { showErrorBankDepositEdition && (
                  <div className="custom-error-container">
                    <div className="custom-error-message">
                      <IconCircleXMark />
                      { t('payment.bankDeposit.errorPeriodValidated') }
                    </div>
                  </div>
                ) }

                <div className="validation-button">
                  <Button
                    type={ buttonConstants.PRIMARY }
                    onClick={ handleEditClick }
                  >
                    { t('payment.editBankDepositLink') }
                  </Button>
                </div>
              </>
            )
          }
        </div>
      </div>
    </AdminLayout>
  );
};

export default withGroupClass(DetailBankDeposit);
