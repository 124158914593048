// @flow

type Props = {
  children: React$Node,
};

const NotificationBadge = (props: Props): React$Node => {
  const { children } = props;
  return (
    <div className="notification-badge">
      { children }
    </div>
  );
};

export default NotificationBadge;
