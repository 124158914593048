// @flow
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button, { buttonConstants } from '@shared/Button';
import IconChevron from '@icons/components/IconChevron';

const ButtonGoBack = (): React$Node => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goBack = useCallback((): void => {
    navigate(-1);
  }, []);

  return (
    <Button
      onClick={ goBack }
      type={ buttonConstants.TERTIARY }
      className="mb-5"
    >
      <IconChevron className="back-icon" /> { t('common.back') }
    </Button>
  );
};

export default ButtonGoBack;
