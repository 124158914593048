// @flow
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import IconTrash from '@icons/components/IconTrash';
import IconTransfer from '@icons/components/IconTransfer';
import IconPencil from '@icons/components/IconPencil';
import IconEllipsisVertical from '@icons/components/IconEllipsisVertical';

import DateService from '_common/services/DateService';
import { uppercaseFirst } from '_common/services/CommonUtils';

import type { PeriodItemOutput, GroupClassDetailed } from '@groupClass/types';
import type { ChequeBalance } from '@payment/types';

import { WEB_PATHS } from '@app/constants/paths';
const { PAYMENTS_CHEQUE_EDIT } = WEB_PATHS;
import {
  PAYMENT_STATUS_NOT_APPLICABLE,
  PAYMENT_STATUS_PENDING,
  PAYMENT_STATUS_TOFINALIZE,
  PAYMENT_STATUS_ACCEPTED,
  PAYMENT_STATUS_OVERPAID,
  PAYMENT_STATUS_REFUSED,
  PAYMENT_STATUS_ABANDONED,
} from '@payment/constants';

type Props = {
  cheque: ChequeBalance,
  groupClass: GroupClassDetailed,
  handleDeleteClick: (cheque: ChequeBalance) => void,
  handleTransferClick: (cheque: ChequeBalance) => void,
  index: number,
  isFirstLine: boolean,
  period: PeriodItemOutput,
};

const ChequeLine = (props: Props): React$Node => {
  const {
    cheque,
    groupClass,
    handleDeleteClick,
    handleTransferClick,
    index,
    isFirstLine,
    period,
  } = props;

  const {
    id,
    bankDepositReference,
    paid,
    paidAt,
    person,
    reference,
    status,
    toPay,
    unsubscribedAt,
  } = cheque;

  const { firstName, lastName } = person;

  const { t, i18n: { language } } = useTranslation();
  const navigate = useNavigate();

  const toPayFormatted = toPay.toLocaleString(language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const paidFormatted = paid.toLocaleString(language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const paidAtFormatted: string = paidAt ? DateService.localDateDisplay(paidAt, language) : '';

  const firstNameFormatted: string = uppercaseFirst(firstName);
  const lastNameFormatted: string = lastName.toUpperCase();

  const isPeriodOpen = useMemo((): boolean => (
    period.validatedAt === null
  ), [period]);

  const handleEdit = useCallback((): void => {
    if (isPeriodOpen && groupClass && cheque && !cheque.isPaymentOnValidatedPeriod) {
      navigate(PAYMENTS_CHEQUE_EDIT.replace(':classId', String(groupClass.id)).replace(':chequeId', String(cheque.id)));
    }
  }, [isPeriodOpen, groupClass, cheque]);

  const handleDelete = useCallback((cheque: ChequeBalance): void => {
    if (isPeriodOpen && !cheque.isPaymentOnValidatedPeriod) {
      handleDeleteClick(cheque);
    }
  }, [isPeriodOpen]);

  const handleTransfer = useCallback((cheque: ChequeBalance): void => {
    if (isPeriodOpen && !cheque.isPaymentOnValidatedPeriod) {
      handleTransferClick(cheque);
    }
  }, [isPeriodOpen]);

  const lineClassName = useMemo((): string => clsx({
    'table-body-colored': index % 2 === 0 && !unsubscribedAt,
    'table-body-unsubscribed': !!unsubscribedAt,
  }), [unsubscribedAt, index]);

  const cellNameClassName = useMemo((): string => clsx({
    'table-body-cell': true,
    'cell-text-name': true,
    'status-refused': status === PAYMENT_STATUS_REFUSED,
    'status-not-applicable': status === PAYMENT_STATUS_NOT_APPLICABLE,
    'status-pending': status === PAYMENT_STATUS_PENDING,
    'status-to-finalize': status === PAYMENT_STATUS_TOFINALIZE,
    'status-accepted': status === PAYMENT_STATUS_ACCEPTED,
    'status-overpaid': status === PAYMENT_STATUS_OVERPAID,
    'status-abandoned': status === PAYMENT_STATUS_ABANDONED,
    'status-is-unsubscribed': !!unsubscribedAt,
  }), [status, unsubscribedAt]);

  const chequeLineIconClassName = useMemo((): string => clsx({
    'icon-action': true,
    'icon-disabled': !isPeriodOpen || cheque.isPaymentOnValidatedPeriod,
  }), [isPeriodOpen, cheque]);

  const chequeLineEllipsisIcon = useMemo((): string => clsx({
    'icon-action': true,
    'navbar-link': true,
    'ellipsis-disabled': !isPeriodOpen || cheque.isPaymentOnValidatedPeriod,
  }), [isPeriodOpen, cheque]);

  return (
    <tr className={ lineClassName } key={ id }>
      <td className={ cellNameClassName }>
        { isFirstLine && (`${ firstNameFormatted } ${ lastNameFormatted }`) }
      </td>
      <td className="table-body-cell cell-text-centered">
        { isFirstLine && toPayFormatted }
      </td>
      <td className="table-body-cell cell-text-centered">
        { paidFormatted }
      </td>
      <td className="table-body-cell cell-text-centered">
        { paidAtFormatted }
      </td>
      <td className="table-body-cell cell-text-centered">
        { reference }
      </td>
      <td className="table-body-cell cell-text-centered">
        { bankDepositReference }
      </td>
      <td className="table-body-cell cell-text-centered">
        <div className="table-cell-actions-desktop">
          <IconPencil
            className={ chequeLineIconClassName }
            onClick={ handleEdit }/>
          <IconTransfer
            className={ chequeLineIconClassName }
            onClick={ () => handleTransfer(cheque) }
          />
          <IconTrash
            className={ chequeLineIconClassName }
            onClick={ () => handleDelete(cheque) }
          />
        </div>
        <div className="table-cell-actions-tablet navbar-item has-dropdown is-hoverable">
          <IconEllipsisVertical className={ chequeLineEllipsisIcon } />
          <div className="navbar-dropdown">
            <div className="payments-navbar-link">
              <div className="navbar-button">
                <button
                  className={ chequeLineIconClassName }
                  onClick={ handleEdit }
                >
                  { t('payment.actionEdit') }
                </button>
              </div>
            </div>
            <div className="navbar-button">
              <button
                className={ chequeLineIconClassName }
                onClick={ () => handleTransfer(cheque) }
              >
                { t('payment.actionTransfer') }
              </button>
            </div>
            <div className="navbar-button">
              <button
                className={ chequeLineIconClassName }
                onClick={ () => handleDelete(cheque) }
              >
                { t('payment.actionDelete') }
              </button>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default ChequeLine;
