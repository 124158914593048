// @flow
import i18n from '@app/i18n/i18n';
import {
  RE_MATCH_PERIOD_AMOUNT_PATTERN,
  RE_MATCH_PERIOD_ENDDATE_PATTERN,
  RE_MATCH_PERIOD_STARTDATE_PATTERN,
} from '@helpers/Regex';

export const IS_NULL = 'ad32d13f-c3d4-423b-909a-857b961eb720';

const COMMON_ERROR = 'commonError';
const DEFAULT = 'DEFAULT';
const DEFAULT_COMMON_ERROR = `${ COMMON_ERROR }.${ DEFAULT }`;

export const ERROR_IS_BLANK = 'c1051bb4-d103-4f74-8988-acbcafc7fdc3';
export const ERROR_MISSING_OR_INVALID = '0370a422-b4e6-11ec-b909-0242ac120002';
export const ERROR_NOT_EQUAL_LENGTH = '4b6f5c76-22b4-409d-af16-fbe823ba9332';
export const ERROR_INVALID_FORMAT = 'd4f6a402-b4e5-11ec-b909-0242ac120002';
export const PREVIOUS_PERIOD_NOT_VALIDED = '2c9f10c0-4527-4225-95f3-3706f4e6127c';
export const NEXT_PERIOD_NOT_VALIDED = '9dbcaa2e-14d0-4980-a7c7-0db65ecc26be';
export const INVALID_DURATION = '778b7ae0-84d3-481a-9dec-35fdb64b1d78';
export const INVALID_CHARACTERS = '21180565-8d34-463b-9fd2-4e32d5644bbc';
export const MAX_NUMBER_UPLOAD_FILE = '756b1212-697c-468d-a9ad-50dd783bb169';
export const MAX_UPLOAD_FILE_SIZE = '30fbb013-d015-4232-8b3b-8f3be97a7e14';
export const IS_NOT_UNIQUE = '23bd9dbf-6b9b-41cd-a99e-4844bcf3077f';

const CODE_LIST = {
  'ceeee6e7-0d52-4fa3-b94b-72f66d71bc2e': 'ADDRESS_BLANK',
  'c1051bb4-d103-4f74-8988-acbcafc7fdc3': 'IS_BLANK',
  'ad32d13f-c3d4-423b-909a-857b961eb720': 'IS_NULL',
  'ba785a8c-82cb-4283-967c-3cf342181b40': 'INVALID_TYPE',
  '9ff3fdc4-b214-49db-8718-39c315e33d45': 'TOO_SHORT',
  'd94b19cc-114f-4f44-9cc4-4138e80a87b9': 'TOO_LONG',
  '4b6f5c76-22b4-409d-af16-fbe823ba9332': 'NOT_EQUAL_LENGTH',
  '35e6a710-aa2e-4719-b58e-24b35749b767': 'INVALID_CHARACTERS',
  '30fbb013-d015-4232-8b3b-8f3be97a7e14': 'MAX_UPLOAD_FILE_SIZE',
  '69819696-02ac-4a99-9ff0-14e127c4d1bc': 'INVALID_FORMAT',
  '3c184ce5-b31d-4de7-8b76-326da7b2be93': 'INVALID_DATE',
  '3063a09b-4674-4e1c-ac0d-0c98008dd51f': 'VALID_COUNTRY',
  '3619ecca-5c09-431d-9ee3-e2f026861a22': 'DUPLICATE_PARTICIPANT',
  'bd79c0ab-ddba-46cc-a703-a7a4b08de310': 'STRICT_CHECK_FAILED',
  'ca23f4ca-38f4-4325-9bcc-eb570a4abe7f': 'INVALID_PHONE_NUMBER',
  '7911c98d-b845-4da0-94b7-a8dac36bc55a': 'IS_NOT_UNIQUE',
  '23bd9dbf-6b9b-41cd-a99e-4844bcf3077f': 'IS_NOT_UNIQUE',
  '7904d214-0232-4bd4-a066-2005ff5e6392': 'FIELD_REQUIRED',
  '2beabf1c-54c0-4882-a928-05249b26e23b': 'MUST_BE_TRUE',
  '9d5a5cd9-d04c-4f09-a8ce-ca6b47c39f31': 'PAYEMENT_CONDITION_TYPE_IS_BLANK',
  'ea4e51d1-3342-48bd-87f1-9e672cd90cad': 'PAYEMENT_SCHEDULE_NEGATIVE_AMOUNT',
  'd4f6a402-b4e5-11ec-b909-0242ac120002': 'INVALID_FORMAT',
  '0370a422-b4e6-11ec-b909-0242ac120002': 'MISSING_OR_INVALID',
  '6d2cf952-ec71-41e7-a29c-14e1d3f6f187': 'INVALID_ADDRESS',
  '778b7ae0-84d3-481a-9dec-35fdb64b1d78': 'INVALID_DURATION',
  '17042cc6-5aba-494d-bb2f-b9cbedb832bc': 'INVALID_DURATION',
  '2c9f10c0-4527-4225-95f3-3706f4e6127c': 'PREVIOUS_PERIOD_NOT_VALIDED',
  '9dbcaa2e-14d0-4980-a7c7-0db65ecc26be': 'NEXT_PERIOD_NOT_VALIDED',
  '21180565-8d34-463b-9fd2-4e32d5644bbc': 'INVALID_CHARACTERS',
  'bef8e338-6ae5-4caf-b8e2-50e7b0579e69': 'IS_BLANK',
  '756b1212-697c-468d-a9ad-50dd783bb169': 'MAX_NUMBER_UPLOAD_FILE',
};

class TranslationHelpers {

  static getCommonErrorKeyByCode(code: string, fieldName: string): string {
    let fieldNameCopy = fieldName;
    if (RE_MATCH_PERIOD_AMOUNT_PATTERN.test(fieldNameCopy)) {
      fieldNameCopy = 'groupClassPeriodsAmount';
    }

    if (RE_MATCH_PERIOD_STARTDATE_PATTERN.test(fieldNameCopy) || RE_MATCH_PERIOD_ENDDATE_PATTERN.test(fieldNameCopy)) {
      fieldNameCopy = 'groupClassPeriodsDate';
    }

    const codeKey = CODE_LIST[code] || null;
    const detailTranslate = `${ COMMON_ERROR }.${ fieldNameCopy }.${ codeKey }`;
    const partialTranslate = `${ COMMON_ERROR }.${ fieldNameCopy }.${ DEFAULT }`;

    if (fieldNameCopy && codeKey && i18n.exists(detailTranslate)){
      return detailTranslate;
    } else if (fieldNameCopy && i18n.exists(partialTranslate)){
      return partialTranslate;
    }
    return DEFAULT_COMMON_ERROR;
  }
}

export default TranslationHelpers;
