// @flow
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import moment from 'moment/dist/moment';

import type { BeneficiaryBalance } from '@beneficiary/types';

import { formatAmountWithEuros } from '@payment/helpers/AmountUtils';

import { WEB_PATHS } from '@app/constants/paths';
const { INVOICE_DETAILS } = WEB_PATHS;

type Props = {
  paymentScheduleId: number,
  balance: BeneficiaryBalance,
  index: number,
  numPeriods: number,
};

const PeriodBalanceRow = (props: Props): React$Node => {
  const { paymentScheduleId, balance, index, numPeriods } = props;

  const { t, i18n: { language } } = useTranslation();

  const amountFormatted = useMemo((): string => (
    formatAmountWithEuros(balance.toPay - balance.paid, language)
  ), [balance, language]);

  const periodBalanceRowButtonClassName = useMemo((): string => clsx({
    'button': true,
    'button-tertiary': numPeriods > 1,
    'button-primary': numPeriods === 1,
    'button-period': true,
  }), [numPeriods]);

  const labelPeriod: string = useMemo((): string => (
    t('payment.summary.periodFromTo', {
      startDate: moment(balance.period.startDate).locale(language).format('L'),
      endDate: moment(balance.period.endDate).locale(language).format('L'),
      interpolation: { escapeValue: false },
    })
  ), [t, language, balance]);

  return (
    <tr className={ index % 2 === 0 ? 'even change-mobile' : 'odd change-mobile' }>
      <td className="cell-padding-left cell-txt-middle">
        { labelPeriod }
      </td>
      <td className="cell-padding cell-txt-middle cell-txt-middle-amount" align="center">
        { amountFormatted }
      </td>
      <td className="cell-padding" align="center">
        <Link
          to={ {
            pathname: INVOICE_DETAILS,
            search: `?schedule=${ paymentScheduleId }`,
          } }
          className={ periodBalanceRowButtonClassName }
        >
          { t('payment.summary.payPeriod', { total: amountFormatted } ) }
        </Link>
      </td>
    </tr>
  );
};

export default PeriodBalanceRow;
