// @flow
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import bsCustomFileInput from 'bs-custom-file-input';
import clsx from 'clsx';

import withGroupClass from '@hoc/withGroupClass';

import AdminLayout from '@layout/components/AdminLayout';

import AdvancedSelectField from '@shared/AdvancedSelectField';
import Button, { buttonConstants } from '@shared/Button';
import LabelInformation from '_common/components/label-information/LabelInformation';
import Toast from '_common/services/Toast/Toast';
import Loader from '_common/components/loader/Loader';
import ButtonGoBack from '@shared/Button/components/ButtonGoBack';

import ObjectHelpers from '@helpers/ObjectHelpers';
import MediaObjectService from '@groupSend/services/MediaObjectService';
import SharedSessionService from '@groupSend/services/SharedSessionService';
import RegisteredsService, { type RegisteredsServiceData } from '@groupClass/services/RegisteredsService';

import { uppercaseFirst } from '_common/services/CommonUtils';

import type { Error, ErrorProps, Option } from '@core/types';
import type { GroupClassDetailed, ShortRegistered } from '@groupClass/types';

import { errorColor } from '@app/constants/styles';
import * as apiConstants from '@api/constants';
import * as coreConstants from '@app/constants/paths';
import * as groupClassesConstants from '@app/constants/constants';
import { ADMIN_HEADER_SENDING_GROUP } from '@layout/constants';
import { PERSON_TYPE_BENEFICIARY } from '@person/constants';
import Help from '_common/components/help/Help';
import { ERROR_IS_BLANK, MAX_NUMBER_UPLOAD_FILE, MAX_UPLOAD_FILE_SIZE } from '@helpers/TranslationHelpers';
import { LOADER_TYPE_PAGE } from '_common/components/loader/constants';

const { PARAMS_NO_UNSUBSCRIBE_NO_PAGINATED_SORTED_LASTNAME } = apiConstants;
const { WEB_PATHS } = coreConstants;
const { GROUP_SEND } = WEB_PATHS;
const { REFERENSIEL_SESSIONS_URL } = groupClassesConstants;

type Props = {
  groupClass: GroupClassDetailed | null,
};

const GroupClassSendSharedSession = (props: Props): React$Node => {
  const { groupClass } = props;
  const { t, i18n: { language } } = useTranslation();
  const navigate = useNavigate();

  const [ registered, setRegistered ] = useState<ShortRegistered[]>(RegisteredsService.groupClassRegistereds);
  const [ beneficiaries, setBeneficiaries ] = useState<Option[]>([]);
  const [ registeredLoading, setRegisteredLoading ] = useState<boolean>(RegisteredsService.isLoadingRegistereds);

  const [ filesToUpload, setFilesToUpload ] = useState<File[]>([]);
  const [ errors, setErrors ] = useState<Error[]>([]);
  const [ isSessionSendLoading, setIsSessionSendLoading ] = useState<boolean>(false);

  const handleUpdateRegistereds = (data: RegisteredsServiceData): void => {
    setRegistered(data.groupClassRegistereds);
    setRegisteredLoading(data.isLoadingRegistereds);
  };

  useEffect(() => RegisteredsService.onChange(handleUpdateRegistereds), []);

  useEffect(() => {
    bsCustomFileInput.init();

    return () => {
      bsCustomFileInput.destroy();
    };
  }, [filesToUpload]);

  useEffect(() => {
    if (groupClass) {
      RegisteredsService.fetchGroupClassRegistereds(groupClass.id, language, PARAMS_NO_UNSUBSCRIBE_NO_PAGINATED_SORTED_LASTNAME);
    }
  }, [groupClass]);

  const recipientsOptions = useMemo((): Option[] => (
    registered?.filter((register) => (register.person?.type === PERSON_TYPE_BENEFICIARY))
      .map((register) => ({
        label: `${ uppercaseFirst(register.person.firstName) } ${ register.person.lastName.toUpperCase() }`,
        value: String(register.person.id),
      })) || []
  ), [registered]);

  const handleChangeRegistereds = useCallback((values: Option[] | null): void => {
    setBeneficiaries(values || []);

    const newErrors: Error[] = ObjectHelpers.deepClone(errors)
      .filter((error) => error.propertyPath !== 'beneficiaries');

    setErrors(newErrors);
  }, [errors, setBeneficiaries, setErrors]);

  const handleUploadFiles = useCallback((e: SyntheticEvent<HTMLInputElement>): void => {
    const inputFiles: File[] = Array.from(e.currentTarget.files);

    const newErrors: Error[] = ObjectHelpers.deepClone(errors)
      .filter((error) => error.propertyPath !== 'uploadFiles');

    if (inputFiles.length > groupClassesConstants.MAX_UPLOAD_FILES) {
      newErrors.push({
        propertyPath: 'uploadFiles',
        code: MAX_NUMBER_UPLOAD_FILE,
        message: t('groupClasses.groupClass.sendGroup.global.maxFiles', { 'maxFiles': groupClassesConstants.MAX_UPLOAD_FILES }),
      });
    }

    const uploadFiles = inputFiles.map((el) => {
      if (el.size > groupClassesConstants.MAX_UPLOAD_FILE_SIZE) {
        newErrors.push({
          propertyPath: 'uploadFiles',
          code: MAX_UPLOAD_FILE_SIZE,
          message: t('groupClasses.groupClass.sendGroup.global.maxSizeFiles'),
        });
      }
      return el;
    });

    setErrors(newErrors);
    if (newErrors.length === 0) {
      setFilesToUpload(uploadFiles);
    }
  }, [errors, setErrors]);

  const handleReturn = useCallback((): void => {
    setErrors([]);
    setIsSessionSendLoading(false);
    navigate(GROUP_SEND.replace(':classId', String(groupClass?.id)));
  }, [groupClass, setErrors, setIsSessionSendLoading]);

  const uploadFile = useCallback((file: File): Promise<number> => (
    MediaObjectService.createMediaObject(file)
      .then((mediaObject) => (mediaObject.id))
  ), []);

  const handleSubmit = useCallback((e: SyntheticEvent<EventTarget>): void => {
    e.preventDefault();
    setIsSessionSendLoading(true);
    const newErrors: Error[] = [];

    if (beneficiaries.length === 0) {
      newErrors.push({
        propertyPath: 'beneficiaries',
        code: ERROR_IS_BLANK,
        message: t('groupClasses.groupClass.sendGroup.global.RecipientMandatory'),
      });
    }

    if (filesToUpload.length === 0) {
      newErrors.push({
        propertyPath: 'uploadFiles',
        code: ERROR_IS_BLANK,
        message: t('groupClasses.groupClass.sendGroup.global.SessionsMandatory'),
      });
    }

    if (newErrors.length === 0 && groupClass) {
      Promise.allSettled(
        filesToUpload.map(uploadFile),
      )
        .catch(() => {
          newErrors.push({
            propertyPath: 'uploadFiles',
            code: MAX_UPLOAD_FILE_SIZE,
            message: t('groupClasses.groupClass.sendGroup.global.maxSizeFiles'),
          });
        })
        .then((fileIds) => ({
          beneficiaries: beneficiaries.map((opt) => parseInt(opt.value, 10)),
          files: fileIds.map((el) => parseInt(el.value, 10)),
        }))
        .then((dataFormSharedSession) => SharedSessionService.createSharedSession(dataFormSharedSession))
        .then(() => setIsSessionSendLoading(false))
        .then(() => Toast.success(t('groupClasses.groupClass.sendGroup.session.sended')))
        .then(() => navigate(GROUP_SEND.replace(':classId', String(groupClass.id))));
    }

    setErrors(newErrors);
  }, [errors, setErrors, beneficiaries, filesToUpload, groupClass, uploadFile, setIsSessionSendLoading]);

  const errorsProps = useMemo((): ErrorProps => {
    let errorsPropsTmp = {};
    errors.forEach((error) => {
      let propertyPath = error.propertyPath;
      Object.assign(errorsPropsTmp, {
        [`${ propertyPath }`]: {
          color: 'is-danger',
          borderColor: errorColor,
          labelProps: { className: 'has-text-danger' },
          error: {
            text: t(error.message),
            textColor: 'is-danger',
          },
        },
      });
    });
    return errorsPropsTmp;
  }, [errors, language]);

  const hasRecipients = useMemo((): boolean => (
    !!recipientsOptions.length
  ), [recipientsOptions]);

  const placeholderDiv = useMemo((): React$Node => {
    const message = hasRecipients
      ? t('groupClasses.groupClass.sendGroup.global.firstFieldPlaceholder')
      : t('groupClasses.groupClass.sendGroup.global.noRegistered');

    return (
      <div className="group-send-recipients-placeholder">
        { message }
      </div>
    );
  }, [hasRecipients, t]);

  const fileInputLabel = useMemo((): string => clsx({
    'label': true,
    'g-label': true,
    'is-flex': true,
    'has-text-danger': errorsProps.uploadFiles?.labelProps,
  }), [errors]);

  return (
    <AdminLayout groupClass={ groupClass } activeTab={ ADMIN_HEADER_SENDING_GROUP } preventPortraitMode>
      <div className="group-class-send-message">
        <div className="container">
          <ButtonGoBack onClick={ handleReturn } />

          <div className="group-class-send-container">
            <div className="container-flex">
              <h1>{ t('groupClasses.groupClass.sendGroup.session.title') }</h1>
            </div>

            <div className="container-white">
              { isSessionSendLoading && (
                <Loader loaderType={ LOADER_TYPE_PAGE } />
              ) }
              { !isSessionSendLoading && (
                <>
                  <form>
                    <AdvancedSelectField
                      name="first-field"
                      label={ t('groupClasses.groupClass.sendGroup.global.firstField') }
                      options={ recipientsOptions }
                      isLoading={ registeredLoading }
                      isClearable={ false }
                      className="send-input"
                      borderColor={ errorsProps.beneficiaries?.borderColor }
                      color={ errorsProps.beneficiaries?.color }
                      labelProps={ errorsProps.beneficiaries?.labelProps }
                      onChange={ handleChangeRegistereds }
                      placeholder={ placeholderDiv }
                      value={ hasRecipients && beneficiaries }
                      isMulti={ hasRecipients && t('groupClasses.groupClass.sendGroup.global.allParticipants') }
                      isDisabled={ !hasRecipients }
                      isSearchable={ false }
                      required
                    />

                    <label className={ fileInputLabel }>
                      { t('groupClasses.groupClass.sendGroup.session.secondField') }
                    </label>
                    <div className="custom-file">
                      <input
                        id="uploadFiles"
                        type="file"
                        multiple
                        className="custom-file-input"
                        accept="application/pdf"
                        onChange={ handleUploadFiles }
                      />
                      <label
                        className={ `custom-file-label ${ errorsProps.uploadFiles?.error ? 'red' : '' }` }
                        htmlFor="uploadFiles"
                        data-content={ t('groupClasses.groupClass.sendGroup.session.uploadFile') }
                      >
                        { t('groupClasses.groupClass.sendGroup.session.secondField') }
                      </label>
                    </div>
                    { errorsProps.uploadFiles?.error && (
                      <Help { ...errorsProps.uploadFiles?.error } textColor={ errorsProps.uploadFiles?.color } />
                    ) }

                    <LabelInformation
                      hideColon={ true }
                      linkUrl={ REFERENSIEL_SESSIONS_URL }
                      title={ t('groupClasses.groupClass.sendGroup.session.findOnReferenSiel') }
                    />

                    <div className="buttons-container">
                      <Button
                        className="cancel-button"
                        onClick={ handleReturn }
                        type={ buttonConstants.TERTIARY }
                      >
                        { t('common.cancel') }
                      </Button>
                      <Button
                        className="send-button"
                        onClick={ handleSubmit }
                        type={ buttonConstants.PRIMARY }
                      >
                        { t('groupClasses.groupClass.sendGroup.global.send') }
                      </Button>
                    </div>
                  </form>
                </>
              ) }
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export {
  GroupClassSendSharedSession,
};

export default withGroupClass(GroupClassSendSharedSession);
