// @flow
import type { Listener } from '@core/types';
import type { PaymentConditionType, PaymentConditionTypes } from '@paymentConditionType/types';
import * as coreConstants from '@app/constants/paths';
import EventEmitter from '_common/services/EventEmitter';
import ApiService from '@api/service';
import { parsePaymentConditionTypesFromPayload } from '@paymentConditionType/services/PaymentConditionTypesUtils';

const { API_PATHS } = coreConstants;
const { PAYMENT_CONDITION_TYPES } = API_PATHS;

export type PaymentConditionTypeServiceData = {
  conditionTypes: PaymentConditionTypes,
  isLoading: boolean,
};

type UpdateValues = (newConditionTypes: PaymentConditionTypes, newIsLoading: boolean) => void;
type FetchAll = (language: string) => void;
type OnChange = (listener: Listener) => Function;
type Trigger = () => void;

let sourceAll = ApiService.createToken();

class PaymentConditionTypeService {
  constructor() {
    this.eventEmitter = new EventEmitter();

    this.conditionTypes = [];
    this.isLoading = false;
  }
  eventEmitter: EventEmitter;
  conditionTypes: PaymentConditionTypes;
  isLoading: boolean;

  get conditionTypesValues(): PaymentConditionTypes {
    return this.conditionTypes;
  }
  get isLoadingValue(): boolean {
    return this.isLoading;
  }

  updateValues: UpdateValues = (newConditionTypes: PaymentConditionTypes, newIsLoading: boolean): void => {
    this.conditionTypes = newConditionTypes;
    this.isLoading = newIsLoading;
    this.#trigger();
  };

  fetchAll: FetchAll = (language: string): void => {
    sourceAll.cancel();
    sourceAll = ApiService.createToken();

    this.updateValues(this.conditionTypes, true);

    ApiService.request({
      method: 'get',
      url: PAYMENT_CONDITION_TYPES,
      cancelToken: sourceAll.token,
      headers: {
        'Accept-Language': language,
      },
      params: {
        pagination: false,
      },
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;
          const newConditionTypes: PaymentConditionTypes = data['hydra:member'].map(
            (conditionType: PaymentConditionType) => (
              parsePaymentConditionTypesFromPayload(conditionType)
            ),
          );

          this.updateValues(newConditionTypes, false);
        }
      })
      .catch(() => {
        this.updateValues(this.conditionTypes, false);
      });
  };

  onChange: OnChange = (listener: Listener): Function => {
    const listenerFunction = this.eventEmitter.addListener(listener);
    this.#trigger();
    return listenerFunction;
  };

  /**
   * @private
   */
  #trigger: Trigger = (): void => {
    this.eventEmitter.trigger({
      conditionTypes: this.conditionTypes,
      isLoading: this.isLoading,
    });
  };
}

const instance: PaymentConditionTypeService = new PaymentConditionTypeService();
export default instance;
