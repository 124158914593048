// @flow
import { useCallback } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import * as dateTimePickerConstants from '@shared/DateTimePicker/constants';
import IconArrow from '@icons/components/IconArrow';

type Props = {
  date: moment$Moment,
  navigationDate: moment$Moment,
  withYear: boolean,
  isValid: (date: moment$Moment, viewMode: string) => boolean,
  onChange: (date: moment$Moment, viewMode: string) => void,
  onNavigateDateChange: (date: moment$Moment) => void,
  onYearClicked: () => void,
};

const MonthPicker = (props: Props): React$Node => {
  const {
    date,
    isValid,
    navigationDate,
    onChange,
    onNavigateDateChange,
    onYearClicked,
    withYear,
  } = props;

  const { i18n: { language } } = useTranslation();
  moment.locale(language);

  const handleDateClick: Function = useCallback((date: moment$Moment): void => {
    if (isValid(date, dateTimePickerConstants.VIEW_MODE_MONTH)) {
      onChange(date, dateTimePickerConstants.VIEW_MODE_MONTH);
    }
  }, [isValid, onChange]);

  const handleYearClick: Function = useCallback((): void => {
    if (withYear) {
      onYearClicked();
    }
  }, [withYear, onYearClicked]);

  const goPreviousYear: Function = useCallback((): void => {
    const newDate = moment(navigationDate).subtract(1, 'years');
    onNavigateDateChange(newDate);
  }, [navigationDate, onNavigateDateChange]);

  const goNextYear: Function = useCallback((): void => {
    const newDate = moment(navigationDate).add(1, 'years');
    onNavigateDateChange(newDate);
  }, [navigationDate, onNavigateDateChange]);

  const year = moment(navigationDate).format('YYYY');
  const yearValueClassName = clsx({
    'month-picker-year-value': true,
    'with-year': withYear,
  });
  const months = [];

  for (let i = 0; i < 12; i++) {
    const newDate = moment(navigationDate).month(i);
    months.push(newDate);
  }

  return (
    <div className="month-picker">
      <div className="month-picker-year">
        <IconArrow className="arrow-previous" onClick={ goPreviousYear } />
        <span className={ yearValueClassName } onClick={ handleYearClick }>
          { year }
        </span>
        <IconArrow className="arrow-next" onClick={ goNextYear } />
      </div>

      <div className="month-picker-container">
        { months.map((month, index) => {
          const isCurrent = moment(date).isSame(month, 'month');
          return (
            <div
              key={ index }
              className="month-picker-item"
              onClick={ () => handleDateClick(month) }
            >
              { month.format('MMMM') }

              { isCurrent && (
                <div className="month-current" />
              ) }
            </div>
          );
        }) }
      </div>
    </div>
  );
};

MonthPicker.defaultProps = {
  date: '',
  navigationDate: '',
  withYear: false,
  isValid: (): boolean => true,
  onChange: (): void => undefined,
  onNavigateDateChange: (): void => undefined,
  onYearClicked: (): void => undefined,
};

export default MonthPicker;
