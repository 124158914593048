// @flow

import type { GroupClassDetailed } from '@groupClass/types';
import type { RolesTypeValue } from '@beneficiary/types';
import type { User, SielBleuUserDetailed } from '@user/types';

import { extractUserFromSielBleuUser } from '@user/helpers/SielBleuUsersUtils';

import { Roles } from '@beneficiary/constants';

class RolesHelpers {

  /**
   * isCoachAllowedOnGroupClass
   * @param {SielBleuUserDetailed} sielBleuUser
   * @param {GroupClassDetailed} groupClass
   * @return {boolean}
   */
  static isCoachAllowedOnGroupClass(sielBleuUser: SielBleuUserDetailed, groupClass: GroupClassDetailed): boolean {
    const user = extractUserFromSielBleuUser(sielBleuUser);
    if (RolesHelpers.isCoach(user) || RolesHelpers.isManager(user)) {
      const { coaches } = groupClass;
      return coaches
        .filter((coach) => coach.id)
        .findIndex((coach) => coach.sielBleuUser.id === user.id) !== -1;
    }
    return false;
  }

  /**
   * isRDAllowedOnGroupClass
   * @param {SielBleuUserDetailed} user
   * @param {GroupClassDetailed} groupClass
   * @return {boolean}
   */
  static isRDAllowedOnGroupClass(sielBleuUser: SielBleuUserDetailed, groupClass: GroupClassDetailed): boolean {
    const user = extractUserFromSielBleuUser(sielBleuUser);
    if (RolesHelpers.isCoachAllowedOnGroupClass(sielBleuUser, groupClass)) return true;
    if (RolesHelpers.isManager(user)) {
      const { sector } = groupClass;
      const { sectorIds } = sielBleuUser;
      if (sectorIds.includes(sector.id)) return true;
    }
    return false;
  }

  /**
   * isSielBleuUserAllowedOnGroupClass
   * @param {SielBleuUserDetailed} sielBleuUser
   * @param {GroupClassDetailed} groupClass
   * @return {boolean}
   */
  static isSielBleuUserAllowedOnGroupClass(sielBleuUser: SielBleuUserDetailed, groupClass: GroupClassDetailed): boolean {
    const user = extractUserFromSielBleuUser(sielBleuUser);
    if (RolesHelpers.isAdmin(user)) return true;
    if (RolesHelpers.isRDAllowedOnGroupClass(sielBleuUser, groupClass)) return true;
    return false;
  }

  /**
   * isAdmin
   * @param {User | null} user
   * @return {boolean}
   */
  static isAdmin(user: User | null): boolean {
    if (user && RolesHelpers.hasRoleAdmin(user.role)) return true;
    return false;
  }
  /**
   * isManager
   * @param {User | null} user
   * @return {boolean}
   */
  static isManager(user: User | null): boolean {
    if (user && RolesHelpers.hasRoleManager(user.role)) return true;
    return false;
  }

  /**
   * isCoach
   * @param {User | null} user
   * @return {boolean}
   */
  static isCoach(user: User | null): boolean {
    if (user && RolesHelpers.hasRoleCoach(user.role)) return true;
    return false;
  }

  /**
   * isBeneficiary
   * @param {User | null} user
   * @return {boolean}
   */
  static isBeneficiary(user: User | null): boolean {
    if (user && RolesHelpers.hasRoleBeneficiary(user.role)) return true;
    return false;
  }

  /**
   * isSielBleuUser
   * @param {User | null} user
   * @return {boolean}
   */
  static isSielBleuUser(user: User | null): boolean {
    if (user && (RolesHelpers.hasRoleCoach(user.role) || RolesHelpers.hasRoleAdmin(user.role) || RolesHelpers.hasRoleManager(user.role))) return true;
    return false;
  }

  /**
   * hasRoleUser
   * @param {Array<RolesTypeValue>} roles
   * @return {boolean}
   */
  static hasRoleUser(roles: Array<RolesTypeValue>): boolean {
    return roles.includes(Roles.User);
  }

  /**
   * hasRoleBeneficiary
   * @param {Array<RolesTypeValue>} roles
   * @return {boolean}
   */
  static hasRoleBeneficiary(roles: Array<RolesTypeValue>): boolean {
    return roles.includes(Roles.Beneficiary);
  }

  /**
   * hasRoleAdmin
   * @param {Array<RolesTypeValue>} roles
   * @return {boolean}
   */
  static hasRoleAdmin(roles: Array<RolesTypeValue>): boolean {
    return roles.includes(Roles.Admin);
  }

  /**
   * hasRoleManager
   * @param {Array<RolesTypeValue>} roles
   * @return {boolean}
   */
  static hasRoleManager(roles: Array<RolesTypeValue>): boolean {
    return roles.includes(Roles.Manager);
  }

  /**
   * hasRoleCoach
   * @param {Array<RolesTypeValue>} roles
   * @return {boolean}
   */
  static hasRoleCoach(roles: Array<RolesTypeValue>): boolean {
    return roles.includes(Roles.Coach);
  }
}

export default RolesHelpers;
