// @flow
import { useCallback } from 'react';

import Checkbox from '@shared/Checkbox/component/Checkbox';
import Field from '_common/components/field/Field';
import Help from '_common/components/help/Help';

import type { Colors, LabelProps, HelpProps } from '@core/types';

type Props = {
  checked?: boolean,
  children: React$Node,
  className: string,
  color?: Colors,
  helpProps?: HelpProps | null,
  isDisabled?: boolean,
  labelProps?: LabelProps,
  required?: boolean,
  onChange: () => void,
};

const CheckboxField = (props: Props): React$Node => {
  const {
    id,
    checked,
    children,
    className,
    color,
    helpProps,
    isDisabled,
    labelProps = {},
    onChange,
    required,
  } = props;

  const handleChange = useCallback((...args): void => {
    if (!isDisabled) { onChange?.apply(this, args); }
  }, [onChange, isDisabled]);

  return (
    <Field>
      <Checkbox
        id={ id }
        className={ className }
        isDisabled={ isDisabled }
        checked={ checked }
        onChange={ handleChange }
        labelProps={ labelProps }
        required={ required }
      >
        { children }
      </Checkbox>
      { helpProps && <Help { ...helpProps } textColor={ color } /> }
    </Field>
  );
};

CheckboxField.defaultProps = {
  onChange: () => {},
};

export default CheckboxField;
