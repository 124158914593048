// @flow
import { useMemo } from 'react';
import clsx from 'clsx';

import type { Tab } from '@shared/Navigation/TabElement/types';
import { getTabs } from '@shared/Navigation/Tabs/helpers/TabsUtils';
import TabElement from '@shared/Navigation/TabElement';

type Props = {
  tabs: Tab[],
  activeTab: string,
  className: string,
  onClick: (tab: Tab) => void,
};

const Tabs = (props: Props): React$Node => {
  const { tabs, activeTab, className, onClick } = props;

  const tabsClassName = useMemo((): string => clsx({
    'tabs-container-tabs': true,
    [String(className)]: !!className,
  }), [className]);

  return (
    <div className="tabs-container">
      <div className={ tabsClassName }>
        { getTabs(tabs, activeTab).map((tab) => (
          <TabElement
            key={ tab.name }
            tab={ tab }
            badge={ tab.badge }
            onClick={ () => onClick(tab) }
          />
        )) }
      </div>
    </div>
  );
};

Tabs.defaultProps = {
  className: 'tabs-container-tabs',
  onClick: () => {},
};

export default Tabs;
