// @flow
import type { Error, Listener, QueryParams, RequestStatusesType } from '@core/types';
import type { ShortRegistered, ShortRegisteredPayload, UserRegistered } from '@groupClass/types';

import EventEmitter from '_common/services/EventEmitter';
import ApiService from '@api/service';
import { hydrateShortRegisteredFromPayload, hydrateRegisteredFromPayload } from '@groupClass/helpers/RegisteredUtils';
import { RequestStatuses } from '@core/constants';

import { API_PATHS } from '@app/constants/paths';
import Toast from '_common/services/Toast/Toast';

const { GROUP_CLASS_REGISTEREDS, GROUP_CLASS_REGISTERED } = API_PATHS;

export type RegisteredsServiceData = {
  groupClassRegistereds: ShortRegistered[],
  isLoadingRegistereds: boolean,
  registeredsRequestStatus: RequestStatusesType,
  groupClassUnregistereds: ShortRegistered[],
  groupClassRegistered: UserRegistered | null,
  isLoadingRegistered: boolean,
  errors: Error[],
};

type UpdateValuesRegistereds = (groupClassRegistereds: ShortRegistered[], requestStatusRegistereds: RequestStatusesType, errors: Error[]) => void;
type UpdateValuesUnregistereds = (groupClassUnregistereds: ShortRegistered[], errors: Error[]) => void;
type UpdateValuesRegistered = (groupClassRegistered: UserRegistered | null, isLoadingRegistered: boolean, errors: Error[]) => void;

type Reset = () => void;
type CleanErrors = () => void;

type FetchAll = (groupClassId: number, language: string, params: QueryParams) => Promise<ShortRegistered[]>;
type FetchOne = (id: number, language: string) => Promise<UserRegistered>;
type Create = (registered: UserRegistered, groupClassId: number, language: string) => Promise<number | any>;
type Update = (registered: UserRegistered, language: string) => Promise<number | any>;
type Remove = (registeredId: number, language: string) => Promise<boolean>;
type OnChange = (listener: Listener) => Function;
type Trigger = () => void;

let sourceOne = ApiService.createToken();
let sourceAll = ApiService.createToken();
let sourceAllUnregistereds = ApiService.createToken();

class RegisteredsService {
  constructor() {
    this.eventEmitter = new EventEmitter();
    this._groupClassRegistereds = [];
    this._isLoadingRegistereds = false;
    this._registeredsRequestStatus = RequestStatuses.IDLE;
    this._groupClassUnregistereds = [];
    this._groupClassRegistered = null;
    this._isLoadingRegistered = false;
    this._errors = [];
  }

  eventEmitter: EventEmitter;
  _groupClassRegistereds: ShortRegistered[];
  _isLoadingRegistereds: boolean;
  _registeredsRequestStatus: RequestStatusesType;
  _groupClassUnregistereds: ShortRegistered[];
  _groupClassRegistered: UserRegistered | null;
  _isLoadingRegistered: boolean;
  _errors: Error[];

  get groupClassRegistereds(): ShortRegistered[] { return this._groupClassRegistereds; }
  get isLoadingRegistereds(): boolean { return this._isLoadingRegistereds; }
  get registeredsRequestStatus(): RequestStatusesType { return this._registeredsRequestStatus; }
  get groupClassUnregistereds(): ShortRegistered[] { return this._groupClassUnregistereds; }
  get groupClassRegistered(): UserRegistered | null { return this._groupClassRegistered; }
  get isLoadingRegistered(): boolean { return this._isLoadingRegistered; }
  get errors(): Error[] { return this._errors; }

  updateRegisteredsValues: UpdateValuesRegistereds = (newGroupClassRegistereds: ShortRegistered[], requestStatusRegistereds: RequestStatusesType, newErrors: Error[] = []): void => {
    this._groupClassRegistereds = newGroupClassRegistereds;
    this._isLoadingRegistereds = requestStatusRegistereds === RequestStatuses.PENDING;
    this._registeredsRequestStatus = requestStatusRegistereds;
    this._errors = newErrors;
    this.#trigger();
  };

  updateUnregisteredsValues: UpdateValuesUnregistereds = (newGroupClassUnregistereds: ShortRegistered[], newErrors: Error[] = []): void => {
    this._groupClassUnregistereds = newGroupClassUnregistereds;
    this._errors = newErrors;
    this.#trigger();
  };

  updateRegisteredValues: UpdateValuesRegistered = (newGroupClassRegistered: UserRegistered | null, newIsLoadingRegistered: boolean, newErrors: Error[] = []): void => {
    this._groupClassRegistered = newGroupClassRegistered;
    this._isLoadingRegistered = newIsLoadingRegistered;
    this._errors = newErrors;
    this.#trigger();
  };

  reset: Reset = (): void => {
    this._groupClassRegistereds = [];
    this._isLoadingRegistereds = false;
    this._registeredsRequestStatus = RequestStatuses.IDLE;
    this._groupClassUnregistereds = [];
    this._groupClassRegistered = null;
    this._isLoadingRegistered = false;
    this._errors = [];
    this.#trigger();
  };

  cleanErrors: CleanErrors = () => {
    this._errors = [];
    this.#trigger();
  };

  fetchGroupClassRegistereds: FetchAll = (groupClassId: number, language: string, params: QueryParams = {}): Promise<ShortRegistered[]> => {
    sourceAll.cancel();
    sourceAll = ApiService.createToken();

    this.updateRegisteredsValues([], RequestStatuses.PENDING, []);

    return ApiService.request({
      method: 'get',
      url: GROUP_CLASS_REGISTEREDS.replace(':id', String(groupClassId)),
      cancelToken: sourceAll.token,
      headers: {
        'Accept-Language': language,
      },
      params,
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;
          const registeredsArray: ShortRegistered[] = data['hydra:member'].map(
            (people: ShortRegisteredPayload) => hydrateShortRegisteredFromPayload(people),
          );

          this.updateRegisteredsValues(registeredsArray, RequestStatuses.SUCCEEDED, []);

          return registeredsArray;
        }
        return Promise.reject();
      })
      .catch(() => {
        this.updateRegisteredsValues(this.groupClassRegistereds, RequestStatuses.FAILED, []);

        return Promise.reject();
      });
  };

  fetchGroupClassUnregistereds: FetchAll = (groupClassId: number, language: string, params: QueryParams = {}): Promise<ShortRegistered[]> => {
    sourceAllUnregistereds.cancel();
    sourceAllUnregistereds = ApiService.createToken();

    this.updateUnregisteredsValues([], []);

    return ApiService.request({
      method: 'get',
      url: GROUP_CLASS_REGISTEREDS.replace(':id', String(groupClassId)),
      cancelToken: sourceAllUnregistereds.token,
      headers: {
        'Accept-Language': language,
      },
      params,
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;
          const unregisteredsArray: ShortRegistered[] = data['hydra:member'].map(
            (people: ShortRegisteredPayload) => hydrateShortRegisteredFromPayload(people),
          );

          this.updateUnregisteredsValues(unregisteredsArray, []);

          return unregisteredsArray;
        }
        return Promise.reject();
      })
      .catch(() => Promise.reject());
  };

  fetchOne: FetchOne = (id: number, language: string): Promise<UserRegistered> => {
    sourceOne.cancel();
    sourceOne = ApiService.createToken();

    this.updateRegisteredValues(null, true, []);

    return ApiService.request({
      method: 'get',
      url: `${ GROUP_CLASS_REGISTERED }/${ id }`,
      cancelToken: sourceOne.token,
      headers: {
        'Accept-Language': language,
      },
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;
          const newRegistered = hydrateRegisteredFromPayload(data);

          this.updateRegisteredValues(newRegistered, false, []);

          return newRegistered;
        }
        return Promise.reject();
      })
      .catch(() => {
        this.updateRegisteredValues(this.groupClassRegistered, false, []);

        return Promise.reject();
      });
  };

  createRegistered: Create = (registered: UserRegistered, groupClassId: number, language: string): Promise<number | any> => {
    this.updateRegisteredValues(null, true, []);

    return ApiService.request({
      method: 'post',
      url: GROUP_CLASS_REGISTERED,
      headers: {
        'Accept-Language': language,
      },
      data: {
        groupClass: groupClassId,
        person: registered.person.id,
        paymentFrequency: registered?.paymentFrequency || '',
        paymentConditions: registered?.paymentConditions?.map((paymentCondition) => paymentCondition.id) || [],
        paymentSchedules: registered?.paymentSchedules?.map((paymentSchedule) => (
          {
            groupClassPeriod: paymentSchedule.period.id,
            costAdjust: paymentSchedule.costAdjust,
          }
        )) || [],
        paymentComment: registered?.paymentComment || '',
      },
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;
          const newRegistered = hydrateRegisteredFromPayload(data);

          this.updateRegisteredValues(newRegistered, false, []);

          return data?.id;
        }
        return Promise.reject();
      })
      .catch((error) => {
        const errorsViolations = error.response?.data?.violations || this.errors;
        const isDuplicate = errorsViolations.some((errorViolation) => errorViolation.propertyPath === 'groupClass');
        if (isDuplicate) {
          this.updateRegisteredValues(this.groupClassRegistered, false, [
            { propertyPath: 'already_registered', message: 'error', code: '422' },
          ]);
        } else {
          this.updateRegisteredValues(this.groupClassRegistered, false, errorsViolations);
        }

        return Promise.reject();
      });
  };

  updateRegistered: Update = (registered: UserRegistered, language: string): Promise<number | any> => {
    this.updateRegisteredValues(null, true, []);

    return ApiService.request({
      method: 'put',
      url: `${ GROUP_CLASS_REGISTERED }/${ registered?.id || 0 }`,
      headers: {
        'Accept-Language': language,
      },
      data: {
        annualFeesChange: registered.hasChangedAnnualFees,
        paymentFrequency: registered?.paymentFrequency || '',
        paymentConditions: registered?.paymentConditions?.map((paymentCondition) => paymentCondition.id) || [],
        paymentSchedules: registered?.paymentSchedules?.map((paymentSchedule) => (
          {
            id: paymentSchedule?.id,
            groupClassPeriod: paymentSchedule.period.id,
            costAdjust: paymentSchedule.costAdjust,
          }
        )) || [],
        paymentComment: registered?.paymentComment || '',
        unsubscribedAt: registered?.unsubscribedAt || null,
      },
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;
          const newRegistered = hydrateRegisteredFromPayload(data);

          this.updateRegisteredValues(newRegistered, false, []);

          return data?.id;
        }
        return Promise.reject();
      })
      .catch((error) => {
        const errorsViolations = error.response?.data?.violations || this.errors;

        this.updateRegisteredValues(this.groupClassRegistered, false, errorsViolations);

        Toast.error((error.response?.data === 'Method failure: all period is validated'
          ? 'Toutes les périodes sont validées'
          : error.response?.data),
        );
        return Promise.reject();
      });
  };

  deleteRegistered: Remove = (registeredId: number, language: string): Promise<boolean> => {
    this.updateRegisteredValues(null, true, []);

    return ApiService.request({
      method: 'delete',
      url: `${ GROUP_CLASS_REGISTERED }/${ registeredId }`,
      headers: {
        'Accept-Language': language,
      },
    })
      .then(() => true)
      .catch(() => false)
      .finally((value) => {
        this.updateRegisteredValues(null, false, []);

        return value;
      });
  };

  onChange: OnChange = (listener: Listener) => {
    const listenerFunction = this.eventEmitter.addListener(listener);
    this.#trigger();
    return listenerFunction;
  };

  /**
   * @private
   */
  #trigger: Trigger = (): void => {
    this.eventEmitter.trigger({
      groupClassRegistereds: this._groupClassRegistereds,
      isLoadingRegistereds: this._isLoadingRegistereds,
      groupClassUnregistereds: this._groupClassUnregistereds,

      groupClassRegistered: this._groupClassRegistered,
      isLoadingRegistered: this._isLoadingRegistered,

      errors: this._errors,
    });
  };
}

const instance: RegisteredsService = new RegisteredsService();
export default instance;
