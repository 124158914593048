import { gql } from '@apollo/client';

const CITIES = gql`
  query Cities($search: String!) {
    cities(search: $search) {
      id
      location {
        latitude
        longitude
      }
      name
      zipcode
    }
  }
`;

export default CITIES;
