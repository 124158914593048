// @flow
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';

import withGroupClass from '@hoc/withGroupClass';

import AdminLayout from '@layout/components/AdminLayout';

import Button, { buttonConstants } from '@shared/Button';
import ButtonGoBack from '@shared/Button/components/ButtonGoBack';
import Toast from '_common/services/Toast/Toast';
import ModalAddChequeToBankDeposit from '@payment/components/ModalAddChequeToBankDeposit';
import IconTrash from '@icons/components/IconTrash';
import IconSquarePlus from '@icons/components/IconSquarePlus';
import IconCircleXMark from '@icons/components/IconCircleXMark';

import BankDepositService, { type BankDepositServiceData } from '@payment/services/BankDepositService';
import PaymentChequeService from '@payment/services/PaymentChequeService';
import { templatePaymentScheduleFromGroupClass } from '@groupClass/helpers/PeriodUtils';

import type { BankDepositDetailed, ShortPaymentCheque } from '@payment/types';
import type { GroupClassDetailed } from '@groupClass/types';

import { OPTIONS_DATE_FORMAT } from '@app/constants/constants';
import { WEB_PATHS } from '@app/constants/paths';
import { ADMIN_HEADER_PAYMENTS } from '@layout/constants';
const { PAYMENTS_BANK_DEPOSITS } = WEB_PATHS;

type ChequeRow = {
  id: number,
  reference: string,
  firstName: string,
  lastNameUppercase: string,
  amountFormatted: string,
  periodFormatted: string,
  cheque: ShortPaymentCheque,
};

type Props = {
  groupClass: GroupClassDetailed | null,
};

export const AddChequesBankDeposit = (props: Props): React$Node => {
  const { groupClass } = props;
  const { t, i18n: { language } } = useTranslation();
  const { depositDate: bankDepositDate } = useParams();
  const { reference: bankDepositReference } = useParams();

  const [ bankDeposit, setBankDeposit ] = useState<BankDepositDetailed | null>(BankDepositService.deposit);
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ isActiveModalAddCheque, setIsActiveModalAddCheque ] = useState<boolean>(false);

  const [ showCustomErrorBankDeposit, setShowCustomErrorBankDeposit ] = useState<boolean>(false);

  const handleBankDepositChange = (data: BankDepositServiceData): void => {
    if (data.deposit && data.deposit.paymentCheques.length > 0) {
      setBankDeposit(data.deposit);
      setShowCustomErrorBankDeposit(false);
    }
    if (data.deposit && data.deposit.paymentCheques.length === 0) {
      setBankDeposit(data.deposit);
    }
    setIsLoading(data.isLoading);
  };

  useEffect(() => BankDepositService.onChange(handleBankDepositChange), []);

  const toggleModalAddCheque = useCallback((): void => {
    let activeModalCheque = !isActiveModalAddCheque;
    setIsActiveModalAddCheque(activeModalCheque);
  }, [isActiveModalAddCheque, setIsActiveModalAddCheque]);

  const showSuccessMessage = useCallback((message: string): void => {
    Toast.success(t(message));
  }, [t]);

  const handleDeleteCheque = useCallback((cheque: ShortPaymentCheque): void => {
    const { id, reference, groupClassRegisteredPaymentSchedule, amount } = cheque;

    PaymentChequeService.update(
      id,
      reference,
      groupClassRegisteredPaymentSchedule.id || 0,
      amount,
      null,
    )
      .then(() => { BankDepositService.fetchOne(parseInt(bankDeposit.id, 10)); })
      .then(() => showSuccessMessage(t('payment.chequeSuccessfullyRemove')));
  }, [bankDeposit]);

  const organisedBankDeposits = useMemo((): ChequeRow[] => {
    const { paymentCheques = [] } = bankDeposit || {};

    if (paymentCheques.length > 0 && groupClass) {
      return paymentCheques.map((paymentCheque: ShortPaymentCheque) => {
        const { id, reference, person, groupClassRegisteredPaymentSchedule, amount } = paymentCheque;

        const { firstName, lastName } = person;
        const lastNameUppercase = lastName.toUpperCase();
        const amountFormatted = amount.toLocaleString(language, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        const periodFormatted = `${ t('payment.period') } ${ templatePaymentScheduleFromGroupClass(groupClassRegisteredPaymentSchedule, groupClass) }`;

        return {
          id, reference, firstName, lastNameUppercase, amountFormatted, periodFormatted, cheque: paymentCheque,
        };
      });
    }

    return [];
  }, [bankDeposit, language]);

  const depositDateToString = useMemo((): string => {
    const date = new Date(bankDepositDate);
    return date.toLocaleDateString(language, OPTIONS_DATE_FORMAT);
  }, [bankDepositDate]);

  const { paymentCheques = [] } = bankDeposit || {};

  return (
    <AdminLayout groupClass={ groupClass } activeTab={ ADMIN_HEADER_PAYMENTS } preventPortraitMode isLoading={ isLoading }>
      <div className="add-cheques-bank-deposit">
        <div className="add-cheque-bank-deposit-container container">
          <ButtonGoBack />
          <div className="add-cheques-bank-deposit-header">
            <h1 className="add-cheques-bank-deposit-title">{ t('payment.addBankDepositTitle2') }</h1>
            <div className="bank-deposit-information">
              <div className="bank-deposit-information-text">
                <p>{ `${ t('payment.bankDepositNumber') }${ t('common.required') }${ t('common.colons') } ${ bankDepositReference }` }</p>
              </div>
              <div className="bank-deposit-information-text">
                <p>{ `${ t('payment.depositDate') }${ t('common.required') }${ t('common.colons') } ${ depositDateToString }` }</p>
              </div>
            </div>
          </div>
          <>
            <div className="table-payment-container">
              <table className="table">
                <colgroup>
                  <col className="table-cell-width-name"/>
                  <col/>
                  <col/>
                  <col/>
                  <col className="table-cell-width-action-deposit"/>
                </colgroup>
                <thead className="table-head border-table-deposit">
                  <tr>
                    <th className="table-head-title table-cell-title-name">
                      { t('payment.name') }
                    </th>
                    <th className="table-head-title cell-text-centered">
                      { t('payment.period') }
                    </th>
                    <th className="table-head-title cell-text-centered">
                      { t('payment.chequeNumberShort') }
                    </th>
                    <th className="table-head-title cell-text-centered">
                      { t('payment.tablePaid') }
                    </th>
                    <th className="table-head-title cell-text-centered">
                      { t('payment.actions') }
                    </th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  { isLoading && (
                    <tr>
                      <td colSpan={ 5 } className="table-cell-cheques-empty" />
                    </tr>
                  ) }

                  { !isLoading && organisedBankDeposits.map((row) => {
                    const { id, reference, firstName, lastNameUppercase, amountFormatted, periodFormatted } = row;
                    const { cheque } = row;

                    return (
                      <tr key={ id }>
                        <td className="cell-text-name">
                          { `${ firstName } ${ lastNameUppercase }` }
                        </td>
                        <td className="cell-text-centered">
                          { periodFormatted }
                        </td>
                        <td className="cell-text-centered">
                          { reference }
                        </td>
                        <td className="cell-text-centered">
                          { amountFormatted }
                        </td>
                        <td className="cell-text-centered">
                          <div className="cell-icon-action">
                            <button className="navbar-item-button"
                              onClick={ () => handleDeleteCheque(cheque) }>
                              <IconTrash />
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  }) }

                  { !isLoading && groupClass && (
                    <tr>
                      <td colSpan={ 4 } className="table-cell-cheques-empty">
                        { t('payment.addChequeToBankDeposit') }
                      </td>
                      <td className="cell-text-centered">
                        <div>
                          <button className="navbar-item-button"
                            onClick={ toggleModalAddCheque }>
                            <IconSquarePlus className="icon-action-bank-deposit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ) }

                </tbody>
              </table>
            </div>

            <>
              { paymentCheques.length > 0
                ? (
                  <div className="validation-button">
                    <Link
                      to={ PAYMENTS_BANK_DEPOSITS.replace(':classId', String(groupClass?.id)) }
                      className="button button-cancel button-position mb-5 button-primary"
                    >
                      { t('payment.bankDepositFinish') }
                    </Link>
                  </div>
                )
                : (
                  <div className="custom-error-container">
                    { showCustomErrorBankDeposit && (
                      <div className="custom-error-message">
                        <IconCircleXMark />
                        { t('payment.bankDepositEmptyError') }
                      </div>
                    ) }

                    <Button onClick={ () => setShowCustomErrorBankDeposit(true) }
                      type={ buttonConstants.PRIMARY }>
                      { t('payment.bankDepositFinish') }
                    </Button>
                  </div>
                ) }
            </>
          </>
        </div>
      </div>
      { groupClass && (
        <ModalAddChequeToBankDeposit
          isActiveModalAddCheque={ isActiveModalAddCheque }
          toggleModalAddCheque={ toggleModalAddCheque }
          showSuccessMessage={ showSuccessMessage }
          initialBankDeposit={ bankDeposit }
          initialBankDepositReference={ bankDepositReference }
          initialBankDepositDate={ bankDepositDate }
          groupClass={ groupClass }
        />
      ) }
    </AdminLayout>
  );
};

export default withGroupClass(AddChequesBankDeposit);
