export const TOOLTIP_POSITION_TOP = 'top';
export const TOOLTIP_POSITION_RIGHT = 'right';
export const TOOLTIP_POSITION_BOTTOM = 'bottom';
export const TOOLTIP_POSITION_LEFT = 'left';

export const ARROW_DIRECTION_UP = 'up';
export const ARROW_DIRECTION_RIGHT = 'right';
export const ARROW_DIRECTION_DOWN = 'down';
export const ARROW_DIRECTION_LEFT = 'left';

export const ARROW_DIRECTION_FROM_POSITION = {
  [TOOLTIP_POSITION_TOP]: ARROW_DIRECTION_DOWN,
  [TOOLTIP_POSITION_RIGHT]: ARROW_DIRECTION_LEFT,
  [TOOLTIP_POSITION_BOTTOM]: ARROW_DIRECTION_UP,
  [TOOLTIP_POSITION_LEFT]: ARROW_DIRECTION_RIGHT,
};
