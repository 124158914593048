// @flow
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile, isSafari, isTablet } from 'react-device-detect';
import clsx from 'clsx';

import MarkerPopup from '_common/components/map/components/MarkerPopup';

import * as mapConstants from '_common/components/map/constants';
import AuthService from '@user/services/AuthService';
import { getLanguageFormat } from '_common/services/LanguageUtils';
import DocumentHelpers from '@helpers/DocumentHelpers';
import ObjectHelpers from '@helpers/ObjectHelpers';
import usePrevious from '@hooks/usePrevious';
import { type Map, GoogleMap, Marker, MarkerClusterer } from '@react-google-maps/api';
import type { LocationGroupClass } from '@shared/Locations/types';
import {
  MAINLAND_FRANCE_AREA_COORDINATES,
  MAP_DEFAULT_ZOOM,
  SPAIN_AREA_COORDINATES,
} from '_common/components/map/constants';

type Props = {
  activeMarker: number | null,
  isDataLoading: boolean,
  markers: LocationGroupClass[],
  zoom: number,
  zoomOnCountry: boolean,
  setZoomOnCountry: (value: boolean) => void,
  handleIdleMap: (map: Map) => void,
  handleZoomChange: (zoomLvl: number) => void,
};

const {
  DEFAULT_CENTER_POSITION,
  DEFAULT_SIZE_CONTAINER,
  DEFAULT_SIZE_CONTAINER_MOBILE,
  DEFAULT_SIZE_CONTAINER_TABLET,
  MIN_ZOOM_LEVEL,
  MAP_OPTIONS,
  OPTIONS_MARKER_CLUSTERER,
  SPAIN_CENTER_POSITION,
  SPAIN_LANGUAGE,
} = mapConstants;

export const MapComponent = (props: Props): React$Node => {
  const {
    activeMarker,
    markers,
    handleIdleMap,
    handleZoomChange,
    zoom,
    isDataLoading,
    zoomOnCountry,
    setZoomOnCountry,
  } = props;
  const prevActiveMarker = usePrevious(activeMarker);

  const [map, setMap] = useState<Map | null>(null);
  const [popupInfo, setPopupInfo] = useState<LocationGroupClass | null>(null);
  const [areMapBoundsLoaded, setAreMapBoundsLoaded] = useState<boolean>(false);
  const [sameMarkers, setSameMarkers] = useState<LocationGroupClass[]>([]);
  const [indexMarker, setIndexMarker] = useState<number>(0);

  const user = AuthService.user;
  const { i18n: { language } } = useTranslation();
  const locale = user ? getLanguageFormat(user.language) : getLanguageFormat(language);

  const removeMarkerActiveState = (assetId: number): void => {
    const element = document.getElementById(`map-marker-${ assetId }`);
    if (element) {
      DocumentHelpers.removeClass(element, 'is-active');
    }
  };

  const addMarkerActiveState = (assetId: number): void => {
    const element = document.getElementById(`map-marker-${ assetId }`);
    if (element) {
      DocumentHelpers.addClass(element, 'is-active');
    }
  };

  /**
   * Center the map on Mainland France.
   * Called upon landing on the page and when removing the search filters.
   */
  const scaleOnCountry = (): void => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      const corners = locale === SPAIN_LANGUAGE
        ? Object.values(SPAIN_AREA_COORDINATES)
        : Object.values(MAINLAND_FRANCE_AREA_COORDINATES);
      corners.forEach((corner) => {
        const { lat, lng } = corner;
        bounds.extend(new window.google.maps.LatLng(lat, lng));
      });
      map.setCenter(DEFAULT_CENTER_POSITION);
      map.fitBounds(bounds);
      map.setZoom(MIN_ZOOM_LEVEL);
      setZoomOnCountry(false);
    }
  };

  const autoScale = (): void => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      if (markers.length > 0) {
        markers.forEach((marker) => {
          const { lat, lng } = marker;
          bounds.extend(new window.google.maps.LatLng(lat, lng));
        });
        map.setCenter(bounds.getCenter());
        map.fitBounds(bounds);
        map.setZoom(MIN_ZOOM_LEVEL);
      } else {
        map.setZoom(MIN_ZOOM_LEVEL);
      }
    }
  };

  const onBoundsChanged = useCallback((): void => {
    if (map && !areMapBoundsLoaded) {
      setAreMapBoundsLoaded(true);
      handleIdleMap(map);
    }
  }, [areMapBoundsLoaded, map]);

  const onZoomChange = useCallback((): void => {
    if (map) {
      handleZoomChange(map.getZoom());
    }
  }, [map]);

  const handleNearestMarkersPopUp = useCallback((newMarker: null | LocationGroupClass): void => {
    if (newMarker === null) {
      setSameMarkers([]);
      setPopupInfo(null);
      setIndexMarker(0);
      return;
    }
    const filteredMarkers = markers.filter((marker) => marker.lat === newMarker.lat && marker.lng === newMarker.lng);
    const findIndex = filteredMarkers.findIndex((marker) => marker.id === newMarker.id);
    setSameMarkers(filteredMarkers);
    setPopupInfo(newMarker);
    setIndexMarker(parseInt(findIndex, 10));
  }, [markers]);

  const generateMarkersBlock = useCallback((clusterer): Array<React$Element<any>> => (
    markers.map((marker, index) => {
      const key = `${ marker.lat || 0 }-${ marker.lng || 0 }-${ index }`;
      return (
        <Marker
          key={ key }
          icon="__ASSET_PATH__/assets/marker.png"
          position={ marker }
          clusterer={ clusterer }
          onClick={ () => handleNearestMarkersPopUp(marker) }
        />
      );
    })
  ),[markers]);

  const onUnmount = useCallback((): void => {
    setMap(null);
  }, []);

  useEffect(() => (
    () => setAreMapBoundsLoaded(false)
  ), []);

  useEffect(() => {
    if (!ObjectHelpers.isEmpty(markers)) {
      if (prevActiveMarker) {
        removeMarkerActiveState(prevActiveMarker);
      }
      if (activeMarker) {
        addMarkerActiveState(activeMarker);
      }
    }
  }, [activeMarker, markers]);

  useEffect(() => {
    if (map && !zoomOnCountry) {
      autoScale();
    }
    if (map && zoomOnCountry) {
      scaleOnCountry();
    }
  }, [map, markers]);

  const onLoad = useCallback((mapLoaded: Map): void => {
    const bounds = new window.google.maps.LatLngBounds();
    mapLoaded.fitBounds(bounds);
    setMap(mapLoaded);
    scaleOnCountry();
  }, []);

  const onIdle = useCallback((): void => {
    handleIdleMap(map);
  }, [map]);

  const mapComponentClassName = useMemo((): string => clsx({
    'map-component': true,
    'is-safari': isSafari,
  }), [isSafari]);

  if (isDataLoading) {
    return (
      <div className="map-component">
        <div className="loading-map" />
      </div>
    );
  }

  const center = locale === SPAIN_LANGUAGE ? { ...SPAIN_CENTER_POSITION } : { ...DEFAULT_CENTER_POSITION };
  const defaultCenter = markers.length > 0 ? undefined : center;

  const style = isTablet
    ? DEFAULT_SIZE_CONTAINER_TABLET
    : (isMobile
      ? DEFAULT_SIZE_CONTAINER_MOBILE
      : DEFAULT_SIZE_CONTAINER
    );

  return (
    <div className={ mapComponentClassName }>
      { window?.google?.maps && !isDataLoading && (
        <GoogleMap
          id="google-map"
          options={ { ...MAP_OPTIONS } }
          onIdle={ onIdle }
          onBoundsChanged={ onBoundsChanged }
          onLoad={ onLoad }
          onZoomChanged={ onZoomChange }
          zoom={ zoom }
          onUnmount={ onUnmount }
          mapContainerStyle={ { ...style } }
          center={ defaultCenter }
        >
          <MarkerClusterer options={ { ...OPTIONS_MARKER_CLUSTERER } }>
            { (clusterer) => generateMarkersBlock(clusterer) }
          </MarkerClusterer>
          {
            popupInfo && (
              <MarkerPopup
                info={ popupInfo }
                sameMarkers={ sameMarkers }
                indexMarker={ indexMarker }
                onChange={ handleNearestMarkersPopUp }
                onUnmount={ () => handleNearestMarkersPopUp(null) }
              />
            )
          }
        </GoogleMap>
      ) }
      { !window?.google?.maps && <div className="loading-map" /> }
    </div>
  );
};

MapComponent.defaultProps = {
  activeMarker: null,
  isDataLoading: true,
  markers: ([]: Array<LocationGroupClass>),
  zoom: MAP_DEFAULT_ZOOM,
  zoomOnCountry: true,
  setZoomOnCountry: () => {},
  handleIdleMap: () => {},
  handleZoomChange: () => {},
};

export default MapComponent;
