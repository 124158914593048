// @flow

import NavBarItem from '@header/components/NavBarItem';
import { type NavLink } from '@header/types';

const NavLinks = ({ links }: { links: NavLink[] }): React$Element<React$ElementType> => (
  <>
    { links.map(
      (l: NavLink, index) =>
        l.isVisible && (
          <NavBarItem
            key={ index }
            to={ l.to }
            external={ l.external || false }
            target={ l.target }
            rel={ l.rel }
            asButton={ l.asButton || false }
            badge={ l.badge }
          >
            { l.children }
          </NavBarItem>
        ),
    ) }
  </>
);

export default NavLinks;
