// @flow
import { useTranslation } from 'react-i18next';

import IconTick from '@icons/components/IconTick';

type Props = {
  translationKey: string,
  isValid: boolean,
};

const PasswordHint = (props: Props): React$Node => {
  const { t } = useTranslation();
  const { translationKey, isValid } = props;

  return (
    <>
      <IconTick className={ isValid ? 'green-tick' : '' } />
      <span className="password-hint-text">{ t(translationKey) }</span>
    </>
  );
};

export default PasswordHint;
