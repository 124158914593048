// @flow
import { useMemo } from 'react';
import clsx from 'clsx';

type Props = {
  className: string,
  onClick: () => void,
};

const IconLinkedin = (props: Props): React$Node => {
  const {
    className,
    onClick,
  } = props;

  const iconSocialClassName = useMemo((): string => clsx({
    'icon-social': true,
    [String(className)]: !!className,
  }), [className]);

  const newProps = {
    className: iconSocialClassName,
    onClick,
  };

  return (
    <svg { ...newProps } viewBox="0 0 52.34 52.67" width="52.34" height="52.67">
      <g>
        <path className="bg"
          d="M0,3.77C0,1.69,1.73,0,3.87,0h44.6c2.14,0,3.87,1.69,3.87,3.77v45.12c0,2.08-1.73,3.77-3.87,3.77H3.87c-2.14,0-3.87-1.69-3.87-3.77V3.77Z"/>
        <g>
          <path className="icon"
            d="M16.97,42.44v-21.74h-7.23v21.74h7.23ZM13.35,17.73c2.52,0,4.09-1.67,4.09-3.76-.05-2.13-1.57-3.76-4.04-3.76s-4.09,1.62-4.09,3.76,1.57,3.76,3.99,3.76h.05Z"/>
          <path className="icon"
            d="M20.96,42.44h7.23v-12.14c0-.65.05-1.3.24-1.76.52-1.3,1.71-2.64,3.71-2.64,2.61,0,3.66,1.99,3.66,4.92v11.63h7.23v-12.47c0-6.68-3.57-9.79-8.32-9.79-3.9,0-5.61,2.18-6.56,3.66h.05v-3.15h-7.23c.09,2.04,0,21.74,0,21.74h0Z"/>
        </g>
      </g>
    </svg>
  );
};

IconLinkedin.defaultProps = {
  className: 'icon-linkedin',
  onClick: () => {
  },
};

export default IconLinkedin;
