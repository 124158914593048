// @flow
import { useMemo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import type { Colors, Sizes, LabelProps, HelpProps } from '@core/types';
import Field from '_common/components/field/Field';
import Control from '_common/components/control/Control';
import Textarea, { type TextAreaProps } from '_common/components/textarea/Textarea';
import Label from '_common/components/label/Label';
import Help from '_common/components/help/Help';

type Props = {
  className?: string,
  classNameTextarea?: string,
  color?: Colors | null,
  helpProps?: HelpProps | null,
  id?: string,
  isLoading?: boolean,
  label: React$Node,
  labelProps?: LabelProps,
  limitedChar?: number,
  name: string,
  placeholder?: string,
  required?: boolean,
  size?: Sizes,
  textareaProps?: TextAreaProps,
  value: string,
  onChange: (event: SyntheticEvent<HTMLInputElement>) => void,
};

const TEXTAREA_MAX_LENGTH = 524288; // default value of textarea max-length

const TextareaField = (props: Props): React$Node => {
  const {
    className,
    classNameTextarea,
    color,
    helpProps,
    id,
    isLoading,
    label,
    labelProps = {},
    limitedChar,
    name,
    onChange,
    placeholder,
    required,
    size,
    textareaProps,
    value,
  } = props;

  const { className: labelClassName, ...othersLabelProps } = labelProps;

  const { t } = useTranslation();

  const charLeft = limitedChar && value ? limitedChar - String(value).length : limitedChar;

  const helperClassName = useMemo((): string => clsx({
    'helper': true,
    'help-char-left': limitedChar && !helpProps,
  }), [limitedChar, helpProps]);

  const textareaFieldLabelClassName = useMemo((): string => clsx({
    'g-label': true,
    'is-flex': true,
    [String(labelClassName)]: !!labelClassName,
    [String(color)]: !!color,
  }), [labelClassName, color]);

  const TextareaFieldControlClassName = useMemo((): string => clsx({
    'g-form-field': true,
    [String(classNameTextarea)]: !!classNameTextarea,
  }), [classNameTextarea]);

  return (
    <Field>
      <div id={ id } className={ className }>
        <Label
          { ...othersLabelProps }
          size={ size }
          htmlFor={ name }
          className={ textareaFieldLabelClassName }
        >
          { label } { required && '*' }
        </Label>

        <Control size={ size } isLoading={ isLoading }>
          <Textarea
            { ...textareaProps }
            size={ size }
            name={ name }
            borderColor={ color }
            className={ TextareaFieldControlClassName }
            value={ value }
            maxLength={ limitedChar || TEXTAREA_MAX_LENGTH }
            onChange={ onChange }
            required={ required }
            placeholder={ placeholder }
          />
        </Control>
      </div>
      <div className={ helperClassName }>
        { helpProps && <Help { ...helpProps } textColor={ color } /> }

        { limitedChar && <Help text={ t('form.charactersLeft', { charLeft }) } /> }
      </div>
    </Field>
  );
};

export default TextareaField;
