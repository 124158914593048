// @flow
import type { Person, PersonPayload } from '@person/type';

import { formatAddressFromPayload } from '@shared/Locations/services/AddressUtils';

export const hydratePersonFromPayload = (person: PersonPayload): Person => ({
  id: person.id,
  address: person.address ? formatAddressFromPayload(person.address) : null,
  birthDate: new Date(person.birthDate),
  email: person?.email ?? '',
  firstName: person.firstName,
  landlinePhone: person?.landlinePhone ?? '',
  lastName: person.lastName,
  mobilePhone: person?.mobilePhone ?? '',
  sexe: person.sexe,
  type: person.type,
});
