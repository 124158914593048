// @flow
import EventEmitter from '_common/services/EventEmitter';
import ApiService from '@api/service';

import type { Error, Listener } from '@core/types';

import { API_PATHS } from '@app/constants/paths';
const { PAYMENT_TRANSFER } = API_PATHS;

export type PaymentTransferServiceData = {
  loading: boolean,
  errors: Error[],
};

type OnChange = (listener: Listener) => Function;
type TransferPayment = (paymentId: number, groupClassId: number, periodId: number) => Promise<boolean | null>;

type UpdateValues = (
  newLoading: boolean,
  newErrors: Error[],
) => void;

class PaymentTransferService {
  constructor() {
    this.eventEmitter = new EventEmitter();
    this.errors = [];
    this.loading = false;
  }

  eventEmitter: EventEmitter;
  loading: boolean;
  errors: Error[];

  updateValues: UpdateValues = (
    newLoading: boolean,
    newErrors: Error[],
  ): void => {
    this.loading = newLoading;
    this.errors = newErrors;
    this.#trigger();
  };

  transferPayment: TransferPayment = (paymentId: number, groupClassId: number, periodId: number): Promise<boolean | null> => {
    this.updateValues(true, this.errors);

    return ApiService.request({
      method: 'put',
      url: PAYMENT_TRANSFER.replace(':id', String(paymentId)),
      data: {
        payment: paymentId,
        groupClass: groupClassId,
        period: periodId,
      },
    })
      .then((payload) => {
        if (payload.data) {
          // const { data } = payload;
          // const updatedPayment = hydratePaymentFromPayload(/* data */);

          this.updateValues(false, this.errors);
          return true;
        }
        return Promise.reject();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.violations) {
            this.updateValues(false, error.response.data.violations);
          }
        }
        return Promise.reject();
      });
  };

  onChange: OnChange = (listener: Listener): Function => {
    const listenerFunction = this.eventEmitter.addListener(listener);
    this.#trigger();
    return listenerFunction;
  };

  #trigger = async () => {
    await this.eventEmitter.trigger({
      loading: this.loading,
      errors: this.errors,
    });
  };
}

const instance: PaymentTransferService = new PaymentTransferService();
export default instance;
