// @flow
import { useEffect } from 'react';

type OnClickOutsideProps = (
  elementRef: React$Reference<any> | null,
  setIsOpen: (boolean) => void,
) => void;

const useOnClickOutside: OnClickOutsideProps = (elementRef: React$Reference<any> | null, setIsOpen: (boolean) => void): void => {
  useEffect((): Function => {
    if (elementRef?.current) {
      const listener = (event: SyntheticEvent<EventTarget>): void => {
        if (!elementRef?.current?.contains(event.target)) setIsOpen(false);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    }
    return () => {};
  }, [elementRef, setIsOpen]);
};

export default useOnClickOutside;
