// @flow
import { useMemo } from 'react';
import { InfoWindow } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import type { LocationGroupClass } from '@shared/Locations/types';

import Help from '_common/components/help/Help';
import CardItem from '@groupClass/components/CardItem';
import IconCircleArrow from '@icons/components/IconCircleArrow';

import useGroupClass from '@groupClass/hooks/useGroupClass';

import { WEB_PATHS } from '@app/constants/paths';

const { GROUP_CLASS } = WEB_PATHS;

type Props = {
  info: LocationGroupClass,
  sameMarkers: LocationGroupClass[],
  indexMarker: number,
  onChange: (marker: LocationGroupClass, indexMarker: number) => void,
  onUnmount: () => void,
};

const MarkerPopup = (props: Props): React$Node => {
  const { info, indexMarker, sameMarkers, onChange, onUnmount } = props;
  const { t, i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const position = { lat: info.lat, lng: info.lng };
  const [groupClass, isLoading, , errors] = useGroupClass(info.id.toString(), language);

  const helpProps = {
    text: t('common.notFound'),
    textColor: 'is-danger',
  };

  const indexBefore = useMemo((): number => (
    (indexMarker - 1) < 0 ? sameMarkers.length - 1: indexMarker - 1
  ), [indexMarker, sameMarkers]);

  const indexAfter = useMemo((): number => (
    indexMarker + 1 === sameMarkers.length ? 0 : indexMarker + 1
  ), [ indexMarker, sameMarkers]);

  return (
    <InfoWindow onUnmount={ onUnmount } onCloseClick={ onUnmount } position={ position }>
      <div className="marker-popup">
        { isLoading ? (
          <p className="marker-popup-loading">{ t('common.loading') }</p>
        ) : errors.length === 0 && groupClass ? (
          <>
            {
              sameMarkers.length > 1 && (!!sameMarkers[indexMarker - 1] || (indexMarker - 1) < 0) && (
                <div className="marker-left-icon position-left">
                  <IconCircleArrow onClick={ () => onChange(sameMarkers[indexBefore], indexBefore) } />
                </div>
              )
            }
            <CardItem
              groupClass={ groupClass }
              onClick={ () => navigate(GROUP_CLASS.replace(':classId', String(groupClass.id))) }
              paymentStatus={ groupClass.paymentStatus }
            />
            {
              (sameMarkers.length > 1 && (!!sameMarkers[indexMarker + 1] || (indexMarker + 1) === sameMarkers.length)) && (
                <div className="marker-right-icon position-right">
                  <IconCircleArrow onClick={ () => onChange(sameMarkers[indexAfter], indexAfter) } className="rotate-180" />
                </div>
              )
            }
          </>
        ) : (
          <Help { ...helpProps } />
        ) }
      </div>
    </InfoWindow>
  );
};

export default MarkerPopup;
