// @flow
import { useMemo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import type { StatusPayment } from '@payment/types';

import {
  PAYMENT_STATUS_NOT_APPLICABLE,
  PAYMENT_STATUS_PENDING,
  PAYMENT_STATUS_TOFINALIZE,
  PAYMENT_STATUS_ACCEPTED,
  PAYMENT_STATUS_OVERPAID,
} from '@payment/constants';

type Props = {
  status: StatusPayment,
  isSubscribed: boolean,
};

const StatusBlock = (props: Props): React$Node => {
  const { t } = useTranslation();
  const { status, isSubscribed } = props;

  const statusBlockClassName = useMemo((): string => clsx({
    'status-block': true,
    'status-block-not-applicable': isSubscribed && status === PAYMENT_STATUS_NOT_APPLICABLE,
    'status-block-pending': isSubscribed && status === PAYMENT_STATUS_PENDING,
    'status-block-to-finalize': isSubscribed && status === PAYMENT_STATUS_TOFINALIZE,
    'status-block-accepted': isSubscribed && status === PAYMENT_STATUS_ACCEPTED,
    'status-block-overpaid': isSubscribed && status === PAYMENT_STATUS_OVERPAID,
    'status-block-unsubscribed': !isSubscribed,
  }), [status, isSubscribed]);

  return (
    <div className={ statusBlockClassName }>
      <p>{ t(`payment.${ status }`) }</p>
    </div>
  );
};

export default StatusBlock;
