// @flow
import type { Listener } from '@core/types';
import type { User } from '@user/types';
import * as coreConstants from '@app/constants/paths';
import * as userConstants from '@user/constants';
import EventEmitter from '_common/services/EventEmitter';
import ApiService from '@api/service';
import TokenService from '@user/services/TokenService';
import i18n from '@app/i18n/i18n';
import { getLanguageFormat } from '_common/services/LanguageUtils';

const { API_PATHS } = coreConstants;
const { DEFAULT_COST_LIST } = userConstants;
const { LOGIN, ME } = API_PATHS;

export type AuthServiceData = {
  isConnected: boolean,
  isLoading: boolean,
  user: User | null,
};

type UpdateValues = (newIsConnected: boolean, newIsLoading: boolean, newUser: User | null) => void;
type AutoLogin = () => Promise<void>;
type Connect = (token: string) => Promise<void>;
type Disconnect = () => void;
type GetCacheUserData = () => Promise<User>;
type Login = (email: string, password: string) => Promise<any>;
type OnChange = (listener: Listener) => Function;
type PurgeCacheUserData = () => void;
type RequestUserData = () => Promise<void>;
type SetCacheUserData = () => void;
type Trigger = () => void;

class AuthService {
  constructor() {
    this._isConnected = false;
    this._isLoading = true;
    this._user = null;
    this.eventEmitter = new EventEmitter();
  }

  _isConnected: boolean;
  _isLoading: boolean;
  _user: User | null;
  eventEmitter: EventEmitter;

  get isConnected(): boolean { return this._isConnected; }
  get isLoading(): boolean { return this._isLoading; }
  get user(): User | null { return this._user; }

  updateValues: UpdateValues = (newIsConnected: boolean, newIsLoading: boolean, newUser: User | null): void => {
    this._isConnected = newIsConnected;
    this._isLoading = newIsLoading;
    this._user = newUser;
    this.#trigger();
  };

  set isConnected(data: boolean ): void{ this._isConnected = data; this.#trigger(); }
  set isLoading(data: boolean ): void{ this._isLoading = data; this.#trigger(); }
  set user(data: User | null ): void{ this._user = data; this.#trigger(); }

  autoLogin: AutoLogin = async (): Promise<void> => {
    const token = TokenService.getToken();
    if (token) await this.connect(token);
    else this.isLoading = false;
  };

  connect: Connect = (token: string): Promise<void> => {
    TokenService.setToken(token);
    return Promise.resolve()
      .then(this.getCacheUserData)
      .then((userData: User) => {
        this.updateValues(true, false, userData);
      })
      .catch(this.requestUserData);
  };

  login: Login = (email: string, password: string): Promise<any> => (
    ApiService.request({
      method: 'post',
      url: LOGIN,
      data: { email, password },
    })
      .then((response) => {
        const { token } = response.data;
        return token
          ? this.connect(token)
          : Promise.reject();
      })
  );

  requestUserData: RequestUserData = (): Promise<void> => (
    ApiService.request({ method: 'get', url: ME })
      .then((payload) => {
        if (payload.status === 200) {
          this.user = {
            ...payload.data,
            preferences: { cost_list: DEFAULT_COST_LIST },
          };
          this.isConnected = true;
          this.isLoading = false;
          i18n.changeLanguage(getLanguageFormat(payload.data.language));
          this.setCacheUserData();
        } else {
          this.disconnect();
        }
      })
      .catch(console.error)
  );

  setCacheUserData: SetCacheUserData = (): void => {
    sessionStorage.setItem('user', JSON.stringify(this._user));
  };

  getCacheUserData: GetCacheUserData = (): Promise<User> => {
    const userData = sessionStorage.getItem('user');
    return userData ? Promise.resolve(JSON.parse(userData)) : Promise.reject();
  };

  purgeCacheUserData: PurgeCacheUserData = (): void => {
    sessionStorage.removeItem('user');
  };

  disconnect: Disconnect = (): void => {
    TokenService.clearToken();
    this._isConnected = false;
    this._user = null;
    this.#trigger();
    this.purgeCacheUserData();
  };

  onChange: OnChange = (listener: Listener): Function => (
    this.eventEmitter.addListener(listener)
  );

  #trigger: Trigger = (): void => {
    this.eventEmitter.trigger({
      user: this._user,
      isConnected: this._isConnected,
      isLoading: this._isLoading,
    });
  };
}

const instance: AuthService = new AuthService();

export default instance;
