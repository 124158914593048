// @flow
import type {
  PaymentCheque,
  PaymentChequePayload,
  PaymentChequeFormData,
  ShortPaymentChequePayload,
  ShortPaymentCheque,
} from '@payment/types';

import type { Option } from '@core/types';

import { hydrateShortDepositFromPayload } from '@payment/helpers/BankDepositUtils';
import { hydratePaymentScheduleFromPayload } from '@payment/helpers/PeriodPaymentUtils';

import { hydratePersonFromPayload } from '@person/helpers/PersonUtils';
import { formatShortGroupClassFromPayload } from '@groupClass/helpers/GroupClassesUtils';

export const hydrateChequeFromPayload = (chequePayload: PaymentChequePayload): PaymentCheque => {
  const person = hydratePersonFromPayload(chequePayload.person);
  const groupClass = formatShortGroupClassFromPayload(chequePayload.groupClass);
  const groupClassRegisteredPaymentSchedule = hydratePaymentScheduleFromPayload(chequePayload.groupClassRegisteredPaymentSchedule);
  const bankDeposit = chequePayload.bankDeposit ? hydrateShortDepositFromPayload(chequePayload.bankDeposit) : null;

  return {
    id: chequePayload.id,
    reference: chequePayload.reference,
    person,
    groupClass,
    groupClassRegisteredPaymentSchedule,
    bankDeposit,
    amount: chequePayload.amount,
  };
};

export const hydrateShortPaymentChequeFromPayload = (chequePayload: ShortPaymentChequePayload): ShortPaymentCheque => {
  const person = hydratePersonFromPayload(chequePayload.person);
  const groupClass = formatShortGroupClassFromPayload(chequePayload.groupClass);
  const groupClassRegisteredPaymentSchedule = hydratePaymentScheduleFromPayload(chequePayload.groupClassRegisteredPaymentSchedule);

  return {
    id: chequePayload.id,
    reference: chequePayload.reference,
    person,
    groupClass,
    groupClassRegisteredPaymentSchedule,
    amount: chequePayload.amount,
  };
};

export const amountFormatter = (amount: ?string): number => (
  'string' == typeof amount ? parseFloat(amount.replace(',', '.').replace(' ', '')) : -1
);

export const emptyPaymentCheque: PaymentChequeFormData = {
  reference: '',
  groupClassRegisteredPaymentSchedule: null,
  amount: null,
};

export const createOptionFromPayload = (cheque: PaymentCheque): Option => ({
  label: cheque.reference,
  value: `${ cheque.id }`,
});
