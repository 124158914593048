// @flow
import { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import Select, { type ActionMeta } from 'react-select';

import type { Colors, Sizes, Option, LabelProps, HelpProps } from '@core/types';
import Field from '_common/components/field/Field';
import Control from '_common/components/control/Control';
import Label from '_common/components/label/Label';
import Help from '_common/components/help/Help';
import AdvancedSelectFieldHelpers from '@shared/AdvancedSelectField/helpers/AdvancedSelectFieldHelpers';

import { errorColor, inputBorderColor, bgColorDisabled, colorDisabled, colorWhite } from '@app/constants/styles';

type Props = {
  asyncOptions?: Promise<Array<Option>>,
  borderColor?: string,
  className?: string,
  color?: Colors,
  controlIsExpanded?: boolean,
  helpProps?: HelpProps,
  isClearable?: boolean,
  isDisabled?: boolean,
  isLoading?: boolean,
  isMulti?: string | boolean,
  label?: React$Node,
  labelProps?: LabelProps,
  menuPlacement?: 'top' | 'auto' | 'bottom',
  menuPortalTarget?: HTMLElement | null,
  name: string,
  options: Array<Option>,
  placeholder?: React$Node,
  required?: boolean,
  selectAll?: string,
  size?: Sizes,
  value: Option | Option[] | null,
  onInputChange?: (newValue: string) => void,
  onChange: (newValue: Option | Option[] | null, actionMeta: ActionMeta<Option>) => void,
};

const AdvancedSelectField = (props: Props): React$Node => {
  const {
    borderColor = inputBorderColor,
    className,
    color,
    controlIsExpanded,
    helpProps,
    isClearable,
    isDisabled,
    isLoading,
    isMulti,
    label,
    labelProps = {},
    menuPlacement,
    menuPortalTarget,
    name,
    onChange,
    onInputChange,
    options,
    placeholder,
    required,
    size,
    selectAll,
    value,
    ...others
  } = props;

  const { className: labelClassName, ...othersLabelProps } = labelProps;

  const customStyles = {
    control: (base) => ({
      ...base,
      border: `1px solid ${ color === 'is-danger' ? errorColor : isDisabled ? bgColorDisabled : borderColor }`,
      backgroundColor: isDisabled ? bgColorDisabled : colorWhite,
      ':hover': {
        borderColor: borderColor,
      },
    }),
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
    singleValue: (provided) => ({
      ...provided,
      color: isDisabled ? colorDisabled : provided.color,
    }),
  };

  const selectAllOption: Option = {
    value: '<SELECT_ALL>',
    label: (isMulti && String(isMulti)) || (selectAll && String(selectAll)) || '',
  };

  const getOptions = useMemo((): Option[] => {
    const optionsArray: Option[] = options ? [...options] : [];
    const isMultiString = isMulti && typeof isMulti === 'string';

    if (isMultiString || selectAll) {
      optionsArray.unshift(selectAllOption);
    }

    return optionsArray;
  }, [isMulti, options, selectAll]);

  const values = useMemo((): Option[] => {
    const defaultValue: Option | Option[] = value ?? [];
    const returnValue: Option[] = Array.isArray(defaultValue) ? defaultValue : [defaultValue];
    return options && isMulti && returnValue.length === options.length
      ? [selectAllOption]
      : returnValue;
  }, [options, value, isMulti]);

  const isOptionSelected = useCallback((option: Option): boolean => (
    !!isMulti
    && (values?.some((element) => element.value === option.value) || (values.includes(selectAllOption)))
  ), [isMulti, values, options]);

  const handleChange = useCallback((optionSelected: Option | Option[], actionMeta: ActionMeta<Option>): void => {
    const advancedSelectFieldHelpers = new AdvancedSelectFieldHelpers(optionSelected, actionMeta, values, options, isMulti);
    const newValue = advancedSelectFieldHelpers.handleUpdateValue();
    onChange(newValue, actionMeta);
  }, [values, options, isMulti, onChange]);

  const advancedSelectFieldLabelClassName = useMemo((): string => clsx({
    'g-label': true,
    'is-flex': true,
    [String(labelClassName)]: !!labelClassName,
  }), [labelClassName]);

  //TODO: make property 'required' works
  return (
    <Field className={ className }>
      { label && (
        <Label
          { ...othersLabelProps }
          size={ size }
          htmlFor={ name }
          className={ advancedSelectFieldLabelClassName }
        >
          { label } { required && '*' }
        </Label>
      ) }
      <Control isExpanded={ controlIsExpanded }>
        <Select
          { ...others }
          name={ name }
          value={ values }
          isLoading={ isLoading }
          isDisabled={ isDisabled }
          isClearable={ isClearable }
          menuPlacement={ menuPlacement }
          placeholder={ placeholder }
          className="g-form-field"
          onChange={ handleChange }
          options={ getOptions }
          isMulti={ isMulti }
          styles={ customStyles }
          onInputChange={ onInputChange }
          //required={required}
          hideSelectedOptions={ false }
          isOptionSelected={ isOptionSelected }
          menuPortalTarget={ menuPortalTarget }
        />
      </Control>
      { helpProps && <Help { ...helpProps } textColor={ color } /> }
    </Field>
  );
};

AdvancedSelectField.defaultProps = {
  options: ([]: Option[]),
  placeholder: '',
  value: null,
  onChange: () => {},
};

export default AdvancedSelectField;
