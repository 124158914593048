// @flow
import clsx from 'clsx';

type Props = {
  className: string,
  onClick: () => void,
};

const IconRotateScreen = (props: Props): React$Node => {
  const { className, onClick } = props;

  const newProps = {
    className: clsx({ icon: true, 'icon-rotate-screen': true, [className]: !!className }),
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '0 0 612 612',
    preserveAspectRatio: 'xMidYMid',
    onClick,
  };

  return (
    <svg { ...newProps }>
      <path d="M419.475 63.75c84.15 38.25 142.8 119.85 153 216.75h38.25C595.425 122.4 465.375 0 304.725 0h-17.85l96.9 96.9 35.7-33.15zm-160.65-20.4c-15.3-15.3-38.25-15.3-53.55 0l-163.2 163.2c-15.3 15.3-15.3 38.25 0 53.55l306 306c15.301 15.301 38.25 15.301 53.551 0l163.199-163.2c15.301-15.301 15.301-38.25 0-53.551l-306-305.999zm117.3 497.25-306-306 163.2-163.2 306 306-163.2 163.2zm-186.15 7.65c-84.15-38.25-142.8-119.85-153-216.75h-35.7c12.75 158.1 142.8 280.5 303.45 280.5h17.85l-96.899-96.9-35.701 33.15z" />
    </svg>
  );
};

IconRotateScreen.defaultProps = {
  className: 'icon-rotate-screen',
  onClick: () => {},
};

export default IconRotateScreen;
