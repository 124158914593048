// @flow
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import ModalYesNo from '@shared/Modal/components/ModalYesNo/ModalYesNo';

import PaymentChequeService from '@payment/services/PaymentChequeService';
import GroupClassPaymentService from '@payment/services/GroupClassPaymentService';

import type { GroupClassDetailed } from '@groupClass/types';

type Props = {
  deleteChequeId: number | null,
  isActive: boolean,
  toggleModal: () => void,
  showSuccessMessage: (message: string) => void,
  groupClass: GroupClassDetailed,
  periodId: number,
};

const ModalDeleteCheque = (props: Props): React$Node => {
  const {
    deleteChequeId,
    isActive,
    toggleModal,
    showSuccessMessage,
    groupClass,
    periodId,
  } = props;

  const { t } = useTranslation();

  const onClickYes = useCallback((): void => {
    if (deleteChequeId) {
      PaymentChequeService.remove(deleteChequeId)
        .then(() => {
          showSuccessMessage('payment.deleteChequeNotification');
          const params = {
            id: groupClass.id,
            periodId: periodId,
          };
          GroupClassPaymentService.fetchChequeBalances(params);
          toggleModal();
        });
    }
  }, [toggleModal]);

  return (
    <ModalYesNo
      isActive={ isActive }
      toggleModal={ toggleModal }
      onClickYes={ onClickYes }
      title={ t('payment.actionDelete') }
    >
      <p className="text-modal-grey">
        { t('payment.deleteDepositSentenceOne') }
      </p>
    </ModalYesNo>
  );
};

export default ModalDeleteCheque;
