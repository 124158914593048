// @flow
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import RolesHelpers from '@user/helpers/RolesHelpers';
import AuthService, { type AuthServiceData } from '@user/services/AuthService';
import SielBleuUserService, { type SielBleuUserServiceData } from '@user/services/SielBleuUserService';
import Loader from '_common/components/loader/Loader';

import type { GroupClassDetailed } from '@groupClass/types';
import type { SielBleuUserDetailed } from '@user/types';
import type { User } from '@user/types';

import { LOADER_TYPE_PAGE } from '_common/components/loader/constants';
import { WEB_PATHS } from '@app/constants/paths';
const { PAGE_NOT_FOUND, LOGIN } = WEB_PATHS;

type Props = {
  children: React$Node,
  groupClass?: GroupClassDetailed | null,
};

const SielBleuUserRoute = (props: Props): React$Node => {
  const {
    children,
    groupClass,
  } = props;

  const navigate = useNavigate();
  const { classId } = useParams();

  const [ currentUser, setCurrentUser ] = useState<User | null>(AuthService.user);
  const [ isUserLoading, setIsUserLoading ] = useState<boolean>(AuthService.isLoading);
  const [ canAccessComponent, setCanAccessComponent ] = useState<boolean>(false);
  const [ sielBleuUser, setSielBleuUser ] = useState<null | SielBleuUserDetailed>(SielBleuUserService.sielBleuUser);

  const handleUpdateAuthService = (data: AuthServiceData) => {
    setCurrentUser(data.user);
    setIsUserLoading(data.isLoading);
  };

  const handleUpdateSielBleuUserService = (data: SielBleuUserServiceData) => {
    setSielBleuUser(data.sielBleuUser);
  };

  useEffect(() => AuthService.onChange(handleUpdateAuthService), []);

  useEffect(() => SielBleuUserService.onChange(handleUpdateSielBleuUserService), []);

  useEffect(() => {
    if (groupClass && !sielBleuUser && currentUser) {
      SielBleuUserService.fetchOne(currentUser.id)
        .catch(() => navigate(PAGE_NOT_FOUND));
    }
  }, [groupClass, sielBleuUser, currentUser]);

  useEffect(() => {
    if (!isUserLoading) {
      if (!currentUser) {
        navigate(LOGIN);
      } else {
        if (!RolesHelpers.isSielBleuUser(currentUser)) {
          navigate(PAGE_NOT_FOUND);
        } else if (!groupClass && RolesHelpers.isSielBleuUser(currentUser)) {
          setCanAccessComponent(true);
        } else if (groupClass && currentUser && sielBleuUser && classId && parseInt(classId,10) === groupClass.id) {
          if (RolesHelpers.isSielBleuUserAllowedOnGroupClass(sielBleuUser, groupClass)) {
            setCanAccessComponent(true);
          } else {
            navigate(PAGE_NOT_FOUND);
          }
        }
      }
    }
  }, [
    isUserLoading,
    classId,
    sielBleuUser,
    currentUser,
    groupClass,
    setCanAccessComponent,
  ]);

  return (
    <>
      { canAccessComponent
        ? children
        : <Loader loaderType={ LOADER_TYPE_PAGE } />
      }
    </>
  );
};

export default SielBleuUserRoute;
