// @flow
import { useTranslation } from 'react-i18next';

import withGroupClass from '@hoc/withGroupClass';

import ParticipantsLayout from '@layout/components/ParticipantsLayout';

import type { GroupClassDetailed } from '@groupClass/types';

type Props = {
  groupClass: GroupClassDetailed | null,
};

export const Participants = (props: Props): React$Node => {
  const { groupClass } = props;
  const { t } = useTranslation();

  return (
    <ParticipantsLayout groupClass={ groupClass }>
      <h1>{ t('groupClasses.groupClass.peoples.title') }</h1>
      <ul className="group-class-peoples-menu">
        <li>{ t('groupClasses.groupClass.peoples.editCard') }</li>
        <li>{ t('groupClasses.groupClass.peoples.contactMail') }</li>
        <li>{ t('groupClasses.groupClass.peoples.contactSms') }</li>
        <li>{ t('groupClasses.groupClass.peoples.sendSession') }</li>
      </ul>
    </ParticipantsLayout>
  );
};

export default withGroupClass(Participants);
