// @flow
import EventEmitter from '_common/services/EventEmitter';
import ApiService from '@api/service';

import { hydrateGroupClassesPeriodArchiveFromPayload } from '@groupClass/helpers/GroupClassesPeriodArchiveUtils';

import type { GroupClassesPeriodArchive } from '@groupClass/types';
import type { Error, Listener, QueryParams } from '@core/types';

import { API_PATHS } from '@app/constants/paths';
const { GROUP_CLASS_PERIODS_ARCHIVE } = API_PATHS;

const FETCH_ALL_DEFAULT_PARAMS = {
  page: 1,
  itemsPerPage: 5,
  'order[archivedAt]': 'desc',
};

export type GroupClassesPeriodArchiveServiceData = {
  groupClassesPeriodArchive: GroupClassesPeriodArchive,
  groupClassesPeriodArchives: GroupClassesPeriodArchive[],
  loading: boolean,
  errors: Error[],
};

type OnChange = (listener: Listener) => Function;
type FetchOne = (groupClassesPeriodArchiveId: number) => Promise<GroupClassesPeriodArchive>;
type FetchAll = (params?: QueryParams) => Promise<GroupClassesPeriodArchive[]>;
type Create = () => Promise<boolean>;
type Reset = () => void;
type Trigger = () => void;

type UpdateValues = (
  newGroupClassesPeriodArchive: GroupClassesPeriodArchive | null,
  newGroupClassesPeriodArchives: GroupClassesPeriodArchive[],
  newLoading: boolean,
  newErrors: Error[],
) => void;

let sourceAll = ApiService.createToken();

class GroupClassesPeriodArchiveService {
  constructor() {
    this.eventEmitter = new EventEmitter();
    this.groupClassesPeriodArchive = null;
    this.groupClassesPeriodArchives = [];
  }

  eventEmitter: EventEmitter;
  groupClassesPeriodArchive: GroupClassesPeriodArchive | null;
  groupClassesPeriodArchives: GroupClassesPeriodArchive[];
  loading: boolean;
  errors: Error[];

  updateValues: UpdateValues = (
    newGroupClassesPeriodArchive: GroupClassesPeriodArchive | null,
    newGroupClassesPeriodArchives: GroupClassesPeriodArchive[],
    newLoading: boolean,
    newErrors: Error[],
  ): void => {
    this.groupClassesPeriodArchive = newGroupClassesPeriodArchive;
    this.groupClassesPeriodArchives = newGroupClassesPeriodArchives;
    this.loading = newLoading;
    this.errors = newErrors;
    this.#trigger();
  };

  fetchAll: FetchAll = (customParams?: QueryParams = FETCH_ALL_DEFAULT_PARAMS): Promise<GroupClassesPeriodArchive[]> => {
    sourceAll.cancel();
    sourceAll = ApiService.createToken();

    this.updateValues(this.groupClassesPeriodArchive, [], true, this.errors);
    return ApiService.request({
      method: 'get',
      url: `${ GROUP_CLASS_PERIODS_ARCHIVE }`,
      params: customParams,
      cancelToken: sourceAll.token,
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;
          const newGroupClassesPeriodArchives = data['hydra:member'].map((payload) => hydrateGroupClassesPeriodArchiveFromPayload(payload));
          this.updateValues(this.groupClassesPeriodArchive, newGroupClassesPeriodArchives, false, this.errors);
          return newGroupClassesPeriodArchives;
        }
        return Promise.reject();
      })
      .catch(() => Promise.reject());
  };

  fetchOne: FetchOne = (groupClassesPeriodArchiveId: number): Promise<GroupClassesPeriodArchive> => {
    this.updateValues(null, this.groupClassesPeriodArchives, true, this.errors);

    return ApiService.request({
      method: 'get',
      url: `${ GROUP_CLASS_PERIODS_ARCHIVE }/${ groupClassesPeriodArchiveId }`,
    })
      .then((payload) => {
        if (payload.data) {
          const newGroupClassesPeriodArchive = hydrateGroupClassesPeriodArchiveFromPayload(payload.data);
          this.updateValues(newGroupClassesPeriodArchive, this.groupClassesPeriodArchives, false, this.errors);
          return newGroupClassesPeriodArchive;
        }
        return Promise.reject();
      })
      .catch(() => Promise.reject());
  };

  create: Create = (): Promise<boolean> => {
    this.updateValues(this.groupClassesPeriodArchive, this.groupClassesPeriodArchives, true, this.errors);

    return ApiService.request({
      method: 'post',
      url: `${ GROUP_CLASS_PERIODS_ARCHIVE }`,
    })
      .then((payload) => {
        if (payload.data) {
          return true;
        }
        return Promise.reject();
      })
      .catch(() => Promise.reject());
  };

  reset: Reset = (): void => {
    this.updateValues(null, [], false, []);
  };

  onChange: OnChange = (listener: Listener): Function => {
    const listenerFunction = this.eventEmitter.addListener(listener);
    this.#trigger();
    return listenerFunction;
  };

  /**
   * @private
   */
  #trigger: Trigger = (): void => {
    this.eventEmitter.trigger({
      groupClassesPeriodArchive: this.groupClassesPeriodArchive,
      groupClassesPeriodArchives: this.groupClassesPeriodArchives,
      loading: this.loading,
      errors: this.errors,
    });
  };
}

const instance: GroupClassesPeriodArchiveService = new GroupClassesPeriodArchiveService();
export default instance;
