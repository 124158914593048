// @flow
import clsx from 'clsx';

import { useMemo } from 'react';

type Props = {
  children: React$Node,
  isHorizontal?: boolean,
  className?: string,
};

const Field = (props: Props): React$Node => {
  const {
    children,
    isHorizontal,
    className,
  } = props;

  const fieldClassName = useMemo((): string => clsx({
    'field': true,
    'is-horizontal': !!isHorizontal,
    [String(className)]: !!className,
  }), [className, isHorizontal]);

  return (
    <div className={ fieldClassName }>
      { children }
    </div>
  );
};

export default Field;
