// @flow
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DateHelpers from '@helpers/DateHelpers';

type Props = {
  date: moment$Moment | string,
  format: string,
  isDisabled: boolean,
  isError: boolean,
  isOptional: boolean,
  label: string,
  name: string,
  placeholder: string,
  onChange: (date: moment$Moment | string) => void,
  onFocus: () => void,
  onKeyDown: (target: KeyboardEvent) => void,
};

/**
 * getCorrectDateString
 * Helper used to convert correctly the date receive has parameters
 * @param {moment$Moment | string} date
 * @param {string} format
 * @returns {string}
 */
const getCorrectDateString = (date: moment$Moment | string, format: string): string => (
  date && DateHelpers.parseDate(date, format).isValid()
    ? DateHelpers.formatDate(date, format)
    : String(date)
);

const InputDateTime = (props: Props): React$Node => {
  const { t, i18n: { language } } = useTranslation();
  const {
    date,
    format,
    isDisabled,
    isOptional,
    label,
    name,
    placeholder,
    onChange,
    onFocus,
    onKeyDown,
  } = props;

  const [currentDate, setCurrentDate] = useState<string>(getCorrectDateString(date, format));

  /**
   * Effect used to update the local date of the component from the date received in props
   */
  useEffect(() => {
    const correctDate = getCorrectDateString(date, format);
    setCurrentDate(correctDate);
  }, [date, format]);

  /**
   * handleChange
   * Helpers used to update the local date from the data wrote by the user
   * @param {moment$Moment} date
   * @returns {void}
   */
  const handleChange = useCallback((event: SyntheticEvent<HTMLInputElement>): void => {
    let { value } = event.currentTarget;
    if (value.length === 3 && !value.endsWith('/')) {
      value = `${ value.substring(0, 2) }/${ value.substring(2) }`;
    } else if (value.length === 6 && !value.endsWith('/')) {
      value = `${ value.substring(0, 5) }/${ value.substring(5) }`;
    }
    setCurrentDate(value);
  }, []);

  /**
   * handleBlur
   * Helpers used to update the global component to update the internal value of the date time picker.
   * @returns {void}
   */
  const handleBlur = useCallback((): void => {
    if (currentDate) {
      const newDate = DateHelpers.parseDate(currentDate, format, language);
      onChange(newDate);
    } else {
      onChange('');
    }
  }, [onChange, currentDate, format]);

  const inputLabelClassName = 'input-text input-date-picker';

  const inputLabel = useMemo((): string => (
    isOptional
      ? `${ label } (${ t('field.optional'), language })`
      : label
  ), [isOptional, label, language, t]);

  const inputPlaceholder = placeholder || label;
  const inputName = name || label;

  return (
    <div className="field-input-datetime">
      <div className="field-border">
        <label className="field-label" htmlFor={ inputName }>{ inputLabel }</label>

        { isDisabled && (
          <span className={ inputLabelClassName }>{ currentDate || inputLabel }</span>
        ) }

        { !isDisabled && (
          <input
            autoComplete="off"
            className={ inputLabelClassName }
            name={ inputName }
            onBlur={ handleBlur }
            onChange={ handleChange }
            onClick={ onFocus }
            pattern="[0-9]{2}/[0-9]{2}/[0-9]{4}"
            placeholder={ inputPlaceholder }
            role="presentation"
            type="text"
            value={ currentDate }
            onKeyDown= { onKeyDown }
          />
        ) }
      </div>
    </div>
  );
};

InputDateTime.defaultProps = {
  format: 'DD/MM/YYYY',
  isDisabled: false,
  isError: false,
  isOptional: false,
  name: 'input-date-time',
  onChange: () => {},
  onFocus: () => {},
};

export default InputDateTime;
