// @flow
import { useMemo } from 'react';
import clsx from 'clsx';

import IconClose from '@icons/components/IconClose';

type Props = {
  children: React$Node,
  isOpen: boolean,
  onClick: () => void,
};

const Menu = (props: Props): React$Node => {
  const { children, onClick, isOpen } = props;

  const menuClassName = useMemo((): string => clsx({
    'menu-container': true,
    'is-active': isOpen,
    'is-not-active': !isOpen,
  }), [isOpen]);

  return (
    <div className={ menuClassName }>
      <IconClose onClick={ onClick } />
      <ul className="menu-list">{ children }</ul>
    </div>
  );
};

export default Menu;
