// @flow
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Button, { buttonConstants } from '@shared/Button';

import Checkbox from '@shared/Checkbox/component/Checkbox';
import AdvancedSelectField from '@shared/AdvancedSelectField';
import SearchField from '@search/components/SearchField';
import DateHelpers from '@helpers/DateHelpers';
import InputField from '_common/components/input-field/InputField';

import useActivities from '@activity/hooks/useActivities';

import type { SearchCity } from '@shared/Locations/types';
import type { Option } from '@core/types';

type Props = {
  isAdmin: boolean,
  isCoach: boolean,
  isManager: boolean,
  myClassesOnly: boolean,
  mySectorGroupClasses: boolean,
  activity: Option | null,
  caseNumber: string | null,
  day: Option | null,
  groupClassKey: string | null,
  value: SearchCity | null,
  locale: string,
  onSearch: (value: SearchCity | null) => void,
  onChangeAddress: (event: SyntheticEvent<HTMLInputElement>) => void,
  onChangeCaseNumber: (event: SyntheticEvent<HTMLInputElement>) => void,
  onChangeGroupClassKey: (event: SyntheticEvent<HTMLInputElement>) => void,
  onChangeDay: () => void,
  setMyClassesOnly: (value: boolean) => void,
  setMySectorGroupClasses: (value: boolean) => void,
  onChangeActivity: () => void,
  onSubmit: (event: SyntheticEvent<EventTarget>) => void,
};

const SearchBanner = (props: Props): React$Node => {
  const {
    onSearch,
    isAdmin,
    isCoach,
    isManager,
    myClassesOnly,
    mySectorGroupClasses,
    onChangeAddress,
    onChangeCaseNumber,
    onChangeGroupClassKey,
    setMyClassesOnly,
    setMySectorGroupClasses,
    value,
    locale,
    activity,
    onChangeActivity,
    onChangeDay,
    day,
    groupClassKey,
    caseNumber,
    onSubmit,
  } = props;

  const { t, i18n: { language } } = useTranslation();
  const [ activities, isLoading ] = useActivities(language);

  const handleMySectorCheckbox = useCallback((): void => {
    setMySectorGroupClasses(!mySectorGroupClasses);
    if (isManager) {
      setMyClassesOnly(mySectorGroupClasses);
    }
  }, [mySectorGroupClasses, isManager]);

  const handleClassesOnly = useCallback((): void => {
    setMyClassesOnly(!myClassesOnly);
    if (isManager) {
      setMySectorGroupClasses(myClassesOnly);
    }
  }, [myClassesOnly, isManager]);

  const activityOptions = useMemo((): Option[] => (
    activities?.map((activity) => ({
      label: activity.name,
      value: activity.id.toString(),
    })) || []
  ), [activities]);

  const daysOptions = DateHelpers.getDaysOfWeekOptions(language);

  return (
    <>
      { !isAdmin && !isCoach && (
        <div className="search-banner">
          <Checkbox
            className="search-banner-checkbox"
            checked={ mySectorGroupClasses }
            onChange={ handleMySectorCheckbox }
            name="mySectorGroupClasses"
          >
            { t('groupClasses.mySectorGroupClasses') }
          </Checkbox>
          <Checkbox
            className="search-banner-checkbox"
            checked={ myClassesOnly }
            onChange={ handleClassesOnly }
            name="myClassesOnly"
          >
            { t('groupClasses.myClassesOnly') }
          </Checkbox>
        </div>
      ) }
      <div className="search-banner-search search-container">
        <SearchField locale={ locale } value={ value } onChange={ onChangeAddress } isAdmin={ isAdmin } onSearch={ onSearch } />
        <AdvancedSelectField
          name="activity"
          placeholder={ t('groupClasses.activities') }
          options={ activityOptions }
          isClearable
          className="search-field-input"
          value={ activity }
          onChange={ onChangeActivity }
          selectAll={ t('common.allFem') }
          isDisabled={ isLoading }
          isSearchable={ false }
        />
        <AdvancedSelectField
          name="day"
          options={ daysOptions }
          placeholder={ t('groupClasses.groupClass.detailed.day') }
          isClearable
          value={ day }
          className="search-field-input"
          onChange={ onChangeDay }
          selectAll={ t('common.allMasc') }
          isSearchable={ false }
        />
        <InputField
          name="groupClassKey"
          value={ groupClassKey }
          onChange={ onChangeGroupClassKey }
          className="search-field-input"
          placeholder={ t('groupClasses.groupClass.detailed.groupClassKey') }
        />
        <InputField
          name="caseNumber"
          value={ caseNumber }
          onChange={ onChangeCaseNumber }
          className="search-field-input"
          placeholder={ t('groupClasses.groupClass.detailed.caseNumber') }
        />
        <Button type={ buttonConstants.PRIMARY } onClick={ onSubmit }>
          { t('common.search') }
        </Button>
      </div>
    </>
  );
};

export default SearchBanner;
