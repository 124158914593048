export const VIEW_MODE_DAY = 'day';
export const VIEW_MODE_MONTH = 'month';
export const VIEW_MODE_TIME = 'time';
export const VIEW_MODE_YEAR = 'year';

export const DEFAULT_TIME_STEP = 15;
export const DEFAULT_STEP_RULES = {
  minutes: { step: DEFAULT_TIME_STEP },
};

export const CALENDAR_BEGINNING = {
  en: 0,
  fr: 1,
  es: 1,
};
