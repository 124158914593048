// @flow
import { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';

import withGroupClass from '@hoc/withGroupClass';

import AdminLayout from '@layout/components/AdminLayout';

import Toast from '_common/services/Toast/Toast';
import Tabs from '@shared/Navigation/Tabs';
import Button, { buttonConstants } from '@shared/Button';
import NavigationPills from '@shared/Navigation/NavigationPills';
import PeriodSelector from '@payment/components/PeriodSelector';
import ModalValidatePeriod from '@payment/components/ModalValidatePeriod';
import ChequeDetailed from '@payment/components/ChequeDetailed';
import CreditCardDetailed from '@payment/components/CreditCardDetailed';
import Loader from '_common/components/loader/Loader';
import IconCircleXMark from '@icons/components/IconCircleXMark';

import GroupClassService from '@groupClass/services/GroupClassService';
import AuthService, { type AuthServiceData } from '@user/services/AuthService';
import GroupClassPeriodService, { type GroupClassPeriodServiceData } from '@groupClass/services/GroupClassPeriodService';
import RolesHelpers from '@user/helpers/RolesHelpers';
import { findTheNearestPeriod } from '@payment/helpers/PeriodPaymentUtils';

import type { GroupClassDetailed } from '@groupClass/types';
import type { PeriodItemOutput, Period } from '@groupClass/types';
import type { User } from '@user/types';
import type { Error } from '@core/types';
import type { Tab } from '@shared/Navigation/TabElement/types';

import { getPaymentNavigationTabs, getPaymentDetailedTab, TAB_CHEQUES, TAB_CREDIT_CARD, DETAILED_SELECTED } from '@payment/constants';
import { LOADER_TYPE_BACKGROUND } from '_common/components/loader/constants';
import { NEXT_PERIOD_NOT_VALIDED } from '@helpers/TranslationHelpers';
import { ADMIN_HEADER_PAYMENTS } from '@layout/constants';
import { WEB_PATHS } from '@app/constants/paths';
const { PAYMENTS_CREATE_CHEQUE } = WEB_PATHS;

type useParamsType = {
  paymentType: string,
};

type Props = {
  groupClass: GroupClassDetailed | null,
};

const PaymentDetailed = (props: Props): React$Node => {
  const { groupClass } = props;
  const { t, i18n: { language } } = useTranslation();
  const { paymentType } = useParams<useParamsType>();

  const activeTabFromParams = useMemo((): string => {
    if (paymentType === TAB_CHEQUES) {
      return TAB_CHEQUES;
    }
    return TAB_CREDIT_CARD;
  }, [paymentType]);

  const [ currentUser, setCurrentUser ] = useState<User | null>(AuthService.user);
  const [ period, setPeriod ] = useState<PeriodItemOutput | null>(GroupClassPeriodService.period);
  const [ isLoadingPeriod, setIsLoadingPeriod ] = useState<boolean>(GroupClassPeriodService.loading);
  const [ isActiveModalValidatePeriod, setIsActiveModalValidatePeriod ] = useState<boolean>(false);
  const [ errors, setErrors ] = useState<Error[]>(GroupClassPeriodService.errors);
  const [ activeTab, setActiveTab ] = useState<string>(activeTabFromParams);

  const periods = useMemo((): Period[] => (
    (groupClass?.periods ?? [])
      .slice(0)
      .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
  ), [groupClass]);

  const handleUpdateAuthService = (data: AuthServiceData) => {
    setCurrentUser(data.user);
  };

  const handleUpdatePeriodService = (data: GroupClassPeriodServiceData): void => {
    setPeriod(data.period);
    setIsLoadingPeriod(data.loading);
    setErrors(data.errors);
  };

  useEffect(() => AuthService.onChange(handleUpdateAuthService), []);
  useEffect(() => GroupClassPeriodService.onChange(handleUpdatePeriodService), []);

  useEffect((): void => {
    const nearestPeriod = findTheNearestPeriod(periods);
    if (nearestPeriod) {
      GroupClassPeriodService.fetchOne(nearestPeriod.id);
    }
  }, [periods]);

  const isAdmin = useMemo((): boolean => (
    RolesHelpers.isAdmin(currentUser)
  ), [currentUser]);

  const toggleModalValidatePeriod = useCallback((): void => {
    GroupClassPeriodService.resetErrors();
    setIsActiveModalValidatePeriod(!isActiveModalValidatePeriod);
  }, [isActiveModalValidatePeriod, setIsActiveModalValidatePeriod]);

  const showSuccessMessage = useCallback((message: string): void => {
    Toast.success(t(message));
  }, [t]);

  const tabs = useMemo((): Tab[] => (
    groupClass ? getPaymentDetailedTab(groupClass.id) : []
  ), [groupClass]);

  const indexOfPeriod = useMemo((): number => (
    periods.findIndex((element) => period?.id === element.id)
  ), [period]);

  const handlePeriod = useCallback((type: string): void => {
    if (type === 'next') {
      GroupClassPeriodService.fetchOne(periods[indexOfPeriod + 1].id);
    } else if (type === 'previous') {
      GroupClassPeriodService.fetchOne(periods[indexOfPeriod - 1].id);
    }
  }, [indexOfPeriod, periods]);

  const isPeriodArchived = useMemo((): boolean => (
    period?.archivedAt !== null
  ), [period]);

  const isAdminAndPeriodValidated = useMemo((): boolean => {
    if (period && !isPeriodArchived) {
      return period.validatedAt !== null && isAdmin;
    }
    return false;
  }, [period, isAdmin, isPeriodArchived]);

  const isPeriodValidatedAndNotAdmin = useMemo((): boolean => {
    if (period && !isPeriodArchived) {
      return period.validatedAt !== null && !isAdmin;
    }
    return false;
  }, [period, isAdmin, isPeriodArchived]);

  const isPeriodUnvalidated = useMemo((): boolean => {
    if (period && !isPeriodArchived) {
      return period.validatedAt === null;
    }
    return false;
  }, [period, isPeriodArchived]);

  const unValidatePeriod = useCallback((): void => {
    if (groupClass && period && !isLoadingPeriod) {
      GroupClassPeriodService.updatePeriod(period.id, null)
        .then(() => {
          showSuccessMessage('groupClasses.groupClass.peoples.form.wellUpdated');
          GroupClassPeriodService.fetchOne(period.id);
          //Fetch GroupClass to update groupClass Info
          GroupClassService.fetchGroupClassById(String(groupClass.id), language);
        });
    }
  }, [period, isLoadingPeriod, showSuccessMessage]);

  const isNextPeriodValidationError = useMemo((): boolean => (
    errors?.[0]?.code === NEXT_PERIOD_NOT_VALIDED
  ), [errors]);

  return (
    <AdminLayout activeTab={ ADMIN_HEADER_PAYMENTS } groupClass={ groupClass } preventPortraitMode>
      <div className="payment-container payment-detailed container">
        <div className="payment-container-header payment-detailed-header">
          { groupClass && (
            <>
              <NavigationPills navigationTabs={ getPaymentNavigationTabs(groupClass.id) } isSelectedKey={ DETAILED_SELECTED } />
              <Link
                to={ PAYMENTS_CREATE_CHEQUE.replace(':classId', String(groupClass.id)) }
                className="button button-secondary"
              >
                { t('payment.addCheque') }
              </Link>
            </>
          ) }
        </div>
        { isLoadingPeriod ? (
          <Loader loaderType={ LOADER_TYPE_BACKGROUND } />
        ) : (
          <div className="table-payment-container">
            <PeriodSelector periods={ periods } period={ period } indexOfPeriod={ indexOfPeriod } handlePeriod={ handlePeriod } />
            <Tabs
              tabs={ tabs }
              activeTab={ activeTab }
              onClick={ (tab) => setActiveTab(tab.name) }
            />
            { activeTab === TAB_CHEQUES && period && groupClass && (
              <ChequeDetailed groupClass={ groupClass } period={ period } />
            ) }
            { activeTab === TAB_CREDIT_CARD && period && groupClass && (
              <CreditCardDetailed groupClass={ groupClass } period={ period } />
            ) }
          </div>
        ) }
        { !isLoadingPeriod && period && (
          <div className="button-validate" >
            { isPeriodUnvalidated && (
              <Button onClick={ toggleModalValidatePeriod } type={ buttonConstants.PRIMARY }>
                { t('payment.validatePeriod') }
              </Button>
            ) }
            { isAdminAndPeriodValidated && (
              <Button onClick={ unValidatePeriod } type={ buttonConstants.PRIMARY }>
                { t('payment.validatedPeriod') }
              </Button>
            ) }
            { isPeriodValidatedAndNotAdmin && (
              <Button onClick={ toggleModalValidatePeriod } type={ buttonConstants.PRIMARY } isDisabled>
                { t('payment.validatedPeriod') }
              </Button>
            ) }
            { isPeriodArchived && (
              <Button type={ buttonConstants.PRIMARY } isDisabled>
                { t('payment.periodArchived') }
              </Button>
            ) }
          </div>
        ) }
        { isNextPeriodValidationError && (
          <div className="custom-error-container">
            <div className="custom-error-message">
              <IconCircleXMark />
              { t('payment.nextPeriodValidationError') }
            </div>
          </div>
        ) }
      </div>
      { groupClass && period && (
        <ModalValidatePeriod
          isActive={ isActiveModalValidatePeriod }
          toggleModal={ toggleModalValidatePeriod }
          showSuccessMessage={ showSuccessMessage }
          periodId={ period.id }
          groupClass={ groupClass }
        />
      ) }
    </AdminLayout>
  );
};

export default withGroupClass(PaymentDetailed);
