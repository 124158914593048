// @flow
import { useCallback, useMemo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment/dist/moment';

import withGroupClass from '@hoc/withGroupClass';

import AdminLayout from '@layout/components/AdminLayout';

import Button, { buttonConstants } from '@shared/Button';
import DescriptionContainer from '_common/components/description-container/DescriptionContainer';
import LabelInformation from '_common/components/label-information/LabelInformation';
import CoachTable from '@coach/components/CoachTable';
import PeriodTable from '@period/components/PeriodTable';
import Modal from '@shared/Modal/Modal';
import Toast from '_common/services/Toast/Toast';
import IconCircleXMark from '@icons/components/IconCircleXMark';

import GroupClassService from '@groupClass/services/GroupClassService';
import { formatAddressToString } from '@shared/Locations/services/AddressUtils';

import type { GroupClassDetailed as GroupClassDetailedType } from '@groupClass/types';

import { ADMIN_HEADER_INFORMATION } from '@layout/constants';
import { WEB_PATHS } from '@app/constants/paths';
const { GROUP_CLASS_EDIT } = WEB_PATHS;

type Props = {
  groupClass: GroupClassDetailedType | null,
};

export const GroupClassDetailed = (props: Props): React$Node | null => {
  const { groupClass: initialGroupClass } = props;
  const { t, i18n: { language } } = useTranslation();

  const [ groupClass, setGroupClass ] = useState<GroupClassDetailedType | null>(initialGroupClass);
  const [ isActiveModal, setIsActiveModal ] = useState<boolean>(false);
  const [ showSeasonClosingError, setShowSeasonClosingError ] = useState<boolean>(false);

  const {
    groupClassKey,
    caseNumber,
    seasonKey,
    type,
    address,
    description,
    coaches,
    periods,
    registrationTerms,
    registrationContact,
    startDate,
    endDate,
    exceptDates,
  } = groupClass || {};

  useEffect(() => {
    if ((!groupClass && initialGroupClass) || (groupClass?.id !== initialGroupClass?.id)) {
      setGroupClass(initialGroupClass);
    }
  }, [initialGroupClass, setGroupClass]);

  const { complementNumber } = address || {};

  const addressFormatted = useMemo((): string => (
    address ? formatAddressToString(address) : ''
  ), [address]);

  const startDateFormatted = useMemo((): string => (
    startDate ? moment(startDate).locale(language).format('L') : ''
  ), [startDate, language]);

  const endDateFormatted = useMemo((): string => (
    endDate ? moment(endDate).locale(language).format('L') : ''
  ), [endDate, language]);

  const exceptDateFormatted = useMemo((): string => (
    exceptDates || t('groupClasses.groupClass.detailed.exceptDatesDefault')
  ), [exceptDates, language]);

  const registrationTermsFormatted = useMemo((): string => (
    registrationTerms
  ), [registrationTerms]);

  const groupClassKeyValue = useMemo((): string => (
    groupClassKey || ''
  ), [groupClassKey]);

  const caseNumberValue = useMemo((): string => (
    caseNumber || ''
  ), [caseNumber]);

  const toggleModal = useCallback((): void => {
    setIsActiveModal(!isActiveModal);
    setShowSeasonClosingError(false);
  }, [isActiveModal, setIsActiveModal, setShowSeasonClosingError]);

  const isSeasonCloseable = useMemo((): boolean => {
    if (groupClass) {
      return groupClass.periods.filter((period) => {
        const today = moment();
        const endDate = moment(period.endDate);
        return endDate.utc().isSameOrAfter(today, 'day');
      }).length === 0;
    }
    return false;
  }, [groupClass]);

  const handleClosedSeason = useCallback((e: SyntheticEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (groupClass) {
      if (isSeasonCloseable) {
        GroupClassService.archiveGroupClass(groupClass, moment().format('YYYY-MM-DD'))
          .then(() => Toast.success(t('groupClasses.groupClass.peoples.form.wellUpdated')))
          .then(toggleModal)
          .then(() => GroupClassService.fetchGroupClassById(String(groupClass.id), language))
          .then(setGroupClass)
          .catch(() => {
            Toast.error(t('groupClasses.groupClass.detailed.errorSeasonClosed'));
            toggleModal();
          });
      } else {
        setShowSeasonClosingError(true);
      }
    }
  }, [groupClass, isSeasonCloseable, language, toggleModal, setGroupClass, setShowSeasonClosingError]);

  const footerModal: React$Node = useMemo((): React$Node => (
    <footer className="modal-footer">
      <Button
        type={ buttonConstants.TERTIARY }
        onClick={ toggleModal }
        isOutlined
      >
        { t('common.no') }
      </Button>

      <Button
        type={ buttonConstants.SECONDARY }
        className="button is-success confirm"
        onClick={ handleClosedSeason }
      >
        { t('common.yes') }
      </Button>
    </footer>
  ), [toggleModal, handleClosedSeason, language]);

  const isSeasonClosed = useMemo((): boolean => (
    !!groupClass?.seasonClosedAt
  ), [groupClass]);

  if (!groupClass) {
    return null;
  }

  return (
    <AdminLayout activeTab={ ADMIN_HEADER_INFORMATION } groupClass={ groupClass } preventPortraitMode>
      <div className="container-group-class-detailed container">
        <div className="group-class-detailed">
          <div className="group-class-block-title group-class-block-title-detailed">
            <div className="group-class-title">
              <h1 className="group-class-detailed-title">{ t('groupClasses.groupClass.navigation.infos') }</h1>
            </div>
            <div className="button-container">
              <Link
                to={ GROUP_CLASS_EDIT.replace(':classId', String(groupClass.id)) }
                className="button button-primary edit-button"
              >
                { t('payment.actionEdit') }
              </Link>
              <Button type={ buttonConstants.SECONDARY } onClick={ toggleModal } isDisabled={ isSeasonClosed } >
                { t('groupClasses.groupClass.detailed.buttonEndSeason') }
              </Button>
            </div>
          </div>
          <div className="group-class-container-white">
            <div className="group-class-detailed-show">
              <div className="columns">
                <div className="column is-12">
                  <div className="group-class-detailed-show-general">
                    <div className="columns is-flex-wrap-wrap">
                      <div className="column is-12">
                        <h2 className="group-class-detailed-show-title">{ t('groupClasses.groupClass.detailed.general') }</h2>
                      </div>
                      <div className="column is-6-desktop is-12-tablet p-0">
                        <div className="columns is-flex-wrap-wrap">
                          <LabelInformation
                            title={ t('groupClasses.groupClass.detailed.groupClassKey') }
                            value={ groupClassKeyValue }
                            required
                          />

                          <LabelInformation
                            title={ t('groupClasses.groupClass.detailed.caseNumber') }
                            value={ caseNumberValue }
                            required
                          />

                          <LabelInformation
                            title={ t('groupClasses.groupClass.detailed.address.address') }
                            value={ addressFormatted }
                            required
                          />
                        </div>
                      </div>
                      <div className="column is-6-desktop p-0">
                        <div className="columns is-flex-wrap-wrap">
                          <LabelInformation
                            title={ t('groupClasses.groupClass.detailed.seasonKey') }
                            value={ seasonKey }
                            required
                          />

                          <LabelInformation
                            title={ t('groupClasses.groupClass.detailed.classType') }
                            value={ type ? t(`groupClasses.groupClass.type.${ type }`) : '' }
                            classNameValue="class-type"
                            required
                          />

                          <LabelInformation
                            title={ t('groupClasses.groupClass.detailed.complementNumber') }
                            value={ complementNumber || '' }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column is-12">
                      <DescriptionContainer value={ description } className="show-description" required />
                    </div>
                  </div>
                  <div className="group-class-detailed-show-coach">
                    <div className="columns is-flex-wrap-wrap">
                      <div className="column is-12">
                        <h2 className="group-class-detailed-show-title">{ t('groupClasses.groupClass.detailed.coach') }</h2>
                      </div>
                      <CoachTable coaches={ coaches } />
                    </div>
                  </div>
                  <div className="group-class-detailed-show-dates">
                    <div className="columns is-flex-wrap-wrap">
                      <div className="column is-12">
                        <h2 className="group-class-detailed-show-title">{ t('payment.periodAndPrice') }</h2>
                      </div>
                      <div className="column is-12 p-0 period-table-columns">
                        { periods && periods.length > 0 && (
                          <PeriodTable periods={ periods } />
                        ) }
                      </div>
                      <div className="column is-12 px-0 period-table-columns">
                        <div className="show-dates">
                          <div className="columns is-flex-wrap-wrap">
                            <div className="column is-6-desktop p-0">
                              <div className="columns">
                                <LabelInformation
                                  title={ t('groupClasses.groupClass.detailed.startSeason') }
                                  value={ startDateFormatted }
                                  className="show-start-date"
                                  required
                                />
                              </div>
                            </div>
                            <div className="column is-6-desktop p-0">
                              <div className="columns">
                                <LabelInformation
                                  title={ t('groupClasses.groupClass.detailed.endSeason') }
                                  value={ endDateFormatted }
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="columns is-flex-wrap-wrap">
                            <div className="column is-6-desktop p-0">
                              <div className="columns">
                                { exceptDates && (
                                  <LabelInformation
                                    title={ t('groupClasses.groupClass.detailed.exceptDate') }
                                    value={ exceptDateFormatted }
                                    className="show-label-full-block"
                                    classNameTitle="show-title-full-block"
                                    classNameValue="show-value-full-block"
                                  />
                                ) }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="group-class-detailed-show-registration">
                    <div className="columns is-flex-wrap-wrap">
                      <div className="column is-12">
                        <h2 className="group-class-detailed-show-title">{ t('groupClasses.groupClass.detailed.registration') }</h2>
                      </div>
                      <div className="column is-12 p-0">
                        <div className="columns is-flex-wrap-wrap">
                          <div className="column is-6-desktop p-0">
                            <div className="columns">
                              <LabelInformation
                                title={ t('groupClasses.groupClass.detailed.registrationTerm') }
                                value={ registrationTermsFormatted }
                                className="show-label-full-block"
                                classNameTitle="show-title-full-block"
                                classNameValue="show-value-full-block"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="column is-12 p-0">
                        <div className="columns is-flex-wrap-wrap">
                          <div className="column is-6-desktop p-0">
                            <div className="columns">
                              <LabelInformation
                                title={ t('groupClasses.groupClass.detailed.registrationContact') }
                                value={ registrationContact }
                                className="show-label-full-block"
                                classNameTitle="show-title-full-block"
                                classNameValue="show-value-full-block"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              isActive={ isActiveModal }
              isClipped
              onClose={ toggleModal }
              title={ t('groupClasses.groupClass.detailed.buttonEndSeason') }
              footer={ footerModal }
            >
              { t('groupClasses.groupClass.detailed.modalEndSeason') }
              { showSeasonClosingError && (
                <div className="custom-error-container">
                  <div className="custom-error-message">
                    <IconCircleXMark />
                    { t('error.modal.season.closed') }
                  </div>
                </div>
              ) }
            </Modal>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default withGroupClass(GroupClassDetailed);
