// @flow
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BeneficiaryLayout from '@layout/components/BeneficiaryLayout';

import SharedSessionService from '@groupSend/services/SharedSessionService';
import { type SharedSession, SharedSessionsData } from '@groupSend/types';

import { RequestStatuses } from '@core/constants';

import SharedSessionsRegistered from '@session/components/SharedSessionsRegistered';

const SharedSessions = (): React$Node => {
  const { t } = useTranslation();
  const [ sharedSessions, setSharedSessions ] = useState<SharedSession[]>(SharedSessionService.sharedSessionsValues);

  const handleUpdateSharedSessions = (data: SharedSessionsData) => {
    setSharedSessions(data.sharedSessions);
  };

  useEffect(() => SharedSessionService.onChange(handleUpdateSharedSessions), []);

  const emptySharedSessions = useMemo((): boolean => (
    SharedSessionService.requestStatusSharedSessions === RequestStatuses.SUCCEEDED
    && sharedSessions.length === 0
  ), [sharedSessions]);

  return (
    <BeneficiaryLayout>
      <div className="shared-sessions-container container">
        <div className="columns is-flex-wrap-wrap">
          { emptySharedSessions && (
            <div className="column is-12 column-no-result">
              <h1>{ t('sharedSessions.title') }</h1>
              <span className="no-result">{ t('groupClasses.groupClass.peoples.search.noResultText') }</span>
            </div>
          ) }
          <div className="column is-12">
            <SharedSessionsRegistered />
          </div>
        </div>
      </div>
    </BeneficiaryLayout>
  );
};

export default SharedSessions;
