// @flow
import clsx from 'clsx';

import { useMemo } from 'react';

import type { Colors, Sizes } from '@core/types';

export type TextAreaProps = {
  className?: string,
  borderColor?: Colors | null,
  hasFixedSize?: boolean,
  isActive?: boolean,
  isFocused?: boolean,
  isHovered?: boolean,
  isStatic?: boolean,
  size?: Sizes,
};

const Textarea = (props: TextAreaProps): React$Node => {
  const {
    size,
    borderColor,
    isHovered,
    isFocused,
    isActive,
    isStatic,
    hasFixedSize,
    className,
    ...others
  } = props;

  const textAreaClassName = useMemo((): string => clsx({
    'has-fixed-size': !!hasFixedSize,
    'is-active': !!isActive,
    'is-focused': !!isFocused,
    'is-hovered': !!isHovered,
    'is-static': !!isStatic,
    'textarea': true,
    [String(borderColor)]: !!borderColor,
    [String(size)]: !!size,
    [String(className)]: !!className,
  }), [hasFixedSize, isActive, isFocused, isHovered, isStatic, borderColor, size, className]);

  return (
    <textarea
      { ...others }
      className={ textAreaClassName }
    />
  );
};

export default Textarea;
