// @flow

import type { UserRegistered, GroupClassDetailed } from '@groupClass/types';
import type { Person } from '@person/type';
import type { ShortBeneficiary, ShortBeneficiaryPayload, Beneficiary, BeneficiaryPayload, BeneficiaryUpdatePayload, BeneficiaryBalancePayload, BeneficiaryBalance } from '@beneficiary/types';

import { Roles } from '@beneficiary/constants';
import { PAYMENT_FREQUENCY_PERIOD } from '@payment/constants';
import { formatAddressFromPayload } from '@shared/Locations/services/AddressUtils';
import { formatShortGroupClassFromPayload } from '@groupClass/helpers/GroupClassesUtils';
import { extractShortGroupClassFromDetailed } from '@groupClass/helpers/GroupClassUtils';
import { hydratePeriodFromPayload } from '@groupClass/helpers/PeriodUtils';

export const parseShortBeneficiaryFromPayload = (beneficiary: ShortBeneficiaryPayload): ShortBeneficiary => ({
  id: beneficiary.id,
  beneficiaryId: beneficiary.registeredId,
  firstName: beneficiary.firstName,
  lastName: beneficiary.lastName,
  type: 'beneficiary',
  role: [Roles.Beneficiary, Roles.User],
});

export const parseBeneficiaryFromPayload = (beneficiary: BeneficiaryPayload): Beneficiary => ({
  address: formatAddressFromPayload(beneficiary.address),
  email: beneficiary.email,
  role: [Roles.Beneficiary, Roles.User],
  language: beneficiary.language,
  id: beneficiary.id,
  firstName: beneficiary.firstName,
  lastName: beneficiary.lastName,
  birthDate: new Date(beneficiary.birthDate),
  mobilePhone: beneficiary.mobilePhone || null,
  landlinePhone: beneficiary.landlinePhone || null,
  sexe: beneficiary.sexe,
  type: 'beneficiary',
});

export const parseBeneficiary = (beneficiary: Beneficiary): BeneficiaryUpdatePayload => ({
  mobilePhone: beneficiary.mobilePhone,
  landlinePhone: beneficiary.landlinePhone,
});

export const parseBeneficiaryBalanceFromPayload = (balance: BeneficiaryBalancePayload): BeneficiaryBalance => ({
  type: balance.type,
  toPay: balance.toPay,
  paid: balance.paid,
  status: balance.status,
  groupClass: formatShortGroupClassFromPayload(balance.groupClass),
  period: hydratePeriodFromPayload(balance.period),
  unsubscribedAt: balance.unsubscribedAt,
  paymentScheduleId: balance.paymentScheduleId,
});

export const extractPersonfromBeneficiary = (beneficiary: Beneficiary): Person => ({
  id: beneficiary.id,
  address: beneficiary.address,
  birthDate: beneficiary.birthDate,
  email: beneficiary.email,
  firstName: beneficiary.firstName,
  landlinePhone: beneficiary.landlinePhone,
  lastName: beneficiary.lastName,
  mobilePhone: beneficiary.mobilePhone,
  sexe: beneficiary.sexe,
  type: beneficiary.type,
});

export const createRegisteredFromBeneficiary = (beneficiary: Beneficiary, groupClass: GroupClassDetailed): UserRegistered => ({
  id: null,
  person: extractPersonfromBeneficiary(beneficiary),
  groupClass: extractShortGroupClassFromDetailed(groupClass),
  paymentFrequency: PAYMENT_FREQUENCY_PERIOD,
  paymentSchedules: [],
  paymentConditions: [],
  subscriptionPaidAt: null,
  unsubscribedAt: null,
});
