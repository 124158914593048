// @flow
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button, { buttonConstants } from '@shared/Button';

import IconChevron from '@icons/components/IconChevron';

type Props = {
  onClick?: () => void,
};

const ButtonGoBack = (props: Props): React$Node => {
  const { onClick } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = useCallback((): void => {
    if (onClick) {
      onClick();
    } else {
      navigate(-1);
    }
  }, [onClick, history]);

  return (
    <Button
      onClick={ handleClick }
      type={ buttonConstants.TERTIARY }
      className="button button-tertiary mb-5"
    >
      <IconChevron className="back-icon" />
      { t('common.back') }
    </Button>
  );
};

export default ButtonGoBack;
