// @flow
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Button, { buttonConstants } from '@shared/Button';
import FormContainer from '@shared/FormContainer';
import ParticipantIndicator from '@shared/ParticipantIndicator';
import Modal from '@shared/Modal/Modal';
import IconPlus from '@icons/components/IconPlus';
import IconMinus from '@icons/components/IconMinus';

import PersonalInformation from '@shared/Personal/components/PersonalInformation';
import PaymentInformation from '@shared/Payment/components/PaymentInformation';

import DateService from '_common/services/DateService';
import Toast from '_common/services/Toast/Toast';

import ParticipantService from '@participant/services/ParticipantService';
import PeopleRequestsRegisteredsService from '@beneficiary/services/PeopleRequestsRegisteredsService';

import type { GroupClassDetailed, UserRegistered } from '@groupClass/types';
import type { Participant } from '@participant/types';

import { WEB_PATHS } from '@app/constants/paths';
const { PARTICIPANTS, PARTICIPANTS_REGISTERED_EDIT_PERSON, PARTICIPANTS_REGISTERED_EDIT_PAYMENT } = WEB_PATHS;

type Props = {
  registered: UserRegistered,
  groupClass: GroupClassDetailed | null,
};

type UseParamProps = {
  classId: string,
};
const ParticipantRegisteredCard = (props: Props): React$Node => {
  const { registered, groupClass } = props;
  const { t, i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const { classId } = useParams<UseParamProps>();

  const [ isOpenPersonalInformation, setIsOpenPersonalInformation ] = useState<boolean>(true);
  const [ isOpenPaymentInformation, setIsOpenPaymentInformation ] = useState<boolean>(true);

  const [ isLoading, setLoading ] = useState<boolean>(ParticipantService.isLoadingValue);
  const [ participant, setParticipant ] = useState<Participant | null>(ParticipantService.participantValue);

  const [ isActiveModal, setIsActiveModal ] = useState<boolean>(false);

  const toggleModal = useCallback((): void => {
    setIsActiveModal(!isActiveModal);
  }, [isActiveModal]);

  const {
    firstName = '',
    lastName = '',
  } = participant || {};

  const lastNameUpperCased = useMemo((): string => (
    lastName.toUpperCase()
  ), [lastName]);

  const handleUpdateState = (data: { participant: Participant | null, isLoading: boolean }) => {
    const { isLoading, participant } = data;
    setLoading(isLoading);
    setParticipant(participant);
  };

  useEffect(() => ParticipantService.onChange(handleUpdateState), []);

  useEffect(() => {
    ParticipantService.fetchOne(registered.person.id, language);
  }, [registered, language]);

  const participantRegisteredCardSeparatorInfosClassName = useMemo((): string => clsx({
    'separator': true,
    'not-displaying': !isOpenPersonalInformation,
  }), [isOpenPersonalInformation]);

  const participantRegisteredCardOpenInfosClassName = useMemo((): string => clsx({
    'content': true,
    'is-open': isOpenPersonalInformation,
    'not-open': !isOpenPersonalInformation,
  }), [isOpenPersonalInformation]);

  const participantRegisteredCardSeparatorPaymentClassName = useMemo((): string => clsx({
    'separator': true,
    'not-displaying': !isOpenPaymentInformation,
  }), [isOpenPaymentInformation]);

  const participantRegisteredCardOpenPaymentClassName = useMemo((): string => clsx({
    'content': true,
    'is-open': isOpenPaymentInformation,
    'not-open': !isOpenPaymentInformation,
  }), [isOpenPaymentInformation]);

  const footerButtons: React$Node = useMemo((): React$Node => (
    <div className="has-text-centered">
      <Button type={ buttonConstants.TERTIARY } onClick={ toggleModal }>
        { t('groupClasses.groupClass.peoples.form.unsubscribeButton') }
      </Button>
    </div>
  ), [toggleModal, language]);

  const handleDeleteRequest = useCallback((): void => {
    PeopleRequestsRegisteredsService.deleteRegistered(registered?.id || 0, parseInt(classId, 10), language)
      .then(() => Toast.success(t('groupClasses.groupClass.peoples.form.wellUnsubscribe')))
      .finally(() => {
        toggleModal();
        navigate(PARTICIPANTS.replace(':classId', classId));
      });
  }, [registered, classId, language, toggleModal]);

  const footerModal: React$Node = useMemo((): React$Node => (
    <footer className="modal-footer">
      <Button
        type={ buttonConstants.TERTIARY }
        onClick={ toggleModal }
        isOutlined
      >
        { t('common.cancel') }
      </Button>

      <Button
        type={ buttonConstants.SECONDARY }
        className="button is-success confirm"
        onClick={ handleDeleteRequest }
      >
        { t('groupClasses.groupClass.peoples.form.popUpConfirm') }
      </Button>
    </footer>
  ), [toggleModal, handleDeleteRequest, language]);

  const groupClassActivityName = registered?.groupClass?.activity?.name || '';
  const groupClassCity = registered?.groupClass?.address.city || '';
  const groupClassPostCode = registered?.groupClass?.address.postalCode || '';

  const groupClassDayTime = useMemo((): string => {
    if (registered && registered.groupClass) {
      const { day, startTime, endTime } = registered.groupClass;
      return DateService.localDateAndTimeDisplay(day, startTime, endTime, language);
    } else {
      return '';
    }
  }, [registered, language]);

  const toggleMenuPersonalInformation = useCallback((): void => {
    setIsOpenPersonalInformation(!isOpenPersonalInformation);
  }, [isOpenPersonalInformation]);

  const toggleMenuPaymentInformation = useCallback((): void => {
    setIsOpenPaymentInformation(!isOpenPaymentInformation);
  }, [isOpenPaymentInformation]);

  return (
    <>
      <FormContainer
        title={
          <>
            <ParticipantIndicator isBeneficiary={ false } extraCss="participant-tab" />
            { !isLoading && (firstName + ' ' + lastNameUpperCased) }
          </>
        }
        className="beneficiary-request-card"
        isLoading={ (isLoading) }
        footerButtonActions={ footerButtons }
      >
        <div className="block-infos">
          <div className="block-title">
            <div className="title">
              <h2 className="title-two">{ t('groupClasses.groupClass.peoples.form.personalInfosTitle') }</h2>
            </div>
            <div className="title-button">
              <Link
                to={ PARTICIPANTS_REGISTERED_EDIT_PERSON.replace(':classId', classId).replace(':registeredId', String(registered.id)) }
                className="button button-primary button-edit"
              >
                { t('payment.actionEdit') }
              </Link>
              { isOpenPersonalInformation
                ? (<IconMinus onClick={ toggleMenuPersonalInformation } />)
                : (<IconPlus onClick={ toggleMenuPersonalInformation } />)
              }
            </div>
          </div>
          <div className={ participantRegisteredCardSeparatorInfosClassName } />
          <div className={ participantRegisteredCardOpenInfosClassName } >
            <div className="container">
              <PersonalInformation data={ registered } user={ participant } />
            </div>
          </div>
        </div>
        <div className="block-infos">
          <div className="block-title">
            <div className="flex wrap">
              <h2 className="title-two">
                { `${ t('groupClasses.groupClass.peoples.form.paiementBlocTitle') }
                ${ groupClassActivityName } - ` }
              </h2>
              <h2 className="uppercase-first title-two">{ `${ groupClassPostCode } ${ groupClassCity }` }</h2>
              <h2 className="title-two">{ groupClassDayTime }</h2>
            </div>
            <div className="title-button">
              <Link
                to={ PARTICIPANTS_REGISTERED_EDIT_PAYMENT.replace(':classId', classId).replace(':registeredId', String(registered.id)) }
                className="button button-primary button-edit"
              >
                { t('payment.actionEdit') }
              </Link>
              { isOpenPaymentInformation
                ? (<IconMinus onClick={ toggleMenuPaymentInformation } />)
                : (<IconPlus onClick={ toggleMenuPaymentInformation } />)
              }
            </div>
          </div>
          <div className={ participantRegisteredCardSeparatorPaymentClassName } />
          <div className={ participantRegisteredCardOpenPaymentClassName } >
            <div className="container containerPersonnalInfos">
              <div className="columns">
                <div className="column is-12">
                  <PaymentInformation
                    data={ registered }
                    groupClass={ groupClass }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </FormContainer>
      <Modal
        isActive={ isActiveModal }
        isClipped
        onClose={ toggleModal }
        title={ t('groupClasses.groupClass.peoples.form.unsubscribe') }
        footer={ footerModal }
      >
        <p>{ t('groupClasses.groupClass.peoples.form.popUpSentence', { firstName, lastName: lastNameUpperCased }) }</p>
        <br />
        <p>{ t('groupClasses.groupClass.peoples.form.popUpSentenceLine2') }</p>
      </Modal>
    </>
  );
};

export default ParticipantRegisteredCard;
