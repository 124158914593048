// @flow
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

import LoginService from '@login/services/LoginService';

import type { GroupClassDetailed } from '@groupClass/types';

type Props = {
  groupClass: GroupClassDetailed,
};

const PreRegister = (props: Props): React$Node => {
  const { t } = useTranslation();
  const { groupClass } = props;

  const [ loginUrl, setLoginUrl ] = useState<string>(LoginService.loginUrl);

  useEffect(() => {
    if (groupClass) {
      const params = {
        groupClassId: String(groupClass.id),
      };
      LoginService.getLoginUrl(params)
        .then(setLoginUrl)
        .catch(() => {});
    }
  }, [groupClass, setLoginUrl]);

  return (
    <a href={ loginUrl } className="first-time-preregister-button button button-primary">
      { t('groupClasses.groupClass.detailed.firstTimePreregisterButton') }
    </a>
  );
};

export default PreRegister;
