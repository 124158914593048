// @flow
import { useMemo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import {
  LOADER_TYPE_PAGE,
  LOADER_TYPE_BACKGROUND,
} from '_common/components/loader/constants';

type Props = {
  color?: 'dark' | 'light',
  className?: string,
  loaderType?: string,
};

const Loader = (props: Props): React$Node => {
  const {
    color = 'dark',
    className,
    loaderType,
  } = props;

  const { t } = useTranslation();

  const loaderClassName = useMemo((): string => clsx({
    'loader-component': true,
    [String(className)]: !!className,
  }), [className]);

  const loaderOuterClassName = useMemo((): string => clsx({
    'loader-outer': true,
    'loader-element': true,
    [String(color)]: !!color,
  }), [color]);

  const loaderMiddleClassName = useMemo((): string => clsx({
    'loader-middle': true,
    'loader-element': true,
    [String(color)]: !!color,
  }), [color]);

  const loaderInnerClassName = useMemo((): string => clsx({
    'loader-inner': true,
    'loader-element': true,
    [String(color)]: !!color,
  }), [color]);

  const loaderPageClassName = useMemo((): string => clsx({
    'loader-page': loaderType === LOADER_TYPE_PAGE,
    'loader-background': loaderType === LOADER_TYPE_BACKGROUND,
  }), [loaderType]);

  return (
    <div className={ loaderPageClassName }>
      <div className="loader-div">
        <div className={ loaderClassName }>
          <div className={ loaderOuterClassName } />
          <div className={ loaderMiddleClassName } />
          <div className={ loaderInnerClassName } />
        </div>
      </div>
      { loaderType === LOADER_TYPE_PAGE && (
        <h1 className="loader-title">{ t('common.loading') }</h1>
      ) }
    </div>
  );
};

export default Loader;
