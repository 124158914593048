// @flow
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import moment from 'moment';

import * as dateTimePickerConstants from '@shared/DateTimePicker/constants';
import Calendar from '@shared/DateTimePicker/components/Calendar';
import IconArrow from '@icons/components/IconArrow';

type Props = {
  date: moment$Moment,
  navigationDate: moment$Moment,
  withMonth: boolean,
  isValid: (date: moment$Moment, viewMode: string) => boolean,
  onChange: (date: moment$Moment, viewMode: string) => void,
  onMonthClicked: () => void,
  onNavigateDateChange: (date: moment$Moment) => void,
};

const DatePicker = (props: Props): React$Node => {
  const {
    date,
    isValid,
    navigationDate,
    onChange,
    onMonthClicked,
    onNavigateDateChange,
    withMonth,
  } = props;

  const { i18n: { language } } = useTranslation();
  const month = moment(navigationDate);
  month.locale(language);

  const handleDateClick: Function = useCallback((date: moment$Moment): void => {
    if (isValid(date, dateTimePickerConstants.VIEW_MODE_DAY)) {
      onChange(date, dateTimePickerConstants.VIEW_MODE_DAY);
    }
  }, [onChange, isValid]);

  const handleMonthClick: Function = useCallback((): void => {
    if (withMonth) {
      onMonthClicked();
    }
  }, [onMonthClicked, withMonth]);

  const goPreviousMonth: Function = useCallback((): void => {
    const newDate = month.subtract(1, 'months');
    onNavigateDateChange(newDate);
  }, [onNavigateDateChange, month]);

  const goNextMonth: Function = useCallback((): void => {
    const newDate = month.add(1, 'months');
    onNavigateDateChange(newDate);
  }, [onNavigateDateChange, month]);

  const formattedMonth = month.format('MMMM YYYY');
  const monthValueClassName = clsx({
    'date-picker-month-value': true,
    'with-month': withMonth,
  });

  return (
    <div className="date-picker-component">
      <div className="date-picker-month">
        <IconArrow className="arrow-previous" onClick={ goPreviousMonth } />
        <span className={ monthValueClassName } onClick={ handleMonthClick }>
          { formattedMonth }
        </span>
        <IconArrow className="arrow-next" onClick={ goNextMonth } />
      </div>

      <Calendar
        date={ date }
        navigationDate={ month }
        isValid={ isValid }
        onClick={ handleDateClick }
      />
    </div>
  );
};

DatePicker.defaultProps = {
  date: '',
  navigationDate: '',
  withMonth: false,
  isValid: (): boolean => true,
  onChange: () => {},
  onMonthClicked: () => {},
  onNavigateDateChange: () => {},
};

export default DatePicker;
