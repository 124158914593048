// @flow
import * as coreConstants from '@app/constants/paths';
import * as paymentConstants from '@payment/constants';
import EventEmitter from '_common/services/EventEmitter';
import ApiService from '@api/service';
import UrlHelpers from '@helpers/UrlHelpers';
import * as paymentUtils from '@shared/Payment/helpers/PaymentUtils';
import i18n from '@app/i18n/i18n';

import type { BillingAddressDataPayload, PaymentCreditCardData, PaymentProviderData, PaymentInformation } from '@payment/types';
import type { Listener, QueryParams } from '@core/types';

const { PAYMENT_REFERENCE_QUERY_PARAM } = paymentConstants;
const { MONETICO_ERROR, MONETICO_SUCCESS } = coreConstants.WEB_PATHS;
const { PAYMENT_CREDIT_CARD } = coreConstants.API_PATHS;

export type PaymentProviderServiceData = {
  paymentProviderInformation: PaymentProviderData | null,
  paymentCCInformation: PaymentCreditCardData | null,
  paymentInformations: PaymentInformation | null,
};

type FetchPaymentProviderInformation = (paymentSchedule: number, amount: number, billingAddress: BillingAddressDataPayload) => Promise<any>;
type FetchCCInformation = (params: QueryParams) => Promise<PaymentCreditCardData>;
type FetchPaymentInformation = (paymentId: string) => void;
type OnChange = (listener: Listener) => Function;
type Trigger = () => void;

class PaymentProviderService {
  constructor() {
    this.eventEmitter = new EventEmitter();
    this._paymentProviderInformation = null;
    this._paymentCCInformation = null;
    this._paymentInformations = null;
  }

  eventEmitter: EventEmitter;
  _paymentProviderInformation: PaymentProviderData | null;
  _paymentCCInformation: PaymentCreditCardData | null;
  _paymentInformations: PaymentInformation | null;

  /**
   * Getter/Setter
   */
  get paymentProviderInformation(): PaymentProviderData | null { return this._paymentProviderInformation; }
  get paymentCCInformation(): PaymentCreditCardData | null { return this._paymentCCInformation; }
  get paymentInformations(): PaymentInformation | null { return this._paymentInformations; }

  set paymentProviderInformation(data: PaymentProviderData): void { this._paymentProviderInformation = data; this.#trigger(); }
  set paymentCCInformation(data: PaymentCreditCardData): void { this._paymentCCInformation = data; this.#trigger(); }
  set paymentInformations(data: PaymentInformation): void { this._paymentInformations = data; this.#trigger(); }

  /**
   * fetchPaymentProviderInformation
   * Request informations about the payment solution provider
   * @param {number} paymentScheduleId
   * @param {number} amount
   * @returns {void}
   */
  fetchPaymentProviderInformation: FetchPaymentProviderInformation = (paymentScheduleId: number, amount: number, billingData: BillingAddressDataPayload): Promise<any> => {
    const { language } = i18n;
    const { billingFirstName, billingLastName, billingAddress } = billingData;

    return ApiService.request({
      method: 'post',
      url: PAYMENT_CREDIT_CARD,
      data: {
        amount,
        groupClassRegisteredPaymentSchedule: paymentScheduleId,
        urlRetourOk: `${ UrlHelpers.getFullUri(MONETICO_SUCCESS) }?${ PAYMENT_REFERENCE_QUERY_PARAM }=`,
        urlRetourErr: `${ UrlHelpers.getFullUri(MONETICO_ERROR) }?${ PAYMENT_REFERENCE_QUERY_PARAM }=`,
        billingFirstName: billingFirstName,
        billingLastName: billingLastName,
        billingAddress: billingAddress,
        language: language.slice(0, 2).toUpperCase(),
      },
    })
      .then((response) => {
        this.paymentProviderInformation = paymentUtils.parsePaymentProviderInfo(response.data);
      })
      .catch((error) => {
        if (error?.response?.data.violations) {
          return Promise.reject(error.response.data.violations);
        }
        return Promise.reject();
      });
  };

  /**
   * fetchCCInformation
   * Retrieve information about the last CC details (if any) from the Back End API
   * @returns {void}
   */
  fetchCCInformation: FetchCCInformation = (params: QueryParams): Promise<PaymentCreditCardData> => {
    const {
      order = 'ASC',
      page = 1,
      itemsPerPage = 10,
    } = params;

    return ApiService.request({
      method: 'get',
      url: `${ PAYMENT_CREDIT_CARD }`,
      params: {
        'order[createdAt]': order,
        page,
        itemsPerPage,
      },
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;
          const maybeCCData = data['hydra:member'].shift();

          if (maybeCCData) {
            this.paymentCCInformation = paymentUtils.parseCCInformation(maybeCCData);
            return this.paymentCCInformation;
          }
        }
        return Promise.reject();
      })
      .catch(() => Promise.reject());
  };

  /**
   * fetchPaymentInformation
   * Retrieve information about the payment from the Back End API
   * @param {string} paymentId
   * @returns {void}
   */
  fetchPaymentInformation: FetchPaymentInformation = (paymentId: string): void => {
    ApiService.request({
      method: 'get',
      url: `${ PAYMENT_CREDIT_CARD }/${ paymentId }`,
    })
      .then((response) => {
        this.paymentInformations = paymentUtils.parsePaymentInformations(response.data);
      });
  };

  /**
   * onChange
   * Add callback to update listeners. It returns the unsubscribe function ton consume when the
   * composant is unmounted
   * @param {Listener} listener
   * @returns {Function}
   */
  onChange: OnChange = (listener: Listener): Function => {
    const listenerFunction = this.eventEmitter.addListener(listener);
    this.#trigger();
    return listenerFunction;
  };

  /**
   * @private
   */
  #trigger: Trigger = (): void => {
    this.eventEmitter.trigger({
      paymentProviderInformation: this._paymentProviderInformation,
      paymentCCInformation: this._paymentCCInformation,
      paymentInformations: this._paymentInformations,
    });
  };
}

const instance: PaymentProviderService = new PaymentProviderService();
export default instance;
