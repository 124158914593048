// @flow
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import withGroupClass from '@hoc/withGroupClass';

import ParticipantsLayout from '@layout/components/ParticipantsLayout';

import BeneficiaryShow from '@beneficiary/components/BeneficiaryShow';
import ParticipantShow from '@participant/components/ParticipantShow';

import RegisteredsService, { type RegisteredsServiceData } from '@groupClass/services/RegisteredsService';

import type { GroupClassDetailed, UserRegistered } from '@groupClass/types';

type UseParamProps = {
  registeredId: string,
};

type Props = {
  groupClass: GroupClassDetailed | null,
};

export const RegisteredShow = (props: Props): React$Node => {
  const { groupClass } = props;
  const { registeredId } = useParams<UseParamProps>();
  const { i18n: { language } } = useTranslation();

  const [ loading, setLoading ] = useState<boolean>(RegisteredsService.isLoadingRegistered);
  const [ registered, setRegistered ] = useState<UserRegistered | null>(RegisteredsService.groupClassRegistered);

  const handleUpdateState = (data: RegisteredsServiceData): void => {
    setLoading(data.isLoadingRegistered);
    setRegistered(data.groupClassRegistered);
  };

  useEffect(() => RegisteredsService.onChange(handleUpdateState), []);

  useEffect(() => {
    RegisteredsService.fetchOne(parseInt(registeredId, 10), language);
  }, [registeredId, language]);

  const isBeneficiary = useMemo((): boolean => {
    if (registered && !loading) {
      return registered.person.type === 'beneficiary';
    }
    return false;
  }, [registered, loading]);

  const isParticipant = useMemo((): boolean => {
    if (registered && !loading) {
      return registered.person.type === 'participant';
    }
    return false;
  }, [registered, loading]);

  return (
    <ParticipantsLayout groupClass={ groupClass }>
      { isBeneficiary && registered && (
        <BeneficiaryShow registered={ registered } groupClass={ groupClass } />
      ) }
      { isParticipant && registered && (
        <ParticipantShow registered={ registered } groupClass={ groupClass } />
      ) }
    </ParticipantsLayout>
  );
};

export default withGroupClass(RegisteredShow);
