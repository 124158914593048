// @flow
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AdvancedSelectField from '@shared/AdvancedSelectField';
import Button, { buttonConstants } from '@shared/Button';
import InputField from '_common/components/input-field/InputField';
import Help from '_common/components/help/Help';
import ObjectHelpers from '@helpers/ObjectHelpers';
import IconPlus from '@icons/components/IconPlus';
import IconTrash from '@icons/components/IconTrash';

import type { ErrorProps, Option } from '@core/types';
import type { GroupClass, GroupClassDetailed } from '@groupClass/types';
import type { SielBleuUser } from '@user/types';
import type { Coach } from '@coach/types';

import Modal from '@shared/Modal/Modal';

type Props = {
  errorsProps: ErrorProps,
  groupClass: GroupClass,
  users: SielBleuUser[],
  setGroupClass: (groupClass: GroupClassDetailed | null) => void,
};

type CoachBlock = {
  contact: string,
  selected: Option | null,
};

const CoachesTable = (props: Props): React$Node => {
  const { t, i18n: { language } } = useTranslation();
  const { groupClass, users, setGroupClass, errorsProps } = props;
  const [ isActiveModal, setIsActiveModal ] = useState<boolean>(false);
  const [ selectedCoachIndex, setSelectedCoachIndex ] = useState<number | null>(null);

  const showDeleteModal = useCallback((index: number): void => {
    setIsActiveModal(true);
    setSelectedCoachIndex(index);
  }, [groupClass]);

  const hideDeleteModal = useCallback((ev?: SyntheticEvent<EventTarget>): void => {
    ev?.preventDefault();
    setIsActiveModal(false);
    setSelectedCoachIndex(null);
  }, []);

  const handleAddCoach = useCallback((ev: SyntheticEvent<EventTarget>): void => {
    ev.preventDefault();
    const newGroupClass = ObjectHelpers.deepClone(groupClass);
    newGroupClass.coaches.push({});
    setGroupClass(newGroupClass);
  }, [groupClass]);

  const handleRemoveCoach = useCallback((ev: SyntheticEvent<EventTarget>): void => {
    ev.preventDefault();
    const newGroupClass = ObjectHelpers.deepClone(groupClass);
    if (selectedCoachIndex) {
      newGroupClass.coaches = newGroupClass.coaches.filter((coach) => coach.id !== selectedCoachIndex);
    } else {
      newGroupClass.coaches = newGroupClass.coaches.filter((coach) => coach.id);
    }
    setGroupClass(newGroupClass);
    hideDeleteModal();
  }, [groupClass, selectedCoachIndex]);

  const handleChangeCoach = useCallback((newValue, actionMeta, index): void => {
    const newGroupClass = ObjectHelpers.deepClone(groupClass);
    const coachSelected = users.find((user) => user.id.toString() === newValue.value);
    const newCoach = {
      id: coachSelected?.id,
      sielBleuUser: {
        id: coachSelected?.id,
        email: coachSelected?.email,
        firstName: coachSelected?.firstName,
        lastName: coachSelected?.lastName,
        mobilePhone: coachSelected?.mobilePhone,
        landlinePhone: coachSelected?.landlinePhone,
      },
      contactInformation: null,
    };

    if (index === null) {
      newGroupClass.coaches.pop();
      newGroupClass.coaches.push(newCoach);
    } else {
      const indexToModify = newGroupClass.coaches.findIndex((coach) => coach.id === index);
      newGroupClass.coaches[indexToModify] = newCoach;
    }
    setGroupClass(newGroupClass);
  }, [groupClass]);

  const handleContact = useCallback((newValue, actionMeta, identity): void => {
    const newGroupClass = ObjectHelpers.deepClone(groupClass);
    const coach = newGroupClass.coaches.find((coach) => coach.id === identity);

    if (coach) {
      coach.contactInformation = newValue.currentTarget.value;
      setGroupClass(newGroupClass);
    }

  }, [groupClass]);

  const userOptions: Option[] = useMemo((): Option[] => (
    users
      .filter((user) => user.role.includes('ROLE_CP') || user.role.includes('ROLE_RD'))
      .filter((user) => groupClass.coaches.find((coach) => coach.id !== user.id))
      .map((user) => ({
        label: `${ user.lastName.toUpperCase() } ${ user.firstName }`,
        value: user.id.toString(),
      }))
  ), [users, groupClass.coaches]);

  const coachesBlock: CoachBlock[] = useMemo((): CoachBlock[] => (
    groupClass.coaches
      .filter((coach) => !coach.isMainCoach)
      .map((coach) => {
        let identity = null;
        let selected = null;
        let contact = '';
        if (coach.id) {
          identity = coach.id;
          selected = {
            label: `${ coach.sielBleuUser.lastName.toUpperCase() } ${ coach.sielBleuUser.firstName }`,
            value: coach.id.toString(),
          };
          contact = coach?.contactInformation || '';
        }
        return { selected, contact, identity };
      })
  ), [groupClass.coaches]);

  const footerModal: React$Node = useMemo((): React$Node => (
    <footer className="modal-footer">
      <Button
        type={ buttonConstants.TERTIARY }
        onClick={ hideDeleteModal }
        isOutlined
      >
        { t('common.no') }
      </Button>

      <Button
        type={ buttonConstants.SECONDARY }
        className="button is-success confirm"
        onClick={ handleRemoveCoach }
      >
        { t('common.yes') }
      </Button>
    </footer>
  ), [hideDeleteModal, handleRemoveCoach, language, t]);

  const mainCoach = useMemo((): Coach | null => (
    groupClass.coaches.find((coach) => coach.isMainCoach) ?? null
  ), [groupClass.coaches]);

  const mainCoachName = useMemo((): string => (
    mainCoach ? `${ mainCoach.sielBleuUser.lastName.toUpperCase() } ${ mainCoach.sielBleuUser.firstName }` : ''
  ), [mainCoach]);

  const mainCoachContact = useMemo((): string => (
    mainCoach ? mainCoach.contactInformation : ''
  ), [mainCoach]);

  return (
    <div className="coaches-container input-edit-form">
      <h2 className="title-two">{ t('groupClasses.groupClass.detailed.coaches') }</h2>
      <div className="coaches-coach">
        <InputField
          name="coaches[0].name"
          id="coaches[0].name"
          label={ t('groupClasses.groupClass.detailed.coach.main') }
          className="coaches-name coaches-field"
          value={ mainCoachName }
          helpProps={ errorsProps['coaches']?.error }
          color={ errorsProps['coaches']?.color }
          labelProps={ errorsProps['coaches']?.labelProps }
          isDisabled
          readOnly
        />
        <div className="coaches-half-block">
          <InputField
            name="contact-0"
            label={ t('groupClasses.groupClass.detailed.phone') }
            placeholder={ `(${ t('common.optional') })` }
            className="coaches-contact"
            value={ mainCoachContact }
            isDisabled
            readOnly
          />
        </div>
      </div>
      { coachesBlock.map(({ selected, contact, identity }, index) => {
        const indexWithoutMainCoach = index + 1;
        return (
          <div className="coaches-coach" key={ `coach-${ indexWithoutMainCoach }` }>
            <AdvancedSelectField
              name="coach"
              label={ t('groupClasses.groupClass.detailed.coach') }
              options={ userOptions }
              placeholder={ t('groupClasses.groupClass.detailed.coachPlaceholder') }
              helpProps={ errorsProps[`coaches[${ indexWithoutMainCoach }].name`]?.error }
              color={ errorsProps[`coaches[${ indexWithoutMainCoach }].name`]?.color }
              labelProps={ errorsProps[`coaches[${ indexWithoutMainCoach }].name`]?.labelProps }
              id={ `coaches[${ indexWithoutMainCoach }].name` }
              isClearable={ false }
              className="coaches-field"
              value={ selected }
              onChange={ (newValue, actionMeta) => handleChangeCoach(newValue, actionMeta, identity) }
              required
            />
            <div className="coaches-half-block">
              <InputField
                name={ `contact-${ indexWithoutMainCoach }` }
                label={ t('groupClasses.groupClass.detailed.phone') }
                placeholder={ `(${ t('common.optional') })` }
                className="coaches-contact"
                value={ contact }
                onChange={ (newValue, actionMeta) => handleContact(newValue, actionMeta, identity) }
              />
              <div className="coaches-icons">
                <div className="wrapper">
                  <IconTrash
                    onClick={ () => showDeleteModal(identity) }
                  />
                </div>
                <div className="wrapper">
                  <Button
                    onClick={ handleAddCoach }
                    type={ buttonConstants.PRIMARY }
                    className={ (selected && indexWithoutMainCoach === coachesBlock.length) ? '' : 'icon-disabled' }
                  >
                    <IconPlus />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        );
      }) }
      { groupClass.coaches.length === 1 && (
        <div className="coaches-icons coaches-one-icon">
          <Button onClick={ handleAddCoach } type={ buttonConstants.PRIMARY }>
            <IconPlus />
          </Button>
        </div>
      ) }

      { errorsProps?.noCoaches?.error && (
        <Help text={ errorsProps.noCoaches.error.text } textColor="is-danger" className="input-edit-form noCoaches" />
      ) }

      <Modal
        isActive={ isActiveModal }
        isClipped
        onClose={ hideDeleteModal }
        title={ t('payment.actionDelete') }
        footer={ footerModal }
      >
        { t('groupClasses.groupClass.detailed.coachModal.deleteCoach') }
      </Modal>
    </div>
  );
};

export default CoachesTable;
