// @flow
import { useEffect, useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, Link } from 'react-router-dom';
import moment from 'moment/dist/moment';

import withGroupClass from '@hoc/withGroupClass';
import BeneficiaryLayout from '@layout/components/BeneficiaryLayout';

import Button, { buttonConstants } from '@shared/Button';
import Modal from '@shared/Modal/Modal';
import ButtonGoBack from '@shared/Navigation/ButtonGoBack';

import AuthService, { type AuthServiceData } from '@user/services/AuthService';
import DateService from '_common/services/DateService';
import Toast from '_common/services/Toast/Toast';
import BeneficiaryGroupClassesRequestsService, { type BeneficiaryGroupClassesRequestsServiceData } from '@beneficiary/services/BeneficiaryGroupClassesRequestsService';
import BeneficiaryGroupClassesRegisteredsService, { type BeneficiaryGroupClassesRegisteredsServiceData } from '@beneficiary/services/BeneficiaryGroupClassesRegisteredsService';
import RequestsService from '@beneficiary/services/RequestsService';
import RolesHelpers from '@user/helpers/RolesHelpers';

import type { User } from '@user/types';
import type { ShortGroupClassBeneficiaryRequests } from '@participant/types';
import type { GroupClassDetailed, GroupClassRegistered } from '@groupClass/types';
import type { Coach } from '@coach/types';

import * as apiConstants from '@api/constants';
import { LOADER_TYPE_PAGE } from '_common/components/loader/constants';
import { WEB_PATHS } from '@app/constants/paths';
import { GROUP_CLASS_REQUEST_INITIATED } from '@app/constants/constants';
import { CLASS_PAYMENT_STATUS_TO_PAY } from '@payment/constants';

const { PARAMS_NO_UNSUBSCRIBE_NO_PAGINATED_SORTED_ACTIVITY } = apiConstants;

import IconCalendar from '@icons/components/IconCalendar';
import IconMarkerMap from '@icons/components/IconMarkerMap';
import { FRANCE, SPAIN_INDICATIVE_PRICE } from '@app/constants/constants';

const { GROUP_CLASSES_BENEFICIARY, BENEFICIARY_PAYMENT, PAGE_NOT_FOUND } = WEB_PATHS;

type Props = {
  groupClass: GroupClassDetailed | null,
};

type useParamsType = {
  classId: string,
};

export const GroupClassBeneficiary = (props: Props): React$Node => {
  const { groupClass } = props;

  const { classId } = useParams<useParamsType>();
  const { t, i18n: { language } } = useTranslation();
  const navigate = useNavigate();

  const [ isLoadingRequest, setIsLoadingRequest ] = useState<boolean>(false);
  const [ groupClassesRequests, setGroupClassesRequests ] = useState<ShortGroupClassBeneficiaryRequests>(BeneficiaryGroupClassesRequestsService.requestsValues);
  const [ isActiveModal, setIsActiveModal ] = useState<boolean>(false);
  const [ groupClassesRegistereds, setGroupClassesRegistereds ] = useState<GroupClassRegistered[]>(BeneficiaryGroupClassesRegisteredsService.registeredsValues);
  const [ isLoadingRegistereds, setIsLoadingRegistereds ] = useState<boolean>(BeneficiaryGroupClassesRegisteredsService.isLoadingValue);
  const [ currentUser, setCurrentUser ] = useState<User | null>(AuthService.user);

  const isBeneficiary = useMemo((): boolean => (
    RolesHelpers.isBeneficiary(currentUser)
  ), [currentUser]);

  /**
   * handleUpdateStateRegistereds
   * Function used to handle update of BeneficiaryGroupClassesRegistereds service
   * @param {BeneficiaryGroupClassesRegisteredsServiceData} data
   * @returns {void}
   */
  const handleUpdateStateRegistereds = (data: BeneficiaryGroupClassesRegisteredsServiceData): void => {
    setIsLoadingRegistereds(data.isLoading);
    setGroupClassesRegistereds(data.registereds);
  };

  /**
   * handleUpdateRequestsService
   * Function used to handle update of BeneficiaryGroupClassesRequests service
   * @param {BeneficiaryGroupClassesRequestsServiceData} data
   * @returns {void}
   */
  const handleUpdateRequestsService = (data: BeneficiaryGroupClassesRequestsServiceData): void => {
    setGroupClassesRequests(data.requests);
  };

  /**
   * toggleModal
   * Function used to toggle the visibility value of the modal
   * @param {void}
   * @returns {void}
   */
  const toggleModal = useCallback((): void => {
    setIsActiveModal(!isActiveModal);
  }, [isActiveModal]);

  /**
   * handleDeleteRequest
   * Function used to delete the request ID from the group class.
   * @param {void}
   * @returns {void}
   */
  const handleDeleteRequest = useCallback((): void => {
    BeneficiaryGroupClassesRequestsService.deleteOne(groupClassesRequests[0].id, language, currentUser?.id)
      .then(() => Toast.success(t('groupClasses.groupClass.peoples.preRegistrationCancelled')))
      .then(() => currentUser
        ? BeneficiaryGroupClassesRegisteredsService.fetchAll(currentUser.id, language, PARAMS_NO_UNSUBSCRIBE_NO_PAGINATED_SORTED_ACTIVITY)
        : Promise.resolve(),
      )
      .finally(() => {
        toggleModal();
        navigate(GROUP_CLASSES_BENEFICIARY);
      });
  }, [groupClassesRegistereds, language, t, currentUser]);

  /**
   * handleDeleteRegistered
   * Function used to remove the a registered from a group class
   * @param {void}
   * @returns {void}
   */
  const handleDeleteRegistered = useCallback((): void => {
    if (currentUser?.id) {
      BeneficiaryGroupClassesRegisteredsService.deleteOne(
        groupClassesRegistereds[0].registered,
        language,
        currentUser.id,
        { pagination: false, 'groupClass.id': classId },
      )
        .then(() => Toast.success(t('groupClasses.groupClass.peoples.form.wellUnsubscribe')))
        .finally(() => {
          toggleModal();
          navigate(GROUP_CLASSES_BENEFICIARY);
        });
    }
  }, [currentUser, groupClassesRegistereds, language, t, classId]);

  /**
   * handlePreregister
   * Function used to pre registered a beneficiary or a participant to the group class
   * @param {void}
   * @returns {void}
   */
  const handlePreregister = useCallback((): void => {
    if (isBeneficiary && groupClass && currentUser) {
      setIsLoadingRequest(true);
      RequestsService.preregisterBeneficiary(groupClass.id, currentUser.id)
        .then(() => {
          Toast.success(t('groupClasses.groupClass.peoples.preRegistrationDone'));
          return BeneficiaryGroupClassesRequestsService.fetchAll(currentUser?.id, language, { ['groupClass.id']: groupClass.id, status: GROUP_CLASS_REQUEST_INITIATED });
        })
        .finally(() => setIsLoadingRequest(false));
    }
  }, [currentUser, isBeneficiary, groupClass, language, t]);

  const handleUpdateAuthService = (data: AuthServiceData) => {
    setCurrentUser(data.user);
  };

  useEffect(() => AuthService.onChange(handleUpdateAuthService), []);

  /**
   * useEffect used to subscribe to all changes of BeneficiaryGroupClassesRequests service
   */
  useEffect(() => BeneficiaryGroupClassesRequestsService.onChange(handleUpdateRequestsService), []);

  /**
   * useEffect used to subscribe to all changes of BeneficiaryGroupClassesRegistereds service
   */
  useEffect(() => BeneficiaryGroupClassesRegisteredsService.onChange(handleUpdateStateRegistereds), []);

  /**
   * useEffect used to fetch all requests of a group class
   */
  useEffect(() => {
    if (currentUser?.id && groupClass?.id) {
      BeneficiaryGroupClassesRequestsService.fetchAll(currentUser.id, language, { ['groupClass.id']: groupClass.id, status: GROUP_CLASS_REQUEST_INITIATED });
    }
  }, [currentUser, language, groupClass]);

  /**
   * useEffect used to go on not found page if the group class is closed
   */
  useEffect(() => {
    if (groupClass && groupClass?.seasonClosedAt) {
      navigate(PAGE_NOT_FOUND);
    }
  }, [groupClass]);

  /**
   * useEffect used to fetch all registered from a group class
   */
  useEffect(() => {
    if (isBeneficiary && currentUser?.id) {
      BeneficiaryGroupClassesRegisteredsService.fetchAll(currentUser.id, language, {
        'exists[unsubscribedAt]': false,
        pagination: false,
        'groupClass.id': classId,
      });
    }
  }, [currentUser, language, isBeneficiary]);

  const hasToPay = useMemo((): boolean => (
    groupClass && currentUser
      ? groupClassesRegistereds.some((element) => element.paymentStatus === CLASS_PAYMENT_STATUS_TO_PAY && element.groupClass.id === groupClass.id)
      : false
  ),[groupClassesRegistereds, currentUser, groupClass]);

  const footerModal: React$Node = useMemo((): React$Node => (
    <footer className="modal-footer">
      <Button
        type={ buttonConstants.TERTIARY }
        onClick={ toggleModal }
        isOutlined
      >
        { t('common.cancel') }
      </Button>

      <Button
        type={ buttonConstants.SECONDARY }
        className="is-success confirm"
        onClick={ groupClassesRegistereds?.length > 0 ? handleDeleteRegistered : handleDeleteRequest }
      >
        { t('groupClasses.groupClass.peoples.form.popUpConfirm') }
      </Button>
    </footer>
  ), [toggleModal, handleDeleteRegistered, handleDeleteRequest, language]);

  const isLoading = useMemo((): boolean => (
    !(!isLoadingRegistereds && !isLoadingRequest)
  ), [isLoadingRegistereds, isLoadingRequest]);

  const startDate = useMemo((): string => (
    moment(groupClass?.startDate).locale(language).format('L')
  ), [groupClass, language]);

  const endDate = useMemo((): string => (
    moment(groupClass?.endDate).locale(language).format('L')
  ), [groupClass, language]);

  const startTime = useMemo((): string => (
    groupClass
      ? DateService.localDateAndTimeDisplay(groupClass.day, groupClass.startTime, groupClass.endTime, language)
      : ''
  ), [groupClass, language]);

  const addressGroupClass = useMemo((): string => (
    groupClass
      ? `${ groupClass.address.streetName } ${ groupClass.address.additionalLocalizationConstruction ?? '' } ${ groupClass.address.postalCode } ${ groupClass.address.city }`
      : ''
  ), [groupClass]);

  const mainCoach = useMemo((): Coach | null => (
    groupClass?.coaches.find((coach) => coach.isMainCoach) ?? null
  ), [groupClass]);

  const mainCoachName = useMemo((): string => (
    mainCoach?.sielBleuUser.firstName || ''
  ), [mainCoach]);

  const indicativePrice = groupClass?.sector.country === FRANCE
    ? groupClass?.activity.indicativePrice
    : SPAIN_INDICATIVE_PRICE;

  const canPreregister = currentUser?.language.includes(groupClass?.sector.country.toLowerCase());

  return (
    <BeneficiaryLayout isLoading={ isLoading } loaderType={ LOADER_TYPE_PAGE }>
      <div className="group-class-card-container container">
        <div className="group-class-card-header">
          <div className="back-btn">
            <ButtonGoBack />
          </div>
          <div className="faq-toggle-btn">
            <a key={ t('user.faq') } target="_blank" rel="noreferrer" href={ t('user.faq.link') }>{ t('user.faq') }</a>
          </div>
        </div>

        { groupClass && (
          <>
            <div className="group-class-card">
              <div className="header">
                <div>
                  <h2 className="name">{ groupClass?.activity.name }</h2>
                  { mainCoach && (
                    <p className="coach">
                      { t('common.with') } <span><u>{ mainCoachName }</u></span>
                    </p>
                  ) }
                </div>
                { hasToPay && (
                  <div className="button-div">
                    <Link to={ BENEFICIARY_PAYMENT } className="button button-primary">
                      { t('payment.summary.payGroupClass') }
                    </Link>
                  </div>
                ) }
              </div>

              <div className="group-class-card-content">
                <div>
                  <div className="infos">
                    <div className="infos-datetime">
                      <IconCalendar />
                      <span>{ startTime }</span>
                    </div>
                    <div className="infos-address">
                      <IconMarkerMap />
                      <span>{ addressGroupClass }</span>
                    </div>
                  </div>
                </div>

                <div className="group-class-card-content-block">
                  <h2 className="group-class-card-title">
                    { t('common.description') }
                  </h2>
                  <span>
                    { groupClass.description }
                  </span>
                </div>

                <div className="block-preregister-prices group-class-card-content-block">
                  <div className="price-block">
                    <h2 className="group-class-card-title no-margin-top">
                      { t('groupClasses.groupClass.detailed.indicativePrices.title') }
                    </h2>
                    <span>{ t('groupClasses.groupClass.detailed.indicativePrices.price', { indicativePrice }) }</span>
                    { groupClass?.sector.country === FRANCE ? (
                      <ul className="price">
                        <li>{ t('groupClasses.groupClass.detailed.indicativePrices.firstLine') }</li>
                        <li>{ t('groupClasses.groupClass.detailed.indicativePrices.secondLine') }</li>
                        <li>{ t('groupClasses.groupClass.detailed.indicativePrices.thirdLine') }</li>
                      </ul>
                    ) : null }
                  </div>

                  { !(groupClassesRequests.length > 0 && groupClassesRequests[0].groupClass.id === groupClass.id) && groupClassesRegistereds.length === 0 && (
                    <div className="preregister-block">
                      <Button
                        className="first-time-preregister-button"
                        onClick={ handlePreregister }
                        type={ buttonConstants.PRIMARY }
                        disabled={ !canPreregister }
                      >
                        { t('groupClasses.groupClass.detailed.firstTimePreregisterButton') }
                      </Button>
                      <span className="first-time-preregister-text">
                        { t('groupClasses.groupClass.detailed.firstTimePreregister') }
                      </span>
                    </div>
                  ) }
                </div>

                <div className="group-class-card-content-block">
                  <h2 className="group-class-card-title">
                    { t('groupClasses.groupClass.detailed.season') }
                  </h2>
                  <span>
                    { t('dates.from') } { startDate }
                    &nbsp;
                    { t('dates.to') } { endDate }
                    <br />
                    { groupClass?.exceptDates && (
                      <span>{ t('groupClasses.groupClass.detailed.exceptDate') } { groupClass.exceptDates }</span>
                    ) }
                  </span>
                </div>

                { groupClass?.registrationTerms && (
                  <div className="group-class-card-content-block">
                    <h2 className="group-class-card-title">
                      { t('groupClasses.groupClass.detailed.registrationTerm') }
                    </h2>
                    <span>
                      { groupClass.registrationTerms }
                    </span>
                  </div>
                ) }

                { groupClass?.registrationContact && (
                  <div className="group-class-card-content-block">
                    <h2 className="group-class-card-title">
                      { t('groupClasses.groupClass.detailed.registrationContact') }
                    </h2>
                    <span>
                      { groupClass.registrationContact }
                    </span>
                  </div>
                ) }

                { (groupClassesRequests.length > 0 && groupClassesRequests[0].groupClass.id === groupClass?.id && groupClassesRegistereds.length === 0) && (
                  <div className="button-cancel-preregister">
                    <Button type={ buttonConstants.TERTIARY } isOutlined onClick={ toggleModal }>
                      { t('groupClasses.groupClass.detailed.cancelPreregister') }
                    </Button>
                  </div>
                ) }
                {
                  groupClassesRegistereds.length > 0 && (
                    <div className="button-cancel-preregister">
                      <Button type={ buttonConstants.TERTIARY } isOutlined onClick={ toggleModal }>
                        { t('groupClasses.groupClass.detailed.cancelRegister') }
                      </Button>
                    </div>
                  )
                }
              </div>
            </div>
            <Modal
              isActive={ isActiveModal }
              isClipped
              onClose={ toggleModal }
              title={
                groupClassesRegistereds.length > 0
                  ? t('groupClasses.groupClass.detailed.cancelRegister')
                  : t('groupClasses.groupClass.detailed.cancelPreregister')
              }
              footer={ footerModal }
            >
              {
                groupClassesRegistereds.length > 0
                  ? t('groupClasses.groupClass.detailed.cancelRegisterModal')
                  : t('groupClasses.groupClass.detailed.cancelPreregisterModal')
              }
            </Modal>
          </>
        ) }
      </div>
    </BeneficiaryLayout>
  );
};

export default withGroupClass(GroupClassBeneficiary);
