import Flags from 'country-flag-icons/react/3x2';

export const COUNTRY_FR = 'FR';
export const COUNTRY_ES = 'ES';
export const COUNTRY_BE = 'BE';
export const COUNTRY_GB = 'GB';

export const COUNTRIES_SUPPORTED = [COUNTRY_FR, COUNTRY_ES, COUNTRY_BE, COUNTRY_GB];

export const countryList = [
  { value: COUNTRY_FR, icon: <Flags.FR title={ COUNTRY_FR } className="flag-icon-option"/> },
  { value: COUNTRY_ES, icon: <Flags.ES title={ COUNTRY_ES } className="flag-icon-option"/> },
  { value: COUNTRY_BE, icon: <Flags.BE title={ COUNTRY_BE } className="flag-icon-option"/> },
  { value: COUNTRY_GB, icon: <Flags.GB title={ COUNTRY_GB } className="flag-icon-option"/> },
];
