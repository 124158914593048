// @flow
import ReactDOMServer from 'react-dom/server';

import { toast, setDefaults, type Options } from 'bulma-toast';

import IconWarning from '@icons/components/IconWarning';
import IconCircleCheck from '@icons/components/IconCircleCheck';

setDefaults({
  dismissible: false,
  duration: 3000,
  animate: { in: 'fadeInLeft', out: 'fadeOutLeft' },
  position: 'bottom-left',
  closeOnClick: true,
  pauseOnHover: true,
  extraClasses: 'custom-toast',
});

const notify = (message: string, extra: Options = {}): void => {
  toast({ ...extra, message });
};

class Toast {
  static info(message: string, extra: Options = {}): void {
    notify(message, { ...extra, type: 'is-info' });
  }

  static success(message: string, extra: Options = {}) {
    const toastContent = ReactDOMServer.renderToString(<><IconCircleCheck className="icon-check-toast" />{ message }</>);
    notify(toastContent, { ...extra, type: 'is-success' });
  }

  static warning(message: string, extra: Options = {}) {
    const toastContent = ReactDOMServer.renderToString(<><IconWarning className="icon-warning-toast" />{ message }</>);
    notify(toastContent, { ...extra, type: 'is-warning' });
  }

  static error(message: string, extra: Options = {}) {
    notify(message, { ...extra, type: 'is-danger' });
  }
}

export default Toast;
