// @flow
import type { Listener } from '@core/types';

import EventEmitter from '_common/services/EventEmitter';
import ApiService from '@api/service';
import AuthService from '@user/services/AuthService';

import { API_PATHS } from '@app/constants/paths';
import type { QueryParams } from '@core/types';

const { LOGIN_SSO, URL_SSO, URL_SSO_LOGOUT } = API_PATHS;

export type LoginServiceData = {
  loginUrl: string,
};

type GetLoginUrl = (params: QueryParams) => Promise<string>;
type GetLogoutUrl = (params: QueryParams) => Promise<string>;
type AuthenticateToken = (params: string) => Promise<any>;
type OnChange = (listener: Listener) => Function;
type Trigger = () => void;

class LoginService {
  constructor() {
    this.eventEmitter = new EventEmitter();
    this._loginUrl = '';
    this._logoutUrl = '';
  }

  eventEmitter: EventEmitter;
  _loginUrl: string;
  _logoutUrl: string;

  get loginUrl(): string { return this._loginUrl; }
  get logoutUrl(): string { return this._logoutUrl; }

  set loginUrl(data: string ): void { this._loginUrl = data; this.#trigger(); }
  set logoutUrl(data: string ): void { this._logoutUrl = data; this.#trigger(); }

  getLoginUrl: GetLoginUrl = (params: QueryParams = {}): Promise<string> => (
    ApiService.request({
      method: 'get',
      url: URL_SSO,
      params,
    })
      .then((response) => Promise.resolve(response.data))
      .catch(() => Promise.reject())
  );

  getLogoutUrl: GetLogoutUrl = (params: QueryParams = {}): Promise<string> => (
    ApiService.request({
      method: 'get',
      url: URL_SSO_LOGOUT,
      params,
    })
      .then((response) => Promise.resolve(response.data))
      .catch(() => Promise.reject())
  );

  authenticateToken: AuthenticateToken = (params: string): Promise<any> => (
    ApiService.request({
      method: 'post',
      url: LOGIN_SSO,
      data: params,
    })
      .then((response) => {
        const { token } = response.data;
        return token
          ? AuthService.connect(token)
          : Promise.reject();
      })
      .catch(() => Promise.reject())
  );

  onChange: OnChange = (listener: Listener): Function => {
    const listenerFunction = this.eventEmitter.addListener(listener);
    this.#trigger();
    return listenerFunction;
  };

  /**
   * @private
   */
  #trigger: Trigger = (): void => {
    this.eventEmitter.trigger({
      loginUrl: this._loginUrl,
    });
  };
}

const instance: LoginService = new LoginService();
export default instance;
