// @flow
import { useMemo } from 'react';
import clsx from 'clsx';

import Loader from '_common/components/loader/Loader';

type Props = {
  children: React$Node,
  className: string,
  footerButtonActions?: React$Node,
  headerButtonActions?: React$Node,
  title: string,
  isLoading?: boolean,
};

const FormContainer = (props: Props): React$Node => {
  const { children, className, footerButtonActions, title, isLoading } = props;

  const formContainerClassName = useMemo((): string => clsx({
    'form-container': true,
    [String(className)]: !!className,
  }), [className]);

  return (
    <div className={ formContainerClassName }>
      <div className="card-form-header">
        <div>
          <h1 className="card-form-title">{ title }</h1>
        </div>
      </div>
      <div className="card-form-body">
        { isLoading ? <Loader className="form-container-loader" /> : children }
      </div>
      { footerButtonActions && (
        <div className="card-form-footer">
          <div className="button-container">{ footerButtonActions }</div>
        </div>
      ) }
    </div>
  );
};

FormContainer.defaultProps = {
  className: 'form-container',
  title: '',
};

export default FormContainer;
