// @flow
import { useEffect, useState } from 'react';

import type { SielBleuUser, SielBleuUserPayload } from '@user/types';
import ApiService from '@api/service';
import { hydrateSielBleuUserFromPayload } from '@user/helpers/SielBleuUsersUtils';

import { API_PATHS } from '@app/constants/paths';

const { SIEL_BLEU_USERS } = API_PATHS;

let source = ApiService.createToken();

type ServiceResponse = [
  SielBleuUser[], // users field
  boolean, // isLoading field
];

const useSielBleuUsers = (language: string): ServiceResponse => {
  const [ users, setUsers ] = useState<SielBleuUser[]>([]);
  const [ isUsersLoading, setLoading ] = useState<boolean>(false);

  useEffect(() => {
    source.cancel();
    source = ApiService.createToken();

    setLoading(true);
    ApiService.request({
      method: 'get',
      url: SIEL_BLEU_USERS,
      cancelToken: source.token,
      headers: {
        'Accept-Language': language,
      },
      params: {
        pagination: 'false',
        'order[lastName]': 'asc',
      },
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;

          const usersArray: SielBleuUser[] = data['hydra:member'].map((user: SielBleuUserPayload) =>
            hydrateSielBleuUserFromPayload(user),
          );

          setUsers(usersArray);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    return () => {
      source.cancel();
    };
  }, [language]);

  return [ users, isUsersLoading ];
};

export default useSielBleuUsers;
