// @flow
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { WEB_PATHS } from '@app/constants/paths';
const { HOME } = WEB_PATHS;

const PageNotFound = (): React$Node => {
  const { t } = useTranslation();

  return (
    <div className="page-not-found">
      <h1 className="title">{ t('pageNotFound.title') }</h1>
      <div className="bloc-sentence">
        <h2 className="sentence">{ t('pageNotFound.sentence') }&nbsp;</h2>
        <Link to={ HOME } className="sentence link">
          { t('pageNotFound.getphyHomePage') }
        </Link>
      </div>
    </div>
  );
};

export default PageNotFound;
