// @flow
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import moment from 'moment';

import type { PickerRules } from '@shared/DateTimePicker/types';
import * as dateTimePickerConstants from '@shared/DateTimePicker/constants';
import InputDateTime from '@shared/DateTimePicker/components/InputDateTime';
import DatePicker from '@shared/DateTimePicker/components/DatePicker';
import MonthPicker from '@shared/DateTimePicker/components/MonthPicker';
import TimePicker from '@shared/DateTimePicker/components/TimePicker';
import YearPicker from '@shared/DateTimePicker/components/YearPicker';
import DateHelpers from '@helpers/DateHelpers';
import useOnClickOutside from '@hooks/useOnClickOutside';

type Props = {
  className: string,
  closeOnSelect: boolean,
  date: string | moment$Moment,
  disabled: boolean,
  field: string,
  format: string,
  initialDate: string | moment$Moment,
  isError: boolean,
  label: string,
  placeholder: string,
  rules: PickerRules,
  withDate: boolean,
  withMonth: boolean,
  withTime: boolean,
  withYear: boolean,
  isValid: (field: string, date: string) => boolean,
  onChange: (field: string, date: string) => void,
  onClose: () => void,
  onOpen: () => void,
};

const calculatedViewMode = (props: Props): string => {
  const { withDate, withMonth, withTime, withYear } = props;
  let viewMode = dateTimePickerConstants.VIEW_MODE_DAY;
  if (!withDate && !withTime && withMonth) {
    viewMode = dateTimePickerConstants.VIEW_MODE_MONTH;
  } else if (!withDate && !withTime && !withMonth && withYear) {
    viewMode = dateTimePickerConstants.VIEW_MODE_YEAR;
  }
  return viewMode;
};

export const DateTimePicker = (props: Props): React$Node => {
  const { t, i18n: { language } } = useTranslation();
  moment.locale(language);

  const {
    className,
    closeOnSelect,
    date,
    disabled,
    field,
    format,
    initialDate,
    isError,
    label,
    isValid,
    onChange,
    onClose,
    onOpen,
    placeholder,
    rules,
    withDate,
    withMonth,
    withTime,
    withYear,
  } = props;

  const [ isOpen, setIsOpen] = useState<boolean>(false);
  const [ navigationDate, setNavigationDate] = useState<moment$Moment>(date ? moment(date, format) : moment(initialDate, format));
  const [ viewMode, setViewMode] = useState<string>(calculatedViewMode(props));

  const datePickerRef: React$Reference<?HTMLElement> = useRef();

  /**
   * handleClickOutside
   * Function used to manage how to manage clicks outside of this component
   * @returns {void}
   */
  const handleClickOutside = useCallback((): void => {
    setIsOpen(false);
    if (isOpen) onClose();
  }, [isOpen, onClose, setIsOpen]);

  /**
   * Listen click of the user to automatically close this component if the click was made outside of
   * this component
   */
  useOnClickOutside(datePickerRef, handleClickOutside);

  /**
   * useEffect used to update the navigation date
   */
  useEffect(() => {
    if (date) setNavigationDate(moment(date, format));
    else setNavigationDate(moment());
  }, [date, setNavigationDate]);

  /**
   * useEffect used to update vertical position of the container if the picker is shown outside of
   * the visible part of the screen
   */
  useEffect(() => {
    if (!datePickerRef.current) return;
    if (isOpen) {
      const { height, top } = datePickerRef.current.getBoundingClientRect();
      const screenHeight = window.innerHeight;
      const overSize = (height + top + 16) - screenHeight;
      if (datePickerRef?.current?.style && overSize > 0) {
        datePickerRef.current.style.top = `-${ overSize }px`;
      }
    } else {
      datePickerRef.current.style.top = '0';
    }
  }, [datePickerRef, isOpen]);

  /**
   * viewModeNeedsCheck
   * Checks if the viewMode requires checks on the navigationDate or not.
   * Only some case will used the validation rules
   * @param {string} viewMode
   * @returns {boolean}
   */
  const viewModeNeedsCheck = useCallback((viewMode: string): boolean => (
    viewMode === dateTimePickerConstants.VIEW_MODE_DAY
      || viewMode === dateTimePickerConstants.VIEW_MODE_TIME
      || (viewMode === dateTimePickerConstants.VIEW_MODE_MONTH && !withDate)
      || (viewMode === dateTimePickerConstants.VIEW_MODE_YEAR && !withMonth && !withDate)
  ), [withDate, withMonth]);

  /**
   * isValidDate
   * Checks if the given date is valid or not
   * @param {moment$Moment} date
   * @returns {boolean}
   */
  const isValidDate = useCallback((date: moment$Moment): boolean => (
    isValid(field, moment(date).format(format))
  ), [field, format, isValid]);

  /**
   * handleChange
   * Update the date on the parent component side
   * @param {moment$Moment} date
   * @returns {void}
   */
  const handleChange = useCallback((date: moment$Moment): void => {
    onChange(field, date.isValid() ? moment(date).format(format) : '');
    if (closeOnSelect) onClose();
  }, [closeOnSelect, field, format, onClose, onChange, language, t]);

  /**
   * handleKeyDown
   * Handle some key pressed value and do special actions.
   * keyCode 09 = Tab
   * keyCode 13 = Enter
   * @param {KeyboardEvent} target
   * @returns {void}
   */
  const handleKeyDown = useCallback((event: KeyboardEvent): void => {
    if ([9, 13].includes(event.keyCode)) {
      handleClickOutside();
    }
  }, [handleClickOutside]);

  /**
   * handleFocus
   * Handle the event when the focus is taken by this component
   * @returns {void}
   */
  const handleFocus = useCallback((): void => {
    if (!isOpen) {
      onOpen();
      setIsOpen(true);
    }
  }, [onOpen, isOpen, setIsOpen]);

  /**
   * onDateChange
   * Handle update of the date and checks if the date is valid
   * @param {moment$Moment} date
   * @param {string} viewMode
   * @param {?boolean} autoOnClose
   */
  const onDateChange = useCallback((date: moment$Moment, viewMode: string, autoOnClose?: boolean = true): void => {
    if (!viewModeNeedsCheck(viewMode) || isValidDate(date)) {
      setNavigationDate(date);
      handleChange(date);
    }

    if (viewMode === dateTimePickerConstants.VIEW_MODE_YEAR && withMonth) {
      setViewMode(dateTimePickerConstants.VIEW_MODE_MONTH);
    } else if (viewMode === dateTimePickerConstants.VIEW_MODE_MONTH && withDate) {
      setViewMode(dateTimePickerConstants.VIEW_MODE_DAY);
    } else if (isOpen) {
      setIsOpen(!autoOnClose);
    }
  }, [handleChange, isOpen, isValidDate, setIsOpen, viewModeNeedsCheck, withDate, withMonth]);

  /**
   * onInputDateChange
   * Handle update of the input date value and checks if the date is valid
   * @param {moment$Moment} date
   * @returns {void}
   */
  const onInputDateChange = useCallback((date: moment$Moment | string): void => {
    onDateChange(DateHelpers.parseDate(date, format), viewMode, false);
  }, [format, onDateChange, viewMode]);

  const dateTimePickerClassName = useMemo((): string => clsx({
    'field': true,
    'date-time-picker-component': true,
    'field-disabled': disabled,
    'field-error': isError,
    'is-open': isOpen,
    'is-disabled': disabled,
    'is-error': isError,
    [className]: !!className,
  }), [className, disabled, isOpen, isError]);

  const withCalendar = withTime || withDate || withMonth || withYear;
  const isCalendarMode = viewMode === dateTimePickerConstants.VIEW_MODE_DAY
    || viewMode === dateTimePickerConstants.VIEW_MODE_TIME;
  const showYearPicker = withYear && viewMode === dateTimePickerConstants.VIEW_MODE_YEAR;
  const showMonthPicker = withMonth && viewMode === dateTimePickerConstants.VIEW_MODE_MONTH;
  const showDatePicker = withDate && isCalendarMode;
  const showTimePicker = withTime && isCalendarMode;

  return (
    <div
      className={ dateTimePickerClassName }
      ref={ datePickerRef }
      onKeyDown={ handleKeyDown }
    >
      <InputDateTime
        date={ date }
        format={ format }
        isDisabled={ disabled }
        isError={ isError }
        label={ label }
        name={ field }
        onChange={ onInputDateChange }
        onFocus={ handleFocus }
        placeholder={ placeholder }
        onKeyDown={ handleKeyDown }
      />

      { withCalendar && isOpen && (
        <div className="date-time-picker-wrapper">
          <div className="date-time-picker-container">
            { showYearPicker && (
              <YearPicker
                date={ (date && moment(date)) || navigationDate }
                navigationDate={ navigationDate }
                onChange={ onDateChange }
                onNavigateDateChange={ setNavigationDate }
              />
            ) }

            { showMonthPicker && (
              <MonthPicker
                date={ (date && moment(date)) || navigationDate }
                navigationDate={ navigationDate }
                withYear={ withYear }
                onChange={ onDateChange }
                onNavigateDateChange={ setNavigationDate }
                onYearClicked={ () => setViewMode(dateTimePickerConstants.VIEW_MODE_YEAR) }
              />
            ) }

            { showDatePicker && (
              <DatePicker
                date={ (date && moment(date)) || navigationDate }
                navigationDate={ navigationDate }
                withMonth={ withMonth }
                isValid={ isValidDate }
                onChange={ onDateChange }
                onNavigateDateChange={ setNavigationDate }
                onMonthClicked={ () => setViewMode(dateTimePickerConstants.VIEW_MODE_MONTH) }
              />
            ) }

            { showDatePicker && showTimePicker && (
              <div className="separator" />
            ) }

            { showTimePicker && (
              <TimePicker
                date={ (date && moment(date)) || navigationDate }
                rules={ rules }
                onChange={ onDateChange }
              />
            ) }
          </div>
        </div>
      ) }
    </div>
  );
};

DateTimePicker.defaultProps = {
  className: 'date-time-picker-component',
  closeOnSelect: false,
  date: (moment(): moment$Moment),
  disabled: false,
  field: '',
  format: 'DD/MM/YYYY',
  initialDate: (moment(): moment$Moment),
  isError: false,
  placeholder: '',
  rules: dateTimePickerConstants.DEFAULT_STEP_RULES,
  withDate: false,
  withMonth: false,
  withTime: false,
  withYear: false,
  isValid: (): boolean => true,
  onChange: () => {},
  onClose: () => {},
  onOpen: () => {},
};

export default DateTimePicker;
