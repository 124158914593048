// @flow
import type { Address } from '@shared/Locations/types';
import type { SielBleuUser, SielBleuUserPayload, SielBleuUserDetailed, SielBleuUserDetailedPayload, User } from '@user/types';

export const hydrateSielBleuUserFromPayload = (user: SielBleuUserPayload): SielBleuUser => ({
  id: user.id,
  email: user.email,
  role: user.role,
  language: user.language,
  firstName: user.firstName,
  lastName: user.lastName,
  birthDate: new Date(user.birthDate),
  mobilePhone: user.mobilePhone,
  landlinePhone: user.landlinePhone,
  sexe: user.sexe,
  type: 'sielbleuuser',
});

export const hydrateSielBleuUserDetailedFromPayload = (user: SielBleuUserDetailedPayload): SielBleuUserDetailed => ({
  id: user.id,
  email: user.email,
  role: user.role,
  language: user.language,
  firstName: user.firstName,
  lastName: user.lastName,
  birthDate: new Date(user.birthDate),
  mobilePhone: user.mobilePhone,
  landlinePhone: user.landlinePhone,
  sexe: user.sexe,
  type: 'sielbleuuser',
  sectorIds: user.sectorIds,
});

export const extractUserFromSielBleuUser = (user: SielBleuUserDetailed): User => ({
  id: user.id,
  firstName: user.firstName,
  lastName: user.lastName,
  email: user.email,
  role: user.role,
  address: emptyAdress(),
});

export const emptyAdress = (): Address => ({
  id: null,
  additionalLocalizationConstruction: '',
  city: '',
  complementNumber: null,
  country: '',
  distributionService: '',
  latitude: null,
  longitude:  null,
  number: null,
  postalCode: '',
  streetName: '',
});
