// @flow
import { useEffect, useMemo, useState, useCallback } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import type { UserRegistered } from '@groupClass/types';
import type { Participant } from '@participant/types';
import type { Error } from '@core/types';

import ParticipantIndicator from '@shared/ParticipantIndicator';
import Button, { buttonConstants } from '@shared/Button';
import FormContainer from '@shared/FormContainer';
import Toast from '_common/services/Toast/Toast';

import PersonalForm from '@shared/Personal/components/PersonalForm';

import ParticipantService, { type ParticipantServiceData } from '@participant/services/ParticipantService';

import { WEB_PATHS } from '@app/constants/paths';
import { validateParticipantForm } from '@participant/helpers/ParticipantsUtils';
const { PARTICIPANTS_REGISTERED_ID } = WEB_PATHS;

type Props = {
  registered: UserRegistered,
};

type UseParamProps = {
  classId: string,
};

const ParticipantEditPerson = (props: Props): React$Node => {
  const { registered } = props;
  const { t, i18n: { language } } = useTranslation();
  const { classId } = useParams<UseParamProps>();
  const navigate = useNavigate();

  const [ isLoading, setLoading ] = useState<boolean>(ParticipantService.isLoadingValue);
  const [ participant, setParticipant ] = useState<Participant | null>(ParticipantService.participantValue);
  const [ errors, setErrors ] = useState<Error[]>(ParticipantService.errorsValues);
  const [ authorizeData, setAuthorizeData ] = useState<boolean>(true);

  const toggleAuthorizeData = useCallback((): void => {
    setAuthorizeData(!authorizeData);
  }, [authorizeData]);

  const {
    firstName = '',
    lastName = '',
  } = participant || {};

  const lastNameUpperCased = useMemo((): string => (
    lastName.toUpperCase()
  ), [lastName]);

  const handleUpdateState = (data: ParticipantServiceData): void => {
    const { isLoading, errors, participant } = data;
    setLoading(isLoading);
    if (!isLoading && errors.length === 0) setParticipant(participant);
    setErrors(errors);
  };

  useEffect(() => ParticipantService.onChange(handleUpdateState), []);

  useEffect(() => {
    ParticipantService.fetchOne(registered.person.id, language);
  }, [registered, language]);

  const updateParticipant = useCallback((): void => {
    const formErrors = validateParticipantForm(participant);

    setErrors(formErrors);

    if (participant && formErrors.length === 0) {
      ParticipantService.update(participant.id, participant)
        .then(() => Toast.success(t('groupClasses.groupClass.peoples.form.wellUpdated')))
        .then(() => navigate(PARTICIPANTS_REGISTERED_ID.replace(':classId', classId).replace(':registeredId', String(registered.id))));
    }
  }, [participant, classId]);

  const footerButtons: React$Node = useMemo((): React$Node => (
    <div className="button-edit-mode">
      <Link
        to={ PARTICIPANTS_REGISTERED_ID.replace(':classId', classId).replace(':registeredId', String(registered.id)) }
        className="button button-tertiary button-cancel button-position"
      >
        { t('common.cancel') }
      </Link>
      <Button
        className="button-position"
        onClick={ updateParticipant }
        type={ buttonConstants.PRIMARY }
      >
        { t('groupClasses.groupClass.peoples.form.confirmButton') }
      </Button>
    </div>
  ), [classId, registered, updateParticipant, language]);

  return (
    <FormContainer
      title={
        <>
          <ParticipantIndicator isBeneficiary={ false } extraCss="participant-tab" />
          { !isLoading && (firstName + ' ' + lastNameUpperCased) }
        </>
      }
      className="beneficiary-request-card"
      isLoading={ (isLoading) }
      footerButtonActions={ footerButtons }
    >
      <div className="block-infos">
        <div className="block-title">
          <h2 className="title-two">{ t('groupClasses.groupClass.peoples.form.personalInfosTitle') }</h2>
        </div>
        <div className="separator" />
        <div className="container containerPersonnalInfos">
          <div className="columns">
            <div className="column is-12">
              <div className="content is-open">
                <PersonalForm
                  user={ participant }
                  setUser={ setParticipant }
                  errors={ errors }
                  authorizeData={ authorizeData }
                  toggleAuthorizeData={ toggleAuthorizeData }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormContainer>
  );
};

export default ParticipantEditPerson;
