// @flow
import { useEffect, useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import moment from 'moment';

import type { Beneficiary } from '@beneficiary/types';
import type { Error, Option, ErrorProps } from '@core/types';
import type { Participant } from '@participant/types';
import type { Cities, SearchCity } from '@shared/Locations/types';
import type { GroupClassDetailed } from '@groupClass/types';

import CheckboxField from '_common/components/checkbox-field/CheckboxField';
import AdvancedSelectField from '@shared/AdvancedSelectField/components/AdvancedSelectField';
import {
  CITY_REGEX,
  FRANCE,
  GROUP_CLASS_PEOPLE_FORM_COUNTRY,
  GROUP_CLASS_PEOPLE_GENDER,
  ZIPCODE_REGEX,
} from '@app/constants/constants';
import InputField from '_common/components/input-field/InputField';
import Label from '_common/components/label/Label';
import Help from '_common/components/help/Help';
import IconWarning from '@icons/components/IconWarning';
import DateTimePicker from '@shared/DateTimePicker/components/DateTimePicker';
import { scrollToFirstErrorElement } from '_common/services/CommonUtils';
import { EMPTY_PARTICIPANT } from '@participant/constants';

import { errorColor } from '@app/constants/styles';
import ObjectHelpers from '@helpers/ObjectHelpers';
import TranslationHelpers from '@helpers/TranslationHelpers';
import { formatAddressFromGoogleAutoComplete } from '@shared/Locations/services/AddressUtils';

import { inputBorderColor } from '@app/constants/styles';
import PhoneField from '_common/components/phone-field/PhoneField';

import { GOOGLE_MAPS_API_KEY } from '@app/constants/constants';
import { useLazyQuery } from '@apollo/client';
import CITIES from '@root/src/Apollo/queries/cities';

type Props = {
  authorizeData: boolean,
  errors: Error[],
  setUser: (user: Beneficiary | Participant | null) => void,
  user: Beneficiary | Participant | null,
  toggleAuthorizeData: () => void,
  groupClass: GroupClassDetailed | null,
};

const PersonalForm = (props: Props): React$Node => {
  const {
    user,
    errors,
    setUser,
    authorizeData,
    toggleAuthorizeData,
    groupClass,
  } = props;

  const { t, i18n: { language } } = useTranslation();
  const { address, birthDate, email, firstName, landlinePhone, lastName, mobilePhone, sexe } = user || EMPTY_PARTICIPANT;
  const { country, city, postalCode } = address;

  const [ autoCompleteAddress, setAutoCompleteAddress ] = useState<SearchCity | null>(null);
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [suggestCities, setSuggestCities] = useState<Option[] | null>(null);
  const [selectedCity, setSelectedCity] = useState<Option | null>(null);
  const [fetchCities] = useLazyQuery(CITIES);
  const isSearchable = useMemo(() => (value: string) => value.match(`^${ ZIPCODE_REGEX }$`) || value.match(`^${ CITY_REGEX }$`), []);

  useEffect((): void => {
    if (!isLoading && errors.length > 0) {
      scrollToFirstErrorElement(document.getElementsByClassName('input-personal-form'), errors);
    }
  }, [errors, isLoading]);

  const getPredictionAddress = useCallback((): void => {
    if (city !== '' && postalCode !== '') {
      if (country === FRANCE) {
        setSelectedCity(
          { label: `${ postalCode } ${ city.charAt(0).toUpperCase() }${ city.slice(1).toLowerCase() }`,
            value: { zipcode: postalCode, city: city },
          });
      }
      if (country !== FRANCE && window?.google?.maps){
        const service = new window.google.maps.places.AutocompleteService();
        service.getQueryPredictions({ input: `${ postalCode } ${ city }` }, (predictions) => {
          if (predictions?.length > 0) {
            setAutoCompleteAddress({ label: `${ postalCode } ${ city }`, value: predictions[0] });
          }
          setIsLoading(false);
        });
      }
    }
  }, [country, city, postalCode, window?.google?.maps, autoCompleteAddress, selectedCity]);

  // callback for script injected by react-google-places-autocomplete, in case, api maps is not loaded...
  window.__googleMapsCallback = getPredictionAddress;

  useEffect(() => {
    getPredictionAddress();
  }, [city, postalCode, window.google?.maps]);

  const errorsProps = useMemo((): ErrorProps => {
    let errorsPropsTmp = {};
    errors.forEach((error) => {
      Object.assign(errorsPropsTmp, {
        [`${ error.propertyPath }`]: {
          color: 'is-danger',
          borderColor: errorColor,
          labelProps: { className: 'has-text-danger' },
          labelTextAreaProps: {
            className: 'has-text-danger label',
          },
          selectFieldHelperText: { text: t('commonError.thisFieldIsMandatory'), textColor: 'is-danger' },
          error: {
            text: t(TranslationHelpers.getCommonErrorKeyByCode(error.code, error.propertyPath)),
            textColor: 'is-danger',
          },
        },
      });
    });
    return errorsPropsTmp;
  }, [errors]);

  const countryOptions = (groupClass)
    ? [{ value: groupClass.sector.country, label: t(`countries.${ groupClass.sector.country.toLowerCase() }`) }]
    : GROUP_CLASS_PEOPLE_FORM_COUNTRY.map((country) => ({ value: country.value, label: t(country.label, language) }));

  const sexeValue = useMemo((): Option | null => (
    sexe ? { value: sexe, label: t(`gender.${ sexe }`) } : null
  ), [sexe]);

  const sexeOptions = GROUP_CLASS_PEOPLE_GENDER.map((gender) => ({ value: gender.value, label: t(gender.label) }));

  const countryValue = (groupClass)
    ? { label: t(`countries.${ groupClass.sector.country.toLowerCase() }`), value: groupClass.sector.country }
    : { label: country ? t(`countries.${ country.toLowerCase() }`) : '', value: country };

  const handleChange = useCallback((e: SyntheticEvent<HTMLInputElement>): void => {
    const newUser = ObjectHelpers.deepClone(user);
    newUser[e.currentTarget.name] = e.currentTarget.value;
    setUser(newUser);
  }, [user]);

  const handleChangeSexe = useCallback((newValue: Option | null): void => {
    const newUser = ObjectHelpers.deepClone(user);
    newUser.sexe = newValue?.value || '';
    setUser(newUser);
  }, [user]);

  const handleChangeBirthdate = useCallback((field: string, date: string): void => {
    const newUser = ObjectHelpers.deepClone(user);
    newUser.birthDate = date !== '' ? moment(date, 'DD/MM/YYYY').utc().toDate() : null;
    setUser(newUser);
  }, [user]);

  const handleChangeAddressAutoComplete = (value) => {
    const newUser = ObjectHelpers.deepClone(user);
    if (value === null) {
      newUser.address.postalCode = '';
      newUser.address.city = '';

      setUser(newUser);

      setAutoCompleteAddress(null);
      setSelectedCity(null);
      return;
    }
    if (value.value.place_id) {
      geocodeByPlaceId(value.value.place_id).then((results) => {
        const newAddress = formatAddressFromGoogleAutoComplete(results[0]);
        setAutoCompleteAddress(value);
        newUser.address = {
          ...newUser.address,
          ...newAddress,
        };
      });
    }
    else {
      setSelectedCity(value);
      newUser.address = {
        ...newUser.address,
        postalCode: value.value.zipcode,
        city: value.value.city,
      };
    }

    setUser(newUser);

  };

  const handleChangeAddress = useCallback((newValue: Option | Option[] | null): void => {
    const newUser = ObjectHelpers.deepClone(user);
    if (Array.isArray(newValue)) return;

    if (newValue !== null) {
      newUser.address.country = newValue.value;
    }
    if (user?.address?.country !== newUser.address.country) {
      newUser.address.postalCode = '';
      newUser.address.city = '';
      setAutoCompleteAddress(null);
      setSelectedCity(null);
    }
    setUser(newUser);
  }, [user]);

  const onChangeSearch = useCallback((search: string) => {
    if (isSearchable(search)) {
      fetchCities({ variables: { search } }).then(({ data }: { data?: { cities: Cities[] } }) => {
        const suggestCitiesData: Option[] = (data?.cities || []).map((c) => ({
          value: { zipcode: c.zipcode, city: c.name },
          label: `${ c.zipcode } ${ c.name.charAt(0).toUpperCase() }${ c.name.slice(1).toLowerCase() }`,
        }));
        setSuggestCities([...suggestCitiesData]);
      });
    }
  }, [fetchCities]);

  const handleMobileChange = useCallback((value): void => {
    setUser({
      ...ObjectHelpers.deepClone(user),
      mobilePhone: value,
    });
  }, [user]);

  const handlelandlineChange = useCallback((value): void => {
    setUser({
      ...ObjectHelpers.deepClone(user),
      landlinePhone: value,
    });
  }, [user]);

  const isBeneficiary = useMemo((): boolean => (
    user?.type === 'beneficiary'
  ), [user]);

  return (
    <form className="beneficiary-personal-edit">
      <h2 className="label-title">{ t('groupClasses.groupClass.peoples.form.peopleIdentity') }</h2>
      <div className="block-flex">
        <AdvancedSelectField
          name="sexe"
          label={ t('groupClasses.groupClass.peoples.form.peopleGender') }
          color={ errorsProps.sexe?.color }
          labelProps={ errorsProps.sexe?.labelProps }
          helpProps={ errorsProps.sexe?.error }
          className="field input-personal-form"
          options={ sexeOptions }
          isClearable={ false }
          value={ sexeValue }
          onChange={ handleChangeSexe }
          required
          isDisabled={ isBeneficiary }
        />
        <InputField
          name="firstName"
          label={ t('groupClasses.groupClass.peoples.form.peopleFirstName') }
          color={ errorsProps.firstName?.color }
          labelProps={ errorsProps.firstName?.labelProps }
          helpProps={ errorsProps.firstName?.error }
          className="field input-personal-form firstName"
          value={ firstName }
          onChange={ handleChange }
          required
          isDisabled={ isBeneficiary }
        />
        <InputField
          name="lastName"
          label={ t('groupClasses.groupClass.peoples.form.peopleLastName') }
          color={ errorsProps.lastName?.color }
          labelProps={ errorsProps.lastName?.labelProps }
          helpProps={ errorsProps.lastName?.error }
          className="field input-personal-form lastName"
          value={ lastName }
          onChange={ handleChange }
          required
          isDisabled={ isBeneficiary }
        />
      </div>
      <div className="date-picker">
        <DateTimePicker
          className="field input-personal-form birthDate"
          date={ birthDate ? moment(birthDate).format('DD/MM/YYYY') : '' }
          label={ `${ t('user.birthDate') } ${ t('common.required') }` }
          placeholder={ t('user.birthdate.placeholder') }
          onChange={ handleChangeBirthdate }
          isError={ !!errorsProps['birthDate'] }
          disabled={ isBeneficiary }
        />
        { (errorsProps?.['birthDate']?.error) && (
          <Help
            textColor={ errorsProps['birthDate']?.error?.textColor }
            text={ errorsProps['birthDate']?.error?.text || '' }
          />
        ) }
      </div>

      <h2 className="label-title">{ t('groupClasses.groupClass.detailed.address.address') }</h2>

      <div className="block-flex block-flex-country">
        <AdvancedSelectField
          name="country"
          label={ t('user.address.country') }
          options={ countryOptions }
          isClearable={ false }
          className="input-personal-form country"
          value={ countryValue }
          onChange={ handleChangeAddress }
          color={ errorsProps['address.country']?.color }
          labelProps={ errorsProps['address.country']?.labelProps }
          helpProps={ errorsProps['address.country']?.error }
          isSearchable={ false }
          required
          isDisabled={ isBeneficiary || groupClass }
        />

        <div className="field input-personal-form address.city address.country">
          { isBeneficiary ? (
            <InputField
              name="autoCompleteAddress"
              value={ autoCompleteAddress?.label }
              isDisabled
            />
          ) : (
            <>
              <Label
                htmlFor="google-autocomplete"
                className={ clsx('g-label is-flex', errorsProps?.['address.postalCode']?.labelProps?.className || errorsProps?.['address.city']?.labelProps?.className) }
              >
                { t('groupClasses.groupClass.detailed.address.postalCode') } { t('common.required') }
              </Label>
              <div className="control">
                { countryValue?.value === FRANCE ? (
                  <AdvancedSelectField
                    name="city"
                    placeholder={ t('groupClasses.searchFieldPlaceholder') }
                    options={ suggestCities }
                    isClearable
                    value={ selectedCity }
                    className="search-field-input-map g-form-field"
                    onInputChange={ (e) => onChangeSearch(e) }
                    onChange={ (e) => handleChangeAddressAutoComplete(e) }
                    isSearchable={ true }
                    onFocus={ () => setSuggestCities(null) }
                  />
                )
                  : (
                    <GooglePlacesAutocomplete
                      apiKey={ GOOGLE_MAPS_API_KEY }
                      debounce={ 250 }
                      id="google-map-script"
                      name="google-autocomplete"
                      minLengthAutocomplete={ 3 }
                      apiOptions={ {
                        language: language,
                        libraries: ['places'],
                      } }
                      selectProps={ {
                        className: 'g-form-field',
                        placeholder: t('groupClasses.groupClass.peoples.form.searchFieldPlaceholder'),
                        isClearable: true,
                        onChange: handleChangeAddressAutoComplete,
                        value: autoCompleteAddress,
                        loadingMessage:() => t('common.loading'),
                        noOptionsMessage: () => t('common.noOptions'),
                        styles: {
                          control: (provided) => ({
                            ...provided,
                            border: `1px solid ${ errorsProps?.['address.postalCode']?.color === 'is-danger' || errorsProps?.['address.city']?.color === 'is-danger' ? errorColor : inputBorderColor }`,
                            ':hover': {
                              borderColor: errorsProps?.['address.postalCode']?.color === 'is-danger' || errorsProps?.['address.city']?.color === 'is-danger' ? errorColor : inputBorderColor,
                            },
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            color: errorsProps?.['address.postalCode']?.color === 'is-danger' || errorsProps?.['address.city']?.color === 'is-danger' ? errorColor : provided.color,
                          }),
                        },
                      } }
                      autocompletionRequest={ {
                        types: ['(regions)'],
                        componentRestrictions: {
                          country: countryValue?.value || 'fr',
                        },
                      } }
                    />
                  ) }
                { (errorsProps?.['address.postalCode']?.error || errorsProps?.['address.city']?.error) && (
                  <Help
                    textColor={ errorsProps?.['address.postalCode']?.error?.textColor || errorsProps?.['address.city']?.error?.textColor }
                    text={ errorsProps?.['address']?.error?.text || errorsProps?.['address.postalCode']?.error?.text || errorsProps?.['address.city']?.error?.text || '' }
                  />
                ) }
              </div>
            </>
          ) }
        </div>
      </div>

      <h2 className="label-title">{ t('groupClasses.groupClass.peoples.form.peopleContact') }</h2>
      <div className="block-flex contact">
        <InputField
          name="email"
          label={ t('login.email') }
          className="field half-width input-personal-form email contact"
          color={ errorsProps.email?.color }
          labelProps={ errorsProps.email?.labelProps }
          helpProps={ errorsProps.email?.error }
          value={ email }
          onChange={ handleChange }
          isDisabled={ isBeneficiary }
        />
        <div className="text-and-or">
          <p className="text">{ t('payment.periodSeperationText') }</p>
        </div>
        <div className="block-flex bloc-phone half-width">
          <PhoneField
            inputName="mobilePhone"
            inputProps={ { name: 'mobilePhone' } }
            selectName="countrymobilePhone"
            inputLabel={ t('user.mobilePhone') }
            className="field input-personal-form mobilePhone phone-control"
            selectClassName="country-phone"
            inputClassName="phone-input"
            selectPlaceHolder={ t('user.countryCode') }
            color={ errorsProps.mobilePhone?.color }
            labelProps={ errorsProps.mobilePhone?.labelProps }
            helpProps={ errorsProps.mobilePhone?.error }
            value={ mobilePhone || '' }
            onChange={ handleMobileChange }
            isDisabled={ isBeneficiary }
            groupClass={ groupClass }
          />
          <PhoneField
            inputName="landlinePhone"
            selectName="countryLandlinePhone"
            inputLabel={ t('user.landlinePhone') }
            inputProps={ { name: 'landlinePhone' } }
            className="field input-personal-form landlinePhone phone-control"
            selectClassName="country-phone"
            inputClassName="phone-input"
            selectPlaceHolder={ t('user.countryCode') }
            color={ errorsProps.landlinePhone?.color }
            labelProps={ errorsProps.landlinePhone?.labelProps }
            helpProps={ errorsProps.landlinePhone?.error }
            value={ landlinePhone || '' }
            onChange={ handlelandlineChange }
            isDisabled={ isBeneficiary }
            groupClass={ groupClass }
          />
        </div>
      </div>
      { errorsProps['contact'] && (
        <div className="error-contact">
          <IconWarning className="contact-error-icon" />
          <p className={ 'error-contact-text' }>{ errorsProps['contact'].error?.text || '' }</p>
        </div>
      ) }

      <CheckboxField
        className="checkbox-label"
        name="authorizeData"
        isDisabled={ !!user?.id }
        checked={ authorizeData }
        color={ errorsProps.authorizeData?.color }
        helpProps={ errorsProps.authorizeData?.error }
        labelProps={ errorsProps.authorizeData?.labelProps }
        onChange={ toggleAuthorizeData }
        required
      >
        { t('groupClasses.groupClass.peoples.form.authorizeData') }
      </CheckboxField>
    </form>
  );
};

export default PersonalForm;
