// @flow
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import withGroupClass from '@hoc/withGroupClass';

import AdminLayout from '@layout/components/AdminLayout';

import BankDepositLine from '@payment/components/BankDepositLine';
import Loader from '_common/components/loader/Loader';
import BankDepositService, { type BankDepositServiceData } from '@payment/services/BankDepositService';
import NavigationPills from '@shared/Navigation/NavigationPills';

import type { GroupClassDetailed } from '@groupClass/types';
import type { BankDeposit } from '@payment/types';

import { getPaymentNavigationTabs, DEPOSIT_SELECTED } from '@payment/constants';
import { LOADER_TYPE_BACKGROUND } from '_common/components/loader/constants';
import { WEB_PATHS } from '@app/constants/paths';
import { ADMIN_HEADER_PAYMENTS } from '@layout/constants';

const { PAYMENTS_BANK_DEPOSITS_ADD } = WEB_PATHS;

type Props = {
  groupClass: GroupClassDetailed | null,
};

const PaymentDeposit = (props: Props): React$Node => {
  const { groupClass } = props;
  const { t } = useTranslation();

  const [ bankDeposits, setBankDeposits ] = useState<BankDeposit[]>(BankDepositService.deposits);
  const [ isLoading, setIsLoading ] = useState<boolean>(BankDepositService.isLoading);

  const handleUpdateDeposit = (data: BankDepositServiceData): void => {
    const depositsArraySortedByDate = data.deposits.sort((a,b) => b.depositDate - a.depositDate);
    setBankDeposits(depositsArraySortedByDate);
    setIsLoading(data.isLoading);
  };

  useEffect((): Function => {
    const unsubscribe = BankDepositService.onChange(handleUpdateDeposit);
    return () => {
      unsubscribe();
      BankDepositService.reset();
    };
  }, []);

  useEffect((): void => {
    if (groupClass) {
      const params = { groupClassId: groupClass.id };
      BankDepositService.fetchAll(params);
    }
  }, [groupClass]);

  return (
    <AdminLayout groupClass={ groupClass } activeTab={ ADMIN_HEADER_PAYMENTS } preventPortraitMode>
      <div className="payment-container payment-deposit container">
        <div className="payment-container-header payment-deposit-header">
          { groupClass && (
            <>
              <NavigationPills navigationTabs={ getPaymentNavigationTabs(groupClass.id) } isSelectedKey={ DEPOSIT_SELECTED } />
              <Link
                to={ PAYMENTS_BANK_DEPOSITS_ADD.replace(':classId', String(groupClass.id)) }
                className="button button-secondary"
              >
                { t('payment.addBankDeposit') }
              </Link>
            </>
          ) }
        </div>
        <div className="table-payment-container border-table-deposit rounded-corners">
          { isLoading ? (
            <Loader loaderType={ LOADER_TYPE_BACKGROUND } />
          ) : (
            <table className="table border-table-deposit">
              <colgroup>
                <col className="table-cell-width" />
                <col className="table-cell-width" />
                <col className="table-cell-width" />
                <col className="table-cell-width-actions" />
              </colgroup>
              <thead className="table-head border-table-deposit">
                <tr>
                  <th className="table-head-title table-head-title-deposit table-head-title-date table-head-border-left">
                    { t('payment.date') }
                  </th>
                  <th className="table-head-title table-head-title-deposit cell-text-centered">
                    { t('payment.depositNumber') }
                  </th>
                  <th className="table-head-title table-head-title-deposit cell-text-centered">
                    { t('payment.totalAmount') }
                  </th>
                  <th className="table-head-title table-head-title-deposit cell-text-centered table-head-border-right">
                    { t('payment.actions') }
                  </th>
                </tr>
              </thead>
              <tbody className="table-body">
                { !isLoading && bankDeposits.length === 0 && (
                  <tr>
                    <td colSpan="4" className="cell-text-centered">
                      { t('payment.noDeposits') }
                    </td>
                  </tr>
                ) }

                { !isLoading && groupClass && bankDeposits.map((bankDeposit, index) => (
                  <BankDepositLine bankDeposit={ bankDeposit } groupClass={ groupClass } index={ index } key={ bankDeposit.id } />
                )) }
              </tbody>
            </table>
          ) }
        </div>
      </div>
    </AdminLayout>
  );
};

export default withGroupClass(PaymentDeposit);
