// @flow
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import withGroupClass from '@hoc/withGroupClass';

import ParticipantsLayout from '@layout/components/ParticipantsLayout';

import ParticipantEditPerson from '@participant/components/ParticipantEditPerson';
import BeneficiaryEditPerson from '@beneficiary/components/BeneficiaryEditPerson';

import RegisteredsService, { type RegisteredsServiceData } from '@groupClass/services/RegisteredsService';

import type { GroupClassDetailed, UserRegistered } from '@groupClass/types';

import { LOADER_TYPE_PAGE } from '_common/components/loader/constants';

type UseParamProps = {
  registeredId: string,
};

type Props = {
  groupClass: GroupClassDetailed | null,
};

export const RegisteredEditPerson = (props: Props): React$Node => {
  const { groupClass } = props;
  const { registeredId } = useParams<UseParamProps>();
  const { i18n: { language } } = useTranslation();

  const [ loading, setLoading ] = useState<boolean>(RegisteredsService.isLoadingRegistered);
  const [ registered, setRegistered ] = useState<UserRegistered | null>(RegisteredsService.groupClassRegistered);

  const handleUpdateState = (data: RegisteredsServiceData): void => {
    setLoading(data.isLoadingRegistered);
    setRegistered(data.groupClassRegistered);
  };

  useEffect(() => RegisteredsService.onChange(handleUpdateState), []);

  useEffect(() => {
    RegisteredsService.fetchOne(parseInt(registeredId, 10), language);
  }, [registeredId, language]);

  const isBeneficiary = useMemo((): boolean => {
    if (registered && !loading) {
      return registered.person.type === 'beneficiary';
    }
    return false;
  }, [registered, loading]);

  const isParticipant = useMemo((): boolean => {
    if (registered && !loading) {
      return registered.person.type === 'participant';
    }
    return false;
  }, [registered, loading]);

  const isLoading = useMemo((): boolean => (
    (!isBeneficiary && !isParticipant)
  ), [isBeneficiary, isParticipant]);

  return (
    <ParticipantsLayout groupClass={ groupClass } isLoading={ isLoading } loaderType={ LOADER_TYPE_PAGE }>
      { isBeneficiary && registered && (
        <BeneficiaryEditPerson registered={ registered } />
      ) }
      { isParticipant && registered && (
        <ParticipantEditPerson registered={ registered } />
      ) }
    </ParticipantsLayout>
  );
};

export default (withGroupClass(RegisteredEditPerson));
