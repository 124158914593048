const forms = {
  es: {
    formId: process.env.REACT_APP_HUBSPOT_FORM_CONTACT_ES_ID,
    url: process.env.REACT_APP_HUBSPOT_FORM_CONTACT_ES_URL,
  },
  en: {
    formId: process.env.REACT_APP_HUBSPOT_FORM_CONTACT_EN_ID,
    url: process.env.REACT_APP_HUBSPOT_FORM_CONTACT_EN_URL,
  },
  fr: {
    formId: process.env.REACT_APP_HUBSPOT_FORM_CONTACT_FR_ID,
    url: process.env.REACT_APP_HUBSPOT_FORM_CONTACT_FR_URL,
  },
};

export default forms;

