// @flow
import { Link, useLocation } from 'react-router-dom';
import { type NavLink } from '@header/types';
import { useMemo } from 'react';
import clsx from 'clsx';

const NavBarItem = (props: NavLink): React$Node => {
  const { children, to, external, target, rel, asButton, badge } = props;
  const location = useLocation();

  const navBarItemClassName = useMemo((): string => clsx({
    'navbar-item': true,
    'is-active': location.pathname === to,
    'as-button': !!asButton,
  }), [location, to, asButton]);

  if (external) {
    return (
      <a href={ to } className={ navBarItemClassName } target={ target || null } rel={ rel || null }>
        { children }
      </a>
    );
  }

  return (
    <>
      <Link to={ to } className={ navBarItemClassName }>
        { children }
        { !!badge && (<div className="tab-element-badge">{ badge }</div>) }
      </Link>
    </>
  );
};

export default NavBarItem;
