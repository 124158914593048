// @flow
import { useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment/dist/moment';

import CreatableSelectField from '@shared/CreatableSelectField/components/CreatableSelectField';
import Modal from '@shared/Modal/Modal';
import Button, { buttonConstants } from '@shared/Button';
import AdvancedSelectField from '@shared/AdvancedSelectField/components/AdvancedSelectField';
import InputField from '_common/components/input-field/InputField';
import Loader from '_common/components/loader/Loader';
import IconCircleXMark from '@icons/components/IconCircleXMark';

import RegisteredsService, { type RegisteredsServiceData } from '@groupClass/services/RegisteredsService';
import PaymentChequeService, { type PaymentChequeServiceData } from '@payment/services/PaymentChequeService';
import BankDepositService from '@payment/services/BankDepositService';

import TranslationHelpers, { ERROR_MISSING_OR_INVALID, ERROR_IS_BLANK, ERROR_NOT_EQUAL_LENGTH } from '@helpers/TranslationHelpers';
import { debounce } from '_common/services/CommonUtils';
import { emptyPaymentCheque, amountFormatter, createOptionFromPayload } from '@payment/helpers/PaymentChequeUtils';
import { templatePaymentScheduleFromGroupClass } from '@groupClass/helpers/PeriodUtils';
import { findTheNearestPaymentSchedule } from '@payment/helpers/PeriodPaymentUtils';
import ObjectHelpers from '@helpers/ObjectHelpers';

import type { GroupClassDetailed } from '@groupClass/types';
import type { PaymentChequeFormData, BankDepositDetailed, PaymentCheque } from '@payment/types';
import type { Error, Option, ErrorProps } from '@core/types';
import type { PaymentSchedule, ShortRegistered, UserRegistered } from '@groupClass/types';

import { errorColor } from '@app/constants/styles';
import { RE_2_DIGITS_COMMA_DOT, RE_7_DIGITS } from '@helpers/Regex';

type Props = {
  isActiveModalAddCheque: boolean,
  toggleModalAddCheque: () => void,
  showSuccessMessage: (message: string) => void,
  addChequeSuccess: Function,
  groupClass: GroupClassDetailed | null,
  initialBankDeposit: BankDepositDetailed | null,
  initialBankDepositReference: string | null,
  initialBankDepositDate: string | null,
};

type ActionMetaType = { action: string, name: string, option?: string };

const fetchChequesDebounced = debounce(PaymentChequeService.fetchAll, 150);

const ModalAddChequeToBankDeposit = (props: Props): React$Node => {
  let { initialBankDeposit } = props;
  const {
    isActiveModalAddCheque,
    toggleModalAddCheque,
    showSuccessMessage,
    addChequeSuccess,
    groupClass,
    initialBankDepositReference,
    initialBankDepositDate,
  } = props;
  const { t, i18n: { language } } = useTranslation();

  const [ isLoading, setLoading ] = useState<boolean>(RegisteredsService.isLoadingRegistereds);
  const [ groupClassRegistered, setGroupClassRegistered ] = useState<UserRegistered | null>(RegisteredsService.groupClassRegistered);
  const [ groupClassesRegistereds, setGroupClassesRegistereds ] = useState<ShortRegistered[]>(RegisteredsService.groupClassRegistereds);
  const [ registeredSelected, setRegisteredSelected ] = useState<Option | null>(null);
  const [ isRegisteredLoading, setRegisteredLoading ] = useState<boolean>(RegisteredsService.isLoadingRegistered);
  const [ paymentSchedulesOptions, setPaymentSchedulesOptions ] = useState<Option[]>([]);
  const [ paymentSchedule, setPaymentSchedule ] = useState<Option | null>(null);
  const [ newPaymentCheque, setNewPaymentCheque ] = useState<PaymentChequeFormData | PaymentCheque>(emptyPaymentCheque);
  const [ isLoadingCheques, setIsLoadingCheques ] = useState<boolean>(PaymentChequeService.isLoading);
  const [ paymentChequeFetched, setPaymentChequeFetched ] = useState<PaymentCheque | null>(null);
  const [ errors, setErrors ] = useState<Error[]>([]);
  const [ isDisabled, setIsDisabled ] = useState<boolean>(true);

  const [ selectOption, setSelectOption ] = useState<Option | null>(null);
  const [ selectOptions, setSelectOptions ] = useState<Option[]>([]);

  const resetComponent = useCallback((): void => {
    PaymentChequeService.reset();
    setGroupClassRegistered(null);
    setRegisteredSelected(null);
    setPaymentSchedulesOptions([]);
    setPaymentSchedule(null);
    setNewPaymentCheque(emptyPaymentCheque);
    setErrors([]);
    setSelectOption(null);
    setSelectOptions([]);
    setIsDisabled(true);
    setPaymentChequeFetched(null);
  }, []);

  const fetchChequesOptions = useCallback((reference: string): void => {
    if (reference.length > 2) {
      fetchChequesDebounced({ reference });
    } else {
      setSelectOptions([]);
    }
  }, [fetchChequesDebounced, setSelectOptions]);

  const getCreateLabelText = useCallback((chequeRef: string): string => (
    t('payment.createChequeNumber', { chequeRef })
  ), [t]);

  const handleOptionCreate = useCallback((reference: string): void => {
    const createdPaymentCheque = { ...emptyPaymentCheque, reference };
    setIsDisabled(false);

    setNewPaymentCheque(createdPaymentCheque);

    setSelectOption({
      label: reference,
      value: '',
    });

  }, []);

  const handleUpdatePaymentCheque = useCallback((data: PaymentChequeServiceData): void => {
    setIsLoadingCheques(data.isLoading);
    setErrors(data.errors);
    if (data.cheques && groupClass) {
      setSelectOptions(
        data.cheques
          .filter((cheque) => cheque.groupClass.id === groupClass.id && cheque.groupClassRegisteredPaymentSchedule.period.validatedAt === null && cheque.bankDeposit === null)
          .map((cheque) => createOptionFromPayload(cheque))
          .sort((a, b) => a.label.localeCompare(b.label)),
      );
    }
    if (data.cheque) {
      setSelectOption({
        label: data.cheque.reference,
        value: data.cheque.id.toString(),
      });
      setPaymentChequeFetched(data.cheque);
    }
  }, [
    groupClass,
  ]);

  // When the user select an existing paymentCheque
  useEffect((): void => {
    if (selectOption && paymentChequeFetched) {
      setNewPaymentCheque(paymentChequeFetched);
      const registeredSelected = groupClassesRegistereds.find((registered) => registered.person.id === paymentChequeFetched.person.id);
      if (registeredSelected) {
        setRegisteredSelected({
          label: `${ registeredSelected.person.lastName.toUpperCase() } ${ registeredSelected.person.firstName }`,
          value: registeredSelected.person.id.toString(),
        });
      }
      setPaymentSchedule({
        label: periodLabel(paymentChequeFetched.groupClassRegisteredPaymentSchedule, language),
        value: paymentChequeFetched.groupClassRegisteredPaymentSchedule.id.toString(),
      });
    }
  }, [selectOption, groupClassesRegistereds, paymentChequeFetched]);

  const isValidNewOption = useCallback((value): boolean => (
    RE_7_DIGITS.test(value)
  ), []);

  useEffect(() => PaymentChequeService.onChange(handleUpdatePaymentCheque), []);

  const periodLabel = useCallback((paymentSchedule: PaymentSchedule | null, language: string): string => {
    if (paymentSchedule && groupClass) {
      const templateIndex = templatePaymentScheduleFromGroupClass(paymentSchedule, groupClass);
      const startDate = moment(paymentSchedule.period.startDate).locale(language).format('L');
      const endDate = moment(paymentSchedule.period.endDate).locale(language).format('L');
      return `${ t('payment.period') } (${ templateIndex })${ t('common.colons') } ${ startDate } - ${ endDate } (${ paymentSchedule.amount }€)`;
    }
    return '';
  }, [t, groupClass]);

  useEffect((): void => {
    resetComponent();
  }, [isActiveModalAddCheque]);

  const handleRegisteredUpdateState = (data: RegisteredsServiceData): void => {
    setLoading(data.isLoadingRegistereds);
    setGroupClassRegistered(data.groupClassRegistered);
    setGroupClassesRegistereds(data.groupClassRegistereds);
    setRegisteredLoading(data.isLoadingRegistered);
    setErrors(data.errors);
  };

  useEffect(() => RegisteredsService.onChange(handleRegisteredUpdateState), []);

  useEffect((): void => {
    if (groupClass) {
      RegisteredsService.fetchGroupClassRegistereds(groupClass.id, language, { pagination: false, 'order[person.lastName]': 'asc' });
    }
  }, [groupClass, language]);

  useEffect((): void => {
    if (registeredSelected && selectOption?.value === '') {
      RegisteredsService.fetchOne(registeredSelected.value, language);
    }
  }, [registeredSelected, language, selectOption]);

  // When the user select a registered
  useEffect((): void => {
    if (groupClassRegistered && newPaymentCheque.groupClassRegisteredPaymentSchedule === null) {
      const paymentSchedulesFiltered = (groupClassRegistered.paymentSchedules || [])
        .filter((paymentSchedule) => paymentSchedule.period.validatedAt === null)
        .reverse();

      setPaymentSchedulesOptions(paymentSchedulesFiltered
        .map((paymentSchedule) => ({
          label: periodLabel(paymentSchedule, language),
          value: paymentSchedule.id ? paymentSchedule.id.toString() : '',
        })),
      );

      const nearestPaymentSchedule = findTheNearestPaymentSchedule(paymentSchedulesFiltered);
      if (nearestPaymentSchedule) {
        setPaymentSchedule({
          label: periodLabel(nearestPaymentSchedule, language),
          value: nearestPaymentSchedule.id.toString(),
        });
        const newPaymentChequeUpdated = ObjectHelpers.deepClone(newPaymentCheque);
        newPaymentChequeUpdated.groupClassRegisteredPaymentSchedule = nearestPaymentSchedule.id.toString();
        setNewPaymentCheque(newPaymentChequeUpdated);
      }
    }
  }, [groupClassRegistered, newPaymentCheque, language]);

  const handleChange = useCallback((e: SyntheticEvent<HTMLInputElement>): void => {
    const { name, value } = e.currentTarget;
    if (name === 'amount' && (value.match(RE_2_DIGITS_COMMA_DOT) || value === '')) {
      setNewPaymentCheque({ ...newPaymentCheque, amount: value });
      const newErrors = errors.filter((error) => error.propertyPath !== 'amount');
      setErrors(newErrors);
    }
  }, [
    setNewPaymentCheque,
    newPaymentCheque,
    errors,
    setErrors,
  ]);

  const validateAndSubmit = useCallback((closeModal: boolean): void => {
    const formErrors = [];
    if (newPaymentCheque.id) {
      if (initialBankDeposit !== null && initialBankDeposit?.paymentCheques.findIndex((paymentCheque) => paymentCheque.reference === newPaymentCheque.reference) !== -1) {
        formErrors.push({ propertyPath: 'existingCheque', code: ERROR_MISSING_OR_INVALID, message: '' });
        setErrors(formErrors);
      }

      if (formErrors.length === 0) {
        new Promise((resolve) => {
          if (initialBankDeposit === null ) {
            resolve(BankDepositService.create(initialBankDepositReference, initialBankDepositDate, false)
              .then((result) => {
                initialBankDeposit = result;
                resolve();
              }),
            );
          }
          resolve();
        })
          .then(() => {
            PaymentChequeService.update(
              newPaymentCheque.id,
              newPaymentCheque.reference,
              newPaymentCheque.groupClassRegisteredPaymentSchedule.id,
              newPaymentCheque.amount,
              initialBankDeposit.id,
            ).then(() => BankDepositService.fetchOne(initialBankDeposit.id));
          })
          .then(() => {
            showSuccessMessage('payment.paymentAdded');
            if (closeModal) {
              toggleModalAddCheque();
            } else {
              resetComponent();
            }
          })
          .catch(() => {
            setErrors(PaymentChequeService.errorValues);
          });
      }
    }

    if (!newPaymentCheque.id) {
      if (!registeredSelected) {
        formErrors.push({ propertyPath: 'name', code: ERROR_IS_BLANK, message: '' });
      }

      if (!newPaymentCheque.groupClassRegisteredPaymentSchedule) {
        formErrors.push({ propertyPath: 'groupClassRegisteredPaymentSchedule', code: ERROR_IS_BLANK, message: '' });
      }

      if (!newPaymentCheque.reference) {
        formErrors.push({ propertyPath: 'reference', code: ERROR_IS_BLANK, message: '' });
      }

      if (newPaymentCheque.reference && !RE_7_DIGITS.test(newPaymentCheque.reference)) {
        formErrors.push({ propertyPath: 'reference', code: ERROR_NOT_EQUAL_LENGTH, message: '' });
      }

      if (!newPaymentCheque.amount) {
        formErrors.push({ propertyPath: 'amount', code: ERROR_IS_BLANK, message: '' });
      }

      const chequeAmount = amountFormatter(newPaymentCheque.amount);

      if (chequeAmount <= 0) {
        formErrors.push({ propertyPath: 'amount', code: ERROR_IS_BLANK, message: '' });
      }

      setErrors(formErrors);
      if (formErrors.length === 0) {
        new Promise((resolve) => {
          resolve(PaymentChequeService.create(
            newPaymentCheque.reference,
            newPaymentCheque.groupClassRegisteredPaymentSchedule,
            chequeAmount,
            initialBankDeposit.id,
          ));
        })
          .then((res) => {
            showSuccessMessage('payment.paymentAdded');
            BankDepositService.fetchOne(initialBankDeposit.id);
            addChequeSuccess(res);
            if (closeModal) {
              toggleModalAddCheque();
            } else {
              resetComponent();
            }
          })
          .catch(() => {
            setErrors(PaymentChequeService.errorValues);
          });
      }
    }
  }, [isActiveModalAddCheque, newPaymentCheque, registeredSelected, addChequeSuccess]);

  const registeredOptions: Option[] = groupClassesRegistereds?.map((registered): Option => ({
    label: `${ registered.person.lastName.toUpperCase() } ${ registered.person.firstName.charAt(0).toUpperCase() + registered.person.firstName.slice(1) }`,
    value: registered.id.toString(),
  }));

  const handleChangeSelectReference = useCallback((newValue, actionMeta: ActionMetaType): void => {
    if (newValue !== null) {
      const selected: Option = Array.isArray(newValue) ? newValue[0] : newValue;
      if (actionMeta.action === 'select-option') {
        if (selected.value) {
          PaymentChequeService.fetchOne(parseInt(selected.value, 10));
        }
      }

      if (actionMeta.action === 'clear') {
        resetComponent();
      }
    }
  }, [resetComponent]);

  const handleChangeSelectName = useCallback((newValue): void => {
    if (newValue !== null) {
      const selected: Option = Array.isArray(newValue) ? newValue[0] : newValue;
      setRegisteredSelected({
        label: selected.label,
        value: selected.value,
      });
      setNewPaymentCheque({ ...newPaymentCheque, groupClassRegisteredPaymentSchedule: null });
      setGroupClassRegistered(null);
    }
  }, [
    newPaymentCheque,
    setRegisteredSelected,
    setNewPaymentCheque,
    setGroupClassRegistered,
  ]);

  const handleChangeSelectPaymentSchedule = useCallback((newValue): void => {
    if (newValue !== null) {
      const selected: Option = Array.isArray(newValue) ? newValue[0] : newValue;
      setPaymentSchedule({
        label: selected.label,
        value: selected.value,
      });
      setNewPaymentCheque({ ...newPaymentCheque, groupClassRegisteredPaymentSchedule: selected.value });
    }
  }, [
    setPaymentSchedule,
    setNewPaymentCheque,
    newPaymentCheque,
    groupClassRegistered,
  ]);

  const errorsProps = useMemo((): ErrorProps => {
    let errorsPropsTmp = {};
    errors.forEach((error) => {
      Object.assign(errorsPropsTmp, {
        [`${ error.propertyPath }`]: {
          color: 'is-danger',
          borderColor: errorColor,
          labelProps: { className: 'has-text-danger' },
          labelTextAreaProps: {
            className: 'label has-text-danger',
          },
          selectFieldHelperText: { text: t(TranslationHelpers.getCommonErrorKeyByCode(error.code, error.propertyPath)), textColor: 'is-danger' },
          error: {
            text: t(TranslationHelpers.getCommonErrorKeyByCode(error.code, error.propertyPath)),
            textColor: 'is-danger',
          },
        },
      });
    });
    return errorsPropsTmp;
  }, [errors]);

  const footerModalAddCheque: React$Node = useMemo((): React$Node => (
    <footer className="modal-cheque-footer">
      <Button
        type={ buttonConstants.PRIMARY }
        className="button-modal"
        onClick={ () => validateAndSubmit(true) }
      >
        { t('payment.validAndExit') }
      </Button>
      <Button
        type={ buttonConstants.TERTIARY }
        className="button-modal"
        onClick={ () => validateAndSubmit(false) }
        isOutlined
      >
        { t('payment.validAndNextCheque') }
      </Button>

    </footer>
  ), [validateAndSubmit, language]);

  return (
    <Modal
      isActive={ isActiveModalAddCheque }
      isClipped
      onClose={ toggleModalAddCheque }
      title={ t('payment.addCheque') }
      footer={ footerModalAddCheque }
    >
      <div className="modal-cheque-container">
        { isLoading
          ? (
            <div className="field">
              <Loader />
            </div>
          )
          : (
            <>
              <CreatableSelectField
                id="reference"
                name="reference"
                label={ t('payment.chequeNumber') }
                value={ selectOption }
                options={ selectOptions }
                onChange={ handleChangeSelectReference }
                onInputChange={ fetchChequesOptions }
                placeholder={ t('payment.bankDepositNumberPlaceholder') }
                isClearable
                allowCreateWhileLoading={ false }
                createOptionPosition="last"
                formatCreateLabel={ getCreateLabelText }
                isLoading={ isLoadingCheques }
                isValidNewOption={ isValidNewOption }
                onCreateOption={ handleOptionCreate }
                borderColor={ errorsProps.reference?.borderColor }
                color={ errorsProps.reference?.color }
                labelProps={ errorsProps.reference?.labelProps }
                helpProps={ errorsProps.reference?.error }
                className="field-modal"
                required
              />
              <AdvancedSelectField
                id="name"
                name="name"
                label={ t('payment.name') }
                options={ registeredOptions }
                placeholder={ t('payment.namePlaceholder') }
                onChange={ handleChangeSelectName }
                isClearable={ false }
                value={ registeredSelected }
                color={ errorsProps.name?.color }
                labelProps={ errorsProps.name?.labelProps }
                borderColor={ errorsProps.name?.borderColor }
                helpProps={ errorsProps.name?.selectFieldHelperText }
                className="field-modal"
                isDisabled={ isDisabled }
                isSearchable={ false }
                required
              />

              { isRegisteredLoading
                ? (
                  <div className="field registereds-loading">
                    <Loader />
                  </div>
                )
                : (
                  <AdvancedSelectField
                    id="groupClassRegisteredPaymentSchedule"
                    name="groupClassRegisteredPaymentSchedule"
                    label={ t('payment.period') }
                    options={ paymentSchedulesOptions }
                    onChange={ handleChangeSelectPaymentSchedule }
                    placeholder={ t('payment.namePlaceholder') }
                    isClearable={ false }
                    value={ paymentSchedule }
                    color={ errorsProps.groupClassRegisteredPaymentSchedule?.color }
                    labelProps={ errorsProps.groupClassRegisteredPaymentSchedule?.labelProps }
                    borderColor={ errorsProps.groupClassRegisteredPaymentSchedule?.borderColor }
                    helpProps={ errorsProps.groupClassRegisteredPaymentSchedule?.selectFieldHelperText }
                    className="field-modal"
                    isDisabled={ isDisabled }
                    isSearchable={ false }
                    required
                  />
                )
              }

              <InputField
                id="amount"
                name="amount"
                label={ t('payment.chequeAmount') }
                placeholder={ t('payment.chequeAmountPlaceholder') }
                onChange={ handleChange }
                value={ newPaymentCheque.amount ? newPaymentCheque.amount : '' }
                color={ errorsProps.amount?.color }
                labelProps={ errorsProps.amount?.labelProps }
                helpProps={ errorsProps.amount?.error }
                type="number"
                step={ 1 }
                className="field-modal"
                isDisabled={ isDisabled }
                required
              />

              { errorsProps?.existingCheque?.error && (
                <div className="existingCheque">
                  <IconCircleXMark />
                  { errorsProps.existingCheque.error.text }
                </div>
              ) }
            </>
          ) }
      </div>
    </Modal>
  );
};

ModalAddChequeToBankDeposit.defaultProps = {
  addChequeSuccess: () => {},
};

export default ModalAddChequeToBankDeposit;
