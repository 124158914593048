// @flow
import IconInfos from '@icons/components/IconInfos';
import { useCallback, useState } from 'react';

type Props = {
  text: string,
  cta: any,
};
const HeaderBanner = (props: Props): React$Node => {
  const { text, cta } = props;
  const [show, setShow] = useState(true);

  const closeBanner = useCallback((): void => {
    setShow(false);
  }, [show] );

  return (
    <>
      { show && (
        <div className="header-banner">
          <div className="content">
            <div className="icon">
              <IconInfos />
            </div>
            <div className="text">
              { text }
              { cta }
            </div>
          </div>
          <div className="close" onClick={ () => closeBanner() }></div>
        </div>
      ) }
    </>
  );
};

export default HeaderBanner;
