// @flow
import DocumentHelpers from '@helpers/DocumentHelpers';

/* eslint-disable no-bitwise */
export function guid(): string {
  // source : https://blogs.cozi.com/tech/2010/04/generating-uuids-in-javascript.html
  const uuid = new Array(36);
  const data = [
    Math.floor(0x100000000 * Math.random()) & 0xffffffff,
    (Math.floor(0x100000000 * Math.random()) & 0xffff0fff) | (4 << 12), // version (1-5)
    (Math.floor(0x100000000 * Math.random()) & 0x3fffffff) | 0x80000000, // rfc 4122 variant
    Math.floor(0x100000000 * Math.random()) & 0xffffffff,
    new Date().getTime(),
  ];
  for (let i = 0, k = 0; i < 5; i++) {
    let rnd = data[i];
    for (let j = 0; j < 8; j++) {
      if (k === 8 || k === 13 || k === 18 || k === 23) {
        uuid[k++] = '-';
      }
      const r = (rnd >>> 28) & 0xf; // Take the high-order nybble
      rnd = (rnd & 0x0fffffff) << 4;
      uuid[k++] = '0123456789abcdef'.charAt(r);
    }
  }
  return uuid.join('');
}

export const isFunction = (value: any): boolean => (
  value && typeof value === 'function'
);

export const uppercaseFirst = (string: string): string => (
  string.charAt(0).toUpperCase() + string.slice(1)
);

const getErrorElements = (inputElementArray: HTMLCollection<HTMLElement>, errors: Array<Object>): Object => {
  const errorsFound = Array.from(inputElementArray).filter((el) => (
    errors.find((f) => (el.className.includes(f)))
  ));
  return errorsFound[0];
};

export const scrollToFirstErrorElement = (elementsArray: HTMLCollection<HTMLElement>, errors: Array<Object>): void => {
  if (!errors.length) return;
  const errorsFormatted = errors.map((error) => error.propertyPath.split('[')[0]);
  const errorElement = getErrorElements(elementsArray, errorsFormatted);
  if (errorElement) DocumentHelpers.scrollToElement(errorElement, 0);
};

export const isValidUrl = (string: string): boolean => {
  try {
    let url = new URL(string);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (_) {
    return false;
  }
};

export const debounce = (fn: Function, delay: number = 500): Function => {
  let timer;
  return (...args: any): void => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, args);
      timer = null;
    }, delay);
  };
};
