// @flow
import { useTranslation } from 'react-i18next';
import moment from 'moment/dist/moment';

import type { Period } from '@groupClass/types';

type Props = {
  periods: Period[],
};

const PeriodTable = (props: Props): React$Node | null => {
  const { periods } = props;
  const { t, i18n: { language } } = useTranslation();
  const formatNumber = Intl.NumberFormat(language, { minimumFractionDigits: 2 });

  if (!periods?.length) {
    return null;
  }

  return periods.map((period: Period, index: number): React$Node => {
    let statusPeriod = '';
    if (period.validatedAt) {
      statusPeriod = `(${ t('groupClasses.validated') })`;
    }
    if (period.archivedAt) {
      statusPeriod = `(${ t('groupClasses.archived') })`;
    }
    const startDate = moment(period.startDate).locale(language).format('L');
    const endDate = moment(period.endDate).locale(language).format('L');

    return (
      <div key={ period.id } className="period-table">
        <div className="columns">
          <div className="column is-3-desktop">
            <div className="period-title period-table-title">
              { `${ t('payment.period') } ${ index + 1 } ${ statusPeriod }${ t('common.required') }` }
            </div>
          </div>
          <div className="column is-3-desktop">
            <div className="period-value-value">{ startDate }</div>
          </div>
          <div className="column is-3-desktop">
            <div className="period-value-value">{ endDate }</div>
          </div>
          <div className="column is-3-desktop">
            <div className="period-price">
              <div className="period-table-title">
                { `${ t('groupClasses.groupClass.detailed.tarif') }${ t('common.required') }${ t('common.colons') }` }
              </div>
              <div className="period-price-value">{
                formatNumber.format(period.amount)
              } €</div>
            </div>
          </div>
        </div>
      </div>
    );
  });
};

export default PeriodTable;
