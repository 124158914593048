export const MAP_DEFAULT_ZOOM = 5;
export const MAP_LABEL_ANCHOR = { X: -48, Y: -55 };
export const MAP_STYLES = [];
export const SPAIN_LANGUAGE = 'es-ES';

export const MIN_ZOOM_LEVEL = 5;

export const MAP_OPTIONS = {
  clickableIcons: false,
  fullscreenControl: false,
  gestureHandling: 'greedy',
  mapTypeControl: false,
  minZoom: 3,
  scrollwheel: true,
  streetViewControl: false,
  styles: MAP_STYLES,
};

export const MARKER_STYLE = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transform: 'translate(-50%, -100%)',
};

export const DEFAULT_CENTER_POSITION = {
  lat: 46.75,
  lng: 1.7,
};

/**
 * Corner coordinates to cover the Mainland France area
 */
export const MAINLAND_FRANCE_AREA_COORDINATES = {
  topLeft: { lat: 51.3, lng: -5.15 },
  topRight: { lat: 51.3, lng: 8.25 },
  bottomLeft: { lat: 42.25, lng: -5.15 },
  bottomRight: { lat: 42.25, lng: 8.25 },
};

export const SPAIN_CENTER_POSITION = {
  lat: 39.5,
  lng: -3.3,
};
export const SPAIN_AREA_COORDINATES = {
  topLeft: { lat: 43.75, lng: -9.31 },
  topRight: { lat: 43.75, lng: 4.34 },
  bottomLeft: { lat: 36, lng: -9.31 },
  bottomRight: { lat: 36, lng: 4.34 },
};

export const DEFAULT_SIZE_CONTAINER = {
  width: '100%',
  height: 'calc(700px - 2vh)',
  marginBottom: '1rem',
  padding: 0,
  borderRadius: '10px',
  boxShadow: '0 8px 16px 5px rgb(0 0 0 / 20%)',
};

export const DEFAULT_SIZE_CONTAINER_TABLET = {
  height: 'calc(800px - 25vh)',
  padding: 0,
  borderRadius: '10px',
  width: '94%',
  boxShadow: '0 8px 16px 5px rgb(0 0 0 / 20%)',
};

export const DEFAULT_SIZE_CONTAINER_MOBILE = {
  height: '300px',
  padding: 0,
  borderRadius: '10px',
  width: '294px',
  boxShadow: '0 8px 16px 5px rgb(0 0 0 / 20%)',
};

// 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
// or you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
export const OPTIONS_MARKER_CLUSTERER = {
  imagePath:
    '__ASSET_PATH__/assets/',
  maxZoom: 18,
};
