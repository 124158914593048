// @flow
import moment from 'moment';

import type { Participant, ParticipantPayload, ShortParticipant, ShortParticipantPayload } from '@participant/types';
import type { Person } from '@person/type';
import type { UserRegistered, GroupClassDetailed } from '@groupClass/types';
import { formatAddressFromPayload, parsedAddressToPayload } from '@shared/Locations/services/AddressUtils';
import { extractShortGroupClassFromDetailed } from '@groupClass/helpers/GroupClassUtils';
import { PAYMENT_FREQUENCY_PERIOD } from '@payment/constants';
import { ERROR_IS_BLANK } from '@helpers/TranslationHelpers';

export const parseShortParticipantFromPayload = (participant: ShortParticipantPayload): ShortParticipant => ({
  id: participant.id,
  registeredId: participant.registeredId,
  firstName: participant.firstName,
  lastName: participant.lastName,
  type : 'participant',
  membershipFee: participant.membershipFee,
});

export const parseParticipantFromPayload = (participant: ParticipantPayload ): Participant => {
  const addressFormatted = formatAddressFromPayload(participant.address);
  return {
    address: addressFormatted,
    membershipFee: participant.membershipFee,
    authorizeData: participant.authorizeData,
    id: participant.id,
    email: participant.email,
    firstName: participant.firstName,
    lastName: participant.lastName,
    birthDate: new Date(participant.birthDate),
    mobilePhone: participant.mobilePhone,
    landlinePhone: participant.landlinePhone,
    sexe: participant.sexe,
    type: 'participant',
  };
};

export const parseParticipant = (participant: Participant): ParticipantPayload => {
  const birthDateValue = moment(participant.birthDate);
  const birthDateText = birthDateValue.isValid() ? birthDateValue.format('YYYY-MM-DD') : '';
  return {
    ...participant,
    address: participant.address ? parsedAddressToPayload(participant.address) : null,
    birthDate: birthDateText,
    landlinePhone: participant.landlinePhone ?? '',
    mobilePhone: participant.mobilePhone ?? '',
    authorizeData: !!participant.authorizeData,
  };
};

export const extractPersonFromParticipant = (participant: Participant): Person => ({
  id: participant.id,
  address: participant.address,
  birthDate: participant.birthDate,
  email: participant.email,
  firstName: participant.firstName,
  landlinePhone: participant.landlinePhone,
  lastName: participant.lastName,
  mobilePhone: participant.mobilePhone,
  sexe: participant.sexe,
  type: participant.type,
});

export const createRegisteredFromParticipant = (participant: Participant, groupClass: GroupClassDetailed): UserRegistered => {
  const shortGroupClass = extractShortGroupClassFromDetailed(groupClass);
  const person = extractPersonFromParticipant(participant);
  return ({
    id: null,
    person: person,
    groupClass: shortGroupClass,
    paymentFrequency: PAYMENT_FREQUENCY_PERIOD,
    paymentSchedules: [],
    paymentConditions: [],
    subscriptionPaidAt: null,
    unsubscribedAt: null,
  });
};

export const validateParticipantForm = (participant: Participant): Error[] => {
  const errors = [];
  if (!participant.firstName) {
    errors.push({ propertyPath: 'firstName', code: ERROR_IS_BLANK, message: '' });
  }
  if (!participant.lastName) {
    errors.push({ propertyPath: 'lastName', code: ERROR_IS_BLANK, message: '' });
  }
  if (!participant.birthDate) {
    errors.push({ propertyPath: 'birthDate', code: ERROR_IS_BLANK, message: '' });
  }
  if (!participant.address?.postalCode) {
    errors.push({ propertyPath: 'address.postalCode', code: ERROR_IS_BLANK, message: '' });
  }
  if (!participant.sexe) {
    errors.push({ propertyPath: 'sexe', code: ERROR_IS_BLANK, message: '' });
  }
  return errors;
};
