// @flow
import { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

type Props = {
  title: string,
  value?: string | number,
  className: string,
  classNameTitle: string,
  classNameValue: string,
  linkUrl?: string,
  hideColon: boolean,
  required: boolean,
};

const LabelInformation = (props: Props): React$Node => {
  const {
    value,
    classNameValue,
    linkUrl,
    title,
    classNameTitle,
    required,
    hideColon,
  } = props;

  const { t } = useTranslation();
  const localTitle = `${ title }${ required ? '*' : '' }`;
  const colon = !hideColon && t('common.colons');

  const goToUrl = useCallback((url: string): void => {
    window.open(url, '_blank');
  }, []);

  const handleClick = useCallback((): void => {
    if (linkUrl) goToUrl(linkUrl);
  }, [linkUrl]);

  const labelInformationTitleClassName = useMemo((): string => clsx({
    'label-information-title': true,
    'link': linkUrl,
    [String(classNameTitle)]: !!classNameTitle,
  }), [classNameTitle, linkUrl]);

  const labelInformationValueClassName = useMemo((): string => clsx({
    'label-information-value': true,
    [String(classNameValue)]: !!classNameValue,
  }), [classNameValue]);

  return (
    <>
      <div className="column is-6-desktop">
        <div className={ labelInformationTitleClassName } onClick={ handleClick }>
          { localTitle }{ colon }
        </div>
      </div>
      <div className="column is-6-desktop">
        <div className={ labelInformationValueClassName }>{ value }</div>
      </div>
    </>
  );
};

LabelInformation.defaultProps = {
  className: 'label-information',
  classNameTitle: 'label-information-title',
  classNameValue: 'label-information-value',
  hideColon: false,
  required: false,
};

export default LabelInformation;
