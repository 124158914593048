// @flow
import EventEmitter from '_common/services/EventEmitter';
import ApiService from '@api/service';

import type { Error, Listener } from '@core/types';
import type { BeneficiaryBalance, BeneficiaryBalancePayload } from '@beneficiary/types';

import { parseBeneficiaryBalanceFromPayload } from '@beneficiary/helpers/BeneficiariesUtils';

import { API_PATHS } from '@app/constants/paths';
const { BENEFICIARY_BALANCES } = API_PATHS;

export type BeneficiaryBalancesServiceData = {
  beneficiaryBalances: BeneficiaryBalance[],
  loading: boolean,
  errors: Error[],
};

type OnChange = (listener: Listener) => Function;
type FetchBalances = (beneficiaryId: number, language: string) => Promise<BeneficiaryBalance[]>;
type Trigger = () => void;

type UpdateValues = (
  newBeneficiaryBalances: BeneficiaryBalance[],
  newLoading: boolean,
  newErrors: Error[],
) => void;

class BeneficiaryBalancesService {
  constructor() {
    this.eventEmitter = new EventEmitter();
    this.beneficiaryBalances = [];
    this.errors = [];
    this.loading = false;
  }

  eventEmitter: EventEmitter;
  beneficiaryBalances: BeneficiaryBalance[];
  loading: boolean;
  errors: Error[];

  updateValues: UpdateValues = (
    newBeneficiaryBalances: BeneficiaryBalance[],
    newLoading: boolean,
    newErrors: Error[],
  ): void => {
    this.beneficiaryBalances = newBeneficiaryBalances;
    this.loading = newLoading;
    this.errors = newErrors;
    this.#trigger();
  };

  // TODO: Implements getters/setters

  fetchAll: FetchBalances = (beneficiaryId: number, language: string): Promise<BeneficiaryBalance[]> => {
    this.updateValues([], true, []);

    return ApiService.request({
      method: 'get',
      url: BENEFICIARY_BALANCES.replace(':id', String(beneficiaryId)),
      headers: {
        'Accept-Language': language,
      },
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;

          const balances: BeneficiaryBalance[] = data['hydra:member']
            .map((balance: BeneficiaryBalancePayload) => (
              parseBeneficiaryBalanceFromPayload(balance)
            ));

          this.updateValues(balances, false, []);
          return balances;
        }

        this.updateValues(this.beneficiaryBalances, false, []);
        return Promise.reject();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.violations) {
            this.updateValues(this.beneficiaryBalances, false, error.response.data.violations);
          }
        }
        this.updateValues(this.beneficiaryBalances, false, []);
        return Promise.reject();
      });
  };

  onChange: OnChange = (listener: Listener): Function => {
    const listenerFunction = this.eventEmitter.addListener(listener);
    this.#trigger();
    return listenerFunction;
  };

  /**
   * @private
   */
  #trigger: Trigger = (): void => {
    this.eventEmitter.trigger({
      beneficiaryBalances: this.beneficiaryBalances,
      loading: this.loading,
      errors: this.errors,
    });
  };
}

const instance: BeneficiaryBalancesService = new BeneficiaryBalancesService();
export default instance;
