// @flow
import type { Error, Listener, QueryParams } from '@core/types';
import type { BankDeposit, BankDepositDetailed } from '@payment/types';
import EventEmitter from '_common/services/EventEmitter';
import ApiService from '@api/service';

import { hydrateDepositFromPayload, hydrateDepositDetailedFromPayload } from '@payment/helpers/BankDepositUtils';

import { API_PATHS } from '@app/constants/paths';

export type BankDepositServiceData = {
  deposit: BankDepositDetailed | null,
  deposits: Array<BankDeposit>,
  isLoading: boolean,
  errors: Error[],
};

export type BankDepositUpdated = {
  bankDeposit: BankDepositDetailed,
  updated?: boolean,
};

type UpdateValues = (newDeposit: BankDepositDetailed | null, newDeposits: Array<BankDeposit>, newIsLoading: boolean, newErrors: Error[]) => void;
type CleanErrors = () => void;
type FetchAll = (params: QueryParams) => void;
type FetchOne = (id: number) => Promise<BankDepositDetailed>;
type Create = (reference: string, depositDate: string, paymentCheques?: Array<number>) => Promise<BankDepositDetailed>;
type Update = (id: number, reference: string, depositDate: string) => Promise<BankDepositUpdated>;
type Reset = () => void;
type Trigger = () => void;
type OnChange = (listener: Listener) => Function;
type Remove = (id: number) => Promise<boolean>;

const { BANK_DEPOSITS } = API_PATHS;

let sourceAll = ApiService.createToken();

class BankDepositService {

  constructor() {
    this.eventEmitter = new EventEmitter();

    this.deposit = null;
    this.deposits = [];
    this.isLoading = false;
    this.errors = [];
  }

  eventEmitter: EventEmitter;

  deposit: BankDepositDetailed | null;
  deposits: Array<BankDeposit>;
  isLoading: boolean;
  errors: Error[];

  get getDeposit(): BankDepositDetailed | null {
    return this.deposit;
  }

  get getDeposits(): BankDeposit[] {
    return this.deposits;
  }

  get isLoadingValue(): boolean {
    return this.isLoading;
  }

  get errorsValues(): Error[] {
    return this.errors;
  }

  updateValues: UpdateValues = (newDeposit: BankDepositDetailed | null, newDeposits: Array<BankDeposit>, newIsLoading: boolean, newErrors: Error[] = []): void => {
    this.deposit = newDeposit;
    this.deposits = newDeposits;
    this.isLoading = newIsLoading;
    this.errors = newErrors;
    this.#trigger();
  };

  cleanErrors: CleanErrors = () => {
    this.updateValues(this.deposit, this.deposits, false, []);
  };

  fetchAll: FetchAll = (params: QueryParams): void => {
    const { reference, groupClassId, page = 1, pagination = true } = params;

    sourceAll.cancel();
    sourceAll = ApiService.createToken();

    this.updateValues(this.deposit, [], true, this.errors);

    ApiService.request({
      method: 'get',
      url: `${ BANK_DEPOSITS }`,
      cancelToken: sourceAll.token,
      params: {
        page,
        pagination,
        reference,
        groupClassId,
      },
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;
          const depositsArray = data['hydra:member'].map(
            (depositPayload) => hydrateDepositFromPayload(depositPayload),
          );
          this.updateValues(this.deposit, depositsArray, false, this.errors);
        } else {
          this.updateValues(this.deposit, [], false, this.errors);
        }
      })
      .catch(() => {
        this.updateValues(this.deposit, [], false, this.errors);
      });
  };

  fetchOne: FetchOne = (id: number): Promise<BankDepositDetailed> => {
    this.updateValues(null, this.deposits, true, this.errors);

    return ApiService.request({
      method: 'get',
      url: `${ BANK_DEPOSITS }/${ id }`,
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;
          const deposit = hydrateDepositDetailedFromPayload(data);

          this.updateValues(deposit, this.deposits, false, this.errors);
          return deposit;
        }

        this.updateValues(null, this.deposits, false, this.errors);
        return Promise.reject();
      })
      .catch(() => {
        this.updateValues(null, this.deposits, false, this.errors);
        return Promise.reject();
      });
  };

  create: Create = (reference: string, depositDate: string, updateValue: boolean = true): Promise<BankDepositDetailed> => {
    if (updateValue) {
      this.updateValues(this.deposit, this.deposits, true, []);
    }

    return ApiService.request({
      method: 'post',
      url: `${ BANK_DEPOSITS }`,
      data: {
        reference,
        depositDate,
      },
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;
          const newDeposit = hydrateDepositDetailedFromPayload(data);

          if (updateValue) {
            this.updateValues(newDeposit, this.deposits, false, this.errors);
          }
          return newDeposit;
        }

        if (updateValue) {
          this.updateValues(this.deposit, this.deposits, false, this.errors);
        }
        return Promise.reject();
      })
      .catch((error) => {
        const errorViolations = error.response?.data?.violations || this.errors;
        if (updateValue) {
          this.updateValues(this.deposit, this.deposits, false, errorViolations);
        }
        return Promise.reject();
      });
  };

  update: Update = (
    id: number,
    reference: string,
    depositDate: string,
  ): Promise<BankDepositUpdated> => {
    this.updateValues(null, this.deposits, true, this.errors);

    return ApiService.request({
      method: 'put',
      url: `${ BANK_DEPOSITS }/${ id }`,
      data: {
        reference,
        depositDate,
      },
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;
          const updatedDeposit = hydrateDepositDetailedFromPayload(data);

          this.updateValues(updatedDeposit, this.deposits, false, this.errors);
          return { bankDeposit: updatedDeposit, updated: true };
        }

        this.updateValues(this.deposit, this.deposits, false, this.errors);
        return Promise.reject();
      })
      .catch((error) => {
        const errorViolations = error.response?.data?.violations || this.errors;
        this.updateValues(this.deposit, this.deposits, false, errorViolations);
        return Promise.reject();
      });
  };

  remove: Remove = (id: number): Promise<boolean> => {
    this.updateValues(this.deposit, this.deposits, true, this.errors);

    return ApiService.request({
      method: 'delete',
      url: `${ BANK_DEPOSITS }/${ id }`,
    })
      .then(() => {
        this.updateValues(this.deposit, this.deposits, false, this.errors);
        return true;
      })
      .catch(() => {
        this.updateValues(this.deposit, this.deposits, false, this.errors);
        return Promise.reject(false);
      });
  };

  reset: Reset = (): void => {
    this.updateValues(null, [], false, []);
  };

  onChange: OnChange = (listener: Listener): Function => {
    const listenerFunction = this.eventEmitter.addListener(listener);
    this.#trigger();
    return listenerFunction;
  };

  /**
   * @private
   */
  #trigger: Trigger = (): void => {
    this.eventEmitter.trigger({
      deposit: this.deposit,
      deposits: this.deposits,
      isLoading: this.isLoading,
      errors: this.errors,
    });
  };
}

const instance: BankDepositService = new BankDepositService();
export default instance;
