// @flow
import type { Participant } from '@participant/types';

export const DEFAULT_COUNTRY = 'FR';

export const EMPTY_PARTICIPANT: Participant = {
  address: {
    additionalLocalizationConstruction: '',
    city: '',
    country: DEFAULT_COUNTRY,
    postalCode: '',
    streetName: '',
  },
  membershipFee: false,
  authorizeData: false,
  email: '',
  firstName: '',
  lastName: '',
  birthDate: null,
  mobilePhone: '',
  landlinePhone: '',
  sexe: '',
  type: 'participant',
};
