// @flow
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import ModalDeleteBankDeposit from '@payment/components/ModalDeleteBankDeposit';
import IconTrash from '@icons/components/IconTrash';
import IconPencil from '@icons/components/IconPencil';
import IconEye from '@icons/components/IconEye';
import IconEllipsisVertical from '@icons/components/IconEllipsisVertical';

import Toast from '_common/services/Toast/Toast';
import DateService from '_common/services/DateService';

import type { GroupClassDetailed } from '@groupClass/types';
import type { BankDeposit } from '@payment/types';

import { WEB_PATHS } from '@app/constants/paths';
const { PAYMENTS_BANK_DEPOSIT_VIEW, PAYMENTS_DEPOSIT_EDIT } = WEB_PATHS;

type Props = {
  bankDeposit: BankDeposit,
  groupClass: GroupClassDetailed,
  index: number,
};

const BankDepositLine = (props: Props): React$Node => {
  const {
    bankDeposit,
    groupClass,
    index,
  } = props;

  const {
    id,
    depositDate,
    isDeletable,
    reference,
    totalAmount,
  } = bankDeposit;

  const { t, i18n: { language } } = useTranslation();
  const navigate = useNavigate();

  const [ isActiveModalDeleteBankDeposit, setIsActiveModalDeleteBankDeposit ] = useState<boolean>(false);

  const valueToShow = totalAmount.toLocaleString(language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const handleDetail = useCallback((): void => {
    if (groupClass && bankDeposit) {
      navigate(PAYMENTS_BANK_DEPOSIT_VIEW.replace(':classId', String(groupClass.id)).replace(':depositId', String(bankDeposit.id)));
    }
  }, [groupClass, bankDeposit]);

  const handleEdit = useCallback((): void => {
    if (isDeletable && groupClass && bankDeposit) {
      navigate(PAYMENTS_DEPOSIT_EDIT.replace(':classId', String(groupClass.id)).replace(':depositId', String(bankDeposit.id)));
    }
  }, [isDeletable, groupClass, bankDeposit]);

  const handleDelete = useCallback((): void => {
    if (isDeletable) {
      setIsActiveModalDeleteBankDeposit(!isActiveModalDeleteBankDeposit);
    }
  }, [isActiveModalDeleteBankDeposit, isDeletable]);

  const toggleModalDeleteBankDeposit = useCallback((): void => {
    setIsActiveModalDeleteBankDeposit(!isActiveModalDeleteBankDeposit);
  }, [isActiveModalDeleteBankDeposit]);

  const showSuccessMessage = useCallback((message: string): void => {
    Toast.success(t(message));
  }, [t]);

  const depositLineIconClassName = useMemo((): string => clsx({
    'icon-action': true,
    'icon-disabled': !isDeletable,
  }), [isDeletable]);

  const lineClassName = useMemo((): string => clsx({
    'table-body-colored': index % 2 !== 0,
  }), [index]);

  return (
    <>
      <tr className={ lineClassName }>
        <td className="table-body-cell cell-text-date">
          { DateService.localDateDisplay(depositDate, language) }
        </td>
        <td className="table-body-cell cell-text-centered">
          { reference }
        </td>
        <td className="table-body-cell cell-text-centered">
          { valueToShow }
        </td>
        <td className="table-body-cell cell-text-centered">
          <div className="table-cell-actions-desktop">
            <IconEye onClick={ handleDetail } />
            <IconPencil
              className={ depositLineIconClassName }
              onClick= { handleEdit }
            />
            <IconTrash
              className={ depositLineIconClassName }
              onClick={ handleDelete }
            />
          </div>
          <div className="table-cell-actions-tablet navbar-item has-dropdown is-hoverable">
            <IconEllipsisVertical className="icon-action navbar-link" />
            <div className="navbar-dropdown">
              <div className="navbar-button">
                <button
                  className={ depositLineIconClassName }
                  onClick={ handleDetail }
                >
                  { t('payment.actionVisualize') }
                </button>
              </div>
              <div className="navbar-button">
                <button
                  className={ depositLineIconClassName }
                  onClick={ handleEdit }
                >
                  { t('payment.actionEdit') }
                </button>
              </div>
              <div className="navbar-button">
                <button
                  className={ depositLineIconClassName }
                  onClick={ handleDelete }
                >
                  { t('payment.actionDelete') }
                </button>
              </div>
            </div>
          </div>
        </td>
      </tr>

      <ModalDeleteBankDeposit
        deleteBankDepositId={ id }
        isActive={ isActiveModalDeleteBankDeposit }
        toggleModal={ toggleModalDeleteBankDeposit }
        showSuccessMessage={ showSuccessMessage }
        groupClass={ groupClass }
      />
    </>
  );
};

export default BankDepositLine;
