// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PublicRoute from '@shared/PublicRoute/components/PublicRoute';

import { WEB_PATHS } from '@app/constants/paths';
const { HOME } = WEB_PATHS;

const ServiceUnavailable = (): React$Node => {
  const { t } = useTranslation();

  return (
    <PublicRoute>
      <div className="container not-from-france-container">
        <p>{ t('login.nonFranceMessage') }</p>
        <Link
          to={ HOME }
          className="button button-primary"
        >
          { t('login.nonFranceButton') }
        </Link>
      </div>
    </PublicRoute>
  );
};

export default ServiceUnavailable;
