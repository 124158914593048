// @flow

import Loader from '_common/components/loader/Loader';
import BeneficiaryRoute from '@shared/ProtectedRoute/components/BeneficiaryRoute';

type Props = {
  children: React$Node,
  isLoading?: boolean,
  loaderType?: string,
};

const BeneficiaryLayout = (props: Props): React$Node => {
  const { children, isLoading, loaderType } = props;

  return (
    <BeneficiaryRoute>
      <>
        { isLoading
          ? ( <Loader loaderType={ loaderType } /> )
          : ( children )
        }
      </>
    </BeneficiaryRoute>
  );
};

BeneficiaryLayout.defaultProps = {
  isLoading: false,
};

export default BeneficiaryLayout;
