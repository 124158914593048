// @flow
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import withGroupClass from '@hoc/withGroupClass';

import ParticipantsLayout from '@layout/components/ParticipantsLayout';

import FormContainer from '@shared/FormContainer';
import InputField from '_common/components/input-field/InputField';
import Loader from '_common/components/loader/Loader';
import Pagination from '@shared/Pagination';
import ParticipantIndicator from '@shared/ParticipantIndicator';
import IconSearch from '@icons/components/IconSearch';

import PersonRequestService, { type PersonRequestsServiceData } from '@person/services/PersonRequestsService';

import type { GroupClassDetailed } from '@groupClass/types';
import type { PaginationData } from '@api/types';
import type { People, Person } from '@person/type';

import { PERSON_TYPE_BENEFICIARY, PERSON_TYPE_PARTICIPANT } from '@person/constants';
import { DEFAULT_PAGINATION_DATA, DEFAULT_PAGE } from '@api/constants';
import { OPTIONS_DATE_FORMAT, FRANCE } from '@app/constants/constants';
import { WEB_PATHS } from '@app/constants/paths';
const { PARTICIPANTS_CREATE_PARTICIPANT, PARTICIPANTS_ADD_PERSON_BY_ID_PERSON } = WEB_PATHS;

type Props = {
  groupClass: GroupClassDetailed | null,
};

const SearchPeople = (props: Props): React$Node => {
  const { groupClass } = props;
  const { t, i18n: { language } } = useTranslation();
  const navigate = useNavigate();

  const { state: stateFromLocation } = useLocation();

  const [ paginationData, setPaginationData ] = useState<PaginationData>(DEFAULT_PAGINATION_DATA);
  const [ initialState, setInitialState ] = useState<boolean>(true);
  const [ isLoading, setLoading ] = useState<boolean>(PersonRequestService.isLoadingValue);
  const [ page, setPage ] = useState<number>(DEFAULT_PAGE);
  const [ personRequests, setPersonRequests ] = useState<People>(PersonRequestService.requestsValues);
  const [ search, setSearch ] = useState<string>('');

  const handleUpdateState = (data: PersonRequestsServiceData): void => {
    setLoading(data.isLoading);
    setPersonRequests(data.requests);
    setPaginationData(data.paginationData ? data.paginationData : DEFAULT_PAGINATION_DATA);
  };

  useEffect(() => PersonRequestService.onChange(handleUpdateState), []);

  useEffect(() => {
    const isSearchComingFromService = stateFromLocation?.keepSearch || false;
    if (isSearchComingFromService) {
      setPage(PersonRequestService.pageValue);
      setSearch(PersonRequestService.searchValue);
    } else {
      PersonRequestService.onClean();
    }
  }, [stateFromLocation]);

  const handleSearchChange = useCallback((event: SyntheticEvent<HTMLInputElement>): void => {
    const { value } = event.currentTarget;

    setSearch(value);
  }, []);

  useEffect(() => {
    if (search.length >= 3) {
      const newPage = (search !== PersonRequestService.searchValue) ? 1 : page;
      PersonRequestService.fetchAll(language, {
        currentPage: newPage,
        fullName: search,
        personTypes: [PERSON_TYPE_BENEFICIARY, PERSON_TYPE_PARTICIPANT],
        locale: groupClass?.sector?.country || FRANCE,
        groupClass: groupClass?.id || null,
      });
      setInitialState(false);
      setPage(newPage);
    } else if (search.length === 0) {
      setPersonRequests([]);
      setInitialState(true);
      setPage(1);
    }
  }, [page, search, groupClass, language]);

  const icons = {
    rightIcon: <IconSearch />,
  };

  const handleSelectPerson = useCallback((person: Person): void => {
    navigate(PARTICIPANTS_ADD_PERSON_BY_ID_PERSON
      .replace(':classId', String(groupClass?.id))
      .replace(':personId', String(person.id))
      .replace(':personType', person.type));
  }, [groupClass]);

  const handleCreateLink = useCallback((event: SyntheticEvent<HTMLAnchorElement>): void => {
    if (search.length < 3) {
      event.preventDefault();
    }
  }, [search]);

  const buttonClassName = useMemo((): string => clsx({
    'button': true,
    'is-light': search.length < 3,
    'button-primary': search.length >= 3,
  }), [search]);

  return (
    <ParticipantsLayout groupClass={ groupClass }>
      <FormContainer
        className="group-class-search-people"
        title={ t('groupClasses.groupClass.peoples.form.addParticipant') }
      >
        <div className="search-field">
          <InputField
            name="search"
            value={ search }
            onChange={ handleSearchChange }
            icons={ icons }
            placeholder={ t('groupClasses.groupClass.peoples.search.placeholderSearch') }
          />
        </div>

        <div className="group-class-search-people-results">
          <p className="group-class-search-people-mention">{ t('groupClasses.groupClass.peoples.search.mentionText') }</p>

          <div className="table-results-block">
            <table className="table-results">
              <thead className="table-results-head">
                <tr>
                  <th className="table-results-head-cell" colSpan={ 7 }>
                    { initialState
                      ? t('groupClasses.groupClass.peoples.search.table.title')
                      : t('groupClasses.groupClass.peoples.search.table.titleWithResults', {
                        nbResults: paginationData?.totalItems ?? 0,
                      }) }
                  </th>
                </tr>
                <tr>
                  <th />
                  <th className="table-results-head-cell">
                    { t('groupClasses.groupClass.peoples.form.peopleFirstName') }
                  </th>
                  <th className="table-results-head-cell">
                    { t('groupClasses.groupClass.peoples.form.peopleLastName') }
                  </th>
                  <th className="table-results-head-cell">
                    { t('user.birthDate') }
                  </th>
                  <th className="table-results-head-cell">
                    { t('groupClasses.groupClass.detailed.address.postalCode') }
                  </th>
                  <th className="table-results-head-cell">
                    { t('login.email') }
                  </th>
                </tr>
              </thead>
              <tbody className="table-results-body">
                { initialState && (
                  <tr className="table-results-body-row">
                    <td className="table-results-body-row-cell" colSpan={ 7 }>
                      { t('groupClasses.groupClass.peoples.search.initialSearchText') }
                    </td>
                  </tr>
                ) }

                { isLoading && (
                  <tr className="table-results-body-row block-loader">
                    <td className="table-results-body-row-cell" colSpan={ 7 }>
                      <Loader className="table-loader" />
                    </td>
                  </tr>
                ) }

                { !isLoading && personRequests?.map((person: Person) => (
                  <tr className="table-results-body-row" key={ person.id } onClick={ () => handleSelectPerson(person) }>
                    <td className="table-results-body-row-cell">
                      <ParticipantIndicator isBeneficiary={ person.type === PERSON_TYPE_BENEFICIARY } extraCss="people-search" />
                    </td>
                    <td className="table-results-body-row-cell">{ person.firstName }</td>
                    <td className="table-results-body-row-cell">{ person.lastName.toUpperCase() }</td>
                    <td className="table-results-body-row-cell">
                      { person?.birthDate?.toLocaleDateString(language, OPTIONS_DATE_FORMAT) || '' }
                    </td>
                    <td className="table-results-body-row-cell">{ person.address?.postalCode || '' }</td>
                    <td className="table-results-body-row-cell">{ person.email }</td>
                  </tr>
                )) }

                { !isLoading && !initialState && personRequests?.length === 0 && (
                  <tr className="table-results-body-row">
                    <td className="table-results-body-row-cell" colSpan={ 7 }>
                      { t('groupClasses.groupClass.peoples.search.noResultText') }
                    </td>
                  </tr>
                ) }
              </tbody>
            </table>
          </div>

          { paginationData?.lastPage > 1 && (
            <div className="pagination-block">
              <Pagination
                changePage={ setPage }
                currentPage={ paginationData.currentPage }
                lastPage={ paginationData.lastPage }
                alignment="is-centered"
                isRounded
              />
            </div>
          ) }

          <div className="not-exist-block">
            <p className="not-exist-text">
              { t('groupClasses.groupClass.peoples.search.notExistText') }
            </p>

            <Link
              to={ groupClass ? PARTICIPANTS_CREATE_PARTICIPANT.replace(':classId', String(groupClass.id)) : '' }
              onClick={ handleCreateLink }
              className={ buttonClassName }
            >
              { t('groupClasses.groupClass.peoples.search.button.create') }
            </Link>
          </div>
        </div>
      </FormContainer>
    </ParticipantsLayout>
  );
};

SearchPeople.defaultProps = {
  className: 'group-class-search-people',
};

export {
  SearchPeople,
};

export default withGroupClass(SearchPeople);
