// @flow
import { type MediaObject, SharedSession } from '@groupSend/types';

export const hydrateMediaObjectFromPayload = (mediaObject: MediaObject): MediaObject => ({
  id: mediaObject.id,
  originalName: mediaObject.originalName,
  name: mediaObject.name,
  width: mediaObject.width ?? null,
  height: mediaObject.height ?? null,
  contentType: mediaObject.contentType,
  contentSize: mediaObject.contentSize,
  upload: mediaObject.upload ?? null,
  download: mediaObject.download ?? null,
});

export const parseSharedSessionFromPayload = (sharedSession: SharedSession): SharedSession => (sharedSession);
