// @flow
import { useMemo } from 'react';
import clsx from 'clsx';

type Props = {
  className: string,
  onClick: () => void,
};

const IconInstagram = (props: Props): React$Node => {
  const {
    className,
    onClick,
  } = props;

  const iconSocialClassName = useMemo((): string => clsx({
    'icon-social': true,
    [String(className)]: !!className,
  }), [className]);

  const newProps = {
    className: iconSocialClassName,
    onClick,
  };

  return (
    <svg { ...newProps } viewBox="0 0 52.34 52.67" width="52.34" height="52.67">
      <g>
        <path className="bg"
          d="M0,3.77C0,1.69,1.73,0,3.87,0h44.6c2.14,0,3.87,1.69,3.87,3.77v45.12c0,2.08-1.73,3.77-3.87,3.77H3.87c-2.14,0-3.87-1.69-3.87-3.77V3.77Z"/>
        <path className="icon"
          d="M26.57,9.9c-4.47,0-5.03,0-6.76.1-1.73.1-2.95.36-4.01.76-1.07.41-1.98.97-2.9,1.88s-1.47,1.83-1.88,2.9c-.41,1.07-.66,2.24-.76,3.96-.1,1.78-.1,2.34-.1,6.76s0,5.03.1,6.76.36,2.95.76,4.01.97,1.98,1.88,2.9c.91.91,1.83,1.47,2.9,1.88,1.07.41,2.24.66,4.01.76s2.34.1,6.76.1,5.03,0,6.76-.1c1.73-.1,2.95-.36,4.01-.76,1.07-.41,1.98-.97,2.9-1.88.91-.91,1.47-1.83,1.88-2.9s.66-2.24.76-4.01.1-2.34.1-6.76,0-5.03-.1-6.76-.36-2.95-.76-3.96c-.41-1.07-.97-1.98-1.88-2.9s-1.83-1.47-2.9-1.88c-1.07-.41-2.24-.66-4.01-.76-1.73-.1-2.29-.1-6.76-.1M26.57,12.85c4.37,0,4.93,0,6.65.1,1.63.05,2.49.36,3.05.56.76.3,1.32.66,1.88,1.22s.91,1.12,1.22,1.88c.2.56.51,1.47.56,3.05.1,1.73.1,2.24.1,6.65s0,4.93-.1,6.65c-.05,1.63-.36,2.49-.56,3.05-.3.76-.66,1.32-1.22,1.88-.56.56-1.12.91-1.88,1.22-.56.2-1.47.51-3.05.56-1.73.1-2.24.1-6.65.1s-4.93,0-6.65-.1c-1.63-.05-2.49-.36-3.05-.56-.76-.3-1.32-.66-1.88-1.22-.56-.56-.91-1.12-1.22-1.88-.2-.56-.51-1.47-.56-3.05-.1-1.73-.1-2.24-.1-6.65s0-4.93.1-6.65c.05-1.63.36-2.49.56-3.05.3-.76.66-1.32,1.22-1.88s1.12-.91,1.88-1.22c.56-.2,1.47-.51,3.05-.56,1.73-.1,2.29-.1,6.65-.1"/>

        <path className="icon"
          d="M26.57,31.79c-3.05,0-5.49-2.44-5.49-5.49s2.44-5.49,5.49-5.49,5.49,2.44,5.49,5.49-2.44,5.49-5.49,5.49M26.57,17.88c-4.67,0-8.43,3.76-8.43,8.43s3.76,8.43,8.43,8.43,8.43-3.76,8.43-8.43-3.76-8.43-8.43-8.43"/>

        <path className="icon"
          d="M37.34,17.52c0,1.07-.86,1.98-1.98,1.98s-1.98-.86-1.98-1.98.86-1.98,1.98-1.98c1.07.05,1.98.91,1.98,1.98h0Z"/>
      </g>
    </svg>
  );
};

IconInstagram.defaultProps = {
  className: 'icon-instagram',
  onClick: () => {
  },
};

export default IconInstagram;
