// @flow
import { useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { format as dateFmt } from 'date-fns';

import CreatableSelectField, { type MenuPlacementOption } from '@shared/CreatableSelectField/components/CreatableSelectField';
import DatePickerField from '_common/components/DateTimePicker/components/DatePickerField';
import BankDepositService from '@payment/services/BankDepositService';

import {
  createOptionFromPayload,
  hydrateDepositFormDataFromDeposit,
  emptyBankDeposit,
} from '@payment/helpers/BankDepositUtils';

import { debounce } from '_common/services/CommonUtils';

import type { BankDepositFormData, BankDepositDetailed, BankDeposit } from '@payment/types';
import type { ErrorProps, Option } from '@core/types';

import { WIDESCREEN_WIDTH } from '@payment/constants';
import { FORMAT_DATE_API } from '@app/constants/dates';

type Props = {
  idx: number,
  bankDeposit: BankDepositFormData,
  handleBankDepositData: (data: BankDepositFormData | null) => void,
  errorsProps: ErrorProps,
  className?: string,
  selectClassName?: string,
  datePickerClassName?: string,
  isDisabled?: boolean,
};

const fetchDepositsDebounced: Function = debounce(BankDepositService.fetchAll, 100);

const BankDepositInput = (props: Props): React$Node => {
  const {
    idx,
    bankDeposit,
    handleBankDepositData,
    errorsProps,
    className,
    selectClassName,
    datePickerClassName,
    isDisabled,
  } = props;

  const { t } = useTranslation();

  const [ selectOption, setSelectOption ] = useState<Option | null>(null);
  const [ selectOptions, setSelectOptions ] = useState<Option[]>([]);
  const [ isDepositDateRequired, setIsDepositDateRequired ] = useState<boolean>(false);

  // reset fields
  useEffect(() => {
    setSelectOption(null);
    setSelectOptions([]);
    if (bankDeposit.id) {
      setSelectOption(createOptionFromPayload(bankDeposit));
    }
    return () => BankDepositService.reset();
  }, [idx]);

  const handleUpdateState = (data: {
    deposit: BankDepositDetailed | null,
    deposits: Array<BankDeposit>,
  }) => {
    if (data.deposit) {
      const depositData = hydrateDepositFormDataFromDeposit(data.deposit);
      handleBankDepositData(depositData);
    }

    setSelectOptions(data.deposits.map((depositItem) => createOptionFromPayload(depositItem)));
  };

  useEffect(() => BankDepositService.onChange(handleUpdateState), []);

  const fetchDepositOptions = useCallback((value: string): void => {
    if (value.length > 2) {
      const params = { reference: value, pagination: false };
      fetchDepositsDebounced(params);
    } else {
      setSelectOptions([]);
    }
  }, [fetchDepositsDebounced, setSelectOptions]);

  const handleOptionSelect = useCallback((opt: Option): void => {
    setSelectOption(null);
    if (opt) {
      const picked = selectOptions.find((optionItem) => optionItem.value === opt.value);
      setSelectOption(picked || null);

      BankDepositService.fetchOne(parseInt(opt.value, 10));
    } else {
      handleBankDepositData(null);
      setIsDepositDateRequired(false);
    }

  }, [selectOptions, bankDeposit]);

  const handleOptionCreate = useCallback((reference: string): void => {
    const createdData = { ...emptyBankDeposit, reference };

    handleBankDepositData(createdData);

    setSelectOption({
      label: reference,
      value: '',
    });

    setIsDepositDateRequired(true);
  }, []);

  const handleDateChange = useCallback((updatedDate: moment$Moment): void => {
    const depositDate = dateFmt(updatedDate, FORMAT_DATE_API);
    handleBankDepositData({ ...bankDeposit, depositDate });
  }, [bankDeposit, handleBankDepositData, setSelectOption]);

  const getCreateLabelText = useCallback((depositRef: string): string => (
    t('payment.createBankDepositNumber', { depositRef })
  ), [t]);

  const menuPlacement = useMemo((): MenuPlacementOption => (
    window.innerWidth < WIDESCREEN_WIDTH ? 'top' : 'auto'
  ), [window.innerWidth]);

  return (
    <div className={ className }>
      <CreatableSelectField
        id="depositNumber"
        name="depositNumber"
        label={ t('payment.bankDepositNumber') }
        value={ selectOption }
        options={ selectOptions }
        onChange={ handleOptionSelect }
        onInputChange={ fetchDepositOptions }
        placeholder={ t('payment.bankDepositNumberPlaceholder') }
        isClearable
        allowCreateWhileLoading={ false }
        createOptionPosition="last"
        formatCreateLabel={ getCreateLabelText }
        onCreateOption={ handleOptionCreate }
        borderColor={ errorsProps.depositNumber?.borderColor }
        color={ errorsProps.depositNumber?.color }
        labelProps={ errorsProps.depositNumber?.labelProps }
        helpProps={ errorsProps.depositNumber?.error }
        className={ selectClassName }
        menuPlacement={ menuPlacement }
        isDisabled={ isDisabled }
      />

      <DatePickerField
        id="depositDate"
        name="depositDate"
        value={ (bankDeposit && bankDeposit.depositDate) ? new Date(bankDeposit.depositDate) : null }
        onChange={ handleDateChange }
        label={ t('payment.depositDate') }
        isDisabled={ !!bankDeposit?.id || !selectOption }
        color={ errorsProps.depositDate?.color }
        labelProps={ errorsProps.depositDate?.labelProps }
        helpProps={ errorsProps.depositDate?.error }
        required={ isDepositDateRequired }
        className={ datePickerClassName }
      />
    </div>
  );
};

export default BankDepositInput;
