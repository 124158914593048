// @flow
import { useEffect, useMemo, useState } from 'react';
import { isEdge, isMobile, useMobileOrientation } from 'react-device-detect';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import RolesHelpers from '@user/helpers/RolesHelpers';
import AuthService, { type AuthServiceData } from '@user/services/AuthService';
import type { User } from '@user/types';

import IconRotateScreen from '@icons/components/IconRotateScreen';
import DocumentHelpers from '@helpers/DocumentHelpers';

type Props = {
  isVisible: boolean,
};

const RotateScreen = (props: Props): React$Node | null => {
  const { isVisible } = props;

  const { t } = useTranslation();
  const { isPortrait } = useMobileOrientation();

  const [ showPortraitOverlay, setShowPortraitOverlay ] = useState<boolean>((isPortrait && isMobile) || isEdge);
  const [ currentUser, setCurrentUser ] = useState<User | null>(AuthService.user);

  const handleUpdateAuthService = (data: AuthServiceData) => {
    setCurrentUser(data.user);
  };

  useEffect(() => AuthService.onChange(handleUpdateAuthService), []);

  const isSielBleuUser = useMemo((): boolean => (
    RolesHelpers.isSielBleuUser(currentUser)
  ), [currentUser]);

  const overlayEdgeClassName = useMemo((): string => clsx({
    'overlay': true,
    'overlay-edge': isEdge,
  }), [isEdge]);

  const rotateScreenEdgeClassName = useMemo((): string => clsx({
    'rotate-screen': true,
    'rotate-screen-edge': isEdge,
  }), [isEdge]);

  /**
   * useEffect used to handle the screen rotation
   */
  useEffect(() => {
    if (isMobile && isVisible) {
      setShowPortraitOverlay(isPortrait);
      isPortrait ? DocumentHelpers.fixedToBody() : DocumentHelpers.unfixedToBody();
    }

    if (isEdge && isSielBleuUser) {
      setShowPortraitOverlay(isEdge);
      DocumentHelpers.fixedToBodyEdge();
    }

  }, [isMobile, isPortrait, isVisible]);

  if ((!isVisible && !isEdge) || !showPortraitOverlay || !isSielBleuUser) {
    return null;
  }

  return (
    <>
      <div className={ overlayEdgeClassName } />
      <div className={ rotateScreenEdgeClassName }>
        <IconRotateScreen />
        <p>{ t('rotate.screen') }</p>
      </div>
    </>
  );
};

RotateScreen.defaultProps = {
  isVisible: false,
};

export default RotateScreen;
