// @flow
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationsES from '@app/i18n/translations/es.json';
import translationsFR from '@app/i18n/translations/fr.json';

const resources = {
  es: {
    translation: translationsES,
  },
  fr: {
    translation: translationsFR,
  },
};

const detectionOptions = {
  order: ['navigator'],
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    detection: detectionOptions,
    resources,
    fallbackLng: 'fr',
    // lng: 'en',
    react: {
      // wait: true,
      useSuspense: false,
    },
  });

export default i18n;
