// @flow
import { useMemo } from 'react';
import clsx from 'clsx';

import type { Colors, AnotherColors, LightColors, Sizes } from '@core/types';
import type { ButtonType } from '@shared/Button/types';
import * as buttonConstants from '@shared/Button/constants';

export type ButtonProps = {
  children: React$Node,
  className: string,
  color?: Colors | AnotherColors | LightColors,
  isActive: boolean,
  isDisabled: boolean,
  isFocused: boolean,
  isFullWidth: boolean,
  isHovered: boolean,
  isInverted: boolean,
  isLoading: boolean,
  isOutlined: boolean,
  isRounded: boolean,
  isStatic: boolean,
  size?: Sizes,
  type: ButtonType,
  onClick: (event: SyntheticEvent<HTMLButtonElement>) => void,
};

const Button = (props: ButtonProps): React$Node => {
  const {
    children,
    className,
    color,
    isActive,
    isDisabled,
    isFocused,
    isFullWidth,
    isHovered,
    isInverted,
    isLoading,
    isOutlined,
    isRounded,
    isStatic,
    onClick,
    size,
    type,
    ...others
  } = props;

  const actionOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    if (!isDisabled && !isLoading) onClick(event);
  };

  const buttonStyle = type && buttonConstants.BUTTON_STYLES.includes(type)
    ? type
    : buttonConstants.PRIMARY;

  const buttonClassName = useMemo((): string => clsx({
    'button': true,
    'is-active': !!isActive,
    'is-disabled': !!isDisabled,
    'is-focused': !!isFocused,
    'is-fullwidth': !!isFullWidth,
    'is-hovered': !!isHovered,
    'is-inverted': !!isInverted,
    'is-loading': !!isLoading,
    'is-outlined': !!isOutlined,
    'is-rounded': !!isRounded,
    'is-static': !!isStatic,
    [String(`button-${ buttonStyle }`)]: !!buttonStyle,
    [String(className)]: !!className,
    [String(color)]: !!color,
    [String(size)]: !!size,
  }), [buttonStyle,
    isActive,
    isDisabled,
    isFocused,
    isFullWidth,
    isHovered,
    isInverted,
    isLoading,
    isOutlined,
    isRounded,
    isStatic,
    size,
    color,
    className,
  ]);

  return (
    <button { ...others } className={ buttonClassName } onClick={ actionOnClick }>
      { children }
    </button>
  );
};

Button.defaultProps = {
  className: 'button',
  isActive: false,
  isDisabled: false,
  isFocused: false,
  isFullWidth: false,
  isHovered: false,
  isInverted: false,
  isLoading: false,
  isOutlined: false,
  isRounded: false,
  isStatic: false,
  type: buttonConstants.PRIMARY,
  onClick: () => {},
};

export default Button;
