// @flow
import clsx from 'clsx';

import { useMemo } from 'react';

import Control from '_common/components/control/Control';

import type { Colors, Sizes, Option } from '@core/types';

export type SelectProps = {
  color?: Colors,
  controlIsExpanded?: boolean,
  hasIconLeft?: React$Node,
  isFocused?: boolean,
  isHovered?: boolean,
  isLoading?: boolean,
  isMultiple?: boolean,
  isRounded?: boolean,
  multipleSize?: number,
  options: Array<Option>,
  size?: Sizes,
  onChange: (event: SyntheticEvent<HTMLSelectElement>) => void,
};

const Select = (props: SelectProps): React$Node => {
  const {
    color,
    controlIsExpanded,
    hasIconLeft,
    isFocused,
    isHovered,
    isLoading,
    isMultiple,
    isRounded,
    multipleSize = 0,
    onChange,
    options,
    size,
    ...others
  } = props;

  const selectClassName = useMemo((): string => clsx({
    'select': true,
    'is-multiple': !!isMultiple,
    'is-rounded': !!isRounded,
    'is-loading': !!isLoading,
    [String(color)]: !!color,
    [String(size)]: !!size,
  }), [isMultiple, isRounded, isLoading, color, size]);

  const selectDivClassName = useMemo((): string => clsx ({
    'is-hovered': !!isHovered,
    'is-focused': !!isFocused,
  }), [isHovered, isFocused]);

  const controlLeftIcon = hasIconLeft
    ? { leftIcon: hasIconLeft, leftIconClassName: size }
    : {};

  const controlSize = isMultiple ? multipleSize : 0;

  return (
    <Control
      isExpanded={ controlIsExpanded }
      icons={ controlLeftIcon }>
      <div className={ selectClassName }>
        <select
          { ...others }
          onChange={ onChange }
          multiple={ isMultiple }
          size={ controlSize }
          className={ selectDivClassName }
        >
          { options.map((option) => (
            <option
              key={ option.value }
              value={ option.value }
            >
              { option.label }
            </option>
          )) }
        </select>
      </div>
    </Control>
  );
};

export default Select;
