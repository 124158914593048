// @flow
import { useCallback } from 'react';
import moment from 'moment';

import * as dateTimePickerConstants from '@shared/DateTimePicker/constants';
import IconArrow from '@icons/components/IconArrow';

type Props = {
  date: moment$Moment,
  navigationDate: moment$Moment,
  isValid: (date: moment$Moment, viewMode: string) => boolean,
  onChange: (date: moment$Moment, viewMode: string) => void,
  onNavigateDateChange: (date: moment$Moment) => void,
};

const YearPicker = (props: Props): React$Node => {
  const { date, navigationDate, isValid, onChange, onNavigateDateChange } = props;

  const handleDateClick: Function = useCallback((year: number): void => {
    const newDate = moment(date).year(year);
    if (isValid(newDate, dateTimePickerConstants.VIEW_MODE_YEAR)) {
      onChange(newDate, dateTimePickerConstants.VIEW_MODE_YEAR);
    }
  }, [date, isValid, onChange]);

  const goPreviousDecade: Function = useCallback((): void => {
    const newDate = moment(navigationDate).subtract(10, 'years');
    onNavigateDateChange(newDate);
  }, [navigationDate, onNavigateDateChange]);

  const goNextDecade: Function = useCallback((): void => {
    const newDate = moment(navigationDate).add(10, 'years');
    onNavigateDateChange(newDate);
  }, [navigationDate, onNavigateDateChange]);

  const selectedYearDate = parseInt(moment(date).format('YYYY'), 10);
  const yearDate = parseInt(moment(navigationDate).format('YYYY'), 10);
  const yearDecade = Math.trunc(yearDate / 10) * 10;
  const years = [];

  for (let i = 0; i < 10; i++) {
    years.push(yearDecade + i);
  }
  // Add two empty items for a square rendering
  years.push(null);
  years.push(null);

  return (
    <div className="year-picker">
      <div className="year-picker-nav">
        <IconArrow className="arrow-previous" onClick={ goPreviousDecade } />
        <span className="date-picker-nav-value">{ yearDecade }</span>
        <IconArrow className="arrow-next" onClick={ goNextDecade } />
      </div>

      <div className="year-picker-container">
        { years.map((year, index) => {
          if (!year) {
            return (
              <div key={ index } className="year-picker-item no-value" />
            );
          }

          const isCurrent = selectedYearDate === year;

          return (
            <div
              key={ year }
              className="year-picker-item"
              onClick={ () => year && handleDateClick(year) }
            >
              { year }

              { isCurrent && (
                <div className="year-current" />
              ) }
            </div>
          );
        }) }
      </div>
    </div>
  );
};

YearPicker.defaultProps = {
  date: '',
  navigationDate: '',
  isValid: (): boolean => true,
  onChange: (): void => undefined,
  onNavigateDateChange: (): void => undefined,
};

export default YearPicker;
