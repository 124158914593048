// @flow
import type { AxiosXHR } from 'axios';
import { type LatLng, geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';

import i18n from '@app/i18n/i18n';
import ApiService from '@api/service';
import type { DictionaryObject } from '@core/types';

export const getLatLngByPlaceId = (placeId: string): Promise<LatLng> => (
  geocodeByPlaceId(placeId)
    .then((results) => getLatLng(results[0]))
);

export const requestAddress = (props: DictionaryObject): Promise<AxiosXHR<any>> => {
  const { url, config, params } = props;
  const { language } = i18n;

  const newConfig = { ...config };

  if (language) {
    newConfig['headers'] = { 'Accept-Language': language };
  }

  return ApiService.request({
    ...newConfig,
    url,
    params,
    baseURL: process.env.REACT_APP_GOOGLE_MAPS_API_URL,
    method: 'get',
  });
};
