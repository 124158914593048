// @flow
import { useEffect, useMemo, useState } from 'react';

import type { QueryParams } from '@core/types';
import type { GroupClassRegistered } from '@groupClass/types';
import type { ShortGroupClassBeneficiaryRequests } from '@participant/types';
import { RequestStatuses } from '@core/constants';

import TrialBanners from '@banner/components/TrialBanners';
import GroupClassesRegistered from '@groupClass/components//GroupClassesRegistered';
import BeneficiaryLayout from '@layout/components/BeneficiaryLayout';

import BeneficiaryGroupClassesRegisteredsService, { type BeneficiaryGroupClassesRegisteredsServiceData } from '@beneficiary/services/BeneficiaryGroupClassesRegisteredsService';
import BeneficiaryGroupClassesRequestsService from '@beneficiary/services/BeneficiaryGroupClassesRequestsService';

import { DEFAULT_ITEMS_PER_PAGE } from '@api/constants';

const GroupClasses = (): React$Node => {

  const [ groupClassesRegistereds, setGroupClassesRegistereds ] = useState<GroupClassRegistered[]>(BeneficiaryGroupClassesRegisteredsService.registeredsValues);
  const [ groupClassesRequests, setGroupClassesRequests ] = useState<ShortGroupClassBeneficiaryRequests>(BeneficiaryGroupClassesRequestsService.requestsValues);

  const handleUpdateStateRegistereds = (data: BeneficiaryGroupClassesRegisteredsServiceData): void => {
    setGroupClassesRegistereds(data.registereds);
  };

  const handleUpdateStateRequests = (data) => {
    setGroupClassesRequests(data.requests);
  };

  useEffect(() => BeneficiaryGroupClassesRegisteredsService.onChange(handleUpdateStateRegistereds), []);
  useEffect(() => BeneficiaryGroupClassesRequestsService.onChange(handleUpdateStateRequests), []);

  const emptyGroupClassesRegistereds = useMemo((): boolean => (
    BeneficiaryGroupClassesRegisteredsService.registeredsRequestStatus === RequestStatuses.SUCCEEDED
    && groupClassesRegistereds.length === 0
    && BeneficiaryGroupClassesRequestsService.registeredsRequestStatus === RequestStatuses.SUCCEEDED
    && groupClassesRequests.length === 0
  ), [groupClassesRegistereds, BeneficiaryGroupClassesRegisteredsService.registeredsRequestStatus, groupClassesRequests, BeneficiaryGroupClassesRequestsService.registeredsRequestStatus]);

  const paramsGroupClasses: QueryParams = {
    orderActivityName: 'ASC',
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
  };

  return (
    <BeneficiaryLayout>
      <section className="group-classes-container container">
        <div className="columns is-flex-wrap-wrap">
          { emptyGroupClassesRegistereds && (
            <div className="column is-12 column-no-result">
              <h1>{ 'Mes cours collectifs' }</h1>
              <div className="no-result">{ 'Aucun cours collectif' }</div>
            </div>
          ) }
          <div className="column is-12">
            <TrialBanners paramsGroupClasses={ paramsGroupClasses } />
          </div>
          <div className="column is-12">
            <GroupClassesRegistered />
          </div>
        </div>
      </section>
    </BeneficiaryLayout>
  );
};

export default GroupClasses;
