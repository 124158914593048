// @flow
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import StatusBlock from '@payment/components/StatusBlock';

import type { Balance } from '@payment/types';

import {
  PAYMENT_TYPE_CB_CHQ,
  PAYMENT_TYPE_CB_CHQ_FORMATTED,
} from '@payment/constants';

type Props = {
  balance: Balance,
};

const PaymentLine = (props: Props): React$Node => {
  const { balance } = props;
  const { person, toPay, paid, type, unsubscribedAt, status } = balance;
  const { firstName, lastName } = person;

  const { i18n: { language } } = useTranslation();

  const isSubscribed = unsubscribedAt ? false : true;

  const typeFormatted = type === PAYMENT_TYPE_CB_CHQ ? PAYMENT_TYPE_CB_CHQ_FORMATTED : type;

  const trClassName = useMemo((): string => clsx({
    'table-body-even': true && isSubscribed,
    'table-body-unsubscribed': isSubscribed === false,
  }, [isSubscribed]));

  const toPayFormatted = toPay.toLocaleString(language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const paidFormatted = paid.toLocaleString(language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const firstNameFormatted = firstName.charAt(0).toUpperCase() + firstName.slice(1);
  const lastNameFormatted = lastName.toUpperCase();

  return (
    <tr className={ trClassName }>
      <td className="table-body-cell cell-text-name">
        { firstNameFormatted } { lastNameFormatted }
      </td>
      <td className="table-body-cell table-cell-width">
        <StatusBlock status={ status } isSubscribed={ isSubscribed } />
      </td>
      <td className="table-body-cell cell-text-centered table-cell-width">
        { toPayFormatted }
      </td>
      <td className="table-body-cell cell-text-centered table-cell-width">
        { paidFormatted }
      </td>
      <td className="table-body-cell table-cell-width cell-text-centered">
        { typeFormatted }
      </td>
    </tr>
  );
};

export default PaymentLine;
