// @flow
import type { Tab } from '@shared/Navigation/TabElement/types';

export const getTabs = (tabs: Tab[], active: string = ''): Tab[] => (
  tabs.map((tab: Tab): Tab => {
    const newTab = { ...tab };
    if (tab.name === active) {
      newTab.isActive = true;
    }
    return newTab;
  })
);
