// @flow
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment/dist/moment';

import type { GroupClassDetailed } from '@groupClass/types';
import type { ErrorProps } from '@core/types';

import DateService from '_common/services/DateService';

import Button, { buttonConstants } from '@shared/Button';
import DatePickerField from '_common/components/DateTimePicker/components/DatePickerField';
import Help from '_common/components/help/Help';
import InputField from '_common/components/input-field/InputField';
import IconTrash from '@icons/components/IconTrash';
import IconPlus from '@icons/components/IconPlus';

import { errorColor } from '@app/constants/styles';
import ObjectHelpers from '@helpers/ObjectHelpers';
import * as groupClassesConstants from '@app/constants/constants';

const { GROUP_CLASS_TYPE_SIEL_BLEU } = groupClassesConstants;

type Props = {
  errorsProps: ErrorProps,
  groupClass: GroupClassDetailed,
  setGroupClass: (groupClass: GroupClassDetailed | null) => void,
};

const Periods = (props: Props): React$Node => {
  const { t, i18n: { language } } = useTranslation();
  const { errorsProps, groupClass, setGroupClass } = props;

  const [ errorPeriods, setErrorPeriods ] = useState<ErrorProps>(errorsProps);

  const startDate = useMemo((): Date => new Date(groupClass.startDate), [groupClass]);
  const endDate = useMemo((): Date => new Date(groupClass.endDate), [groupClass]);

  const handleAddPeriod = useCallback((): void => {
    const newGroupClass = ObjectHelpers.deepClone(groupClass);

    let startDateForNewPeriod = startDate;
    let endDateForNewPeriod = endDate;
    if (
      newGroupClass.periods[newGroupClass.periods.length - 1]
      && newGroupClass.periods[newGroupClass.periods.length - 1].endDate
    ) {
      startDateForNewPeriod = new Date(
        newGroupClass.periods[newGroupClass.periods.length - 1].endDate,
      );
      startDateForNewPeriod = DateService.addDayToDate(startDateForNewPeriod, 1);
      endDateForNewPeriod = DateService.addDayToDate(startDateForNewPeriod, 1);
    }
    newGroupClass.periods.push({
      id: null,
      validatedAt: null,
      amount: '',
      startDate: startDateForNewPeriod,
      endDate: endDateForNewPeriod,
    });

    setGroupClass(newGroupClass);
  }, [groupClass, startDate, endDate]);

  const handleRemovePeriod = useCallback((index: number): void => {
    const newGroupClass = ObjectHelpers.deepClone(groupClass);

    if (newGroupClass.periods.length - 1 >= 0) {
      newGroupClass.periods.splice(index, 1);
    }
    newGroupClass.endDate = newGroupClass.periods[newGroupClass.periods.length - 1]?.endDate || new Date();

    setGroupClass(newGroupClass);
  }, [groupClass]);

  const handleChangePeriod = useCallback((e: Date | SyntheticEvent<HTMLInputElement>, index, date): void => {
    const newGroupClass = ObjectHelpers.deepClone(groupClass);

    newGroupClass.periods[index][date] = e;
    if (e === null) {
      errorPeriods[`periods[${ index }].${ date }`] = {
        color: 'is-danger',
        borderColor: errorColor,
        labelProps: { className: 'has-text-danger' },
        labelTextAreaProps: {
          className: 'has-text-danger label',
        },
        selectFieldHelperText: { text: t('commonError.thisFieldIsMandatory'), textColor: 'is-danger' },
        error : {
          text: t('groupClasses.groupClass.detailed.errorPeriodDateNull'),
          textColor: 'is-danger',
        },
      };
      setErrorPeriods(errorPeriods);
    }

    const periodLength = newGroupClass?.periods?.length;
    let periodStartDate = newGroupClass?.periods[0]?.startDate ? moment(newGroupClass?.periods[0]?.startDate).format('YYYY-MM-DD') : null;
    let startDate = periodLength ? periodStartDate : newGroupClass?.startDate;

    let periodEndDate = newGroupClass?.periods[periodLength - 1]?.endDate ? moment(newGroupClass?.periods[periodLength - 1]?.endDate).format('YYYY-MM-DD') : null;
    let endDate = periodLength ? periodEndDate : newGroupClass?.endDate;

    newGroupClass.startDate = startDate || newGroupClass.startDate;
    newGroupClass.endDate = endDate || newGroupClass.endDate;

    setGroupClass(newGroupClass);
  }, [groupClass, errorColor, errorPeriods]);

  const handleChangeAmount = useCallback((e: SyntheticEvent<HTMLInputElement>, index: number): void => {
    const newGroupClass = ObjectHelpers.deepClone(groupClass);
    newGroupClass.periods[index][e.currentTarget.name] = e.currentTarget.value;
    setGroupClass(newGroupClass);
  }, [groupClass]);

  const periodsBlock = useMemo((): React$Node => (
    groupClass.periods.map((period, index) => {
      const startDatePeriod = new Date(period.startDate);
      const endDatePeriod = new Date(period.endDate);

      const canDelete = groupClass.type === GROUP_CLASS_TYPE_SIEL_BLEU
        ? groupClass.periods.length > 1 && index >= 1 && !period.hasPayment && !period.validatedAt
        : !period.hasPayment && !period.validatedAt;

      const isDisabled = period.validatedAt !== null;

      let statusPeriod = '';
      if (period.validatedAt) {
        statusPeriod = `(${ t('groupClasses.validated') })`;
      }
      if (period.archivedAt) {
        statusPeriod = `(${ t('groupClasses.archived') })`;
      }

      const labelPeriod = `${ t('payment.period') } ${ index + 1 } ${ statusPeriod }`;

      const datePickerFieldStartValue = !isNaN(startDatePeriod.getTime()) ? startDatePeriod : null;
      const datePickerFieldEndValue = !isNaN(endDatePeriod.getTime()) ? endDatePeriod : null;

      const amount = isDisabled
        ? new Intl.NumberFormat(language, { minimumFractionDigits: 2 }).format(period.amount)
        : period.amount;

      return (
        <div className="periods-container" key={ `period-${ index }` }>
          <div className="periods periods-first-block">
            <DatePickerField
              name="startDate"
              value={ datePickerFieldStartValue }
              label={ labelPeriod }
              onChange={ (e) => handleChangePeriod(e, index, 'startDate') }
              isDisabled={ isDisabled }
              helpProps={ errorsProps[`periods[${ index }].startDate`]?.error }
              color={ errorsProps[`periods[${ index }].startDate`]?.color }
              labelProps={
                errorsProps[`periods[${ index }].startDate`]?.labelProps
                || errorsProps[`periods[${ index }].endDate`]?.labelProps
              }
              id={ `periods[${ index }].startDate` }
              required
            />
            <DatePickerField
              name="endDate"
              value={ datePickerFieldEndValue }
              className="period-end-date"
              onChange={ (e) => handleChangePeriod(e, index, 'endDate') }
              isDisabled={ isDisabled }
              helpProps={ errorsProps[`periods[${ index }].endDate`]?.error }
              color={ errorsProps[`periods[${ index }].endDate`]?.color }
              labelProps={ errorsProps[`periods[${ index }].endDate`]?.labelProps }
              id={ `periods[${ index }].endDate` }
              required
            />
          </div>
          <div className="periods periods-second-block">
            <InputField
              type={ isDisabled ? 'text' : 'number' }
              step={ 1 }
              name="amount"
              value={ amount }
              label={ t('groupClasses.groupClass.detailed.tarif') }
              onChange={ (e) => handleChangeAmount(e, index) }
              isDisabled={ isDisabled }
              helpProps={ errorsProps[`periods[${ index }].amount`]?.error }
              color={ errorsProps[`periods[${ index }].amount`]?.color }
              labelProps={ errorsProps[`periods[${ index }].amount`]?.labelProps }
              id={ `periods[${ index }].amount` }
              required
            />
            <div className="periods-icons">
              <div className="wrapper">
                <IconTrash
                  onClick={ () => handleRemovePeriod(index) }
                  className={ canDelete ? '' : 'icon-disabled' }
                />
              </div>
              <div className="wrapper">
                <Button
                  onClick={ handleAddPeriod }
                  type={ buttonConstants.PRIMARY }
                  className={ index === groupClass.periods.length - 1 ? '' : 'icon-disabled' }
                >
                  <IconPlus />
                </Button>
              </div>
            </div>
          </div>
          <hr />
        </div>
      );
    })
  ), [groupClass.periods, errorsProps]);

  return (
    <div className="input-edit-form periods no-periods">
      <h2 className="periods-title">{ t('payment.periodAndPrice') }</h2>

      { periodsBlock }
      { groupClass.periods.length === 0 && (
        <div className="periods-icons periods-one-icon">
          <Button
            onClick={ handleAddPeriod }
            type={ buttonConstants.PRIMARY }
          >
            <IconPlus />
          </Button>
        </div>
      ) }

      { (errorsProps?.noPeriods?.error || errorsProps?.periods?.error) && (
        <Help
          text={ errorsProps?.noPeriods?.error?.text || errorsProps.periods?.error?.text || '' }
          textColor="is-danger"
          className="input-edit-form"
        />
      ) }
    </div>
  );
};

export default Periods;
