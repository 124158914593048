// @flow
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import ModalYesNo from '@shared/Modal/components/ModalYesNo/ModalYesNo';

import BankDepositService from '@payment/services/BankDepositService';

import type { GroupClassDetailed } from '@groupClass/types';

type Props = {
  deleteBankDepositId: number | null,
  isActive: boolean,
  toggleModal: () => void,
  showSuccessMessage: (message: string) => void,
  groupClass: GroupClassDetailed,
};

const ModalDeleteBankDeposit = (props: Props): React$Node => {
  const {
    deleteBankDepositId,
    isActive,
    toggleModal,
    showSuccessMessage,
    groupClass,
  } = props;

  const { t } = useTranslation();

  const onClickYes = useCallback((): void => {
    if (deleteBankDepositId) {
      BankDepositService.remove(deleteBankDepositId)
        .then(() => {
          showSuccessMessage('payment.depositDeleted');
          const params = { groupClassId: groupClass.id };
          BankDepositService.fetchAll(params);
          toggleModal();
        });
    }
  }, [deleteBankDepositId, groupClass, toggleModal]);

  return (
    <ModalYesNo
      isActive={ isActive }
      toggleModal={ toggleModal }
      onClickYes={ onClickYes }
      title={ t('payment.actionDelete') }
    >
      <>
        <p className="text-modal-grey">
          { t('payment.deleteDepositSentenceOne') }
        </p>
        <p className="text-modal-grey">
          { t('payment.deleteDepositSentenceTwo') }
        </p>
        <p className="text-modal-grey">
          { t('payment.deleteDepositSentenceThree') }
        </p>
      </>
    </ModalYesNo>
  );
};

export default ModalDeleteBankDeposit;
