// @flow
import { useEffect, useState, type AbstractComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';

import GroupClassService, { type GroupClassServiceData } from '@groupClass/services/GroupClassService';

import type { GroupClassDetailed } from '@groupClass/types';

import { WEB_PATHS } from '@app/constants/paths';
const { PAGE_NOT_FOUND } = WEB_PATHS;

export type InjectedProps = {|
  groupClass: GroupClassDetailed | null,
|};

const withGroupClass = <Config>(WrappedComponent: AbstractComponent<{| ...Config, ...InjectedProps |}>): Function => {
  const HOCComponent = (props: Config): React$Node => {
    const { classId } = useParams();
    const { i18n: { language } } = useTranslation();
    const navigate = useNavigate();

    const [ groupClass, setGroupClass ] = useState<GroupClassDetailed | null>(GroupClassService.groupClass);

    const handleGroupClassServiceUpdate = (data: GroupClassServiceData): void => {
      setGroupClass(data.groupClass);
    };

    useEffect(() => GroupClassService.onChange(handleGroupClassServiceUpdate), []);

    useEffect(() => {
      if (classId && parseInt(classId, 10) !== groupClass?.id && !GroupClassService.isFetchingById) {
        GroupClassService.fetchGroupClassById(classId, language)
          .catch(() => navigate(PAGE_NOT_FOUND));
      }
    }, [groupClass, classId, language]);

    return (
      <WrappedComponent { ...props } groupClass={ groupClass } />
    );
  };

  HOCComponent.displayName = 'withGroupClassHOC';

  return HOCComponent;
};

export default withGroupClass;
