// @flow
import clsx from 'clsx';

import { useMemo } from 'react';

import type { LabelProps } from '@core/types';

type Props = {
  children: React$Node,
  isDisabled: boolean,
  className: string,
  required: boolean,
  labelProps?: LabelProps,
};

const Checkbox = (props: Props): React$Node => {
  const {
    id,
    children,
    isDisabled,
    className,
    required,
    labelProps = {},
    ...others
  } = props;

  const labelPropsClassName = labelProps.className ?? '';

  const checkboxClassName = useMemo((): string => clsx({
    'checkbox': true,
    'g-label': true,
    [className]: !!className,
    [labelPropsClassName]: !!labelPropsClassName,
  }), [className, labelPropsClassName]);

  return (
    <div className={ checkboxClassName }>
      <input
        { ...others }
        id={ id }
        type="checkbox"
        className="mr-2"
        disabled={ isDisabled }
      />
      <label htmlFor={ id }>{ children } { required && '*' }</label>
    </div>
  );
};

Checkbox.defaultProps = {
  isDisabled: false,
  className: 'checkbox',
  required: false,
};

export default Checkbox;
