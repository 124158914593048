// @flow
import type { Address, AddressPayload, SimpleAddress, AddressGoogleAutoComplete } from '@shared/Locations/types';

export const formatAddressFromPayload = (addressFromPayload: AddressPayload): Address => ({
  id: addressFromPayload?.id ?? null,
  additionalLocalizationConstruction: addressFromPayload.additionalLocalizationConstruction || '',
  city: addressFromPayload.city || '',
  complementNumber: addressFromPayload?.complementNumber || '',
  country: addressFromPayload.country || '',
  distributionService: addressFromPayload.distributionService || '',
  latitude: addressFromPayload.latitude ?? null,
  longitude: addressFromPayload.longitude ?? null,
  number: addressFromPayload.number ?? null,
  postalCode: addressFromPayload.postalCode || '',
  streetName: addressFromPayload.streetName || '',
});

export const formatSimpleAddressFromPayload = (addressFromPayload: AddressPayload): SimpleAddress => ({
  city: addressFromPayload.city || '',
  country: addressFromPayload.country || '',
  number: addressFromPayload.number ? addressFromPayload.number.toString() : '',
  postalCode: addressFromPayload.postalCode || '',
  streetName: addressFromPayload.streetName || '',
});

export const parsedAddressToPayload = (address: Address): AddressPayload => ({
  ...address,
  id: address.id ?? undefined,
  additionalLocalizationConstruction: address.additionalLocalizationConstruction || null,
  complementNumber: address.complementNumber || null,
  distributionService: address.distributionService || null,
  latitude: address.latitude || null,
  longitude: address.longitude || null,
  number: address.number || null,
});

export const formatAddressFromGoogleAutoComplete = (address: AddressGoogleAutoComplete): SimpleAddress => {
  const { long_name: number = null } = address.address_components.find((c) => c.types.includes('street_number')) || {};
  const { long_name: streetName = '' } = address.address_components.find((c) => c.types.includes('route')) || {};
  const { long_name: postalCode = '' } = address.address_components.find((c) => c.types.includes('postal_code')) || {};
  const { long_name: city = '' } = address.address_components.find((c) => c.types.includes('locality')) || {};
  const { short_name: country = '' } = address.address_components.find((c) => c.types.includes('country')) || {};

  return {
    number,
    streetName,
    postalCode,
    city,
    country,
  };
};

export const formatAddressToString = (address: Address): string => {
  const { number, streetName, postalCode, city } = address;
  return `${ number ? number : '' } ${ streetName } ${ postalCode } ${ city }`;
};

export const formatSimpleAddressToString = (address: SimpleAddress): string => {
  const { number, streetName, postalCode, city } = address;
  return `${ number ? number : '' } ${ streetName } ${ postalCode } ${ city }`;
};
