// @flow
import { useTranslation } from 'react-i18next';
import moment from 'moment/dist/moment';

import type { ShortPaymentCheque } from '@payment/types';

type Props = {
  paymentCheque: ShortPaymentCheque,
  index: number,
};

export const PaymentChequeRow = (props: Props): React$Node => {
  const { paymentCheque, index } = props;

  const { i18n: { language } } = useTranslation();

  const { person } = paymentCheque;
  const { firstName, lastName } = person;

  const nameFormatted = `${ firstName } ${ lastName.toUpperCase() }`;

  const { period } = paymentCheque.groupClassRegisteredPaymentSchedule;

  const periodFormatted = `${ moment(period.startDate).locale(language).format('L') } - ${ moment(period.endDate).locale(language).format('L') }`;

  const amountFormatted = paymentCheque.amount.toLocaleString(language, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <tr className={ index % 2 === 0 ? 'even' : 'odd' }>
      <td className="cell-padding-left">
        { nameFormatted }
      </td>
      <td className="cell-padding" align="center">
        { periodFormatted }
      </td>
      <td className="cell-padding" align="center">
        { paymentCheque.reference }
      </td>
      <td className="cell-padding" align="center">
        { amountFormatted }
      </td>
    </tr>
  );
};

export default PaymentChequeRow;
