// @flow
import { useMemo } from 'react';
import clsx from 'clsx';

type Props = {
  className: string,
  onClick: () => void,
};

const IconFacebook = (props: Props): React$Node => {
  const {
    className,
    onClick,
  } = props;

  const iconSocialClassName = useMemo((): string => clsx({
    'icon-social': true,
    [String(className)]: !!className,
  }), [className]);

  const newProps = {
    className: iconSocialClassName,
    onClick,
  };

  return (
    <svg { ...newProps } viewBox="0 0 52.34 52.67" width="52.34" height="52.67">
      <g>
        <path className="bg"
          d="M0,3.77C0,1.69,1.73,0,3.87,0h44.6c2.14,0,3.87,1.69,3.87,3.77v45.12c0,2.08-1.73,3.77-3.87,3.77H3.87c-2.14,0-3.87-1.69-3.87-3.77V3.77Z"/>
        <path className="icon"
          d="M29.64,20.77h5.4l-.38,4.9h-5.02v17.1h-6.36v-17.1h-3.43v-4.9h3.43v-3.31c0-1.46.06-3.69,1.08-5.09,1.14-1.46,2.67-2.48,5.28-2.48,4.26,0,6.1.64,6.1.64l-.83,5.02s-1.4-.38-2.73-.38-2.48.51-2.48,1.78v3.81h-.06Z"/>
      </g>
    </svg>
  );
};

IconFacebook.defaultProps = {
  className: 'icon-facebook',
  onClick: () => {},
};

export default IconFacebook;
