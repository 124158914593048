// @flow
import EventEmitter from '_common/services/EventEmitter';
import ApiService from '@api/service';
import ObjectHelpers from '@helpers/ObjectHelpers';
import { parseGroupClassOut, hydrateGroupClassDetailedFromPayload } from '@groupClass/helpers/GroupClassesUtils';

import { API_PATHS } from '@app/constants/paths';

const { GROUP_CLASSES, GROUP_CLASSES_ARCHIVE } = API_PATHS;

import type { GroupClassDetailed } from '@groupClass/types';
import type { Error, Listener } from '@core/types';

export type GroupClassServiceData = {
  groupClass: GroupClassDetailed | null,
  loading: boolean,
  errors: Error[],
};

type OnChange = (listener: Listener) => Function;
type FetchGroupClassById = (id: string, language: string) => Promise<GroupClassDetailed>;
type UpdateGroupClass = (groupClass: GroupClassDetailed) => Promise<GroupClassDetailed>;
type ArchiveGroupClass = (groupClass: GroupClassDetailed, seasonClosedAt: string) => Promise<GroupClassDetailed>;
type ResetService = () => void;
type UpdateValues = (newGroupClass: GroupClassDetailed | null, newLoading: boolean, newErrors: Error[]) => void;
type Trigger = () => void;

let fetchByIdToken = ApiService.createToken();

class GroupClassService {
  constructor() {
    this.eventEmitter = new EventEmitter();
    this._groupClass = null;
    this.errors = [];
    this.loading = false;
    this._isFetchingById = false;
  }
  eventEmitter: EventEmitter;
  loading: boolean;
  errors: Error[];
  _groupClass: GroupClassDetailed | null;
  _isFetchingById: boolean;

  get isFetchingById(): boolean { return this._isFetchingById; }
  get groupClass(): GroupClassDetailed | null { return this._groupClass; }

  set isFetchingById(data: boolean): void { this._isFetchingById = data; this.#trigger(); }

  updateValues: UpdateValues = (newGroupClass: GroupClassDetailed | null, newLoading: boolean, newErrors: Error[]): void => {
    this._groupClass = newGroupClass;
    this.loading = newLoading;
    this.errors = newErrors;
    this.#trigger();
  };

  fetchGroupClassById: FetchGroupClassById = (id: string, language: string): Promise<GroupClassDetailed> => {
    this.updateValues(null, true, []);
    this.isFetchingById = true;
    fetchByIdToken.cancel();
    fetchByIdToken = ApiService.createToken();

    if (this._groupClass && id === String(this._groupClass.id)) {
      return Promise.resolve(this._groupClass);
    }

    return ApiService.request({
      method: 'get',
      url: `${ GROUP_CLASSES }/${ id }`,
      headers: {
        'Accept-Language': language,
      },
      cancelToken: fetchByIdToken.token,
    })
      .then((groupClassPayload) => {
        this.isFetchingById = false;
        if (groupClassPayload.data) {
          const groupClass = hydrateGroupClassDetailedFromPayload(groupClassPayload.data);
          this.updateValues(
            groupClass,
            false,
            this.errors,
          );
          return groupClass;
        }
        return Promise.reject();
      })
      .catch((error) => {
        this.updateValues(
          this._groupClass,
          false,
          this.errors,
        );
        this.isFetchingById = false;
        return Promise.reject(error);
      });
  };

  updateGroupClass: UpdateGroupClass = (groupClass: GroupClassDetailed): Promise<GroupClassDetailed> => {

    this.updateValues(this._groupClass, true, this.errors);

    const newGroupClassPayload = parseGroupClassOut(groupClass);

    return ApiService.request({
      method: 'put',
      url: `${ GROUP_CLASSES }/${ groupClass.id }`,
      data: newGroupClassPayload,
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;
          const groupClassData = hydrateGroupClassDetailedFromPayload(data);
          this.updateValues(groupClassData, false, this.errors);
          return groupClassData;
        }
        return Promise.reject();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.violations) {
            const newGroupClass = ObjectHelpers.deepClone(groupClass);
            if (this._groupClass?.seasonClosedAt === null) newGroupClass.seasonClosedAt = null;
            this.updateValues(newGroupClass, false, error.response.data.violations);
            return Promise.reject(error.response.data.violations);
          }
        }
        return Promise.reject();
      });
  };

  archiveGroupClass: ArchiveGroupClass = (groupClass: GroupClassDetailed, seasonClosedAt: string): Promise<GroupClassDetailed> => {

    this.updateValues(this._groupClass, true, this.errors);

    return ApiService.request({
      method: 'put',
      url: GROUP_CLASSES_ARCHIVE.replace(':classId', groupClass.id),
      data: {
        seasonClosedAt,
      },
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;
          const groupClassData = hydrateGroupClassDetailedFromPayload(data);
          this.updateValues(groupClassData, false, this.errors);
          return groupClassData;
        }
        return Promise.reject();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.violations) {
            const newGroupClass = ObjectHelpers.deepClone(groupClass);
            if (this._groupClass?.seasonClosedAt === null) newGroupClass.seasonClosedAt = null;
            this.updateValues(newGroupClass, false, error.response.data.violations);
            return Promise.reject(error.response.data.violations);
          }
        }
        return Promise.reject();
      });
  };

  resetService: ResetService = (): void => {
    this.updateValues(null, false, []);
  };

  onChange: OnChange = (listener: Listener): Function => {
    const listenerFunction = this.eventEmitter.addListener(listener);
    this.#trigger();
    return listenerFunction;
  };

  /**
   * @private
   */
  #trigger: Trigger = (): void => {
    this.eventEmitter.trigger({
      groupClass: this._groupClass,
      loading: this.loading,
      errors: this.errors,
    });
  };
}

const instance: GroupClassService = new GroupClassService();
export default instance;
