// @flow
import { useState, useMemo } from 'react';
import { parsePhoneNumber } from 'react-phone-number-input';
import clsx from 'clsx';
import Input, { getCountryCallingCode } from 'react-phone-number-input/input';
import Select from 'react-select';

import { countryList, COUNTRY_FR, COUNTRIES_SUPPORTED } from '_common/components/phone-field/constants';

import type { Colors, Option, LabelProps, HelpProps } from '@core/types';
import type { IconsProps } from '_common/components/control/Control';
import type { GroupClassDetailed } from '@groupClass/types';
import Field from '_common/components/field/Field';
import Control from '_common/components/control/Control';
import Label from '_common/components/label/Label';
import Help from '_common/components/help/Help';

type Props = {
  className?: string,
  inputLabel?: string,
  inputName: string,
  inputClassName?: string,
  selectName: string,
  inputPlaceHolder?: string,
  selectClassName?: string,
  color?: Colors,
  labelProps?: LabelProps,
  helpProps?: HelpProps,
  icons?: IconsProps,
  controlIsExpanded?: boolean,
  value?: string,
  required?: boolean,
  onChange?: (value: string) => void,
  isDisabled: boolean,
  groupClass?: GroupClassDetailed,
};

const PhoneField = (props: Props): React$Node => {
  const {
    inputLabel,
    inputName,
    selectName,
    selectClassName,
    inputClassName,
    inputPlaceHolder,
    value,
    onChange,
    color,
    labelProps,
    className,
    helpProps,
    icons,
    controlIsExpanded,
    required,
    isDisabled,
    groupClass,
  } = props;

  const [ country, setCountry ] = useState<string>(value && parsePhoneNumber(value) && parsePhoneNumber(value).country || 'FR');

  const labelClassName = labelProps?.className || null;

  const options = (groupClass)
    ? countryList.filter((list) => list.value === groupClass.sector.country)
    : countryList;

  const countryOptions = options.map((country) => (
    { label: `(+${ getCountryCallingCode(country.value) })`, value: country.value, icon: country.icon }
  ));

  const inputClassNameFormated = useMemo((): string => clsx({
    input: true,
    [String(color)]: !!color,
  }), [color]);

  let optionSelected: Option = {
    label: `(+${ getCountryCallingCode(COUNTRY_FR) })`,
    value: COUNTRY_FR,
    icon: countryList.find((element) => element.value === COUNTRY_FR).icon,
  };
  if (groupClass) {
    optionSelected = {
      label: `(+${ getCountryCallingCode(groupClass.sector.country) })`,
      value: groupClass.sector.country,
      icon: countryList.find((element) => element.value === groupClass.sector.country).icon,
    };
  } else if (country && COUNTRIES_SUPPORTED.includes(country)) {
    optionSelected = {
      label: `(+${ getCountryCallingCode(country) })`,
      value: country,
      icon: countryList.find((element) => element.value === country).icon,
    };
  } else if (country && !COUNTRIES_SUPPORTED.includes(country)) {
    optionSelected = {
      label: '(+ ??)',
      value: country,
    };
  }

  const getOptionLabel = (option) => (
    <div className="advanced-select-field-option">
      { option.icon && <div className="option-icon">{ option.icon }</div> }
      <div className="option-label">{ option.label }</div>
    </div>
  );

  const phoneFieldClassName = useMemo((): string => clsx({
    'phone-field': true,
    [String(className)]: !!className,
  }), [className]);

  const phoneFieldLabelClassName = useMemo((): string => clsx({
    'g-label': true,
    'is-flex': true,
    [String(labelClassName)]: !!labelClassName,
  }), [labelClassName]);

  const phoneFieldInputClassName = useMemo((): string => clsx({
    'phone-field-input-row': true,
    [String(inputClassName)]: !!inputClassName,
  }), [inputClassName]);

  const phoneFieldSelectClassName = useMemo((): string => clsx({
    'list-requests-phone-field-country': true,
    [String(selectClassName)]: !!selectClassName,
  }), [selectClassName]);

  return (
    <Field className={ phoneFieldClassName }>
      { inputLabel && (
        <Label
          htmlFor={ inputName }
          className={ phoneFieldLabelClassName }
        >
          { inputLabel }
          { required && ' *' }
        </Label>
      ) }
      <div className={ phoneFieldInputClassName }>
        <Select
          name={ selectName }
          formatOptionLabel={ getOptionLabel }
          className={ phoneFieldSelectClassName }
          options={ countryOptions }
          onChange={ (newValue)=> setCountry(newValue.value) }
          color={ color }
          required={ required }
          value={ optionSelected }
          isDisabled={ isDisabled || groupClass }
        />
        <Control className="control" isExpanded={ controlIsExpanded } icons={ icons }>
          <Input
            country={ country }
            value={ value }
            onChange={ onChange }
            placeholder={ inputPlaceHolder }
            className={ inputClassNameFormated }
            name={ inputName }
            required={ required }
            disabled={ isDisabled }
          />
        </Control>
      </div>
      <div className="helper">
        { helpProps && <Help textColor={ color } { ...helpProps } /> }
      </div>
    </Field>
  );
};

export default PhoneField;
