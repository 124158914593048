// @flow
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import DateService from '_common/services/DateService';
import DateHelpers from '@helpers/DateHelpers';

import type { ShortGroupClass } from '@groupClass/types';
import type { Coach } from '@coach/types';

type Props = {
  groupClass: ShortGroupClass,
  numCols: number,
  showCoach: boolean,
  children?: React$Node | null,
};

const GroupClassRow = (props: Props): React$Node => {
  const { groupClass, numCols, showCoach, children } = props;

  const { t, i18n: { language } } = useTranslation();

  const mainCoach = useMemo((): Coach | null => (
    groupClass.coaches.find((coach) => coach.isMainCoach) ?? null
  ), [groupClass]);

  const mainCoachName = useMemo((): string => (
    mainCoach?.sielBleuUser.firstName || ''
  ), [mainCoach]);

  const withCoach = useMemo((): boolean => showCoach && mainCoach !== null, [mainCoach, showCoach]);

  return (
    <>
      <tr className="bg-dark-blue txt-white">
        <td className="cell-padding-left" colSpan={ numCols }>
          <div className="group-class-infos">
            <div className="group-class-payment-title">{ groupClass.activity.name }</div>
            <div className="group-class-payment-infos">
              <span>
                { groupClass.address.postalCode }
                &nbsp;
                { groupClass.address.city }
              </span>
              <span className="spaces">&nbsp;-&nbsp;</span>
              <span>
                { DateHelpers.getDayOfWeek(groupClass.day, language) }
                &nbsp;-&nbsp;
                { DateService.localTimeDisplay(new Date(groupClass.startTime), language) }
              </span>
              { withCoach && (
                <span>
                  <span className="spaces">&nbsp;</span>
                  { t('common.with').toLocaleLowerCase() }
                  &nbsp;
                  { mainCoachName }
                </span>
              ) }
            </div>
          </div>
        </td>
      </tr>

      { children }

    </>
  );
};

GroupClassRow.defaultProps = {
  showCoach: false,
};

export default GroupClassRow;
