// @flow

class FormatHelpers {
  /**
   * frenchNumber
   * Convert french number in french phone number stringify, with right spaces
   * @param {string} phone
   * @returns {string}
   */

  static frenchNumber(phone: string): string {
    const phoneArray = phone.split('');
    let numberFormatted = '';
    let count = 0;
    for (let i = 0; i < phoneArray.length; i++) {
      numberFormatted += phoneArray[i];
      count++;
      if (count % 2 === 0 && count !== phoneArray.length) {
        numberFormatted += ' ';
      }
    }
    return numberFormatted;
  }

  /**
   * spanishNumber
   * Convert spanish number in spanish phone number stringify, with right spaces
   * @param {string} phone
   * @returns {string}
   */
  static spanishNumber(phone: string): string {
    const phoneArray = phone.split('');
    let numberFormatted = phoneArray.slice(0, 3).join('')+' ';
    let count = 0;
    for (let i = 3; i < phoneArray.length; i++) {
      numberFormatted += phoneArray[i];
      count++;
      if (count % 2 === 0 && count !== phoneArray.length) {
        numberFormatted += ' ';
      }
    }
    return numberFormatted;
  }

  /**
   * belgiumNumber
   * Convert belgium number in belgium phone number stringify, with right spaces
   * @param {string} phone
   * @returns {string}
   */
  static belgiumNumber(phone: string): string {
    const phoneArray = phone.split('');
    let numberFormatted = '';
    let count = 0;
    for (let i = 0; i < phoneArray.length; i++) {
      numberFormatted += phoneArray[i];
      count++;
      if (
        ((count % 2 === 0 && count < 3)
        || (count % 2 !== 0 && count > 4))
        && count !== phoneArray.length
      ) {
        numberFormatted += ' ';
      }
    }
    return numberFormatted;
  }

  /**
   * phoneFormatter
   * Convert the given phone number into the right phone number stringify format
   * @param {string} phoneNumber
   * @returns {string}
   */
  static phoneFormatter(phoneNumber: string): string {
    if (phoneNumber.startsWith('+33')) {
      return FormatHelpers.frenchNumber(`0${ phoneNumber.slice(3) }`);
    }
    if (phoneNumber.startsWith('+32')) {
      return FormatHelpers.belgiumNumber(`0${ phoneNumber.slice(3) }`);
    }
    if (phoneNumber.startsWith('+34')) {
      return FormatHelpers.spanishNumber(phoneNumber.slice(3));
    }
    return phoneNumber;
  }
}

export default FormatHelpers;
