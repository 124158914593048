// @flow
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import moment from 'moment/dist/moment';

import PaymentInfoBubble from '@shared/Payment/components/PaymentInfoBubble';

import { formatAmount } from '@payment/helpers/AmountUtils';

import type { PaymentConditionType } from '@paymentConditionType/types';
import type { PaymentSchedule } from '@groupClass/types';

type Props = {
  paymentConditions: PaymentConditionType[],
  paymentSchedule: PaymentSchedule,
};

const PaymentInformationRow = (props: Props): React$Node => {
  const {
    paymentConditions,
    paymentSchedule,
  } = props;
  const { t, i18n: { language } } = useTranslation();

  const { amount, discount, costAdjust, period } = paymentSchedule;

  const startDate = moment(period.startDate).locale(language).format('L');
  const endDate = moment(period.endDate).locale(language).format('L');

  const amountDue = amount + discount + costAdjust;

  const [ isBubbleVisible, setIsBubbleVisible ] = useState<boolean>(false);

  let statusPeriod = '';
  if (period.validatedAt) {
    statusPeriod = t('groupClasses.validated');
  }
  if (period.archivedAt) {
    statusPeriod = t('groupClasses.archived');
  }

  const toggleBubble = useCallback((value?: boolean): void => {
    setIsBubbleVisible(value ?? !isBubbleVisible);
  }, [isBubbleVisible]);

  const isPeriodValidated = useMemo((): string => clsx({
    'is-period-validated': !!statusPeriod,
  }), [statusPeriod]);

  return (
    <tr className={ isPeriodValidated } >
      <td>{ `${ startDate } - ${ endDate }` }</td>
      <td>
        <span className="amountEuro">{ formatAmount(period.amount, language) }</span> &euro;
      </td>
      <td>
        <span className="amountEuro">{ formatAmount(paymentSchedule.discount, language) }</span> &euro;
        <PaymentInfoBubble
          paymentConditions={ paymentConditions }
          isVisible={ isBubbleVisible }
          toggleVisible={ toggleBubble }
        />
      </td>
      <td>
        <span className="amountEuro">{ formatAmount(paymentSchedule.costAdjust, language) }</span> &euro;
      </td>
      <td>
        <span className="amountEuro">{ formatAmount(amountDue > 0 ? amountDue : 0, language) }</span> &euro;
      </td>
      <td>{ statusPeriod }</td>
    </tr>
  );
};

export default PaymentInformationRow;
