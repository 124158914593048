// @flow
import { useState, useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format as dateFmt } from 'date-fns';

import withGroupClass from '@hoc/withGroupClass';

import AdminLayout from '@layout/components/AdminLayout';

import ListCheques from '@payment/components/EditBankDeposit/ListCheques';
import DatePickerField from '_common/components/DateTimePicker/components/DatePickerField';
import InputField from '_common/components/input-field/InputField';
import Button, { buttonConstants } from '@shared/Button';
import Toast from '_common/services/Toast/Toast';
import ButtonGoBack from '@shared/Navigation/ButtonGoBack';
import Loader from '_common/components/loader/Loader';

import BankDepositService, { type BankDepositServiceData } from '@payment/services/BankDepositService';
import TranslationHelpers, { ERROR_IS_BLANK } from '@helpers/TranslationHelpers';
import { initialBankDeposit } from '@payment/helpers/BankDepositUtils';

import type { BankDepositDetailed, ShortPaymentCheque } from '@payment/types';
import type { Error, ErrorProps } from '@core/types';
import type { GroupClassDetailed } from '@groupClass/types';

import { LOADER_TYPE_BACKGROUND } from '_common/components/loader/constants';
import { FORMAT_DATE_API } from '@app/constants/dates';
import { errorColor } from '@app/constants/styles';
import { WEB_PATHS } from '@app/constants/paths';
import { RE_1_TO_30_TEXT_DIGITS, RE_ONLY_TEXT_NUMBERS } from '@helpers/Regex';

import { ADMIN_HEADER_PAYMENTS } from '@layout/constants';
const { PAYMENTS_BANK_DEPOSITS, PAGE_NOT_FOUND } = WEB_PATHS;

type useParamsType = {
  depositId: string,
};

type Props = {
  groupClass: GroupClassDetailed | null,
};

export const EditBankDeposit = (props: Props): React$Node => {
  const { groupClass } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { depositId } = useParams<useParamsType>();

  const [ bankDeposit, setBankDeposit ] = useState<BankDepositDetailed>(initialBankDeposit);
  const [ isLoading, setIsLoading ] = useState<boolean>(true);
  const [ errors, setErrors ] = useState<Error[]>(BankDepositService.errors);
  const [ isActiveModalAddCheque, setIsActiveModalAddCheque ] = useState<boolean>(false);

  const toggleModalAddCheque = useCallback((): void => {
    setIsActiveModalAddCheque(!isActiveModalAddCheque);
  }, [isActiveModalAddCheque]);

  const handleUpdateDeposit = (data: BankDepositServiceData): void => {
    if (data.deposit) {
      setBankDeposit(data.deposit);
    }
    setIsLoading(data.isLoading);
    setErrors(data.errors);
  };

  useEffect(() => BankDepositService.onChange(handleUpdateDeposit), []);

  useEffect((): Function => {
    if (groupClass) {
      BankDepositService.fetchOne(parseInt(depositId, 10))
        .then(setBankDeposit)
        .catch(() => navigate(PAGE_NOT_FOUND));
    }
    return () => BankDepositService.reset();
  }, [depositId, groupClass]);

  const handleReferenceChange = useCallback((e: SyntheticEvent<HTMLInputElement>): void => {
    const { value } = e.currentTarget;
    if (value.match(RE_1_TO_30_TEXT_DIGITS) || value === '') {
      setBankDeposit({ ...bankDeposit, reference: value });
      setErrors(errors.filter((error) => error.propertyPath !== 'reference'));
    }
  }, [bankDeposit]);

  const handleDateChange = useCallback((updatedDate: moment$Moment): void => {
    const formattedDate = new Date(String(updatedDate));
    const depositDate = new Date(dateFmt(formattedDate, FORMAT_DATE_API));
    setBankDeposit({ ...bankDeposit, depositDate });
    setErrors(errors.filter((error) => error.propertyPath !== 'depositDate'));
  }, [bankDeposit, errors, setErrors]);

  const handleRemoveCheque = useCallback((cheque: ShortPaymentCheque): void => {
    const updatedCheques = bankDeposit.paymentCheques.filter((item) => item.id !== cheque.id);
    setBankDeposit({ ...bankDeposit, paymentCheques: updatedCheques });
    showSuccessMessage('payment.bankDepositDeleted');
  }, [bankDeposit]);

  const handleAddCheque = useCallback((cheque: ShortPaymentCheque): void => {
    const updatedCheques = bankDeposit.paymentCheques;
    updatedCheques.push(cheque);
    setBankDeposit({ ...bankDeposit, paymentCheques: updatedCheques });
  },[bankDeposit]);

  const showSuccessMessage = useCallback((message: string): void => {
    Toast.success(t(message));
  }, [t]);

  const handleSubmit = useCallback((): void => {
    if (groupClass) {
      const { id, reference, depositDate } = bankDeposit;
      const formErrors = [...errors];

      if (!reference) {
        formErrors.push({ propertyPath: 'reference', code: ERROR_IS_BLANK, message: '' });
      }

      if (formErrors.length === 0) {
        BankDepositService.update(id, reference, dateFmt(depositDate, FORMAT_DATE_API))
          .then(() => {
            showSuccessMessage('payment.bankDepositUpdated');
            navigate(`${ PAYMENTS_BANK_DEPOSITS.replace(':classId', String(groupClass.id)) }`);
          });
      }

      setErrors(formErrors);
    }
  }, [bankDeposit, errors, setErrors, showSuccessMessage, groupClass]);

  const errorsProps = useMemo((): ErrorProps => {
    let errorsPropsTmp = {};
    errors.forEach((error) => {
      Object.assign(errorsPropsTmp, {
        [`${ error.propertyPath }`]: {
          color: 'is-danger',
          borderColor: errorColor,
          labelProps: { className: 'has-text-danger' },
          labelTextAreaProps: {
            className: 'label has-text-danger',
          },
          selectFieldHelperText: error.propertyPath === 'reference'
            ? { text: t(TranslationHelpers.getCommonErrorKeyByCode(error.code, 'referenceBankDeposit')), textColor: 'is-danger' }
            : { text: t(TranslationHelpers.getCommonErrorKeyByCode(error.code, error.propertyPath)), textColor: 'is-danger' },
          error: {
            text: t(TranslationHelpers.getCommonErrorKeyByCode(error.code, error.propertyPath)),
            textColor: 'is-danger',
          },
        },
      });
    });

    return errorsPropsTmp;
  }, [errors]);

  return (
    <AdminLayout groupClass={ groupClass } activeTab={ ADMIN_HEADER_PAYMENTS } preventPortraitMode>
      <div className="bank-deposit">
        <div className="bank-deposit-container container">
          { groupClass && (
            <ButtonGoBack />
          ) }

          { isLoading
            ? (
              <Loader loaderType={ LOADER_TYPE_BACKGROUND } />
            ) : (
              <>
                <div className="bank-deposit-header">
                  <div className="bank-deposit-title">
                    <h1>{ t('payment.editBankDepositTitle') }</h1>
                  </div>
                  <div className="edit-bank-deposit-fields">
                    <InputField
                      id="reference"
                      name="reference"
                      label={ t('payment.bankDepositNumber') }
                      onChange={ handleReferenceChange }
                      value={ isLoading ? undefined : bankDeposit.reference }
                      color={ errorsProps.reference?.color }
                      labelProps={ errorsProps.reference?.labelProps }
                      borderColor={ errorsProps.reference?.borderColor }
                      helpProps={ errorsProps.reference?.selectFieldHelperText }
                      className="edit-bank-deposit-field"
                      type="text"
                      unauthorizedKeys={ RE_ONLY_TEXT_NUMBERS }
                      required
                    />
                    <DatePickerField
                      id="depositDate"
                      name="depositDate"
                      value={ isLoading ? null : bankDeposit.depositDate }
                      onChange={ handleDateChange }
                      label={ t('payment.depositDate') }
                      color={ errorsProps.depositDate?.color }
                      labelProps={ errorsProps.depositDate?.labelProps }
                      helpProps={ errorsProps.depositDate?.selectFieldHelperText }
                      className="edit-bank-deposit-field"
                      required
                    />
                  </div>
                </div>

                <ListCheques
                  isLoading={ isLoading }
                  isActiveModalAddCheque={ isActiveModalAddCheque }
                  toggleModalAddCheque={ toggleModalAddCheque }
                  paymentCheques={ bankDeposit?.paymentCheques || [] }
                  removeCheque={ handleRemoveCheque }
                  addCheque={ handleAddCheque }
                  groupClass={ groupClass }
                  bankDeposit={ bankDeposit }
                />

                <div className="validation-button">
                  <Button
                    type={ buttonConstants.PRIMARY }
                    onClick={ handleSubmit }
                  >
                    { t('payment.validateChanges') }
                  </Button>
                </div>
              </>
            )
          }
        </div>
      </div>
    </AdminLayout>
  );
};

export default withGroupClass(EditBankDeposit);
