// @flow
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import moment from 'moment/dist/moment';

import type { Period, PeriodItemOutput } from '@groupClass/types';

import IconCircleArrow from '@icons/components/IconCircleArrow';

type Props = {
  periods: Period[],
  period: PeriodItemOutput | null,
  indexOfPeriod: number,
  handlePeriod: (type: string) => void,
};

const PeriodSelector = (props: Props): React$Node => {
  const { t, i18n: { language } } = useTranslation();
  const { periods, period, indexOfPeriod, handlePeriod } = props;

  const titlePeriod = useMemo ((): string => {
    const now = moment();
    if (period && period.validatedAt) {
      return t('payment.validatedPeriod');
    } else if (period && moment(period.startDate).utc().isSameOrBefore(now, 'day') && moment(period.endDate).utc().isSameOrAfter(now, 'day')) {
      return t('payment.currentPeriod');
    }
    return t('payment.period');
  }, [period, language]);

  const startDate = useMemo((): string => (
    period ? moment(period.startDate).locale(language).format('L') : ''
  ), [period, language]);

  const endDate = useMemo((): string => (
    period ? moment(period.endDate).locale(language).format('L') : ''
  ), [period, language]);

  return (
    <div className="period-selector">
      { period && (
        <>
          <div>
            { indexOfPeriod > 0 && (
              <IconCircleArrow onClick={ ()=> handlePeriod('previous') } />
            ) }
          </div>
          <div className="period-text">
            <p className="period-title">{ titlePeriod } ({ indexOfPeriod + 1 }/{ periods.length }){ t('common.colons') }</p>
            <p className="period-data">{ startDate } - { endDate }</p>
          </div>
          <div>
            { indexOfPeriod < (periods.length - 1) && (
              <IconCircleArrow onClick={ () => handlePeriod('next') } className="rotate-180" />
            ) }
          </div>
        </>
      ) }
    </div>
  );
};

export default PeriodSelector;
