// @flow
import { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Link } from 'react-router-dom';

import { uppercaseFirst } from '_common/services/CommonUtils';
import DateService from '_common/services/DateService';

import IconBurgerMenu from '@icons/components/IconBurgerMenu';
import Menu from '_common/components/menu/Menu';
import Tabs from '@shared/Navigation/Tabs';
import IconChevron from '@icons/components/IconChevron';
import RequestsService, { type RequestsServiceData } from '@beneficiary/services/RequestsService';

import type { GroupClassDetailed } from '@groupClass/types';

import { groupClassDetailedTabs } from '@app/helpers/tabs';

import * as layoutTabs from '@layout/constants';
import { GROUP_CLASS_REQUEST_INITIATED } from '@app/constants/constants';
import { WEB_PATHS } from '@app/constants/paths';
const { PAYMENTS, GROUP_SEND, PARTICIPANTS, GROUP_CLASS } = WEB_PATHS;

const COUNT_REQUEST_PARAMS = {
  itemsPerPage: 1,
  page: 1,
  status: GROUP_CLASS_REQUEST_INITIATED,
};

type Props = {
  groupClass: GroupClassDetailed,
  tab: string,
};

const GroupClassAdminHeader = (props: Props): React$Node => {
  const { t, i18n: { language } } = useTranslation();
  const { groupClass, tab } = props;

  const [ isMenuOpen, setIsMenuOpen ] = useState<boolean>(false);
  const [ countRequests, setCountRequests ] = useState<number>(RequestsService.countRequests);

  const handleRequestsUpdateState = (data: RequestsServiceData): void => {
    setCountRequests(data.countRequests);
  };

  useEffect(() => RequestsService.onChange(handleRequestsUpdateState), []);

  useEffect(() => {
    RequestsService.countGroupClassRequests(groupClass.id, language, COUNT_REQUEST_PARAMS);
    return () => RequestsService.resetCountRequest();
  }, [groupClass, language]);

  const toggleMenu = useCallback((): void => {
    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);

  const activeTab = tab || layoutTabs.ADMIN_HEADER_INFORMATION;

  const groupClassAdminHeaderInfosClassName = useMemo((): string => clsx({
    'menu-link': true,
    'is-active' : !tab || tab === layoutTabs.ADMIN_HEADER_INFORMATION,
  }), [tab]);

  const groupClassAdminHeaderPeoplesClassName = useMemo((): string => clsx({
    'menu-link': true,
    'is-active': tab === layoutTabs.ADMIN_HEADER_PARTICIPANTS,
  }), [tab]);

  const groupClassAdminHeaderPaymentsClassName = useMemo((): string => clsx({
    'menu-link': true,
    'is-active': tab === layoutTabs.ADMIN_HEADER_PAYMENTS,
  }), [tab]);

  const groupClassAdminHeaderSendingClassName = useMemo((): string => clsx({
    'menu-link': true,
    'is-active' : tab === layoutTabs.ADMIN_HEADER_SENDING_GROUP,
  }), [tab]);

  const activityName = useMemo((): string => (
    groupClass.activity.name
  ), [groupClass]);

  const address = useMemo((): string => (
    groupClass.address.postalCode
  ), [groupClass]);

  const city = useMemo((): string => (
    uppercaseFirst(groupClass.address.city.toLocaleLowerCase())
  ), [groupClass]);

  const date = useMemo((): string => (
    DateService.localDateAndTimeDisplay(groupClass.day, new Date(groupClass.startTime), new Date(groupClass.endTime), language)
  ), [groupClass]);

  return (
    <div className="group-class-admin-header">
      <div className="container-group-class-admin-header container">
        <div className="header">
          <div className="header-title">
            <IconBurgerMenu className="icon-burger-menu" onClick={ toggleMenu } />
            <div className="group-class-admin-header-desktop">
              <h2 className="group-class-admin-header-name">{ activityName }</h2>
              <div className="group-class-admin-header-address">
                { address }&nbsp;
                <span>{ city }</span> -&nbsp;
                { date }
              </div>
            </div>
          </div>
        </div>
        <div className="group-class-admin-header-navigation">
          <Tabs tabs={ groupClassDetailedTabs(groupClass.id, countRequests) } activeTab={ activeTab } />
        </div>
      </div>

      <Menu onClick={ toggleMenu } isOpen={ isMenuOpen }>
        <li>
          <Link
            to={ GROUP_CLASS.replace(':classId', String(groupClass.id)) }
            className={ groupClassAdminHeaderInfosClassName }
          >
            { t('groupClasses.groupClass.navigation.infos') }
            <IconChevron />
          </Link>
        </li>
        <li>
          <Link
            to={ PARTICIPANTS.replace(':classId', String(groupClass.id)) }
            className={ groupClassAdminHeaderPeoplesClassName }
          >
            { t('user.participants') }
            <IconChevron className="rotate-180" />
          </Link>
        </li>
        <li>
          <Link
            to={ PAYMENTS.replace(':classId', String(groupClass.id)) }
            className={ groupClassAdminHeaderPaymentsClassName }
          >
            { t('groupClasses.groupClass.navigation.payments') }
            <IconChevron />
          </Link>
        </li>
        <li>
          <Link
            to={ GROUP_SEND.replace(':classId', String(groupClass.id)) }
            className={ groupClassAdminHeaderSendingClassName }
          >
            { t('notification.send.toGroup') }
            <IconChevron className="rotate-180" />
          </Link>
        </li>
      </Menu>
    </div>
  );
};

export default GroupClassAdminHeader;
