// @flow
import type { Balance, CreditCardBalance, ChequeBalance, StatusPayment } from '@payment/types';

import {
  PAYMENT_STATUS_NOT_APPLICABLE,
  PAYMENT_STATUS_PENDING,
  PAYMENT_STATUS_TOFINALIZE,
  PAYMENT_STATUS_ACCEPTED,
  PAYMENT_STATUS_OVERPAID,
} from '@payment/constants';

type SortedPaymentBalance<T> = {
  [string]: T[],
};

export const filterBalances = (balances: Array<Balance>, status: StatusPayment): Array<Balance> => (
  balances.filter((balance) => balance.status === status)
);

export const filterChequeBalances = (balances: Array<ChequeBalance>, status: StatusPayment): Array<ChequeBalance> => (
  balances.filter((balance) => balance.status === status)
);

export const sortBalanceSubscribed = (balances: Array<Balance>, status: StatusPayment): Array<Balance> => (
  filterBalances(balances, status)
    .filter((balance) => balance.unsubscribedAt === null)
    .sort((a, b) => a.person.lastName.localeCompare(b.person.lastName))
);

export const sortBalanceUnsubscribed = (balances: Array<Balance>, status: StatusPayment): Array<Balance> => (
  filterBalances(balances, status)
    .filter((balance) => balance.unsubscribedAt !== null)
    .sort((a, b) => a.person.lastName.localeCompare(b.person.lastName))
);

export const sortPaymentsBalanceByName = (balances: Array<any>): SortedPaymentBalance<any> => {
  const sortedPersonsBalances = {};
  balances
    .slice()
    .sort((a, b) => {
      const nameA = `${ a.person.lastName } ${ a.person.firstName } ${ a.person.id }`;
      const nameB = `${ b.person.lastName } ${ b.person.firstName } ${ b.person.id }`;
      return nameA.localeCompare(nameB);
    })
    .sort((a, b) => {
      if (a.unsubscribedAt === null && b.unsubscribedAt !== null) {
        return -1;
      }
      if (a.unsubscribedAt !== null && b.unsubscribedAt === null) {
        return 1;
      }
      return 0;
    })
    .forEach((element) => {
      const index = `${ element.person.lastName }${ element.person.firstName }${ element.person.id }`;
      if (!sortedPersonsBalances?.[index]) {
        sortedPersonsBalances[index] = [];
      }
      sortedPersonsBalances[index].push(element);
    });
  return sortedPersonsBalances;
};

export const sortChequeBalance = (balances: ChequeBalance[]): ChequeBalance[][] => {
  const sortedPersonsBalances: SortedPaymentBalance<ChequeBalance> = sortPaymentsBalanceByName(balances);
  const sortedBalances = [];

  Object
    .keys(sortedPersonsBalances)
    .map((key: string): ChequeBalance[] => sortedPersonsBalances[key])
    .forEach((balancesPaymentStatus: ChequeBalance[]): void => {
      const toFinalizeBalances = filterChequeBalances(balancesPaymentStatus, PAYMENT_STATUS_TOFINALIZE)
        .sort((a, b) => (b?.paidAt || 0) - (a?.paidAt || 0));
      const acceptedBalances = filterChequeBalances(balancesPaymentStatus, PAYMENT_STATUS_ACCEPTED)
        .sort((a, b) => (b?.paidAt || 0) - (a?.paidAt || 0));
      sortedBalances.push([...toFinalizeBalances, ...acceptedBalances]);
    });

  return sortedBalances;
};

export const sortCreditCardBalance = (balances: CreditCardBalance[]): CreditCardBalance[][] => {
  const sortedPersonsBalances: SortedPaymentBalance<CreditCardBalance> = sortPaymentsBalanceByName(balances);
  const sortedBalances = [];

  Object
    .keys(sortedPersonsBalances)
    .map((key: string): CreditCardBalance[] => sortedPersonsBalances[key])
    .forEach((balancesPerson: CreditCardBalance[]) => {
      const balancesSortedPerPaidAt = balancesPerson.sort((a: CreditCardBalance, b: CreditCardBalance) => (b?.paidAt || 0) - (a?.paidAt || 0));
      sortedBalances.push([...balancesSortedPerPaidAt]);
    });

  return sortedBalances;
};

export const sortBalancesByTypes = (balances: Array<Balance>): Array<Balance> => {
  const notApplicableBalances = sortBalanceSubscribed(balances, PAYMENT_STATUS_NOT_APPLICABLE);
  const waitingBalances = sortBalanceSubscribed(balances, PAYMENT_STATUS_PENDING);
  const toFinalizeBalances = sortBalanceSubscribed(balances, PAYMENT_STATUS_TOFINALIZE);
  const acceptedBalances = sortBalanceSubscribed(balances, PAYMENT_STATUS_ACCEPTED);
  const overpaidBalances = sortBalanceSubscribed(balances, PAYMENT_STATUS_OVERPAID);
  const waitingBalancesUnsubscribed = sortBalanceUnsubscribed(balances, PAYMENT_STATUS_PENDING);
  const toFinalizeBalancesUnsubscribed = sortBalanceUnsubscribed(balances, PAYMENT_STATUS_TOFINALIZE);
  const acceptedBalancesUnsubscribed = sortBalanceUnsubscribed(balances, PAYMENT_STATUS_ACCEPTED);
  const overpaidBalancesUnsubscribed = sortBalanceUnsubscribed(balances, PAYMENT_STATUS_OVERPAID);

  return [
    ...notApplicableBalances,
    ...waitingBalances,
    ...toFinalizeBalances,
    ...acceptedBalances,
    ...overpaidBalances,
    ...waitingBalancesUnsubscribed,
    ...toFinalizeBalancesUnsubscribed,
    ...acceptedBalancesUnsubscribed,
    ...overpaidBalancesUnsubscribed,
  ];
};
