// @flow
import type { GroupClassDetailed, ShortGroupClass } from '@groupClass/types';
import type { User } from '@user/types';

export const isCoachInGroupClass = (groupClass: GroupClassDetailed, userData: User): boolean => {
  const { coaches } = groupClass;
  return coaches.findIndex((coach) => coach.sielBleuUser.id === userData.id) !== -1;
};

export const extractShortGroupClassFromDetailed = (groupClass: GroupClassDetailed): ShortGroupClass => ({
  id: groupClass.id,
  activity: groupClass.activity,
  address: groupClass.address,
  coaches: groupClass.coaches,
  day: groupClass.day,
  endTime: groupClass.endTime,
  name: groupClass.name,
  seasonKey: groupClass.seasonKey,
  seasonClosedAt: groupClass.seasonClosedAt ? groupClass.seasonClosedAt : '',
  startTime: groupClass.startTime,
});