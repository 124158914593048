// @flow
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import PublicRoute from '@shared/PublicRoute/components/PublicRoute';
import Loader from '_common/components/loader/Loader';
import GroupClassesService from '@groupClass/services/GroupClassesService';
import SielBleuUserService from '@user/services/SielBleuUserService';
import AuthService from '@user/services/AuthService';

import { WEB_PATHS } from '@app/constants/paths';

const { HOME } = WEB_PATHS;

const ReturnLogoutSSO = (): React$Node => {
  const navigate = useNavigate();

  const { search } = useLocation();

  const redirectTo = new URLSearchParams(search.split('?')[1]).get('redirect_to') ?? '';

  useEffect(() => {
    GroupClassesService.resetService();
    SielBleuUserService.reset();
    AuthService.disconnect();
    if (redirectTo) {
      redirectTo[0] === '/' ? navigate(redirectTo) : window.location.assign(redirectTo);
    } else {
      navigate(HOME);
    }
  }, [redirectTo]);

  return (
    <PublicRoute>
      <div className="column is-flex is-justify-content-center is-align-items-center is-full-height">
        <Loader />
      </div>
    </PublicRoute>
  );
};

export default ReturnLogoutSSO;
