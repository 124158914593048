// @flow
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import RolesHelpers from '@user/helpers/RolesHelpers';
import AuthService, { type AuthServiceData } from '@user/services/AuthService';
import LoginService from '@login/services/LoginService';
import Loader from '_common/components/loader/Loader';

import type { User } from '@user/types';

import { LOADER_TYPE_PAGE } from '_common/components/loader/constants';
import { PROMPT_TYPE_LOGIN } from '@login/constants';
import { WEB_PATHS } from '@app/constants/paths';
const { PAGE_NOT_FOUND } = WEB_PATHS;

type Props = {
  children: React$Node,
};

const BeneficiaryRoute = (props: Props): React$Node => {
  const {
    children,
  } = props;

  const navigate = useNavigate();

  const [ currentUser, setCurrentUser ] = useState<User | null>(AuthService.user);
  const [ isUserLoading, setIsUserLoading ] = useState<boolean>(AuthService.isLoading);
  const [ loginUrl, setLoginUrl ] = useState<string>(LoginService.loginUrl);
  const [ canAccessComponent, setCanAccessComponent ] = useState<boolean>(false);

  useEffect(() => {
    LoginService.getLoginUrl({ prompt: PROMPT_TYPE_LOGIN })
      .then(setLoginUrl)
      .catch(() => {});
  }, []);

  const handleUpdateAuthService = (data: AuthServiceData) => {
    setCurrentUser(data.user);
    setIsUserLoading(data.isLoading);
  };

  useEffect(() => AuthService.onChange(handleUpdateAuthService), []);

  useEffect(() => {
    if (!isUserLoading) {
      if (loginUrl && !currentUser) {
        window.location.href = loginUrl;
      } else if (currentUser && !RolesHelpers.isBeneficiary(currentUser)) {
        navigate(PAGE_NOT_FOUND);
      } else if (RolesHelpers.isBeneficiary(currentUser)) {
        setCanAccessComponent(true);
      }
    }
  }, [
    isUserLoading,
    currentUser,
    loginUrl,
    setCanAccessComponent,
  ]);

  return (
    <>
      { canAccessComponent
        ? children
        : <Loader loaderType={ LOADER_TYPE_PAGE } />
      }
    </>
  );
};

export default BeneficiaryRoute;
