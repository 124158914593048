// @flow
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '@shared/Modal/Modal';
import Button, { buttonConstants } from '@shared/Button';
import AdvancedSelectField from '@shared/AdvancedSelectField/components/AdvancedSelectField';

import DateService from '_common/services/DateService';
import DateHelpers from '@helpers/DateHelpers';

import RegisteredsService from '@groupClass/services/RegisteredsService';
import GroupClassPeriodService from '@groupClass/services/GroupClassPeriodService';
import PaymentTransferService from '@payment/services/PaymentTransferService';

import TranslationHelpers, { ERROR_IS_BLANK } from '@helpers/TranslationHelpers';

import { errorColor } from '@app/constants/styles';
import type { Period, ShortGroupClass, UserRegistered } from '@groupClass/types';
import type { CreditCardBalance } from '@payment/types';
import type { ErrorProps, Error, Option } from '@core/types';

type Props = {
  isActive: boolean,
  toggleModal: () => void,
  balance: CreditCardBalance,
  onTransferSuccess: () => void,
};

const ModalTransferPayment = (props: Props): React$Node => {
  const { isActive, toggleModal, onTransferSuccess, balance } = props;
  const { id: paymentId, registeredId } = balance;

  const { t, i18n: { language } } = useTranslation();

  const [ registered, setRegistered ] = useState<UserRegistered | null>(null);
  const [ groupClassOption, setGroupClassOption ] = useState<Option | null>(null);
  const [ periodOption, setPeriodOption ] = useState<Option | null>(null);
  const [ periodOptions, setPeriodOptions ] = useState<Option[]>([]);
  const [ errors, setErrors ] = useState<Error[]>([]);

  useEffect((): void => {
    if (isActive) {
      setGroupClassOption(null);
      setPeriodOption(null);
      setPeriodOptions([]);
      setErrors([]);
      RegisteredsService.fetchOne(registeredId, language)
        .then(setRegistered)
        .catch(() => {
          toggleModal();
          // error notification toast
        });
    }
  }, [
    isActive,
    registeredId,
    language,
  ]);

  const formatClassName = useCallback((groupClass: ShortGroupClass): string => (
    `${ groupClass.activity.name } - `
    + `${ groupClass.address.postalCode } ${ groupClass.address.city } `
    + `${ DateHelpers.getDayOfWeek(groupClass.day, language) } ${ DateService.localTimeDisplay(new Date(groupClass.startTime), language) }`
  ), [t]);

  const groupClassOpts = useMemo((): Option[] => (
    registered?.groupClassesRegisteredTo?.map((groupClass) => ({
      label: formatClassName(groupClass),
      value: String(groupClass.id),
    })) || []
  ), [registered]);

  const formatPeriod = useCallback((period: Period): string => (
    `${ DateService.localDateDisplay(new Date(period.startDate), language) }`
    + ' - '
    + `${ DateService.localDateDisplay(new Date(period.endDate), language) }`
  ), [language]);

  const fetchPeriodOptions = useCallback((groupClassId: number): void => {
    GroupClassPeriodService.fetchAll({
      'exists[archivedAt]': false,
      'exists[validatedAt]': false,
      groupClass: groupClassId,
    })
      .then((periods) => {
        const opts = periods
          .filter((period) => period.id !== balance.periodId)
          .map((period) => ({
            label: formatPeriod(period),
            value: String(period.id),
          }));
        setPeriodOption(null);
        setPeriodOptions(opts);
      });
  }, [formatPeriod]);

  const handleClassChange = useCallback((opt: Option | null): void => {
    setGroupClassOption(opt);
    fetchPeriodOptions(parseInt(opt?.value, 10));
  }, [fetchPeriodOptions]);

  const handlePeriodChange = useCallback((opt: Option | null): void => {
    setPeriodOption(opt);
  }, []);

  const validateAndSubmit = useCallback((): void => {
    const formErrors = [];

    const groupClassId = parseInt(groupClassOption?.value, 10);
    const periodId = parseInt(periodOption?.value, 10);

    if (!groupClassId) {
      formErrors.push({ propertyPath: 'group-class', code: ERROR_IS_BLANK, message: '' });
    }

    if (!periodId) {
      formErrors.push({ propertyPath: 'period', code: ERROR_IS_BLANK, message: '' });
    }

    setErrors(formErrors);

    if (formErrors.length === 0) {
      PaymentTransferService.transferPayment(paymentId, groupClassId, periodId)
        .then(onTransferSuccess)
        .then(toggleModal);
    }
  }, [groupClassOption, periodOption, paymentId, toggleModal]);

  const errorsProps = useMemo((): ErrorProps => {
    let errorsPropsTmp = {};
    errors.forEach((error) => {
      Object.assign(errorsPropsTmp, {
        [`${ error.propertyPath }`]: {
          color: 'is-danger',
          borderColor: errorColor,
          labelProps: { className: 'has-text-danger' },
          labelTextAreaProps: {
            className: 'label has-text-danger',
          },
          selectFieldHelperText: { text: t(TranslationHelpers.getCommonErrorKeyByCode(error.code, error.propertyPath)), textColor: 'is-danger' },
          error: {
            text: t(TranslationHelpers.getCommonErrorKeyByCode(error.code, error.propertyPath)),
            textColor: 'is-danger',
          },
        },
      });
    });
    return errorsPropsTmp;
  }, [errors, t]);

  const footerTransferPayment: React$Node = useMemo((): React$Node => (
    <footer className="modal-cheque-footer">
      <Button
        type={ buttonConstants.PRIMARY }
        className="button-modal"
        onClick={ validateAndSubmit }
      >
        { t('common.validate') }
      </Button>
      <Button
        type={ buttonConstants.TERTIARY }
        className="button-modal"
        isOutlined
        onClick={ toggleModal }
      >
        { t('common.cancel') }
      </Button>
    </footer>
  ), [validateAndSubmit, toggleModal, language]);

  return (
    <Modal
      isActive={ isActive }
      isClipped
      onClose={ toggleModal }
      title={ t('payment.transfer.title') }
      footer={ footerTransferPayment }
    >
      <AdvancedSelectField
        id="group-class"
        name="group-class"
        label={ t('payment.transfer.selectClass') }
        options={ groupClassOpts }
        placeholder={ t('payment.transfer.classPlaceholder') }
        onChange={ handleClassChange }
        isClearable={ false }
        value={ groupClassOption }
        noOptionsMessage={ () => t('common.noOptions') }
        color={ errorsProps['group-class']?.color }
        labelProps={ errorsProps['group-class']?.labelProps }
        borderColor={ errorsProps['group-class']?.borderColor }
        helpProps={ errorsProps['group-class']?.selectFieldHelperText }
        className="group-class-select"
        required
      />
      <AdvancedSelectField
        id="period"
        name="period"
        label={ t('payment.transfer.selectPeriod') }
        options={ periodOptions }
        placeholder={ t('payment.transfer.periodPlaceholder') }
        onChange={ handlePeriodChange }
        isClearable={ false }
        value={ periodOption }
        noOptionsMessage={ () => t('common.noOptions') }
        color={ errorsProps.period?.color }
        labelProps={ errorsProps.period?.labelProps }
        borderColor={ errorsProps.period?.borderColor }
        helpProps={ errorsProps.period?.selectFieldHelperText }
        className="period-select"
        required
      />
    </Modal>
  );
};

ModalTransferPayment.defaultProps = {
  onTransferSuccess: () => {},
};

export default ModalTransferPayment;
