// @flow
import { type Locale } from 'date-fns';
import { enGB, enUS, es, fr } from 'date-fns/locale';

import type { Option } from '@core/types';
import i18n from '@app/i18n/i18n';
import { languages } from '@app/constants/languages';

export const getLanguagesOptions = (): Array<Option> => (
  languages.map((language: string) => ({
    label: i18n.t(`languages.${ language }`),
    value: language,
  }))
);

export const getLanguageDefault = (): string => {
  const { language } = i18n;
  let returnLanguage: string;

  switch (language) {
    case 'fr-FR':
    case 'fr':
      returnLanguage = 'fr'; break;
    case 'es':
      returnLanguage = 'es'; break;
    case 'en':
    case 'en-GB':
    case 'en-US':
    default:
      returnLanguage = 'en';
  }

  return returnLanguage;
};

export const getDateLocale = (): Locale => {
  const { language } = i18n;
  let returnLanguage = enGB;

  switch (language) {
    case 'en-US':
      returnLanguage = enUS; break;
    case 'fr-FR':
    case 'fr':
      returnLanguage = fr; break;
    case 'es':
      returnLanguage = es; break;
    case 'en':
    case 'en-GB':
    default:
      returnLanguage = enGB;
  }

  return returnLanguage;
};

export const getLanguageFormat = (language: string): string => {
  let returnLanguageFormat = 'fr-FR';
  switch (language) {
    case 'en':
    case 'en-GB':
    case 'en-US':
      returnLanguageFormat = 'en-US'; break;
    case 'es':
    case 'ES':
    case 'es-ES':
    case 'es_ES':
      returnLanguageFormat = 'es-ES'; break;
    case 'fr-FR':
    case 'fr':
    case 'FR':
    case 'fr_fr':
      returnLanguageFormat = 'fr-FR'; break;
    default:
      returnLanguageFormat = 'fr-FR';
  }
  return returnLanguageFormat;
};
