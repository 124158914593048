// @flow
import EventEmitter from '_common/services/EventEmitter';
import ApiService from '@api/service';

import { hydratePeriodItemOutputFromPayload, hydratePeriodFromPayload } from '@groupClass/helpers/PeriodUtils';

import type { Period, PeriodItemOutput } from '@groupClass/types';
import type { Error, Listener, QueryParams } from '@core/types';

import { API_PATHS } from '@app/constants/paths';
const { GROUP_CLASS_PERIODS } = API_PATHS;

export type GroupClassPeriodServiceData = {
  period: PeriodItemOutput | null,
  periods: Period[],
  loading: boolean,
  errors: Error[],
};

type OnChange = (listener: Listener) => Function;
type UpdatePeriod = (periodId: number, validedAt: string | null) => Promise<PeriodItemOutput>;
type FetchOne = (periodId: number) => Promise<PeriodItemOutput>;
type FetchAll = (params: QueryParams) => Promise<Period[]>;
type Reset = () => void;
type ResetErrors = () => void;
type Trigger = () => void;

type UpdateValues = (
  newPeriod: PeriodItemOutput | null,
  newPeriods: Period[],
  newLoading: boolean,
  newErrors: Error[],
) => void;

let sourceOne = ApiService.createToken();
let sourceAll = ApiService.createToken();

class GroupClassPeriodService {
  constructor() {
    this.eventEmitter = new EventEmitter();
    this.period = null;
    this.periods = [];
    this.errors = [];
    this.loading = false;
  }
  eventEmitter: EventEmitter;
  period: PeriodItemOutput | null;
  periods: Period[];
  loading: boolean;
  errors: Error[];

  updateValues: UpdateValues = (newPeriod: PeriodItemOutput | null, newPeriods: Period[], newLoading: boolean, newErrors: Error[]): void => {
    this.period = newPeriod;
    this.periods = newPeriods;
    this.loading = newLoading;
    this.errors = newErrors;
    this.#trigger();
  };

  updatePeriod: UpdatePeriod = (periodId: number, validatedAt: string | null): Promise<PeriodItemOutput> => {
    this.updateValues(this.period, this.periods, true, this.errors);

    return ApiService.request({
      method: 'put',
      url: `${ GROUP_CLASS_PERIODS }/${ periodId }`,
      data: { validatedAt },
    })
      .then((payload) => {
        if (payload.data) {
          const newPeriod = hydratePeriodItemOutputFromPayload(payload.data);
          this.updateValues(newPeriod, this.periods, false, this.errors);
          return newPeriod;
        }
        return Promise.reject();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.violations) {
            this.updateValues(this.period, this.periods, false, error.response.data.violations);
          }
        }
        return Promise.reject();
      });
  };

  fetchOne: FetchOne = (periodId: number): Promise<PeriodItemOutput> => {
    sourceOne.cancel();
    sourceOne = ApiService.createToken();

    this.updateValues(null, this.periods, true, []);

    return ApiService.request({
      method: 'get',
      url: `${ GROUP_CLASS_PERIODS }/${ periodId }`,
      cancelToken: sourceOne.token,
    })
      .then((payload) => {
        if (payload.data) {
          const newPeriod = hydratePeriodItemOutputFromPayload(payload.data);
          this.updateValues(newPeriod, this.periods, false, this.errors);
          return newPeriod;
        }
        return Promise.reject();
      })
      .catch(() => Promise.reject());
  };

  fetchAll: FetchAll = (params: QueryParams): Promise<Period[]> => {
    sourceAll.cancel();
    sourceAll = ApiService.createToken();

    return ApiService.request({
      method: 'get',
      url: `${ GROUP_CLASS_PERIODS }`,
      params: params,
      cancelToken: sourceAll.token,
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;
          const newPeriods = data['hydra:member'].map((periodPayload) => hydratePeriodFromPayload(periodPayload));
          return newPeriods;
        }
        return Promise.reject();
      })
      .catch(() => Promise.reject());
  };

  resetErrors: ResetErrors = (): void => {
    this.updateValues(this.period, this.periods, false, []);
  };

  reset: Reset = (): void => {
    this.updateValues(null, [], false, []);
  };

  onChange: OnChange = (listener: Listener): Function => {
    const listenerFunction = this.eventEmitter.addListener(listener);
    this.#trigger();
    return listenerFunction;
  };

  /**
   * @private
   */
  #trigger: Trigger = (): void => {
    this.eventEmitter.trigger({
      period: this.period,
      periods: this.periods,
      loading: this.loading,
      errors: this.errors,
    });
  };
}

const instance: GroupClassPeriodService = new GroupClassPeriodService();
export default instance;
