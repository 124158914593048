// @flow
export const SIZE_SMALL = 'is-small';
export const SIZE_NORMAL = 'is-normal';
export const SIZE_MEDIUM = 'is-medium';
export const SIZE_LARGE = 'is-large';

export const SIZES = [
  SIZE_SMALL,
  SIZE_NORMAL,
  SIZE_MEDIUM,
  SIZE_LARGE,
];

export const RequestStatuses = {
  IDLE: 'idle',
  PENDING: 'pending',
  FAILED: 'failed',
  SUCCEEDED: 'succeeded',
};