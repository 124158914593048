// @flow
import { useMemo } from 'react';
import clsx from 'clsx';

import type { Sizes } from '@core/types';
import * as coreConstants from '@core/constants';

export type IconsProps = {
  leftIcon?: React$Node,
  leftIconClassName?: string,
  rightIcon?: React$Node,
  rightIconClassName?: string,
  handleLeftIconClick?: () => void,
  handleRightIconClick?: () => void,
};

type Props = {
  children: React$Node,
  size: Sizes,
  icons: IconsProps | null,
  isExpanded: boolean,
  isLoading: boolean,
  className: string,
};

const clickable = { cursor: 'pointer', pointerEvents: 'initial' };
const { SIZES, SIZE_SMALL } = coreConstants;

const Control = (props: Props): React$Node => {
  const {
    children,
    className,
    icons = {},
    isExpanded,
    isLoading,
    size,
  } = props;

  const {
    handleLeftIconClick = null,
    handleRightIconClick = null,
    leftIcon = null,
    leftIconClassName = null,
    rightIcon = null,
    rightIconClassName = null,
  } = icons || {};

  const leftIconStype = useMemo((): Object => (
    handleLeftIconClick ? clickable : {}
  ), [handleLeftIconClick, clickable]);

  const rightIconStype = useMemo((): Object => (
    handleRightIconClick ? clickable : {}
  ), [handleRightIconClick, clickable]);

  const controlClassName = useMemo((): string => clsx({
    control: true,
    'is-expanded': !!isExpanded,
    'is-loading': !!isLoading,
    'has-icons-left': !!leftIcon,
    'has-icons-right': !!rightIcon,
    [className]: !!className,
    [SIZE_SMALL]: !SIZES.includes(size),
    [String(size)]: SIZES.includes(size),
  }), [size, className, isExpanded, isLoading, leftIcon, rightIcon]);

  const controlLeftClassName = useMemo((): string => clsx({
    'icon': true,
    'is-left': true,
    [String(leftIconClassName)]: !!leftIconClassName,
  }), [leftIconClassName]);

  const controlRightClassName = useMemo((): string => clsx({
    'icon': true,
    'is-right': true,
    [String(rightIconClassName)]: !! rightIconClassName,
  }), [rightIconClassName]);

  return (
    <div className={ controlClassName }>
      { children }

      { leftIcon && (
        <span
          className={ controlLeftClassName }
          onClick={ handleLeftIconClick }
          onKeyDown={ handleLeftIconClick }
          style={ leftIconStype }
          role="button"
          tabIndex={ 0 }
        >
          { leftIcon }
        </span>
      ) }

      { rightIcon && (
        <span
          className={ controlRightClassName }
          onClick={ handleRightIconClick }
          onKeyDown={ handleRightIconClick }
          style={ rightIconStype }
          role="button"
          tabIndex={ 0 }
        >
          { rightIcon }
        </span>
      ) }
    </div>
  );
};

Control.defaultProps = {
  children: null,
  size: 'is-small',
  icons: null,
  isExpanded: false,
  isLoading: false,
  className: 'control',
};

export default Control;
