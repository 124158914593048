// @flow

import { type ActionMeta } from 'react-select';
import type { Option } from '@core/types';

import ObjectHelpers from '@helpers/ObjectHelpers';

type HandleUpdateValue = () => null | Option | Option[];
type AddOptionToMultiSelect = (option: Option) => Option[];
type RemoveOptionToMultiSelect = (option: Option) => Option[] | null;
type AddOptionWhenAllSelected = (option: Option) => Option[];
type HandleMultiSelect = () => null | Option[];
type HandleSingleSelect = () => null | Option | Option[];

const SELECT_ALL = '<SELECT_ALL>';
const REMOVE_OPTIONS = ['deselect-option', 'remove-value'];
const ADD_OPTION = 'select-option';

class AdvancedSelectFieldHelpers {

  constructor(optionSelected: Option | Option[], actionMeta: ActionMeta<Option>, values: Option[], options: Option[], isMulti?: string | boolean) {
    this._optionSelected = optionSelected;
    this._actionMeta = actionMeta;
    this._values = values;
    this._options = options;
    this._isMulti = isMulti;
  }
  _optionSelected: Option | Option[];
  _actionMeta: ActionMeta<Option>;
  _values: Option[];
  _options: Option[];
  _isMulti: void | string | boolean;

  get optionSelected(): Option | Option[] { return this._optionSelected; }
  get actionMeta(): ActionMeta<Option> { return this._actionMeta; }
  get values(): Option[] { return this._values; }
  get options(): Option[] { return this._options; }
  get isMulti(): void | string | boolean { return this._isMulti; }

  set values(newValues: Option[]): void { this._values = newValues; }

  handleUpdateValue: HandleUpdateValue = (): null | Option | Option[] => (
    this.isMulti ? this.handleMultiSelect() : this.handleSingleSelect()
  );

  handleMultiSelect: HandleMultiSelect = (): null | Option[] => {
    const { option, removedValue, action } = this.actionMeta;
    const allWasSelected = this.values && this.values.length > 0 && this.values[0].value === SELECT_ALL;
    if (option && action === ADD_OPTION) {
      return this.addOptionToMultiSelect(option);
    }
    if (removedValue && REMOVE_OPTIONS.includes(action)) {
      return this.removeOptionToMultiSelect(removedValue);
    }
    if (option && allWasSelected && REMOVE_OPTIONS.includes(action)) {
      return this.removeOptionWhenAllSelected(option);
    }
    if (option && REMOVE_OPTIONS.includes(action)) {
      return this.removeOptionToMultiSelect(option);
    }
    return null;
  };

  handleSingleSelect: HandleSingleSelect = (): null | Option | Option[] => {
    const { action } = this.actionMeta;
    if (REMOVE_OPTIONS.includes(action)) {
      return null;
    }
    if (this.optionSelected) {
      return this.optionSelected;
    }
    return null;
  };

  addOptionToMultiSelect: AddOptionToMultiSelect = (option: Option): Option[] => {
    const isAllSelected = option.value === SELECT_ALL;

    if (isAllSelected) {
      return this.options;
    }

    let newValues = ObjectHelpers.deepClone(this.values);
    newValues.push(option);

    if (newValues.length === this.options.length) {
      return this.options;
    }

    return newValues;
  };

  removeOptionWhenAllSelected: AddOptionWhenAllSelected = (option: Option): Option[] => (
    this.options.filter((optionList) => optionList.value !== option.value)
  );

  removeOptionToMultiSelect: RemoveOptionToMultiSelect = (option: Option): Option[] | null => {
    const isAllSelected = option.value === SELECT_ALL;
    if (isAllSelected) {
      return null;
    }
    let newValues = ObjectHelpers.deepClone(this.values);
    if (option.value === SELECT_ALL) {
      newValues.push(option);
      return newValues;
    }

    return newValues.filter((optionList) => optionList.value !== option.value);
  };

}

export default AdvancedSelectFieldHelpers;
