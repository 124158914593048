// @flow
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';

import {
  CLASS_PAYMENT_STATUS_NOT_REGISTERED,
  CLASS_PAYMENT_STATUS_TO_PAY,
  CLASS_PAYMENT_STATUS_REQUESTED,
  CLASS_PAYMENT_STATUS_PAID,
  CLASS_PAYMENT_STATUS_FREE,
} from '@payment/constants';

type Props = {
  paymentStatus: string | null,
};

const CardItemPaymentStatus = (props: Props): React$Node | null => {
  const { paymentStatus } = props;

  const { t } = useTranslation();

  const cardItemPaymentStatusClassName = useMemo((): string => clsx({
    'payment-status': true,
    'not-registered': paymentStatus === CLASS_PAYMENT_STATUS_NOT_REGISTERED,
    'to-pay': paymentStatus === CLASS_PAYMENT_STATUS_TO_PAY,
    'requested': paymentStatus === CLASS_PAYMENT_STATUS_REQUESTED,
    'paid': paymentStatus === CLASS_PAYMENT_STATUS_PAID,
    'free': paymentStatus === CLASS_PAYMENT_STATUS_FREE,
  }), [paymentStatus]);

  if (paymentStatus === null) {
    return null;
  }

  return (
    <div className={ cardItemPaymentStatusClassName }>
      { t(`payment.classStatus.${ paymentStatus }`) }
    </div>
  );
};

CardItemPaymentStatus.defaultProps = {
  paymentStatus: null,
};

export default CardItemPaymentStatus;
