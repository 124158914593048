// @flow

import GroupClasses from '@groupClass/components/GroupClasses';
import SielBleuUserRoute from '@shared/ProtectedRoute/components/SielBleuUserRoute';

const GroupClassesAdmin = (): React$Node => (
  <SielBleuUserRoute>
    <GroupClasses />
  </SielBleuUserRoute>
);

export default GroupClassesAdmin;
