// @flow
import Footer from '_common/components/footer/Footer';
import SielBleuLogo from '@layout/components/siel-bleu-logo/SielBleuLogo';
import Social from '@footer/components/Social';
import Links from '@footer/components/Links';

const MainFooter = (): React$Node => (
  <Footer>
    <div className="footer-container">
      <div className="footer-up">
        <SielBleuLogo className="siel-bleu-logo large" />
        <Social />
      </div>
      <Links />
    </div>
  </Footer>
);

export default MainFooter;
