// @flow

import Loader from '_common/components/loader/Loader';
import RotateScreen from '@shared/RotateScreen/components/RotateScreen';
import GroupClassAdminHeader from '@layout/components/GroupClassAdminHeader';
import SielBleuUserRoute from '@shared/ProtectedRoute/components/SielBleuUserRoute';

import type { GroupClassDetailed } from '@groupClass/types';

type Props = {
  children: React$Node,
  activeTab: string,
  groupClass: GroupClassDetailed | null,
  isLoading?: boolean,
  loaderType?: string,
  preventPortraitMode: boolean,
};

const AdminLayout = (props: Props): React$Node => {
  const { children, activeTab, groupClass, isLoading, loaderType, preventPortraitMode } = props;

  return (
    <SielBleuUserRoute groupClass={ groupClass }>
      { groupClass && (
        <GroupClassAdminHeader groupClass={ groupClass } tab={ activeTab } />
      ) }
      <div className="group-class">
        { isLoading
          ? ( <Loader loaderType={ loaderType } /> )
          : ( children )
        }
        <RotateScreen isVisible={ preventPortraitMode } />
      </div>
    </SielBleuUserRoute>
  );
};

AdminLayout.defaultProps = {
  isLoading: false,
  preventPortraitMode: false,
};

export default AdminLayout;
