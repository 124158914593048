// @flow
import { useEffect, useRef, useState } from 'react';

import type { PaymentProviderData } from '@payment/types';
import PaymentProviderService, { type PaymentProviderServiceData } from '@payment/services/PaymentProviderService';

const PaymentFormProvider = (): React$Node | null => {
  const [paymentProviderInformation, setPaymentProviderInformation] = useState<PaymentProviderData | null>(PaymentProviderService.paymentProviderInformation);
  const formRef = useRef();

  /**
   * handlePaymentProviderDataChange
   * Store informations about the PSP into component storage of the component
   * @param {PaymentProviderServiceData} data
   * @returns {void}
   */
  const handlePaymentProviderDataChange = (data: PaymentProviderServiceData): void => {
    setPaymentProviderInformation(data.paymentProviderInformation);
  };

  /**
   * useEffect used to subscribe on a the PaymentOnProvider service
   */
  useEffect(() => PaymentProviderService.onChange(handlePaymentProviderDataChange), []);

  /**
   * useEffect used to auto submit the form
   */
  useEffect(() => {
    if (paymentProviderInformation !== null && formRef?.current) {
      formRef.current.submit();
    }
  }, [paymentProviderInformation, formRef]);

  if (paymentProviderInformation === null) return null;

  return (
    <form
      ref={ formRef }
      method="post"
      name="Monetico"
      target="_top"
      action={ paymentProviderInformation.url }
    >
      <input type="hidden" name="version" value={ paymentProviderInformation.version } />
      <input type="hidden" name="TPE" value={ paymentProviderInformation.TPE } />
      <input type="hidden" name="date" value={ paymentProviderInformation.date } />
      <input type="hidden" name="montant" value={ paymentProviderInformation.montant } />
      <input type="hidden" name="reference" value={ paymentProviderInformation.reference } />
      <input type="hidden" name="MAC" value={ paymentProviderInformation.mac } />
      <input type="hidden" name="url_retour_ok" value={ paymentProviderInformation.urlRetourOk } />
      <input type="hidden" name="url_retour_err" value={ paymentProviderInformation.urlRetourErr } />
      <input type="hidden" name="lgue" value={ paymentProviderInformation.lgue } />
      <input type="hidden" name="societe" value={ paymentProviderInformation.societe } />
      <input type="hidden" name="contexte_commande" value={ paymentProviderInformation.contextCommand } />
      <input type="hidden" name="mail" value={ paymentProviderInformation.mail } />
    </form>
  );
};

export default PaymentFormProvider;
