// @flow
import {
  addDays,
  startOfDay,
  startOfMonth,
  endOfMonth,
  startOfYear,
  addMonths,
  startOfWeek,
  endOfWeek,
  format,
  endOfYear,
} from 'date-fns';
import { getI18n } from 'react-i18next';

import DateHelpers from '@helpers/DateHelpers';

import type { DateServiceType } from '_common/types/dates';

import { getDateLocale } from '_common/services/LanguageUtils';

const { t } = getI18n();

const locale = getDateLocale();
const weekStartsOn = 1;

const DateService: DateServiceType = {
  today: startOfDay(new Date()),
  yesterday: startOfDay(addDays(new Date(), -1)),
  startOfWeek: startOfWeek(new Date(), { weekStartsOn }),
  endOfWeek: endOfWeek(new Date(), { weekStartsOn }),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7), { weekStartsOn }),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7), { weekStartsOn }),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),
  xDaysBefore: (days: number): Date => startOfDay(addDays(new Date(), -days)),
  xMonthsBefore: (month: number): Date => startOfMonth(addMonths(new Date(), -month)),
  localDateDisplay: (date: Date, language: string): string => format(date, t('dates.dateFormat', language), { locale }),
  localTimeDisplay: (date: Date, language: string): string => format(date, t('dates.timeFormat', language), { locale }),
  localHoursDisplay: (date: Date, formatTime: string): string => format(date, formatTime, { locale }),
  localStartDateAndTimeDisplay: (day: string, start: Date, language: string): string =>
    `${ DateHelpers.getDayOfWeek(day.toLocaleLowerCase(), language) } ${ DateService.localTimeDisplay(start, language) }`,
  localDateAndTimeDisplay: (day: string, start: Date, end: Date, language: string): string =>
    `${ DateHelpers.getDayOfWeek(day.toLocaleLowerCase(), language) } ${ DateService.localTimeDisplay(start, language) } -
      ${ DateService.localTimeDisplay(end, language) }`,
  addDayToDate: (date: Date, number: number): Date => {
    const dateTemp = new Date(date);
    dateTemp.setDate(date.getDate() + number);
    return dateTemp;
  },
};

export default DateService;
