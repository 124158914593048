// @flow
import { useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import withGroupClass from '@hoc/withGroupClass';

import AdminLayout from '@layout/components/AdminLayout';

import Loader from '_common/components/loader/Loader';
import Toast from '_common/services/Toast/Toast';
import Button, { buttonConstants } from '@shared/Button';
import NavigationPills from '@shared/Navigation/NavigationPills';
import PeriodSelector from '@payment/components/PeriodSelector';
import ModalValidatePeriod from '@payment/components/ModalValidatePeriod';
import IconCircleXMark from '@icons/components/IconCircleXMark';
import PaymentLine from '@payment/components/PaymentLine';

import AuthService, { type AuthServiceData } from '@user/services/AuthService';
import GroupClassPaymentService, { type GroupClassPaymentServiceData } from '@payment/services/GroupClassPaymentService';
import GroupClassPeriodService, { type GroupClassPeriodServiceData } from '@groupClass/services/GroupClassPeriodService';
import GroupClassService from '@groupClass/services/GroupClassService';
import RolesHelpers from '@user/helpers/RolesHelpers';
import { findTheNearestPeriod } from '@payment/helpers/PeriodPaymentUtils';
import { sortBalancesByTypes } from '@payment/helpers/SortPaymentUtils';

import type { GroupClassDetailed } from '@groupClass/types';
import type { PeriodItemOutput, Period } from '@groupClass/types';
import type { Balance } from '@payment/types';
import type { User } from '@user/types';
import type { Error } from '@core/types';

import { getPaymentNavigationTabs, GENERAL_SELECTED } from '@payment/constants';
import { NEXT_PERIOD_NOT_VALIDED } from '@helpers/TranslationHelpers';
import { ADMIN_HEADER_PAYMENTS } from '@layout/constants';
import { WEB_PATHS } from '@app/constants/paths';
import { LOADER_TYPE_BACKGROUND } from '_common/components/loader/constants';

const { PAYMENTS_CREATE_CHEQUE } = WEB_PATHS;

type Props = {
  groupClass: GroupClassDetailed | null,
};

const Payments = (props: Props): React$Node => {
  const { groupClass } = props;
  const { t, i18n: { language } } = useTranslation();

  const [ currentUser, setCurrentUser ] = useState<User | null>(AuthService.user);
  const [ period, setPeriod ] = useState<PeriodItemOutput | null>(GroupClassPeriodService.period);
  const [ balances, setBalances ] = useState<Balance[]>(GroupClassPaymentService.balances);
  const [ isLoading, setIsLoading ] = useState<boolean>(GroupClassPaymentService.isLoading);
  const [ isLoadingPeriod, setIsLoadingPeriod ] = useState<boolean>(GroupClassPeriodService.loading);
  const [ isActiveModalValidatePeriod, setIsActiveModalValidatePeriod ] = useState<boolean>(false);
  const [ errors, setErrors ] = useState<Error[]>(GroupClassPeriodService.errors);

  const periods = useMemo((): Period[] => (
    (groupClass?.periods ?? [])
      .slice(0)
      .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
  ), [groupClass]);

  const handleUpdateAuthService = (data: AuthServiceData) => {
    setCurrentUser(data.user);
  };

  const handleUpdateBalances = (data: GroupClassPaymentServiceData): void => {
    const balances = sortBalancesByTypes(data.balances);
    setBalances(balances);
    setIsLoading(data.isLoading);
  };

  const handleUpdatePeriodService = (data: GroupClassPeriodServiceData): void => {
    setPeriod(data.period);
    setIsLoadingPeriod(data.loading);
    setErrors(data.errors);
  };

  useEffect(() => AuthService.onChange(handleUpdateAuthService), []);
  useEffect(() => GroupClassPeriodService.onChange(handleUpdatePeriodService), []);
  useEffect(() => {
    const unsubscribe = GroupClassPaymentService.onChange(handleUpdateBalances);
    return () => {
      unsubscribe();
      GroupClassPaymentService.reset();
    };
  }, []);

  useEffect((): void => {
    const nearestPeriod = findTheNearestPeriod(periods);
    if (nearestPeriod) {
      GroupClassPeriodService.fetchOne(nearestPeriod.id);
    }
  }, [periods]);

  useEffect((): void => {
    if (period && groupClass) {
      const params = {
        id: groupClass.id,
        periodId: period.id,
      };
      GroupClassPaymentService.fetchBalances(params);
    }
  }, [groupClass, period]);

  const isAdmin = useMemo((): boolean => (
    RolesHelpers.isAdmin(currentUser)
  ), [currentUser]);

  const toggleModalValidatePeriod = useCallback((): void => {
    GroupClassPeriodService.resetErrors();
    setIsActiveModalValidatePeriod(!isActiveModalValidatePeriod);
  }, [isActiveModalValidatePeriod, setIsActiveModalValidatePeriod]);

  const showSuccessMessage = useCallback((message: string): void => {
    Toast.success(t(message));
  }, [t]);

  const indexOfPeriod = useMemo((): number => (
    periods.findIndex((element: Period) => period?.id === element.id)
  ), [period]);

  const handlePeriod = useCallback((type: string): void => {
    if (type === 'next') {
      GroupClassPeriodService.fetchOne(periods[indexOfPeriod + 1].id);
    } else if (type === 'previous') {
      GroupClassPeriodService.fetchOne(periods[indexOfPeriod - 1].id);
    }
    GroupClassPaymentService.reset();
  }, [indexOfPeriod, periods]);

  const isPeriodArchived = useMemo((): boolean => (
    period?.archivedAt !== null
  ), [period]);

  const isAdminAndPeriodValidated = useMemo((): boolean => {
    if (period && !isPeriodArchived) {
      return period.validatedAt !== null && isAdmin;
    }
    return false;
  }, [period, isAdmin, isPeriodArchived]);

  const isPeriodValidatedAndNotAdmin = useMemo((): boolean => {
    if (period && !isPeriodArchived) {
      return period.validatedAt !== null && !isAdmin;
    }
    return false;
  }, [period, isAdmin, isPeriodArchived]);

  const isPeriodUnvalidated = useMemo((): boolean => {
    if (period && !isPeriodArchived) {
      return period.validatedAt === null;
    }
    return false;
  }, [period, isPeriodArchived]);

  const unValidatePeriod = useCallback((): void => {
    if (period && !isLoadingPeriod) {
      GroupClassPeriodService.updatePeriod(period.id, null)
        .then(() => {
          showSuccessMessage('groupClasses.groupClass.peoples.form.wellUpdated');
          GroupClassPeriodService.fetchOne(period.id);
          GroupClassService.fetchGroupClassById(String(groupClass.id), language);
        });
    }
  }, [period, isLoadingPeriod, showSuccessMessage]);

  const isNextPeriodValidationError = useMemo((): boolean => (
    errors?.[0]?.code === NEXT_PERIOD_NOT_VALIDED
  ), [errors]);

  return (
    <AdminLayout groupClass={ groupClass } activeTab={ ADMIN_HEADER_PAYMENTS } preventPortraitMode>
      <div className="payment-container payments-global container">
        <div className="payment-container-header payments-global-header">
          { groupClass && (
            <>
              <NavigationPills navigationTabs={ getPaymentNavigationTabs(groupClass.id) } isSelectedKey={ GENERAL_SELECTED } />
              <Link
                to={ PAYMENTS_CREATE_CHEQUE.replace(':classId', String(groupClass.id)) }
                className="button button-secondary"
              >
                { t('payment.addCheque') }
              </Link>
            </>
          ) }
        </div>
        { isLoading ? (
          <Loader loaderType={ LOADER_TYPE_BACKGROUND } />
        ) : (
          <div className="table-payment-container">
            <PeriodSelector periods={ periods } period={ period } indexOfPeriod={ indexOfPeriod } handlePeriod={ handlePeriod } />
            <table className="table">
              <colgroup>
                <col />
                <col className="table-cell-width" />
                <col className="table-cell-width" />
                <col className="table-cell-width" />
                <col className="table-cell-width" />
              </colgroup>
              <thead className="table-head">
                <tr>
                  <th className="table-head-title table-cell-title-name">
                    { t('payment.name') }
                  </th>
                  <th className="table-head-title cell-text-centered">
                    { t('payment.tableStatus') }
                  </th>
                  <th className="table-head-title cell-text-centered">
                    { t('payment.tableDue') }
                  </th>
                  <th className="table-head-title cell-text-centered">
                    { t('payment.tablePaid') }
                  </th>
                  <th className="table-head-title cell-text-centered">
                    { t('payment.tableType') }
                  </th>
                </tr>
              </thead>
              <tbody className="table-body">
                { !isLoading && balances.length === 0 && (
                  <tr>
                    <td colSpan="5" className="cell-text-centered">
                      { t('payment.noresultForThisPeriod') }
                    </td>
                  </tr>
                ) }
                { balances.map((balance, index) => {
                  const showPayment = balance.paid > 0 || !balance.unsubscribedAt || (balance.unsubscribedAt && balance.toPay > 0);

                  if (showPayment) {
                    return (
                      <PaymentLine key={ index } balance={ balance } />
                    );
                  }
                }) }
              </tbody>
            </table>
          </div>
        ) }
        { !isLoadingPeriod && period && (
          <div className="button-validate">
            { isPeriodUnvalidated && (
              <Button onClick={ toggleModalValidatePeriod } type={ buttonConstants.PRIMARY }>
                { t('payment.validatePeriod') }
              </Button>
            ) }
            { isAdminAndPeriodValidated && (
              <Button onClick={ unValidatePeriod } type={ buttonConstants.PRIMARY }>
                { t('payment.validatedPeriod') }
              </Button>
            ) }
            { isPeriodValidatedAndNotAdmin && (
              <Button onClick={ toggleModalValidatePeriod } type={ buttonConstants.PRIMARY } isDisabled>
                { t('payment.validatedPeriod') }
              </Button>
            ) }
            { isPeriodArchived && (
              <Button type={ buttonConstants.PRIMARY } isDisabled>
                { t('payment.periodArchived') }
              </Button>
            ) }
          </div>
        ) }
        { isNextPeriodValidationError && (
          <div className="custom-error-container">
            <div className="custom-error-message">
              <IconCircleXMark />
              { t('payment.nextPeriodValidationError') }
            </div>
          </div>
        ) }
      </div>
      { groupClass && period && (
        <ModalValidatePeriod
          isActive={ isActiveModalValidatePeriod }
          toggleModal={ toggleModalValidatePeriod }
          showSuccessMessage={ showSuccessMessage }
          periodId={ period.id }
          groupClass={ groupClass }
        />
      ) }
    </AdminLayout>
  );
};

export default withGroupClass(Payments);
