// @flow
import moment from 'moment/dist/moment';

import type {
  PaymentCreditCardData,
  PaymentCreditCardDataPayload,
  PaymentInformation,
  PaymentInformationPayload,
  PaymentProviderData,
  PaymentProviderDataPayload,
} from '@payment/types';
import type { UserRegistered, PaymentSchedule } from '@groupClass/types';
import * as groupClassesUtils from '@groupClass/helpers/GroupClassesUtils';
import * as periodPaymentUtils from '@payment/helpers/PeriodPaymentUtils';
import * as personUtils from '@person/helpers/PersonUtils';
import * as paymentConditionTypeConstants from '@paymentConditionType/constants';
import * as paymentConstants from '@payment/constants';
import { formatSimpleAddressFromPayload } from '@shared/Locations/services/AddressUtils';
import type { PaymentConditionType } from '@paymentConditionType/types';

const { PAYMENT_FREQUENCY_ANNUAL, CONDITION_TYPE_OTHER, CONDITION_TYPE_SUBSIDY } = paymentConditionTypeConstants;
const { PAYMENT_CARD_TYPE_CB } = paymentConstants;

export type SeparatedConditionTypes = {
  periodDiscount: number,
  annualFeeAmount: number,
  hasSubsidyOrOther: boolean,
};

/**
 * calculatePaymentScheduleAmount
 * Helper used to calcule the new amount of the paymentSchedule
 * @param {PaymentSchedule} paymentSchedule
 * @param {number} periodDiscount
 * @param {number} annualFeeAmount
 * @param {number} annualFeePeriodId
 * @returns {PaymentSchedule}
 */
export const calculatePaymentScheduleAmount = (
  paymentSchedule: PaymentSchedule,
  periodDiscount: number,
  annualFeeAmount: number,
  annualFeePeriodId: number,
  hasSubsidyOrOther: boolean,
): PaymentSchedule => {
  const { validatedAt, id: periodId } = paymentSchedule.period;

  if (validatedAt) {
    return paymentSchedule;
  }

  let updatedDiscount = periodDiscount;

  if (annualFeeAmount > 0 && periodId === annualFeePeriodId) {
    updatedDiscount += annualFeeAmount;
  }

  if (!hasSubsidyOrOther) {
    paymentSchedule.costAdjust = 0;
  }

  return {
    ...paymentSchedule,
    discount: updatedDiscount,
  };
};

/**
 * calculatePaymentSchedulesAmount
 * Helper used to calcule the new amount of all paymentSchedule
 * @param {UserRegistered} data
 * @returns {PaymentSchedule[]}
 */
export const calculatePaymentSchedulesAmount = (data: UserRegistered): PaymentSchedule[] => {
  const { paymentConditions = [], paymentSchedules = [] } = data;

  const { periodDiscount, annualFeeAmount, hasSubsidyOrOther } = separatePaymentConditionTypes(paymentConditions);

  const periodId = findApplicableSubscriptionPeriod(paymentSchedules, data.hasChangedAnnualFees);

  return paymentSchedules.map((paymentSchedule) => calculatePaymentScheduleAmount(
    paymentSchedule,
    periodDiscount,
    annualFeeAmount,
    periodId,
    hasSubsidyOrOther,
  ));
};

export const separatePaymentConditionTypes = (paymentConditions: PaymentConditionType[]): SeparatedConditionTypes => {
  const periodDiscount = paymentConditions.reduce(
    (sum, condition) => condition.frequency === PAYMENT_FREQUENCY_ANNUAL ? sum : sum + parseFloat(condition.amount),
    0,
  );

  const annualFeeAmount = paymentConditions.reduce(
    (sum, condition) => condition.frequency === PAYMENT_FREQUENCY_ANNUAL ? sum + parseFloat(condition.amount) : sum,
    0,
  );

  const hasSubsidyOrOther = paymentConditions.some(
    (condition) => condition.code === CONDITION_TYPE_SUBSIDY || condition.code === CONDITION_TYPE_OTHER,
  );

  return {
    periodDiscount,
    annualFeeAmount,
    hasSubsidyOrOther,
  };
};

/**
 * findApplicableSubscriptionPeriod
 * Helper used to find the applicable period id for subscription fee.
 * Returns the id of the applicable period, -1 if none found
 * @param {PaymentSchedule[]} paymentSchedules
 * @param {boolean} hasChangedAnnualFees
 * @returns {number}
 */
export const findApplicableSubscriptionPeriod = (paymentSchedules: PaymentSchedule[], hasChangedAnnualFees): number => {

  // In case want back to keep initial period
  const annualFeePaymentScheduleId = paymentSchedules
    .find((paymentSchedule) => paymentSchedule.areFeesDue)?.period.id ?? null;

  if (annualFeePaymentScheduleId && !hasChangedAnnualFees) {
    return annualFeePaymentScheduleId;
  }

  const now = moment();

  const current = paymentSchedules.find((schedule) => (
    !schedule.period.validatedAt && moment(schedule.period.startDate).isSameOrBefore(now) && moment(schedule.period.endDate).isSameOrAfter(now)
  ));

  if (current) {
    return current.period.id;
  }

  const future = paymentSchedules.sort((a, b) => new Date(a.period.startDate) - new Date(b.period.startDate))
    .find((schedule) => (
      !schedule.period.validatedAt && moment(schedule.period.startDate).isAfter(now)
    ));

  if (future) {
    return future.period.id;
  }

  const past = paymentSchedules.sort((a, b) => new Date(b.period.startDate) - new Date(a.period.startDate))
    .find((schedule) => (
      !schedule.period.validatedAt && moment(schedule.period.endDate).isBefore(now)
    ));

  if (past) {
    return past.period.id;
  }

  return -1;
};

/**
 * parsePaymentProviderInfo
 * Helper used to parse payment provider info received from the API (to work with monetico)
 * @param {PaymentProviderDataPayload} data
 * @returns {PaymentProviderData}
 */
export const parsePaymentProviderInfo = (data: PaymentProviderDataPayload): PaymentProviderData => ({
  contextCommand: data.contextCommand ?? '',
  date: data.date ?? '',
  lgue: data.lgue ?? '',
  mac: data.mac ?? '',
  mail: data.mail ?? '',
  montant: data.montant ?? '',
  reference: data.reference ?? '',
  societe: data.societe ?? '',
  TPE: data.TPE ?? '',
  url: data.url ?? '',
  urlRetourErr: data.urlRetourErr ?? '',
  urlRetourOk: data.urlRetourOk ?? '',
  version: data.version ?? '',
});

/**
 * parseCCInformation
 * Helper used to parse CC information received from the Back end API
 * @param {PaymentCreditCardDataPayload} data
 * @returns {PaymentCreditCardData}
 */
export const parseCCInformation = (data: PaymentCreditCardDataPayload): PaymentCreditCardData => ({
  id: data.id,
  reference: data.reference,
  person: personUtils.hydratePersonFromPayload(data.person),
  groupClass: groupClassesUtils.formatShortGroupClassFromPayload(data.groupClass),
  groupClassRegisteredPaymentSchedule: periodPaymentUtils.hydratePaymentScheduleFromPayload(data.groupClassRegisteredPaymentSchedule),
  amount: data.amount,
  status: data.status,
  type: data.status,
  date: data.date ? new Date(data.date) : null,
  billingFirstName: data.billingFirstName,
  billingLastName: data.billingLastName,
  billingAddress: formatSimpleAddressFromPayload(data.billingAddress),
});

/**
 * parsePaymentInformations
 * Helper used to parse payment informations received from the Back end API
 * @param {PaymentInformationPayload} data
 * @returns {PaymentInformation}
 */
export const parsePaymentInformations = (data: PaymentInformationPayload): PaymentInformation => ({
  amount: data.amount,
  groupClass: groupClassesUtils.formatShortGroupClassFromPayload(data.groupClass),
  groupClassRegisteredPaymentSchedule: periodPaymentUtils.hydratePaymentScheduleFromPayload(data.groupClassRegisteredPaymentSchedule),
  id: data.id,
  person: personUtils.hydratePersonFromPayload(data.person),
  reference: data.reference,
  status: data.status,
  date: data.date ?? new Date(),
  cardType: data.cardType ?? PAYMENT_CARD_TYPE_CB,
});
