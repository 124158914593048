// @flow
import { useCallback, useMemo } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import * as dateTimePickerConstants from '@shared/DateTimePicker/constants';

type Props = {
  date: moment$Moment,
  navigationDate: moment$Moment,
  isValid: (date: moment$Moment, viewMode: string) => boolean,
  onClick: (date: moment$Moment, viewMode: string) => void,
};

const Calendar = (props: Props): React$Node => {
  const { date, navigationDate, isValid, onClick } = props;
  const { i18n: { language } } = useTranslation();

  moment.locale(language);

  /**
   * handleClick
   * Handle the click on the calendar by the user, to select, check and save the date
   * @param {moment$Moment} clickedDate
   * @returns {void}
   */
  const handleClick = useCallback((clickedDate: moment$Moment): void => {
    const dateMoment = date ? moment(date) : moment();
    const newDate = moment(clickedDate)
      .hours(dateMoment.hours())
      .minutes(dateMoment.minutes());

    if (isValid(newDate, dateTimePickerConstants.VIEW_MODE_DAY)) {
      onClick(newDate, dateTimePickerConstants.VIEW_MODE_DAY);
    }
  }, [date, isValid, onClick]);

  const days = [];
  const beginOfMonth = moment(navigationDate).startOf('month');
  const endOfMonth = moment(navigationDate).endOf('month');
  const startDayOfWeek = moment(beginOfMonth).weekday();

  // Build Week days, first line of the calendar
  const weekDays = useMemo(() => {
    const weekOffset = dateTimePickerConstants.CALENDAR_BEGINNING[language];
    const weekDays = moment.weekdaysShort();
    if (weekOffset === 1) {
      weekDays.push(weekDays.shift());
    }
    return weekDays;
  }, [language, navigationDate]);

  // Fill days list with empty data until the beginning of the month
  if (startDayOfWeek > 0) {
    for (let i = startDayOfWeek; i > 0; i--) {
      days.push(null);
    }
  }

  // Fill with days
  const nbDays = endOfMonth.diff(beginOfMonth, 'day');
  for (let i = 0; i <= nbDays; i++) {
    const currentDay = moment(beginOfMonth).add(i, 'days');
    const endOfCurrentDay = moment(currentDay).endOf('day');

    days.push({
      date: currentDay,
      isValid: isValid(currentDay, dateTimePickerConstants.VIEW_MODE_DAY)
        || isValid(endOfCurrentDay, dateTimePickerConstants.VIEW_MODE_DAY),
      isCurrent: moment(date).isSame(currentDay, 'day'),
    });
  }

  // Fill with empty days to complete the days array
  const missingCells = days.length % 7;
  if (missingCells > 0) {
    for (let i = 7; i > missingCells; i--) {
      days.push(null);
    }
  }

  return (
    <div className="calendar">
      <div className="calendar-row header">
        { weekDays.map((day, index) => (
          <div className="calendar-cell" key={ index }>{ day }</div>
        )) }
      </div>

      <div className="calendar-row">
        { days.map((day, index) => {
          if (day === null) {
            return (
              <div className="calendar-cell no-value" key={ index } />
            );
          }

          const cellClassName = clsx({
            'calendar-cell': true,
            'is-disabled': !day.isValid,
            'is-current': day.isCurrent,
          });

          return (
            <div
              key={ index }
              className={ cellClassName }
              onClick={ () => day && handleClick(day.date) }
            >
              { moment(day.date).format('DD') }

              { day.isCurrent && (
                <div className="calendar-current" />
              ) }
            </div>
          );
        }) }
      </div>
    </div>
  );
};

Calendar.defaultProps = {
  date: '',
  navigationDate: '',
  isValid: (): boolean => true,
  onClick: (): void => {},
};

export default Calendar;
