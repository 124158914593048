// @flow
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CheckboxField from '_common/components/checkbox-field/CheckboxField';
import TextareaField from '_common/components/textarea-field/TextareaField';
import PaymentFormRow from '@shared/Payment/components/PaymentFormRow';
import IconInfos from '@icons/components/IconInfos';

import AuthService, { type AuthServiceData } from '@user/services/AuthService';
import RolesHelpers from '@user/helpers/RolesHelpers';
import PaymentConditionTypeService, { type PaymentConditionTypeServiceData } from '@paymentConditionType/services/PaymentConditionTypeService';
import ObjectHelpers from '@helpers/ObjectHelpers';
import { calculatePaymentSchedulesAmount, findApplicableSubscriptionPeriod } from '@shared/Payment/helpers/PaymentUtils';
import TranslationHelpers from '@helpers/TranslationHelpers';
import { formatAmount } from '@payment/helpers/AmountUtils';
import DateService from '_common/services/DateService';

import type { GroupClassDetailed, PaymentSchedule, UserRegistered } from '@groupClass/types';
import type { PaymentConditionType } from '@paymentConditionType/types';
import type { Error, ErrorProps } from '@core/types';
import type { User } from '@user/types';

import { errorColor } from '@app/constants/styles';
import { COMMENT_LENGTH_LIMIT } from '@app/constants/constants';

import {
  PAYMENT_FREQUENCY_ANNUAL,
  CONDITION_TYPE_SUBSIDY,
  CONDITION_TYPE_OTHER,
  CONDITION_TYPE_ANNUAL_FEES,
} from '@paymentConditionType/constants';

type Props = {
  data: UserRegistered | null,
  errors: Error[],
  groupClass: GroupClassDetailed,
  onChange: (event: SyntheticEvent<HTMLInputElement>) => void,
  onChangeData: (data: UserRegistered) => void,
};

const PaymentForm = (props: Props): React$Node => {
  const {
    data,
    groupClass,
    onChange,
    onChangeData,
    errors,
  } = props;
  const { t, i18n: { language } } = useTranslation();

  const [ currentUser, setCurrentUser ] = useState<User | null>(AuthService.user);
  const [ mustCreateSchedules, setMustCreateSchedules ] = useState<boolean>(true);
  const [ isLoading, setLoading ] = useState<boolean>(PaymentConditionTypeService.isLoadingValue);
  const [ conditionTypes, setConditionTypes ] = useState<PaymentConditionType[]>([]);

  const hasAnnualFeeValidated = data?.paymentSchedules
    .find((paymentSchedule) => (
      (paymentSchedule.period.validatedAt && paymentSchedule.areFeesDue) || paymentSchedule.feesPaidAt),
    );

  const validatedPeriodPaymentSchedules = data?.paymentSchedules
    .filter((paymentSchedule) => (
      paymentSchedule.period.validatedAt
    ));

  const handleUserDataUpdate = (data: AuthServiceData): void => {
    setCurrentUser(data.user);
  };

  useEffect(() => AuthService.onChange(handleUserDataUpdate), []);

  const now = new Date();

  const isAdmin = useMemo((): boolean => (
    RolesHelpers.isAdmin(currentUser)
  ), [currentUser]);

  const handleUpdateState = (data: PaymentConditionTypeServiceData): void => {
    setLoading(data.isLoading);
    setConditionTypes(data.conditionTypes);
  };

  useEffect(() => PaymentConditionTypeService.onChange(handleUpdateState), []);

  useEffect(() => {
    PaymentConditionTypeService.fetchAll(language);
  }, [language]);

  const reductionPaymentConditions = useMemo((): PaymentConditionType[] => (
    conditionTypes.filter((condition) => condition.frequency !== PAYMENT_FREQUENCY_ANNUAL)
  ), [conditionTypes]);

  const subscriptionPaymentCondition = useMemo((): PaymentConditionType | null => (
    conditionTypes.find((condition) => condition.frequency === PAYMENT_FREQUENCY_ANNUAL) || null
  ), [conditionTypes]);

  useEffect(() => {
    if (mustCreateSchedules && data) {
      const newData = ObjectHelpers.deepClone(data);
      newData.paymentSchedules = groupClass.periods
        .map((period) => {
          const paymentSchedule = newData?.paymentSchedules?.find((paymentSchedule: PaymentSchedule) => paymentSchedule?.period?.id === period?.id) || null;
          if (paymentSchedule) {
            return paymentSchedule;
          } else {
            if ((period.validatedAt && now < new Date(period.validatedAt)) || !period.validatedAt) {
              return {
                id: null,
                costAdjust: 0,
                period: period,
                amount: period.amount,
              };
            }
          }
          return null;
        })
        .filter((schedule) => schedule !== null);
      onChangeData(newData);
      setMustCreateSchedules(false);
    }
  }, [groupClass, now, data, mustCreateSchedules]);

  const feesPaymentInfo = useMemo((): string => {
    if (data?.subscriptionPaidAt && subscriptionPaymentCondition) {
      return t('groupClasses.groupClass.payment.feesPaid', {
        feeCost: subscriptionPaymentCondition.amount,
        datePaid: DateService.localDateDisplay(data.subscriptionPaidAt, language),
        interpolation: { escapeValue: false },
      });
    }
    return t('groupClasses.groupClass.payment.feesToPay');
  }, [data, subscriptionPaymentCondition, language]);

  const handleChangeCostAdjust = useCallback((periodId: number, adjustment: string): void => {
    const adjustValue = parseFloat(adjustment) || 0;
    const newData = ObjectHelpers.deepClone(data);
    newData.paymentSchedules = newData.paymentSchedules.map((paymentSchedule: PaymentSchedule) => {
      if (paymentSchedule.period.id === periodId) {
        paymentSchedule.costAdjust = adjustValue;
      }
      return paymentSchedule;
    });

    onChangeData(newData);
  }, [data]);

  const handleChangePaymentCondition = useCallback((conditionType: PaymentConditionType): void => {
    if (data !== null) {
      let paymentConditions = ObjectHelpers.deepClone(data.paymentConditions || []);

      const hasConditionType = paymentConditions.some((paymentConditionType) => paymentConditionType.id === conditionType.id);
      if (hasConditionType) {
        paymentConditions = paymentConditions.filter((paymentConditionType) => paymentConditionType.id !== conditionType.id);
      } else {
        paymentConditions.push(conditionType);
      }

      const userRegistered = {
        ...ObjectHelpers.deepClone(data),
        paymentConditions,
      };

      let canChange = true;

      if (hasAnnualFeeValidated && conditionType.code === CONDITION_TYPE_ANNUAL_FEES) {
        canChange = false;
      } else if (conditionType.code === CONDITION_TYPE_SUBSIDY || conditionType.code === CONDITION_TYPE_OTHER) {
        const hasCostAdjustValidated = validatedPeriodPaymentSchedules.find((paymentSchedule) => paymentSchedule.costAdjust);
        if (hasCostAdjustValidated && hasConditionType) {
          canChange = false;
        }
      }

      if (canChange) {
        onChangeData({
          ...userRegistered,
          hasChangedAnnualFees: conditionType.code === CONDITION_TYPE_ANNUAL_FEES,
          paymentSchedules: calculatePaymentSchedulesAmount(userRegistered),
        });
      }
    }
  }, [data, onChangeData, now, validatedPeriodPaymentSchedules]);

  const handleExemption = useCallback((paymentSchedule: PaymentSchedule, costAdjust: number) => {
    if (data !== null) {
      const paymentConditions = ObjectHelpers.deepClone(data.paymentConditions || []);

      const hasConditionType = paymentConditions.some((condition) => condition.code === CONDITION_TYPE_OTHER);
      if (!hasConditionType) {
        const conditionType = reductionPaymentConditions.find((condition) => condition.code === CONDITION_TYPE_OTHER);
        if (conditionType) {
          paymentConditions.push(conditionType);
        }
      }

      const paymentSchedules = (data.paymentSchedules || []).map((schedule) => ({
        ...schedule,
        costAdjust: paymentSchedule.period.id === schedule.period.id ? costAdjust : schedule.costAdjust,
      }));

      const userRegistered = {
        ...ObjectHelpers.deepClone(data),
        paymentConditions,
        paymentSchedules,
      };

      onChangeData({
        ...userRegistered,
        paymentSchedules: calculatePaymentSchedulesAmount(userRegistered),
      });
    }
  }, [data, reductionPaymentConditions, onChangeData, now]);

  const totalOwed = useMemo((): number => {
    if (data) {
      const paymentSchedules = calculatePaymentSchedulesAmount(data);

      const totalAmount = paymentSchedules.reduce((amount, schedule) => {
        const scheduleTotal = schedule.period.amount + schedule.discount + parseFloat(schedule.costAdjust);
        return amount + (scheduleTotal > 0 ? scheduleTotal : 0);
      }, 0);
      return totalAmount > 0 ? totalAmount : 0;
    }

    return 0;
  }, [data, now]);

  const isRequired = useMemo((): boolean => (
    data?.paymentConditions?.some((paymentCondition) => paymentCondition.code === CONDITION_TYPE_SUBSIDY || paymentCondition.code === CONDITION_TYPE_OTHER) || false
  ), [data]);

  const annualFeesPeriodId = useMemo((): number => (
    findApplicableSubscriptionPeriod((data?.paymentSchedules || []), data?.hasChangedAnnualFees)
  ), [data]);

  const errorsProps = useMemo((): ErrorProps => {
    let errorsPropsTmp = {};
    errors.forEach((error) => {
      let errorTranslationKey;
      errorTranslationKey = t(TranslationHelpers.getCommonErrorKeyByCode(error.code, error.propertyPath));
      Object.assign(errorsPropsTmp, {
        [`${ error.propertyPath }`]: {
          color: 'is-danger',
          borderColor: errorColor,
          labelProps: { className: 'has-text-danger' },
          labelTextAreaProps: {
            className: 'has-text-danger label',
          },
          selectFieldHelperText: { text: t('commonError.thisFieldIsMandatory'), textColor: 'is-danger' },
          error: {
            text: errorTranslationKey,
            textColor: 'is-danger',
          },
        },
      });
    });
    return errorsPropsTmp;
  }, [errors]);

  useEffect(() => {
    if (Object.keys(errorsProps).length > 0) {
      setMustCreateSchedules(true);
    }
  }, [errorsProps]);

  const alertPayments = useMemo((): boolean => (
    (data?.paymentSchedules || []).length !== (groupClass?.periods || []).length
  ), [data, groupClass]);

  return (
    <div className="beneficiary-payment-edit">
      <h2 className="label-title">{ t('groupClasses.groupClass.peoples.form.paiementParticipantCondition') }</h2>
      <div className="payment-condition container">
        <div className="columns is-flex-wrap-wrap mb-3">
          <div className="column is-2-widescreen is-12-desktop is-12-tablet">
            <p>{ t('groupClasses.groupClass.payment.discounts') }</p>
          </div>
          { !isLoading && reductionPaymentConditions.map((paymentCondition, index) => {
            const found = data?.paymentConditions?.some(
              (dataPaymentCondition) => dataPaymentCondition.id === paymentCondition.id,
            );
            const checkboxId = `payment-condition-checkbox-${ paymentCondition.id }`;

            return (
              <div className="column is-2-widescreen is-4-desktop is-4-tablet"
                key={ `payment-condition-${ paymentCondition.id }-${ index }` }
              >
                <CheckboxField
                  id={ checkboxId }
                  className="condition-checkbox"
                  checked={ found }
                  onChange={ () => handleChangePaymentCondition(paymentCondition) }
                >
                  { paymentCondition.name }
                </CheckboxField>
              </div>
            );
          }) }
        </div>
      </div>
      <div className="payment-condition container">
        { !isLoading && subscriptionPaymentCondition && (
          <div className="columns is-flex-wrap-wrap">
            <div className="column is-4-desktop">
              <p>{ feesPaymentInfo }</p>
            </div>
            <div className="column is-8-desktop">
              <CheckboxField
                id={ `subscription-payment-condition-${ subscriptionPaymentCondition.id }` }
                className="condition-checkbox"
                key={ `payment-condition-${ subscriptionPaymentCondition.id }` }
                checked={ data?.paymentConditions?.some(
                  (dataPaymentCondition) => dataPaymentCondition.id === subscriptionPaymentCondition.id,
                ) }
                onChange={ () => handleChangePaymentCondition(subscriptionPaymentCondition) }
              >
                { subscriptionPaymentCondition.name }
              </CheckboxField>
            </div>
          </div>
        ) }
      </div>
      <div className="comment-field">
        <TextareaField
          className="comment"
          limitedChar={ COMMENT_LENGTH_LIMIT }
          classNameTextarea="textarea"
          name="paymentComment"
          label={ t('groupClasses.groupClass.peoples.form.paymentCommentary') }
          value={ data?.paymentComment || '' }
          onChange={ onChange }
          required={ isRequired }
          color={ errorsProps.paymentComment?.color }
          labelProps={ errorsProps.paymentComment?.labelProps }
          helpProps={ errorsProps.paymentComment?.error }
        />
      </div>

      <div className="period-container">

        <h2 className="label-title">{ t('groupClasses.groupClass.payment.paymentSummary') }</h2>

        <table>
          <thead>
            <tr className="table-entitled">
              <td>{ t('payment.period') }</td>
              <td>{ t('groupClasses.groupClass.detailed.tarif') }</td>
              <td>{ t('groupClasses.groupClass.payment.paymentConditions') }</td>
              <td>{ t('groupClasses.groupClass.payment.costAdjust') }</td>
              <td>{ t('groupClasses.groupClass.payment.amountDue') }</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            { groupClass.periods.map((period) => {
              const paymentSchedule = (data?.paymentSchedules || []).find((paymentSchedule: PaymentSchedule) => paymentSchedule.period.id === period.id);

              if (paymentSchedule) {
                return (
                  <PaymentFormRow
                    key={ `period-${ period.id }` }
                    isAdmin={ isAdmin }
                    paymentConditions={ data?.paymentConditions || [] }
                    paymentSchedule={ paymentSchedule }
                    period={ period }
                    annualFeesPeriodId={ hasAnnualFeeValidated ? hasAnnualFeeValidated.period.id : annualFeesPeriodId }
                    errorsProps={ errorsProps }
                    handleCostAdjust={ handleChangeCostAdjust }
                    handleExemption={ handleExemption }
                  />
                );
              }
            }) }
          </tbody>
        </table>
        { alertPayments && (
          <div className="custom-error-container">
            <div className="errorPeriods custom-error-message">
              <IconInfos />
              { t('display.error.validatedPeriods.archivedPeriods') }
            </div>
          </div>
        ) }

        <p className="total-amount">
          { t('groupClasses.groupClass.payment.totalAmountDue') }
          &nbsp;=&nbsp;
          { formatAmount(totalOwed, language) } &euro;
        </p>
      </div>

    </div>
  );
};

export default PaymentForm;
