// @flow
import { useMemo } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

type Props = {
  className?: string,
  value?: string,
  trad?: string,
  required?: boolean,
  children?: React$Node,
};

const DescriptionContainer = (props: Props): React$Node => {
  const { children, className, required, trad, value } = props;
  const { t } = useTranslation();

  const translation = trad ?? 'common.description';

  const descriptionContainerClassName = useMemo((): string => clsx({
    'description-container': true,
    [String(className)]: !!className,
  }), [className]);

  return (
    <div className={ descriptionContainerClassName }>
      <div className="description-container-title">
        { t(translation) }{ required && '*' }
      </div>
      { value && <div className="description-container-value">{ value }</div> }
      { children }
    </div>
  );
};

export default DescriptionContainer;
