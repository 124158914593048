// @flow
import { WEB_PATHS } from '@app/constants/paths';
const { GROUP_CLASSES, GROUP_CLASSES_BENEFICIARY, SHARED_SESSIONS } = WEB_PATHS;

export const GROUP_CLASSES_TAB_NAME = 'groupClassesSearch';
export const BENEFICIARY_TAB_NAME = 'groupClassesBeneficiary';
export const SHARED_SESSIONS_TAB_NAME = 'mySharedSessions';

export const GROUP_CLASSES_TAB = {
  name: GROUP_CLASSES_TAB_NAME,
  translationKey: '',
  isActive: false,
  link: GROUP_CLASSES,
};

export const BENEFICIARY_TAB = {
  name: BENEFICIARY_TAB_NAME,
  translationKey: 'navigation.myGroupClasses',
  isActive: false,
  link: GROUP_CLASSES_BENEFICIARY,
};

export const SHARED_SESSIONS_TAB = {
  name: SHARED_SESSIONS_TAB_NAME,
  translationKey: 'navigation.mySharedSessions',
  isActive: false,
  link: SHARED_SESSIONS,
};
