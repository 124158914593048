// @flow
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import type { Tab } from '@shared/Navigation/TabElement/types';
import { uppercaseFirst } from '_common/services/CommonUtils';

type Props = {
  tab: Tab,
  badge?: number,
  onClick: () => void,
};

const TabElement = (props: Props): React$Node => {
  const { tab, onClick, badge } = props;
  const { t } = useTranslation();

  const tabElementClassName = useMemo((): string => clsx({
    'tab-element': true,
    'is-active': tab.isActive,
  }), [tab.isActive]);

  const tabElementlinkClassName = useMemo((): string => clsx({
    'tab-element-link': true,
    'is-active': tab.isActive,
  }), [tab.isActive]);

  return (
    <div
      key={ tab.name }
      className={ tabElementClassName }
    >
      <Link
        to={ tab.link }
        onClick={ onClick }
        className={ tabElementlinkClassName }
      >
        { uppercaseFirst(t(tab.translationKey)) }
      </Link>
      { !!badge && (<div className="tab-element-badge">{ badge }</div>) }
    </div>
  );
};

export default TabElement;
