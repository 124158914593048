// @flow
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import BeneficiaryLayout from '@layout/components/BeneficiaryLayout';
import IconCircleXMark from '@icons/components/IconCircleXMark';

import { WEB_PATHS } from '@app/constants/paths';
const { GROUP_CLASSES_BENEFICIARY } = WEB_PATHS;

const MoneticoError = (): React$Node => {
  const { t } = useTranslation();

  return (
    <BeneficiaryLayout>
      <div className="monetico-container container">
        <div className="monetico">
          <IconCircleXMark className="monetico-icon color-error" />
          <h1 className="monetico-title">{ t('payment.failedPayment') }</h1>
          <p className="monetico-text">{ t('payment.textFailedPayment') }</p>
          <div className="monetico-button">
            <Link
              to={ GROUP_CLASSES_BENEFICIARY }
              className="monetico-button button button-tertiary"
            >
              { t('payment.btnPayment') }
            </Link>
          </div>
        </div>
      </div>
    </BeneficiaryLayout>
  );
};

export default MoneticoError;
