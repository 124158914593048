// @flow
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { Coach } from '@coach/types';

type Props = {
  coaches: Coach[],
};

const CoachTable = (props: Props): React$Node => {
  const { coaches } = props;
  const { t } = useTranslation();

  const filteredCoaches = useMemo((): Coach[] => (
    coaches
      .filter((coach) => coach.id)
      .sort((coach) => coach.isMainCoach ? -1 : 1)
  ), [coaches]);

  const mainCoach = `${ t('payment.name') } ${ t('payment.name.main') }${ t('common.colons') }`;
  const otherCoach = `${ t('payment.name') }${ t('common.colons') }`;

  return (
    <div className="coach-container">
      { filteredCoaches.map((coach, index) => (
        <div key={ coach.id } className="coach-table">
          <div className="columns">
            <div className="column is-3-desktop">
              <label className="coach-table-title">
                { index === 0 ? mainCoach : otherCoach }
              </label>
            </div>
            <div className="column is-3-desktop">
              <div className="coach-table-value">
                { coach.sielBleuUser.firstName } { coach.sielBleuUser.lastName.toUpperCase() }
              </div>
            </div>
            <div className="column is-3-desktop">
              <label className="coach-table-title">
                { t('groupClasses.groupClass.detailed.phone') }{ t('common.colons') }
              </label>
            </div>
            <div className="column is-3-desktop">
              <div className="coach-table-value">
                { coach.contactInformation }
              </div>
            </div>
          </div>
        </div>
      )) }
    </div>
  );
};

export default CoachTable;
