// @flow
import Image from '@shared/Image';

type Props = {
  title: string,
  icon: string,
};

const SendIconCard = (props: Props): React$Node => {
  const { icon, title } = props;

  return (
    <div className="send-icon-card">
      <div className="send-icon-card-picture">
        <Image src={ icon } alt="icon" className="send-icon-picture"/>
      </div>
      <p className="send-icon-title">{ title }</p>
    </div>
  );
};

export default SendIconCard;
