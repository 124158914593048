// @flow
import type { Error, Listener } from '@core/types';
import type { Beneficiary } from '@beneficiary/types';
import EventEmitter from '_common/services/EventEmitter';
import ApiService from '@api/service';
import { parseBeneficiaryFromPayload, parseBeneficiary } from '@beneficiary/helpers/BeneficiariesUtils';

import { API_PATHS } from '@app/constants/paths';
const { BENEFICIARIES } = API_PATHS;

export type BeneficiaryServiceData = {
  beneficiary: Beneficiary | null,
  isLoading: boolean,
  errors: Error[],
};

type UpdateValues = (newBeneficiary: Beneficiary | null, newIsLoading: boolean, newErrors?: Error[]) => void;
type CleanErrors = () => void;
type FetchOne = (id: number, language: string) => Promise<Beneficiary>;
type Update = (id: number, data: Beneficiary) => Promise<any>;
type OnChange = (listener: Listener) => Function;
type Trigger = () => void;

let sourceOne = ApiService.createToken();

class BeneficiaryService {
  constructor() {
    this.eventEmitter = new EventEmitter();

    this.beneficiary = null;
    this.isLoading = false;
    this.errors = [];
  }
  eventEmitter: EventEmitter;
  beneficiary: Beneficiary | null;
  isLoading: boolean;
  errors: Error[];

  get beneficiaryValue(): Beneficiary | null {
    return this.beneficiary;
  }
  get isLoadingValue(): boolean {
    return this.isLoading;
  }
  get errorsValues(): Error[] {
    return this.errors;
  }

  // TODO: Implements getters/setters

  updateValues: UpdateValues = (newBeneficiary: Beneficiary | null, newIsLoading: boolean, newErrors: Error[] = []): void => {
    this.beneficiary = newBeneficiary;
    this.isLoading = newIsLoading;
    this.errors = newErrors;
    this.#trigger();
  };

  cleanErrors: CleanErrors = (): void => {
    this.updateValues(this.beneficiary, false, []);
  };

  fetchOne: FetchOne = (id: number, language: string): Promise<Beneficiary> => {
    sourceOne.cancel();
    sourceOne = ApiService.createToken();

    this.updateValues(this.beneficiary, true, []);

    return ApiService.request({
      method: 'get',
      url: `${ BENEFICIARIES }/${ id }`,
      cancelToken: sourceOne.token,
      headers: {
        'Accept-Language': language,
      },
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;
          const beneficiaryFromApi = parseBeneficiaryFromPayload(data);

          this.updateValues(beneficiaryFromApi, false, this.errors);
          return beneficiaryFromApi;
        }
        return Promise.reject();
      })
      .catch(() => {
        this.updateValues(this.beneficiary, false, this.errors);
        return Promise.reject();
      });
  };

  update: Update = (id: number, data: Beneficiary): Promise<any> => {
    this.updateValues(this.beneficiary, true, []);

    return ApiService.request({
      method: 'put',
      url: `${ BENEFICIARIES }/${ id }`,
      data: parseBeneficiary(data),
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;
          const newBeneficiary: Beneficiary = parseBeneficiaryFromPayload(data);

          this.updateValues(newBeneficiary, false);
          return Promise.resolve();
        }
        this.updateValues(this.beneficiary, false, this.errors);
        return Promise.reject();
      })
      .catch((error) => {
        const errorsViolations = error.response?.data?.violations || this.errors;
        this.updateValues(this.beneficiary, false, errorsViolations);
        return Promise.reject();
      });
  };

  onChange: OnChange = (listener: Listener): Function => {
    const listenerFunction = this.eventEmitter.addListener(listener);
    this.#trigger();
    return listenerFunction;
  };

  /**
   * @private
   */
  #trigger: Trigger = (): void => {
    this.eventEmitter.trigger({
      beneficiary: this.beneficiary,
      isLoading: this.isLoading,
      errors: this.errors,
    });
  };
}

const instance: BeneficiaryService = new BeneficiaryService();
export default instance;
