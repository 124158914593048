// @flow
import { useMemo } from 'react';
import clsx from 'clsx';

type Props = {
  className: string,
  onClick: () => void,
};

const IconX = (props: Props): React$Node => {
  const {
    className,
    onClick,
  } = props;

  const iconSocialClassName = useMemo((): string => clsx({
    'icon-social': true,
    [String(className)]: !!className,
  }), [className]);

  const newProps = {
    className: iconSocialClassName,
    onClick,
  };

  return (
    <svg { ...newProps } viewBox="0 0 52.34 52.67" width="52.34" height="52.67">
      <g>
        <path className="bg"
          d="M0,3.77C0,1.69,1.73,0,3.87,0h44.6c2.14,0,3.87,1.69,3.87,3.77v45.12c0,2.08-1.73,3.77-3.87,3.77H3.87c-2.14,0-3.87-1.69-3.87-3.77V3.77Z"/>
        <path className="icon"
          d="M29.23,23.81l11.98-13.93h-2.84l-10.41,12.1-8.31-12.1h-9.59l12.57,18.29-12.57,14.61h2.84l10.99-12.77,8.78,12.77h9.59l-13.03-18.97h0ZM25.34,28.34l-1.27-1.82-10.13-14.49h4.36l8.18,11.7,1.27,1.82,10.63,15.2h-4.36l-8.67-12.41h0Z"/>
      </g>
    </svg>
  );
};

IconX.defaultProps = {
  className: 'icon-x',
  onClick: () => {
  },
};

export default IconX;
