// @flow
import type { Listener } from '@core/types';

import EventEmitter from '_common/services/EventEmitter';
import ApiService from '@api/service';

import { API_PATHS } from '@app/constants/paths';
const { SIEL_BLEU_USERS_RESET_KEY_REQUEST, SIEL_BLEU_USERS_PASSWORD_UPDATE } = API_PATHS;

import { type PasswordUpdateOutput } from '@login/types';

export type PasswordUpdateServiceData = {
  isLoading: boolean,
  errors: Error[],
};

type ResetKeyRequest = (email: string) => Promise<any>;
type PasswordUpdate = (resetKey: string, password: string, passwordBis: string) => Promise<PasswordUpdateOutput>;
type OnChange = (listener: Listener) => Function;
type Trigger = () => void;

class PasswordUpdateService {
  constructor() {
    this.eventEmitter = new EventEmitter();

    this._isLoading = false;
    this.errors = [];
  }

  eventEmitter: EventEmitter;
  _isLoading: boolean;
  errors: Error[];

  get isLoading(): boolean { return this._isLoading; }

  set isLoading(data: boolean): void { this._isLoading = data; this.#trigger(); }

  resetKeyRequest: ResetKeyRequest = (email: string): Promise<any> => {
    this.isLoading = true;

    return ApiService.request({
      method: 'post',
      url: SIEL_BLEU_USERS_RESET_KEY_REQUEST,
      data: {
        email,
      },
    })
      .then(() => {
        this.isLoading = false;
        return Promise.resolve();
      })
      .catch(() => {
        this.isLoading = false;
        return Promise.reject();
      });
  };

  passwordUpdate: PasswordUpdate = (resetKey: string, password: string, passwordBis: string): Promise<PasswordUpdateOutput> => {
    this.isLoading = true;

    return ApiService.request({
      method: 'post',
      url: SIEL_BLEU_USERS_PASSWORD_UPDATE,
      data: {
        resetKey,
        password,
        passwordBis,
      },
    })
      .then((res) => {
        this.isLoading = false;
        return Promise.resolve({ updated: res.data.updated });
      })
      .catch(() => {
        this.isLoading = false;
        return Promise.reject();
      });
  };

  onChange: OnChange = (listener: Listener): Function => {
    const listenerFunction = this.eventEmitter.addListener(listener);
    this.#trigger();
    return listenerFunction;
  };

  /**
   * @private
   */
  #trigger: Trigger = (): void => {
    this.eventEmitter.trigger({
      isLoading: this._isLoading,
      errors: this.errors,
    });
  };
}

const instance: PasswordUpdateService = new PasswordUpdateService();
export default instance;
