// @flow

import { useParams, Link } from 'react-router-dom';
import clsx from 'clsx';

import ParticipantIndicator from '@shared/ParticipantIndicator';

import type { ShortGroupClassRequest } from '@participant/types';
import { PERSON_TYPE_BENEFICIARY } from '@person/constants';
import { WEB_PATHS } from '@app/constants/paths';
const { PARTICIPANTS_CLASS_REQUEST_PERSON } = WEB_PATHS;

type Props = {
  request: ShortGroupClassRequest,
};

type UseParamProps = {
  classId: string,
  requestId: string,
};

const ListRequestRow = (props: Props): React$Node => {
  const { request } = props;
  const { classId: id, requestId } = useParams<UseParamProps>();
  const { firstName, lastName } = request.person;
  const lastNameFormatted = lastName.toUpperCase();
  const isBeneficiary = request.person.type === PERSON_TYPE_BENEFICIARY;
  const label = `${ firstName } ${ lastNameFormatted }`;
  const className = clsx({
    'people': true,
    'list-registered-request': true,
    'is-selected': String(request.id) === requestId,
  });

  return (
    <li className={ className } title={ label }>
      <Link to={ PARTICIPANTS_CLASS_REQUEST_PERSON.replace(':classId', id).replace(':requestId', String(request.id)) } >
        <div className="list-requests-line-people">
          <ParticipantIndicator isBeneficiary={ isBeneficiary } />
          <span className="list-requests-name">{ label }</span>
        </div>
      </Link>
    </li>
  );
};

export default ListRequestRow;
