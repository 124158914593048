// @flow

type Props = {
  inError?: boolean,
};

const ImageLoader = (props: Props): React$Node => {
  const { inError } = props;

  const noPictureImg = '__ASSET_PATH__/assets/no-picture.svg';

  return (
    <div className="image-loader-container">
      { inError ? (
        <img className="no-image-picture" src={ noPictureImg } />
      ) : (
        <svg className="circular" viewBox="25 25 50 50">
          <circle
            className="path"
            cx="50"
            cy="50"
            r="20"
            fill="none"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </svg>
      ) }
    </div>
  );
};

export default ImageLoader;
