// @flow
import { useEffect, useState } from 'react';

import type { GroupClassDetailed } from '@groupClass/types';
import type { Error } from '@core/types';
import ApiService from '@api/service';
import { hydrateGroupClassDetailedFromPayload } from '@groupClass/helpers/GroupClassesUtils';

import { API_PATHS } from '@app/constants/paths';

const { GROUP_CLASSES } = API_PATHS;

type UseGroupClassResponse = [
  GroupClassDetailed | null,
  boolean,
  (groupClass: GroupClassDetailed | null) => void,
  Error[],
];

let source = ApiService.createToken();

const useGroupClass = (id: string, language: string): UseGroupClassResponse => {
  const [ groupClass, setGroupClass ] = useState<GroupClassDetailed | null>(null);
  const [ isLoading, setLoading ] = useState<boolean>(false);
  const [ errors, setErrors ] = useState<Error[]>([]);

  useEffect(() => {
    source.cancel();
    source = ApiService.createToken();

    setLoading(true);
    ApiService.request({
      method: 'get',
      url: `${ GROUP_CLASSES }/${ id }`,
      cancelToken: source.token,
      headers: {
        'Accept-Language': language,
      },
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;

          const groupClassData = hydrateGroupClassDetailedFromPayload(data);

          setGroupClass(groupClassData);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 404) {
            setErrors([error.response.data]);
          }
        }
      });
    return () => {
      source.cancel();
    };
  }, [id, language]);

  return [groupClass, isLoading, setGroupClass, errors];
};

export default useGroupClass;
