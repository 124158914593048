// @flow
import moment from 'moment/dist/moment';

import type {
  Period,
  PaymentSchedule,
  PaymentSchedulePayload,
} from '@groupClass/types';

import { hydratePeriodFromPayload } from '@groupClass/helpers/PeriodUtils';

import ObjectHelpers from '@helpers/ObjectHelpers';

export const findTheNearestPeriod = (periods: Period[]): Period => {
  const now = moment();
  const currentPeriod = periods.find((period) => (
    moment(period.startDate).isSameOrBefore(now, 'day')
    && moment(period.endDate).isSameOrAfter(now, 'day')
  ));

  if (currentPeriod) {
    return currentPeriod;
  }

  const nearestPeriod = ObjectHelpers.deepClone(periods)
    .reverse()
    .find((period) => moment(period.endDate).isBefore(now, 'day'));

  if (nearestPeriod) {
    return nearestPeriod;
  }
  return periods[0];
};

export const findTheNearestPaymentSchedule = (paymentSchedules: PaymentSchedule[]): PaymentSchedule | null => {
  const now = moment();

  const currentPaymentSchedule = paymentSchedules.find((paymentSchedule) => {
    const { period } = paymentSchedule;
    return moment(period.startDate).isSameOrBefore(now, 'day') && moment(period.endDate).isSameOrAfter(now, 'day');
  });

  if (currentPaymentSchedule) {
    return currentPaymentSchedule;
  }

  const nearestPaymentSchedule = ObjectHelpers.deepClone(paymentSchedules)
    .reverse()
    .find((paymentSchedule) => {
      const { period } = paymentSchedule;
      return moment(period.endDate).isBefore(now, 'day');
    });

  if (nearestPaymentSchedule) {
    return nearestPaymentSchedule;
  }

  if (paymentSchedules.length > 0) {
    return paymentSchedules[0];
  }

  return null;
};

export const getPaymentScheduleAmountDue = (paymentSchedule: PaymentSchedule): number => (
  parseFloat(paymentSchedule.period.amount) + parseFloat(paymentSchedule.costAdjust) + parseFloat(paymentSchedule.discount) - parseFloat(paymentSchedule.alreadyPaid)
);

export const getSelectedPaymentSchedule = (paymentSchedules: PaymentSchedule[], id: string): PaymentSchedule | null => (
  paymentSchedules.find((paymentSchedule) => paymentSchedule.id === parseInt(id)) || null
);

export const hydratePaymentScheduleFromPayload = (payload: PaymentSchedulePayload): PaymentSchedule => ({
  id: payload.id,
  amount: payload.amount,
  discount: payload.discount,
  costAdjust: payload.costAdjust,
  feesPaidAt: payload.feesPaidAt ? new Date(payload.feesPaidAt) : null,
  alreadyPaid: payload.alreadyPaid,
  period: hydratePeriodFromPayload(payload.period),
  areFeesDue: payload.areFeesDue,
});
