// @flow
import { format as dateFmt } from 'date-fns';

import type {
  BalancePayload,
  Balance,
  CreditCardBalancePayload,
  CreditCardBalance,
  ChequeBalance,
  ChequeBalancePayload,
} from '@payment/types';
import { hydratePersonFromPayload } from '@person/helpers/PersonUtils';
import { FORMAT_DATE_API } from '@app/constants/dates';

export const hydrateBalancesFromPayload = (balancesPayload: Array<BalancePayload>): Array<Balance> => (
  balancesPayload.map((balancePayload) => {
    const unsubscribedAt = balancePayload.unsubscribedAt ? new Date(balancePayload.unsubscribedAt) : null;
    return {
      paid: balancePayload.paid,
      person: hydratePersonFromPayload(balancePayload.person),
      status: balancePayload.status,
      toPay: balancePayload.toPay,
      type: balancePayload.type,
      unsubscribedAt,
    };
  })
);

export const hydrateCreditCardBalancesFromPayload = (balancesPayload: Array<CreditCardBalancePayload>): Array<CreditCardBalance> => (
  balancesPayload.map((balancePayload) => {
    const unsubscribedAt = balancePayload.unsubscribedAt ? dateFmt(new Date(balancePayload.unsubscribedAt), FORMAT_DATE_API) : null;
    const paidAt = balancePayload.paidAt ? new Date(balancePayload.paidAt) : null;
    return {
      id: balancePayload.id,
      paid: balancePayload.paid,
      paidAt,
      person: hydratePersonFromPayload(balancePayload.person),
      reference: balancePayload.reference,
      status: balancePayload.status,
      toPay: balancePayload.toPay,
      unsubscribedAt,
      registeredId: balancePayload.registeredId,
      isPaymentOnValidatedPeriod: balancePayload.isPaymentOnValidatedPeriod,
      periodId: balancePayload.periodId,
    };
  })
);

export const hydrateChequeBalancesFromPayload = (balancesPayload: Array<ChequeBalancePayload>): Array<ChequeBalance> => (
  balancesPayload.map((balancePayload) => {
    const unsubscribedAt = balancePayload.unsubscribedAt ? dateFmt(new Date(balancePayload.unsubscribedAt), FORMAT_DATE_API) : null;
    const paidAt = balancePayload.paidAt ? new Date(balancePayload.paidAt) : null;
    return {
      id: balancePayload.id,
      paid: balancePayload.paid,
      paidAt,
      person: hydratePersonFromPayload(balancePayload.person),
      registeredId: balancePayload.registeredId,
      reference: balancePayload.reference,
      bankDepositReference: balancePayload.bankDepositReference,
      toPay: balancePayload.toPay,
      unsubscribedAt,
      status: balancePayload.status,
      isPaymentOnValidatedPeriod: balancePayload.isPaymentOnValidatedPeriod,
      periodId: balancePayload.periodId,
    };
  })
);
