// @flow
import { useTranslation } from 'react-i18next';
import type { Cities, SearchCity } from '@shared/Locations/types';
import {
  CITY_REGEX,
  SPAIN,
  GOOGLE_MAPS_API_KEY,
  ZIPCODE_REGEX,
} from '@app/constants/constants';
import AdvancedSelectField from '@shared/AdvancedSelectField';
import React, { useCallback, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import CITIES from '@root/src/Apollo/queries/cities';
import type { Option } from '@core/types';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

type Props = {
  isAdmin: boolean,
  locale: string,
  value: SearchCity | null,
  onChange: (event: SyntheticEvent<HTMLInputElement>) => void,
};

const SearchField = (props: Props): React$Node => {
  const { isAdmin, value, locale, onChange } = props;
  const { t, i18n: { language } } = useTranslation();
  const [suggestCities, setSuggestCities] = useState<Option[] | null>(null);
  const [city, setCity] = useState<Option | null>(null);
  const [fetchCities] = useLazyQuery(CITIES);

  const isSearchable = useMemo(() => (value: string) => value.match(`^${ ZIPCODE_REGEX }$`) || value.match(`^${ CITY_REGEX }$`), []);

  const onChangeSearch = useCallback((search: string) => {
    if (isSearchable(search)) {
      // For french users the city search autocompletion is made on FizzUp api because the results is more accurate than Google Places
      fetchCities({ variables: { search } }).then(({ data }: { data?: { cities: Cities[] } }) => {
        const suggestCitiesData: Option[] = (data?.cities || []).map((c) => ({
          value: { lat: c.location.latitude, lng: c.location.longitude },
          label: `${ c.zipcode } ${ c.name.charAt(0).toUpperCase() }${ c.name.slice(1).toLowerCase() }`,
        }));
        setSuggestCities([...suggestCitiesData]);
      });
    }
  }, [fetchCities]);

  const onChangeCity = useCallback((selectedCity: any) => {
    // handle clear input
    if (!selectedCity) {
      setSuggestCities(null);
    }
    setCity(selectedCity);
    onChange(selectedCity);
  }, [onChange]);

  const apiOptions = {
    language: language,
    libraries: ['places'],
  };
  const componentRestrictions = {
    country: ['es'],
  };

  if (language === SPAIN) {
    apiOptions.region = 'es';
  }

  if (isAdmin) {
    componentRestrictions.country.push('fr');
  }

  return (
    <form className="is-flex search-field-map">
      <>
        { (isAdmin || locale === SPAIN) ? (
          <GooglePlacesAutocomplete
            apiKey={ GOOGLE_MAPS_API_KEY }
            debounce={ 500 }
            id="google-search-autocomplete"
            minLengthAutocomplete={ 1 }
            apiOptions={ apiOptions }
            selectProps={ {
              className: 'search-field-input-map g-form-field',
              placeholder: t('groupClasses.searchFieldPlaceholder'),
              loadingMessage: () => t('common.loading'),
              noOptionsMessage: () => t('common.noOptions'),
              isClearable: true,
              onChange,
              value,
              styles: {
                menu: (provided) => ({
                  ...provided,
                  'height': 'initial',
                }),
              },
            } }
            autocompletionRequest={ {
              types: ['(regions)'],
              componentRestrictions,
            } }
          />
        ) : (
          <AdvancedSelectField
            name="city"
            placeholder={ t('groupClasses.searchFieldPlaceholder') }
            options={ suggestCities }
            isClearable
            value={ city }
            className="search-field-input-map g-form-field"
            onInputChange={ (e) => onChangeSearch(e) }
            onChange={ (e) => onChangeCity(e) }
            isSearchable={ true }
            onFocus={ () => setSuggestCities(null) }
          />
        ) }
      </>
    </form>
  );
};

export default SearchField;
