//@flow
import { useTranslation } from 'react-i18next';

import type { GroupClass } from '@groupClass/types';
import type { ErrorProps } from '@core/types';

import InputField from '_common/components/input-field/InputField';

import * as groupClassesConstants from '@app/constants/constants';

const {
  REGISTRATION_TERMS_LENGTH_LIMIT,
  FRANCE,
} = groupClassesConstants;

type Props = {
  isAlreadyEdited: boolean,
  errorsProps: ErrorProps,
  groupClass: GroupClass,
  onChange: (event: SyntheticEvent<HTMLInputElement>) => void,
};

const Registration = (props: Props): React$Node => {
  const { t } = useTranslation();
  const { isAlreadyEdited, errorsProps, groupClass, onChange } = props;

  return (
    <>
      <h2 className="edit-title">{ t('groupClasses.groupClass.detailed.registration') }</h2>

      <InputField
        id="registrationTerms"
        name="registrationTerms"
        label={ t('groupClasses.groupClass.detailed.registrationTerm') }
        limitedChar={ REGISTRATION_TERMS_LENGTH_LIMIT }
        color={ errorsProps.registrationTerms?.color }
        labelProps={ errorsProps.registrationTerms?.labelProps }
        helpProps={ errorsProps.registrationTerms?.error }
        className="edit-field"
        value={ !isAlreadyEdited && groupClass.sector.country === FRANCE && groupClass.registrationTerms === ''
          ? t('groupClasses.groupClass.detailed.registrationTermsDefault')
          : groupClass.registrationTerms }
        onChange={ onChange }
        inputProps={ {
          className: '',
          placeholder: t('groupClasses.groupClass.detailed.optional'),
        } }
      />

      <InputField
        id="registrationContact"
        name="registrationContact"
        label={ t('groupClasses.groupClass.detailed.registrationContact') }
        color={ errorsProps.registrationContact?.color }
        labelProps={ errorsProps.registrationContact?.labelProps }
        helpProps={ errorsProps.registrationContact?.error }
        className="edit-field"
        value={ groupClass.registrationContact }
        onChange={ onChange }
      />
    </>
  );
};

export default Registration;
