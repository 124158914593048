// @flow
import EventEmitter from '_common/services/EventEmitter';
import ApiService from '@api/service';

import type { Error, Listener, QueryParams } from '@core/types';

import { API_PATHS } from '@app/constants/paths';
const { PAYMENT_VENTILATED } = API_PATHS;

export type PaymentTransferServiceData = {
  loading: boolean,
  errors: Error[],
};

type OnChange = (listener: Listener) => Function;
type FetchAccoutingExport = (params: QueryParams) => Promise<boolean | null>;
type UpdateValues = (newLoading: boolean, newErrors: Error[]) => void;
type Trigger = () => void;

class PaymentVentilatedService {
  constructor() {
    this.eventEmitter = new EventEmitter();
    this.errors = [];
    this.loading = false;
  }

  eventEmitter: EventEmitter;
  loading: boolean;
  errors: Error[];

  updateValues: UpdateValues = (newLoading: boolean, newErrors: Error[]): void => {
    this.loading = newLoading;
    this.errors = newErrors;
    this.#trigger();
  };

  FetchAccoutingExport: FetchAccoutingExport = (params: QueryParams): Promise<boolean | null> => {
    this.updateValues(true, []);

    return ApiService.request({
      method: 'get',
      url: PAYMENT_VENTILATED,
      params,
    })
      .catch((error) => {
        if (error?.response?.data?.violations) {
          this.updateValues(false, error.response.data.violations);
        } else if (error.response.data?.['hydra:description']) {
          const errorData = {
            propertyPath: Object.keys(params)[0] || 'startDate',
            code: error.response.data['hydra:description'],
            message: error.response.data['hydra:description'],
          };
          this.updateValues(false, [errorData]);
        }
        return Promise.reject();
      })
      .then(({ data }) => {
        if (data?.link) {
          window.open(data?.link, '_blank');
          return Promise.resolve(true);
        }
        return Promise.reject();
      });
  };

  onChange: OnChange = (listener: Listener): Function => {
    const listenerFunction = this.eventEmitter.addListener(listener);
    this.#trigger();
    return listenerFunction;
  };

  /**
   * @private
   */
  #trigger: Trigger = (): void => {
    this.eventEmitter.trigger({
      loading: this.loading,
      errors: this.errors,
    });
  };
}

const instance: PaymentVentilatedService = new PaymentVentilatedService();
export default instance;
