// @flow
import { useState, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import AdminLayout from '@layout/components/AdminLayout';
import ListRequests from '@participant/components/ListRequests';
import ListRegistereds from '@participant/components/ListRegistereds';
import IconChevron from '@icons/components/IconChevron';

import type { GroupClassDetailed } from '@groupClass/types';

import { buttonConstants } from '@shared/Button';
import { ADMIN_HEADER_PARTICIPANTS } from '@layout/constants';
import { WEB_PATHS } from '@app/constants/paths';
const { PARTICIPANTS_SEARCH } = WEB_PATHS;

type Props = {
  groupClass: GroupClassDetailed | null,
  children: React$Node,
  isLoading?: boolean,
  loaderType?: string,
};

const ParticipantsLayout = (props: Props): React$Node => {
  const { children, groupClass, isLoading, loaderType } = props;
  const { t } = useTranslation();

  const [ isOpen, setIsOpen ] = useState<boolean>(false);

  const groupClassDetailedClassName = useMemo((): string => clsx({
    'group-class-peoples': true,
    'opened': !!isOpen,
    'closed': !isOpen,
  }), [isOpen]);

  const toggleMenu = useCallback((): void => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const iconChevronClassName = useMemo((): string => clsx({
    'rotate-90': isOpen,
    'rotate-270': !isOpen,
    'group-class-peoples-chevron': true,
  }), [isOpen]);

  return (
    <AdminLayout
      groupClass={ groupClass }
      activeTab={ ADMIN_HEADER_PARTICIPANTS }
      isLoading={ isLoading }
      loaderType={ loaderType }
      preventPortraitMode
    >
      <div className="group-class-content">
        <div className={ groupClassDetailedClassName }>
          <div className="group-class-peoples-header" onClick={ toggleMenu }>
            <h1 className="group-class-peoples-title-one">
              { t('user.participants') }
              <IconChevron className={ iconChevronClassName } />
            </h1>
          </div>

          <div className="group-class-peoples-header group-class-peoples-link">
            <Link
              className="button button-primary group-class-peoples-add"
              type={ buttonConstants.PRIMARY }
              to={ groupClass ? PARTICIPANTS_SEARCH.replace(':classId', String(groupClass.id)) : '' }
            >
              { t('groupClasses.groupClass.peoples.form.addParticipant') }
            </Link>
          </div>
          <div className="group-class-peoples-lists">
            <div className="group-class-peoples-list-request">
              <ListRequests />
            </div>
            <div className="group-class-peoples-list-registered">
              <ListRegistereds />
            </div>
          </div>
        </div>
        <div className="peoples-container">
          { children }
        </div>
      </div>
    </AdminLayout>
  );
};

export default ParticipantsLayout;
