// @flow
import { useCallback, useEffect, useState, useMemo } from 'react';
import clsx from 'clsx';

import Label from '_common/components/label/Label';

import type { LabelProps } from '@core/types';

type Props = {
  children: React$Node,
  className?: string,
  defaultChecked?: boolean,
  disabled?: boolean,
  labelProps?: LabelProps,
  name: string,
  onChange?: (toggle: boolean) => void,
};

const ToggleButton = (props: Props): React$Node => {
  const {
    children,
    name,
    labelProps = {},
    onChange,
    disabled,
    className,
    defaultChecked = false,
  } = props;

  const [ toggle, setToggle ] = useState<boolean>(false);

  useEffect(() => {
    setToggle(defaultChecked);
  }, [setToggle, defaultChecked]);

  const { className: labelClassName, ...othersLabelProps } = labelProps;

  const triggerToggle = useCallback((): void => {
    if (!disabled) {
      setToggle(!toggle);

      if (onChange) {
        onChange(!toggle);
      }
    }
  }, [disabled, onChange, toggle]);

  const toggleButtonClassName = useMemo((): string => clsx({
    'toggle-button': true,
    [String(className)]: !!className,
  }), [className]);

  const toggleButtonDivClassName = useMemo((): string => clsx({
    'toggle-button-toggle': true,
    'disabled': !!disabled,
    'checked': !!toggle,
  }), [disabled, toggle]);

  const toggleButtonLabelClassName = useMemo((): string => clsx({
    'g-label': true,
    [String(labelClassName)]: !!labelClassName,
  }), [labelClassName]);

  return (
    <div className={ toggleButtonClassName }>
      <div
        onClick={ triggerToggle }
        className={ toggleButtonDivClassName }
      >
        <div className="background" />
        <div className="circle" />
        <input type="checkbox" aria-label="Toggle Button" name={ name } className="input" />
      </div>
      <Label { ...othersLabelProps } htmlFor={ name } className={ toggleButtonLabelClassName }>
        { children }
      </Label>
    </div>
  );
};

export default ToggleButton;
