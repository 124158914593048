// @flow
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PublicRoute from '@shared/PublicRoute/components/PublicRoute';
import Loader from '_common/components/loader/Loader';
import Toast from '_common/services/Toast/Toast';

import LoginService from '@login/services/LoginService';
import AuthService from '@user/services/AuthService';
import RolesHelpers from '@user/helpers/RolesHelpers';
import BeneficiaryGroupClassesRegisteredsService from '@beneficiary/services/BeneficiaryGroupClassesRegisteredsService';
import BeneficiaryGroupClassesRequestsService from '@beneficiary/services/BeneficiaryGroupClassesRequestsService';

import { GROUP_CLASS_REQUEST_INITIATED } from '@app/constants/constants';
import { WEB_PATHS } from '@app/constants/paths';
const { GROUP_CLASSES, GROUP_CLASSES_BENEFICIARY } = WEB_PATHS;

const LoginSingleSignOn = (): React$Node | null => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const navigate = useNavigate();

  const queryParameters = search.substring(1);

  useEffect(() => {
    LoginService.authenticateToken(queryParameters)
      .then(() => {
        const user = AuthService.user;

        if (user && RolesHelpers.isBeneficiary(user)) {
          Promise.all([
            BeneficiaryGroupClassesRegisteredsService.fetchAll(user.id, 'fr_FR', { 'exists[unsubscribedAt]' : false }),
            BeneficiaryGroupClassesRequestsService.fetchAll(user.id, 'fr_FR', { status: GROUP_CLASS_REQUEST_INITIATED }),
          ])
            .then((res) => {
              const isBeneficiaryRegisteredOrRequest = res[0] > 0 || res[1] > 0;
              navigate(isBeneficiaryRegisteredOrRequest ? GROUP_CLASSES_BENEFICIARY : GROUP_CLASSES);
            });
        }
        navigate(GROUP_CLASSES);
      })
      .catch(() => Toast.error(t('login.sso.error')));
  }, [search]);

  return (
    <PublicRoute>
      <div className="column is-flex is-justify-content-center is-align-items-center is-full-height">
        <Loader />
      </div>
    </PublicRoute>
  );
};

export default LoginSingleSignOn;
