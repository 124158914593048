// @flow
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import IconQuestionMark from '@icons/components/IconQuestionMark';
import ToolTip, { toolTipConstants } from '@shared/ToolTip';

import type { PaymentConditionType } from '@paymentConditionType/types';

import * as conditionTypeConstants from '@paymentConditionType/constants';
const {
  CONDITION_TYPE_ANNUAL_FEES,
  CONDITION_TYPE_COUPLE,
  CONDITION_TYPE_FAMILY_SB,
  CONDITION_TYPE_MULTIPLE_COURSES,
} = conditionTypeConstants;
import * as costAmountConstants from '@shared/Payment/constants';
const {
  COUPLE_AMOUNT,
  FAMILY_SB_AMOUNT,
  MULTIPLE_COURSES_AMOUNT,
  ANNUAL_FEES_AMOUNT,
} = costAmountConstants;

type Props = {
  paymentConditions: PaymentConditionType[],
  isVisible: boolean,
  toggleVisible: (value?: boolean) => void,
};

const PaymentInfoBubble = (props: Props): React$Node => {
  const {
    paymentConditions,
    isVisible,
    toggleVisible,
  } = props;
  const { t } = useTranslation();

  const coupleCost = useMemo((): number => (
    paymentConditions.find((condition) => condition.code === CONDITION_TYPE_COUPLE)?.amount || COUPLE_AMOUNT
  ), [paymentConditions]);

  const familyCost = useMemo((): number => (
    paymentConditions.find((condition) => condition.code === CONDITION_TYPE_FAMILY_SB)?.amount || FAMILY_SB_AMOUNT
  ), [paymentConditions]);

  const multiCost = useMemo((): number => (
    paymentConditions.find((condition) => condition.code === CONDITION_TYPE_MULTIPLE_COURSES)?.amount || MULTIPLE_COURSES_AMOUNT
  ), [paymentConditions]);

  const annualFeesCost = useMemo((): number => (
    paymentConditions.find((condition) => condition.code === CONDITION_TYPE_ANNUAL_FEES)?.amount || ANNUAL_FEES_AMOUNT
  ), [paymentConditions]);

  return (
    <span className="tool-tip-container">
      <IconQuestionMark onClick={ () => toggleVisible(!isVisible) } />
      <ToolTip
        place={ toolTipConstants.TOOLTIP_POSITION_TOP }
        visible={ isVisible }
        onClick={ toggleVisible }
        withArrow
      >
        <ul>
          <li>{ t('paymentCondition.couple') } &nbsp; { coupleCost } &euro;</li>
          <li>{ t('paymentCondition.familysb') } &nbsp; { familyCost } &euro;</li>
          <li>
            { t('paymentCondition.multiplecourses') } &nbsp; { multiCost } &euro;
            <br />
            { t('paymentCondition.multiplecoursesExtra') }
          </li>
          <li>{ t('paymentCondition.annual.fees') } &nbsp; { `+${ annualFeesCost }` } &euro;</li>
        </ul>
      </ToolTip>
    </span>
  );
};

PaymentInfoBubble.defaultProps = {
  toggleVisible: () => {},
};

export default PaymentInfoBubble;
