// @flow
import clsx from 'clsx';

import { useMemo } from 'react';

import type { HelpProps } from '@core/types';

const Help = (props: HelpProps): React$Node => {
  const {
    text,
    textColor,
    className,
  } = props;

  const helpClassName = useMemo((): string => clsx({
    'help': true,
    [String(textColor)]: !!textColor,
    [String(className)]: !!className,
  }), [className, textColor]);

  return (
    <p className={ helpClassName }>
      { text }
    </p>
  );
};

export default Help;
