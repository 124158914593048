// @flow
import { useTranslation } from 'react-i18next';

import IconQuestionMark from '@icons/components/IconQuestionMark';
import ToolTip, { toolTipConstants } from '@shared/ToolTip';

type Props = {
  isVisible: boolean,
  toggleVisible: (value?: boolean) => void,
};

const SpecialCharInfoBubble = (props: Props): React$Node => {
  const {
    isVisible,
    toggleVisible,
  } = props;
  const { t } = useTranslation();

  return (
    <span className="tool-tip-container">
      <IconQuestionMark onClick={ () => toggleVisible(!isVisible) } />
      <ToolTip
        place={ toolTipConstants.TOOLTIP_POSITION_TOP }
        visible={ isVisible }
        onClick={ toggleVisible }
        withArrow
      >
        <div className="special-char-title">{ t('special.char.info.title') }</div>
        <div>{ `! " # $ % & ' ( ) * + , - . / : ; < = > ? @ [ \\ ] ^ _ ${ '`' } { | } ~` }</div>
      </ToolTip>
    </span>
  );
};

SpecialCharInfoBubble.defaultProps = {
  toggleVisible: () => {},
};

export default SpecialCharInfoBubble;
