// @flow
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PublicRoute from '@shared/PublicRoute/components/PublicRoute';
import InputField from '_common/components/input-field/InputField';
import Button, { buttonConstants } from '@shared/Button';
import AuthService from '@user/services/AuthService';
import IconCircleXMark from '@icons/components/IconCircleXMark';

import GroupClassesService from '@groupClass/services/GroupClassesService';

import type { Error, ErrorProps } from '@core/types';

import { ERROR_IS_BLANK, INVALID_CHARACTERS } from '@helpers/TranslationHelpers';
import TranslationHelpers from '@helpers/TranslationHelpers';
import { errorColor } from '@app/constants/styles';

import { LOADER_TYPE_PAGE } from '_common/components/loader/constants';
import { WEB_PATHS } from '@app/constants/paths';
const { GROUP_CLASSES, RESET_KEY_REQUEST } = WEB_PATHS;

const Login = (): React$Node => {
  const { t, i18n: { language } } = useTranslation();
  const navigate = useNavigate();

  const [ email, setEmail ] = useState<string>('');
  const [ password, setPassword ] = useState<string>('');
  const [ isLoading, setLoading ] = useState<boolean>(false);
  const [ showErrorMessage, setShowErrorMessage ] = useState<boolean>(false);
  const [ loginErrors, setLoginErrors ] = useState<Error[]>([]);

  const handleSubmit = useCallback((event: SyntheticEvent<EventTarget>) => {
    event.preventDefault();
    const customError: Error[] = [];

    if (email === '') {
      const error = { propertyPath: 'email', code: ERROR_IS_BLANK, message: '' };
      customError.push(error);
    }

    if (email !== '' && !email.includes('@')) {
      const error = { propertyPath: 'email', code: INVALID_CHARACTERS, message: '' };
      customError.push(error);
    }

    if (password === '') {
      const error = { propertyPath: 'password', code: ERROR_IS_BLANK, message: '' };
      customError.push(error);
    }

    if (customError.length === 0) {
      GroupClassesService.resetService();
      setLoading(true);
      AuthService.login(email, password)
        .then(() => {
          navigate(GROUP_CLASSES);
        })
        .catch(() => {
          setLoading(false);
          setShowErrorMessage(true);
        });
    }
    setLoginErrors(customError);
  }, [email, password, setLoading, setLoginErrors, setShowErrorMessage]);

  const handleEmailChange = (event: SyntheticEvent<HTMLInputElement>): void => {
    setEmail(event.currentTarget.value);
  };

  const handlePasswordChange = (event: SyntheticEvent<HTMLInputElement>): void => {
    setPassword(event.currentTarget.value);
  };

  const errorsProps = useMemo((): ErrorProps => {
    let errorsPropsTmp = {};
    loginErrors.forEach((error) => {
      Object.assign(errorsPropsTmp, {
        [`${ error.propertyPath }`]: {
          color: 'is-danger',
          borderColor: errorColor,
          labelProps: { className: 'has-text-danger' },
          labelTextAreaProps: {
            className: 'has-text-danger label',
          },
          selectFieldHelperText: { text: t('commonError.thisFieldIsMandatory'), textColor: 'is-danger' },
          error: {
            text: t(TranslationHelpers.getCommonErrorKeyByCode(error.code, error.propertyPath)),
            textColor: 'is-danger',
          },
        },
      });
    });
    return errorsPropsTmp;
  }, [loginErrors, language]);

  return (
    <PublicRoute isLoading={ isLoading } loaderType={ LOADER_TYPE_PAGE }>
      <div className=" login-container container">
        <div className="login-content">
          <h1>{ t('login.title') }</h1>
          <form>
            <InputField
              name="email"
              type="email"
              value={ email }
              onChange={ handleEmailChange }
              label={ t('login.email') }
              color={ errorsProps.email?.color }
              labelProps={ errorsProps.email?.labelProps }
              helpProps={ errorsProps.email?.error }
            />
            <InputField
              label={ t('login.password') }
              name="password"
              onChange={ handlePasswordChange }
              value={ password }
              type="password"
              color={ errorsProps.password?.color }
              labelProps={ errorsProps.password?.labelProps }
              helpProps={ errorsProps.password?.error }
            />
            <div className="forgot-password">
              <Link to={ RESET_KEY_REQUEST }>
                { t('login.forgot.password') }
              </Link>
            </div>
            { showErrorMessage && (
              <div className="custom-error-container">
                <div className="custom-error-message">
                  <IconCircleXMark />
                  { t('login.error') }
                </div>
              </div>
            ) }
            <Button
              type={ buttonConstants.PRIMARY }
              onClick={ handleSubmit }
            >
              { t('common.login') }
            </Button>
          </form>
        </div>
      </div>
    </PublicRoute>
  );
};

export default Login;
