// @flow
import { useEffect, useRef } from 'react';

const usePrevious = (value: any): any | null => {
  const ref = useRef<any>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current ?? null;
};

export default usePrevious;
