// @flow
import Field from '_common/components/field/Field';
import Select, { type SelectProps } from '_common/components/select/Select';
import Label from '_common/components/label/Label';
import Help from '_common/components/help/Help';

import type { LabelProps, HelpProps } from '@core/types';

type Props = SelectProps & {
  name: string,
  label: React$Node,
  required?: boolean,
  labelProps?: LabelProps,
  helpProps?: HelpProps,
};

const SelectField = (props: Props): React$Node => {
  const {
    name,
    label,
    options,
    isMultiple,
    multipleSize,
    color,
    isRounded,
    size,
    isHovered,
    isFocused,
    isLoading,
    hasIconLeft,
    controlIsExpanded,
    labelProps,
    helpProps,
    required,
    ...others
  } = props;

  return (
    <Field>
      <Label
        { ...labelProps }
        size={ size }
        htmlFor={ name }
      >
        { label } { required && '*' }
      </Label>
      <Select
        { ...others }
        options={ options }
        isMultiple={ isMultiple }
        multipleSize={ multipleSize }
        color={ color }
        isRounded={ isRounded }
        size={ size }
        isHovered={ isHovered }
        isFocused={ isFocused }
        isLoading={ isLoading }
        hasIconLeft={ hasIconLeft }
        controlIsExpanded={ controlIsExpanded }
      />
      { helpProps && (
        <Help
          { ...helpProps }
          textColor={ color } />
      ) }
    </Field>
  );
};

export default SelectField;
