// @flow
/* eslint-disable max-len */
import clsx from 'clsx';

type Props = {
  className: string,
  onClick: () => void,
};

const IconInfos = (props: Props): React$Node => {
  const { className, onClick } = props;
  const newProps = {
    className: clsx({ icon: true, 'icon-infos': true, [className]: !!className }),
    preserveAspectRatio: 'xMidYMid',
    role: 'img',
    viewBox: '0 0 512 512',
    xmlns: 'http://www.w3.org/2000/svg',
    onClick,
  };

  return (
    <svg { ...newProps }>
      <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z" />
    </svg>
  );
};

IconInfos.defaultProps = {
  className: 'icon-infos',
  onClick: () => {},
};

export default IconInfos;
