// @flow
import { format as dateFmt } from 'date-fns';

import { FORMAT_DATE_API } from '@app/constants/dates';

import type {
  BankDepositFormData,
  BankDepositDetailed,
  BankDepositDetailedPayload,
  ShortBankDeposit,
  ShortBankDepositPayload,
  BankDepositPayload,
  BankDeposit,
} from '@payment/types';
import type { Option } from '@core/types';

import { hydrateShortPaymentChequeFromPayload } from '@payment/helpers/PaymentChequeUtils';

export const hydrateDepositDetailedFromPayload = (bankDepositPayload: BankDepositDetailedPayload): BankDepositDetailed => {
  const paymentCheques = bankDepositPayload.paymentCheques.map((paymentChequePayload) => hydrateShortPaymentChequeFromPayload(paymentChequePayload));
  return {
    id: bankDepositPayload.id,
    reference: bankDepositPayload.reference,
    depositDate: new Date(bankDepositPayload.depositDate),
    totalAmount: bankDepositPayload.totalAmount || 0,
    paymentCheques: paymentCheques,
    isDeletable: bankDepositPayload.isDeletable,
  };
};

export const hydrateDepositFromPayload = (bankDepositPayload: BankDepositPayload): BankDeposit => ({
  id: bankDepositPayload.id,
  reference: bankDepositPayload.reference,
  depositDate: new Date(bankDepositPayload.depositDate),
  totalAmount: bankDepositPayload.totalAmount || 0,
  isDeletable: bankDepositPayload.isDeletable,
});

export const hydrateShortDepositFromPayload = (bankDepositPayload: ShortBankDepositPayload): ShortBankDeposit => ({
  id: bankDepositPayload.id,
  reference: bankDepositPayload.reference,
  depositDate: new Date(bankDepositPayload.depositDate),
  paymentCheques: bankDepositPayload.paymentCheques || [],
});

export const createOptionFromPayload = (bankDepositPayload: BankDeposit | BankDepositFormData): Option => ({
  label: bankDepositPayload.reference,
  value: String(bankDepositPayload.id ?? ''),
});

export const hydrateDepositFormDataFromDeposit = (bankDeposit: BankDepositDetailed): BankDepositFormData => {
  const paymentCheques = bankDeposit.paymentCheques.map((paymentCheque) => paymentCheque.id);

  return {
    id: bankDeposit.id,
    reference: bankDeposit.reference,
    depositDate: dateFmt(new Date(bankDeposit.depositDate), FORMAT_DATE_API),
    paymentCheques: paymentCheques,
  };
};

export const shortBankDepositToFormData = (bankDeposit: ShortBankDeposit): BankDepositFormData => ({
  id: bankDeposit.id,
  reference: bankDeposit.reference,
  depositDate: dateFmt(new Date(bankDeposit.depositDate), FORMAT_DATE_API),
  paymentCheques: bankDeposit.paymentCheques || [],
});

export const emptyBankDeposit: BankDepositFormData = {
  id: null,
  reference: '',
  depositDate: '',
  paymentCheques: [],
};

export const initialBankDeposit: BankDepositDetailed = {
  id: 0,
  reference: '',
  depositDate: new Date(),
  totalAmount: 0,
  paymentCheques: [],
  isDeletable: true,
};
