// @flow
import type { Listener, QueryParams } from '@core/types';
import type { GroupClassRequest, ShortGroupClassRequests, ShortGroupClassRequestPayload } from '@participant/types';

import EventEmitter from '_common/services/EventEmitter';
import ApiService from '@api/service';
import { hydrateShortRequest, hydrateRequest } from '@groupClass/helpers/RequestsUtils';

import { API_PATHS } from '@app/constants/paths';
import { GROUP_CLASS_REQUEST_INITIATED } from '@app/constants/constants';

const { GROUP_CLASS_REQUESTS, GROUP_CLASS_REQUEST } = API_PATHS;

const DEFAULT_PARAMS = {
  status: GROUP_CLASS_REQUEST_INITIATED,
  pagination: false,
  'order[beneficiary.lastName]': 'asc',
};

export type RequestsServiceData = {
  countRequests: number,
  groupClassRequest: GroupClassRequest | null,
  groupClassRequests: ShortGroupClassRequests,
  isLoadingRequest: boolean,
  isLoadingRequests: boolean,
};

type PreregisterBeneficiary = (groupClassId: number, beneficiary: number) => Promise<any>;
type DeclineRequest = (requestId: number) => Promise<any>;
type CountGroupClassRequests = (groupClassId: number, language: string, params?: QueryParams) => void;
type FetchGroupClassRequests = (groupClassId: number, language: string, params?: QueryParams) => Promise<ShortGroupClassRequests>;
type FetchOne = (id: number, language: string) => Promise<GroupClassRequest>;
type UpdateValues = (newGroupClassRequests: ShortGroupClassRequests, newIsLoadingRequests: boolean) => void;
type UpdateRequestValues = (newGroupClassRequest: GroupClassRequest | null, newIsLoadingRequest: boolean) => void;
type OnChange = (listener: Listener) => Function;
type Reset = () => void;
type ResetCountRequest = () => void;
type Trigger = () => void;

let sourceOne = ApiService.createToken();
let sourceCount = ApiService.createToken();
let sourceAll = ApiService.createToken();

class RequestsService {
  constructor() {
    this.eventEmitter = new EventEmitter();

    this._countRequests = 0;
    this._groupClassRequest = null;
    this._groupClassRequests = [];
    this._isLoadingRequest = false;
    this._isLoadingRequests = false;
  }

  _countRequests: number;
  _groupClassRequest: GroupClassRequest | null;
  _groupClassRequests: ShortGroupClassRequests;
  _isLoadingRequest: boolean;
  _isLoadingRequests: boolean;
  eventEmitter: EventEmitter;

  get countRequests(): number { return this._countRequests; }
  get groupClassRequests(): ShortGroupClassRequests { return this._groupClassRequests; }
  get isLoadingRequests(): boolean { return this._isLoadingRequests; }
  get groupClassRequest(): GroupClassRequest | null { return this._groupClassRequest; }
  get isLoadingRequest(): boolean { return this._isLoadingRequest; }

  set countRequests(data: number): void { this._countRequests = data; this.#trigger(); }
  set groupClassRequests(data: ShortGroupClassRequests): void { this._groupClassRequests = data; this.#trigger(); }
  set isLoadingRequests(data: boolean): void { this._isLoadingRequests = data; this.#trigger(); }
  set groupClassRequest(data: GroupClassRequest | null): void { this._groupClassRequest = data; this.#trigger(); }
  set isLoadingRequest(data: boolean): void { this._isLoadingRequest = data; this.#trigger(); }

  reset: Reset = (): void => {
    this.updateValues([], false);
    this.updateRequestValues(null, false);
  };

  resetCountRequest: ResetCountRequest = (): void => {
    this.countRequests = 0;
  };

  updateValues: UpdateValues = (newGroupClassRequests: ShortGroupClassRequests, newIsLoadingRequests: boolean ): void => {
    this._groupClassRequests = newGroupClassRequests;
    this._isLoadingRequests = newIsLoadingRequests;
    this.#trigger();
  };

  updateRequestValues: UpdateRequestValues = (newGroupClassRequest: GroupClassRequest | null, newIsLoadingRequest: boolean): void => {
    this._groupClassRequest = newGroupClassRequest;
    this._isLoadingRequest = newIsLoadingRequest;
    this.#trigger();
  };

  countGroupClassRequests: CountGroupClassRequests = (groupClassId: number, language: string, params?: QueryParams = DEFAULT_PARAMS): void => {
    sourceCount.cancel();
    sourceCount = ApiService.createToken();

    this.countRequests = 0;

    ApiService.request({
      method: 'get',
      url: GROUP_CLASS_REQUESTS.replace(':id', String(groupClassId)),
      cancelToken: sourceCount.token,
      headers: {
        'Accept-Language': language,
      },
      params,
    })
      .then((payload) => {
        if (payload.data) {
          this.countRequests = payload.data['hydra:totalItems'];
        }
      })
      .catch(() => {});
  };

  fetchGroupClassRequests: FetchGroupClassRequests = (groupClassId: number, language: string, params?: QueryParams = DEFAULT_PARAMS): Promise<ShortGroupClassRequests> => {
    sourceAll.cancel();
    sourceAll = ApiService.createToken();

    this.isLoadingRequests = true;

    return ApiService.request({
      method: 'get',
      url: GROUP_CLASS_REQUESTS.replace(':id', String(groupClassId)),
      cancelToken: sourceAll.token,
      headers: {
        'Accept-Language': language,
      },
      params,
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;
          const groupClassRequestsData: ShortGroupClassRequests = data['hydra:member'].map(
            (people: ShortGroupClassRequestPayload) => hydrateShortRequest(people),
          );

          this.updateValues(groupClassRequestsData, false);
          return groupClassRequestsData;
        }
        return Promise.reject();
      })
      .catch(() => {
        this.isLoadingRequests = false;
        return Promise.reject();
      });
  };

  fetchOne: FetchOne = (id: number, language: string): Promise<GroupClassRequest> => {
    sourceOne.cancel();
    sourceOne = ApiService.createToken();

    this.isLoadingRequest = true;

    return ApiService.request({
      method: 'get',
      url: `${ GROUP_CLASS_REQUEST }/${ id }`,
      cancelToken: sourceOne.token,
      headers: {
        'Accept-Language': language,
      },
    })
      .then((payload) => {
        if (payload.data) {
          const { data } = payload;
          const newRequest = hydrateRequest(data);

          this.updateRequestValues(newRequest, false);
          return newRequest;
        }
        return Promise.reject();
      })
      .catch(() => {
        this.isLoadingRequest = false;
        return Promise.reject();
      });
  };

  preregisterBeneficiary: PreregisterBeneficiary = (groupClassId: number, beneficiary: number): Promise<any> => {
    this.isLoadingRequests = true;

    return ApiService.request({
      method: 'post',
      url: GROUP_CLASS_REQUEST,
      data: {
        groupClass: groupClassId,
        beneficiary,
      },
    })
      .then(() => {
        this.isLoadingRequests = false;
        return Promise.resolve();
      })
      .catch(() => {
        this.isLoadingRequests = false;
        return Promise.reject();
      });
  };

  declineRequest: DeclineRequest = (requestId: number): Promise<any> => (
    ApiService.request({
      method: 'put',
      url: `${ GROUP_CLASS_REQUEST }/${ requestId }`,
      data: { status: 'refused' },
    })
  );

  onChange: OnChange = (listener: Listener): Function => {
    const listenerFunction = this.eventEmitter.addListener(listener);
    this.#trigger();
    return listenerFunction;
  };

  /**
   * @private
   */
  #trigger: Trigger = (): void => {
    this.eventEmitter.trigger({
      countRequests: this._countRequests,
      groupClassRequests: this._groupClassRequests,
      isLoadingRequests: this._isLoadingRequests,
      groupClassRequest: this._groupClassRequest,
      isLoadingRequest: this._isLoadingRequest,
    });
  };
}

const instance: RequestsService = new RequestsService();
export default instance;
