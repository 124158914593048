// @flow

export const getPageNumberFromUrl = (url: string): number | null => {
  const re = /page=(?<pageNumber>\d+)$/g.exec(url);
  if (re && re.groups && re.groups.pageNumber !== '') {
    return parseInt(re.groups.pageNumber, 10);
  }

  return null;
};
