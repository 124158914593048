// @flow
import { memo, useMemo } from 'react';
import clsx from 'clsx';

import type { Alignment, Sizes } from '@core/types';

import Button, { buttonConstants } from '@shared/Button';

import usePagination from '@shared/Pagination/hooks/usePagination';

import IconChevron from '@icons/components/IconChevron';

type Props = {
  currentPage: number,
  lastPage: number,
  pagesToShow?: number,
  alignment?: Alignment,
  size?: Sizes,
  isRounded?: boolean,
  changePage: (page: number) => void,
};

const Ellispsis = (): React$Node => (
  <li>
    <span className="pagination-ellipsis">&hellip;</span>
  </li>
);

const Pagination = (props: Props): React$Node => {
  const { currentPage, lastPage, changePage, pagesToShow = 5, alignment, size, isRounded } = props;

  const [pages, leftEllipsis, rightEllipsis, displayFirstPage, displayLastPage] = usePagination(
    currentPage,
    lastPage,
    pagesToShow,
  );

  const paginationClassName = useMemo((): string => clsx({
    'pagination': true,
    'is-rounded': isRounded,
    [String(alignment)]: !!alignment,
    [String(size)]: !!size,
  }), [size, alignment, isRounded]);

  return (
    <nav
      className={ paginationClassName }
      role="navigation"
      aria-label="pagination"
    >
      <Button
        type={ buttonConstants.SECONDARY }
        className="pagination-previous-next"
        onClick={ () => changePage(currentPage - 1) }
        disabled={ currentPage <= 1 }
      >
        <IconChevron />
      </Button>

      <ul className="list-page">
        { displayFirstPage && (
          <li id="first-page">
            <Button
              type={ buttonConstants.SECONDARY }
              onClick={ () => changePage(1) }
              className="pagination-link"
              aria-label="Goto page 1"
            >
              1
            </Button>
          </li>
        ) }

        { leftEllipsis && <Ellispsis /> }

        { pages.map((page) => {
          const isCurrent = page === currentPage ? 'is-current' : null;
          return (
            <li key={ page }>
              <Button
                type={ buttonConstants.SECONDARY }
                className={ clsx({ 'pagination-link': true, 'is-current': isCurrent }) }
                id={ `page${ page }` }
                aria-label={ `Page ${ page }` }
                onClick={ () => changePage(page) }
              >
                { page }
              </Button>
            </li>
          );
        }) }

        { rightEllipsis && <Ellispsis /> }

        { displayLastPage && (
          <li id="last-page">
            <Button
              type={ buttonConstants.SECONDARY }
              onClick={ () => changePage(lastPage) }
              className="pagination-link"
              aria-label={ `Goto page ${ lastPage }` }
            >
              { lastPage }
            </Button>
          </li>
        ) }
      </ul>

      <Button
        type={ buttonConstants.SECONDARY }
        className="pagination-previous-next"
        onClick={ () => changePage(currentPage + 1) }
        disabled={ currentPage >= lastPage }
      >
        <IconChevron className="rotate-180" />
      </Button>
    </nav>
  );
};

export default memo(Pagination);
