// @flow
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import Modal from '@shared/Modal/Modal';
import Button, { buttonConstants } from '@shared/Button';
import Image from '@shared/Image';

import DateService from '_common/services/DateService';

import type { ShortGroupClass } from '@groupClass/types';
import type { Coach } from '@coach/types';

import { WEB_PATHS } from '@app/constants/paths';

const { GROUP_CLASS } = WEB_PATHS;

type Props = {
  groupClass: ShortGroupClass | null,
  onDeleteRequest: (requestId: SyntheticEvent<HTMLButtonElement>) => void,
};

const TrialBanner = (props: Props): React$Node | null => {

  const { groupClass, onDeleteRequest } = props;

  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const navigate = useNavigate();

  const { streetName, additionalLocalizationConstruction, postalCode, city } = groupClass?.address || {};
  const [ isActiveModal, setIsActiveModal ] = useState(false);

  const toggleModal = useCallback((): void => {
    setIsActiveModal(!isActiveModal);
  }, [isActiveModal]);

  const footerModal = useMemo((): React$Node => (
    <footer className="modal-footer">
      <Button
        type={ buttonConstants.TERTIARY }
        className="button"
        onClick={ toggleModal }
        isOutlined
      >
        { t('common.cancel') }
      </Button>

      <Button
        type={ buttonConstants.SECONDARY }
        className="button is-success confirm"
        onClick={ onDeleteRequest }
      >
        { t('groupClasses.groupClass.peoples.form.popUpConfirm') }
      </Button>
    </footer>
  ), [onDeleteRequest, toggleModal, t, language]);

  const cancelPreregister = useCallback((e: SyntheticEvent<EventTarget>): void => {
    e.preventDefault();
    e.stopPropagation();
    toggleModal();
  }, [toggleModal]);

  const handleClick = useCallback((): void => {
    if (groupClass) {
      navigate(GROUP_CLASS.replace(':classId', String(groupClass.id)));
    }
  }, [groupClass]);

  const mainCoach = useMemo((): Coach | null => (
    groupClass?.coaches.find((coach) => coach.isMainCoach) ?? null
  ), [groupClass]);

  const mainCoachName = useMemo((): string => (
    mainCoach?.sielBleuUser.firstName || ''
  ), [mainCoach]);

  if (!groupClass) {
    return null;
  }

  return (
    <>
      <div className="trial-banner" onClick={ handleClick }>
        <div className="trial-banner-left-block">
          <h2 className="trial-banner-title">
            { groupClass.activity.name }
          </h2>
          <p>
            <span>{ streetName }</span>
            <span>{ additionalLocalizationConstruction }</span>
            <span>{ postalCode } { city }</span>
          </p>
          <div className="trial-banner-date-coach">
            <p>{ DateService.localDateAndTimeDisplay(groupClass.day, groupClass.startTime, groupClass.endTime, language) }</p>
            { mainCoach && (
              <p className="trial-banner-coach">
                { t('common.with') }&nbsp;
                <span>
                  <u>
                    { mainCoachName }
                  </u>
                </span>
              </p>
            ) }
          </div>
          <Image src="arrow-banner.svg" alt="arrow" className="trial-banner-arrow-right" />
        </div>

        <div className="trial-banner-right-block">
          <h2 className="trial-banner-title-mentions">{ t('groupClasses.trialBanner.titleMentions') }</h2>
          <p>{ t('groupClasses.trialBanner.mentionsOne') }</p>
          <p className="trial-banner-mention-two">{ t('groupClasses.trialBanner.mentionsTwo') }</p>
          <div className="trial-banner-cancel-block">
            <div className="trial-banner-see-more">
              <Link
                to={ GROUP_CLASS.replace(':classId', String(groupClass.id)) }
                className="button button-tertiary"
              >
                { t('common.seeMore') }
              </Link>
            </div>
            <div className="trial-banner-cancel-btn">
              <span onClick={ cancelPreregister }>
                <u>
                  { t('groupClasses.groupClass.detailed.cancelPreregister') }
                </u>
              </span>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isActive={ isActiveModal }
        isClipped
        onClose={ toggleModal }
        title={ t('groupClasses.groupClass.detailed.cancelPreregister') }
        footer={ footerModal }
      >
        { t('groupClasses.groupClass.detailed.cancelPreregisterModal') }
      </Modal>
    </>
  );
};

export default TrialBanner;
